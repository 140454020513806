import { Step } from "@sssuite-component-ui/multi-step-wizard/dist/Body/Step";
import { Wizard } from "@sssuite-component-ui/multi-step-wizard/dist/Body/Wizard";
import { ISignerModel } from "../../core/domain/models/ISignerModel";
import { History } from "history";
import * as React from "react";
import { match, RouteComponentProps } from "react-router";
import {
  ClientType,
  DistributeType,
  SignatureStatus,
  EngagementTypeS,
  KBATransactionResultType,
  SignatureType,
  DraftStatus,
  TaxReturnStatus,
} from "../../core/common/Enums";
import { ICompany } from "../../core/domain/models/company/Company";
import { IDocument } from "../../core/domain/models/esign/Document";
import { IDocumentSettings } from "../../core/domain/models/IDocumentSettings";
import { DocumentGroups, IVoucher } from "../../core/domain/models/IGroup";
import {
  GetK1EngagementType,
  IK1ShareHolderDetails,
} from "../../core/domain/models/IK1Distribute";
import { IKBATransactionResponse } from "../../core/domain/models/IKBA";
import {
  initailsignatureSettingsModel,
  ISignatureSettingsModel,
} from "../../core/domain/models/ISignatureSettingsModel";
import { ITaxDocumentModel } from "../../core/domain/models/ITaxDocumentModel";
import * as TaxDocument from "../../core/domain/models/ITaxReturn";
import { ITutorialInfo } from "../../core/domain/models/ITutorialInfo";
import { ISignedDocument } from "../../core/domain/models/manualsign/SignedDocument";
import { IClientProcessViewModel } from "../../core/domain/viewModels/IClientProcessViewModel";
import { ICommonDataViewModel } from "../../core/domain/viewModels/ICommonDataViewModel";
import { IDocumentReviewModel } from "../../core/domain/viewModels/IDocumentReviewModel";
import {
  IHeaderInfoViewModel,
  initialHeaderInfoViewModel,
} from "../../core/domain/viewModels/IHeaderInfoViewModel";
import { ILoggedInUserInfo } from "../../core/domain/viewModels/ILoggedInUserInfo";
import { IPayScreenViewModel } from "../../core/domain/viewModels/IPayScreenViewModel";
import { ISignProcessInfoViewModel } from "../../core/domain/viewModels/ISignProcessInfoViewModel";
import { ISummaryViewModel } from "../../core/domain/viewModels/ISummaryViewModel";
import { IDialogBox } from "../../core/utilities/ui/DialogBox";
import { container } from "../../startup/inversify.config";
import { TYPES } from "../../startup/types";
import * as AccountStore from "../../store/Common/AccountStore";
import * as CompanyStore from "../../store/Common/Company/CompanyStore";
import * as EsignStore from "../../store/Common/EsignStore";
import * as HelperStore from "../../store/Common/HelperStore";
import * as TaxDocumentStore from "../../store/Common/TaxDocumentStore";
import * as K1DistributeStore from "../../store/K1/K1DistributeStore";
import * as PaperFileReturnStore from "../../store/PaperFileReturn/PaperFileReturnStore";
import * as PayStore from "../../store/PayStore";
import * as PreparerMessageStore from "../../store/PreparerMessageStore";
import * as DocumentReviewStore from "../../store/ReviewStore";
import * as ManualSignStore from "../../store/Sign/ManualSignStore";
import * as SignProcessStore from "../../store/SignProcess/SignProcessStore";
import * as SummaryStore from "../../store/SummaryStore";
import * as TutorialStore from "../../store/Tutorial/TutorialStore";
import { PaperFileReturnConstants } from "../Common/Constants";
import {
  Common,
  K1ShareHolderConstants,
  ManualSignConstants,
  PathConstants,
  getRoutePathUrl,
} from "../Common/Constants";
import {
  PreviousButtonIcon,
  NextButtonIcon,
  TemplateIcon,
} from "../Common/Icons/SvgIcons";
import { Body } from "../Common/StepLayout/Body";
import { StepLayout } from "../Common/StepLayout/StepLayout";
import { EsignConsentEmail } from "../Esign/EsignConsentEmail";
import { KBAComponent } from "../Esign/KBAComponent";
import { Esign } from "../Esign/Main/Esign";
import { PdfViewManager } from "../Esign/Main/EsignBase";
import SelectSignMethod from "../Esign/SelectSignMethod";
import SignatureCompletedPage from "../Esign/SignatureCompletedPage";
import SpouseSignRequired from "../Esign/SpouseSignRequired";
import TaxpayerHelper from "../Helper/TaxpayerHelper";
import { DistributeElectronic } from "../K1Distribution/DistributeElectronic";
import { DistributeManual } from "../K1Distribution/DistributeManual";
import { K1DistributionMethod } from "../K1Distribution/K1DistributionMethod";
import { ManualSign } from "../ManualSign/ManualSign";
import { ManualSignDownloadFiles } from "../ManualSign/ManualSignDownloadFiles";
import * as PreparerMessageWizard from "../PreparerMessage/MessageWizard";
import * as PaperFileMessageWizard from "../PaperFile/PaperFileMessageWizard";
import * as InvoiceWizard from "../Review/Main/Invoice";
import * as ReviewWizard from "../Review/Main/Review";
import * as SummaryWizard from "../Summary/Main/WizardBody";
import {
  SignProcessSteps,
  TaxpayerSignFlowManager,
} from "./TaxpayerSignFlowManager";
import {
  hideReviewStep,
  isIndividualEngagementType,
  showPayNow,
  hidePayLater,
  buttonNameChange,
  isAnyPaySettingsEnabled,
  isAttachedUrlEnabled,
  isStripeEnabled,
} from "../Helper/HelperFunction";
import * as PaymentStore from "../../store/PaymentStore";
import { IPaymentTransactionModel } from "../../core/domain/models/IPaymentTransactionModel";
import { IAdditionalEsignDocument } from "../../core/domain/models/IAdditionalEsignDocument";
import * as CCRecipientStore from "../../store/CCRecipientStore";
import { ICCRecipientDownloadableDocuments } from "../../core/domain/models/ICCRecipientModel";
import * as DelegateeSignerStore from "../../store/DelegateeSignerStore";
import { storeControllerIdInMemory } from "../../core/services/dataAccess/DataService.Axios";
import { PaperFileList } from "../PaperFile/PaperFileList";
import { IPaperFileReturnDetails } from "../../core/domain/models/IPaperFileReturn";
import PaymentVoucher from "../Pay/PaymentVoucher/PaymentVoucher";
import EstimatedVoucher from "../Pay/EstimatedVoucher/EstimatedVoucher";
import * as DocumentStatusStore from "../../store/Common/DocumentStatusStore";
import { logger } from "../../routes";
import { OverlayLoader } from "../Common/Loader/OverlayLoader";
import { ILoader } from "../../core/utilities/ui/Loader";
import { Promise } from "es6-promise";
import * as TrialK1DistributeStore from "../../store/K1/TrialK1DistributeStore";
import {
  DocumentEvent,
  IDocumentTransaction,
} from "../../core/domain/models/IDocumentTransaction";
import SuiteModal from "../Common/SuiteModal";
import ConsentPopUp from "../ConsentPopUp";
import {
  getConsentDetails,
  updateConsentDetails,
} from "../../store/SMSService/SMSService.api";
import {
  ISMSGETResponse,
  ISMSPUTRequest,
} from "../../store/SMSService/SMSService.model";
import { isPartnership } from "../../core/domain/models/ITaxReturn";

const loader = container.get<ILoader>(TYPES.ILoader);
const NO_INDEX = -1;

export type MainComponentProps = {
  summaryData: ISummaryViewModel;
  esignData: ITaxDocumentModel;
  signerData: ISignerModel[];
  manualSignData: ISignedDocument[];
  companyData: ICompany;
  kbaData: IKBATransactionResponse;
  payScreenData: IPayScreenViewModel;
  signatureControlsData: IDocument[];
  commonData: ICommonDataViewModel;
  signatureSettingModel: ISignatureSettingsModel;
  documentSettings: IDocumentSettings;
  documentReviewModel: IDocumentReviewModel[];
  headInfoViewModel: IHeaderInfoViewModel;
  taxReturn: TaxDocument.ITaxReturn;
  isKBAPassed: boolean;
  match: match;
  history: History;
  loading: boolean;
  k1Data: IK1ShareHolderDetails[];
  isK1Loading: boolean;
  paperFileReturnData: IPaperFileReturnDetails[];
  signProcessInfo: ISignProcessInfoViewModel;
  loggedInUserInfo: ILoggedInUserInfo;
  tutorialInfo: ITutorialInfo;
  additionalEsignDocuments: IAdditionalEsignDocument[];
  downloadAllAdditionEsignDocuments: (
    clientId: string,
    fileName: string
  ) => any;
  requestAdditionalEsignDocument(
    clientGuid: string,
    callback?: () => void
  ): void;
  ccRecipientDownloadableDocuments: ICCRecipientDownloadableDocuments[];
  paymentData: IPaymentTransactionModel;
  requestMyDownload: (clientId: string) => void;
  changedSignBehalfSpouse: boolean;
  DocumentStatusState: DocumentStatusStore.IDocumentStatusState;
} & typeof SummaryStore.actionCreators &
  typeof HelperStore.actionCreators &
  typeof EsignStore.actionCreators &
  typeof AccountStore.actionCreators &
  typeof ManualSignStore.actionCreators &
  typeof CompanyStore.actionCreators &
  typeof PreparerMessageStore.actionCreators &
  typeof DocumentReviewStore.actionCreators &
  typeof TaxDocumentStore.actionCreators &
  typeof K1DistributeStore.actionCreators &
  typeof PaperFileReturnStore.actionCreators &
  typeof SignProcessStore.actionCreators &
  typeof PayStore.actionCreators &
  typeof TutorialStore.actionCreators &
  typeof CCRecipientStore.actionCreators &
  typeof PaymentStore.actionCreators &
  typeof DelegateeSignerStore.actionCreators &
  typeof DocumentStatusStore.actionCreators &
  typeof TrialK1DistributeStore.actionCreators &
  RouteComponentProps<{}>;

interface MainComponentState {
  signatureSettings: ISignatureSettingsModel;
  headInfoViewModel: IHeaderInfoViewModel;
  hideNextButton: boolean;
  hidePreviousButton: boolean;
  isNextButtonDisable: boolean;
  isConsentAgreed: boolean;
  hideNavigationBar: boolean;
  hideFooter: boolean;
  isPreviewMode: boolean;
  isInOfficeESign: boolean;
  showCCModal: boolean;
  isSignBehalfOfSpouse: boolean;
  isSpouseBehalfCheckDisabled: boolean;
  isDelegateeAssigned: boolean;
  isPaymentDetailsUpdated: boolean;
  voucherState: string;
  voucherFlow: string;
  isTrialView: boolean;
  showConsentPopUp: boolean;
  countryCode: string;
  mobileNumber: string;
  clientId: string;
  clientType: number;
  initialCountryCode: string;
  initialMobileNumber: string;
  isAccessedFromOneHub: boolean;
  isDraftReturn: boolean;
}

const dialogBox = container.get<IDialogBox>(TYPES.IDialogBox);

export class TaxpayerSignFlow extends React.Component<
  MainComponentProps,
  MainComponentState
> {
  private _wizardRef: any;
  private _reviewRef: any;
  private _consentRef: any;
  private _paperFileConsentRef: any;
  private _esignRef: any;
  private _spouseSignRequiredRef: any;
  private _manualSign: any;
  private _params: any;
  private _taxpayerSignFlowManager: TaxpayerSignFlowManager =
    TaxpayerSignFlowManager.createNullObject();
  private _distributeManualRef: any = React.createRef();
  private _distributeElectronicRef: any = React.createRef();
  private _paperfileListRef: any = React.createRef();
  private _paymentRef: any = React.createRef();
  private _payRef: any;

  private signFlowApi: any[] = [];

  constructor(props: MainComponentProps) {
    super(props);

    this.state = {
      signatureSettings: initailsignatureSettingsModel,
      headInfoViewModel: initialHeaderInfoViewModel,
      hideNextButton: false,
      hidePreviousButton: false,
      isNextButtonDisable: false,
      isConsentAgreed: false,
      hideNavigationBar: false,
      hideFooter: false,
      isPreviewMode: false,
      isInOfficeESign: false,
      showCCModal: false,
      isSignBehalfOfSpouse: false,
      isSpouseBehalfCheckDisabled: false,
      isDelegateeAssigned: false,
      isPaymentDetailsUpdated: false,
      voucherState: "payment",
      voucherFlow: "forward",
      isTrialView: false,
      showConsentPopUp: false,
      countryCode: "+1",
      mobileNumber: "",
      clientId: "",
      clientType: 0,
      initialCountryCode: "",
      initialMobileNumber: "",
      isAccessedFromOneHub: false,
      isDraftReturn: false,
    };
  }

  componentDidMount() {
    this.setLoader();
    let countryCode = "";
    let mobileNumber = "";
    let clientId = "";
    let clientType = 0;
    let param: any = this.props.match.params;
    this._params = this.props.match.params;
    if (this._params && this._params.controllerId) {
      storeControllerIdInMemory(this._params.controllerId);
    }
    let _self = this;
    this.signFlowApi.push(
      new Promise((resolve) => {
        _self.getSignProcessStatus(param.clientId, resolve);
      })
    );
    this.signFlowApi.push(
      new Promise((resolve) => {
        _self.props.requestDocumentSettings(param.clientId, () => {
          resolve(true);
        });
      })
    );
    this.signFlowApi.push(
      new Promise((resolve) => {
        _self.props.requestDocumentReviewModel(param.clientId, () => {
          resolve(true);
        });
      })
    );
    if (
      this.props.commonData &&
      this.props.commonData.taxingAuthorities.length == 0
    ) {
      this.signFlowApi.push(
        new Promise((resolve) => {
          _self.props.requestAllTaxingAuthorities(param.clientId, () => {
            resolve(true);
          });
        })
      );
    }
    this.signFlowApi.push(
      new Promise((resolve) => {
        _self.props.requestTaxDocument(
          param.clientId,
          (taxReturn: TaxDocument.IMarriedJointTaxReturn) => {
            clientId = taxReturn.clientId;
            if (taxReturn.spouse?.clientGuid === param.clientId) {
              countryCode = taxReturn.spouse?.countryCode;
              mobileNumber = taxReturn.spouse?.mobileNumber;
              clientType = 2;
            } else {
              countryCode = isPartnership(taxReturn)
                ? taxReturn.partnership?.countryCode
                : taxReturn.taxpayer?.countryCode;
              mobileNumber = isPartnership(taxReturn)
                ? taxReturn.partnership?.mobileNumber
                : taxReturn.taxpayer?.mobileNumber;
              clientType = 1;
            }
            this.setState({ clientId: clientId, clientType: clientType });
            resolve(true);
            _self.signFlowApi.push(
              new Promise((resolveCCRecipient) => {
                _self.props.requestCCRecipientDownloadableDocuments(
                  param.clientId,
                  taxReturn.documentStatus,
                  () => {
                    resolveCCRecipient(true);
                  }
                );
              })
            );
            _self.signFlowApi.push(
              new Promise((resolveLastVisitedStep) => {
                _self.props.requestLastVisitedStep(
                  this._params.clientId,
                  (lastVisitedStep: SignProcessSteps) => {
                    resolveLastVisitedStep(true);
                    this.signProcessRecoverConfirmation(lastVisitedStep);
                  }
                );
              })
            );
          }
        );
      })
    );
    this.signFlowApi.push(
      new Promise((resolve) => {
        _self.props.requestSigners(param.clientId, () => {
          resolve(true);
        });
      })
    );
    this.signFlowApi.push(
      new Promise((resolve) => {
        _self.props.requestKBAStatus(param.clientId, () => {
          resolve(true);
        });
      })
    );
    this.signFlowApi.push(
      new Promise((resolve) => {
        _self.props.requestSignedDocuments(param.clientId, undefined, () => {
          resolve(true);
        });
      })
    );
    this.signFlowApi.push(
      new Promise((resolve) => {
        _self.props.requestAdditionalEsignDocument(
          this._params.clientId,
          () => {
            resolve(true);
          }
        );
      })
    );

    this.props.requestCompanyDetails(param.clientId);
    this.props.requestPaymentTransaction(param.clientId);
    this.props.requestStripePublicAPIKey(param.clientId);
    this.props.getDocumentUpdatedStatus(param.clientId);

    Promise.all(this.signFlowApi)
      .then(() => {
        loader.hide();
        getConsentDetails(
          param.clientId,
          clientId,
          clientType,
          mobileNumber,
          (consentResponse: ISMSGETResponse) => {
            if (!countryCode || countryCode.length === 0) {
              countryCode = consentResponse.countryCode ?? "+1";
            }
            if (!mobileNumber || mobileNumber.length === 0) {
              mobileNumber = consentResponse.mobileNumber ?? "";
            }
            this.setState({
              showConsentPopUp: consentResponse.isConsent,
              countryCode: countryCode,
              mobileNumber: mobileNumber,
              initialCountryCode: countryCode,
              initialMobileNumber: mobileNumber,
            });
          }
        );
      })
      .catch((error: any) => {
        console.log(error);
      });
  }

  componentDidUpdate(
    prevProps: Readonly<MainComponentProps>,
    prevState: Readonly<MainComponentState>,
    snapshot?: any
  ): void {
    if (
      this.props.summaryData.draftStatus !==
        prevProps.summaryData.draftStatus ||
      (this.props.headInfoViewModel.draftStatus === DraftStatus.Draft &&
        !this.state.isDraftReturn)
    ) {
      const newIsDraftReturn =
        this.props.summaryData.draftStatus === DraftStatus.Draft;
      if (this.state.isDraftReturn !== newIsDraftReturn) {
        this.setState({
          isDraftReturn: newIsDraftReturn,
        });
      }
    }
  }

  checkIfBothSignerDeceased = () => {
    const param: any = this.props.match.params;
    if (
      this.props.taxReturn.documentSettings.documentSignatureSetting
        .signatureFormSelectionType !== SignatureType.ManualSign &&
      TaxpayerHelper.bothSignerDeceased(
        this.props.taxReturn as any,
        this.props.signerData
      )
    ) {
      this.handleSetSignMethod(SignatureType.ManualSign);
    }
  };

  private getSignProcessStatus = (clientId: any, resolveCurrentStep?: any) => {
    this.props.requestCurrentStepAndUserRole(
      clientId,
      (clientProcessState: IClientProcessViewModel) => {
        resolveCurrentStep(true);
        this.handleRedirect(clientProcessState);
      }
    );
  };

  private handleRedirect = (clientProcessState: IClientProcessViewModel) => {
    this._params = this.props.match.params;
    const url = TaxpayerHelper.getSignFlowRedirectUrl(
      clientProcessState,
      this._params.clientId,
      this._params.controllerId
    );
    if (url && url.trim()) {
      this.props.history.push(url);
    }
    this.setState(
      {
        isPreviewMode: TaxpayerHelper.isPreviewMode(clientProcessState),
        isDelegateeAssigned: clientProcessState.isDelegated,
        isTrialView: TaxpayerHelper.isTrialView(),
        isInOfficeESign: clientProcessState.isInOfficeESign,
        isAccessedFromOneHub: clientProcessState.isAccessedFromOneHub,
      },
      () => {
        logger.trackPageView(`isPreviewMode --> ${this.state.isPreviewMode}`, {
          ClientId: this._params.clientId,
          DocumentId: this.props.taxReturn.id,
          PreviewMode: this.state.isPreviewMode,
        });
        if (this.state.isInOfficeESign) {
          history.pushState(null, "", window.location.href);
          history.back();
          window.onpopstate = () => history.forward();
        }
      }
    );
  };

  signProcessRecoverConfirmation = (lastVisitedStep: SignProcessSteps) => {
    let param: any = this.props.match.params;
    if (
      lastVisitedStep !== SignProcessSteps.SignerProcessComplete &&
      lastVisitedStep !== SignProcessSteps.None &&
      this.props.taxReturn.id > 0 &&
      this.props.taxReturn.signatureStatus.toString() !=
        SignatureStatus[SignatureStatus.ESigned] &&
      this.props.taxReturn.signatureStatus.toString() !=
        SignatureStatus[SignatureStatus.ESignedInOffice] &&
      this.props.taxReturn.signatureStatus.toString() !=
        SignatureStatus[SignatureStatus.Uploaded] &&
      this.props.taxReturn.signatureStatus.toString() !=
        SignatureStatus[SignatureStatus.ManuallySigned] &&
      this.props.signProcessInfo.visitedSteps &&
      this.props.signProcessInfo.visitedSteps.findIndex(
        (x) => x === SignProcessSteps.ManualSign
      ) === NO_INDEX
    ) {
      this._taxpayerSignFlowManager.signProcessRecoverConfirmation(
        lastVisitedStep,
        param.clientId,
        this.props.requestTutorialInfo
      );
    }
  };

  /* TODO Take from props(redux), move the method to SelectSignMethod component */

  handleSetSignMethod = (signatureType: SignatureType) => {
    const param: any = this.props.match.params;

    let tempSignatureSetting: ISignatureSettingsModel = this.state
      .signatureSettings as ISignatureSettingsModel;
    tempSignatureSetting.signatureFormSelectionType = signatureType;

    this.setState({ signatureSettings: tempSignatureSetting }, () => {
      this.props.updateDocumentSignatureSettingModel(
        param.clientId,
        signatureType,
        () => {
          this.updateSettingsAndRefreshTaxDocument(param.clientId);
        }
      );
    });
  };

  setSigningStateForSpouse = () => {
    this.setState(
      { isSignBehalfOfSpouse: true, isSpouseBehalfCheckDisabled: true },
      () => {
        this.props.updateSignBehalfSpouseState();
      }
    );
  };

  updateSettingsAndRefreshTaxDocument = (clientId: string) => {
    this.props.requestTaxDocument(clientId);
    if (
      this.state.signatureSettings.signatureFormSelectionType ===
      SignatureType.ESign
    ) {
      this.goToNextStep();
    } else {
      this.goToStepById(SignProcessSteps.ManualSignDownload.toString());
    }
  };

  private isSpouseSignRequired = (): boolean => {
    let param: any = this.props.match.params;
    return (
      this.props.taxReturn.documentSettings.documentSignatureSetting
        .signatureFormSelectionType == SignatureType.ESign &&
      TaxDocument.isMutual(this.props.taxReturn) &&
      this.props.taxReturn.engagementType == EngagementTypeS.E1040 &&
      TaxpayerHelper.isMarried(this.props.taxReturn) &&
      TaxpayerHelper.isFirstSignerLoggedIn(
        this.props.taxReturn,
        this.props.signerData,
        param.clientId
      ) &&
      !TaxpayerHelper.isNextSignerDeceased(
        this.props.taxReturn,
        this.props.signerData
      ) &&
      !this.state.isSignBehalfOfSpouse
    );
  };

  private isSecondSignerExists = (): boolean => {
    let param: any = this.props.match.params;
    return (
      this.props.taxReturn.documentSettings.documentSignatureSetting
        .signatureFormSelectionType == SignatureType.ESign &&
      TaxDocument.isMutual(this.props.taxReturn) &&
      TaxpayerHelper.isMarried(this.props.taxReturn) &&
      ClientType.Undefined !=
        TaxpayerHelper.getSecondSignerType(
          this.props.taxReturn,
          this.props.signerData,
          param.clientId
        ) &&
      !TaxpayerHelper.isNextSignerDeceased(
        this.props.taxReturn,
        this.props.signerData
      ) &&
      !this.state.isSignBehalfOfSpouse
    );
  };

  private hideSignComplete = (): boolean => {
    if (
      this.props.taxReturn.documentSettings.documentSignatureSetting
        .signatureFormSelectionType != SignatureType.ESign
    ) {
      return true;
    } else {
      if (this.isSecondSignerExists()) {
        return true;
      } else {
        return false;
      }
    }
  };

  private clearState = () => {
    this.setState({
      hideNextButton: false,
      hidePreviousButton: false,
      isNextButtonDisable: false,
    });
  };

  goToStepById = (id: any) => {
    this._wizardRef && this._wizardRef.goToStepById(id);
  };

  thumbNailClick = (stepNumber: number) => {
    switch (stepNumber) {
      case SignProcessSteps.SignMethod:
        if (
          this.props.taxReturn?.documentSettings?.documentSignatureSetting
            ?.signatureFormSelectionType == SignatureType.ESign
        ) {
          if (this._taxpayerSignFlowManager.isSigningProcessCompleted()) {
            if (this.isSpouseSignRequired()) {
              stepNumber = SignProcessSteps.SpouseSignature;
            } else {
              stepNumber = SignProcessSteps.SignComplete;
            }
          }
        } else {
          if (
            this._taxpayerSignFlowManager.isLinkVisited(
              SignProcessSteps.ManualSign
            )
          ) {
            stepNumber = SignProcessSteps.ManualSign;
          } else {
            stepNumber = SignProcessSteps.ManualSignDownload;
          }
        }
        break;
      default:
        break;
    }
    this.goToStepById(stepNumber);
  };

  goToStep = (stepNumber: number) => {
    this._wizardRef && this._wizardRef.goToStep(stepNumber);
  };

  goToNextStep = () => {
    this._wizardRef && this._wizardRef.goToNextStep();
  };

  handleHeaderAndFooterVisibility = (hidden: boolean) => {
    this.setState({ hideNavigationBar: hidden, hideFooter: hidden });
  };

  goToPreviousStep = () => {
    this._wizardRef && this._wizardRef.goToPreviousStep();
  };

  goToCompletionWizard = (isDocSettingsUpdated: boolean = false) => {
    const param: any = this.props.match.params;
    if (isDocSettingsUpdated) {
      this.props.requestDocumentSettings(param.clientId);
    }
    this.props.history.push(
      getRoutePathUrl(
        PathConstants.CompletionWizard,
        param.clientId,
        param.controllerId
      )
    );
  };

  disableNextButton = (value: boolean) => {
    this.setState({ isNextButtonDisable: value });
  };

  onReviewWizardPreviousBtnClick = () => {
    this._reviewRef.onPrevious(this._reviewRef.state.currentStep);
  };

  onReviewWizardNextBtnClick = () => {
    this._reviewRef.onNext(this._reviewRef.state.currentStep);
  };

  onConsentWizardNextBtnClick = (currStep: number) => {
    this._consentRef.onNext(currStep);
  };

  onConsentWizardPreviousBtnClick = (currStep: number) => {
    this._consentRef.onPrevious(currStep);
  };

  onEsignWizardNextBtnClick = (currStep: number) => {
    this._esignRef.onNext(currStep);
  };

  onSpouseSignRequiredNextBtnClick = (currStep: number) => {
    this._spouseSignRequiredRef.onNext(currStep);
  };

  onConsentAgreed = (value: boolean) => {
    this.disableNextButton(!value);
    this.setState({ isConsentAgreed: value });
  };

  onPaperFileConsentWizardNextBtnClick = (currStep: number) => {
    this.goToNextStep();
  };

  getNextStepForDelegatee = () => {
    if (this.props.summaryData.hasPaperFiles === true) {
      if (
        this.props.documentSettings.deliverySettings.paperFilePreparerMessage
          .body
      ) {
        return SignProcessSteps.PaperFileMessage;
      } else {
        return SignProcessSteps.PaperFileList;
      }
    } else if (
      this.props.taxReturn.formGroups.find(
        (x) => x.group === DocumentGroups.K1
      ) !== undefined
    ) {
      return SignProcessSteps.DistributeSelection;
    } else return SignProcessSteps.Pay;
  };

  onPaperFileConsentWizardPreviousBtnClick = (currStep: number) => {
    this._paperFileConsentRef.onPrevious(currStep);
  };

  onPaperFilePrepareMessageNextBtnClick = (currStep: number) => {
    this.goToNextStep();
  };

  onPaperFileListNextBtnClick = (currStep: number) => {
    this.props.updateLastVisitedStep(
      this._params.clientId,
      SignProcessSteps.PaperFileListComplete,
      () => {
        this.goToNextStep();
      }
    );
  };

  private isPaperFileSkipped = (): boolean => {
    let index =
      this.props.signProcessInfo.visitedSteps &&
      this.props.signProcessInfo.visitedSteps.findIndex(
        (x) => x == SignProcessSteps.PaperFileListSkip
      );
    if (index != NO_INDEX) {
      return true;
    }
    return false;
  };

  onPaperFileListPageSkip = () => {
    this.props.updateLastVisitedStep(
      this._params.clientId,
      SignProcessSteps.PaperFileListSkip,
      () => {
        this.goToNextStep();
      }
    );
  };

  onPaperFileListPreviousBtnClick = () => {
    this.goToPreviousStep();
  };

  onSignOnBehalfOfSpouse = (value: boolean) => {
    this.setState({ isSignBehalfOfSpouse: value }, () => {
      this.props.updateSignBehalfSpouseState();
    });
  };

  onEsignComplete = () => {
    this.goToNextStep();
  };

  onK1DistributionNextBtnClick = (currStep: number) => {
    this.goToStepById(SignProcessSteps.Pay.toString());
  };
  onK1DistributionPreviousBtnClick = () => {
    this.goToStepById(SignProcessSteps.DistributeSelection.toString());
  };
  onKBAFailure = () => {
    this.goToStepById(SignProcessSteps.ManualSignDownload.toString());
  };

  private k1DistributionMethodConfirm = (currStep: number) => {
    dialogBox.confirm(
      Common.DialogBox.WarningTitle,
      K1ShareHolderConstants.WarningMessage.K1DistributionWarning,
      K1ShareHolderConstants.WarningMessage.Cancel,
      K1ShareHolderConstants.WarningMessage.Confirm,
      (result: boolean) => {
        result && this.onK1DistributionNextBtnClick(currStep);
      }
    );
  };

  handleSetDistributeMethod = (type: DistributeType) => {
    DistributeType.Manual === type
      ? this.goToStepById(SignProcessSteps.DistributeManual.toString())
      : this.goToStepById(SignProcessSteps.DistributeElectronic.toString());
  };

  handleStepChange = (step: number, stepId: string) => {
    if (this.state.isDraftReturn) {
      return;
    }
    if (
      this.state.isDelegateeAssigned &&
      stepId == SignProcessSteps.Pay.toString()
    ) {
      stepId = SignProcessSteps.SignerProcessComplete.toString();
    }
    this.props.updateLastVisitedStep(this._params.clientId, Number(stepId));
  };

  handleSignCompletion = () => {
    if (this.state.isDraftReturn) {
      return;
    } else {
      this.props.getDocumentTransactions(
        this._params.clientId,
        this.updateSignerProcessCompleteStep
      );
    }
  };

  private updateSignerProcessCompleteStep = (
    documentTransactionList: any[]
  ) => {
    if (
      documentTransactionList.some(
        (x) =>
          (x.eventId == DocumentEvent.SendForSignature ||
            x.eventId == DocumentEvent.Filed) &&
          (x.eventData.clientType ===
            this.props.signerData.find(
              (x) => x.clientGuid == this._params.clientId
            ).signerType ||
            x.eventData.clientType === ClientType.Controller)
      ) ||
      this.props.taxReturn.signatureStatus.toString() ==
        SignatureStatus[SignatureStatus.ManuallySigned] ||
      this.props.taxReturn.signatureStatus.toString() ==
        SignatureStatus[SignatureStatus.SignedAndESigned] ||
      this.props.taxReturn.signatureStatus.toString() ==
        SignatureStatus[SignatureStatus.AwaitingUpload]
    )
      this.props.updateLastVisitedStep(
        this._params.clientId,
        SignProcessSteps.SignerProcessComplete,
        () => {
          this._payRef && this._payRef.updateVoucherReminderDetails();
        }
      );
    else this._payRef && this._payRef.updateVoucherReminderDetails();
  };

  handleSubmit = () => {
    if (this.state.voucherState === "estimated" && this.state.isDraftReturn) {
      this.props.updateDraftReturnSignatureStatus(
        this._params.clientId,
        SignatureStatus.DraftApproved,
        () => {
          this.props.history.push(
            `/taxreturnstatus/${TaxReturnStatus.DraftApproved}`
          );
        }
      );
      return;
    }

    const hasValidVouchers =
      this.props.taxReturn &&
      TaxpayerHelper.isVoucherExist(this.props.taxReturn);
    if (hasValidVouchers && this.state.voucherState === "payment") {
      this._payRef &&
        this._payRef.updateVoucherReminderDetails(() => {
          return;
        });

      return this.setState({
        voucherState: "estimated",
        voucherFlow: "forward",
      });
    } else {
      this.handleSignCompletion();
    }
  };

  handleCustomSubmit = () => {
    this.props.updateDraftReturnSignatureStatus(
      this._params.clientId,
      SignatureStatus.DraftRejected,
      () => {
        this.props.history.push(
          `/taxreturnstatus/${TaxReturnStatus.DraftRejected}`
        );
      }
    );
  };

  handleSubmitForNoVoucher = () => {
    if (this.state.voucherState === "payment") {
      if (this.state.voucherFlow === "forward") {
        return this.setState({ voucherState: "estimated" });
      } else {
        return this.setState({ voucherFlow: "forward" }, () => {
          this.goToPreviousStep();
        });
      }
    } else {
      this.handleSignCompletion();
    }
  };

  disableNavigationLink = (step: SignProcessSteps) => {
    let index = NO_INDEX;
    index =
      this.props.signProcessInfo.visitedSteps &&
      this.props.signProcessInfo.visitedSteps.indexOf(step);
    if (
      index == null ||
      (index != null && index === NO_INDEX) ||
      this.state.isDelegateeAssigned
    ) {
      return true;
    } else {
      return false;
    }
  };

  confirmRedirectToPay = () => {
    const param: any = this.props.match.params;
    if (this.props.manualSignData.length > 0) {
      logger.trackTrace("Taxpayer Manual sign completion pop up opened", {
        ClientId: param.clientId,
        DocumentId: this.props.taxReturn.id,
        PreviewMode: this.state.isPreviewMode,
      });
      dialogBox.confirm(
        Common.DialogBox.ConfirmationTitle,
        ManualSignConstants.ConfirmMessage.replace(
          "<COMPANYNAME>",
          this.props.headInfoViewModel.companyName
        ),
        Common.DialogBox.Cancel,
        Common.DialogBox.Confrim,
        (result: boolean) => {
          logger.trackTrace("Taxpayer Manual sign completion pop up result ", {
            ClientId: param.clientId,
            DocumentId: this.props.taxReturn.id,
            Result: result,
            PreviewMode: this.state.isPreviewMode,
          });
          result && this._manualSign && this._manualSign.completeManualSign();
        }
      );
    } else {
      this.goToNextStep();
    }
  };

  showCCRecipientModal = () => {
    this.setState({ showCCModal: true });
  };

  closeCCRecipientModal = () => {
    this.setState({ showCCModal: false });
  };

  saveVoucherPayment = (voucher: IVoucher) => {
    const param: any = this.props.match.params;
    if (voucher !== null && voucher !== undefined) {
      if (!voucher.isPaid) {
        this.props.addVoucherPayemnt(
          param.clientId,
          voucher,
          this.voucherPaymentUpdated
        );
      } else {
        this.props.updateVoucherPayemnt(
          param.clientId,
          voucher,
          this.voucherPaymentUpdated
        );
      }
    }
  };

  openInvoiceUrl = () => {
    window.open(this.props.companyData.onlinePaymentUrl, "_blank");
  };

  showCustomBtnInReviewStep = () => {
    if (
      this.props.documentReviewModel.find(
        (x) => x.documentGroup == DocumentGroups.Invoice
      ) != undefined
    ) {
      if (
        !this.props.companyData.onlinePaymentUrl ||
        this.props.taxReturn.invoiceAmount == 0
      ) {
        return false;
      }
      return true;
    }
    return true;
  };

  downloadAllDocuments = () => {
    const param: any = this.props.match.params;
    let documentGroups: number[] = [];
    this.props.ccRecipientDownloadableDocuments.forEach((document, i) => {
      documentGroups.push(document.documentGroup);
    });
    this.props.downloadSelectedDocuments(param.clientId, documentGroups);
  };

  downloadSelectedDocuments = (documentGroups: number[]) => {
    const param: any = this.props.match.params;
    this.props.downloadSelectedDocuments(param.clientId, documentGroups);
  };

  saveDelegateeState = () => {
    this.setState({ isDelegateeAssigned: true });
  };

  handlePayPreviousBtn = () => {
    if (this.state.isDelegateeAssigned) {
      return;
    }

    let self = this;
    this.setState(
      {
        voucherFlow: "forward",
      },
      () => {
        self.goToPreviousStep();
      }
    );
  };

  clearPaymentDetails = (voucher: IVoucher) => {
    const param: any = this.props.match.params;
    if (voucher !== null && voucher !== undefined) {
      this.props.clearPaymentDetails(
        param.clientId,
        voucher,
        this.voucherPaymentUpdated
      );
    }
  };

  voucherPaymentUpdated = () => {
    this.setState({ isPaymentDetailsUpdated: true });
  };

  setVoucherState = (voucherStateValue: string) => {
    this.setState({ voucherState: voucherStateValue });
  };

  handleBackVoucher = () => {
    this.setState({ voucherState: "payment", voucherFlow: "backward" });
  };

  setEstimated = () => {
    this.setState({ voucherState: "estimated", voucherFlow: "forward" });
  };

  private setLoader() {
    if (document.getElementById("overlay-loader")) {
      loader.show();
      return;
    }
    return <OverlayLoader />;
  }

  private handleConsent = (
    consentGiven: boolean,
    countryCodeInput?: string,
    mobileNumberInput?: string
  ) => {
    let param: any = this.props.match.params;
    const consentDetails: ISMSPUTRequest = {
      clientId: this.state.clientId,
      clientGuid: param.clientId,
      clientType: this.state.clientType,
      countryCode: consentGiven
        ? countryCodeInput
        : this.state.initialCountryCode,
      mobileNumber: consentGiven
        ? mobileNumberInput
        : this.state.initialMobileNumber,
      isConsent: consentGiven,
    };
    updateConsentDetails(consentDetails, () => {
      this.setState({ showConsentPopUp: false });
      this.props.requestTaxDocument(param.clientId);
    });
  };

  public render() {
    const { hideNavigationBar, hideFooter, isPreviewMode, isTrialView } =
      this.state;

    const {
      companyData: {
        name,
        taxpayerPaymentSettings: { payOption },
      },
    } = this.props;
    const type =
      this.props.taxReturn &&
      GetK1EngagementType(this.props.taxReturn.engagementType);

    const isK1Sent =
      Array.isArray(this.props.k1Data) &&
      this.props.k1Data.filter((x) => x.k1SendDate != undefined);

    const payButton = showPayNow(
      this.props.companyData,
      this.props.paymentData,
      this.props.taxReturn.invoiceAmount
    );

    const buttonName = buttonNameChange(
      this.props.paymentData,
      this.props.taxReturn.invoiceAmount,
      this.props.companyData,
      this.props.documentSettings.documentDisplaySetting
    );

    const invoiceIncluded =
      this.props.documentReviewModel.find(
        (x) => x.documentGroup == DocumentGroups.Invoice
      ) !== undefined;

    const completedPercentage = PdfViewManager.getControlValueUpdatedPercentage(
      this.props.signatureControlsData
    );

    const param: any = this.props.match.params;
    const SignStepIcon = Common.Images.SignStepIcon;

    const hasValidVouchers =
      this.props.taxReturn &&
      TaxpayerHelper.isVoucherExist(this.props.taxReturn);

    return (
      <Wizard
        hideTopNavigation={hideNavigationBar}
        hideFooter={hideFooter}
        nextButtonAutomationTestId={"FA6F3407-F80B-4FCD-87A5-C094ECF286EC"}
        previousButtonAutomationTestId={"D945DDEF-3701-4155-9529-1C7BDECA4A29"}
        submitLabel={
          this.state.isDraftReturn
            ? this.state.voucherState === "payment"
              ? Common.Wizard.NextButtonLabel
              : Common.Wizard.ApproveDraftReturn
            : this.state.voucherState === "payment" ||
              (this.state.voucherState === "estimated" &&
                this.state.isAccessedFromOneHub)
            ? Common.Wizard.NextButtonLabel
            : "Done"
        }
        ref={(ref: any) => {
          this._wizardRef = ref;
          this._taxpayerSignFlowManager = TaxpayerSignFlowManager.create(
            ref,
            this.props
          );
        }}
        onStepChange={this.handleStepChange}
        onSubmit={this.handleSubmit}
        disableMobileResponsiveness={true}
        submitIcon={
          this.state.isDraftReturn ? (
            this.state.voucherState === "payment" ? (
              <NextButtonIcon />
            ) : (
              <></>
            )
          ) : this.state.voucherState === "payment" ||
            (this.state.voucherState === "estimated" &&
              this.state.isAccessedFromOneHub) ? (
            <NextButtonIcon />
          ) : (
            <></>
          )
        }
        mainStepTabIndex={0}
        submitBtnVariant={
          this.state.isDraftReturn
            ? this.state.voucherState === "payment"
              ? "primary"
              : "success"
            : this.state.voucherState === "payment" ||
              (this.state.voucherState === "estimated" &&
                this.state.isAccessedFromOneHub)
            ? "primary"
            : "success"
        }
        showLastStepCustomSubmitBtn={
          this.state.voucherState === "estimated" && this.state.isDraftReturn
        }
        lastStepCustomSubmitBtnLabel="Request Changes"
        lastStepCustomSubmitBtnVariant="danger"
        lastStepCustomSubmitBtnOnClick={this.handleCustomSubmit}
        lastStepCustomSubmitBtnClassname={
          this.state.isDraftReturn && "last-step-buttons"
        }
        submitBtnClassname={
          this.state.voucherState === "estimated" &&
          this.state.isDraftReturn &&
          "last-step-buttons"
        }
      >
        {this.props.taxReturn.id > 0 && (
          <Step
            id={SignProcessSteps.Summary.toString()}
            tooltip={"Summary"}
            name={"Summary"}
            template={<TemplateIcon />}
            hideNextButton={true}
            hidePreviousButton={true}
            previousLabel={Common.Wizard.PreviousButtonLabel}
            disableNavigationClick={this.state.isDelegateeAssigned}
            nextLabel={Common.Wizard.NextButtonLabel}
            previousButtonIcon={<PreviousButtonIcon />}
            nextButtonIcon={<NextButtonIcon />}
          >
            <SummaryWizard.WizardBody
              commonData={this.props.commonData}
              match={this.props.match}
              history={this.props.history}
              summaryData={this.props.summaryData}
              requestSummaryDetails={this.props.requestSummaryDetails}
              goToStep={this.goToStep}
              showPaymentRefundPrint={false}
              signProcessInfo={this.props.signProcessInfo}
              goToStepById={this.thumbNailClick}
              tutorialInfo={this.props.tutorialInfo}
              hideSummaryTutorial={this.props.hideSummaryTutorial}
              headerInfo={this.props.headInfoViewModel}
              prepareMessageExist={
                this.props.documentSettings.deliverySettings.preparerMessage
                  .body
                  ? true
                  : false
              }
              goToNextStep={this.goToNextStep}
              isPreviewMode={this.state.isPreviewMode}
              isDraftReturn={this.state.isDraftReturn}
            />
          </Step>
        )}
        {this.props.taxReturn.id > 0 && (
          <Step
            id={SignProcessSteps.PreparerMessage.toString()}
            disableNavigationClick={true}
            previousLabel={Common.Wizard.PreviousButtonLabel}
            nextLabel={Common.Wizard.NextButtonLabel}
            hidden={
              !this.props.documentSettings.deliverySettings.preparerMessage.body
            }
            previousButtonIcon={<PreviousButtonIcon />}
            nextButtonIcon={<NextButtonIcon />}
          >
            <PreparerMessageWizard.PreparerMessageWizardBody
              match={this.props.match}
              preparerMessage={
                this.props.documentSettings.deliverySettings.preparerMessage
                  .body
              }
              headerInfo={this.props.headInfoViewModel}
              isDelegatee={false}
              isPreviewMode={this.state.isPreviewMode}
            />
          </Step>
        )}
        {this.props.taxReturn.id > 0 && (
          <Step
            id={SignProcessSteps.Invoice.toString()}
            name={"Review"}
            tooltip={"Review"}
            template={<TemplateIcon />}
            preventNextButtonDefaultBehaviour={
              this.props.documentReviewModel.find(
                (x) => x.documentGroup == DocumentGroups.Invoice
              ) == undefined
            }
            preventPreviousButtonDefaultBehaviour={
              this.props.documentReviewModel.find(
                (x) => x.documentGroup == DocumentGroups.Invoice
              ) == undefined
            }
            onNext={this.onReviewWizardNextBtnClick}
            hidden={hideReviewStep(this.props.documentReviewModel)}
            onPrevious={this.onReviewWizardPreviousBtnClick}
            disableNavigationClick={this.disableNavigationLink(
              SignProcessSteps.Invoice
            )}
            previousLabel={Common.Wizard.PreviousButtonLabel}
            hideScrollbar={true}
            nextLabel={
              invoiceIncluded && buttonName
                ? "Pay Later & Continue"
                : Common.Wizard.NextButtonLabel
            }
            showCustomButton={invoiceIncluded ? payButton : false}
            disableCustomButton={this.state.isDraftReturn}
            customButtonLabel={
              invoiceIncluded && isAnyPaySettingsEnabled(this.props.companyData)
                ? Common.Wizard.PayNow
                : ""
            }
            onCustomButtonClick={
              invoiceIncluded && isStripeEnabled(this.props.companyData)
                ? () => this._paymentRef.onPayNowClick()
                : isAttachedUrlEnabled(this.props.companyData)
                ? this.openInvoiceUrl
                : undefined
            }
            disableNextButton={
              invoiceIncluded && !this.state.isDraftReturn
                ? hidePayLater(
                    this.props.companyData,
                    this.props.documentSettings.documentDisplaySetting,
                    this.props.paymentData,
                    this.props.taxReturn.invoiceAmount
                  )
                : false
            }
            previousButtonIcon={<PreviousButtonIcon />}
            nextButtonIcon={<NextButtonIcon />}
          >
            {this.props.documentReviewModel.find(
              (x) => x.documentGroup == DocumentGroups.Invoice
            ) != undefined ? (
              <InvoiceWizard.Invoice
                ref={(pay) => (this._paymentRef = pay)}
                invoiceDocumentReviewModel={this.props.documentReviewModel}
                match={this.props.match}
                invoiceAmount={this.props.taxReturn.invoiceAmount}
                paymentData={this.props.paymentData}
                requestPaymentIntent={this.props.requestPaymentIntent}
                paymentSetting={this.props.companyData.taxpayerPaymentSettings}
                updatePurchaseTransactionStatus={
                  this.props.updatePurchaseTransactionStatus
                }
                downloadAllDocuments={this.downloadAllDocuments}
                downloadSelectedDocuments={this.downloadSelectedDocuments}
                ccRecipientDownloadableDocuments={
                  this.props.ccRecipientDownloadableDocuments
                }
                showDownloadButton={false}
                isPreviewMode={this.state.isPreviewMode}
                isDraftReturn={this.state.isDraftReturn}
              />
            ) : (
              <ReviewWizard.ReviewWizardBody
                onHeaderAndFooterVisibility={
                  this.handleHeaderAndFooterVisibility
                }
                ref={(ref: any) => {
                  this._reviewRef = ref;
                }}
                documentReviewModel={this.props.documentReviewModel}
                downloadAttachment={this.props.downloadIndividualAttachment}
                downloadAllAttachments={this.props.downloadAllAttachments}
                match={this.props.match}
                nextStep={this.goToNextStep}
                prevStep={this.goToPreviousStep}
                hideTitle={hideNavigationBar && hideFooter}
                tutorialInfo={this.props.tutorialInfo}
                hideReviewTutorial={this.props.hideReviewTutorial}
                showModal={this.state.showCCModal}
                closeCCRecipientModal={this.closeCCRecipientModal}
                ccRecipientDownloadableDocuments={
                  this.props.ccRecipientDownloadableDocuments
                }
                taxdocumentId={this.props.taxReturn.id}
                forwardDocumentsToRecipients={
                  this.props.forwardDocumentsToRecipients
                }
                downloadAllDocuments={this.downloadAllDocuments}
                downloadSelectedDocuments={this.downloadSelectedDocuments}
                showDownloadButton={true}
                isPreviewMode={false}
                showCCRecipientModal={this.showCCRecipientModal}
                summaryData={this.props.summaryData}
                updatePaperFileConsent={this.props.updatePaperFileConsent}
                headerInfo={this.props.headInfoViewModel}
                skipButtonText={PaperFileReturnConstants.SkipToNextButtonText}
                isDraftReturn={this.state.isDraftReturn}
              />
            )}
          </Step>
        )}
        {this.props.taxReturn.id > 0 && (
          <Step
            id={SignProcessSteps.Review.toString()}
            preventNextButtonDefaultBehaviour={true}
            preventPreviousButtonDefaultBehaviour={true}
            onNext={this.onReviewWizardNextBtnClick}
            onPrevious={this.onReviewWizardPreviousBtnClick}
            hidden={
              this.props.documentReviewModel.find(
                (x) => x.documentGroup == DocumentGroups.Invoice
              ) == undefined
            }
            disableNavigationClick={true}
            previousLabel={Common.Wizard.PreviousButtonLabel}
            nextLabel={Common.Wizard.NextButtonLabel}
            hideScrollbar={true}
            previousButtonIcon={<PreviousButtonIcon />}
            nextButtonIcon={<NextButtonIcon />}
          >
            <ReviewWizard.ReviewWizardBody
              ref={(ref: any) => {
                this._reviewRef = ref;
              }}
              onHeaderAndFooterVisibility={this.handleHeaderAndFooterVisibility}
              documentReviewModel={this.props.documentReviewModel}
              downloadAttachment={this.props.downloadIndividualAttachment}
              downloadAllAttachments={this.props.downloadAllAttachments}
              match={this.props.match}
              nextStep={this.goToNextStep}
              prevStep={this.goToPreviousStep}
              hideTitle={hideNavigationBar && hideFooter}
              tutorialInfo={this.props.tutorialInfo}
              hideReviewTutorial={this.props.hideReviewTutorial}
              showModal={this.state.showCCModal}
              closeCCRecipientModal={this.closeCCRecipientModal}
              ccRecipientDownloadableDocuments={
                this.props.ccRecipientDownloadableDocuments
              }
              taxdocumentId={this.props.taxReturn.id}
              forwardDocumentsToRecipients={
                this.props.forwardDocumentsToRecipients
              }
              downloadAllDocuments={this.downloadAllDocuments}
              downloadSelectedDocuments={this.downloadSelectedDocuments}
              showDownloadButton={true}
              isPreviewMode={false}
              showCCRecipientModal={this.showCCRecipientModal}
              summaryData={this.props.summaryData}
              updatePaperFileConsent={this.props.updatePaperFileConsent}
              headerInfo={this.props.headInfoViewModel}
              skipButtonText={PaperFileReturnConstants.SkipToNextButtonText}
              isDraftReturn={this.state.isDraftReturn}
            />
          </Step>
        )}

        {this.props.taxReturn.id > 0 && (
          <Step
            id={SignProcessSteps.SignMethod.toString()}
            name={"Sign"}
            tooltip={"Sign"}
            template={<TemplateIcon />}
            hidden={
              !(
                this.props.taxReturn.documentSettings?.documentSignatureSetting
                  .signatureFormSelectionType == SignatureType.ESign &&
                !this._taxpayerSignFlowManager.isSigningProcessCompleted()
              )
            }
            hideNextButton={true}
            hidePreviousButton={false}
            onPrevious={this.onConsentWizardPreviousBtnClick}
            disableNavigationClick={this.disableNavigationLink(
              SignProcessSteps.SignMethod
            )}
            previousLabel={Common.Wizard.PreviousButtonLabel}
            nextLabel={Common.Wizard.NextButtonLabel}
            previousButtonIcon={<PreviousButtonIcon />}
            nextButtonIcon={<NextButtonIcon />}
          >
            <SelectSignMethod
              onSetSignMethod={this.handleSetSignMethod}
              clearParentState={this.clearState}
              match={this.props.match}
              history={this.props.history}
              taxReturn={this.props.taxReturn}
              saveDelegateeDetails={this.props.assignToDelegatee}
              isDelegateeAssigned={this.state.isDelegateeAssigned}
              goToStep={this.goToStepById}
              nextStep={this.getNextStepForDelegatee()}
              saveDelegateeState={this.saveDelegateeState}
              isPreviewMode={false}
              checkIfBothSignerDeceased={this.checkIfBothSignerDeceased}
              signerData={this.props.signerData}
              updateSpouseMailChange={this.props.updateSpouseMail}
              updateSpouseMobileChange={this.props.updateSpouseMobileNumber}
              refreshTaxDocument={this.props.requestTaxDocument}
              isDraftReturn={this.state.isDraftReturn}
            />
          </Step>
        )}

        {this.props.taxReturn.id > 0 && (
          <Step
            id={SignProcessSteps.EsignConsentEmail.toString()}
            hidden={
              !(
                this.props.taxReturn.documentSettings?.documentSignatureSetting
                  .signatureFormSelectionType == SignatureType.ESign &&
                isIndividualEngagementType(
                  this.props.taxReturn.engagementType
                ) &&
                !this._taxpayerSignFlowManager.isSigningProcessCompleted()
              )
            }
            disableNextButton={this.state.isNextButtonDisable}
            onNext={this.onConsentWizardNextBtnClick}
            onPrevious={this.onConsentWizardPreviousBtnClick}
            preventNextButtonDefaultBehaviour={true}
            preventPreviousButtonDefaultBehaviour={false}
            disableNavigationClick={true}
            previousLabel={Common.Wizard.PreviousButtonLabel}
            nextLabel={Common.Wizard.NextButtonLabel}
            previousButtonIcon={<PreviousButtonIcon />}
            nextButtonIcon={<NextButtonIcon />}
          >
            <EsignConsentEmail
              ref={(ref: any) => {
                this._consentRef = ref;
              }}
              history={this.props.history}
              match={this.props.match}
              isPreviewMode={this.state.isPreviewMode}
              updateSpouseMailChange={this.props.updateSpouseMail}
              updateDOBChange={this.props.updateDOB}
              individual={
                this.props.taxReturn as TaxDocument.IIndividualTaxReturn
              }
              mutual={
                this.props.taxReturn as TaxDocument.IMarriedJointTaxReturn
              }
              consent={this.state.isConsentAgreed}
              onConsentAgreed={this.onConsentAgreed}
              nextStep={this.goToNextStep}
              prevStep={this.goToPreviousStep}
              refreshTaxDocument={this.props.requestTaxDocument}
              signerData={this.props.signerData}
              disableNextButton={this.disableNextButton}
              clearParentState={this.clearState}
              signBehalfSpouse={this.state.isSignBehalfOfSpouse}
              onSignOnBehalfOfSpouse={this.onSignOnBehalfOfSpouse}
              updateSpouseMobileChange={this.props.updateSpouseMobileNumber}
              setSigningStateForSpouse={this.setSigningStateForSpouse}
              isSpouseBehalfCheckDisabled={
                this.state.isSpouseBehalfCheckDisabled
              }
            />
          </Step>
        )}

        {this.props.taxReturn.id > 0 && (
          <Step
            id={SignProcessSteps.KBA.toString()}
            hidden={
              !(
                this.props.taxReturn.documentSettings?.documentSignatureSetting
                  .signatureFormSelectionType == SignatureType.ESign &&
                this.props.taxReturn.engagementType == EngagementTypeS.E1040 &&
                !this.state.isInOfficeESign &&
                !this.props.isKBAPassed &&
                this.props.documentSettings?.documentSignatureSetting
                  .knowledgeBasedAuthentication &&
                !(param.controllerId != null && param.controllerId != "")
              )
            }
            hideNextButton={true}
            hidePreviousButton={
              this.props.kbaData.transactionStatus.toString() ===
              KBATransactionResultType[KBATransactionResultType.error]
                ? false
                : true
            }
            disableNavigationClick={true}
            previousLabel={Common.Wizard.PreviousButtonLabel}
            nextLabel={Common.Wizard.NextButtonLabel}
            previousButtonIcon={<PreviousButtonIcon />}
            nextButtonIcon={<NextButtonIcon />}
          >
            <KBAComponent
              match={this.props.match}
              loadKBAQuestions={this.props.clientAuthentication}
              validateKBAAnswers={this.props.clientValidation}
              response={this.props.kbaData}
              clearParentState={this.clearState}
              refreshKBAStatus={this.props.requestKBAStatus}
              onCompletion={this.goToNextStep}
              updateDocumentSignatureSettingModel={
                this.props.updateDocumentSignatureSettingModel
              }
              onKBAFailure={this.onKBAFailure}
              refreshTaxDocument={this.props.requestTaxDocument}
              updateLastVisitedStep={this.props.updateLastVisitedStep}
              isPreviewMode={this.state.isPreviewMode}
            />
          </Step>
        )}

        {this.props.taxReturn.id > 0 && (
          <Step
            id={SignProcessSteps.Esign.toString()}
            hidden={
              !(
                this.props.taxReturn.documentSettings?.documentSignatureSetting
                  .signatureFormSelectionType == SignatureType.ESign &&
                !this._taxpayerSignFlowManager.isSigningProcessCompleted()
              )
            }
            disableNextButton={
              this.state.isDraftReturn
                ? false
                : completedPercentage < 100 || this.state.isNextButtonDisable
            }
            disableNavigationClick={true}
            preventNextButtonDefaultBehaviour={true}
            onNext={this.onEsignWizardNextBtnClick}
            previousLabel={Common.Wizard.PreviousButtonLabel}
            nextLabel={Common.Wizard.NextButtonLabel}
            hideScrollbar={true}
            previousButtonIcon={<PreviousButtonIcon />}
            nextButtonIcon={<NextButtonIcon />}
          >
            <Esign
              ref={(ref: any) => {
                this._esignRef = ref;
              }}
              match={this.props.match}
              history={this.props.history}
              isPreviewMode={this.state.isPreviewMode}
              isDraftReturn={this.state.isDraftReturn}
              onHeaderAndFooterVisibility={this.handleHeaderAndFooterVisibility}
              requestSignatureControls={
                this.state.isDraftReturn
                  ? this.props.requestPreviewSignatureControls
                  : this.props.requestSignatureControls
              }
              requestSignBehalfSpouseSignatureControls={
                this.state.isDraftReturn
                  ? this.props.requestSignBehalfSpousePreviewSignatureControls
                  : this.props.requestSignBehalfSpouseSignatureControls
              }
              signatureControlsData={this.props.signatureControlsData}
              onSigningComplete={this.props.updateSignatureControlsData}
              disableNextButton={this.disableNextButton}
              completeSigningStep={this.onEsignComplete}
              isSpouseSignRequired={this.isSpouseSignRequired()}
              sign={this.state.isDraftReturn ? undefined : this.props.sign}
              signOnBehalfSpouse={this.props.signOnBehalfSpouse}
              headerInfo={this.props.headInfoViewModel}
              engagementType={this.props.taxReturn.engagementType}
              signBehalfSpouse={this.state.isSignBehalfOfSpouse}
              mutual={
                this.props.taxReturn as TaxDocument.IMarriedJointTaxReturn
              }
              signerData={this.props.signerData}
              changedSignBehalfSpouse={this.props.changedSignBehalfSpouse}
              updateChangedSignBehalfSpouseState={
                this.props.updateSignBehalfSpouseState
              }
            />
          </Step>
        )}

        {this.props.taxReturn.id > 0 && (
          <Step
            id={SignProcessSteps.SpouseSignature.toString()}
            name={"Sign"}
            tooltip={"Sign"}
            template={
              this._taxpayerSignFlowManager.isSigningProcessCompleted() &&
              this.isSpouseSignRequired() && <TemplateIcon />
            }
            hidden={!this.isSpouseSignRequired()}
            disableNavigationClick={true}
            preventNextButtonDefaultBehaviour={true}
            onNext={this.onSpouseSignRequiredNextBtnClick}
            previousLabel={Common.Wizard.PreviousButtonLabel}
            nextLabel={Common.Wizard.NextButtonLabel}
            previousButtonIcon={<PreviousButtonIcon />}
            nextButtonIcon={<NextButtonIcon />}
          >
            <SpouseSignRequired
              ref={(ref: any) => {
                this._spouseSignRequiredRef = ref;
              }}
              companyName={this.props.headInfoViewModel.companyName}
              refreshTaxDocument={this.props.requestTaxDocument}
              mutual={
                this.props.taxReturn as TaxDocument.IMarriedJointTaxReturn
              }
              match={this.props.match}
              isPreviewMode={this.state.isPreviewMode}
              updateSpouseMailChange={this.props.updateSpouseMail}
              signatureControlsData={this.props.signatureControlsData}
              sign={this.props.sign}
              signOnBehalfSpouse={this.props.signOnBehalfSpouse}
              gotoNextStep={this.goToNextStep}
              signerData={this.props.signerData}
              signingCompleted={() =>
                this._taxpayerSignFlowManager.isSigningProcessCompleted()
              }
              updateSpouseMobileChange={this.props.updateSpouseMobileNumber}
              signBehalfSpouse={this.state.isSignBehalfOfSpouse}
            />
          </Step>
        )}

        {this.props.taxReturn.id > 0 && !this.state.isDraftReturn && (
          <Step
            id={SignProcessSteps.SignComplete.toString()}
            name={"Sign"}
            tooltip={"Sign"}
            template={
              this._taxpayerSignFlowManager.isSigningProcessCompleted() && (
                <TemplateIcon />
              )
            }
            hidden={this.hideSignComplete()}
            disableNavigationClick={true}
            previousLabel={Common.Wizard.PreviousButtonLabel}
            nextLabel={Common.Wizard.NextButtonLabel}
            previousButtonIcon={<PreviousButtonIcon />}
            nextButtonIcon={<NextButtonIcon />}
          >
            <SignatureCompletedPage
              match={this.props.match}
              companyName={this.props.headInfoViewModel.companyName}
              isPreviewMode={this.state.isPreviewMode}
            />
          </Step>
        )}
        {!this._taxpayerSignFlowManager.isLinkVisited(
          SignProcessSteps.ManualSign
        )
          ? this.props.taxReturn.id > 0 && (
              <Step
                id={SignProcessSteps.ManualSignDownload.toString()}
                name={"Sign"}
                tooltip={"Sign"}
                template={<TemplateIcon />}
                disableNextButton={true}
                disableNavigationClick={this.disableNavigationLink(
                  SignProcessSteps.ManualSignDownload
                )}
                previousLabel={Common.Wizard.PreviousButtonLabel}
                nextLabel={Common.Wizard.NextButtonLabel}
                hidden={
                  !(
                    this.props.taxReturn.documentSettings
                      ?.documentSignatureSetting.signatureFormSelectionType ==
                    SignatureType.ManualSign
                  ) ||
                  this._taxpayerSignFlowManager.isLinkVisited(
                    SignProcessSteps.ManualSign
                  )
                }
                previousButtonIcon={<PreviousButtonIcon />}
                nextButtonIcon={<NextButtonIcon />}
              >
                <ManualSignDownloadFiles
                  downloadEfileForms={this.props.downloadEfileForms}
                  clientId={param.clientId}
                  goToNextStep={this.goToNextStep}
                  additionalEsignDocuments={this.props.additionalEsignDocuments}
                  downloadAllAdditionEsignDocuments={
                    this.props.downloadAllAdditionEsignDocuments
                  }
                  clientName={this.props.headInfoViewModel.clientName}
                  taxReturn={this.props.taxReturn}
                  isPreviewMode={false}
                  updateLastVisitedStep={this.props.updateLastVisitedStep}
                />
              </Step>
            )
          : this.props.taxReturn.id > 0 && (
              <Step
                id={SignProcessSteps.ManualSign.toString()}
                name={"Sign"}
                tooltip={"Sign"}
                template={
                  this._taxpayerSignFlowManager.isLinkVisited(
                    SignProcessSteps.ManualSign
                  ) && <TemplateIcon />
                }
                hidden={
                  !(
                    this.props.taxReturn.documentSettings
                      ?.documentSignatureSetting.signatureFormSelectionType ==
                    SignatureType.ManualSign
                  )
                }
                preventNextButtonDefaultBehaviour={true}
                previousLabel={Common.Wizard.PreviousButtonLabel}
                nextLabel={Common.Wizard.NextButtonLabel}
                disableNextButton={this.state.isNextButtonDisable}
                onNext={this.confirmRedirectToPay}
                disableNavigationClick={this.disableNavigationLink(
                  SignProcessSteps.ManualSign
                )}
                previousButtonIcon={<PreviousButtonIcon />}
                nextButtonIcon={<NextButtonIcon />}
              >
                <StepLayout className="manual-sign-stepper">
                  <Body
                    title={ManualSignConstants.Title}
                    lg={8}
                    md={12}
                    sm={12}
                    xs={12}
                    containerClassName={"pt-5"}
                    automationTestId={"11BB28B5-42F3-4571-B567-20700B1740B6"}
                  >
                    <ManualSign
                      match={this.props.match}
                      downloadEfileForms={this.props.downloadEfileForms}
                      addSignedDocument={this.props.addSignedDocument}
                      deleteSignedDocument={this.props.deleteSignedDocument}
                      requestSignedDocuments={this.props.requestSignedDocuments}
                      signedDocuments={this.props.manualSignData}
                      companyInfo={this.props.companyData}
                      requestCompanyDetails={this.props.requestCompanyDetails}
                      completeManualSign={this.props.completeManualSign}
                      onCompletion={this.goToNextStep}
                      ref={(ref: any) => {
                        this._manualSign = ref;
                      }}
                      additionalEsignDocuments={
                        this.props.additionalEsignDocuments
                      }
                      downloadAllAdditionEsignDocuments={
                        this.props.downloadAllAdditionEsignDocuments
                      }
                      requestAdditionalEsignDocument={
                        this.props.requestAdditionalEsignDocument
                      }
                      clientName={this.props.headInfoViewModel.clientName}
                      taxReturn={this.props.taxReturn}
                      isDelegatee={false}
                      isPreviewMode={false}
                      disableNextButton={this.disableNextButton}
                    />
                  </Body>
                </StepLayout>
              </Step>
            )}

        {this.props.taxReturn.id > 0 &&
          this.props.summaryData.hasPaperFiles === true && (
            <Step
              id={SignProcessSteps.PaperFileMessage.toString()}
              name={"PaperFile"}
              tooltip={"Print and Mail Paper File Returns"}
              template={
                this.props.documentSettings.deliverySettings
                  .paperFilePreparerMessage.body && <TemplateIcon />
              }
              hidden={
                !this.props.summaryData.hasPaperFiles ||
                !this.props.documentSettings.deliverySettings
                  .paperFilePreparerMessage.body
              }
              preventNextButtonDefaultBehaviour={true}
              previousLabel={Common.Wizard.PreviousButtonLabel}
              nextLabel={Common.Wizard.NextButtonLabel}
              disableNextButton={false}
              disableNavigationClick={this.disableNavigationLink(
                SignProcessSteps.PaperFileMessage
              )}
              onNext={this.onPaperFilePrepareMessageNextBtnClick}
              disablePreviousButton={this.state.isDelegateeAssigned}
              previousButtonIcon={<PreviousButtonIcon />}
              nextButtonIcon={<NextButtonIcon />}
            >
              <PaperFileMessageWizard.PaperFileMessageWizardBody
                preparerMessage={
                  this.props.documentSettings.deliverySettings
                    .paperFilePreparerMessage.body
                }
                headerInfo={this.props.headInfoViewModel}
                isDelegatee={false}
                match={this.props.match}
                isPreviewMode={this.state.isPreviewMode}
                isDraftReturn={this.state.isDraftReturn}
              />
            </Step>
          )}
        {this.props.taxReturn.id > 0 &&
          this.props.summaryData.hasPaperFiles === true && (
            <Step
              id={SignProcessSteps.PaperFileList.toString()}
              name={"PaperFile"}
              tooltip={"Print and Mail Paper File Returns"}
              hidden={!this.props.summaryData.hasPaperFiles}
              template={
                !this._taxpayerSignFlowManager.isLinkVisited(
                  SignProcessSteps.PaperFileMessage
                ) &&
                !this.props.documentSettings.deliverySettings
                  .paperFilePreparerMessage.body && <TemplateIcon />
              }
              showCustomButton={!this.isPaperFileSkipped()}
              customButtonIcon={""}
              customButtonLabel={PaperFileReturnConstants.SkipToNextButtonText}
              onCustomButtonClick={() =>
                this._paperfileListRef.onSkipandContinue()
              }
              enableCustomButton={false}
              disableNavigationClick={this.disableNavigationLink(
                SignProcessSteps.PaperFileList
              )}
              previousLabel={Common.Wizard.PreviousButtonLabel}
              nextLabel={Common.Wizard.NextButtonLabel}
              preventPreviousButtonDefaultBehaviour={true}
              onPrevious={this.onPaperFileListPreviousBtnClick}
              onNext={this.onPaperFileListNextBtnClick}
              preventNextButtonDefaultBehaviour={true}
              previousButtonIcon={<PreviousButtonIcon />}
              nextButtonIcon={<NextButtonIcon />}
            >
              <StepLayout>
                <Body
                  lg={12}
                  md={12}
                  sm={12}
                  xs={12}
                  automationTestId={"98C5091E-1039-44F8-970D-F2802692CFD5"}
                >
                  <PaperFileList
                    ref={(ins) => (this._paperfileListRef = ins)}
                    clientName={this.props.headInfoViewModel.clientName}
                    requestPaperFileReturnDetails={
                      this.props.requestPaperFileReturnDetails
                    }
                    paperFiledRetursData={this.props.paperFileReturnData}
                    match={this.props.match}
                    isPreviewMode={this.state.isPreviewMode}
                    isDraftReturn={this.state.isDraftReturn}
                    taxReturn={this.props.taxReturn}
                    signerData={this.props.signerData}
                    downloadAllPaperFileReturns={
                      this.props.downloadAllPaperFileReturns
                    }
                    downloadIndividualPaperFileReturn={
                      this.props.downloadIndividualPaperFileReturn
                    }
                    getPaperFileDocumentStream={
                      this.props.getPaperFileDocumentStream
                    }
                    getMergedPaperFileDocumentStream={
                      this.props.getMergedPaperFileDocumentStream
                    }
                    onPaperFileStepSkip={this.onPaperFileListPageSkip}
                    headerInfo={this.props.headInfoViewModel}
                    addPaperFilePrintToClientTracking={
                      this.props.addPaperFilePrintToClientTracking
                    }
                    addPaperFilePrintAllToClientTracking={
                      this.props.addPaperFilePrintAllToClientTracking
                    }
                    summaryData={this.props.summaryData}
                    updatePaperFileConsent={this.props.updatePaperFileConsent}
                    onConsentSkip={this.goToNextStep}
                    skipButtonText={
                      PaperFileReturnConstants.SkipToNextButtonText
                    }
                  />
                </Body>
              </StepLayout>
            </Step>
          )}
        {this.props.taxReturn.id > 0 && (
          <Step
            id={SignProcessSteps.DistributeSelection.toString()}
            name={"Distribute"}
            tooltip={"Distribute"}
            template={<TemplateIcon />}
            onNext={this.k1DistributionMethodConfirm}
            preventNextButtonDefaultBehaviour={true}
            hidden={
              this.props.taxReturn.formGroups.find(
                (x) => x.group === DocumentGroups.K1
              ) === undefined
            }
            disableNavigationClick={this.disableNavigationLink(
              SignProcessSteps.DistributeSelection
            )}
            previousLabel={Common.Wizard.PreviousButtonLabel}
            nextLabel={Common.Wizard.NextButtonLabel}
            previousButtonIcon={<PreviousButtonIcon />}
            nextButtonIcon={<NextButtonIcon />}
          >
            <StepLayout className="select-k1-method-container">
              <Body
                title={`Distribute K-1 Documents to ${type}`}
                subTitle={`Select the method you would like to distribute your K-1's`}
                lg={10}
                md={12}
                sm={12}
                xs={12}
                automationTestId={"11BB28B5-42F3-4571-B567-20700B1740B6"}
              >
                <K1DistributionMethod
                  match={this.props.match}
                  taxReturn={this.props.taxReturn}
                  onSetDistributeMethod={this.handleSetDistributeMethod}
                  clearParentState={this.clearState}
                  isPreviewMode={this.state.isPreviewMode}
                />
              </Body>
            </StepLayout>
          </Step>
        )}

        {this.props.taxReturn.id > 0 && (
          <Step
            id={SignProcessSteps.DistributeManual.toString()}
            hidden={
              this.props.taxReturn.formGroups.find(
                (x) => x.group === DocumentGroups.K1
              ) === undefined
            }
            disableNavigationClick={true}
            previousLabel={Common.Wizard.PreviousButtonLabel}
            nextLabel={Common.Wizard.NextButtonLabel}
            preventPreviousButtonDefaultBehaviour={true}
            onPrevious={this.onK1DistributionPreviousBtnClick}
            preventNextButtonDefaultBehaviour={true}
            onNext={this.onK1DistributionNextBtnClick}
            previousButtonIcon={<PreviousButtonIcon />}
            nextButtonIcon={<NextButtonIcon />}
          >
            <StepLayout className="k1-border">
              <Body
                lg={12}
                md={12}
                sm={12}
                xs={12}
                automationTestId={"11BB28B5-42F3-4571-B567-20700B1740B6"}
              >
                <DistributeManual
                  ref={(ins) => (this._distributeManualRef = ins)}
                  requestK1ShareHolderDetails={
                    this.props.requestK1ShareHolderDetails
                  }
                  k1Data={this.props.k1Data}
                  isK1Loading={this.props.isK1Loading}
                  match={this.props.match}
                  taxReturn={this.props.taxReturn}
                  downloadK1DocumentAsync={this.props.downloadK1DocumentAsync}
                  downloadIndividualK1DocumentAsync={
                    this.props.downloadIndividualK1DocumentAsync
                  }
                  downloadSelectedK1DocumentAsync={
                    this.props.downloadSelectedK1DocumentAsync
                  }
                  requestMyDownload={this.props.requestMyDownload}
                  isPreviewMode={this.state.isPreviewMode}
                  isTrialView={isTrialView}
                  downloadAllK1DocumentsAsync={
                    this.props.downloadAllK1DocumentsAsync
                  }
                  downloadBulkSelectedK1DocumentAsync={
                    this.props.downloadBulkSelectedK1DocumentAsync
                  }
                  isDraftReturn={this.state.isDraftReturn}
                />
              </Body>
            </StepLayout>
          </Step>
        )}

        {this.props.taxReturn.id > 0 && (
          <Step
            id={SignProcessSteps.DistributeElectronic.toString()}
            hidden={
              this.props.taxReturn.formGroups.find(
                (x) => x.group === DocumentGroups.K1
              ) === undefined
            }
            disableNavigationClick={true}
            previousLabel={Common.Wizard.PreviousButtonLabel}
            nextLabel={
              isK1Sent && isK1Sent.length > 0
                ? Common.Wizard.NextButtonLabel
                : Common.Wizard.SendLater
            }
            preventPreviousButtonDefaultBehaviour={true}
            onPrevious={this.onK1DistributionPreviousBtnClick}
            preventNextButtonDefaultBehaviour={true}
            onNext={this.onK1DistributionNextBtnClick}
            previousButtonIcon={<PreviousButtonIcon />}
            nextButtonIcon={<NextButtonIcon />}
          >
            <StepLayout className="k1-border">
              <Body
                lg={12}
                md={12}
                sm={12}
                xs={12}
                automationTestId={"11BB28B5-42F3-4571-B567-20700B1740B6"}
              >
                <DistributeElectronic
                  ref={(ins) => (this._distributeElectronicRef = ins)}
                  requestK1ShareHolderDetails={
                    this.props.requestK1ShareHolderDetails
                  }
                  requestK1ShareHolderActivity={
                    this.props.requestK1ShareHolderActivity
                  }
                  k1Data={this.props.k1Data}
                  match={this.props.match}
                  taxReturn={this.props.taxReturn}
                  updateK1ShareHolderEmail={this.props.updateK1ShareHolderEmail}
                  updateK1ShareHolderDetail={
                    this.props.updateK1ShareHolderDetail
                  }
                  downloadK1DocumentAsync={this.props.downloadK1DocumentAsync}
                  downloadSelectedK1DocumentAsync={
                    this.props.downloadSelectedK1DocumentAsync
                  }
                  downloadIndividualK1DocumentAsync={
                    this.props.downloadIndividualK1DocumentAsync
                  }
                  sendMailToShareHolder={this.props.sendMailToShareHolder}
                  loggedInUserInfo={this.props.loggedInUserInfo}
                  isPreviewMode={isPreviewMode}
                  companyName={name || ""}
                  requestMyDownload={this.props.requestMyDownload}
                  isK1Loading={this.props.isK1Loading}
                  isTrialView={isTrialView}
                  downloadAllK1DocumentsAsync={
                    this.props.downloadAllK1DocumentsAsync
                  }
                  bulkSendMailToShareHolder={
                    this.props.bulkSendMailToShareHolder
                  }
                  downloadBulkSelectedK1DocumentAsync={
                    this.props.downloadBulkSelectedK1DocumentAsync
                  }
                  isDraftReturn={this.state.isDraftReturn}
                />
              </Body>
            </StepLayout>
          </Step>
        )}

        {this.props.taxReturn.id > 0 && (
          <Step
            id={SignProcessSteps.Pay.toString()}
            tooltip={hasValidVouchers ? "Pay" : "No Payment Voucher Available"}
            name={"Pay"}
            template={
              <TemplateIcon
                className={hasValidVouchers ? "" : "cursorDisabled"}
              />
            }
            disablePreviousButton={
              !this.props.summaryData.hasPaperFiles &&
              this.state.isDelegateeAssigned
            }
            disableNavigationClick={this.disableNavigationLink(
              SignProcessSteps.Pay
            )}
            previousLabel={Common.Wizard.PreviousButtonLabel}
            nextLabel={
              this.state.isDraftReturn
                ? Common.Wizard.ApproveDraftReturn
                : Common.Wizard.NextButtonLabel
            }
            preventNextButtonDefaultBehaviour={
              this.state.voucherState === "payment"
            }
            onNext={this.setEstimated}
            preventPreviousButtonDefaultBehaviour={true}
            onPrevious={
              this.state.voucherState === "payment"
                ? this.handlePayPreviousBtn
                : this.handleBackVoucher
            }
            previousButtonIcon={<PreviousButtonIcon />}
            nextButtonIcon={<NextButtonIcon />}
          >
            <StepLayout className="pay-page-stepper">
              <Body
                lg={12}
                md={12}
                sm={12}
                xs={12}
                noBackgroundColor={true}
                containerClassName="pay-panel-body no-padding"
                bodyClassName={"no-padding no-margin pay-step row"}
                automationTestId={"11BB28B5-42F3-4571-B567-20700B1740B6"}
              >
                {this.state.voucherState === "payment" ? (
                  <PaymentVoucher
                    ref={(ref: any) => {
                      this._payRef = ref;
                    }}
                    setVoucherState={this.setVoucherState}
                    commonData={this.props.commonData}
                    match={this.props.match}
                    history={this.props.history}
                    payScreenData={this.props.payScreenData}
                    requestPayScreenDetails={this.props.requestPayScreenDetails}
                    documentSettings={this.props.documentSettings}
                    nextStep={this.goToCompletionWizard}
                    prevStep={this.goToPreviousStep}
                    taxReturn={this.props.taxReturn}
                    downloadAllVouchers={this.props.downloadAllVouchers}
                    downloadFilingInstructions={
                      this.props.downloadFilingInstructions
                    }
                    downloadTaxInvoice={this.props.downloadTaxInvoice}
                    updateVoucherNotificationSettings={
                      this.props.updateVoucherNotificationSettings
                    }
                    downloadSingleVoucher={this.props.downloadSingleVoucher}
                    invoicePaymentUrl={this.props.companyData.onlinePaymentUrl}
                    saveVoucherPayment={this.saveVoucherPayment}
                    isPreviewMode={false}
                    onSubmitPay={this.handleSubmitForNoVoucher}
                    clearPaymentDetails={this.clearPaymentDetails}
                    taxpayerPaymentSettings={
                      this.props.companyData.taxpayerPaymentSettings
                    }
                    documentUpdated={
                      this.props.DocumentStatusState.documentUpdated
                    }
                    SetDocumentIsDownloaded={this.props.SetDocumentIsDownloaded}
                    isDraftReturn={this.state.isDraftReturn}
                  />
                ) : (
                  <EstimatedVoucher
                    ref={(ref: any) => {
                      this._payRef = ref;
                    }}
                    setVoucherState={this.setVoucherState}
                    commonData={this.props.commonData}
                    match={this.props.match}
                    history={this.props.history}
                    payScreenData={this.props.payScreenData}
                    requestPayScreenDetails={this.props.requestPayScreenDetails}
                    documentSettings={this.props.documentSettings}
                    nextStep={this.goToCompletionWizard}
                    prevStep={this.goToPreviousStep}
                    taxReturn={this.props.taxReturn}
                    downloadAllVouchers={this.props.downloadAllVouchers}
                    downloadFilingInstructions={
                      this.props.downloadFilingInstructions
                    }
                    downloadTaxInvoice={this.props.downloadTaxInvoice}
                    updateVoucherNotificationSettings={
                      this.props.updateVoucherNotificationSettings
                    }
                    downloadSingleVoucher={this.props.downloadSingleVoucher}
                    invoicePaymentUrl={this.props.companyData.onlinePaymentUrl}
                    saveVoucherPayment={this.saveVoucherPayment}
                    isPreviewMode={false}
                    onSubmitPay={this.handleSubmitForNoVoucher}
                    clearPaymentDetails={this.clearPaymentDetails}
                    taxpayerPaymentSettings={
                      this.props.companyData.taxpayerPaymentSettings
                    }
                    documentUpdated={
                      this.props.DocumentStatusState.documentUpdated
                    }
                    SetDocumentIsDownloaded={this.props.SetDocumentIsDownloaded}
                    isDraftReturn={this.state.isDraftReturn}
                  />
                )}
              </Body>
            </StepLayout>
          </Step>
        )}
        {this.state.showConsentPopUp && (
          <SuiteModal
            width="500"
            theme="light"
            title="SMS Text Notification Consent"
            onClickOutside={() => {}}
            onClickClose={() => {
              this.handleConsent(false);
            }}
          >
            <ConsentPopUp
              companyName={this.props.companyData.name}
              handleConsent={this.handleConsent}
              countryCode={this.state.countryCode}
              mobileNumber={this.state.mobileNumber}
            />
          </SuiteModal>
        )}
      </Wizard>
    );
  }
}
