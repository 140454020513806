import { DocumentGroups } from '../models/IGroup';

export interface IDocumentReviewModel {
    documentId: number;
    documentGuid: string;
    fileLink: string;
    documentGroup: DocumentGroups;
    fileName: string;
    attachmentInstruction: string;
    taxYear: number;
    documentType: ReviewDocumentType;
}

export const initialDocumentReviewModel: IDocumentReviewModel[] = [];
export enum ReviewDocumentType {
    None = 0,
    Attachment = 1,
    PaperFileReturn = 2
}