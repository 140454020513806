import { ApplicationState } from "../../../store/index";
import { connect } from 'react-redux';
import { PaperFiledReturnCompleted } from "./PaperFiledReturnCompleted";
import { actionCreators as TaxDocumentStore } from '../../../store/Common/TaxDocumentStore';
import { actionCreators as PaperFileReturnStore } from '../../../store/PaperFileReturn/PaperFileReturnStore';
import { actionCreators as SignProcessStore } from '../../../store/SignProcess/SignProcessStore';
import { actionCreators as SummaryStore } from '../../../store/SummaryStore';
import { actionCreators as EsignStore } from '../../../store/Common/EsignStore';
import { IClientProcessViewModel } from '../../../core/domain/viewModels/IClientProcessViewModel'
import { IPaperFileReturnDetails } from "../../../core/domain/models/IPaperFileReturn";



function mapStateToProps(state: ApplicationState) {
    return {
        paperFileReturnData: state.paperFileReturnData.data,
        headInfoViewModel: state.headerInfo,
        taxReturn: state.taxReturn.taxDocument,
        clientName: state.headerInfo.clientName,
        isPreviewMode: false,
        summaryData: state.summaryData,
        signerData:state.esignData.signerDetails,
    };
}

//temperroy fix for webpack failure need to invetigate further when we have time
const mapDispatchToProps = (dispatch: any) => {
    return {
        updateLastVisitedStep: (clientGuid: string, step: number, successCallback?: () => void) =>
            dispatch(SignProcessStore.updateLastVisitedStep(clientGuid, step, successCallback)),
        requestSigners: (clientGuid: string) => dispatch(EsignStore.requestSigners(clientGuid)),
        requestSummaryDetails: (clientGuid: string) => dispatch(SummaryStore.requestSummaryDetails(clientGuid)),
        requestTaxDocument: (clientGuid: string) => dispatch(TaxDocumentStore.requestTaxDocument(clientGuid)),
        updatePaperFileConsent: (clientId: string, callback?: () => void) => dispatch(PaperFileReturnStore.updatePaperFileConsent(clientId, callback)),
        addPaperFileConsentToClientTracking: (clientId: string, callback?: () => void) => dispatch(PaperFileReturnStore.addPaperFileConsentToClientTracking(clientId, callback)),
        addPaperFilePrintToClientTracking: (clientId: string, paperFile: IPaperFileReturnDetails, callback?: () => void) => dispatch(PaperFileReturnStore.addPaperFilePrintToClientTracking(clientId, paperFile, callback)),
        addPaperFilePrintAllToClientTracking: (clientId: string, callback?: () => void) => dispatch(PaperFileReturnStore.addPaperFilePrintAllToClientTracking(clientId, callback)),
        downloadAllPaperFileReturns: (clientId: string, callback?: () => void) => dispatch(PaperFileReturnStore.downloadAllPaperFileReturns(clientId, callback)),
        downloadIndividualPaperFileReturn: (clientId: string, paperFile: IPaperFileReturnDetails, callback?: () => void) => dispatch(PaperFileReturnStore.downloadIndividualPaperFileReturn(clientId, paperFile, callback)),
        getPaperFileDocumentStream: (clientId: string, documentGuid: string, paperFile: IPaperFileReturnDetails, callback?: (data: any) => void) => dispatch(PaperFileReturnStore.getPaperFileDocumentStream(clientId, documentGuid, paperFile, callback)),
        getMergedPaperFileDocumentStream: (clientId: string, callback?: (url: any) => void) => dispatch(PaperFileReturnStore.getMergedPaperFileDocumentStream(clientId, callback)),
        requestPaperFileReturnDetails: (clientGuid: string, pageNo: number, pageSize: number, sortBy: string, sortOrder: string, searchText: string) => dispatch(PaperFileReturnStore.requestPaperFileReturnDetails(clientGuid, pageNo, pageSize, sortBy, sortOrder, searchText)),
        requestCurrentStepAndUserRole: (clientGuid: string, successCallback?: (clientProcessState: IClientProcessViewModel) => void) => dispatch(SignProcessStore.requestCurrentStepAndUserRole(clientGuid, successCallback)),
        dispatch
    }
}


const connector = connect(mapStateToProps, mapDispatchToProps);

export default connector(PaperFiledReturnCompleted);



