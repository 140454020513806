import { Action, Reducer } from 'redux';
import { actionTypes } from '../ActionTypes';
import { AppThunkAction } from '../';
import { initializeAxios } from '../../core/services/dataAccess/DataService.Axios'
import { AxiosResponse  } from 'axios';
import { StatusType} from '../Common/NotificationStore';
import * as Constants from '../../components/Common/Constants';


export interface IDocumentStatusState {
    documentUpdated:boolean;
}

export interface DocumentStatusAction {
    type: actionTypes.SET_UPDATED_DOCUMENT_STATUS;
    data: boolean
}

type KnownAction = DocumentStatusAction;

let intialDocumentStatusState: IDocumentStatusState = { documentUpdated:false };

export const actionCreators = {

    getDocumentUpdatedStatus: (clientId: string): AppThunkAction<KnownAction> => (dispatch:any) => {
        


        return initializeAxios().get<boolean>('api/Pay/GetTransmittalUpdateStatus/' + clientId).then(function (response: AxiosResponse<boolean>) {

                dispatch({ type: actionTypes.SET_UPDATED_DOCUMENT_STATUS, data: response.data });

            })
            .catch(function (error: any) {
                dispatch({
                    type: actionTypes.NOTIFICATION, statusMessage: Constants.VoucherPayConstant.DocumentStatusFetchFailed,
                    statusType: StatusType.Error
                });

               
            });


    },
    SetDocumentIsDownloaded: (clientId: string): AppThunkAction<KnownAction> => (dispatch: any) => {



        return initializeAxios().put<boolean>('api/Pay/SetDocumentIsDownloaded/' + clientId).then(function (response: AxiosResponse<boolean>) {

            dispatch({ type: actionTypes.SET_UPDATED_DOCUMENT_STATUS, data: false });

        })
            .catch(function (error: any) {
                dispatch({
                    type: actionTypes.NOTIFICATION, statusMessage: Constants.VoucherPayConstant.DownloadedDocumentStatusFailed,
                    statusType: StatusType.Error
                });


            });


    }





    }



export const reducer: Reducer<IDocumentStatusState> = (state: IDocumentStatusState = intialDocumentStatusState, incomingAction: Action) => {
    const action = incomingAction as KnownAction;
    switch (action.type) {
        case actionTypes.SET_UPDATED_DOCUMENT_STATUS:
            return {documentUpdated: action.data };
        default:
            return state || intialDocumentStatusState;
    }
}
