import { ApplicationState } from "../../store/index";
import { connect } from 'react-redux';
import { actionCreators as DocumentReviewStore } from '../../store/ReviewStore';
import { actionCreators as CCRecipientStore } from '../../store/CCRecipientStore';
import { DocumentStatus } from "../../core/common/Enums";
import { ReviewCompleted } from './ReviewCompleted';
import { ICCRecipientModel } from "../../core/domain/models/ICCRecipientModel";
import { actionCreators as SignProcessStore } from '../../store/SignProcess/SignProcessStore';
import { IClientProcessViewModel } from "../../core/domain/viewModels/IClientProcessViewModel";
import { actionCreators as TaxDocumentStore } from '../../store/Common/TaxDocumentStore';

function mapStateToProps(state: ApplicationState) {
    return {
        taxReturn: state.taxReturn.taxDocument,
        documentReviewModel: state.documentReviewModel,
        ccRecipientDownloadableDocuments: state.ccRecipientDownloadableDocuments,
    };
}

const mapDispatchToProps = (dispatch: any) => {
    return {
        downloadAllAttachments: (clientId: string, attachments: any) => dispatch(DocumentReviewStore.downloadAllAttachments(clientId, attachments)),
        downloadIndividualAttachment: (clientId: string, attachment: any) => dispatch(DocumentReviewStore.downloadIndividualAttachment(clientId, attachment)),
        requestDocumentReviewModel: (clientId: string) => dispatch(DocumentReviewStore.requestDocumentReviewModel(clientId)),
        requestCCRecipientDownloadableDocuments: (clientGuid: string, documentStatus: DocumentStatus
        ) => dispatch(CCRecipientStore.requestCCRecipientDownloadableDocuments(clientGuid, documentStatus)),
        forwardDocumentsToRecipients: (clientId: string, recipientInfo: ICCRecipientModel, callback: () => void) =>
            dispatch(CCRecipientStore.forwardDocumentsToRecipients(clientId, recipientInfo, callback)),
        requestCurrentStepAndUserRole: (clientGuid: string, successCallback?: (clientProcessState: IClientProcessViewModel) => void) => dispatch(SignProcessStore.requestCurrentStepAndUserRole(clientGuid, successCallback)),
        requestTaxDocument: (clientGuid: string, callback?: (data?: any) => void) => dispatch(TaxDocumentStore.requestTaxDocument(clientGuid, callback)),
        dispatch
    }
}


const connector = connect(mapStateToProps, mapDispatchToProps);

export default connector(ReviewCompleted);



