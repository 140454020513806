import React from "react";
import { SvgIconProps } from "./SvgIcons";

export const SvgIconAccessCode: React.SFC<SvgIconProps> = (props) => {
    return <svg xmlns="http://www.w3.org/2000/svg" width="82.44" height="70" viewBox="0 0 82.44 70" fill="none">
    <path d="M18.7748 28.6228C20.9342 28.6228 22.6848 26.8723 22.6848 24.7129C22.6848 22.5535 20.9342 20.8029 18.7748 20.8029C16.6154 20.8029 14.8649 22.5535 14.8649 24.7129C14.8649 26.8723 16.6154 28.6228 18.7748 28.6228Z" fill="#05386B" stroke="#05386B"/>
    <path d="M34.4034 28.6228C36.5628 28.6228 38.3133 26.8723 38.3133 24.7129C38.3133 22.5535 36.5628 20.8029 34.4034 20.8029C32.244 20.8029 30.4934 22.5535 30.4934 24.7129C30.4934 26.8723 32.244 28.6228 34.4034 28.6228Z" fill="#05386B" stroke="#05386B"/>
    <path d="M50.0325 28.6228C52.1919 28.6228 53.9425 26.8723 53.9425 24.7129C53.9425 22.5535 52.1919 20.8029 50.0325 20.8029C47.8731 20.8029 46.1226 22.5535 46.1226 24.7129C46.1226 26.8723 47.8731 28.6228 50.0325 28.6228Z" fill="#05386B" stroke="#05386B"/>
    <path d="M65.6612 28.6228C67.8206 28.6228 69.5711 26.8723 69.5711 24.7129C69.5711 22.5535 67.8206 20.8029 65.6612 20.8029C63.5018 20.8029 61.7512 22.5535 61.7512 24.7129C61.7512 26.8723 63.5018 28.6228 65.6612 28.6228Z" fill="#05386B" stroke="#05386B"/>
    <path d="M71.9634 0.5H71.9628L12.473 0.5L12.4724 0.5C9.29823 0.503614 6.25511 1.76615 4.01063 4.01063C1.76615 6.2551 0.503614 9.29823 0.5 12.4724V12.473L0.5 36.9525L0.5 36.953C0.503614 40.1272 1.76615 43.1703 4.01063 45.4148C6.25511 47.6593 9.29823 48.9218 12.4724 48.9255H12.473H40.3624C41.0156 48.9255 41.642 48.666 42.1039 48.2041C42.5657 47.7423 42.8252 47.1159 42.8252 46.4627C42.8252 45.8095 42.5657 45.1831 42.1039 44.7213C41.642 44.2594 41.0156 43.9999 40.3624 43.9999H12.4737C10.6052 43.9974 8.81395 43.254 7.49273 41.9327C6.17158 40.6116 5.42819 38.8205 5.42552 36.9521C5.42552 36.952 5.42551 36.9519 5.42551 36.9518V12.4737C5.42551 12.4736 5.42552 12.4734 5.42552 12.4733C5.42819 10.605 6.17158 8.81387 7.49273 7.49273C8.81387 6.17158 10.605 5.42819 12.4733 5.42551C12.4734 5.42551 12.4736 5.42551 12.4737 5.42551H71.9622C71.9623 5.42551 71.9624 5.42551 71.9625 5.42551C73.8309 5.42819 75.6219 6.17158 76.9431 7.49273C78.2643 8.81395 79.0077 10.6052 79.0103 12.4737V31.8388C79.0103 32.492 79.2698 33.1184 79.7316 33.5803C80.1935 34.0421 80.8199 34.3016 81.4731 34.3016C82.1262 34.3016 82.7526 34.0421 83.2145 33.5803C83.6763 33.1184 83.9358 32.492 83.9358 31.8388V12.473V12.4724C83.9322 9.29823 82.6697 6.2551 80.4252 4.01063C78.1807 1.76615 75.1376 0.503614 71.9634 0.5Z" fill="#05386B" stroke="#05386B"/>
    <path d="M65.7082 71.5C66.1363 71.5007 66.5623 71.4402 66.9733 71.3204L66.9771 71.3192C72.5067 69.6581 76.3057 66.9928 78.879 63.9058C81.4497 60.822 82.7759 57.3429 83.4029 54.0819C84.6538 47.5749 83.1311 41.8812 83.0942 41.7435V41.7435L83.0934 41.7404C82.9433 41.1931 82.6078 40.7148 82.1442 40.3873C81.6849 40.0629 81.1282 39.9065 80.5674 39.944C76.1545 40.002 71.8254 38.7366 68.1382 36.311C67.4188 35.8293 66.5725 35.5721 65.7066 35.5721C64.8404 35.5721 63.9937 35.8296 63.274 36.3117C59.9354 38.5283 56.0522 39.7857 52.048 39.9468L52.0458 39.9469C51.0558 39.9911 50.1101 40.3699 49.3635 41.0215C48.6169 41.6732 48.1136 42.5589 47.9359 43.5338L47.9359 43.5338L47.9354 43.5369C47.492 46.0589 46.906 51.5738 48.7873 57.1781C50.6794 62.8142 55.0559 68.5001 64.4441 71.3192L64.447 71.3201C64.8566 71.4404 65.2813 71.5009 65.7082 71.5ZM65.7082 71.5C65.7078 71.5 65.7074 71.5 65.707 71.5L65.7079 71L65.7092 71.5C65.7089 71.5 65.7085 71.5 65.7082 71.5ZM65.6653 40.5638C69.5751 43.0918 74.0747 44.5602 78.7224 44.8253C79.1062 47.1405 79.446 51.322 78.0287 55.4997C76.5484 59.8629 73.1381 64.2679 65.7106 66.539C58.5026 64.3349 55.0698 60.124 53.5186 55.8433C52.0233 51.7171 52.2619 47.4815 52.6584 44.8266C57.2937 44.5788 61.7816 43.1083 65.6653 40.5638Z" fill="#88C656" stroke="#88C656"/>
  </svg>;
}

export const SvgIconProfile: React.SFC<SvgIconProps> = (props) => {
    return <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
    width="80" height="80" viewBox="0 0 82 82">
<path fill="#88C656" style={{stroke:"#88C656", strokeWidth:2}} d="M41,43.4c2.1,0,4.3-0.5,6.2-1.3c2-0.9,3.7-2.2,5.1-3.8c1.4-1.6,2.5-3.5,3.1-5.6c0.6-2.1,0.8-4.2,0.5-6.3
	c-0.3-2.1-1-4.2-2.2-6c-1.1-1.8-2.7-3.4-4.5-4.5c-1.8-1.2-3.8-2-5.9-2.3c-2.1-0.3-4.3-0.2-6.4,0.4c-0.3,0.1-0.5,0.2-0.7,0.3
	c-0.2,0.2-0.4,0.4-0.5,0.6c-0.1,0.2-0.2,0.5-0.3,0.8c0,0.3,0,0.5,0.1,0.8c0.1,0.3,0.2,0.5,0.4,0.7c0.2,0.2,0.4,0.4,0.6,0.5
	c0.2,0.1,0.5,0.2,0.8,0.2c0.3,0,0.5,0,0.8-0.1c2.2-0.6,4.6-0.5,6.8,0.3c2.2,0.8,4,2.2,5.3,4.2c1.3,1.9,2,4.2,1.9,6.5
	c-0.1,2.3-0.8,4.5-2.2,6.4c-1.4,1.8-3.3,3.2-5.5,3.9c-2.2,0.7-4.6,0.6-6.8-0.1c-2.2-0.7-4.1-2.1-5.4-4c-1.3-1.9-2.1-4.1-2.1-6.4
	c0-1,0.1-2.1,0.4-3.1c0.1-0.3,0.1-0.5,0.1-0.8c0-0.3-0.1-0.5-0.2-0.8c-0.1-0.2-0.3-0.4-0.5-0.6s-0.4-0.3-0.7-0.4
	c-0.3-0.1-0.5-0.1-0.8-0.1c-0.3,0-0.5,0.1-0.8,0.2c-0.2,0.1-0.4,0.3-0.6,0.5c-0.2,0.2-0.3,0.5-0.3,0.7c-0.6,2.2-0.8,4.6-0.3,6.9
	c0.4,2.3,1.4,4.5,2.8,6.3c1.4,1.9,3.2,3.4,5.3,4.4C36.4,42.8,38.7,43.4,41,43.4z"/>
<path fill="#05386B" style={{stroke:"#05386B", strokeWidth:2}} d="M9.7,65.2c3.7,4.8,8.4,8.8,13.8,11.5s11.4,4.1,17.5,4.1s12-1.4,17.5-4.1S68.6,70,72.3,65.2
	c0.1-0.1,0.2-0.3,0.3-0.4c3.7-5,6.2-10.8,7.2-17s0.6-12.4-1.3-18.4c-1.9-5.9-5.1-11.3-9.5-15.8C64.6,9.3,59.2,5.9,53.3,4
	C47.4,2,41.1,1.5,34.9,2.5c-6.2,1-12,3.4-17,7c-5,3.7-9.1,8.4-12,14C3.1,29,1.6,35.2,1.6,41.4c0,8.4,2.7,16.6,7.8,23.4
	C9.5,64.9,9.6,65,9.7,65.2z M41,76.7c-5.2,0-10.3-1.1-15-3.3c-4.7-2.2-8.8-5.4-12.1-9.4c0.1-0.1,0.2-0.2,0.3-0.3
	c7.9-5.3,17.2-8.1,26.8-8.1c9.5,0,18.8,2.8,26.8,8.1c0.1,0.1,0.2,0.2,0.3,0.3c-3.3,4-7.5,7.2-12.1,9.4C51.3,75.6,46.2,76.7,41,76.7z
	 M41,6c6.4,0,12.7,1.7,18.1,5c5.5,3.3,10,8,13,13.6c3,5.6,4.5,12,4.2,18.3C76,49.3,74,55.5,70.5,60.8c-0.2-0.1-0.3-0.3-0.5-0.4
	c-8.6-5.7-18.7-8.8-29-8.8c-10.3,0-20.4,3.1-29,8.8c-0.2,0.1-0.3,0.3-0.5,0.4C8,55.5,6,49.3,5.7,43c-0.3-6.4,1.2-12.7,4.2-18.3
	c3-5.6,7.5-10.3,13-13.6C28.3,7.7,34.6,6,41,6z"/>
</svg>

}

export const SvgIconReturnLocked: React.SFC<SvgIconProps> = (props) => {
    return <svg xmlns="http://www.w3.org/2000/svg" width="200" height="200" fill="none" viewBox="0 0 200 200">
        <circle cx="100" cy="100" r="100" fill="#E6EBF0"></circle>
        <path fill="#B4C3D3" d="M142.478 130.608v-2.694c0-3.768-1.511-7.382-4.201-10.046a14.418 14.418 0 00-10.145-4.162 14.414 14.414 0 00-10.144 4.162 14.139 14.139 0 00-4.202 10.046v2.694a5.443 5.443 0 00-2.876 1.916 5.354 5.354 0 00-1.125 3.247v18.503a4.4 4.4 0 001.309 3.118 4.486 4.486 0 003.149 1.294h27.778a4.489 4.489 0 003.15-1.294 4.4 4.4 0 001.308-3.118v-18.503a5.355 5.355 0 00-1.123-3.248 5.44 5.44 0 00-2.878-1.915zm-14.336-12.275a9.736 9.736 0 016.836 2.81 9.548 9.548 0 012.838 6.771v1.847a70.016 70.016 0 00-9.681-.754 70.1 70.1 0 00-9.686.754v-1.847a9.558 9.558 0 012.846-6.775 9.745 9.745 0 016.847-2.806zm-13.678 35.941v-18.503a.795.795 0 01.18-.511.81.81 0 01.466-.28 74.715 74.715 0 0113.056-1.355 74.66 74.66 0 0113.035 1.355.807.807 0 01.466.28.795.795 0 01.18.511v6.21c.012.084.029.167.05.249l.156 11.838-27.589.206z"></path>
        <path fill="#B4C3D3" d="M133.719 61.033H61.586a12.75 12.75 0 00-9.018 3.76 12.865 12.865 0 00-3.742 9.06V121.6a12.864 12.864 0 003.742 9.06 12.752 12.752 0 009.018 3.761h33.63a2.32 2.32 0 001.644-.685 2.34 2.34 0 000-3.302 2.32 2.32 0 00-1.644-.684h-33.63a8.11 8.11 0 01-5.732-2.391 8.18 8.18 0 01-2.378-5.759V86.923h88.353v15.75c0 .62.245 1.214.681 1.652a2.319 2.319 0 003.288 0 2.341 2.341 0 00.681-1.652v-28.82a12.865 12.865 0 00-3.742-9.06 12.75 12.75 0 00-9.018-3.76zM53.476 82.252v-8.408a8.177 8.177 0 012.381-5.753 8.103 8.103 0 015.73-2.387h72.132a8.104 8.104 0 015.732 2.39 8.18 8.18 0 012.378 5.76v8.407l-88.353-.01z"></path><path fill="#B4C3D3" d="M63.622 115.186a3.85 3.85 0 004.597 3.773 3.852 3.852 0 003.023-3.023 3.848 3.848 0 00-6.493-3.471 3.849 3.849 0 00-1.127 2.721zM78.418 115.186c0 .76.243 1.504.698 2.137a4.103 4.103 0 001.86 1.417c.757.291 1.59.367 2.393.219a4.255 4.255 0 002.121-1.053 3.772 3.772 0 001.134-1.97c.16-.746.078-1.52-.236-2.223a3.923 3.923 0 00-1.525-1.726 4.37 4.37 0 00-2.302-.648 4.312 4.312 0 00-2.93 1.126c-.776.722-1.213 1.7-1.213 2.721z"></path>
        </svg>;
}

export const SvgIconReturnDeclined: React.SFC<SvgIconProps> = (props) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="200" height="200" viewBox="0 0 200 200" fill="none">
            <circle cx="100" cy="100" r="100" fill="#E6EBF0" />
            <path
                d="M132.974 46.0093H67.0259C56.455 46.0128 47.8909 54.5009 47.8873 64.978V130.341C47.8873 131.791 49.0744 132.967 50.5371 132.967C51.9998 132.967 53.1869 131.791 53.1869 130.341V77.4091H146.813V130.341C146.817 137.915 140.623 144.054 132.981 144.057H132.974H67.0259C64.6234 144.057 62.2634 143.434 60.1753 142.257C58.861 141.62 57.2747 142.163 56.6317 143.465C56.0487 144.652 56.4409 146.081 57.5538 146.809C60.4403 148.438 63.7049 149.296 67.0259 149.296H132.974C143.545 149.296 152.113 140.804 152.113 130.327V64.978C152.109 54.5009 143.541 46.0128 132.974 46.0093ZM146.813 64.978V72.1565H53.1869V64.978C53.1834 57.4038 59.3768 51.2653 67.0189 51.2618H67.0259H132.974C140.616 51.2583 146.81 57.3968 146.813 64.971V64.978Z"
                fill="#B4C3D3"
            />
            <path
                d="M80.2853 58.2158C78.4697 58.2123 76.9989 59.6831 76.9954 61.4987C76.9918 63.3143 78.4626 64.7851 80.2782 64.7886C82.0938 64.7921 83.5646 63.3213 83.5681 61.5057V61.4987C83.5681 59.6866 82.0974 58.2158 80.2853 58.2158Z"
                fill="#B4C3D3"
            />
            <path
                d="M67.6096 58.2158C65.5346 58.2123 63.8538 59.6831 63.8497 61.4987C63.8457 63.3143 65.5266 64.7851 67.6016 64.7886C69.6766 64.7921 71.3574 63.3213 71.3615 61.5057V61.4987C71.3574 59.6866 69.6806 58.2193 67.6096 58.2158Z"
                fill="#B4C3D3"
            />
            <path
                d="M94.3697 58.2158C92.5506 58.2158 91.0798 59.6866 91.0798 61.5022C91.0798 63.3178 92.5506 64.7886 94.3662 64.7886C96.1818 64.7886 97.6526 63.3178 97.6526 61.5022C97.6526 59.6901 96.1818 58.2158 94.3697 58.2158Z"
                fill="#B4C3D3"
            />
            <path
                d="M86.6714 120.815C87.0429 121.211 87.4845 121.526 87.9707 121.74C88.4569 121.955 88.9782 122.066 89.5047 122.066C90.0312 122.066 90.5525 121.955 91.0387 121.74C91.5249 121.526 91.9664 121.211 92.338 120.815L100.047 112.622L107.761 120.815C108.521 121.567 109.526 121.977 110.565 121.958C111.603 121.938 112.594 121.491 113.329 120.71C114.064 119.929 114.484 118.876 114.503 117.772C114.521 116.668 114.135 115.6 113.427 114.792L105.719 106.6L113.427 98.407C114.158 97.6037 114.563 96.5276 114.554 95.4103C114.545 94.293 114.124 93.2241 113.381 92.4337C112.638 91.6433 111.633 91.1946 110.581 91.1844C109.53 91.1742 108.517 91.6032 107.761 92.379L100.047 100.572L92.338 92.379C91.9679 91.9722 91.5254 91.6478 91.0361 91.4247C90.5469 91.2017 90.0207 91.0844 89.4883 91.0797C88.956 91.0751 88.4281 91.1832 87.9354 91.3977C87.4428 91.6122 86.9953 91.9288 86.619 92.3291C86.2427 92.7293 85.9453 93.2052 85.7439 93.729C85.5425 94.2528 85.4413 94.8139 85.4462 95.3797C85.4511 95.9455 85.5619 96.5045 85.7723 97.0243C85.9826 97.5441 86.2883 98.0141 86.6714 98.407L94.3587 106.6L86.65 114.792C86.279 115.189 85.9852 115.66 85.7855 116.177C85.5857 116.695 85.4839 117.249 85.4859 117.808C85.4879 118.367 85.5936 118.921 85.797 119.437C86.0005 119.953 86.2976 120.421 86.6714 120.815Z"
                fill="#B4C3D3"
            />
        </svg>
    );
};

export const SvgIconManualUpload: React.SFC<SvgIconProps> = (props) => {
    return <svg xmlns="http://www.w3.org/2000/svg" width="54" height="54" viewBox="0 0 54 54" fill="none">
    <path d="M36 36L27 27L18 36" stroke="#05386B" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M27 27V47.25" stroke="#88C656" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M45.8773 41.3773C48.0718 40.181 49.8054 38.2878 50.8045 35.9968C51.8036 33.7057 52.0113 31.1471 51.3948 28.7249C50.7782 26.3027 49.3726 24.1547 47.3998 22.6201C45.427 21.0855 42.9992 20.2515 40.4998 20.2498H37.6648C36.9838 17.6156 35.7144 15.1701 33.9522 13.0971C32.1899 11.0241 29.9807 9.37751 27.4905 8.28121C25.0003 7.18491 22.294 6.6674 19.5751 6.76758C16.8561 6.86776 14.1952 7.58302 11.7924 8.85959C9.38968 10.1362 7.30759 11.9408 5.70269 14.1379C4.09779 16.335 3.01184 18.8673 2.52649 21.5445C2.04115 24.2217 2.16903 26.974 2.90052 29.5947C3.63201 32.2153 4.94808 34.636 6.74979 36.6748" stroke="#05386B" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M36 36L27 27L18 36" stroke="#88C656" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>;
}




