import * as React from "react";
import { Modal } from "react-bootstrap";
import { MarsNotifier } from "../Common/Notification/MarsNotifier";
import { CloseIcon } from "../Common/Icons/SvgIcons";
import { ButtonComponent } from "cp-common-ui-components";
import { handleKeyDown } from "../Helper/HelperFunction";

interface RemarksProps {
    show: boolean;
    onSaveRemarks(): void;
    reason: string;
    onCancel: () => void;
    onRemarksChange: (event: any) => void;
    title: string;
    warningMessage: string;
}

export class DelegateeCancelPopup extends React.Component<RemarksProps, {}> {
    constructor(props: RemarksProps) {
        super(props);

        this.onSubmit = this.onSubmit.bind(this);
        this.onCancel = this.onCancel.bind(this);
    }

    private onSubmit() {
        if (this.props.reason.length === 0) {
            return MarsNotifier.Warning(this.props.warningMessage, null);
        }

        this.props.onSaveRemarks();
    }

    private onCancel() {
        this.setState({
            description: ""
        });
        this.props.onCancel();
    }

    public render() {
        return (
            <Modal className="delegatee-cancel-modal" show={this.props.show} onHide={this.onCancel}>
                <Modal.Header>
                    <Modal.Title>
                        <span>{this.props.title}</span>
                    </Modal.Title>
                    <div
                        data-test-auto="827fcba4-d935-40b7-aeb4-eeaf18e6cdc1"
                        tabIndex={0}
                        className="closeIcon"
                        onClick={this.props.onCancel}
                        onKeyDown={(e: any) => handleKeyDown(e, this.props.onCancel)}
                    >
                        <CloseIcon />
                    </div>
                </Modal.Header>
                <Modal.Body>
                    <textarea
                        value={this.props.reason}
                        onChange={(e: any) => {
                            this.props.onRemarksChange(e);
                        }}
                        className="form-control remarksTextArea"
                        aria-label="Enter text"
                    />
                </Modal.Body>
                <Modal.Footer>
                    <ButtonComponent
                        onClick={this.props.onCancel}
                        variant={"outline-tertiary"}
                        buttonClassName={"button-margin-right"}
                        size={"medium"}
                    >
                        Cancel
                    </ButtonComponent>
                    <ButtonComponent
                        onClick={this.onSubmit}
                        variant={"primary"}
                        size={"medium"}
                    >
                        Submit
                    </ButtonComponent>
                </Modal.Footer>
            </Modal>
        );
    }
}
