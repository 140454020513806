import * as React from 'react';

export interface WelcomeMessageProps {
    message: string;
    customClassName?: string;
}

export const WelcomeMessage: React.FunctionComponent<WelcomeMessageProps> = ({
    message, customClassName
}) => {
    return (<div className={`welcome ${customClassName}`} dangerouslySetInnerHTML={{ __html: message }}></div>);
}