import * as React from 'react';
import { match, RouteComponentProps } from 'react-router';
import * as SignProcessStore from '../../store/SignProcess/SignProcessStore';
import { SignProcessSteps } from './TaxpayerSignFlowManager';
import { History } from 'history';
import { PathConstants, getRoutePathUrl } from '../Common/Constants';
import TaxpayerHelper from '../Helper/TaxpayerHelper';
import { IClientProcessViewModel } from '../../core/domain/viewModels/IClientProcessViewModel'
import { Role } from '../../core/common/Enums'
import { storeControllerIdInMemory } from '../../core/services/dataAccess/DataService.Axios';

const NO_INDEX = -1;

export type RouteMangerProps = {
    match: match;
    history: History;

}
    & typeof SignProcessStore.actionCreators

export class RouteManager extends React.Component<RouteMangerProps, {}> {
    private _params: any;
    constructor(props: RouteMangerProps) {
        super(props);
    }
    componentDidMount() {
        this._params = this.props.match.params;
        this.getSignProcessStatus(this._params.clientId);
        if (this._params && this._params.controllerId) {
            storeControllerIdInMemory(this._params.controllerId);
        }
    }

    private getSignProcessStatus = (clientId: any) => {
        this.props.requestCurrentStepAndUserRole(clientId, this.handleRedirect);
    }

    private handleRedirect = (clientProcessState: IClientProcessViewModel) => {
        let index = NO_INDEX;

        if (clientProcessState.currentstep.visitedSteps) {
            index = clientProcessState.currentstep.visitedSteps.findIndex((x: SignProcessSteps) => x == SignProcessSteps.SignerProcessComplete);
        }

        if (clientProcessState.isSigned === true || clientProcessState.isDelegated
            || (clientProcessState.isSigned === false && index != NO_INDEX)) {
            this.props.history.push(getRoutePathUrl(PathConstants.CompletionWizard, this._params.clientId, this._params.controllerId));
        } else {
            if (clientProcessState.role.toString() === Role[Role.CPA].toString()) {
                this.props.history.push(getRoutePathUrl(PathConstants.PreviewFlow, this._params.clientId, this._params.controllerId));
            } else {
                this.props.history.push(getRoutePathUrl(PathConstants.SignFlow, this._params.clientId, this._params.controllerId));
            }
        }

    }
    public render() {
        return (<div></div>);
    }
}

