import { injectable } from "inversify";

export interface ILoader {
    show(text?: string): void;
    hide(): void;
}

@injectable()
export class Loader implements ILoader {

    public show(text?: string): void {
        const loaderElement: any = document.getElementById("overlay-loader");
        loaderElement && loaderElement.setAttribute("data-show", "true");
    }

    public hide(): void {
        const loaderElement: any = document.getElementById("overlay-loader");
        loaderElement && loaderElement.removeAttribute("data-show");
    }

}