export interface IAttachmentDownloadViewModel {
    attachmentName: string;
    documentGuid: string;
    taxYear: number;
}

export const initialAttachmentDownloadModel: IAttachmentDownloadViewModel = {
    attachmentName: '',
    documentGuid: '',
    taxYear:0
}