import * as React from 'react';
import { match } from "react-router";
import { InvalidLink } from '../../../components/Common/InvalidLink';
import { DraftStatusView } from './DraftStatusView';
import { TaxReturnStatus} from "../../../core/common/Enums";

interface TaxReturnStatusViewProps {
	match: match<{ status: string }>;
}

export class TaxReturnStatusView extends React.Component<TaxReturnStatusViewProps> {

	constructor(props: any) {
		super(props);
	}

	public render() {

		let params: any = this.props.match.params;
		
		var taxReturnStatus = parseInt(params.status);

		switch (taxReturnStatus) {
			case TaxReturnStatus.DraftApproved:
			case TaxReturnStatus.DraftRejected:
			case TaxReturnStatus.DraftCompleted:
				return <DraftStatusView taxReturnStatus={taxReturnStatus}/>
			default:
				return <InvalidLink/>
		}
	}
}
