import * as React from "react";

interface BodyProps {
    title?: any;
    subTitle?: any;
    containerClassName?: string;
    bodyClassName?: string;
    automationTestId?: string;
    width?: number;
    height?: number;
    noBackgroundColor?: boolean;
    xl?: number;
    lg?: number;
    md?: number;
    sm?: number;
    xs?: number;
    icon?: React.ReactNode;
}

const DEFAULT_WIDTH_LG: number = 9;
const DEFAULT_WIDTH_MD: number = 11;
const DEFAULT_WIDTH_SM: number = 12;
const DEFAULT_WIDTH_XS: number = 12;
const TITLE_HEIGHT: number = 65;

export const Body: React.FunctionComponent<BodyProps> = ({
    title,
    subTitle,
    containerClassName,
    bodyClassName,
    automationTestId,
    width,
    xl,
    lg,
    md,
    sm,
    xs,
    height,
    noBackgroundColor,
    icon,
    children
}) => {
    let getBodyHeightStyle = () => {
        let bodyHeightStyle: string = "";
        const bodyHeight: number = height ? height : 100;

        if (title) {
            bodyHeightStyle = `calc(${bodyHeight}% - ${TITLE_HEIGHT}px)`;
        } else {
            bodyHeightStyle = `${bodyHeight}%`;
        }

        return bodyHeightStyle;
    };

    let getBodyWidthClass = () => {
        let bodyWidthClass: string = "";

        if (xl) {
            bodyWidthClass = bodyWidthClass.concat(" col-xl-", xl.toString());
        }

        if (lg) {
            bodyWidthClass = bodyWidthClass.concat(" col-lg-", lg.toString());
        } else {
            bodyWidthClass = bodyWidthClass.concat(" col-lg-", DEFAULT_WIDTH_LG.toString());
        }

        if (md) {
            bodyWidthClass = bodyWidthClass.concat(" col-md-", md.toString());
        } else {
            bodyWidthClass = bodyWidthClass.concat(" col-md-", DEFAULT_WIDTH_MD.toString());
        }

        if (sm) {
            bodyWidthClass = bodyWidthClass.concat(" col-sm-", sm.toString());
        } else {
            bodyWidthClass = bodyWidthClass.concat(" col-sm-", DEFAULT_WIDTH_SM.toString());
        }

        if (xs) {
            bodyWidthClass = bodyWidthClass.concat(" col-xs-", xs.toString());
        } else {
            bodyWidthClass = bodyWidthClass.concat(" col-xs-", DEFAULT_WIDTH_XS.toString());
        }

        return bodyWidthClass;
    };

    const bodyHeightClass: string = title ? "" : "without-header";
    const bodyColorClass: string = noBackgroundColor ? "transparent" : "white";

    return (
        <div className={"step-layout-body-container " + getBodyWidthClass() + " " + containerClassName}>
            {
                icon ? icon : <></>
            }
            {title && (
                <div
                    data-test-auto={automationTestId ? automationTestId : "11BB28B5-42F3-4571-B567-20700B1740B6"}
                    className={"step-layout-body-header"}
                >
                    <span className="title">{title}</span>
                    {subTitle && (
                        <React.Fragment>
                            <span
                                data-test-auto={
                                    (automationTestId ? automationTestId : "11BB28B5-42F3-4571-B567-20700B1740B6") +
                                    "_sub"
                                }
                                className={"sub-title"}
                            >
                                {subTitle}
                            </span>
                        </React.Fragment>
                    )}
                </div>
            )}
            <div className={"step-layout-body " + bodyHeightClass + " " + bodyColorClass + " " + bodyClassName}>
                {children}
            </div>
        </div>
    );
};
