import * as React from "react";
import { Modal, Button } from "react-bootstrap";
import {
  CCRecipientConstants,
  SiginingConstants,
  K1ShareHolderConstants,
} from "../Common/Constants";
import { Guid } from "../../core/utilities/Guid";
import {
  isValidEmailAddress,
  NullandEmptyCheck,
  validateMobileNumber,
} from "../Common/Validations";
import { MarsNotifier } from "../Common/Notification/MarsNotifier";
var htmlencode = require("htmlencode");
import {
  ICCRecipientDownloadableDocuments,
  ICCRecipientModel,
  CCRecipientDocumentGroups,
} from "../../core/domain/models/ICCRecipientModel";
import { Popover, Alert, Overlay } from "react-bootstrap";
import { match } from "react-router";
import { container } from "../../startup/inversify.config";
import { TYPES } from "../../startup/types";
import { IDialogBox } from "../../core/utilities/ui/DialogBox";
import { logger } from "../../../src/routes";
import MessageNote from "../Common/Notification/MessageNote";
import "@sssuite-component-ui/react-phone-input-2/lib/style.css";
import PhoneInput, {
  CountryData,
} from "@sssuite-component-ui/react-phone-input-2";
import { NotificationType } from "../../core/common/Enums";
import {
  CCRecipientAddIcon,
  CCRecipientRemoveIcon,
  CloseIcon,
} from "../Common/Icons/SvgIcons";
import { ButtonComponent } from "cp-common-ui-components";
import { handleKeyDown } from "../Helper/HelperFunction";

export interface CCRecipientProps {
  show?: boolean;
  closeCCRecipientModal?: () => void;
  downloadableDocuments?: ICCRecipientDownloadableDocuments[];
  taxdocumentId?: number;
  forwardDocuments?: (
    clientId: string,
    recipients: ICCRecipientModel,
    callback: () => void
  ) => void;
  match: match;
}

interface CCRecipientState {
  ccRecipients: IRecipient[];
  documents: number[];
  showAlert: boolean;
  showPopOver: boolean;
  popoverTarget: any;
  selectAll: boolean;
}

export interface IRecipient {
  clientGuid: Guid;
  email: string;
  mobileNumber: string;
  countryCode: string;
}

const dialogBox = container.get<IDialogBox>(TYPES.IDialogBox);

export class AddCCRecipientModal extends React.Component<
  CCRecipientProps,
  CCRecipientState
> {
  private attachAddPopoverRef = (target: any) => {
    this.setState({ popoverTarget: target });
  };

  constructor(props: any) {
    super(props);
    this.state = {
      ccRecipients: [
        {
          clientGuid: Guid.newGuid(),
          email: "",
          mobileNumber: "",
          countryCode: "",
        },
      ],
      documents: [],
      showAlert: false,
      showPopOver: false,
      popoverTarget: {},
      selectAll: false,
    };
  }

  onEmailChange = (e: any, index: number) => {
    const recipientEmail = e.target.value;
    let ccRecipient = this.state.ccRecipients;
    ccRecipient[index].email = recipientEmail;
    this.setState({
      ccRecipients: ccRecipient,
    });
  };

  onNumberChange = (value: string, index: number) => {
    const recipientNumber = value;
    let ccRecipient = this.state.ccRecipients;
    ccRecipient[index].mobileNumber = recipientNumber;
    this.setState({
      ccRecipients: ccRecipient,
    });
  };

  onChangeCountryCode = (
    value: string,
    countryObject: CountryData,
    index: number
  ) => {
    const recipientCountryCode = `+${countryObject.dialCode}`;
    let ccRecipient = this.state.ccRecipients;
    ccRecipient[index].countryCode = recipientCountryCode;
    ccRecipient[index].mobileNumber = value.slice(
      countryObject.dialCode.length
    );
    this.setState({
      ccRecipients: ccRecipient,
    });
  };

  onEmailInputKeyDown = (e: any, idx: number) => {
    if (e.keyCode === 13) {
      e.preventDefault();
      if (NullandEmptyCheck(this.state.ccRecipients[idx].email)) {
        if (!isValidEmailAddress(this.state.ccRecipients[idx].email)) {
          MarsNotifier.Warning(
            SiginingConstants.WarningMessage.InvalidEmailAddress,
            null
          );
          return;
        }
        this.getCountryCodeInputFocus(idx);
      } else {
        MarsNotifier.Warning(
          K1ShareHolderConstants.WarningMessage.EmailAdrressEmpty,
          null
        );
        return;
      }
    }
  };

  onNumberInputKeyDown = (e: any, idx: number) => {
    if (e.keyCode === 13 || e.keyCode === 9) {
      e.preventDefault();
      if (idx < this.state.ccRecipients.length - 1) {
        this.getEmailInputFocus(idx + 1);
      } else {
        const focusOutInput = document.getElementById("ccMobile" + idx);
        focusOutInput && focusOutInput.blur();
      }
    }
  };

  onCountryCodeInputKeyDown = (e: any, idx: number) => {
    if (e.keyCode === 13 || e.keyCode === 9) {
      this.getNumberInputFocus(idx);
    }
  };

  handleDelete = (inputToDelete: IRecipient, idx: number) => {
    if (idx > 0) {
      this.setState({
        ccRecipients: this.state.ccRecipients.filter(
          (input, index) => idx !== index
        ),
      });
    } else {
      let recipients = this.state.ccRecipients;
      recipients[idx].mobileNumber = "";
      recipients[idx].email = "";
      recipients[idx].countryCode = "+1";
      this.setState({
        ccRecipients: recipients,
      });
    }
  };

  handleClose = () => {
    if (
      this.state.ccRecipients.some((x) => x.email != "") ||
      this.state.ccRecipients.some((x) => x.mobileNumber != "") ||
      this.state.ccRecipients.some((x) => x.countryCode != "")
    ) {
      this.setState({ showPopOver: true });
      return;
    }
    this.clearSections();
    this.props.closeCCRecipientModal && this.props.closeCCRecipientModal();
  };

  handleCancel = () => {
    this.clearSections();
    this.props.closeCCRecipientModal && this.props.closeCCRecipientModal();
  };

  saveDocumentsToShow = (
    event: any,
    documentGroup: CCRecipientDocumentGroups
  ) => {
    if (event.target.checked) {
      let documentGroups = [...this.state.documents, documentGroup];
      this.setState({ documents: documentGroups });
    } else {
      this.setState({
        documents: this.state.documents.filter(
          (groupId, index) => groupId !== documentGroup
        ),
      });
    }
  };

  handleSelectAll = (event: any) => {
    if (event.target.checked) {
      let documentGroups: number[] = [];
      this.props.downloadableDocuments &&
        this.props.downloadableDocuments.map((document, i) => {
          documentGroups.push(document.documentGroup);
        });
      this.setState({ documents: documentGroups, selectAll: true });
    } else {
      this.setState({ documents: [], selectAll: false });
    }
  };

  mailDocumentsToRecipients = () => {
    if (!this.state.ccRecipients.every((x) => x.email != "")) {
      MarsNotifier.Warning(CCRecipientConstants.CcEmptyWarning, null);
      return;
    } else if (this.state.documents.length == 0) {
      MarsNotifier.Warning(
        CCRecipientConstants.DocumentsNotSelectedWarning,
        null
      );
      return;
    }

    if (
      !this.state.ccRecipients
        .map((recipient, index) => {
          return isValidEmailAddress(recipient.email);
        })
        .every((x: boolean) => x == true)
    ) {
      MarsNotifier.Warning(CCRecipientConstants.InvalidEmail, null);
      return;
    }
    if (
      !this.state.ccRecipients
        .map((recipient, index) => {
          if (recipient.mobileNumber.trim() !== "") {
            if (
              !validateMobileNumber(
                recipient.mobileNumber,
                recipient.countryCode
              )
            ) {
              return false;
            }
          }
          return true;
        })
        .every((x: boolean) => x == true)
    ) {
      return;
    }

    let recipientInfo: ICCRecipientModel = {
      documentId: 0,
      downloadableDocuments: [],
      recipients: [],
    };
    recipientInfo.documentId = this.props.taxdocumentId;
    recipientInfo.recipients = this.state.ccRecipients;
    recipientInfo.downloadableDocuments = this.state.documents;
    const param: any = this.props.match.params;
    this.props.forwardDocuments &&
      this.props.forwardDocuments(param.clientId, recipientInfo, () => {
        this.onSuccess();
        logger.trackTrace("Documents forwarded to cc recipient", {
          ClientId: param.clientId,
          DocumentGroups: this.state.documents,
        });
      });
  };

  onSuccess = () => {
    MarsNotifier.Success(CCRecipientConstants.SuccessfulyMailedDocuments, null);
    this.confirmPopoverClose();
  };

  getNumberInputFocus = (index: number) => {
    const inputNumberRef = document.getElementById("ccMobile" + index);
    inputNumberRef && inputNumberRef.focus();
  };

  getEmailInputFocus = (index: number) => {
    const inputEmailRef = document.getElementById("ccEmail" + index);
    inputEmailRef && inputEmailRef.focus();
  };

  getCountryCodeInputFocus = (index: number) => {
    const inputCountryCodeRef: any = document.getElementById(
      "ccRecipientCountryCode" + index
    );
    const innerHtml: any =
      inputCountryCodeRef &&
      inputCountryCodeRef.children[0].children[0].children[1].children[0]
        .innerHTML;
    const selectorId: string =
      innerHtml && innerHtml.match(/react-select-\d+-input/)[0];
    const countrySelector = document.getElementById(selectorId);
    countrySelector && countrySelector.focus();
  };

  clearSections = () => {
    this.setState({
      ccRecipients: [
        {
          clientGuid: Guid.newGuid(),
          email: "",
          mobileNumber: "",
          countryCode: "",
        },
      ],
      documents: [],
      selectAll: false,
    });
  };

  addNewRecipientInputRow = () => {
    let recipients = [
      ...this.state.ccRecipients,
      {
        clientGuid: Guid.newGuid(),
        email: "",
        mobileNumber: "",
        countryCode: "",
      },
    ];

    this.setState({ ccRecipients: recipients });
  };

  confirmPopoverClose = () => {
    this.setState({ showPopOver: false });
    this.clearSections();
    this.props.closeCCRecipientModal && this.props.closeCCRecipientModal();
  };

  hidePopover = () => {
    this.setState({ showPopOver: false });
  };

  deleteConfirmation = () => {
    return (
      <Popover
        id="closeCCRecipientPopover"
        placement="left"
        data-test-auto="C4ED4674-B4EA-4649-BD90-47F944980BB6"
      >
        <div className="popover-header">
          Discard Changes
          <button
            type="button"
            className="close"
            data-test-auto="15613D09-8326-4FE6-B256-F30085B56534"
            onClick={this.hidePopover}
          >
            &times;
          </button>
        </div>
        <div className="popover-body">
          <div>Are you sure you want to discard the changes made? </div>
        </div>
        <div className="modal-footer">
          <ButtonComponent
            data-test-auto="313B8870-6D87-40F8-B2C0-FEE5C2423279"
            onClick={this.hidePopover}
            variant={"outline-tertiary"}
            size={"medium"}
            buttonClassName={"button-margin-right"}
          >
            No
          </ButtonComponent>
          <ButtonComponent
            data-test-auto="1E01E792-06DB-4361-A944-9A010FAB9EB6"
            variant={"primary"}
            size={"medium"}
            onClick={this.confirmPopoverClose}
          >
            Yes
          </ButtonComponent>
        </div>
      </Popover>
    );
  };

  showSuccessAlert = () => {
    return (
      <Alert
        show={this.state.showAlert}
        variant="success"
        data-test-auto="664C5275-3E9C-4F7B-B04F-A03CC43D63A3"
      >
        <p data-test-auto="83D62FD4-D2DB-480E-99A2-13A3F490E78D">
          Email sent Successfully
        </p>
        <hr />
        <div className="d-flex justify-content-end">
          <Button
            variant="outline-success"
            data-test-auto="5CA98E7B-BAF7-4DBC-A76B-AFA1F2F5F8E7"
          >
            Ok
          </Button>
        </div>
      </Alert>
    );
  };

  renderDownloadableDocumentDetails = (
    downloadableDocument: ICCRecipientDownloadableDocuments,
    index: number
  ) => {
    return (
      <div key={downloadableDocument.fileName + index}>
        <input
          type="checkbox"
          id={`checkbox-${index}`}
          className="chkCcRecipientModalSelect"
          data-test-auto={"18EF199A-D9BE-42A6-ABAB-533174ABD07" + index}
          key={
            downloadableDocument.fileName + downloadableDocument.documentGroup
          }
          checked={
            this.state.documents.findIndex(
              (groupId) => groupId == downloadableDocument.documentGroup
            ) > -1
              ? true
              : false
          }
          onChange={(e: any) =>
            this.saveDocumentsToShow(e, downloadableDocument.documentGroup)
          }
          onKeyDown={(e: any) =>
            handleKeyDown(e, () => {
              if (
                this.state.documents.findIndex(
                  (groupId) => groupId == downloadableDocument.documentGroup
                ) > -1
              ) {
                this.saveDocumentsToShow(
                  { target: { checked: false } },
                  downloadableDocument.documentGroup
                );
              } else {
                this.saveDocumentsToShow(
                  { target: { checked: true } },
                  downloadableDocument.documentGroup
                );
              }
            })
          }
        />
        <label
          htmlFor={`checkbox-${index}`}
          className="ccRecipientModalDownloadableDocument"
        >
          {downloadableDocument.documentGroup ==
          CCRecipientDocumentGroups.SignedEFile
            ? "E-file Forms"
            : downloadableDocument.documentGroup ==
              CCRecipientDocumentGroups.TaxReturns
            ? "Tax Return"
            : downloadableDocument.fileName}
        </label>
      </div>
    );
  };

  public render() {
    return (
      <Modal
        show={this.props.show && this.props.show}
        className="ccRecipientModalPopup"
      >
        <Modal.Header data-test-auto="01897DA0-02F5-45C6-9D29-E0C2F0C4E7C9">
          <Modal.Title>
            {CCRecipientConstants.ForwardDocumentPopupHeader}
          </Modal.Title>
          <div
            data-test-auto="75613D09-8326-4FE6-B256-F30085B56534"
            className="closeIcon"
            data-dismiss="modal"
            onClick={this.handleClose}
            ref={this.attachAddPopoverRef}
            tabIndex={0}
            onKeyDown={(e: any) => handleKeyDown(e, this.handleClose)}
          >
            <CloseIcon />
          </div>
          <Overlay
            placement="left"
            data-test-auto="AE380218-167B-4BD2-A179-75BC737BCEF7"
            target={this.state.popoverTarget}
            show={this.state.showPopOver}
            rootClose={true}
          >
            {this.deleteConfirmation}
          </Overlay>
        </Modal.Header>
        <Modal.Body>
          <div style={{ paddingLeft: "16px" }}>
            <div data-test-auto="BAD25332-6C0A-46C0-88DA-6FD34DDD0F69">
              <MessageNote
                note={CCRecipientConstants.Note}
                noteType={NotificationType.Warning}
              />
            </div>
            <br />
            <div
              className="ccRecipientModalDescription"
              dangerouslySetInnerHTML={{
                __html: htmlencode.htmlDecode(
                  CCRecipientConstants.ModalDescription
                ),
              }}
            ></div>
            <div
              data-test-auto="F366A8F2-401C-4188-9DE3-3CF62F568763"
              className="section-header-text"
            >
              <label>{CCRecipientConstants.DocumentForwardText}</label>
            </div>
            <div className="marB10">
              <input
                type="checkbox"
                id="selectAllCheckbox"
                className="chkCcRecipientModalSelect"
                data-test-auto={"83A6BED4-6BF8-4D1D-BBA4-96AB8C868A3A"}
                key={"select-All"}
                checked={this.state.selectAll}
                onKeyDown={(e: any) =>
                  handleKeyDown(e, () => {
                    if (this.state.selectAll) {
                      this.handleSelectAll({ target: { checked: false } });
                    } else {
                      this.handleSelectAll({ target: { checked: true } });
                    }
                  })
                }
                onChange={(event: any) => {
                  this.handleSelectAll(event);
                }}
              />
              <label
                htmlFor="selectAllCheckbox"
                className="lblCcRecipientModalSelectAll"
              >
                {" "}
                Select All
              </label>
            </div>
            <div>
              <div className="row">
                <div className="col-lg-6 col-md-6 col-sm-6">
                  {this.props.downloadableDocuments &&
                    this.props.downloadableDocuments.map((document, i) => {
                      if (i <= 3) {
                        return this.renderDownloadableDocumentDetails(
                          document,
                          i
                        );
                      }
                    })}
                </div>

                <div className="col-lg-6 col-md-6 col-sm-6">
                  {this.props.downloadableDocuments &&
                    this.props.downloadableDocuments.map((document, i) => {
                      if (i > 3) {
                        return this.renderDownloadableDocumentDetails(
                          document,
                          i
                        );
                      }
                    })}
                </div>
              </div>
            </div>
            <div className="section-header-text">
              <label>Add Contact(s)</label>
            </div>
            <div style={{ display: "flex" }}>
              <div className="labelCCRecipientEmail">Email</div>
              <div className="labelCCRecipientMobile">Phone</div>
            </div>
            <div className="ccRecipientModalBodyContent">
              {this.state.ccRecipients.length > 0 &&
                this.state.ccRecipients.map((recipient, index) => {
                  return (
                    <div
                      key={recipient.clientGuid.toString()}
                      style={{ display: "flex" }}
                    >
                      <div>
                        <input
                          id={"ccEmail" + index}
                          className="inputCCRecipientEmail"
                          aria-label="Enter cc recipient email"
                          type="text"
                          onChange={(event: any) => {
                            this.onEmailChange(event, index);
                          }}
                          onKeyDown={(e: any) => {
                            this.onEmailInputKeyDown(e, index);
                          }}
                          value={this.state.ccRecipients[index].email}
                          data-lpignore="true"
                          data-test-auto="FE0309F3-01BC-4517-A8FD-0F12B2A4BF9F"
                          autoComplete="off"
                        />
                      </div>

                      <div>
                        <PhoneInput
                          containerClass={`inputCCRecipientMobile`}
                          inputProps={{
                            "aria-label": "Enter cc recipient mobile number",
                          }}
                          country={"us"}
                          autoFormat={true}
                          value={`${this.state.ccRecipients[index].countryCode} ${this.state.ccRecipients[index].mobileNumber}`}
                          onChange={(
                            fullValue: string,
                            countryObject: CountryData
                          ) => {
                            this.onChangeCountryCode(
                              fullValue,
                              countryObject,
                              index
                            );
                          }}
                          data-test-auto="F4C7445C-E947-423D-BE32-1D3817519C5C"
                        />
                      </div>

                      <div
                        tabIndex={0}
                        className="deleteContactContainer"
                        onClick={() => {
                          this.handleDelete(recipient, index);
                        }}
                        onKeyDown={(e: any) =>
                          handleKeyDown(e, () => {
                            this.handleDelete(recipient, index);
                          })
                        }
                        data-test-auto="6944FE2C-8C70-4334-8049-454A2D7809F8"
                      >
                        <CCRecipientRemoveIcon />
                        <span>Delete</span>
                      </div>
                    </div>
                  );
                })}

              <div
                tabIndex={0}
                className="btnAddNewCCRecipient"
                onClick={this.addNewRecipientInputRow}
                onKeyDown={(e: any) =>
                  handleKeyDown(e, this.addNewRecipientInputRow)
                }
                data-test-auto="F8A14D37-AAB9-44FE-99F8-43D568E14F83"
              >
                <CCRecipientAddIcon />
                <span>Add New Contact</span>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <ButtonComponent
            data-test-auto="2FFE3287-E2C3-4EAC-B75D-EFE26485E105"
            id="btnHideCCRecipientPopUp"
            onClick={this.handleCancel}
            variant={"outline-tertiary"}
            size={"medium"}
            buttonClassName={"button-margin-right"}
          >
            Cancel
          </ButtonComponent>
          <ButtonComponent
            data-test-auto="E927BDC4-A00E-4A67-9D5D-F5CA7CC46BBB"
            variant={"primary"}
            onClick={this.mailDocumentsToRecipients}
            size={"medium"}
          >
            Send
          </ButtonComponent>
        </Modal.Footer>
      </Modal>
    );
  }
}
