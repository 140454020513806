import * as React from "react";
import { match } from "react-router";
import { Modal } from "react-bootstrap";
import { PaperFileReturnConstants } from "../Common/Constants";
import { ButtonComponent } from "cp-common-ui-components";
import { CloseIcon } from "../Common/Icons/SvgIcons";
import { handleKeyDown } from "../Helper/HelperFunction";

export interface PaperFileConsentModalProps {
  match: match;
  showModal: boolean;
  onConsentAgreed(clientId: string): void;
  onConsentSkip(): void;
  skipButtonText: string;
}

export class PaperFileConsentModal extends React.Component<
  PaperFileConsentModalProps,
  {}
> {
  constructor(props: any) {
    super(props);
    this.setState({
      clientGuid: props.clientGuid,
    });
  }

  onConsent = () => {
    let param: any = this.props.match.params;
    this.props.onConsentAgreed(param.clientId);
  };

  onSkip = () => {
    this.props.onConsentSkip();
  };

    public render() {

        return (
            <Modal show={this.props.showModal} id="paperfileconsentPopup">
                <Modal.Header data-test-auto="11897DA0-02F5-45C6-9D29-E0C2F0C4E7C9">
                    <Modal.Title>
                        <div style={{ fontSize: "20px" }}>{PaperFileReturnConstants.ConsentHeader}</div>
                    </Modal.Title>
                    <div
                        data-test-auto="ac458f72-9fd9-4a01-b8b1-1d4d27de2686"
                        className="closeIcon"
                        onClick={this.onSkip}
                        tabIndex={0}
                        onKeyDown={(e: any) => handleKeyDown(e, this.onSkip)}
                    >
                        <CloseIcon />
                    </div>
                </Modal.Header>
                <Modal.Body>
                    <div>
                        { PaperFileReturnConstants.ConsentAcknowledgementInstruction }
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <ButtonComponent
                        data-test-auto="D927BDC1-A00E-4A67-9D5D-F5CA7CC46BBB"
                        onClick={this.onSkip}
                        variant={"outline-tertiary"}
                        size={"medium"}
                        buttonClassName={"button-margin-right"}
                    >
                        {this.props.skipButtonText}
                    </ButtonComponent>
                    <ButtonComponent
                        data-test-auto="B927BDC4-A00E-4A67-9D5D-F5CA7CC46BBB"
                        onClick={this.onConsent}
                        variant={"primary"}
                        size={"medium"}
                    >
                        {PaperFileReturnConstants.ConsentButton}
                    </ButtonComponent>
                </Modal.Footer>
            </Modal >

        )
    }
}