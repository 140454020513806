import * as React from "react";
import HeaderComp from "../Header/HeaderComp";
import Skeleton from "react-loading-skeleton";
import { logger } from "../../routes";
import Footer from "../Layout/Footer";
import OtpInputControl from "../Common/Controls/OTPInputControl";
import { SvgIconAccessCode } from "../Common/Icons/BrandIcons";
import EmailOtp from "../Common/OTP/OTPEmail";
import { DisplayError } from "../Common/Constants";

export class OTPRequest extends React.Component<any, any> {
  continueRef: any = null;

  constructor(props: any) {
    super(props);
    this.state = {
      otpVal: "",
      continueState: false,
      disableButton: false,
      isInitialOTPSent: false,
      otpError: {
        error: false,
        message: "",
      },
    };

    this.handleOTPChange = this.handleOTPChange.bind(this);
    this.requestCode = this.requestCode.bind(this);
    this.verifyOTP = this.verifyOTP.bind(this);
    this.handleOTPVerification = this.handleOTPVerification.bind(this);
  }

  componentDidMount() {
    this.props.requestHeaderDetails(this.props.match.params.clientId);
    this.props.pageValidity(
      this.props.match.params.clientId,
      this.gotToErrorPage
    );
  }

  handleOTPChange(e: any) {
    let otpValue = e
    if (otpValue.length <= 8 && (!isNaN(otpValue) || otpValue.length === 0)) {
      if (otpValue.length === 8) {
        this.setState({ otpVal: otpValue, continueState: true });
      } else {
        this.setState({ otpVal: otpValue, continueState: false });
      }
    }
  }

  gotToErrorPage = (result: any) => {
    let errorDesc = result.errorDescription;
    let errorCode = result.errorCode;
    if (errorCode && DisplayError.includes(errorCode)) {
      this.props.history.push("/Error/" + errorDesc);
    } else {
      this.setState({
        otpError: { error: true, message: errorDesc },
        otpVal: "",
      });
    }
  };

  handleOTPVerification(guid: string) {
    console.log("in handleOTP");
    this.props.history.push(`/Taxpayerflow/${guid}`);
  }

  requestCode() {
    if (!this.state.isInitialOTPSent) this.setState({ isInitialOTPSent: true });
    const clientId = this.props.match.params.clientId;
    logger.trackTrace("Request Access Code button clicked", {
      ClientId: clientId,
    });

    this.setState({ disableButton: true }, () => {
      this.props.generateOTP(clientId, () => {
        this.setState({ disableButton: false });
      });
    });
  }

  verifyOTP() {
    if (this.state.continueState) {
      this.props.verifyOTP(
        this.state.otpVal,
        this.props.match.params.clientId,
        this.handleOTPVerification,
        this.gotToErrorPage
      );
    }
  }

  render() {

    return (
      <>
        <HeaderComp
          headerInfo={this.props.headerInfoState}
          pathname={this.props.match.path}
          clientId={this.props.match.params.clientId}
          history={this.props.history}
          backButton={true}
          navigateLink="/CoverPage/Index"
        />
        {this.props.OTPPageState.loading === true ? (
         <Skeleton circle={false} height={"100vh"} width={"100vw"} />
        ) : (
            <EmailOtp 
                    match={this.props.match}
                    history={this.props.history}
                    headerInfoState={this.props.headerInfoState}
                    OTPPageState={this.props.OTPPageState}
                    otpVal={this.state.otpVal}
                    continueState={this.state.continueState}
                    isInitialOTPSent={this.state.isInitialOTPSent}
                    otpError={this.state.otpError}
                    handleOTPChange={this.handleOTPChange}
                    requestCode={this.requestCode}
                    verifyOTP={this.verifyOTP}
            />
            )}
            <Footer />
        </>
    );
  }
}
export default OTPRequest;
