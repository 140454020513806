import * as React from 'react';
import { IRefundInfo } from '../../../core/domain/models/IRefundInfo';
import { IRefund, IVoucher, VoucherTypes } from '../../../core/domain/models/IGroup';
import { getPaymentDue, getRefunds, getOverPaymentApplied } from '../../../core/domain/viewModels/ISummaryViewModel'
import { ITaxingAuthority } from '../../../core/domain/models/ITaxingAuthority';
import { TYPES } from '../../../startup/types';
import { container } from '../../../startup/inversify.config';
import { IUtilities } from '../../../core/utilities/Utilities';
import { Shimmer } from '../../../components/Common/Shimmer/Shimmer';
import { IHeaderInfoViewModel } from '../../../core/domain/viewModels/IHeaderInfoViewModel';
import { Down, PrintIcon, SvgIconPrint, Up } from '../../Common/Icons/SvgIcons';

const utilities = container.get<IUtilities>(TYPES.IUtilities);

export interface PaymentAndRefundProps {
    taxYear: number,
    vouchers: IVoucher[],
    refunds: IRefund[],
    addedRefunds: IRefundInfo[],
    taxingAuthorities: ITaxingAuthority[],
    showPaymentRefundPrint: boolean,
    headerInfo?: IHeaderInfoViewModel;
    card1Open: boolean;
    toggleCards: (card: number) => void;
    isEstimatedVouchersEmpty: boolean;
}

export class PaymentAndRefund extends React.Component<PaymentAndRefundProps, {}> {

    constructor(props: PaymentAndRefundProps) {
        super(props);
    }

    printvoucherDetails = () => {
        const { headerInfo } = this.props;
        const companyLogo: any = headerInfo?.companyLogoPath ? '<div class="title">'+headerInfo.companyName+'</div><hr>' : '<div><img src="' + headerInfo?.companyLogoPath + '" width="150" height="50"></div><hr>';
        const printContent: any = document.getElementById("summary-review");
        const WindowPrt: any = window.open('', '_blank', 'left=0,top=0,width=900,height=900,toolbar=0,scrollbars=0,status=0');
        if (WindowPrt) {
            WindowPrt.document?.write('<html><head>');
            WindowPrt.document?.write("<style>.payment-refund-container {overflow: auto;} " +
                " .total-amount-green {	border-top: 1px solid #000; font-size: 14px; font-weight: bold; font-stretch: normal; font-style: normal; line-height: 1.5; letter-spacing: normal; width: 40% !important; } " +
                " .total-amount-red { border-top: 1px solid #000; font-size: 15px; font-weight: 700; width: 40% !important; }" +
                " .title { font-size: 19px;font-weight: 600;margin-bottom: 5px;margin-left: 5px } " +
                ".estimated-voucher-container { margin-top: 3%; overflow: auto; } #preparer_message {display: none}  #complete_sum {display: none}" +
                ".payment-refund-container.title, .estimated-voucher-container.title {font-size: 19px;font-weight: 600;}" +
                ".payment-refund-container.body, .estimated-voucher-container.body {padding: 0px 10px;}" +
                ".content-left > div {padding: 10px;background-color: #fff;border-radius: 5px;position: relative;}" +
                ".red {color: #e00d0d;}.fllft {float: left;}" +
                ".content-left .section-title {color: #0973ba;font-weight: bold;font-size: 13px;clear: both;padding-bottom: 7px}" +
                ".content-left .sub-section .authority {font-size: 14px;font-weight: bold;} .payment-refund-print{display:none}" +
                ".content-left .sub-section div {width: 50%;position: relative;padding-bottom: 7px}" +
                ".sub-section .ellipsis {overflow: hidden;text-overflow: ellipsis;white-space: nowrap;display: block;padding: inherit;}" +
                ".currencytext {text-align: right;padding-right: 0px;}.flrt {float: right;}.green {color: #88c656;}" +
                "#welcomeMessage{display: none}"+
                "#completedSummary{display: none}"+
                ".drop_action_button_Up{visibility: hidden}"+
                ".drop_action_button{visibility: hidden}");
            WindowPrt.document?.write('</style></head><body>');
            WindowPrt.document?.write(companyLogo);
            WindowPrt.document?.write(printContent.innerHTML);
            WindowPrt.document?.write('</body></html>');
            WindowPrt.document?.close();
            WindowPrt.focus();
            WindowPrt.print();
        }
    }

    public render() {
        const paymentDue = getPaymentDue(this.props.vouchers, this.props.taxingAuthorities);
        const refunds = getRefunds(this.props.refunds, this.props.addedRefunds, this.props.taxingAuthorities);
        const overPaymentApplied = getOverPaymentApplied(this.props.refunds, this.props.addedRefunds, this.props.taxingAuthorities);

        return (
            <div className={`company_cards_container ${this.props.card1Open ? this.props.isEstimatedVouchersEmpty ? 'isEstimatedVouchersEmpty' : 'full_height_container' : ''}`}>
        <div className='company_card_header'>
            <div className="title" data-test-auto="ED219186-CB35-45C9-A104-7CF42D6596D0">{this.props.taxYear ? (this.props.taxYear + " Tax Return ")
                : <Shimmer lineCount={13} />}
                {!this.props.showPaymentRefundPrint &&
                    <PrintIcon
                        className="payment-refund-print"
                        data-test-auto="7D102693-26AC-4DF0-9C06-17AEA7B8E27E"
                        onClick={this.printvoucherDetails.bind(this)} />
                }
            </div>
            </div>
            <div className={`company_card_body ${this.props.card1Open ? 'full_height_container' : ''}`}>
                {
                    refunds.Items && refunds.Items.length > 0 && refunds.Items.find(x => x.value > 0) &&
                    <section className="preSelect" key={refunds.GroupName}>
                        <div className="section-title" data-test-auto="3721C0B0-177B-45E5-87F2-EEA08D0B63BC">{refunds.GroupName}</div>
                        {
                            refunds.Items.map((item, i) => {
                                if (item.value > 0)
                                    return (<div key={refunds.GroupName + item.name + i} className="sub-section">
                                        <div className="authority fllft ellipsis">{item.name}</div>
                                        <div className="amount flrt green currencytext">${utilities.formatCurrencyText(item.value)}</div>
                                    </div>)
                            })
                        }
                        {
                            <div className="sub-section" data-test-auto="196ECDFA-277D-4127-B234-1B6BEEE72BD9">
                                <div className="green fllft ellipsis"><span className="flrt total-label-green">Total</span></div>
                                <div className="flrt currencytext green total-amount-green">${utilities.formatCurrencyText(refunds.Total)}</div>
                            </div>
                        }
                    </section>
                }
                {
                    overPaymentApplied.Items && overPaymentApplied.Items.length > 0 &&
                    <section className="preSelect" key={overPaymentApplied.GroupName}>
                        <div className="section-title" data-test-auto="B527F1A4-C6DC-46FC-A3FA-7538335DAC9A">{overPaymentApplied.GroupName}</div>
                        {
                            overPaymentApplied.Items.map((item, i) => {
                                return (<div key={overPaymentApplied.GroupName + item.name + i} className="sub-section">
                                    <div className="authority fllft ellipsis">{item.name}</div>
                                    <div className="amount flrt green currencytext">${utilities.formatCurrencyText(item.value)}</div>
                                </div>)
                            })
                        }
                        {
                            <div className="sub-section" data-test-auto="408C067A-6212-4D6D-BEC2-7E04F7F3064A">
                                <div className="green fllft ellipsis"><span className="flrt total-label-green">Total</span></div>
                                <div className="flrt currencytext green total-amount-green">${utilities.formatCurrencyText(overPaymentApplied.Total)}</div>
                            </div>
                        }
                    </section>
                }
                {
                    paymentDue.Items && paymentDue.Items.length > 0 &&
                    <section className="preSelect" key={paymentDue.GroupName}>
                        <div className="section-title" data-test-auto="DFC7A2FB-CE86-4006-94E3-9CDB00D8E1CF">{paymentDue.GroupName}</div>
                        {
                            paymentDue.Items.map((item, i) => {
                                return (<div key={paymentDue.GroupName + item.name + i} className="sub-section">
                                    <div className="authority fllft ellipsis">{item.name}</div>
                                    <div className="amount flrt red currencytext">${utilities.formatCurrencyText(item.value)}</div>
                                </div>)
                            })
                        }
                        {
                            <div className="sub-section" data-test-auto="E651FB56-E074-44F5-87CB-2EA5003E4EEF">
                                <div className="red fllft ellipsis"><span className="flrt total-label-red">Total</span></div>
                                <div className="flrt currencytext red total-amount-red">${utilities.formatPaymentDueCurreny(paymentDue.Total)}</div>
                            </div>
                        }
                    </section>
                }
                {
                    this.props.taxYear > 0
                    && refunds.Items
                    && refunds.Items.length === 0
                    && overPaymentApplied.Items
                    && overPaymentApplied.Items.length === 0
                    && paymentDue.Items
                    && paymentDue.Items.length === 0
                    && <div className="margin-top-20">
                        No Refunds or Payments Due
                    </div>
                }
            </div>
            <div
                data-test-auto='21c4f274-e553-4085-9da4-ed306a47fa54'
                className={`${this.props.card1Open ? 'drop_action_button' : 'drop_action_button_Up'}`}
                onClick={() => {
                    this.props.toggleCards(1);
                }}>
                {this.props.card1Open ? (
                    <div>
                        <Up />
                    </div>
                ) : (
                    <div>
                        <Down />
                    </div>
                )}
            </div>
        </div>);
    }
}
