
import { connect } from 'react-redux';
import RouteSelector from "./RouteSelector";
import * as OTPReducer from "../../store/CCRecipient/OTPStore";



export default connect(
    (state: any) => ({

    }),
    {
        ...OTPReducer.actionCreators

    }
)(RouteSelector as any);