import { IDownloadableDocuments, } from '../models/ITaxReturn';
import { IAttachment } from '../models/IAttachment';
import { IAdditionalEsignDocument } from '../models/IAdditionalEsignDocument';
import { IDownloadedZipFilesModel } from '../../../components/Layout/MyDownload';
import { IPaperFileReturnDetails } from '../models/IPaperFileReturn';

export interface IDownloadableDocumentsViewModel {
	downloadableDocuments: IDownloadableDocuments[],
	attachments: IAttachment[],
    additionalEsignDocuments: IAdditionalEsignDocument[],
    myDownloadList: IDownloadedZipFilesModel[],
    paperFiles: IPaperFileReturnDetails[]
}

export const initialDownloadableDocumentsViewModel: IDownloadableDocumentsViewModel = {
	downloadableDocuments: [],
	additionalEsignDocuments: [],
    attachments: [],
    myDownloadList:[],
    paperFiles:[]
}