import * as React from 'react';
import { TaxReturnStatus} from "../../../core/common/Enums";

interface DraftStatusViewState {

}

interface DraftStatusViewProps {
    taxReturnStatus: TaxReturnStatus;
}

export class DraftStatusView extends React.Component<DraftStatusViewProps, DraftStatusViewState> {

	constructor(props: DraftStatusViewProps, states: DraftStatusViewState) {
        super(props, states);
    }

	public render() {
		return (this.props.taxReturnStatus == TaxReturnStatus.DraftCompleted ? this.getDraftCompletedStatusView() : this.getDrafStatusView());
	}

	getDrafStatusView = () => {
		return <div>
				<div className="invalid-container">
					<div className="invalid-header">
				</div>
				<div className="content-wrapper">
					<div className='draftstatus-view' style={{ position: 'absolute', top: '30%', left: '40%', textAlign: 'center'}}>
						<div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
							Thank you,<br></br>
										For Completing your review!
						</div>
						<br></br>
						<div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
							Your CPA will be in touch with you
						</div>
						<br></br>
						<div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
						You can now close this window
						</div>
					</div>
				</div>
			</div>
		</div>
	};

	getDraftCompletedStatusView = () => {
		return <div>
				<div className="invalid-container">
					<div className="invalid-header">
				</div>
				<div className="content-wrapper">
					<div className='draftstatus-view' style={{ position: 'absolute', top: '30%', left: '40%', textAlign: 'center'}}>
						<div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
							This task has already<br></br>
										been completed!
						</div>
						<br></br>
						<div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
							Your CPA will be in touch with you
						</div>
						<br></br>
						<div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
						You can now close this window
						</div>
					</div>
				</div>
			</div>
		</div>
	};
}


