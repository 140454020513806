import { Guid } from "../../../utilities/Guid";

export interface IControllerInfo {
    groupId: number;
    name: string;
    emailAddress: string;
    ssn: string;
    clientGuid: Guid;
    mobileNumber: string;
    countryCode: string;
}

export const initialControllerInfo: IControllerInfo = {
    clientGuid: new Guid(Guid.empty),
    countryCode: '',
    emailAddress: '',
    groupId: 0,
    mobileNumber: '',
    name: '',
    ssn:''
};