import * as React from 'react';
import { Route, Switch } from 'react-router-dom';
import { useDispatch } from "react-redux";
import { actionCreators } from "./store/Common/HelperStore";
import MainComponentContainer from './components/Home/MainComponentContainer';
import PreviewComponentContainer from './components/Home/PreviewComponentContainer';
import { Layout } from './components/Layout/Layout';
import { CcRecipientLayout } from './components/Layout/RecipientLayout';
import { DelegateeLayout } from './components/Layout/DelegateeLayout';
import ManualSignCompleted from './components/ManualSign/ManualSignContainer';
import PayCompleted from './components/Pay/PayContainer';
import DownloadContainer from './components/Download/DownloadContainer';
import RouteManager from './components/Home/RouteMangerContainer';
import DelegateeRouteManager from './components/Home/DelegateeRouteManagerContainer';
import CompletedSummaryPageContainer from './components/CompletedSummary/CompleteWizard/CompletedSummaryPageContainer';
import PaperFiledReturn from './components/PaperFile/Completed/PaperFiledReturnContainer';
import K1Distribution from './components/K1Distribution/Completed/K1DistributionContainer';
import ElectronicDistribution from './components/K1Distribution/Completed/ElectronicDistributionContainer';
import ManualDistribution from './components/K1Distribution/Completed/ManualDistributionContainer';
import ScreenShareContainer from './components/ScreenShare/ScreenShareContainer';
import CcRecipientDownloadContainer from './components/CCRecipient/CCRecipientDownloadContainer';
import DelegateeSignerContainer from './components/Home/DelegateeSignerContainer';
import DelegateeSignCompleteContainer from './components/Delegatee/CompletedWizard/CompletedWizardContainer';
import DelegateManualSignContainer from './components/Delegatee/ManualSign/ManualSignContainer';
import ControllerDashbardContainer from './components/GroupedReturns/DashboardContainer';
import { GroupedReturnLayout } from './components/Layout/GroupedReturnLayout';
import ReviewTaxReturn from './components/Review/ReviewContainer';
import ControllerPreviewContainer from './components/GroupedReturns/ControllerPreviewContainer';
import ManualSignPdfViewerContainer from './components/ManualSign/ManualSignPdfViewerContainer';
import ReviewManualSignDocumentsContainer from './components/Delegatee/ManualSign/ReviewManualSignDocumentsContainer';
import CoverPageContainer from "./components/Cover/CoverPageContainer";
import OTPContainer from "./components/OTP/OTPContainer";
import SSNAuthContainer from "./components/SSN/SSNAuthContainer";
import MobileOTPContainer from "./components/MobileOTP/MobileOTPContainer";
import CoverPageContainer_GR from "./components/GR_Cover/CoverPageContainer_GR";
import SSN_GR from "./components/GR_SSN/GroupSSNContainer";
import OTP_GR from "./components/GR_OTP/OTPContainer_GR";
import MobileOTP_GR from "./components/GR_MobileOTP/MobileOTPContainer_GR"
import DelegateeMobileOTP from "./components/Delegatee/MobileOTP/MobileOTPContainer";
import DelegateeOTP from "./components/Delegatee/EmailOTP/EmailOTPContainer";
import CCRMobileOTP from "./components/CCRecipient/MobileOTP/MobileOTPContainer";
import CCREmailOTP from "./components/CCRecipient/EmailOTP/EmailOtpContainer";
import DelegateeRouteSelect from "./components/Delegatee/RouteSelectorContainer";
import CCRRouteSelect from "./components/CCRecipient/RouteSelectorContainer";
import { InvalidLink } from './components/Common/InvalidLink';
import { TaxReturnStatusView } from './components/Common/StatusView/TaxReturnStatusView';
import { initializeAppInsights, TelemetryLogger } from './components/Logger/AppInsights';
import TaxpayerPreviewCover from "./components/Preview/taxpayer/CoverPageContainer";
import ControllerPreviewCover from "./components/Preview/Controller/CoverPageContainer";
import ErrorContainer from "./components/Common/ErrorContainer";
import PayCompletedContainer from "./components/Pay/PayCompletedContainer";
import VoucherSelector from "./components/Pay/VoucherSelector";
import { VoucherTypes } from './core/domain/models/IGroup';
import { initializeAxios } from './core/services/dataAccess/DataService.Axios';
import { AxiosResponse } from 'axios';
import { history } from './core/services/dataAccess/History';
import VoucherSelectorContainer from './components/Pay/VoucherSelectorContainer';

export const logger = TelemetryLogger.getInstance();

export const routes = (
    <Switch>
        <ApiRouteWrapper exact path="/WelcomeScreen/Index/:clientId" />
        <ApiRouteWrapper exact path="/Taxpayer/:clientId" />
        <ApiRouteWrapper exact path="/DownloadTaxDocuments/Index/:clientId" />
        <ApiRouteWrapper exact path="/Controller/Index/:clientId" />
        <ApiRouteWrapper exact path="/Controller/:clientId" />
        <ApiRouteWrapper exact path="/CC/DownloadTaxDocumentsReview/:clientId" />
        <ApiRouteWrapper exact path="/Delegatee/Login/:clientId" />
        <ApiRouteWrapper exact path="/WelcomeScreen/Index/" />
        <ApiRouteWrapper exact path="/K1Download/Login/:clientId" />
        <ApiRouteWrapper exact path="/K1Download/index" />
        <ApiRouteWrapper exact path="/K1Partner/index" />

        <RouteWrapper exact path="/invalid" component={InvalidLink} />
        <RouteWrapper exact path="/CoverPage/Index/:clientId" component={CoverPageContainer}  />
        <RouteWrapper exact path="/OTP/Index/:clientId" component={OTPContainer} />
        <RouteWrapper exact path="/Authentication/SSN/:clientId" component={SSNAuthContainer} />
        <RouteWrapper exact path="/MobileOTP/Index/:clientId" component={MobileOTPContainer} />
        <RouteWrapper exact path="/taxreturnstatus/:status?" component={TaxReturnStatusView} />

        <RouteWrapper exact path="/GroupedReturns/Coverpage/Index/:clientId" component={CoverPageContainer_GR} />
        <RouteWrapper exact path="/GroupedReturns/OTP/Index/:clientId" component={OTP_GR} />
        <RouteWrapper exact path="/GroupedReturns/MobileOTP/Index/:clientId" component={MobileOTP_GR} />

        <RouteWrapper exact path="/Delegatee/OTP/Index/:clientId" component={DelegateeOTP} />
        <RouteWrapper exact path="/Delegatee/MobileOTP/Index/:clientId" component={DelegateeMobileOTP} />
        <RouteWrapper exact path="/CCRecipient/OTP/Index/:clientId" component={CCREmailOTP} />
        <RouteWrapper exact path="/CCRecipient/MobileOTP/Index/:clientId" component={CCRMobileOTP} />

        <RouteWrapper exact path="/Delegatee/:clientId" component={DelegateeRouteSelect} />
        <RouteWrapper exact path="/CCRecipient/:clientId" component={CCRRouteSelect} />

        <RouteWrapper exact path="/coverpage/taxpayerpreview/:clientId" component={TaxpayerPreviewCover} />
        <RouteWrapper exact path="/groupedreturnscoverpage/controllerpreview/:clientId" component={ControllerPreviewCover} />

        <RouteWrapper exact path="/Error/:message?" component={ErrorContainer} /> 
        <RouteWrapper exact path="/VoucherSelect/estimated/:controllerId?/:clientId" component={(props: any) => (<PayCompletedContainer paymentType={VoucherTypes.EstimatedVoucher} {...props} />)} layout={Layout} />
        <RouteWrapper exact path="/VoucherSelect/payment/:controllerId?/:clientId" component={(props: any) => (<PayCompletedContainer paymentType={VoucherTypes.PaymentVoucher} {...props} />)} layout={Layout} />

        <RouteWrapper exact path="/download/:controllerId?/:clientId" component={DownloadContainer} layout={Layout} />
        <RouteWrapper exact path="/taxpayerflow/:controllerId?/:clientId" component={RouteManager} layout={Layout} />
        <RouteWrapper exact path="/home/index/:controllerId?/:clientId" component={RouteManager} layout={Layout} />
        <RouteWrapper exact path="/signflow/:controllerId?/:clientId" component={MainComponentContainer} layout={Layout} />
        <RouteWrapper exact path="/ManualSign/:controllerId?/:clientId" component={ManualSignCompleted} layout={Layout} />
        <RouteWrapper exact path="/signcompleted/:controllerId?/:clientId" component={CompletedSummaryPageContainer} layout={Layout} />
        <RouteWrapper exact path="/Pay/:controllerId?/:clientId" component={VoucherSelectorContainer} layout={Layout} />
        <RouteWrapper exact path="/Review/:controllerId?/:clientId" component={ReviewTaxReturn} layout={Layout} />
        <RouteWrapper exact path="/taxpayerpreview/:controllerId?/:clientId" component={RouteManager} layout={Layout} />
        <RouteWrapper exact path="/preview/index/:clientId" component={RouteManager} layout={Layout} />
        <RouteWrapper exact path="/previewflow/:controllerId?/:clientId" component={PreviewComponentContainer} layout={Layout} />
        <RouteWrapper exact path="/DelegateeInitialize/:clientId" component={DelegateeRouteManager} layout={DelegateeLayout} />
        <RouteWrapper exact path="/Delegatee/SignFlow/:clientId" component={DelegateeSignerContainer} layout={DelegateeLayout} />
        <RouteWrapper exact path="/Delegatee/ManualSign/:clientId" component={DelegateManualSignContainer} layout={DelegateeLayout} />
        <RouteWrapper exact path="/Delegatee/SignCompleted/:clientId" component={DelegateeSignCompleteContainer} layout={DelegateeLayout} />
        <RouteWrapper exact path="/DistributeK1/Electronic/:controllerId?/:clientId" component={ElectronicDistribution} layout={Layout} />
        <RouteWrapper exact path="/DistributeK1/Manual/:controllerId?/:clientId" component={ManualDistribution} layout={Layout} />
        <RouteWrapper exact path="/DistributeK1/:controllerId?/:clientId" component={K1Distribution} layout={Layout} />
        <CcProtectedRoute exact path="/Cc/DownloadTaxDocuments/:controllerId?/:clientId" component={CcRecipientDownloadContainer} layout={CcRecipientLayout} />
        <RouteWrapper exact path="/controller/dashboard/:clientId" component={ControllerDashbardContainer} layout={GroupedReturnLayout} />
        <RouteWrapper exact path="/controllerpreview/:clientId" component={ControllerPreviewContainer} layout={GroupedReturnLayout} />
        <RouteWrapper exact path="/controllerpreviewflow/:clientId" component={ControllerPreviewContainer} layout={GroupedReturnLayout} />
        <RouteWrapper exact path="/ReviewManualSignDocuments/:clientId" component={ManualSignPdfViewerContainer} layout={Layout} />
        <RouteWrapper exact path="/DelegateeReviewManualSignDocuments/:clientId" component={ReviewManualSignDocumentsContainer} layout={DelegateeLayout} />
        <RouteWrapper exact path="/PaperFileReturn/:controllerId?/:clientId" component={PaperFiledReturn} layout={Layout} />
        <Route exact path="/sharescreen/:clientId" component={ScreenShareContainer}/>       
        <RouteWrapper path="*" component={InvalidLink} />
    </Switch>);

function RouteWrapper({
    component: Component,
    layout: Layout,
    ...rest
}: any) {
    initializeAppInsights(rest.computedMatch.params.clientId);

    let dispatch = useDispatch();

    React.useEffect(() => {

        rest.computedMatch.params.clientId && dispatch(actionCreators.getWalkMeScript(rest.computedMatch.params.clientId, (script: string) => {
            if (script && script.length > 0) {
                let scriptElement = document.createElement("script");
                scriptElement.type = "text/javascript";
                scriptElement.async = true;
                scriptElement.text = script;
                document.head.appendChild(scriptElement);
                (window as any).loadWalkMe && (window as any).loadWalkMe();
            }
        }, Layout ? true : false));


    },[])

    return (
        <Route {...rest} render={(props) =>
            Layout ?
                <Layout {...props}>
                    <Component {...props} />
                </Layout> :
                <Component {...props} />
        } />
    );
}

function CcProtectedRoute({
    component: Component,
    layout: Layout,
    ...rest
}: any) {
    initializeAppInsights(rest.computedMatch.params.clientId);
    return (
        <Route {...rest} render={(props) =>
            Layout ?
                <CcRecipientLayout {...props}>
                    <Component {...props} />
                </CcRecipientLayout> :
                <Component {...props} />
        } />
    );
}

function ApiRouteWrapper({
    ...rest
}: any) {

    React.useEffect(() => {

        let path: string = rest.computedMatch.url;
        if (path && path[0] === "/") {
            path = path.substring(1);
        }

        initializeAxios().get<any>(path + rest.location.search)
            .then(function (response: AxiosResponse<any>) {  
                window.location.href = response.data;
            })
            .catch(function (error: any) {
                console.log(error);
            })
    }, [])

    return (
        <></>
    );
}
