import { ApplicationState } from "../../../store/index";
import { connect } from 'react-redux';
import { actionCreators as ManualSignStore } from '../../../store/Sign/ManualSignStore';
import { actionCreators as EsignStore } from '../../../store/Common/EsignStore';
import { ManualSignReviewDocuments } from '../../ManualSign/ReviewManualSignDocuments';
import { actionCreators as DelegateeSignProcessStore } from '../../../store/DelegateeSignProcessStore';
import { actionCreators as DownloadableDocumentsStore } from '../../../store/DownloadableDocumentsStore';

const mapStateToProps = (state: ApplicationState) => {
    return {
        signatureControlsData: state.esignData.controlsData,
        headerInfo: state.delegateeHeaderInfo,
        taxReturn: state.taxReturn.taxDocument,
        signerData: state.esignData.signerDetails,
        downloadableDocumentsViewModel: state.downloadableDocuments,
        summaryData: state.summaryData,
    };
}

const mapDispatchToProps = (dispatch: any) => {
    return {
        requestPreviewSignatureControls: (clientGuid: string) => dispatch(EsignStore.requestPreviewSignatureControls(clientGuid)),

        requestAdditionalEsignDocument: (clientId: string) =>
            dispatch(DownloadableDocumentsStore.requestAdditionalEsignDocument(clientId)),

        downloadAllAdditionEsignDocuments: (clientGuid: string, fileName: string) =>
            dispatch(DelegateeSignProcessStore.delegateeDownloadAllAdditionEsignDocuments(clientGuid, fileName)),

        downloadEfileForms: (clientGuid: string) =>
            dispatch(DelegateeSignProcessStore.delegateeDownloadEfileForms(clientGuid)),

        dispatch
    }
}


const connector = connect(mapStateToProps, mapDispatchToProps);

export default connector(ManualSignReviewDocuments);



