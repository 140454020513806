import * as React from 'react';
import { IVoucher, VoucherNo } from '../../../core/domain/models/IGroup';
import { ITaxingAuthority } from '../../../core/domain/models/ITaxingAuthority';
import { getEstimatedVouchers } from '../../../core/domain/viewModels/ISummaryViewModel';
import { TYPES } from '../../../startup/types';
import { container } from '../../../startup/inversify.config';
import { IUtilities } from '../../../core/utilities/Utilities';
import { Shimmer } from '../../../components/Common/Shimmer/Shimmer';
import { Down, Up } from '../../../components/Common/Icons/SvgIcons';


const utilities = container.get<IUtilities>(TYPES.IUtilities);

export interface EstimatedVoucherProps {
	taxYear: number,
	vouchers: IVoucher[],
	taxingAuthorities: ITaxingAuthority[],
	card2Open: boolean,
	toggleCards: (card: number) => void
}

export class EstimatedVoucher extends React.Component<EstimatedVoucherProps, {}> {

	constructor(props: EstimatedVoucherProps) {
		super(props);
	}

	public render() {
		const autoIds: any = {};
		autoIds[VoucherNo[VoucherNo.Q1].toString()] = "4F660DE8-BE88-48AA-AF2C-51646238FE67";
		autoIds[VoucherNo[VoucherNo.Q2].toString()] = "5BDD3FEC-D42C-463F-83E0-FD2801B27286";
		autoIds[VoucherNo[VoucherNo.Q3].toString()] = "CD5C7E01-F18C-45D8-9690-8565E5F4CB66";
		autoIds[VoucherNo[VoucherNo.Q4].toString()] = "6366E3A8-1895-4017-91FF-97662F494CF8";

		const estimatedVouchers = getEstimatedVouchers(this.props.vouchers, this.props.taxingAuthorities);

		return (
		<div className={`company_cards_container ${this.props.card2Open ? 'full_height_container' : ''}`}>
				<div className='company_card_header'>
					<div className="title">{this.props.taxYear ? (this.props.taxYear + 1 + " Estimated Payments ") : <Shimmer lineCount={12} />} </div>
				</div>
				<div className={`company_card_body ${this.props.card2Open ? 'full_height_container' : ''}`}>
					{
						estimatedVouchers.map((value, index) => {
							if (value.Items.length > 0) {
								return (
									<section className="preSelect" key={value.GroupName + index} data-test-auto={autoIds[value.GroupName]}>
										<div className="section-title">{value.GroupName}</div>
										{
											value.Items.length > 0 ? (
												value.Items.map((item, i) => {
													return (<div key={value.GroupName + item.name + i} className="sub-section">
														<div className="authority fllft ellipsis">{item.name}</div>
														<div className="amount flrt red currencytext">${utilities.formatCurrencyText(item.value)}</div>
													</div>)
												})
											)
												: ""
										}
										{
											value.Items.length > 0 ? (

												<div className="sub-section">
													<div className="red fllft ellipsis"><span className="flrt total-label-red">Total</span></div>
													<div className="flrt currencytext red total-amount-red">${utilities.formatCurrencyText(value.Total)}</div>
												</div>

											) : ""
										}
									</section>
								);
							}
						})
					}
				</div>
				<div
					data-test-auto='21c4f274-e553-4085-9da4-ed306a47fa54'
					className={`${this.props.card2Open ? 'drop_action_button' : 'drop_action_button_Up'}`}
					onClick={() => {
						this.props.toggleCards(2);
					}}>
					{this.props.card2Open ? (
						<div>
							<Up />
						</div>
					) : (
						<div>
							<Down />
						</div>
					)}
				</div>
		</div>);
	}
}
