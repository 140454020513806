
export interface IInvoicePayment {
    invoicePaymentId: number;
    documentId: number;
    amountDue: number;
    amountPaid: number;
    paidDate: Date;
    paymentMode: PaymentMode;
    transactionReferenceNumber: string;
    remark: string;
    isPaid: boolean;
}

export enum PaymentMode {
    Online = 0,
    Check = 1
}

export const initialInvoicePayment: Readonly<IInvoicePayment> = {
    invoicePaymentId: 0,
    documentId: 0,
    amountDue: 0,
    amountPaid: 0,
    paidDate: new Date("0001-01-01T00:00:00"),
    paymentMode: PaymentMode.Online,
    transactionReferenceNumber: '',
    remark: '',
    isPaid: false
}