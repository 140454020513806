import { ClientTypes } from "../../../store/SSN/SSNStore";

export interface IPaperFileConsentInfo {
	isConsented: boolean,
	emailAddress: string,
	name: string,
	clientType: ClientTypes,
}

export const initialPaperFileConsentInfo: IPaperFileConsentInfo = {
	isConsented: false,
	emailAddress: '',
	name: '',
	clientType: ClientTypes.Undefied
}
