
import { connect } from 'react-redux';
import SSNAuth from "./SSNAuth";
import * as headerInfoStore from "../../store/Common/AccountStore";
import * as  SSNReducer from "../../store/SSN/SSNStore";
import * as HeaderStore from "../../store/HeaderStore";



export default connect(
    (state: any) => ({
        headerInfoState: state.HeaderPageReducer,
        SSNPageState: state.SSNPageReducer,

    }),
    {
        ...HeaderStore.actionCreators,
        ...SSNReducer.actionCreators
    }
)(SSNAuth as any);

