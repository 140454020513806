import * as React from "react";
import { IFileUtilities } from "../../core/utilities/File/FileUtilities";
import { container } from "../../startup/inversify.config";
import { TYPES } from "../../startup/types";
import { SvgDownloadButtonIcon } from "../Common/Icons/SvgIcons";
import { MarsNotifier } from "../Common/Notification/MarsNotifier";
import { DownloadDocumentsConstants } from "../Common/Constants";
import { PopoverTriggerComponent } from "cp-common-ui-components";
var htmlencode = require("htmlencode");

export interface DownloadListProps {
    title: string;
    items: DownloadListItem[];
}

export interface DownloadListItem {
    text: string;
    testAutoId: string;
    onClick: (...args: any[]) => any;
    instruction?: string;
    args: any[];
    isPreviewMode?: boolean;
}

const fileUtilities = container.get<IFileUtilities>(TYPES.IFileUtilities);

export const DownloadList: React.FC<DownloadListProps> = (props) => {
    function getInstructionPopOver(key: string, instruction: string) {
        return (
            <div key={key}>
                <div dangerouslySetInnerHTML={{ __html: htmlencode.htmlDecode(instruction) }}></div>
            </div>
        );
    }

    function handleClick(args: any[], funRef: any, isPreviewMode: boolean | undefined) {
        if (!isPreviewMode) {
            if (args) {
                if (args.length === 1) {
                    funRef(args[0]);
                } else if (args.length === 2) {
                    funRef(args[0], args[1]);
                }
            }
        } else {
            MarsNotifier.Warning(DownloadDocumentsConstants.DownloadNotAllowed, null);
        }
    }

    return (
        <div className="download-list">
            <span className="title">{props.title}</span>
            <ul>
                {props.items.map((value, index) => {
                    return (
                        <li key={value.testAutoId} data-test-auto={"HN797ZV301KMC9DA2W0C6NYR58-" + index}>
                            {fileUtilities.getSafeFilename(value.text)}
                            <SvgDownloadButtonIcon
                                onClick={() => {
                                    handleClick(value.args, value.onClick, value.isPreviewMode);
                                }}
                                className="download-list_svg"
                            />
                            {value.instruction && value.instruction.length > 0 && (
                                <PopoverTriggerComponent
                                    data-test-auto={"DQYT5ZTQAA8MQ29U2T1PRXC160-" + index}
                                    trigger={["hover", "focus"]}
                                    popoverTitle={"Instructions"}
                                    popoverContent={getInstructionPopOver(
                                        "Instruction_" + value.testAutoId,
                                        value.instruction
                                    )}
                                >
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="16"
                                        height="16"
                                        viewBox="0 0 16 16"
                                        fill="none"
                                    >
                                        <path
                                            d="M8 15C6.14348 15 4.36301 14.2625 3.05025 12.9497C1.7375 11.637 1 9.85652 1 8C1 6.14348 1.7375 4.36301 3.05025 3.05025C4.36301 1.7375 6.14348 1 8 1C9.85652 1 11.637 1.7375 12.9497 3.05025C14.2625 4.36301 15 6.14348 15 8C15 9.85652 14.2625 11.637 12.9497 12.9497C11.637 14.2625 9.85652 15 8 15ZM8 16C10.1217 16 12.1566 15.1571 13.6569 13.6569C15.1571 12.1566 16 10.1217 16 8C16 5.87827 15.1571 3.84344 13.6569 2.34315C12.1566 0.842855 10.1217 0 8 0C5.87827 0 3.84344 0.842855 2.34315 2.34315C0.842855 3.84344 0 5.87827 0 8C0 10.1217 0.842855 12.1566 2.34315 13.6569C3.84344 15.1571 5.87827 16 8 16Z"
                                            fill="#898D91"
                                        />
                                        <path
                                            d="M8.93 6.588L6.64 6.875L6.558 7.255L7.008 7.338C7.302 7.408 7.36 7.514 7.296 7.807L6.558 11.275C6.364 12.172 6.663 12.594 7.366 12.594C7.911 12.594 8.544 12.342 8.831 11.996L8.919 11.58C8.719 11.756 8.427 11.826 8.233 11.826C7.958 11.826 7.858 11.633 7.929 11.293L8.93 6.588ZM9 4.5C9 4.76522 8.89464 5.01957 8.70711 5.20711C8.51957 5.39464 8.26522 5.5 8 5.5C7.73478 5.5 7.48043 5.39464 7.29289 5.20711C7.10536 5.01957 7 4.76522 7 4.5C7 4.23478 7.10536 3.98043 7.29289 3.79289C7.48043 3.60536 7.73478 3.5 8 3.5C8.26522 3.5 8.51957 3.60536 8.70711 3.79289C8.89464 3.98043 9 4.23478 9 4.5Z"
                                            fill="#898D91"
                                        />
                                    </svg>
                                </PopoverTriggerComponent>
                            )}
                        </li>
                    );
                })}
            </ul>
        </div>
    );
};
