import { History } from "history";
import * as React from "react";
import { match } from "react-router";
import { IDownloadableDocumentsViewModel } from "../../core/domain/viewModels/IDownloadableDocumentsViewModel";
import { IDownloadableDocuments, FormType } from "../../core/domain/models/ITaxReturn";
import { IAttachment } from "../../core/domain/models/IAttachment";
import { Modal } from "react-bootstrap";
import { CloseIcon, SvgDownloadButtonIcon } from "../Common/Icons/SvgIcons";
import { IFileUtilities } from "../../core/utilities/File/FileUtilities";
import { TYPES } from "../../startup/types";
import { MarsNotifier } from "../Common/Notification/MarsNotifier";
import { DownloadDocumentsConstants } from "../Common/Constants";
import { container } from "../../startup/inversify.config";
import { ButtonComponent } from "cp-common-ui-components";
import { handleKeyDown } from "../Helper/HelperFunction";

export interface DownloadAllProps {
    match: match;
    history: History;
    DownloadableDocuments: IDownloadableDocumentsViewModel;
    downloadTaxReturnDocument: (clientGuid: string, fileName: string) => any;
    downloadTransmittalDocument: (clientGuid: string, fileName: string) => any;
    downloadInvoiceDocument: (clientGuid: string, fileName: string) => any;
    downloadK1Document: (clientGuid: string, fileName: string) => any;
    downloadVoucherDocument: (clientGuid: string, fileName: string) => any;
    downloadAttachment: (clientGuid: string, fileName: string) => any;
    downloadMergedPaperFileDocument: (clientGuid: string) => any;
    showModal: boolean;
    onHide: () => void;
    onDownloadAll: (clientGuid: string, fileName: string) => any;
    downloadAllAttatchments: (clientGuid: string, fileName: string) => any;
    fileNamePrefix: string;
    paperFileName?: string;
    isPreviewMode: boolean;
}

const fileUtilities = container.get<IFileUtilities>(TYPES.IFileUtilities);
export const DownloadAll: React.SFC<DownloadAllProps> = (props) => {
    const param: any = props.match.params;

    const vouchers: IDownloadableDocuments[] = props.DownloadableDocuments.downloadableDocuments.filter(
        (x) => x.groupId === FormType.Vouchers
    );
    const invoices: IDownloadableDocuments[] = props.DownloadableDocuments.downloadableDocuments.filter(
        (x) => x.groupId === FormType.Invoice
    );
    const attachments: IAttachment[] = props.DownloadableDocuments.attachments;

    const taxReturn = props.DownloadableDocuments.downloadableDocuments.find((x) => x.groupId === FormType.TaxReturns);

    const k1 = props.DownloadableDocuments.downloadableDocuments.find((x) => x.groupId === FormType.K1);

    const transmittals = props.DownloadableDocuments.downloadableDocuments.find(
        (x) => x.groupId === FormType.Transmittals
    );

    const paperFiles = props.DownloadableDocuments.paperFiles;

    const downloadWarning = () => {
        MarsNotifier.Warning(DownloadDocumentsConstants.DownloadNotAllowed, null);
        return;
    };

    return (
        <div>
            <Modal
                className="download-all-modal"
                show={props.showModal}
                onHide={props.onHide}
                data-test-auto="3ED090CD-AE60-4267-8768-DEC161BF3E72"
            >
                <Modal.Header data-test-auto="460F501F-7086-4E49-BA9C-FDC8614D943B">
                    <Modal.Title>Click a File to Download</Modal.Title>
                    <div
                        data-test-auto="8cc2eef8-27ec-494a-9518-5d52ce8a0891"
                        className="closeIcon"
                        onClick={props.onHide}
                        tabIndex={0}
                        onKeyDown={(e: any) => handleKeyDown(e, props.onHide)}
                    >
                        <CloseIcon />
                    </div>
                </Modal.Header>
                <Modal.Body>
                    <div className="download-all-body">
                        <ul>
                            {taxReturn && taxReturn.documentId > 0 && (
                                <li data-test-auto="0348F062-BECA-4AF2-99FD-C491EFA87CB2">
                                    <span
                                        className="ellipsisText"
                                        title={taxReturn.fileName}
                                    >
                                        {taxReturn.fileName}
                                    </span>
                                    <SvgDownloadButtonIcon
                                        className="download-all-body_svg"
                                        onClick={() => {
                                            props.isPreviewMode
                                                ? downloadWarning()
                                                : props.downloadTaxReturnDocument(param.clientId, taxReturn.fileName);
                                        }}
                                    />
                                </li>
                            )}
                            {paperFiles && paperFiles.length > 0 && (
                                <li data-test-auto="ED2FE653-4AB0-465D-9A61-2CBF09BD9732">
                                    <span
                                        className="ellipsisText"
                                        title={props.paperFileName}
                                    >
                                        {props.paperFileName}
                                    </span>
                                    <SvgDownloadButtonIcon
                                        className="download-all-body_svg"
                                        onClick={() => {
                                            props.isPreviewMode
                                                ? downloadWarning()
                                                : props.downloadMergedPaperFileDocument(param.clientId);
                                        }}
                                    />
                                </li>
                            )}
                            {k1 && k1.documentId > 0 && (
                                <li data-test-auto="736CF2C4-6FED-4B14-9584-DC12D995CD6A">
                                    <span className="ellipsisText" title={k1.fileName}>
                                        {k1.fileName}
                                    </span>
                                    <SvgDownloadButtonIcon
                                        className="download-all-body_svg"
                                        onClick={() => {
                                            props.downloadK1Document(param.clientId, k1.fileName);
                                        }}
                                    />
                                </li>
                            )}
                            {transmittals && transmittals.documentId > 0 && (
                                <li data-test-auto="856BDDCA-8535-41A4-AC03-3EBAB6132CB1">
                                    <span
                                        className="ellipsisText"
                                        title={transmittals.fileName}
                                    >
                                        {transmittals.fileName}
                                    </span>
                                    <SvgDownloadButtonIcon
                                        className="download-all-body_svg"
                                        onClick={() => {
                                            props.isPreviewMode
                                                ? downloadWarning()
                                                : props.downloadTransmittalDocument(
                                                      param.clientId,
                                                      transmittals.fileName
                                                  );
                                        }}
                                    />
                                </li>
                            )}
                            {vouchers &&
                                vouchers.length > 0 &&
                                vouchers.map((value, index) => {
                                    return (
                                        <li
                                            key={"voucher" + index}
                                            data-test-auto={"9DNCEUKEW334T6VQWJHFAZFEHH" + index}
                                        >
                                            <span
                                                className="ellipsisText"
                                                title={value.fileName}
                                            >
                                                {value.fileName}
                                            </span>
                                            <SvgDownloadButtonIcon
                                                className="download-all-body_svg"
                                                onClick={() => {
                                                    props.isPreviewMode
                                                        ? downloadWarning()
                                                        : props.downloadVoucherDocument(param.clientId, value.fileName);
                                                }}
                                            />
                                        </li>
                                    );
                                })}
                            {invoices &&
                                invoices.length > 0 &&
                                invoices.map((value, index) => {
                                    return (
                                        <li
                                            key={"invoice" + index}
                                            data-test-auto={"0D1TTQU0CTG431V156FXNP1R3M" + index}
                                        >
                                            <span
                                                className="ellipsisText"
                                                title={value.fileName}
                                            >
                                                {value.fileName}
                                            </span>
                                            <SvgDownloadButtonIcon
                                                className="download-all-body_svg"
                                                onClick={() => {
                                                    props.isPreviewMode
                                                        ? downloadWarning()
                                                        : props.downloadInvoiceDocument(param.clientId, value.fileName);
                                                }}
                                            />
                                        </li>
                                    );
                                })}
                            {attachments &&
                                attachments.length > 0 &&
                                attachments.map((value, index) => {
                                    return (
                                        <li
                                            key={"attachment" + index}
                                            data-test-auto={"KU1UP04APJ24G37XV5V6U1QVDM" + index}
                                        >
                                            <span
                                                className="ellipsisText"
                                                title={`Attachments - ${fileUtilities.getSafeFilename(value.fileName)}`}
                                            >
                                                Attachments - {fileUtilities.getSafeFilename(value.fileName)}
                                            </span>
                                            <SvgDownloadButtonIcon
                                                className="download-all-body_svg"
                                                onClick={() => {
                                                    props.isPreviewMode
                                                        ? downloadWarning()
                                                        : props.downloadAttachment(param.clientId, value.fileName);
                                                }}
                                            />
                                        </li>
                                    );
                                })}
                        </ul>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <ButtonComponent
                        id="btnCancel"
                        data-test-auto="77A3657B-E2D6-48BF-9547-A2E1791D9769"
                        onClick={props.onHide}
                        variant={"outline-tertiary"}
                        buttonClassName={"button-margin-right"}
                        size={"medium"}
                    >
                        Cancel
                    </ButtonComponent>
                    <ButtonComponent
                        id="btnDownloadAll"
                        data-test-auto="50AD8D23-F8B3-4251-B5E2-FDDB1399F346"
                        onClick={() => {
                            props.isPreviewMode
                                ? downloadWarning()
                                : props.onDownloadAll(param.clientId, props.fileNamePrefix + "_Tax Returns.zip");
                        }}
                        variant={"primary"}
                        size={"medium"}
                    >
                        Download All
                    </ButtonComponent>
                </Modal.Footer>
            </Modal>
        </div>
    );
};
