export interface IDocumentTransaction {
    documentId: number,
    eventId: DocumentEvent,
    actedOn: Date,
    actedBy: IDocumentTransactionUser,
    eventData: IDocumentEventData,
}

export interface IDocumentTransactionUser {
    firstName: string;
    lastName: string;
    emailAddress: string;
}

export interface IDocumentEventData {
    fileName: string;
    ipAddress: string;
    clientType: ClientTypes;
    assignedValue: string;
    mailEventId: MailEvent;
    recipientInfo: IDocumentTransactionUser;
    token: string;
    dob: Date;
    customData: any[];
}

export enum MailEvent {
    None,
    Click = 1,
    Delivered = 2,
    Opened = 3,
    Processed = 4,
    Dropped = 5,
    Bounce = 6,
    Deferred = 7,
    SpamReport = 8,
    ScheduleFailed = 9
}

export enum DocumentEvent {
    None,
    Created = 1,
    DocumentAssignTo = 2,
    DocumentEROStampDecline = 3,
    EROSignDecline = 4,
    DocumentDeliveryApproved = 5,
    Emailed = 6,
    DocumentChangeStatusManual = 7,
    TaxReturnAccessed = 8,
    DocumentAuthenticated = 9,
    AuthenticationFailed = 10,
    AccessLocked = 11,
    OTPAuthenticationFailed = 12,
    OTPAccessLocked = 13,
    TaxReturnReviewed = 14,
    Viewed = 15,
    EfileFormsDownloaded = 16,
    FileUploaded = 17,
    TaxpayerEsigned = 18,
    SpouseEsigned = 19,
    VouchersReviewed = 20,
    VoucherReminderDisabled = 21,
    VoucherReminderEnabled = 22,
    AttachmentDownloaded = 23,
    AllAttachmentDownloaded = 24,
    AllK1DocumentDownloaded = 25,
    DocumentAttachmentsReviewed = 26,
    PaymentSystemAccessed = 27,
    InvoiceReviewed = 28,
    Finished = 29,
    AccessLinkEmailed = 30,
    TransmittalReviewed = 31,
    DocumentTaxSaved = 32,
    K1DistributedTo = 33,
    DocumentDownloadAll = 34,
    DocumentFilingInstructionsDownloaded = 35,
    DocumentTaxReturnDownloaded = 36,
    DocumentVouchersDownloaded = 37,
    DocumentInvoiceDownloaded = 38,
    DocumentSignedEfileDownloaded = 39,
    KBAPassed = 40,
    KBAFailed = 41,
    DocumentKBASuccess = 42,
    DocumentKBAFailed = 43,
    DOBChanged = 44,
    DocumentOptOutESignOptionSelected = 45,
    DocumentSigned = 46,
    SignedDocumentReviewed = 47,
    VoucherReminderUpdated = 48,
    Deleted = 49,
    DocumentFileDeleted = 50,
    ShareholderConsentReceived = 51,
    ShareholderConsentDeclined = 52,
    K1DocumentDownloaded = 53,
    K1InstructionDocumentDownloaded = 54,
    DocumentMailStatus = 55,
    ReturnIsRecalled = 56,
    VoucherRemainderMailStatus = 57,
    DocumentRequestRecallReturn = 58,
    DocumentRequestDeleteReturn = 59,
    Shared = 60,
    Cancelled = 61,
    Filed = 62,
    DocumentRejected = 63,
    AccessCodeEmail = 64,
    PartnershipEsigned = 65,
    DocumentSignedEfileDownloadedByCPA = 66,
    ReminderSendForSigning = 67,
    DownloadLinkSend = 68,
    PaymentCompleted = 69,
    NotDefined = 75,
    UploadInProgress = 76,
    DeliverdToTaxcaddy = 77,
    TaxcaddyDeliveryFailed = 78,
    SigningReminderSend = 79,
    SigningReminderMailStatus = 80,
    K1ShareHolderReminderMailStatus = 81,
    K1TaxpayerReminderMailStatus = 82,
    SendForSignature = 83,
    ResendAccessLink = 87,
    DocumentsForwardedToCCRecipient = 94,
    RestoreReturn = 95,
    NewTaxReturnUploaded = 96,
    TaxReturnReprocessed = 97,
    PaymentDetailsAdded = 100,
    PaymentDetailsUpdated = 101,
    VoucherReminderDisabledByTP = 102,
    StatusChangeToSignedAndEsigned = 110,
    ControllerEsigned = 111,
    ControllerSignedAdditionalDocument = 112,
    InvoicePaymentDetailAdded = 113,
    InvoicePaymentDetailUpdated = 114,
    InvoicePaymentDetailDeleted = 115,
    PaperFileConsented = 116,
    PaperFileDownloaded = 117,
    PaperFileAllDownloaded = 118,
    PaperFilePrinted = 119,
    PaperFileAllPrinted = 120,
    RevisionDocumentUploaded = 121,
    InitialRevisionMail = 122,
    SigningFailed = 123,
    TaxpayerESignedInOffice = 141,
    SpouseESignedInOffice = 142,
    PartnershipESignedInOffice = 143,
    ControllerESignedInOffice = 144
}