import * as React from 'react';
import { match } from 'react-router';
import { DownloadK1s } from './DownloadK1s';
import { DistributeManualTable } from '../K1Distribution/DistributeManualTable';
import { IK1ShareHolderDetails, GetK1EngagementType } from '../../core/domain/models/IK1Distribute';
import { ITaxReturn } from '../../core/domain/models/ITaxReturn';
import { MarsNotifier } from '../Common/Notification/MarsNotifier';
import { K1ShareHolderConstants } from '../Common/Constants';
import { EngagementTypeS } from '../../core/common/Enums';
import SignalRWebSocket from '../SignalRWebSocket';
import { logger } from '../../routes';
import { SvgSearchIcon } from '../Common/Icons/SvgIcons';  

interface DistributeManualProps {
    requestK1ShareHolderDetails: (
        clientGuid: string,
        pageNo: number,
        pageSize: number,
        sortBy: string,
        sortOrder: string,
        searchText: string,
        callback?: () => void
    ) => void;
    downloadK1DocumentAsync: (clientId: string, engagementTpe: EngagementTypeS, callback?: ()=> void) => void;
    downloadIndividualK1DocumentAsync: (
        clientId: string,
        shareHolder: IK1ShareHolderDetails,
        engagementTpe: EngagementTypeS
    ) => void;
    downloadSelectedK1DocumentAsync: (
        clientId: string,
        shareHolders: IK1ShareHolderDetails[],
        engagementTpe: EngagementTypeS,
        callback?: () => void
    ) => void;
    downloadBulkSelectedK1DocumentAsync: (
        clientId: string,
        unSelectedShareHolders: IK1ShareHolderDetails[],
        engagementTpe: EngagementTypeS,
        callback?: () => void
    ) => void;

    k1Data: IK1ShareHolderDetails[];
    match: match;
    taxReturn: ITaxReturn;
    requestMyDownload: (clientId: string) => any;
    isPreviewMode: boolean;
    downloadAllK1DocumentsAsync: (clientId: string, engagementTpe: EngagementTypeS, callback?: ()=> void) => void;
    isTrialView: boolean;
    isK1Loading: boolean;
    isDraftReturn?: boolean;
}

interface DistributeManualState {
    sortName: string;
    sortOrder: string;
    searchText: string;
    page: number;
    selectedK1Partners:IK1ShareHolderDetails[];
    showBulkSelectionMessage: boolean;
    isBulkSelectionEnabled: boolean;
    unSelectedRows: IK1ShareHolderDetails[];
}
const pageSize: number = 10;

export class DistributeManual extends React.Component<DistributeManualProps, DistributeManualState>{
    private _signalrRef: any = React.createRef();
    constructor(props: any) {
        super(props);
        this.state = {
            sortName: 'Name',
            sortOrder: 'asc',
            searchText: '',
            page: 1,
            selectedK1Partners:[],
            showBulkSelectionMessage: false,
            isBulkSelectionEnabled: false,
            unSelectedRows: []
        }
    }

    componentDidMount() {
        let param: any = this.props.match.params;
        logger.trackPageView('Manual K1 Distribute Page', { "ClientId": param.clientId, "DocumentId": this.props.taxReturn.id, "PreviewMode": this.props.isPreviewMode });
        this.props.requestK1ShareHolderDetails(
            param.clientId,
            this.state.page,
            pageSize,
            this.state.sortName,
            this.state.sortOrder,
            this.state.searchText);
    }

    loadK1ShareHolderDetails = () => {
        let param: any = this.props.match.params;
        this.props.requestK1ShareHolderDetails(
            param.clientId,
            this.state.page,
            pageSize,
            this.state.sortName,
            this.state.sortOrder,
            this.state.searchText,
            this.state.isBulkSelectionEnabled ? this.bulkSelect : null);
    }

    private bulkSelect = () => {
        let newSelectedK1Partners = [...this.state.selectedK1Partners];
        this.props.k1Data.forEach(k1Partner => {
            let index = newSelectedK1Partners.findIndex(x=> x.shareHolderId === k1Partner.shareHolderId)
            if(index == -1 && this.state.unSelectedRows.filter(x=> x.shareHolderId === k1Partner.shareHolderId).length == 0)
                newSelectedK1Partners.push(k1Partner);
        });
        this.setState({
            selectedK1Partners: newSelectedK1Partners,
        })
    }

    private onPageChange = (page: number, sizePerPage: number) => {
        this.setState({
            page: page,
            showBulkSelectionMessage: false
        }, () => this.loadK1ShareHolderDetails());
    }

    private onSortChange: any = (sortName: any, sortOrder: any, column: any) => {
        this.setState({
            sortName: sortName,
            sortOrder: sortOrder == "asc" ? "asc" : "desc",
        }, () => this.loadK1ShareHolderDetails());
    }

    private onSearchChange = (event: any) => { 
            this.setState({
                searchText: event.target.value,
                page: 1
            }, () => this.loadK1ShareHolderDetails());       
    }

    private onRowSelect = (row: any, isSelected: any, e: any) => {
        let newSelectedK1Partners = [...this.state.selectedK1Partners];
        let unSelectedRows = this.state.unSelectedRows;

        if (e.target.tagName !== 'BUTTON' && e.target.tagName !== 'I'
            && e.target.tagName !== 'SPAN' && e.target.tagName !== 'A') {
            if (isSelected) {
                newSelectedK1Partners.push(this.props.k1Data[row.rowIndex]);
                if(this.state.isBulkSelectionEnabled)
                {
                    unSelectedRows = unSelectedRows.filter(x=> x.shareHolderId != row.shareHolderId);
                }
            } else {
                newSelectedK1Partners = newSelectedK1Partners.filter(newk1Partner => newk1Partner.shareHolderId != row.shareHolderId );
                if(this.state.isBulkSelectionEnabled)
                {
                    unSelectedRows.push(this.props.k1Data.filter(x=> x.shareHolderId == row.shareHolderId)[0]);
                }    
            }
            this.state.isBulkSelectionEnabled ? this.setState({selectedK1Partners: newSelectedK1Partners, unSelectedRows: unSelectedRows}) :
            this.setState({selectedK1Partners: newSelectedK1Partners});
        }
    }

    private onSelectAll = (isSelected: any, rows: any[], e: any) => {
        let newSelectedK1Partners = [...this.state.selectedK1Partners];
        if (isSelected) {
            rows.forEach(row => {
                let index = newSelectedK1Partners.findIndex(x=> x.shareHolderId === this.props.k1Data[row.rowIndex].shareHolderId)
                if(index == -1)
                    newSelectedK1Partners.push(this.props.k1Data[row.rowIndex]);
            });
            this.setState({
                showBulkSelectionMessage: true
            })
        }
        else
        {
            rows.forEach(row=>{
                newSelectedK1Partners = newSelectedK1Partners.filter(newK1Partner => newK1Partner.shareHolderId != row.shareHolderId);
            });
            this.setState({
                showBulkSelectionMessage: false
            });
        }
        this.setState({
            selectedK1Partners: !this.state.isBulkSelectionEnabled ? newSelectedK1Partners: [],
            isBulkSelectionEnabled:false,
            unSelectedRows: []        
        });
    }

    private downloadSelectedK1s = () => {
        let param: any = this.props.match.params;
        if (this.state.selectedK1Partners.length == 0) {
            MarsNotifier.Warning(K1ShareHolderConstants.WarningMessage.CheckBoxSelect, null);
        }
        else {

            if(this.state.selectedK1Partners.length == 1) {
                this.props.downloadIndividualK1DocumentAsync(param.clientId,
                    this.state.selectedK1Partners[0],
                    this.props.taxReturn.engagementType);
                this.onClearSelection();
            }
            else {
                if(this.props.isTrialView) {
                    this.props.downloadAllK1DocumentsAsync(param.clientId, this.props.taxReturn.engagementType, this.onClearSelection);
                }
                else if (this._signalrRef && !this.props.isPreviewMode) {
                    if(this.state.isBulkSelectionEnabled)
                        this._signalrRef.initSignalR(() => this.props.downloadBulkSelectedK1DocumentAsync(param.clientId, this.state.unSelectedRows,
                            this.props.taxReturn.engagementType, this.onClearSelection));
                    else
                    this._signalrRef.initSignalR(() => this.props.downloadSelectedK1DocumentAsync(param.clientId, this.state.selectedK1Partners,
                        this.props.taxReturn.engagementType, this.onClearSelection));
                }
                else {
                    if(this.state.isBulkSelectionEnabled)
                        this.props.downloadBulkSelectedK1DocumentAsync(param.clientId, this.state.unSelectedRows,
                            this.props.taxReturn.engagementType, this.onClearSelection);
                    else
                    this.props.downloadSelectedK1DocumentAsync(param.clientId, this.state.selectedK1Partners,
                        this.props.taxReturn.engagementType, this.onClearSelection);
                }
            }
        }
    }

    private downloadAllK1Document = () =>{
        let param: any = this.props.match.params;

        if(this.props.isTrialView) {
            this.props.downloadAllK1DocumentsAsync(param.clientId, this.props.taxReturn.engagementType, this.onClearSelection);
        }
        else if (this._signalrRef && !this.props.isPreviewMode) {
            this._signalrRef.initSignalR(() => this.props.downloadK1DocumentAsync(param.clientId, this.props.taxReturn.engagementType, this.onClearSelection));
        }
        else {
            this.props.downloadK1DocumentAsync(param.clientId, this.props.taxReturn.engagementType, this.onClearSelection);
        }
    }

    private onSelectDownloadOption = (item: any) => {
        const value: number = parseInt(item.value);
        switch(value){
            case 1:
                this.downloadAllK1Document();
                break;
            case 2:
                this.downloadSelectedK1s();
                break;
        }
    }

    private onClearSelection = () => {
        this.setState({
            selectedK1Partners: [],
            isBulkSelectionEnabled: false,
            showBulkSelectionMessage: false
        });
    } 

    private onBulkSelectionChange = (isEnabled: boolean) => {
        let showBulkSelectionMessage = false;

        this.setState({
            isBulkSelectionEnabled: isEnabled,
            showBulkSelectionMessage: showBulkSelectionMessage
        });
    };

    public render() {
        const type: string = this.props.taxReturn && GetK1EngagementType(this.props.taxReturn.engagementType);
        const text: string = 'Select ' + type + ' to Print';
        return (
            <div className="col-xs-6 col-sm-6 col-md-12 col-lg-12 content-wrapper-2" style={{ minWidth: "100%" }}>
                <div className="displayFlex margin-bottom-40 margin-top-20">
                    <h3 className="k1-title displayFlex3 no-padding" data-test-auto="5DE433E2-EFAF-44A3-A116-E8EB5403B87F">Distribute K-1 Documents by Mail </h3>
                    <div className="k1Search">
                        <input
                            type='text'
                            id='searchText'
                            placeholder='Search'
                            value={this.state.searchText}
                            maxLength={500}
                            onChange={this.onSearchChange}
                            aria-label='Enter search text'
                        />
                        <button className="searchIcon" aria-label="Search">
                            <SvgSearchIcon />
                        </button>
                    </div>
                    <DownloadK1s
                        match={this.props.match}
                        taxReturn={this.props.taxReturn}
                        onSelect={this.onSelectDownloadOption}
                        isDraftReturn={this.props.isDraftReturn}
                    />

                    <div className="download-helper">
                        <span className="k1-sub-title" data-test-auto="3732082C-B09E-491A-A31D-654F3905B886"> {text} </span>
                    </div>
                </div>
                <DistributeManualTable
                    onPageChange={this.onPageChange}
                    onSortChange={this.onSortChange}
                    onSearchChange={this.onSearchChange}
                    onRowSelect={this.onRowSelect}
                    onSelectAll={this.onSelectAll}
                    selectedK1Partners={this.state.selectedK1Partners}
                    pageNo={this.state.page}
                    pageSize={pageSize}
                    k1Data={this.props.k1Data}
                    taxReturn={this.props.taxReturn}
                    match={this.props.match}
                    downloadIndividualK1DocumentAsync={this.props.downloadIndividualK1DocumentAsync}
                    isK1Loading={this.props.isK1Loading}
                    onBulkSelectionChange={this.onBulkSelectionChange}
                    showBulkSelectionMessage={this.state.showBulkSelectionMessage}
                    isDraftReturn={this.props.isDraftReturn}
                />

                {
                    <SignalRWebSocket
                        ref={(ins) => this._signalrRef = ins}
                        match={this.props.match}
                        requestMyDownload={this.props.requestMyDownload}
                    />
                }

            </div>
        )
    }
}