import { IRecipient } from '../../../components/CCRecipient/AddCCRecipientModal';
import { number, string } from 'prop-types';

export interface ICCRecipientModel {
    documentId: number | undefined;
    recipients: IRecipient[];
    downloadableDocuments: number[];
}

export interface ICCRecipientDownloadableDocuments {
    fileName: string;
    documentGroup: CCRecipientDocumentGroups;
}

export enum CCRecipientDocumentGroups {
    None = 0,
    Transmittals = 1,
    SignedEFile = 2,
    Vouchers = 3,
    TaxReturns = 4,
    Invoice = 5,
    AdditionalEsignDocument = 6,
    Attachments = 7,
    PaperFiles = 8
}

export interface ICcRecipientDownloadModel extends ICCRecipientDownloadableDocuments {
    documentId: number,
    taxYear: number,
    documentGuid: string,
}

export const initialModalState: ICCRecipientDownloadableDocuments[] = [];

export const initialDownloadModalState: ICcRecipientDownloadModel[] = [];