import { AxiosResponse } from 'axios';
import { Action, Reducer } from 'redux';

import { initializeAxios } from '../../core/services/dataAccess/DataService.Axios';
import { actionTypes } from '../ActionTypes';
import { NotificationAction, StatusType } from '../Common/NotificationStore';
import { AppThunkAction } from '../index';
import { IControllerInfo, initialControllerInfo } from '../../core/domain/models/groupedReturns/IControllerInfo';
import { ErrorMessages } from '../../components/Common/Constants';
import { TelemetryLogger } from '../../components/Logger/AppInsights';

const logger = TelemetryLogger.getInstance();

export interface GroupedReturnsState {
    controllerInfoState: ControllerInfoState;
}

export interface ControllerInfoState {
    controllerInfo: IControllerInfo;
    isLoading: boolean;
}

export const initialControllerInfoState: ControllerInfoState = {
    controllerInfo: initialControllerInfo,
    isLoading: false
}

export const initialGroupedReturnsState: GroupedReturnsState = {
    controllerInfoState: initialControllerInfoState
}

interface RequestControllerInfoAction {
    type: actionTypes.CONTROLLERINFO_REQUEST
}

interface ResponseControllerInfoAction {
    type: actionTypes.CONTROLLERINFO_RESPONSE;
    data: IControllerInfo;
}

interface FailureControllerInfoAction {
    type: actionTypes.CONTROLLERINFO_FAILURE;
    data: IControllerInfo;
}

interface UpdateControllerInfoRequestAction {
    type: actionTypes.UPDATE_CONTROLLER_MOBILENUMBER_REQUEST,
}

interface UpdateControllerInfoSuccessAction {
    type: actionTypes.UPDATE_CONTROLLER_MOBILENUMBER_SUCCESS,
    data: IControllerInfo;
}

interface UpdateControllerInfoFailureAction {
    type: actionTypes.UPDATE_CONTROLLER_MOBILENUMBER_FAILURE;
    data: IControllerInfo;
}


type DispatchAction = RequestControllerInfoAction | ResponseControllerInfoAction |
    FailureControllerInfoAction | UpdateControllerInfoRequestAction | UpdateControllerInfoSuccessAction | UpdateControllerInfoFailureAction;

type KnownAction = DispatchAction | NotificationAction;

export const actionCreators = {
    requestControllerInfo: (clientId: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const state = getState();
        dispatch({ type: actionTypes.CONTROLLERINFO_REQUEST });
        return initializeAxios().get<IControllerInfo>(`api/ControllerDashboard/GetControllerInfo/${clientId}`)
            .then(function (response: AxiosResponse<IControllerInfo>) {
                dispatch({
                    type: actionTypes.CONTROLLERINFO_RESPONSE,
                    data: response.data,
                });
            })
            .catch(function (error: any) {
                dispatch({
                    type: actionTypes.NOTIFICATION, statusMessage: error.response ? error.response.statusText : "Error occurred while requesting Grouped Returns",
                    statusType: StatusType.Error
                });
                dispatch({ type: actionTypes.CONTROLLERINFO_FAILURE, data: state.groupedReturnsData.controllerInfoState.controllerInfo });
                logger.trackWarning(`requestControllerInfo failed with error ${error.message} for controller Id: ${clientId}`, { "ClientId": clientId });
            });
    },
    updateControllerMobileNumber: (clientGuid: string, number: string, countryCodeValue: string, callback: () => void):
        AppThunkAction<KnownAction> => (dispatch, getState) => {
            const state = getState();
            let info = state.groupedReturnsData.controllerInfoState.controllerInfo;
            dispatch({ type: actionTypes.UPDATE_CONTROLLER_MOBILENUMBER_REQUEST });

            const data = { mobileNumber: number, countryCode: countryCodeValue };
            return initializeAxios().putJson(data, 'api/ControllerDashboard/UpdateMobileNumber/' + clientGuid)
                .then(function () {
                    info.mobileNumber = data.mobileNumber;
                    info.countryCode = data.countryCode;
                    dispatch({
                        type: actionTypes.UPDATE_CONTROLLER_MOBILENUMBER_SUCCESS, data: info
                    });
                    callback();
                })
                .catch(function (error: any) {
                    dispatch({
                        type: actionTypes.UPDATE_CONTROLLER_MOBILENUMBER_FAILURE,
                        data: info
                    });
                    dispatch({
                        type: actionTypes.NOTIFICATION, statusMessage: error.response ? error.response.statusText : ErrorMessages.ChangeMobileNumberError,
                        statusType: StatusType.Error
                    });
                    logger.trackWarning(`updateControllerMobileNumber failed with error ${error.message} for controller Id: ${clientGuid}`, { "ClientId": clientGuid });
                });
        }
}

export const reducer: Reducer<GroupedReturnsState> = (state: GroupedReturnsState = initialGroupedReturnsState, incomingAction: Action) => {
    const action = incomingAction as DispatchAction;
    const currentState = Object.assign({}, state);
    switch (action.type) {
        case actionTypes.CONTROLLERINFO_REQUEST:
            currentState.controllerInfoState.isLoading = true;
            return currentState;
        case actionTypes.CONTROLLERINFO_RESPONSE:
        case actionTypes.CONTROLLERINFO_FAILURE:
            return {
                ...currentState, controllerInfoState: { controllerInfo: action.data, isLoading: false }
            };
        case actionTypes.UPDATE_CONTROLLER_MOBILENUMBER_REQUEST:
            currentState.controllerInfoState.isLoading = true;
            return currentState;
        case actionTypes.UPDATE_CONTROLLER_MOBILENUMBER_SUCCESS:
        case actionTypes.UPDATE_CONTROLLER_MOBILENUMBER_FAILURE:
            return {
                ...currentState, controllerInfoState: { controllerInfo: action.data, isLoading: false }
            };
        default:
            return currentState || initialGroupedReturnsState;
    }
};