import { AxiosResponse } from "axios";
import { Action, Reducer } from "redux";
import { initializeAxios } from "../../core/services/dataAccess/DataService.Axios";
import { actionTypes } from "../ActionTypes";
import { NotificationAction, StatusType } from "../Common/NotificationStore";
import { AppThunkAction } from "../index";
import { SignProcessSteps } from "../../components/Home/TaxpayerSignFlowManager";
import {
  IClientProcessViewModel,
  initialClientProcessModel,
} from "../../core/domain/viewModels/IClientProcessViewModel";
import { AuthToasterError } from "../../components/Common/Constants";
import { OTPPageConstants } from "../../components/Common/Constants";
import {
  OTPMobilePageConstants,
  DisplayError,
} from "../../components/Common/Constants";

export interface IDelegateeOTPPage {
  loading: boolean;
  error: any;
  mobileNo: string;
  countryCode: string;
  mfaSettings: any;
  delegatee: any;
  otpCreationTime: any;
}

const OTPPage: IDelegateeOTPPage = {
  loading: false,
  error: null,
  mobileNo: "",
  countryCode: "",
  mfaSettings: null,
  delegatee: null,
  otpCreationTime: null,
};

interface RequestSignProcessAction {
  type: actionTypes.SIGN_PROCESS_REQUEST;
}
interface RequestCurrentStepAction {
  type: actionTypes.SIGN_PROCESS_STEP;
  data: IClientProcessViewModel;
}

interface ResponseSignProcessAction {
  type: actionTypes.SIGN_PROCESS_RESPONSE;
  data: number;
}

interface FailureSignProcessAction {
  type: actionTypes.SIGN_PROCESS_FAILURE;
  data: number;
}

interface UpdateLastVisitedStep {
  type: actionTypes.UPDATE_LAST_VISITED_PROCESS_STEP;
  data: SignProcessSteps;
}

export interface SignProcessState {
  data: number;
  clientprocessmodel: IClientProcessViewModel;
}

export const initialSignProcessState: SignProcessState = {
  data: 0,
  clientprocessmodel: initialClientProcessModel,
};

type KnownAction = DispatchAction | NotificationAction;

type DispatchAction =
  | ResponseSignProcessAction
  | RequestSignProcessAction
  | FailureSignProcessAction
  | RequestCurrentStepAction
  | UpdateLastVisitedStep;

interface IClientResponse {
  IsSuccess: boolean;
  ErrorCode: string;
  ErrorDescription: string;
  Data: any;
}

export const actionCreators = {
  pageValidityDelegatee:
    (
      clientId: string,
      navigate?: (path: string) => void,
      handleError?: (result?: any) => void
    ): AppThunkAction<any> =>
    (dispatch, getState) => {
      dispatch({ type: actionTypes.SET_DEL_OTP_LOADING });
      //add api url
      return initializeAxios()
        .get<any>("api/Delegatee/" + clientId)
        .then(function (response: AxiosResponse<any>) {
          let result = response.data;

          if (result.isSuccess) {
            navigate && navigate(result.data);
            dispatch({ type: actionTypes.SET_VALID_DEL_OTP_PAGE });
          } else {
            let errorDesc = result.errorDescription;
            let errorCode = result.errorCode;

            if (errorCode && DisplayError.includes(errorCode)) {
              dispatch({
                type: actionTypes.SET_ERROR_MSG,
                data: errorDesc,
              });
            } else {
              dispatch({ type: actionTypes.SET_INVALID_DEL_OTP_PAGE });
            }
            if (errorCode && AuthToasterError.includes(errorCode)) {
              dispatch({
                type: actionTypes.NOTIFICATION,
                statusMessage: errorDesc,
                statusType: StatusType.Error,
              });
            } else {
              handleError && handleError(result);
            }
          }
        })
        .catch((e: any) => {
          dispatch({
            type: actionTypes.NOTIFICATION,
            statusMessage: OTPPageConstants.ErrorMessage.serverError,
            statusType: StatusType.Error,
          });
        });
    },
  delegateeClientInfo:
    (clientId?: string): AppThunkAction<any> =>
    (dispatch, getState) => {
      dispatch({ type: actionTypes.SET_DEL_OTP_LOADING });
      //add api url
      return initializeAxios()
        .get<any>("api/Delegatee/GetClientInfo/" + clientId)
        .then(function (response: AxiosResponse<any>) {
          let result = response.data;

          dispatch({ type: actionTypes.SET_DEL_CLIENTDATA, data: result });
        })
        .catch((e: any) => {
          dispatch({
            type: actionTypes.NOTIFICATION,
            statusMessage: OTPPageConstants.ErrorMessage.serverError,
            statusType: StatusType.Error,
          });
        });
    },

  generateMobileOTPDelegatee:
    (clientId?: string): AppThunkAction<any> =>
    (dispatch, getState) => {
      //put the api url
      return initializeAxios()
        .postJson<any>(null, "api/Delegatee/Mobile/Generate/" + clientId)
        .then(function (response: any) {
          let result = response.data;
          if (result) {
            dispatch({
              type: actionTypes.NOTIFICATION,
              statusMessage:
                OTPMobilePageConstants.SuccessMessage.OTPGenerateSuccess,
              statusType: StatusType.Success,
            });
          } else {
            dispatch({
              type: actionTypes.NOTIFICATION,
              statusMessage:
                OTPMobilePageConstants.ErrorMessage.OTPGenerateFailed,
              statusType: StatusType.Error,
            });
          }
        })
        .catch(function (error: any) {
          dispatch({
            type: actionTypes.NOTIFICATION,
            statusMessage: OTPMobilePageConstants.ErrorMessage.serverError,
            statusType: StatusType.Error,
          });
        });
    },
  verifyMobileOTPDelegatee:
    (
      OTP: string,
      clientId?: string,
      handleVerify?: (guid: string) => void,
      handleError?: (result?: any) => void
    ): AppThunkAction<any> =>
    (dispatch, getState) => {
      return initializeAxios()
        .postJson<any>(
          '"' + OTP + '"',
          "api/Delegatee/Text/Validate/" + clientId
        )
        .then(function (response: any) {
          let result = response.data;
          if (result.isSuccess) {
            dispatch({
              type: actionTypes.NOTIFICATION,
              statusMessage: "valid OTP",
              statusType: StatusType.Success,
            });
            if (handleVerify !== undefined) {
              handleVerify(result.data);
            }
          } else {
            let errDesc = result.errorDescription;
            let errorCode = result.errorCode;

            if (errorCode && DisplayError.includes(errorCode)) {
              dispatch({
                type: actionTypes.SET_ERROR_MSG,
                data: errDesc,
              });
            }
            if (errorCode && AuthToasterError.includes(errorCode)) {
              dispatch({
                type: actionTypes.NOTIFICATION,
                statusMessage: errDesc,
                statusType: StatusType.Error,
              });
            } else {
              handleError && handleError(result);
            }
          }
        })
        .catch(function (error: any) {
          dispatch({
            type: actionTypes.NOTIFICATION,
            statusMessage: OTPMobilePageConstants.ErrorMessage.serverError,
            statusType: StatusType.Error,
          });
        });
    },
  generateOTPDelegatee:
    (clientId?: string): AppThunkAction<any> =>
    (dispatch, getState) => {
      return initializeAxios()
        .postJson<any>(null, "api/Delegatee/Generate/" + clientId)
        .then(function (response: any) {
          let result = response.data;
          if (result) {
            dispatch({
              type: actionTypes.NOTIFICATION,
              statusMessage: OTPPageConstants.SuccessMessage.OTPGenerateSuccess,
              statusType: StatusType.Success,
            });
          } else {
            dispatch({
              type: actionTypes.NOTIFICATION,
              statusMessage: OTPPageConstants.ErrorMessage.OTPGenerateFailed,
              statusType: StatusType.Error,
            });
          }
        })
        .catch(function (error: any) {
          dispatch({
            type: actionTypes.NOTIFICATION,
            statusMessage: OTPMobilePageConstants.ErrorMessage.serverError,
            statusType: StatusType.Error,
          });
        });
    },
  verifyOTPDelegatee:
    (
      OTP: string,
      clientId: string,
      handleVerify?: (guid: string) => void,
      handleError?: (result?: any) => void
    ): AppThunkAction<any> =>
    (dispatch, getState) => {
      //make the api url changes
      return initializeAxios()
        .postJson<any>('"' + OTP + '"', `api/Delegatee/Validate/${clientId}`)
        .then(function (response: any) {
          let result = response.data;

          if (result.isSuccess) {
            //check the final route
            dispatch({
              type: actionTypes.NOTIFICATION,
              statusMessage: "valid otp",
              statusType: StatusType.Success,
            });

            if (handleVerify !== undefined) {
              handleVerify(result.data);
            }
          } else {
            let errorDesc = result.errorDescription;
            let errorCode = result.errorCode;

            if (errorCode && DisplayError.includes(errorCode)) {
              dispatch({
                type: actionTypes.SET_ERROR_MSG,
                data: errorDesc,
              });
            }
            if (errorCode && AuthToasterError.includes(errorCode)) {
              dispatch({
                type: actionTypes.NOTIFICATION,
                statusMessage: errorDesc,
                statusType: StatusType.Error,
              });
            } else {
              handleError && handleError(result);
            }
          }
        })
        .catch(function (error: any) {
          dispatch({
            type: actionTypes.NOTIFICATION,
            statusMessage: OTPPageConstants.ErrorMessage.serverError,
            statusType: StatusType.Error,
          });
        });
    },
  setMobileNo:
    (mobileNo: string, countryCode: string): AppThunkAction<any> =>
    (dispatch, getState) => {
      return dispatch({
        type: actionTypes.SET_MOBILE_VAL_Delegatee,
        data: { mobileNo, countryCode },
      });
    },
};

export const reducer: Reducer<IDelegateeOTPPage> = (
  state: IDelegateeOTPPage = OTPPage,
  incomingAction: Action
): any => {
  const action = incomingAction as any;
  const currentState = Object.assign({}, state);
  console.log("in reducers", action);
  switch (action.type) {
    case actionTypes.SET_DEL_OTP_LOADING:
      return { ...currentState, loading: true };
    case actionTypes.SET_INVALID_DEL_OTP_PAGE:
      //set a error message stating this is a invalid page
      return { ...currentState, loading: true };
    case actionTypes.SET_VALID_DEL_OTP_PAGE:
      return { ...currentState, loading: false };
    case actionTypes.SET_DEL_CLIENTDATA:
      let data = action.data;
      (currentState.mfaSettings = data.mfaSettings),
        (currentState.delegatee = data.delegatee),
        (currentState.otpCreationTime = data.otpCreationTime);
      return { ...currentState };
    case actionTypes.SET_OTP_ERROR:
      //set the exception error for not getting data.
      let errorMsg = action.error;
      return { ...currentState, loading: false, error: errorMsg };
    case actionTypes.OTP_GENERATE_FAILED:
      //set the alert for OTP generate failed.
      return { ...currentState };
    case actionTypes.OTP_GENERATE_SUCCESS:
      //set the alert for OTP generate success.
      return { ...currentState };
    case actionTypes.SET_MOBILE_VAL_Delegatee:
      return {
        ...currentState,
        mobileNo: action.data.mobileNo,
        countryCode: action.data.countryCode,
      };

    case actionTypes.GENERATE_TAXPAYER_OTP:
      /*add the data getting back from the api*/

      return { ...currentState };
    default:
      return currentState || initialSignProcessState;
  }
};
