import { Step } from '@sssuite-component-ui/multi-step-wizard/dist/Body/Step';
import { Wizard } from '@sssuite-component-ui/multi-step-wizard/dist/Body/Wizard';
import { History } from 'history';
import * as React from 'react';
import { match, RouteComponentProps } from 'react-router';
import { SignatureType, SignatureStatus } from '../../core/common/Enums';
import { ICompany } from '../../core/domain/models/company/Company';
import { IDocument } from '../../core/domain/models/esign/Document';
import { IDocumentSettings } from '../../core/domain/models/IDocumentSettings';
import { initailsignatureSettingsModel, ISignatureSettingsModel } from '../../core/domain/models/ISignatureSettingsModel';
import { ITaxDocumentModel } from '../../core/domain/models/ITaxDocumentModel';
import * as TaxDocument from '../../core/domain/models/ITaxReturn';
import { ITutorialInfo } from '../../core/domain/models/ITutorialInfo';
import { ISignedDocument } from '../../core/domain/models/manualsign/SignedDocument';
import { IClientProcessViewModel } from '../../core/domain/viewModels/IClientProcessViewModel';
import { ICommonDataViewModel } from '../../core/domain/viewModels/ICommonDataViewModel';
import { IDocumentReviewModel } from '../../core/domain/viewModels/IDocumentReviewModel';
import { IHeaderInfoViewModel, initialHeaderInfoViewModel } from '../../core/domain/viewModels/IHeaderInfoViewModel';
import { ILoggedInUserInfo } from '../../core/domain/viewModels/ILoggedInUserInfo';
import { ISignProcessInfoViewModel } from '../../core/domain/viewModels/ISignProcessInfoViewModel';
import { IDialogBox } from '../../core/utilities/ui/DialogBox';
import { container } from '../../startup/inversify.config';
import { TYPES } from '../../startup/types';
import * as AccountStore from '../../store/Common/AccountStore';
import * as CompanyStore from '../../store/Common/Company/CompanyStore';
import * as EsignStore from '../../store/Common/EsignStore';
import * as HelperStore from '../../store/Common/HelperStore';
import * as TaxDocumentStore from '../../store/Common/TaxDocumentStore';
import * as PreparerMessageStore from '../../store/PreparerMessageStore';
import * as DocumentReviewStore from '../../store/ReviewStore';
import * as ManualSignStore from '../../store/Sign/ManualSignStore';
import * as SignProcessStore from '../../store/SignProcess/SignProcessStore';
import * as SummaryStore from '../../store/SummaryStore';
import * as TutorialStore from '../../store/Tutorial/TutorialStore';
import { Common, ManualSignConstants, DelegateePathConstants } from '../Common/Constants';
import {
    SvgIconDownloadWizardActive,
    SvgIconReviewWizardActive,
    SvgIconSignWizardActive,
    SvgIconSummaryWizardActive,
    SvgIconSignCompleted,
    PreviousButtonIcon,
    NextButtonIcon,
    TemplateIcon
} from '../Common/Icons/SvgIcons';
import { Body } from '../Common/StepLayout/Body';
import { StepLayout } from '../Common/StepLayout/StepLayout';
import { Esign } from '../Delegatee/Esign/Esign';
import { PdfViewManager } from '../Esign/Main/EsignBase';
import SelectSignMethod from '../Delegatee/Esign/SelectSignMethod';
import SignatureCompletedPage from '../Esign/SignatureCompletedPage';
import TaxpayerHelper from '../Helper/TaxpayerHelper';
import { ManualSign } from '../ManualSign/ManualSign';
import { ManualSignDownloadFiles } from '../ManualSign/ManualSignDownloadFiles';
import * as PreparerMessageWizard from '../PreparerMessage/MessageWizard';
import * as ReviewWizard from '../Delegatee/Review/ReviewWizard';
import * as SummaryWizard from '../Delegatee/Summary/WizardBody';
import { DelegateeSignManager } from '../Delegatee/DelegateeSignManager';
import { SignProcessSteps } from '../Home/TaxpayerSignFlowManager';
import { IAdditionalEsignDocument } from '../../core/domain/models/IAdditionalEsignDocument';
import * as DelegateeSignerStore from '../../store/DelegateeSignerStore';
import * as DelegateeSignProcessStore from '../../store/DelegateeSignProcessStore';
import { IDelegateeInfo } from '../../core/domain/models/IDelegateeSignerModel';
import { ICCRecipientDownloadableDocuments } from '../../core/domain/models/ICCRecipientModel';
import { ISignerModel } from '../../core/domain/models/ISignerModel';
import { KbaConsentForm } from '../Delegatee/Esign/KbaConsentForm';
import { EngagementTypeS, KBATransactionResultType } from '../../core/common/Enums'
import { KBAComponent } from "../Esign/KBAComponent";
import { IKBATransactionResponse } from '../../core/domain/models/IKBA';
import { logger } from '../../routes';

const NO_INDEX = -1;

export type MainComponentProps =
    {
        esignData: ITaxDocumentModel,
        companyData: ICompany,
        delegateeSignatureControlsData: IDocument[],
        commonData: ICommonDataViewModel,
        signatureSettingModel: ISignatureSettingsModel,
        documentSettings: IDocumentSettings,
        delegateeReviewModel: IDocumentReviewModel[],
        taxReturn: TaxDocument.ITaxReturn,
        match: match;
        history: History;
        loading: boolean;
        signProcessInfo: ISignProcessInfoViewModel,
        loggedInUserInfo: ILoggedInUserInfo,
        tutorialInfo: ITutorialInfo,
        additionalEsignDocuments: IAdditionalEsignDocument[];
        downloadAllAdditionEsignDocuments: (clientId: string, fileName: string) => any;
        requestAdditionalEsignDocument(clientGuid: string): void;
        delegateeHeaderInfo: IHeaderInfoViewModel;
        delegateeSigner: IDelegateeInfo;
        delegateeManualSignData: ISignedDocument[];
        delegateeDownloadableDocuments: ICCRecipientDownloadableDocuments[],
        signerData: ISignerModel[],
        delegateeKBAData: IKBATransactionResponse,
        isKBAPassed: boolean
    }
    & typeof SummaryStore.actionCreators
    & typeof HelperStore.actionCreators
    & typeof EsignStore.actionCreators
    & typeof AccountStore.actionCreators
    & typeof ManualSignStore.actionCreators
    & typeof CompanyStore.actionCreators
    & typeof PreparerMessageStore.actionCreators
    & typeof DocumentReviewStore.actionCreators
    & typeof TaxDocumentStore.actionCreators
    & typeof SignProcessStore.actionCreators
    & typeof TutorialStore.actionCreators
    & typeof DelegateeSignerStore.actionCreators
    & typeof DelegateeSignProcessStore.actionCreators
    & RouteComponentProps<{}>;

interface MainComponentState {
    signatureSettings: ISignatureSettingsModel;
    delegateeHeaderInfo: IHeaderInfoViewModel;
    hideNextButton: boolean;
    hidePreviousButton: boolean;
    isNextButtonDisable: boolean;
    isConsentAgreed: boolean;
    hideNavigationBar: boolean;
    hideFooter: boolean;
    isPreviewMode: boolean;
    isAccessedFromOneHub: boolean;
}

const dialogBox = container.get<IDialogBox>(TYPES.IDialogBox);

export class DelegateeSignFlow extends React.Component<MainComponentProps, MainComponentState> {
    private _wizardRef: any;
    private _reviewRef: any;
    private _esignRef: any;
    private _manualSign: any;
    private _params: any;
    private _delegateeSignManager: DelegateeSignManager = DelegateeSignManager.createNullObject();
    private _kbaConsentRef: any;

    constructor(props: MainComponentProps) {
        super(props);
        this.state = {
            signatureSettings: initailsignatureSettingsModel,
            delegateeHeaderInfo: initialHeaderInfoViewModel,
            hideNextButton: false,
            hidePreviousButton: false,
            isNextButtonDisable: true,
            isConsentAgreed: false,
            hideNavigationBar: false,
            hideFooter: false,
            isPreviewMode: false,
            isAccessedFromOneHub: false
        }
    }

    componentDidMount() {
        let param: any = this.props.match.params;
        this._params = this.props.match.params;
        this.getSignProcessStatus(param.clientId);
        this.props.requestDocumentSettings(param.clientId);
        this.props.requestCompanyDetails(param.clientId, true, true);
        this.props.requestTaxDocument(param.clientId, (taxReturn: TaxDocument.ITaxReturn) => {
            this.props.requestDelegateeDetails(param.clientId, () => { 
            this.props.requestLastVisitedStep(
                this._params.clientId,
                (lastVisitedStep: SignProcessSteps) => {
                    this.signProcessRecoverConfirmation(lastVisitedStep)
                });
            });
            this.props.requestDelegateeKBAStatus(param.clientId);
        });
        this.props.requestAdditionalEsignDocument(this._params.clientId);
    }

    private getSignProcessStatus = (clientId: any) => {
        this.props.requestDelegateeCurrentStepAndUserRole(clientId, this.handleRedirect);
    }

    private handleRedirect = (clientProcessState: IClientProcessViewModel) => {
        this._params = this.props.match.params;
        const url = TaxpayerHelper.getDelegateeSignFlowRedirectUrl(clientProcessState, this._params.clientId);
        if (url && url.trim()) {
            this.props.history.push(url);
        }
        this.setState({isAccessedFromOneHub: clientProcessState.isAccessedFromOneHub});
    }

    signProcessRecoverConfirmation = (lastVisitedStep: SignProcessSteps) => {
        let param: any = this.props.match.params;
        if (lastVisitedStep !== SignProcessSteps.SignerProcessComplete && this.props.taxReturn.id > 0
            && this.props.taxReturn.signatureStatus.toString() != SignatureStatus[SignatureStatus.ManuallySigned]) {

            this._delegateeSignManager.signProcessRecoverConfirmation(lastVisitedStep, param.clientId, this.props.requestTutorialInfo);
        }
    }

    handleSetSignMethod = (signatureType: SignatureType) => {

        const param: any = this.props.match.params;

        let tempSignatureSetting: ISignatureSettingsModel = this.state.signatureSettings as ISignatureSettingsModel;
        tempSignatureSetting.signatureFormSelectionType = signatureType;

        this.setState({ signatureSettings: tempSignatureSetting }, () => {
            this.props.updateDocumentSignatureSettingModel(param.clientId, signatureType, () => {
                this.updateSettingsAndRefreshTaxDocument(param.clientId);
            });
        });
    }

    updateSettingsAndRefreshTaxDocument = (clientId: string) => {
        this.props.requestTaxDocument(clientId);
        if (this.state.signatureSettings.signatureFormSelectionType === SignatureType.ESign) {
            this.goToNextStep();
        } else {
            this.goToStepById(SignProcessSteps.ManualSignDownload.toString());
        }
    }

    private clearState = () => {
        this.setState({
            hideNextButton: false,
            hidePreviousButton: false,
            isNextButtonDisable: false
        })
    }

    goToStepById = (id: any) => {
        this._wizardRef && this._wizardRef.goToStepById(id)
    }

    thumbNailClick = (stepNumber: number) => {
        this.goToStepById(stepNumber);
    }


    goToStep = (stepNumber: number) => {
        this._wizardRef && this._wizardRef.goToStep(stepNumber);
    }

    goToNextStep = () => {
        this._wizardRef && this._wizardRef.goToNextStep();
    };

    handleHeaderAndFooterVisibility = (hidden: boolean) => {
        this.setState({ hideNavigationBar: hidden, hideFooter: hidden });
    }

    goToPreviousStep = () => {
        this._wizardRef && this._wizardRef.goToPreviousStep();
    };


    disableNextButton = (value: boolean) => {
        this.setState({ isNextButtonDisable: value });
    }

    onReviewWizardPreviousBtnClick = () => {
        this._reviewRef.onPrevious(this._reviewRef.state.currentStep);
    }

    onReviewWizardNextBtnClick = () => {
        this._reviewRef.onNext(this._reviewRef.state.currentStep);
    }

    onEsignWizardNextBtnClick = (currStep: number) => {
        this._esignRef.onNext(currStep);
    }


    onEsignComplete = () => {
        this.goToNextStep();
    }

    handleStepChange = (step: number, stepId: string) => {
        if (stepId == SignProcessSteps.SignComplete.toString()) {
            stepId = SignProcessSteps.SignerProcessComplete.toString();
        }
        this.props.updateLastVisitedStep(this._params.clientId, Number(stepId));
    }

    handleSubmit = () => {
        const param: any = this.props.match.params;
        this.props.updateLastVisitedStep(this._params.clientId, SignProcessSteps.SignerProcessComplete,
            () => {
                this.props.history.push(`${DelegateePathConstants.CompletionWizard}${param.clientId}`);
            });
    }

    disableNavigationLink = (step: SignProcessSteps) => {
        let index = NO_INDEX;
        index = this.props.signProcessInfo.visitedSteps && this.props.signProcessInfo.visitedSteps.indexOf(step);
        if (index == null || (index != null && index === NO_INDEX)) {
            return true;
        }
        else {
            return false;
        }
    }

    confirmRedirectToCompletionWizard = () => {
        const param: any = this.props.match.params;
        if (this.props.delegateeManualSignData.length > 0) {
            logger.trackTrace("Delegatee Manual sign completion pop up opened", { "ClientId": param.clientId, "DocumentId": this.props.taxReturn.id, "PreviewMode": this.state.isPreviewMode });
            dialogBox.confirm(
                Common.DialogBox.ConfirmationTitle,
                ManualSignConstants.ConfirmMessage.replace("<COMPANYNAME>", this.props.delegateeHeaderInfo.companyName),
                Common.DialogBox.Cancel,
                Common.DialogBox.Confrim,
                (result: boolean) => {
                    logger.trackTrace("Delegatee Manual sign completion pop up result ",
                        { "ClientId": param.clientId, "DocumentId": this.props.taxReturn.id, "Result": result, "PreviewMode": this.state.isPreviewMode });
                    result && this._manualSign && this._manualSign.completeManualSign()
                });
        }
        else {
            this.props.updateLastVisitedStep(this._params.clientId, SignProcessSteps.SignerProcessComplete,
                () => {
                    this.props.history.push(`${DelegateePathConstants.CompletionWizard}${this._params.clientId}`);
                });
        }
    }


    downloadSelectedDocuments = (documentGroups: number[]) => {
        const param: any = this.props.match.params;
        this.props.downloadDelegateeSelectedDocuments(param.clientId, documentGroups);
    }

    downloadAllDocuments = () => {
        const param: any = this.props.match.params;
        let documentGroups: number[] = [];
        this.props.delegateeDownloadableDocuments.forEach((document, i) => {
            documentGroups.push(document.documentGroup)
        });
        this.props.downloadDelegateeSelectedDocuments(param.clientId, documentGroups);
    }

    handleDecline = () => {
        this._esignRef.onShowModal();
    }

    onConsentAgreed = (value: boolean) => {
        this.disableNextButton(!value);
        this.setState({ isConsentAgreed: value });
    }

    onKBAFailure = () => {
        this.goToStepById(SignProcessSteps.ManualSignDownload.toString());
    }

    onKBAConsentNext = () => {
        this._kbaConsentRef.onNext();
    }

    public render() {
        const { hideNavigationBar, hideFooter } = this.state;

        const completedPercentage = PdfViewManager.getControlValueUpdatedPercentage(this.props.delegateeSignatureControlsData);

        const param: any = this.props.match.params;
        const SignStepIcon = Common.Images.SignStepIcon;

        return (
            <div className='delegatee-sign-flow'>
                <Wizard
                    hideTopNavigation={hideNavigationBar}
                    hideFooter={hideFooter}
                    submitLabel={this.state.isAccessedFromOneHub ? Common.Wizard.NextButtonLabel : "Done"}
                    ref={(ref: any) => {
                        this._wizardRef = ref;
                        this._delegateeSignManager = DelegateeSignManager.create(ref, this.props);
                    }
                    }
                    onStepChange={this.handleStepChange}
                    onSubmit={this.handleSubmit}
                    disableMobileResponsiveness={true}
                    mainStepTabIndex={0}
                >

                    {
                        this.props.taxReturn.id > 0 &&
                        <Step id={SignProcessSteps.Summary.toString()}
                            tooltip={"Summary"}
                            name={"Summary"}
                            template={<TemplateIcon />}
                            hideNextButton={true} hidePreviousButton={true}
                            previousLabel={Common.Wizard.PreviousButtonLabel}
                            nextLabel={Common.Wizard.NextButtonLabel} >
                            <SummaryWizard.WizardBody
                                match={this.props.match}
                                history={this.props.history}
                                delegateeSignerInfo={this.props.delegateeSigner}
                                goToStep={this.goToStep}
                                signProcessInfo={this.props.signProcessInfo}
                                goToStepById={this.thumbNailClick}
                                tutorialInfo={this.props.tutorialInfo}
                                hideSummaryTutorial={this.props.hideSummaryTutorial}
                                headerInfo={this.props.delegateeHeaderInfo}
                                isPreviewMode={this.state.isPreviewMode}
                            />
                        </Step>
                    }

                    {
                        this.props.taxReturn.id > 0 &&
                        <Step id={SignProcessSteps.PreparerMessage.toString()}
                            disableNavigationClick={true}
                            previousLabel={Common.Wizard.PreviousButtonLabel}
                            nextLabel={Common.Wizard.NextButtonLabel}
                            previousButtonIcon={<PreviousButtonIcon />}
                            nextButtonIcon={<NextButtonIcon />}
                        >
                            <PreparerMessageWizard.PreparerMessageWizardBody
                                match={this.props.match}
                                preparerMessage={this.props.delegateeSigner.description}
                                headerInfo={this.props.delegateeHeaderInfo}
                                delegateeInfo={this.props.delegateeSigner}
                                isDelegatee={true}
                                isPreviewMode={this.state.isPreviewMode}
                            />
                        </Step>
                    }

                    {
                        this.props.taxReturn.id > 0 &&

                        <Step id={SignProcessSteps.SignMethod.toString()}
                            name={"Sign"}
                            tooltip={"Sign"}
                            template={<TemplateIcon />}
                            hidden={!(this.props.taxReturn.documentSettings.documentSignatureSetting.signatureFormSelectionType == SignatureType.ESign
                                && !this._delegateeSignManager.isSigningProcessCompleted())}
                            hideNextButton={true}
                            hidePreviousButton={false}
                            disableNavigationClick={this.disableNavigationLink(SignProcessSteps.SignMethod)}
                            previousLabel={Common.Wizard.PreviousButtonLabel}
                            nextLabel={Common.Wizard.NextButtonLabel}
                            previousButtonIcon={<PreviousButtonIcon />}
                            nextButtonIcon={<NextButtonIcon />}
                        >
                            <SelectSignMethod
                                onSetSignMethod={this.handleSetSignMethod}
                                clearParentState={this.clearState}
                                match={this.props.match}
                                isPreviewMode={this.state.isPreviewMode}
                            />
                        </Step>
                    }

                    {
                        this.props.taxReturn.id > 0 &&
                        <Step id={SignProcessSteps.EsignConsentEmail.toString()}
                            hidden={!(this.props.taxReturn.documentSettings.documentSignatureSetting.signatureFormSelectionType == SignatureType.ESign
                                && this.props.taxReturn.engagementType == EngagementTypeS.E1040
                                && !this.props.isKBAPassed
                                && this.props.taxReturn.documentSettings.documentSignatureSetting.enableDelegateeKbaAuthentication)}
                            hideNextButton={false}
                            hidePreviousButton={false}
                            disableNextButton={!this.state.isConsentAgreed}
                            previousLabel={Common.Wizard.PreviousButtonLabel}
                            nextLabel={Common.Wizard.NextButtonLabel}
                            preventNextButtonDefaultBehaviour={true}
                            onNext={this.onKBAConsentNext}
                            previousButtonIcon={<PreviousButtonIcon />}
                            nextButtonIcon={<NextButtonIcon />}
                        >
                            <KbaConsentForm
                                ref={(ref: any) => { this._kbaConsentRef = ref }}
                                match={this.props.match}
                                nextStep={this.goToStepById}
                                delegateeInfo={this.props.delegateeSigner}
                                consentChecked={this.state.isConsentAgreed}
                                onConsentAgreed={this.onConsentAgreed}
                                saveDelegateeConsentDetails={this.props.saveDelegateeConsentDetails}
                            />
                        </Step>

                    }

                    {
                        this.props.taxReturn.id > 0 &&
                        <Step id={SignProcessSteps.KBA.toString()}
                            hidden={!(this.props.taxReturn.documentSettings.documentSignatureSetting.signatureFormSelectionType == SignatureType.ESign
                                && this.props.taxReturn.engagementType == EngagementTypeS.E1040
                                && !this.props.isKBAPassed
                                && this.props.documentSettings.documentSignatureSetting.enableDelegateeKbaAuthentication
                            )}
                            hideNextButton={true}
                            hidePreviousButton={this.props.delegateeKBAData.transactionStatus.toString() === KBATransactionResultType[KBATransactionResultType.error] ? false : true}
                            disableNavigationClick={true}
                            previousLabel={Common.Wizard.PreviousButtonLabel}
                            nextLabel={Common.Wizard.NextButtonLabel}
                            previousButtonIcon={<PreviousButtonIcon />}
                            nextButtonIcon={<NextButtonIcon />}
                        >
                            <KBAComponent
                                match={this.props.match}
                                loadKBAQuestions={this.props.kbaAuthentication}
                                validateKBAAnswers={this.props.kbaValidation}
                                response={this.props.delegateeKBAData}
                                clearParentState={this.clearState}
                                refreshKBAStatus={this.props.requestDelegateeKBAStatus}
                                onCompletion={this.goToNextStep}
                                updateDocumentSignatureSettingModel={this.props.updateDocumentSignatureSettingModel}
                                onKBAFailure={this.onKBAFailure}
                                refreshTaxDocument={this.props.requestTaxDocument}
                                updateLastVisitedStep={this.props.updateLastVisitedStep}
                                isPreviewMode={this.state.isPreviewMode}
                            />
                        </Step>
                    }

                    {
                        this.props.taxReturn.id > 0 &&
                        <Step id={SignProcessSteps.Esign.toString()}
                            hidden={!(this.props.taxReturn.documentSettings.documentSignatureSetting.signatureFormSelectionType == SignatureType.ESign
                                && !this._delegateeSignManager.isSigningProcessCompleted())}
                            disableNextButton={completedPercentage < 100}
                            disableNavigationClick={true}
                            preventNextButtonDefaultBehaviour={true}
                            onNext={this.onEsignWizardNextBtnClick}
                            previousLabel={Common.Wizard.PreviousButtonLabel}
                            nextLabel={Common.Wizard.NextButtonLabel}
                            enableCustomButton={true}
                            showCustomButton={true}
                            customButtonLabel={"Decline"}
                            onCustomButtonClick={this.handleDecline}
                            hideScrollbar={true}
                            previousButtonIcon={<PreviousButtonIcon />}
                            nextButtonIcon={<NextButtonIcon />}
                        >
                            <Esign
                                ref={(ref: any) => { this._esignRef = ref }}
                                match={this.props.match}
                                history={this.props.history}
                                isPreviewMode={false}
                                onHeaderAndFooterVisibility={this.handleHeaderAndFooterVisibility}
                                requestSignatureControls={this.props.requestDelegateeSignatureControls}
                                signatureControlsData={this.props.delegateeSignatureControlsData}
                                onSigningComplete={this.props.updateSignatureControlsData}
                                disableNextButton={this.disableNextButton}
                                completeSigningStep={this.onEsignComplete}
                                isSpouseSignRequired={false}
                                sign={this.props.delegateeSignComplete}
                                headerInfo={this.props.delegateeHeaderInfo}
                                engagementType={this.props.taxReturn.engagementType}
                                saveDeclinedRemarks={this.props.saveDeclinedRemarks}
                                resetTaxClientLastVisitedSteps={this.props.resetTaxClientLastVisitedSteps}
                                mutual={this.props.taxReturn as TaxDocument.IMarriedJointTaxReturn}
                                signerData={this.props.signerData}
                                logout={this.props.logout}
                            />
                        </Step>
                    }
    {!this._delegateeSignManager.isLinkVisited(SignProcessSteps.ManualSign)?
                    
                    (
                        this.props.taxReturn.id > 0 &&
                        <Step id={SignProcessSteps.ManualSignDownload.toString()}
                            name={"Sign"} tooltip={"Sign"}
                            template={<TemplateIcon />}
                            disableNextButton={true}
                            previousLabel={Common.Wizard.PreviousButtonLabel}
                            nextLabel={Common.Wizard.NextButtonLabel}
                            hidden={!(this.props.taxReturn.documentSettings.documentSignatureSetting.signatureFormSelectionType == SignatureType.ManualSign)
                                || this._delegateeSignManager.isLinkVisited(SignProcessSteps.ManualSign)}
                            previousButtonIcon={<PreviousButtonIcon />}
                            nextButtonIcon={<NextButtonIcon />}
                        >

                            <ManualSignDownloadFiles
                                downloadEfileForms={this.props.delegateeDownloadEfileForms}
                                clientId={param.clientId}
                                goToNextStep={this.goToNextStep}
                                additionalEsignDocuments={this.props.additionalEsignDocuments}
                                downloadAllAdditionEsignDocuments={this.props.downloadAllAdditionEsignDocuments}
                                clientName={this.props.delegateeHeaderInfo.clientName}
                                taxReturn={this.props.taxReturn}
                                isPreviewMode={this.state.isPreviewMode}
                                updateLastVisitedStep={this.props.updateLastVisitedStep}
                            />

                        </Step>):
                    

                    (
                        this.props.taxReturn.id > 0 &&
                        <Step id={SignProcessSteps.ManualSign.toString()}
                            name={"Sign"} tooltip={"Sign"}
                            template={this._delegateeSignManager.isLinkVisited(SignProcessSteps.ManualSign) &&
                                <TemplateIcon />}
                            hidden={!(this.props.taxReturn.documentSettings.documentSignatureSetting.signatureFormSelectionType == SignatureType.ManualSign)}
                            preventNextButtonDefaultBehaviour={true}
                            previousLabel={Common.Wizard.PreviousButtonLabel}
                            nextLabel={Common.Wizard.NextButtonLabel}
                            onNext={this.confirmRedirectToCompletionWizard}
                            disableNavigationClick={true}
                            disableNextButton={this.state.isNextButtonDisable}
                            previousButtonIcon={<PreviousButtonIcon />}
                            nextButtonIcon={<NextButtonIcon />}
                        >
                            <StepLayout className="manual-sign-stepper">

                                <Body title={ManualSignConstants.Title}
                                    lg={8} md={12} sm={12} xs={12}
                                    containerClassName={"pt-5"}
                                >
                                    <ManualSign
                                        match={this.props.match}
                                        downloadEfileForms={this.props.delegateeDownloadEfileForms}
                                        addSignedDocument={this.props.addDelegateeSignedDocument}
                                        deleteSignedDocument={this.props.deleteDelegateeSignedDocument}
                                        requestSignedDocuments={this.props.requestDelegateeSignedDocuments}
                                        signedDocuments={this.props.delegateeManualSignData}
                                        companyInfo={this.props.companyData}
                                        requestCompanyDetails={this.props.requestCompanyDetails}
                                        completeManualSign={this.props.completeDelegateeManualSign}
                                        onCompletion={this.goToNextStep}
                                        ref={(ref: any) => { this._manualSign = ref }}
                                        additionalEsignDocuments={this.props.additionalEsignDocuments}
                                        downloadAllAdditionEsignDocuments={this.props.delegateeDownloadAllAdditionEsignDocuments}
                                        requestAdditionalEsignDocument={this.props.requestAdditionalEsignDocument}
                                        clientName={this.props.delegateeHeaderInfo.clientName}
                                        taxReturn={this.props.taxReturn}
                                        isDelegatee={true}
                                        isPreviewMode={false}
                                        disableNextButton={this.disableNextButton}
                                    />
                                </Body>
                            </StepLayout>

                        </Step>)
                    }

                    {
                        this.props.taxReturn.id > 0 &&

                        <Step
                            id={SignProcessSteps.SignComplete.toString()}
                            name={"Finish"}
                            tooltip={"Finish"}
                            template={<TemplateIcon />}
                            disableNavigationClick={true}
                            previousLabel={Common.Wizard.PreviousButtonLabel}
                            nextLabel={Common.Wizard.NextButtonLabel}
                            previousButtonIcon={<PreviousButtonIcon />}
                            nextButtonIcon={<NextButtonIcon />}
                        >
                            <SignatureCompletedPage
                                companyName={this.props.delegateeHeaderInfo.companyName}
                                match={this.props.match}
                                isPreviewMode={this.state.isPreviewMode}
                            />
                        </Step>
                    }
                </Wizard>
            </div>
        );

    }
}
