import * as React from 'react';
import { DelegateeHeader } from './DelegateeHeader';
import Notification from '../Common/Notification/NotificationContainer'
import { Toaster } from '../../components/Common/Notification/MarsNotifier';
import { RouteComponentProps } from 'react-router';
import { ILocalStore } from '../../core/utilities/LocalStore';
import { container } from '../../startup/inversify.config';
import { TYPES } from '../../startup/types';
import { match } from 'react-router';
import { History } from 'history';
import { IHeaderInfoViewModel } from '../../core/domain/viewModels/IHeaderInfoViewModel';
import * as AccountStore from '../../store/Common/AccountStore';
import { DelegateeSessionTimeout } from '../Delegatee/Account/DelegateeSessionTimeout';
import { OverlayLoader } from '../Common/Loader/OverlayLoader';
import { IDelegateeInfo } from '../../core/domain/models/IDelegateeSignerModel';
import * as DelegateeSignerStore from '../../store/DelegateeSignerStore';
import Footer from './Footer';
import { IProfileData } from './DefaultLayout';
import { getClientBasicInfo, injectPendoScript } from '../Helper/HelperFunction';
import { ICompany } from '../../core/domain/models/company/Company';
import { IClientProcessViewModel } from '../../core/domain/viewModels/IClientProcessViewModel';
import * as HelperStore from '../../store/Common/HelperStore';

declare global {
    interface Window {
        Variables: any;
    }
}

export type DelegateeDefaultLayoutProps =
    {
        delegateeHeaderInfo: IHeaderInfoViewModel;
        delegateeSigner: IDelegateeInfo;
        loading: boolean;
        match: match;
        history: History;
        companyData: ICompany;
        clientProcessData: IClientProcessViewModel;
        getClientHubDomainURL: (id: string, callback: (clientHubDomainURL: string) => void) => void;
		checkIfUserExistsInOneHub: (clientId: string, callback: (isUserExists: boolean) => void) => void;
		getOnehubRedirectURL: (clientId: string, callback: (redirectURL: string) => void) => void;
    }
    & typeof AccountStore.actionCreators
    & typeof DelegateeSignerStore.actionCreators
    & typeof HelperStore.actionCreators
    & RouteComponentProps<{}>;

interface IDelegateeDefaultLayoutState {
    idleSessionTimeoutInSeconds: number;
    profileData: IProfileData | undefined;
	isPendoInjected: boolean;
};

const localStore = container.get<ILocalStore>(TYPES.ILocalStore);



export class DelegateeDefaultLayout extends React.Component<DelegateeDefaultLayoutProps, IDelegateeDefaultLayoutState> {

    constructor(props: DelegateeDefaultLayoutProps) {
        super(props);
        this.state = {
			idleSessionTimeoutInSeconds: 0,
            profileData: undefined,
            isPendoInjected: false
		};
    }

    componentDidMount() {
        let param: any = this.props.match.params;
        this.props.requestDelegateeHeaderInfo(param.clientId, this.DelegateCallback);
        this.props.getSessionTimeOutSeconds(param.clientId, this.setSessionTimout);
        this.props.requestDelegateeDetails(param.clientId);
        getClientBasicInfo(param.clientId, this.updateProfileData);
    }

    componentDidUpdate(prevProps: Readonly<DelegateeDefaultLayoutProps>, prevState: Readonly<IDelegateeDefaultLayoutState>): void {
		if (!this.state.isPendoInjected && this.state.profileData) {
			const { companyId, companyName, clientGuid, clientName, emailAddress } = this.state.profileData;
			injectPendoScript(
				companyId,
				companyName,
				clientGuid,
				clientName,
				emailAddress,
				this.state.isPendoInjected,
				this.setIsPendoInjected
			);
		}
	}

    setIsPendoInjected = (isPendoInjected: boolean) => {
		this.setState({ isPendoInjected });
	}

	updateProfileData = (data: IProfileData) => {
		this.setState({ profileData: data });
	}

    private DelegateCallback = () => {
        this.props.history.push('/invalid');
    }

    setSessionTimout = (sessionTimeoutValue: number) =>{
		this.setState({ idleSessionTimeoutInSeconds: sessionTimeoutValue })		
	}

    public render() {
        return (
            <div>
                <DelegateeHeader
                    delegateeHeaderInfo={this.props.delegateeHeaderInfo}
                    delegateeSigner={this.props.delegateeSigner}
                    updateDelegateeMobileNumber={this.props.updateDelegateeMobileNumber}
                    match={this.props.match}
                    history={this.props.history}
                    companyData={this.props.companyData}
                    clientProcessData={this.props.clientProcessData}
                    getClientHubDomainURL={this.props.getClientHubDomainURL}
					checkIfUserExistsInOneHub={this.props.checkIfUserExistsInOneHub}
					getOnehubRedirectURL={this.props.getOnehubRedirectURL}                    
                    logout={this.props.logout}/>

                <div id="content-wrapper" className="content-wrapper" >
                    <Notification />
                    {this.props.children}
                </div>

                <Footer />

                {this.state.idleSessionTimeoutInSeconds > 0 && <DelegateeSessionTimeout
                    match={this.props.match}
                    idleSessionTimeoutInSeconds = {this.state.idleSessionTimeoutInSeconds}
                    refreshToken={this.props.refreshToken}
                /> }

                <OverlayLoader />
                <Toaster />

            </div>)
    }
}