import * as React from "react";
import { match } from "react-router";
import { DownloadAllPaperFileReturns } from "../PaperFile/PaperFileReturnsDownloadAll";
import { PrintAllPaperFileReturns } from "../PaperFile/PaperFileReturnsPrintAll";
import { PaperFileReturnTable } from "../PaperFile/PaperFileReturnTable";
import {
  isIndividual,
  isMutual,
  isPartnership,
  ITaxReturn,
  ToMutual,
  ToIndividual,
  ToPartnership,
} from "../../core/domain/models/ITaxReturn";
import { ISignerModel } from "../../core/domain/models/ISignerModel";
import { PaperFileReturnConstants } from "../Common/Constants";
import { IPaperFileReturnDetails } from "../../core/domain/models/IPaperFileReturn";
import { IHeaderInfoViewModel } from "../../core/domain/viewModels/IHeaderInfoViewModel";
import { PaperFileConsentModal } from "../PaperFile/PaperFileConsentModal";
import { ISummaryViewModel } from "../../core/domain/viewModels/ISummaryViewModel";
import { Role } from "../../core/common/Enums";
import { ClientTypes } from "../../store/SSN/SSNStore_GR";
import { logger } from "../../routes";
import MessageNote from "../Common/Notification/MessageNote";
import { NotificationType } from "../../core/common/Enums";
import { StepLayout } from "../Common/StepLayout/StepLayout";
import { SvgIconPrint } from "../Common/Icons/SvgIcons";
import { MarsNotifier } from "../Common/Notification/MarsNotifier";
import { Body } from "../Common/StepLayout/Body";

export const NO_INDEX = -1;

interface IPaperFileListProps {
  requestPaperFileReturnDetails: (
    clientGuid: string,
    pageNo: number,
    pageSize: number,
    sortBy: string,
    sortOrder: string,
    searchText: string
  ) => void;
  downloadAllPaperFileReturns: (
    clientId: string,
    callback?: () => void
  ) => void;
  downloadIndividualPaperFileReturn: (
    clientId: string,
    paperFile: IPaperFileReturnDetails,
    callback?: () => void
  ) => void;
  paperFiledRetursData: IPaperFileReturnDetails[];
  getPaperFileDocumentStream: (
    clientId: string,
    documentGuid: string,
    paperFile: IPaperFileReturnDetails,
    callback?: (url: any) => void
  ) => void;
  getMergedPaperFileDocumentStream: (
    clientId: string,
    callback?: (url: any) => void
  ) => void;
  addPaperFilePrintToClientTracking: (
    clientId: string,
    paperFile: IPaperFileReturnDetails,
    callback?: () => void
  ) => void;
  addPaperFilePrintAllToClientTracking: (
    clientId: string,
    callback?: () => void
  ) => void;
  match: match;
  clientName: string;
  taxReturn: ITaxReturn;
  signerData: ISignerModel[];
  onPaperFileStepSkip?: () => void;
  headerInfo: IHeaderInfoViewModel;
  isPreviewMode: boolean;
  summaryData: ISummaryViewModel;
  updatePaperFileConsent: (clientId: string, callback?: () => void) => void;
  onConsentSkip?: () => void;
  skipButtonText: string;
  isDraftReturn?: boolean;
}

interface IPaperFileListState {
  selectedRows: number[];
  sortName: string;
  sortOrder: string;
  searchText: string;
  page: number;
  isConsented: boolean;
  isSkipped: boolean;
  isController: boolean;
  isPrintActionAddedToClientTracking: boolean;
}
const pageSize: number = 10;

export class PaperFileList extends React.Component<
  IPaperFileListProps,
  IPaperFileListState
> {
  constructor(props: any) {
    super(props);
    this.state = {
      selectedRows: [],
      sortName: "Name",
      sortOrder: "asc",
      searchText: " ",
      page: 1,
      isConsented: false,
      isSkipped: false,
      isController: false,
      isPrintActionAddedToClientTracking: false,
    };
  }

  componentDidMount() {
    const param: any = this.props.match.params;
    logger.trackPageView("Paper File List Page", {
      ClientId: param.clientId,
      DocumentId: this.props.taxReturn.id,
      PreviewMode: this.props.isPreviewMode,
    });
    this.setState({
      isController:
        this.props.headerInfo.loggedInUserInfo.role.toString() ==
        Role[Role.Controller].toString(),
    });
    this.loadDetails();
  }

  loadDetails = () => {
    let param: any = this.props.match.params;
    this.props.requestPaperFileReturnDetails(
      param.clientId,
      this.state.page,
      pageSize,
      this.state.sortName,
      this.state.sortOrder,
      this.state.searchText
    );
  };

  private onPageChange = (page: number, sizePerPage: number) => {
    this.setState(
      {
        page: page,
        selectedRows: [],
      },
      () => this.loadDetails()
    );
  };

  private onSortChange: any = (sortName: any, sortOrder: any, column: any) => {
    this.setState(
      {
        sortName: sortName,
        sortOrder: sortOrder == "asc" ? "asc" : "desc",
      },
      () => this.loadDetails()
    );
  };

  private onSearchChange = (searchString: string) => {
    let temp = searchString !== "" ? searchString : " ";
    this.setState(
      {
        searchText: temp,
        page: 1,
      },
      () => this.loadDetails()
    );
  };

  private onRowSelect = (row: any, isSelected: any, e: any) => {
    let newList = [...this.state.selectedRows];
    if (
      e.target.tagName !== "BUTTON" &&
      e.target.tagName !== "I" &&
      e.target.tagName !== "SPAN" &&
      e.target.tagName !== "A"
    ) {
      if (isSelected) {
        newList.push(row.rowIndex);
      } else {
        var index = newList.indexOf(row.rowIndex);
        if (index > -1) {
          newList.splice(index, 1);
        }
      }
      this.setState({ selectedRows: newList });
    }
  };

  private onSelectAll = (isSelected: any, rows: any, e: any) => {
    let selectedRows: number[] = [];
    if (isSelected) {
      let count = rows && rows.length ? rows.length : 0;
      Array.from(Array(count).keys());
      selectedRows = Array.from(Array(count).keys());
    }
    this.setState({ selectedRows: selectedRows });
  };

  private onSkipandContinue = () => {
    this.props.onPaperFileStepSkip && this.props.onPaperFileStepSkip();
  };

  UNSAFE_componentWillReceiveProps(nextProps: IPaperFileListProps) {
    if (nextProps) {
      this.setState({
        isConsented: this.props.summaryData.paperFileConsentInfo.isConsented,
      });
    }
  }

  onConsentSkip = () => {
    this.props.onConsentSkip && this.props.onConsentSkip();
  };

  onPaperFileConsentAgreed = (clientId: string) => {
    this.setState(
      {
        isConsented: true,
      },
      () => {
        this.props.summaryData.paperFileConsentInfo.isConsented = true;
        if (this.state.isController) {
          this.props.summaryData.paperFileConsentInfo.clientType =
            ClientTypes.Controller;
        } else {
          this.props.summaryData.paperFileConsentInfo.name =
            this.props.headerInfo.clientName;
        }
        this.props.updatePaperFileConsent(clientId);
        this.setState({ isConsented: true });
      }
    );
  };

  private getName = () => {
    const taxDocument = this.props.taxReturn;
    if (isIndividual(taxDocument)) {
      const individualTaxDocument = ToIndividual(taxDocument);
      return individualTaxDocument.taxpayer.name;
    } else if (isMutual(taxDocument)) {
      const joinTaxDocument = ToMutual(taxDocument);
      return (
        joinTaxDocument.taxpayer.name + " & " + joinTaxDocument.spouse.name
      );
    } else if (isPartnership(taxDocument)) {
      const partnerShipTaxDocument = ToPartnership(taxDocument);
      return partnerShipTaxDocument.partnership.name;
    }
  };

  private GetAcknowledgementInstruction = () => {
    var isMutualReturn: boolean = isMutual(this.props.taxReturn);
    //Before Consent
    if (
      isMutualReturn &&
      !this.props.summaryData.paperFileConsentInfo.isConsented
    ) {
      let isDeceased =
        this.props.signerData.filter((x) => x.isDeceased === true).length > 0;
      if (isDeceased) {
        return this.state.isController
          ? PaperFileReturnConstants.AckInstructionforController
          : PaperFileReturnConstants.AckInstruction;
      }
      return this.state.isController
        ? PaperFileReturnConstants.AckInstructionforController
        : PaperFileReturnConstants.AckInstructionforMutual;
    } //After Consent
    else if (
      isMutualReturn &&
      this.props.summaryData.paperFileConsentInfo.isConsented
    ) {
      if (
        this.props.summaryData.paperFileConsentInfo.name ==
        this.props.headerInfo.clientName
      ) {
        let isDeceased =
          this.props.signerData.filter((x) => x.isDeceased === true).length > 0;
        return isDeceased || this.props.signerData.length === 1
          ? PaperFileReturnConstants.AckInstruction
          : PaperFileReturnConstants.AckInstructionforMutual;
      } else if (
        ClientTypes[
          this.props.summaryData.paperFileConsentInfo.clientType
        ].toString() == this.props.headerInfo.loggedInUserInfo.role.toString()
      ) {
        return PaperFileReturnConstants.AckInstructionforController;
      } else {
        return PaperFileReturnConstants.AckInstructionAfterConsent.replace(
          "<ClientName>",
          this.props.summaryData.paperFileConsentInfo.name
        );
      }
    }

    return PaperFileReturnConstants.AckInstruction;
  };

  printAllDocument = () => {
    if (this.props.isPreviewMode) {
      MarsNotifier.Warning(PaperFileReturnConstants.PreviewModeMessage, null);
      return;
    }

    let param: any = this.props.match.params;
    if (this.state.isPrintActionAddedToClientTracking) {
      this.printMergedDocument();
    } else {
      this.setState(
        {
          isPrintActionAddedToClientTracking: true,
        },
        () => {
          this.props.addPaperFilePrintAllToClientTracking(
            param.clientId,
            () => {
              this.printMergedDocument();
            }
          );
        }
      );
    }
  };

  wait = (ms: number) => {
    var start = new Date().getTime();
    var end = start;
    while (end < start + ms) {
      end = new Date().getTime();
    }
  };

  printDocuments = () => {
    let param: any = this.props.match.params;
    for (var i = 0; i < this.props.paperFiledRetursData.length; i++) {
      if (i > 0) {
        this.wait(1500);
      }

      this.props.getPaperFileDocumentStream(
        param.clientId,
        this.props.taxReturn.documentGuid,
        this.props.paperFiledRetursData[i],
        (response) => {
          var file = new Blob([response.data], { type: "application/pdf" });
          var fileURL = URL.createObjectURL(file);
          window.open(
            fileURL,
            "_blank",
            "left=0,top=0,width=900,height=900,toolbar=0,scrollbars=0,status=0"
          );
        }
      );
    }
  };

  printMergedDocument = () => {
    let param: any = this.props.match.params;
    this.props.getMergedPaperFileDocumentStream(param.clientId, (response) => {
      var file = new Blob([response.data], { type: "application/pdf" });
      var fileURL = URL.createObjectURL(file);
      window.open(
        fileURL,
        "_blank",
        "left=0,top=0,width=900,height=900,toolbar=0,scrollbars=0,status=0"
      );
    });
  };

  downloadAllDocument = () => {
    if (this.props.isPreviewMode) {
      MarsNotifier.Warning(PaperFileReturnConstants.PreviewModeMessage, null);
      return;
    }
    let param: any = this.props.match.params;
    this.props.downloadAllPaperFileReturns(param.clientId);
  };

  public render() {
    return (
      <div style={{ minWidth: "100%" }}>
        <StepLayout
          className="no-padding"
          title={PaperFileReturnConstants.Header}
          showDownloadButton={true}
          customDownloadText="Download All"
          handleDownload={this.downloadAllDocument}
          customDownloadTooltip="Download all your Paper File Returns"
          showCustomButton={true}
          handleCustomButtonClick={this.printAllDocument}
          customButtonIcon={SvgIconPrint}
          customButtonTooltip="Print all your Paper File Returns"
          customButtonText="Print"
          customIconHeight={16}
          customIconWidth={16}
          disableCustomButton={this.props.isDraftReturn}
          disableDownloadButton={this.props.isDraftReturn}
        >
          <Body containerClassName="no-padding">
            <div style={{ paddingLeft: "15%", paddingRight: "15%" }}>
              <div
                className="step-layout-paper-file-title"
                style={{ marginTop: "20px", marginBottom: "20px" }}
                data-test-auto="3DE433E2-EFAF-44A3-A116-E8EB5403B87F"
              >
                {this.getName()}
              </div>
              <MessageNote
                note={this.GetAcknowledgementInstruction()}
                noteType={NotificationType.Info}
              />

              <PaperFileReturnTable
                onPageChange={this.onPageChange}
                onSortChange={this.onSortChange}
                onSearchChange={this.onSearchChange}
                onRowSelect={this.onRowSelect}
                onSelectAll={this.onSelectAll}
                pageNo={this.state.page}
                pageSize={pageSize}
                paperFiledReturnsData={this.props.paperFiledRetursData}
                selectedRows={this.state.selectedRows}
                taxReturn={this.props.taxReturn}
                match={this.props.match}
                downloadIndividualPaperFileRetursAycn={
                  this.props.downloadIndividualPaperFileReturn
                }
                isPreviewMode={this.props.isPreviewMode}
                getPaperFileDocumentStream={
                  this.props.getPaperFileDocumentStream
                }
                addPaperFilePrintToClientTracking={
                  this.props.addPaperFilePrintToClientTracking
                }
                isDraftReturn={this.props.isDraftReturn}
              />
              <PaperFileConsentModal
                match={this.props.match}
                showModal={!this.state.isConsented && !this.props.isPreviewMode}
                onConsentAgreed={this.onPaperFileConsentAgreed}
                onConsentSkip={this.onConsentSkip}
                skipButtonText={this.props.skipButtonText}
              />
            </div>
          </Body>
        </StepLayout>
      </div>
    );
  }
}
