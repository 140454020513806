
import { connect } from 'react-redux';
import OTPRequest from "./OTP_GR";
import * as headerInfoStore from "../../store/Common/AccountStore";
import * as GroupOTPReducer from "../../store/OTP/OTPStore_GR";
import * as HeaderStore from "../../store/HeaderStore";



export default connect(
    (state: any) => ({
        headerInfoState: state.HeaderPageReducer,
        OTPPageState:state.GroupOTPReducer


    }),
    {
        ...HeaderStore.actionCreators,
        ...GroupOTPReducer.actionCreators
        

    }
)(OTPRequest as any);





