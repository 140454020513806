import { IVoucher } from "../../core/domain/models/IGroup";
import Moment from 'moment/moment';

function distinct(value: any, index: number, self: any) {
	return self.indexOf(value) === index;
}

export interface IGroupedVoucher{
groupName:string,
vouchers:IVoucher[]
}

export function getDueDateBasedVouchers(vouchers: IVoucher[]):IGroupedVoucher[] {
	let groupedVouchers: IGroupedVoucher[] = [];
	const dueDateList = vouchers.map(x => x.dueDate).filter(distinct);
	if (dueDateList) {
        dueDateList.forEach((value, index) => {
            //Vouchers with same month and year are grouped together
            const monthYearFormat = Moment.utc(value).format("MMM YYYY");
            const voucher: IVoucher[] = vouchers.filter(x => Moment.utc(x.dueDate).format("MMM YYYY") == monthYearFormat);
            groupedVouchers.find(x => x.groupName == monthYearFormat) === undefined &&
			groupedVouchers.push(
				{
                    groupName: monthYearFormat,
					vouchers: voucher
				}
			);
		});
	}
	return groupedVouchers;
}