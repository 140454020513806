import React, { useEffect } from 'react';
import Select, { MenuPlacement,components } from 'react-select';
import './CustomDropdown.style.scss';
import { DropdownIcon } from '../../Common/Icons/SvgIcons';

interface IDropdownProps {
	selectedOption: ICustomDropdownOption | null;
	options: ICustomDropdownOption[];
	setSelectedOption: (option: ICustomDropdownOption | null) => void;
	clearable?: boolean;
	disabled?: boolean;
	searchable?: boolean;
	customPlaceHolder?: string;
	customPlaceholderStyles?: any;
	id: string;
	className?: string;
	classNamePrefix?: string;
	height?: number;
	title?: string;
	borderColor?: string;
	handleOnBlur?: () => void;
	scrollableMenu?: boolean;
	maxMenuHeight?: number;
	menuPlacement?: MenuPlacement;
	backgroundColor?: string;
	menuListHeight?: number;
	containerClassName?: string;
	tabIndex?: string;
}

export interface ICustomDropdownOption {
	value: number;
	label: string;
}

export const DropdownIndicator = (props: any) => {
	return (
		<components.DropdownIndicator {...props}>
			<DropdownIcon />
		</components.DropdownIndicator>
	);
};

const CustomDropdown: React.FC<IDropdownProps> = (props) => {
	const {
		selectedOption,
		options,
		setSelectedOption,
		clearable,
		disabled,
		customPlaceHolder,
		customPlaceholderStyles,
		id,
		className,
		classNamePrefix,
		height,
		searchable,
		title,
		borderColor,
		handleOnBlur,
		scrollableMenu,
		maxMenuHeight,
		menuPlacement,
		backgroundColor,
		menuListHeight,
		containerClassName,
		tabIndex,
	} = props;

	const handleChange = (option: ICustomDropdownOption | null) => {
		setSelectedOption(option);
	};

	const customStyles = {
		control: (provided: any, state: any) => ({
			...provided,
			background: disabled ? '#C4C6C8' : backgroundColor ?? '#fff',
			borderColor: borderColor ?? '#898D91',
			minHeight: `${height ?? 32}px`,
			height: `${height ?? 32}px`,
			borderRadius: '2px',
			boxShadow: state.isFocused ? '0 0 0 0.2rem rgba(0,123,255,.25)' : null,
			'&:hover': {
				borderColor: borderColor ?? '#898D91',
			},
		}),
		valueContainer: (provided: any, state: any) => ({
			...provided,
			height: `${height ?? 30}px`,
			padding: '0 2px 0 8px',
		}),
		singleValue: (provided: any, state: any) => ({
			...provided,
			marginBottom: '1px',
		}),
		input: (provided: any, state: any) => ({
			...provided,
			margin: '0px',
			padding: '0px',
		}),
		placeholder: (provided: any, state: any) => ({
			...provided,
		    ...customPlaceholderStyles,
		}),
		indicatorSeparator: (state: any) => ({
			display: 'none',
		}),
		indicatorsContainer: (provided: any, state: any) => ({
			...provided,
			height: `${height ? height - 2 : 30}px`,
			alignSelf: 'center',
		}),
		dropdownIndicator: (provided: any, state: any) => ({
			...provided,
		}),
		option: (provided: any) => ({
			...provided,
			cursor: 'pointer',
		}),
		menuList: (provided: any) => ({
			...provided,
			maxHeight: scrollableMenu ? maxMenuHeight || '115px' : '',
			width: '100%',
			overflowX: 'hidden',
			height: menuListHeight ?? '250px',
			overflowY: 'auto',
		}),
		menu: (provided: any) => ({
			...provided,
			fontSize: '14px',
			width: '100%',
			marginTop: '-1px',
			marginBottom: '-1px',
			border: '1px solid #898D91',
			outline: 'none',
			boxShadow: 'none',
			borderRadius: `${menuPlacement === 'top' ? '2px 2px 0 0' : '0 0 2px 2px'}`
		}),
	};

	useEffect(() => {
		const dropdown = document.querySelector('.custom-react-select-dropdown');

		if (dropdown && title) {
			const children = dropdown.querySelectorAll('*');

			children.forEach((child) => {
				if (typeof child.className === 'string') {
					const classNames = child.className.split(' ');
					classNames.forEach((className: string) => {
						if (className.includes('-control')) {
							child.setAttribute('title', title);
						}
					});
				}
			});
		}
	}, []);

	const customComponents = {
		IndicatorSeparator: null,
		DropdownIndicator: DropdownIndicator,
	};

	return (
		<div className={`${disabled ? 'cursor-nodrop' : ''} ${containerClassName ?? ''}`}>
			<Select
				id={id}
				aria-label='Select options'
				value={selectedOption}
				onChange={handleChange}
				options={options}
				isClearable={clearable ?? true}
				isDisabled={disabled ?? false}
				isSearchable={searchable ?? true}
				className={className + `${title ? ' custom-react-select-dropdown' : ''}`}
				classNamePrefix={classNamePrefix ?? null}
				placeholder={customPlaceHolder?.length ? customPlaceHolder : 'Select...'}
				styles={customStyles}
				components={customComponents}
				onBlur={handleOnBlur}
				maxMenuHeight={maxMenuHeight}
				menuPlacement={menuPlacement}
				tabIndex={tabIndex ?? '0'}
			/>
		</div>
	);
};

export default CustomDropdown;
