import { IVoucher } from "../models/IGroup";


export interface IPayScreenViewModel
{
    documentId: number;
    taxYear: number;
    vouchers: IVoucher[];
    paymentInstructions: string
}

export const initialPayScreenViewModel: IPayScreenViewModel = {
    documentId: 0,
    taxYear: 0,
    vouchers: [],
    paymentInstructions:""
}