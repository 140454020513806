import * as React from "react";
import { Modal } from "react-bootstrap";
import { SiginingConstants } from "../Common/Constants";
import "@sssuite-component-ui/react-phone-input-2/lib/style.css";
import PhoneInput, {
  CountryData,
} from "@sssuite-component-ui/react-phone-input-2";
import { CloseIcon } from "../Common/Icons/SvgIcons";
import { ButtonComponent } from "cp-common-ui-components";
import { handleKeyDown } from "../Helper/HelperFunction";

export interface EditSpouseMobileNumberProps {
  showAlert: boolean;
  showState: boolean;
  onSaveButtonClick: (mobileNumber: string, countryCode: string) => void;
  onHide: () => void;
  spouseMobileNumber: string;
  spouseCountryCode: string;
  isEdit: boolean;
}

interface EditSpouseMobileNumberState {
  mobileNumber: string;
  countryCode?: any;
}

export class EditSpouseMobileNumber extends React.Component<
  EditSpouseMobileNumberProps,
  EditSpouseMobileNumberState
> {
  constructor(props: any) {
    super(props);
    this.state = {
      mobileNumber: props.spouseMobileNumber,
      countryCode: props.spouseCountryCode,
    };
  }

  private handleMobileChanges = (
    fullValue: string,
    countryObject: CountryData
  ) => {
    this.setState({
      mobileNumber: fullValue.slice(countryObject.dialCode.length),
      countryCode: `+${countryObject.dialCode}`,
    });
  };

  static getDerivedStateFromProps(
    props: EditSpouseMobileNumberProps,
    state: EditSpouseMobileNumberState
  ) {
    if (!props.spouseMobileNumber) {
      return null;
    } else {
      if (
        props.spouseMobileNumber &&
        props.spouseMobileNumber !== state.mobileNumber
      ) {
        return {
          spouseMobileNumber: state.mobileNumber,
        };
      }
    }
    return null;
  }

  public render() {
    return (
      <Modal
        className="edit-spouse-mobile"
        show={this.props.showState}
        onHide={this.props.onHide}
      >
        <Modal.Header
          className="darkBackground"
          data-test-auto="6E0248EE-A2AB-4F54-90F3-6AE4953B1BAC"
        >
          <Modal.Title>
            {this.props.isEdit
              ? SiginingConstants.EditSecondSignerMobileNumber
              : SiginingConstants.AddSecondSignerMobileNumber}
          </Modal.Title>
          <div
            data-test-auto="827fcba4-d935-40b7-aeb4-eeaf18e6cd1c"
            className="closeIcon"
            onClick={this.props.onHide}
            tabIndex={0}
            onKeyDown={(e: any) => handleKeyDown(e, this.props.onHide)}
          >
            <CloseIcon />
          </div>
        </Modal.Header>
        <Modal.Body>
          <div data-test-auto="D6E17265-FCBC-4D8A-91AB-56FD94F48770">
            <label className="labelMobileNumber">
              Enter Spouse Mobile Number
            </label>
            <div style={{ width: "225px" }} className="mobileSection">
              <PhoneInput
                containerClass={`inputCCMobile`}
                country={"us"}
                autoFormat={true}
                placeholder="() -"
                value={`${this.state.countryCode}${this.state.mobileNumber}`}
                onChange={this.handleMobileChanges}
              />
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <ButtonComponent
            data-test-auto="C20DF8CC-F4AA-4E8D-B710-75DB7EEC6146"
            onClick={this.props.onHide}
            variant={"outline-tertiary"}
            buttonClassName={"button-margin-right"}
            size={"medium"}
          >
            Cancel
          </ButtonComponent>
          <ButtonComponent
            data-test-auto="3BAB1674-CFFD-466E-B34E-18BF07B654C0"
            onClick={() => {
              this.props.onSaveButtonClick(
                this.state.mobileNumber,
                this.state.countryCode
              );
            }}
            variant={"primary"}
            size={"medium"}
          >
            Save
          </ButtonComponent>
        </Modal.Footer>
      </Modal>
    );
  }
}

export default EditSpouseMobileNumber;
