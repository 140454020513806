import * as React from "react";
import { Consent } from "./Consent";
import { SpouseEmail } from "./SpouseEmail";
import { DateType } from "../../core/domain/viewModels/ICommon";
import { History } from "history";
import { match } from "react-router";
import {
  IIndividualTaxReturn,
  IMarriedJointTaxReturn,
  ITaxReturn,
} from "../../core/domain/models/ITaxReturn";
import { ISignerModel } from "../../core/domain/models/ISignerModel";
import { ClientType } from "../../core/common/Enums";
import TaxpayerHelper from "../Helper/TaxpayerHelper";
import {
  isValidEmailAddress,
  isValidDOB,
  validateRequiredMobileNumber,
} from "../Common/Validations";
import { MarsNotifier } from "../Common/Notification/MarsNotifier";
import { SiginingConstants } from "../Common/Constants";
import { StepLayout } from "../Common/StepLayout/StepLayout";
import { Body } from "../Common/StepLayout/Body";
import { SignBehalfSpouse } from "./SignBehalfSpouse";
import { logger } from "../../routes";

export type EsignConsentEmailProps = {
  history: History;
  match: match;
  updateSpouseMailChange?(
    clientGuid: string,
    spouseGuid: string,
    id: string,
    mail: string,
    type: ClientType,
    step: boolean,
    callback: (step: boolean) => void
  ): void;
  updateDOBChange?(
    clientGuid: string,
    id: string,
    dob: string,
    step: boolean,
    callback: (step: boolean) => void
  ): void;
  individual: IIndividualTaxReturn;
  mutual: IMarriedJointTaxReturn;
  consent: boolean;
  onConsentAgreed(value: boolean): void;
  prevStep: () => void;
  nextStep: () => void;
  refreshTaxDocument: (clientGuid: string) => void;
  disableNextButton: (value: boolean) => void;
  clearParentState: () => void;
  isPreviewMode: boolean;
  signerData: ISignerModel[];
  signBehalfSpouse: boolean;
  onSignOnBehalfOfSpouse(value: boolean): void;
  updateSpouseMobileChange?(
    clientGuid: string,
    id: string,
    newMobileNumber: string,
    newCountryCode: string,
    ssn: string,
    type: ClientType,
    step: boolean,
    callback: (step: boolean) => void
  ): void;
  setSigningStateForSpouse: () => void;
  isSpouseBehalfCheckDisabled: boolean;
};

export interface IEsignConsentEmailState {
  emailAddress: string;
  dateOfBirth: Date;
  chainedOperationCount: number;
  noOfSteps: number;
  mobileNumber: string;
  isDOBUpdated: boolean;
  countryCode: string;
}

export class EsignConsentEmail extends React.Component<
  EsignConsentEmailProps,
  IEsignConsentEmailState
> {
  constructor(props: EsignConsentEmailProps) {
    super(props);
    const param: any = this.props.match.params;
    this.state = {
      emailAddress: TaxpayerHelper.getSecondSignerEmail(
        props.mutual,
        props.signerData,
        param.clientId,
        param.controllerId
      ),
      dateOfBirth: EsignConsentEmail.getCurrentSignerDOB(
        props,
        param.controllerId
      ),
      chainedOperationCount: 1,
      noOfSteps: 0,
      mobileNumber: TaxpayerHelper.getSecondSignerMobileNumber(
        props.mutual,
        props.signerData,
        param.clientId,
        param.controllerId
      ),
      isDOBUpdated: false,
      countryCode: TaxpayerHelper.getSecondSignerMobileCountryCode(
        props.mutual,
        props.signerData,
        param.clientId,
        param.controllerId
      ),
    };
    this.props.clearParentState();
    this.props.disableNextButton(!this.props.consent);
    this.onPrevious = this.onPrevious.bind(this);
    this.onNext = this.onNext.bind(this);
  }

  componentDidMount() {
    let param: any = this.props.match.params;
    logger.trackTrace("EsignConsentEmail Page View", {
      ClientId: param.clientId,
      PreviewMode: this.props.isPreviewMode,
    });
    if (
      param.controllerId &&
      TaxpayerHelper.anyOneSignerDeceased(
        this.props.mutual,
        this.props.signerData
      )
    ) {
      this.props.setSigningStateForSpouse &&
        this.props.setSigningStateForSpouse();
    }
  }

  static getDerivedStateFromProps(
    props: EsignConsentEmailProps,
    state: IEsignConsentEmailState
  ) {
    const param: any = props.match.params;
    if (
      (!props.individual ||
        !props.individual.taxpayer ||
        !props.individual.taxpayer.dob) &&
      (!props.mutual || !props.mutual.spouse || !props.mutual.spouse.email)
    ) {
      return null;
    } else {
      if (
        (props.individual.taxpayer &&
          props.individual.taxpayer.dob &&
          props.individual.taxpayer.dob !== state.dateOfBirth) ||
        (props.mutual.spouse &&
          props.mutual.spouse.email &&
          props.mutual.spouse.email !== state.emailAddress)
      ) {
        return {
          dateOfBirth: state.dateOfBirth,
          emailAddress: state.emailAddress,
        };
      }
    }
    return null;
  }

  private handleSpouseMailChange = (mail: string) => {
    this.setState({ emailAddress: mail });
  };

  private handleMobileChanges = (
    fullValue: string,
    countryObject: CountryData
  ) => {
    const mobileNumber = fullValue.slice(countryObject.dialCode.length);
    const countryCode = `+${countryObject.dialCode}`;
    this.setState({
      mobileNumber: mobileNumber,
      countryCode: countryCode,
    });

    if (countryCode) {
      this.props.consent && this.props.disableNextButton(false);
    } else if (!countryCode && this.props.consent == true) {
      this.props.disableNextButton(false);
    } else {
      this.props.disableNextButton(true);
    }

    if (mobileNumber) {
      this.props.consent && this.props.disableNextButton(false);
    } else if (!mobileNumber && this.props.consent == true) {
      this.props.disableNextButton(false);
    } else {
      this.props.disableNextButton(true);
    }
  };

  private handleConsent = (isChecked: boolean) => {
    this.props.onConsentAgreed(isChecked);
  };

  private handleSignBehalfSpouse = (isChecked: boolean) => {
    this.props.onSignOnBehalfOfSpouse(isChecked);
  };

  private handleDOBChange = (dob: string, type: DateType) => {
    const birthDate: Date = this.state.dateOfBirth;
    switch (type) {
      case DateType.Date:
        birthDate.setDate(parseInt(dob));
        break;
      case DateType.Month:
        birthDate.setMonth(parseInt(dob) - 1);
        break;
      case DateType.Year:
        birthDate.setFullYear(parseInt(dob));
        break;
    }
    this.setState({ dateOfBirth: birthDate, isDOBUpdated: true });
  };

  updateEsignState = (step: boolean) => {
    const param: any = this.props.match.params;
    this.props.refreshTaxDocument(param.clientId);
    if (this.state.chainedOperationCount === this.state.noOfSteps) {
      if (step) {
        this.props.nextStep();
      } else {
        this.props.prevStep();
      }
    } else {
      this.setState({
        chainedOperationCount: this.state.chainedOperationCount + 1,
      });
    }
    this.props.disableNextButton(false);
  };

  updateConsentAndEmail(step: boolean) {
    if (!this.props.isPreviewMode) {
      this.props.disableNextButton(true);
    }
    if (!isValidDOB(this.state.dateOfBirth)) {
      MarsNotifier.Warning(SiginingConstants.WarningMessage.InvalidDOB, null);
      return;
    }

    const param: any = this.props.match.params;
    let steps = 1;
    if (!this.props.isPreviewMode) {
      this.setState({ chainedOperationCount: 1 });
      if (
        this.props.mutual &&
        TaxpayerHelper.isMarried(this.props.mutual) &&
        param.clientId ==
          TaxpayerHelper.getFirstSignerId(
            this.props.mutual,
            this.props.signerData
          )
      ) {
        if (
          !TaxpayerHelper.isNextSignerDeceased(
            this.props.mutual,
            this.props.signerData
          ) &&
          (!this.state.emailAddress ||
            !isValidEmailAddress(this.state.emailAddress))
        ) {
          MarsNotifier.Warning(
            SiginingConstants.WarningMessage.InvalidEmailAddress,
            null
          );
          return;
        }

        if (
          this.state.countryCode.trim() !== "" ||
          this.state.mobileNumber.trim() !== ""
        ) {
          if (
            !validateRequiredMobileNumber(
              this.state.mobileNumber.trim(),
              this.state.countryCode.trim()
            )
          ) {
            return false;
          }
        }
        if (
          TaxpayerHelper.getSecondSignerEmail(
            this.props.mutual,
            this.props.signerData,
            param.clientId
          ) !== this.state.emailAddress
        ) {
          steps = steps + 1;
          this.props.updateSpouseMailChange &&
            this.props.updateSpouseMailChange(
              param.clientId,
              TaxpayerHelper.getSecondSignerGuid(
                this.props.mutual,
                this.props.signerData,
                param.clientId
              ).toString(),
              TaxpayerHelper.getSecondSignerId(
                this.props.mutual,
                this.props.signerData,
                param.clientId
              ).toString(),
              this.state.emailAddress,
              TaxpayerHelper.getSecondSignerType(
                this.props.mutual,
                this.props.signerData,
                param.clientId
              ),
              step,
              this.updateEsignState
            );
          logger.trackTrace("Second signer email updated", {
            ClientId: param.clientId,
            UpdatedEmailAddress: this.state.emailAddress,
            PreviewMode: this.props.isPreviewMode,
          });
        }

        if (
          TaxpayerHelper.getSecondSignerMobileNumber(
            this.props.mutual,
            this.props.signerData,
            param.clientId
          ) !== this.state.mobileNumber ||
          TaxpayerHelper.getSecondSignerMobileCountryCode(
            this.props.mutual,
            this.props.signerData,
            param.clientId
          ) !== this.state.countryCode
        ) {
          steps = steps + 1;
          this.props.updateSpouseMobileChange &&
            this.props.updateSpouseMobileChange(
              param.clientId,
              TaxpayerHelper.getSecondSignerId(
                this.props.mutual,
                this.props.signerData,
                param.clientId
              ).toString(),
              this.state.mobileNumber,
              this.state.countryCode,
              TaxpayerHelper.getSecondSignerSSN(
                this.props.mutual,
                this.props.signerData,
                param.clientId
              ),
              TaxpayerHelper.getSecondSignerType(
                this.props.mutual,
                this.props.signerData,
                param.clientId
              ),
              step,
              this.updateEsignState
            );

          logger.trackTrace("Second signer mobile updated", {
            ClientId: param.clientId,
            CountryCode: this.state.countryCode,
            MobileNumber: this.state.mobileNumber,
            PreviewMode: this.props.isPreviewMode,
          });
        }
      }

      if (this.state.isDOBUpdated) {
        this.props.updateDOBChange &&
          this.props.updateDOBChange(
            param.clientId,
            EsignConsentEmail.getCurrentUserId(this.props).toString(),
            this.state.dateOfBirth.toLocaleDateString(),
            step,
            this.updateEsignState
          );
        logger.trackTrace("DOB updated", {
          ClientId: param.clientId,
          UpdatedDOB: this.state.dateOfBirth.toLocaleDateString(),
          PreviewMode: this.props.isPreviewMode,
        });
      } else {
        if (step) {
          this.props.nextStep();
        } else {
          this.props.prevStep();
        }
      }
    }
    this.setState({ noOfSteps: steps }, () => {
      this.props.isPreviewMode && this.updateEsignState(step);
    });
  }

  onPrevious(index: number) {
    this.updateConsentAndEmail(false);
  }

  onNext(index: number) {
    this.updateConsentAndEmail(true);
  }

  static getCurrentSignerDOB(
    props: EsignConsentEmailProps,
    controllerId?: string
  ) {
    const param: any = props.match.params;
    if (
      !TaxpayerHelper.isMarried(EsignConsentEmail.getTaxReturn(props)) || //if individual return
      props.mutual === null ||
      props.mutual === undefined || //if joint tax return but taxreturn object is null
      ((props.mutual.taxpayer === null ||
        props.mutual.taxpayer === undefined) && //if joint tax return but both taxpayer and spouse object are null
        (props.mutual.spouse === null || props.mutual.spouse === undefined))
    ) {
      return props.individual.taxpayer && props.individual.taxpayer.dob
        ? EsignConsentEmail.getDob(props.individual.taxpayer.dob)
        : new Date();
    } else {
      if (controllerId && props.mutual.spouse) {
        return props.mutual.spouse && props.mutual.spouse.dob
          ? EsignConsentEmail.getDob(props.mutual.spouse.dob)
          : new Date();
      } else {
        if (props.mutual.taxpayer.clientGuid === param.clientId) {
          return props.mutual.taxpayer && props.mutual.taxpayer.dob
            ? EsignConsentEmail.getDob(props.mutual.taxpayer.dob)
            : new Date();
        } else if (props.mutual.spouse.clientGuid === param.clientId) {
          return props.mutual.spouse && props.mutual.spouse.dob
            ? EsignConsentEmail.getDob(props.mutual.spouse.dob)
            : new Date();
        } else {
          return new Date();
        }
      }
    }
  }

  static getDob = (date: Date) => {
    var dateString = date.toString().substring(0, 10).split("-");
    return new Date(
      parseInt(dateString[0]),
      parseInt(dateString[1]) - 1,
      parseInt(dateString[2]),
      0,
      0,
      0,
      0
    );
  };

  static getCurrentUserId = (props: EsignConsentEmailProps) => {
    const param: any = props.match.params;
    if (TaxpayerHelper.isMarried(EsignConsentEmail.getTaxReturn(props))) {
      if (props.mutual.taxpayer.clientGuid === param.clientId) {
        return props.mutual.taxpayer.id;
      } else {
        return props.mutual.spouse.id;
      }
    } else {
      return props.individual.taxpayer.id;
    }
  };

  static getTaxReturn = (props: EsignConsentEmailProps) => {
    return props.mutual ? props.mutual : props.individual;
  };

  public render() {
    const param: any = this.props.match.params;

    return (
      <StepLayout className="esign-consent-email">
        <Body
          xl={7}
          lg={12}
          md={12}
          sm={12}
          xs={12}
          containerClassName={"esign-consent-screen no-padding"}
          bodyClassName={"esign-consent-container"}
        >
          <div className="esign-consent-screen-header">
            Sign Tax Documents Electronically
          </div>
          <Consent
            consentChecked={this.props.consent}
            handleConsent={this.handleConsent}
            handleDOBChange={this.handleDOBChange}
            dateOfBirth={this.state.dateOfBirth}
          />
          {(TaxpayerHelper.isMarried(
            EsignConsentEmail.getTaxReturn(this.props)
          ) &&
            TaxpayerHelper.isFirstSignerLoggedIn(
              this.props.mutual,
              this.props.signerData,
              param.clientId
            ) &&
            !TaxpayerHelper.isNextSignerDeceased(
              this.props.mutual,
              this.props.signerData
            )) ||
          (param.controllerId &&
            TaxpayerHelper.anyOneSignerDeceased(
              this.props.mutual,
              this.props.signerData
            )) ? (
            <>
              <div className="black-border-line" />
              <SpouseEmail
                handleSpouseMailChange={this.handleSpouseMailChange}
                spouseEmail={this.state.emailAddress}
                spouseName={TaxpayerHelper.getSecondSignerName(
                  this.props.mutual,
                  this.props.signerData,
                  param.clientId,
                  param.controllerId
                )}
                spouseMobileNumber={this.state.mobileNumber}
                spouseCountryCode={this.state.countryCode}
                handleMobileChanges={this.handleMobileChanges}
              />

              {param.controllerId ? (
                <div>
                  <SignBehalfSpouse
                    signChecked={this.props.signBehalfSpouse}
                    handleSignOn={this.handleSignBehalfSpouse}
                    isSpouseBehalfCheckDisabled={
                      this.props.isSpouseBehalfCheckDisabled
                    }
                  />
                </div>
              ) : null}
            </>
          ) : null}
        </Body>
      </StepLayout>
    );
  }
}
