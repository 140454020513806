import { ApplicationState } from "../../../store/index";
import { connect } from 'react-redux';
import { actionCreators as CompanyStore } from '../../../store/Common/Company/CompanyStore';
import { ManualSignCompleted } from './ManualSignCompleted'
import { actionCreators as SignProcessStore } from '../../../store/SignProcess/SignProcessStore';
import { IClientProcessViewModel } from '../../../core/domain/viewModels/IClientProcessViewModel'
import { actionCreators as DownloadableDocumentsStore } from '../../../store/DownloadableDocumentsStore';
import { actionCreators as DelegateeSignProcessStore } from '../../../store/DelegateeSignProcessStore';

function mapStateToProps(state: ApplicationState) {
    return {
        delegateeManualSignData: state.delegateeManualSignData,
        companyData: state.companyData.data,
        delegateeHeaderInfo: state.delegateeHeaderInfo,
		taxReturn: state.taxReturn.taxDocument,
		additionalEsignDocuments: state.downloadableDocuments.additionalEsignDocuments,
	};
}

const mapDispatchToProps = (dispatch: any) => {
    return {        
        addDelegateeSignedDocument: (clientGuid: string, signedDocument: any, successCallback?: () => void, failureCallback?: () => void) =>
            dispatch(DelegateeSignProcessStore.addDelegateeSignedDocument(clientGuid, signedDocument, successCallback, failureCallback)),

        completeDelegateeManualSign: (clientGuid: string, successCallback?: () => void, failureCallback?: () => void) =>
            dispatch(DelegateeSignProcessStore.completeDelegateeManualSign(clientGuid, successCallback, failureCallback)),

        deleteDelegateeSignedDocument: (clientGuid: string, id: number, successCallback?: () => void, failureCallback?: () => void) =>
            dispatch(DelegateeSignProcessStore.deleteDelegateeSignedDocument(clientGuid, id, successCallback, failureCallback)),

        requestDelegateeSignedDocuments: (clientGuid: string, forceRefresh?: boolean) =>
            dispatch(DelegateeSignProcessStore.requestDelegateeSignedDocuments(clientGuid, forceRefresh)),

        requestCompanyDetails: (clientGuid: string, forceRefresh?: boolean, isDelegatee?: boolean) =>
            dispatch(CompanyStore.requestCompanyDetails(clientGuid, forceRefresh, isDelegatee)),

        delegateeDownloadEfileForms: (clientGuid: string, successCallback?: () => void) =>
            dispatch(DelegateeSignProcessStore.delegateeDownloadEfileForms(clientGuid, successCallback)),

        requestDelegateeCurrentStepAndUserRole: (clientGuid: string, successCallback?: (clientProcessState: IClientProcessViewModel) => void) =>
            dispatch(SignProcessStore.requestDelegateeCurrentStepAndUserRole(clientGuid, successCallback)),

        requestAdditionalEsignDocument: (clientId: string) =>
            dispatch(DownloadableDocumentsStore.requestAdditionalEsignDocument(clientId)),

        delegateeDownloadAllAdditionEsignDocuments: (clientId: string, fileName: string) =>
            dispatch(DelegateeSignProcessStore.delegateeDownloadAllAdditionEsignDocuments(clientId, fileName)),

		dispatch
    }
}


const connector = connect(mapStateToProps, mapDispatchToProps);

export default connector(ManualSignCompleted);



