import * as React from 'react';

export class PdfViewer extends React.Component<{}, {}> {

    public render() {
		return (<div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 review-left-pdf-viewer" style={{ height: 'inherit' }}>
            {this.props.children}
        </div>);
    }
}
