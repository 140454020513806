import * as React from "react";
import { Shimmer } from "../../../components/Common/Shimmer/Shimmer";
import { WelcomeSummaryConstants, CompletedSummaryConstant } from "../../../components/Common/Constants";
import { WelcomeMessage } from "../../../components/CompletedSummary/Parts/WelcomeMessage";
import { SignProcessSteps } from "../../Home/TaxpayerSignFlowManager";
import { SvgIconSignDocumentsSummary } from "../../Common/Icons/SvgIcons";
import { ButtonComponent } from "cp-common-ui-components";
import { TileComponent } from "cp-common-ui-components";
import { handleKeyDown } from "../../../components/Helper/HelperFunction";

export interface WelcomeProps {
    taxYear: number;
    clientName: string;
    goToStep: (stepNumber: SignProcessSteps) => void;
    goToStepById: (id: any) => void;
}

export class Welcome extends React.Component<WelcomeProps, {}> {
    constructor(props: WelcomeProps) {
        super(props);
    }

    public render() {
        return (
            <div id="welcomeMessage">
                {this.props.clientName && this.props.taxYear > 0 ? (
                    <div className="welcome-container">
                        <WelcomeMessage
                            customClassName="welcome-message-ready"
                            message={CompletedSummaryConstant.WelcomeMessage.replace(
                                "<TAXPAYERNAME>",
                                this.props.clientName
                            )}
                        />
                        <WelcomeMessage
                            customClassName="welcome-message-ready"
                            message={WelcomeSummaryConstants.WelcomeMessage.replace(
                                "<TAXYEAR>",
                                this.props.taxYear.toString()
                            )}
                        />
                    </div>
                ) : (
                    <Shimmer height={200} />
                )}

                {this.props.clientName ? (
                    <div className="cards-container">
                        <div className="welcome-page-step-img-btn cards-content">
                            <div
                                className="cards"
                                tabIndex={0}
                                onKeyDown={(e: any) => handleKeyDown(e, () => this.props.goToStep(SignProcessSteps.Invoice))}
                            >
                                <TileComponent
                                    onClick={() => this.props.goToStep(SignProcessSteps.Invoice)}
                                    tileContent={<SvgIconSignDocumentsSummary className="icon---summary-sign-documents" />}
                                    tileDetail={"Sign Documents"}
                                />
                            </div>
                        </div>
                        <div className="summary-start-step-container delegatee flex-align-center">
                            <ButtonComponent
                                data-test-auto="87742DFF-5468-44D2-B959-809042533E9E"
                                onClick={() => this.props.goToStep(SignProcessSteps.PreparerMessage)}
                                variant={"primary"}
                                size={"medium"}
                                buttonClassName={"summary-begin-btn"}
                            >
                                Get Started
                            </ButtonComponent>
                        </div>
                    </div>
                ) : (
                    <Shimmer height={500} />
                )}
            </div>
        );
    }
}
