import * as React from "react";
import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table";
import { match } from "react-router";
import { IPaperFileReturnDetails } from "../../core/domain/models/IPaperFileReturn";
import { ITaxReturn } from "../../core/domain/models/ITaxReturn";
import { MarsNotifier } from "../Common/Notification/MarsNotifier";
import { PaperFileReturnConstants } from "../Common/Constants";
import {
  SvgDownloadIcon,
  SvgAttachmentIcon,
  SvgIconPrint,
  SvgDividerIcon,
} from "../Common/Icons/SvgIcons";

import { NO_INDEX } from "./PaperFileList";
import { handleKeyDown } from "../Helper/HelperFunction";
interface PaperFileReturnTableProps {
  paperFiledReturnsData: IPaperFileReturnDetails[];
  onSortChange: any;
  onPageChange: any;
  pageNo: number;
  pageSize: number;
  onSearchChange: (searchString: string) => void;
  onRowSelect: (row: any, isSelected: any, e: any) => void;
  onSelectAll: (isSelected: any, rows: any, e: any) => void;
  selectedRows: number[];
  taxReturn: ITaxReturn;
  match: match;
  isPreviewMode: boolean;
  downloadIndividualPaperFileRetursAycn: (
    clientId: string,
    paperFile: IPaperFileReturnDetails,
    callback?: () => void
  ) => void;
  getPaperFileDocumentStream: (
    clientId: string,
    documentGuid: string,
    paperFile: IPaperFileReturnDetails,
    callback?: (url: any) => void
  ) => void;
  addPaperFilePrintToClientTracking: (
    clientId: string,
    paperFile: IPaperFileReturnDetails,
    callback?: () => void
  ) => void;
  isDraftReturn: boolean;
}

export class PaperFileReturnTable extends React.Component<
  PaperFileReturnTableProps,
  {}
> {
  constructor(props: any) {
    super(props);
  }

  private tableRef = React.createRef<BootstrapTable>();

  downloadIndividualDocument = (id: number) => {
    if (this.props.isPreviewMode) {
      MarsNotifier.Warning(PaperFileReturnConstants.PreviewModeMessage, null);
      return;
    }
    var index = this.props.paperFiledReturnsData.findIndex((x) => x.id === id);
    if (index != NO_INDEX) {
      let param: any = this.props.match.params;
      this.props.downloadIndividualPaperFileRetursAycn(
        param.clientId,
        this.props.paperFiledReturnsData[index]
      );
    }
  };

  printIndividualDocument = (id: number) => {
    if (this.props.isPreviewMode) {
      MarsNotifier.Warning(PaperFileReturnConstants.PreviewModeMessage, null);
      return;
    }

    var index = this.props.paperFiledReturnsData.findIndex((x) => x.id === id);
    if (index != NO_INDEX) {
      let param: any = this.props.match.params;
      this.props.addPaperFilePrintToClientTracking(
        param.clientId,
        this.props.paperFiledReturnsData[index],
        () => {
          this.props.getPaperFileDocumentStream(
            param.clientId,
            this.props.taxReturn.documentGuid,
            this.props.paperFiledReturnsData[index],
            (response) => {
              var file = new Blob([response.data], { type: "application/pdf" });
              var fileURL = URL.createObjectURL(file);
              window.open(
                fileURL,
                "_blank",
                "left=0,top=0,width=900,height=900,toolbar=0,scrollbars=0,status=0"
              );
            }
          );
        }
      );
    }
  };

  private customStyles = () => {
    return {
        pointerEvents: this.props.isDraftReturn ? "none" : "auto",
        cursor: this.props.isDraftReturn ? "not-allowed" : "pointer",
        opacity: this.props.isDraftReturn ? 0.4 : 1,
    };
  }

  private paperFileReturnStatusAction = (cell: any, row: any) => {
    return (
      <div>
        <span
          className="paper-file-tbl-action-icon"
          style={{ paddingRight: "15px", ...this.customStyles() } as React.CSSProperties}
          data-test-auto="C73C9574-8EED-47B1-A492-AEA62F71B6E4"
          title={"Print Paper File Return"}
          onClick={() => {
            !this.props.isDraftReturn && this.printIndividualDocument(row.paperFileId);
          }}
          tabIndex={0}
          onKeyDown={(e: any) => !this.props.isDraftReturn && handleKeyDown(e, () => this.printIndividualDocument(row.paperFileId))}
          aria-disabled={this.props.isDraftReturn}
        >
          <SvgIconPrint width={16} height={16} />
          Print
        </span>
        <span className="margin-right-8">
          <SvgDividerIcon />
        </span>
        <span
          className="paper-file-tbl-action-icon"
          style={this.customStyles() as React.CSSProperties}
          data-test-auto="A853852E-ED23-4372-A5F9-D0A4CDB0812B"
          title={"Download Paper File Return"}
          onClick={() => {
            !this.props.isDraftReturn && this.downloadIndividualDocument(row.paperFileId);
          }}
          tabIndex={0}
          onKeyDown={(e: any) => !this.props.isDraftReturn && handleKeyDown(e, () => this.downloadIndividualDocument(row.paperFileId))}
          aria-disabled={this.props.isDraftReturn}
        >
          <SvgDownloadIcon width={16} height={16} />
          Download
        </span>
      </div>
    );
  };

  private defaultType = (cell: any, row: any) => {
    return (
      <span
        title={cell}
        className="paperfile-ellipses"
        data-test-auto="40AFA486-A699-4E58-9963-BE6A7778A603"
      >
        <SvgAttachmentIcon />
        {cell}
      </span>
    );
  };

  private defaultMailingAddress = (cell: any, row: any) => {
    return (
      <span
        title={cell}
        className="ellipsis"
        data-test-auto="40AFA486-A699-4E58-9963-BE6A7778A603"
      >
        {cell}
      </span>
    );
  };

  private renderShowsTotal = (start: number, to: number, total: number) => {
    return (
      <p>
        Showing {start} to {to} of {total} entries
      </p>
    );
  };

  private columns = [
    {
      header: "",
      key: "rowIndex",
      isKey: true,
      dataFormat: this.defaultType,
      columnClassName: "",
      dataSort: false,
      toolTip: false,
      isHidden: true,
      width: "auto",
    },
    {
      header: PaperFileReturnConstants.TableFileNameHeader,
      key: "name",
      isKey: false,
      dataFormat: this.defaultType,
      columnClassName: "",
      dataSort: false,
      toolTip: false,
      isHidden: false,
      width: "auto",
    },
    {
      header: PaperFileReturnConstants.TableMailingAddress,
      key: "mailingAdress",
      isKey: false,
      dataFormat: this.defaultMailingAddress,
      columnClassName: "",
      dataSort: false,
      toolTip: false,
      isHidden: false,
      width: "auto",
    },
    {
      header: "Actions",
      key: "button",
      isKey: false,
      dataFormat: this.paperFileReturnStatusAction,
      columnClassName: "",
      dataSort: false,
      toolTip: false,
      isHidden: false,
      width: "210px",
    },
  ];

  public render() {
    const options = {
      onSortChange: this.props.onSortChange,
      onSearchChange: this.props.onSearchChange,
      onPageChange: this.props.onPageChange,
      sizePerPage: this.props.pageSize,
      page: this.props.pageNo,
      paginationShowsTotal: this.renderShowsTotal,
      clearSearch: false,
      hideSizePerPage: true,
    };

    const data =
      this.props.paperFiledReturnsData &&
      this.props.paperFiledReturnsData.map((model, index) => {
        return {
          name: model.authority + "_" + model.formName, //TODO : change to returname
          rowIndex: index,
          button: "Actions",
          paperFileId: model.id,
          mailingAdress: model.mailingAddress,
        };
      });

    let selected: number[] = [];
    if (this.props.selectedRows.length > 0) {
      for (var i in this.props.selectedRows) {
        let rowIndex = this.props.selectedRows[i];
        selected.push(rowIndex);
      }
    }

    let totalCount =
      this.props && this.props.paperFiledReturnsData.length > 0
        ? this.props.paperFiledReturnsData[0].count
        : 0;

    return (
      <div
        className="paperFileReturnsTbl"
        data-test-auto="10D28309-65F6-44DF-AF2D-4A2B7EF65C5C"
      >
        <BootstrapTable
          ref={this.tableRef}
          data={data}
          remote={true}
          pagination={totalCount > this.props.pageSize}
          hover={false}
          withoutTabIndex={true}
          search={false}
          options={options}
          bordered={false}
          fetchInfo={{
            dataTotalSize:
              this.props && this.props.paperFiledReturnsData.length > 0
                ? this.props.paperFiledReturnsData[0].count
                : 0,
          }}
        >
          {this.columns.map((value, index) => {
            const header = value.header === "" && value.isHidden ? `Column ${index + 1}` : value.header;
            const ref = React.createRef<TableHeaderColumn>();
            return (
              <TableHeaderColumn
                key={index}
                ref={ref}
                isKey={value.isKey}
                dataField={value.key}
                hidden={value.isHidden}
                width={value.width}
                dataFormat={value.dataFormat}
                columnClassName={value.columnClassName}
                columnTitle={value.toolTip}
                dataSort={value.dataSort}
              >
                {header}
              </TableHeaderColumn>
            );
          })}
        </BootstrapTable>
      </div>
    );
  }
}
