import { injectable } from "inversify";
import * as _ from "lodash";

export enum ValueType {
    Number = 0,
    Text = 1,
    Date = 2,
    Decimal = 3,
    None = 4
}

export interface ITextUtilities {
    getAvatarText(text: string): string;
    getRandomColor(): string;
    getFormattedText(value: any, type: ValueType): any;
}

@injectable()
export class TextUtilities implements ITextUtilities {

    getAvatarText(text: string): string {
        return text && text.split(' ').map(function (str) { return str ? str[0].toUpperCase() : ""; }).join('');
    }

    getRandomColor(): string {
        return "#" + Math.floor(_.random(1, true) * 16777215).toString(16);
    }

    getFormattedText(value: any, type: ValueType): any {
        
        switch (type) {

            case ValueType.Date:

                return value.toLocaleDateString();

            case ValueType.Decimal:

                return parseFloat(value).toFixed(2);

            case ValueType.Number:

                return value;

            case ValueType.Text:

                return value;

            default:

                return value;
        }
    }
}