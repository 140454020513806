
import * as React from 'react';
import Skeleton from "react-loading-skeleton";
import { Toaster } from "../Common/Notification/MarsNotifier";
import Notification from "../Common/Notification/NotificationContainer";



export class RouteSelector extends React.Component<any, any> {
    constructor(props: any) {
        super(props);
        this.state = {
            otpVal: "",
            continueState: false
        };


        this.selectPath = this.selectPath.bind(this);
    }





    componentDidMount() {

        this.props.pageValidityCCR(this.props.match.params.clientId, this.selectPath, this.handleError);
    }


    handleError = () => {
        this.props.history.push("/Error");
    }







    selectPath(path: string) {
        let clientId: string = this.props.match.params.clientId;
        if (path === "TextOTP") {

            this.props.history.push(`/CCRecipient/MobileOTP/Index/${clientId}`);
        }
        else {
            this.props.history.push(`/CCRecipient/OTP/Index/${clientId}`);
        }

    }

    render() {



        return (<>
            <Notification />
            <Toaster/>
            <Skeleton circle={false} height={"100vh"} width={"100vw"} /> :

        </>
        );
    }

}
export default RouteSelector;