import { Document, IDocument, IPage, Page } from "../../../core/domain/models/esign/Document";
import { ISignerControlDataModal, SignerControlDataModal } from "../../domain/viewModels/ISignerControlDataModal";
import {
	CompanyControl, CustomTextControl, DateControl,
	IControl, ICustomData, InitialControl, ISignatureData,
	ITextData, SignatureControl, TitleControl, TextData,
	ControlRole, IControlRole, ISignerInfo, SignerInfo, PrintNameControl, NameAndTitleControl,
	CheckBoxControl, RadioButtonControl, IChoosableControlResult, ChoosableControlData
} from "../../domain/models/esign/IControls";

import {
	DocumentSignatureDataViewModel, ISignatureControlModel, ISignaturePageData,
	SignatureControlModel, SignaturePageData
} from '../../domain/viewModels/IDocumentSignatureDataViewModel';
import { Guid } from '../../utilities/Guid';
import { SignatureControlRole, SignatureControlTypes } from "../../domain/models/IGroup";
import ControlBase from 'awesome-pdf-viewer/dist/Controls/ControlBase';

export interface IDocumentAdapter {
	convertToClientModel(documentModel: DocumentSignatureDataViewModel[]): IDocument[];
	convertToClientModelWithDisable(documentModel: DocumentSignatureDataViewModel[]): IDocument[];
	convertToServerModel(document: IDocument[]): ISignerControlDataModal;
}

const signatureTopAdjustment: number = 5;
const landScapePageOrientationAngle = 180;

export class DocumentAdapter implements IDocumentAdapter {

	public static create(): DocumentAdapter {
		return new DocumentAdapter();
	}

	public convertToClientModel(documentModel: DocumentSignatureDataViewModel[]): IDocument[] {

		let _self = this;
		let documents: IDocument[] = [];


		documentModel.forEach(function (document: DocumentSignatureDataViewModel) {

			let tmpDocument: IDocument = Document.create(document.id,
				document.name,
				document.fileLink,
				document.disabled,
				document.documentType, [], false);

			document.documentControls.forEach(function (pageItem: ISignaturePageData) {

				let tmpControls: IControl[] = [];

				pageItem.signingControls.forEach(function (controlItem: ISignatureControlModel) {

					tmpControls.push(_self.convertToClientControlModel(controlItem));

				});

				tmpDocument.pages.push(Page.create(pageItem.pageNo, pageItem.formName, pageItem.bookmark, tmpControls, false));

			});

			documents.push(tmpDocument);

		});

		return documents;

	}

	public convertToClientModelWithDisable(documentModel: DocumentSignatureDataViewModel[]): IDocument[] {

		let _self = this;
		let documents: IDocument[] = [];


		documentModel.forEach(function (document: DocumentSignatureDataViewModel) {

			let tmpDocument: IDocument = Document.create(document.id,
				document.name,
				document.fileLink,
				document.disabled,
				document.documentType, [], false);

			document.documentControls.forEach(function (pageItem: ISignaturePageData) {

				let tmpControls: IControl[] = [];

				pageItem.signingControls.forEach(function (controlItem: ISignatureControlModel) {

					tmpControls.push(_self.convertToClientControlModelWithDisable(controlItem));

				});

				tmpDocument.pages.push(Page.create(pageItem.pageNo, pageItem.formName, pageItem.bookmark, tmpControls, false));

			});

			documents.push(tmpDocument);

		});

		return documents;

	}

	public convertToServerModel(documents: IDocument[]): ISignerControlDataModal {

		let _self = this;
		let signerControlDataModal: ISignerControlDataModal = SignerControlDataModal.create("", "", [], "");

		let documentsModel: DocumentSignatureDataViewModel[] = [];

		documents.forEach(function (document: IDocument) {

			let tmpDocument: DocumentSignatureDataViewModel = DocumentSignatureDataViewModel.create(document.id, document.name, document.url, document.disabled, document.documentType, []);

			document.pages.forEach(function (page: IPage) {

				let tmpControls: ISignatureControlModel[] = [];

				page.controls.forEach(function (control: IControl) {
					if (control instanceof CheckBoxControl || control instanceof RadioButtonControl) {
						control.items.forEach(function (item: ChoosableControlData) {
							tmpControls.push(_self.convertToServerControlModel(control, signerControlDataModal, item.id));
						});
					}
					else {
						tmpControls.push(_self.convertToServerControlModel(control, signerControlDataModal));
					}
				});

				tmpDocument.documentControls.push(SignaturePageData.create(page.page, page.formName, page.bookmark, tmpControls));

			});

			documentsModel.push(tmpDocument);

		});

		signerControlDataModal.documentData = documentsModel;

		return signerControlDataModal;
	}

	public convertToServerModelMultiSign(documents: IDocument[], taxpayerGuid: string, spouseGuid: string,): ISignerControlDataModal[] {

		let _self = this;

		let taxpayerControlDataModal: ISignerControlDataModal = SignerControlDataModal.create("", "", [], "");
		let spouseControlDataModal: ISignerControlDataModal = SignerControlDataModal.create("", "", [], "");

		taxpayerControlDataModal.clientGuid = taxpayerGuid;
		spouseControlDataModal.clientGuid = spouseGuid;

		let taxpayerDocumentsModel: DocumentSignatureDataViewModel[] = [];
		let spouseDocumentsModel: DocumentSignatureDataViewModel[] = [];

		documents.forEach(function (document: IDocument) {

			let tmpTaxPayerDocument: DocumentSignatureDataViewModel = DocumentSignatureDataViewModel.create(document.id, document.name, document.url, document.disabled, document.documentType, []);
			let tmpSpouseDocument: DocumentSignatureDataViewModel = DocumentSignatureDataViewModel.create(document.id, document.name, document.url, document.disabled, document.documentType, []);

			document.pages.forEach(function (page: IPage) {

				let tmpTaxpayerControls: ISignatureControlModel[] = [];
				let tmpSpouseControls: ISignatureControlModel[] = [];

				page.controls.forEach(function (control: IControl) {
					if (SignatureControlRole.Taxpayer === control.customData.find(x => x instanceof ControlRole)?.role) {
						if (control instanceof CheckBoxControl || control instanceof RadioButtonControl) {
							control.items.forEach(function (item: ChoosableControlData) {
								tmpTaxpayerControls.push(_self.convertToServerControlModel(control, taxpayerControlDataModal, item.id));
							});
						}
						else {
							tmpTaxpayerControls.push(_self.convertToServerControlModel(control, taxpayerControlDataModal));
						}
					}

					if (SignatureControlRole.Spouse === control.customData.find(x => x instanceof ControlRole)?.role) {
						if (control instanceof CheckBoxControl || control instanceof RadioButtonControl) {
							control.items.forEach(function (item: ChoosableControlData) {
								tmpTaxpayerControls.push(_self.convertToServerControlModel(control, taxpayerControlDataModal, item.id));
							});
						}
						else {
							tmpSpouseControls.push(_self.convertToServerControlModel(control, spouseControlDataModal));
						}
					}
				});

				if (tmpTaxpayerControls && tmpTaxpayerControls.length > 0)
					tmpTaxPayerDocument.documentControls.push(SignaturePageData.create(page.page, page.formName, page.bookmark, tmpTaxpayerControls));

				if (tmpSpouseControls && tmpSpouseControls.length > 0)
					tmpSpouseDocument.documentControls.push(SignaturePageData.create(page.page, page.formName, page.bookmark, tmpSpouseControls));

			});

			taxpayerDocumentsModel.push(tmpTaxPayerDocument);
			spouseDocumentsModel.push(tmpSpouseDocument);

		});

		taxpayerControlDataModal.documentData = taxpayerDocumentsModel;
		spouseControlDataModal.documentData = spouseDocumentsModel;

		return [taxpayerControlDataModal, spouseControlDataModal];
	}


	private convertToServerControlModel(control: IControl, signerControlDataModal: ISignerControlDataModal, dataIdentifier: string = ""): ISignatureControlModel {

		let signatureControlModel: ISignatureControlModel;

		const signerInfo: ICustomData | undefined = control.customData.find(x => (x instanceof SignerInfo) == true);
		const controlRole: ICustomData | undefined = control.customData.find(x => (x instanceof ControlRole) == true);

		const signer: string = (signerInfo as ISignerInfo).email;
		const role: SignatureControlRole = (controlRole as IControlRole).role;

		if (control.isLandScapePage) {
			control.top -= landScapePageOrientationAngle;
			control.left -= 2;
		}

		if (control instanceof SignatureControl) {

			signatureControlModel = SignatureControlModel.create(signer,
				1,
				control.top + signatureTopAdjustment, // to fix small change in signature position bug - #42324
				control.left,
				role,
				control.tooltip,
				control.required,
				{ value: "", type: SignatureControlTypes.Signature }
			);

			if (control.data != undefined && control.data != null) {
				var signatureData: ISignatureData = control.data as ISignatureData;
				signerControlDataModal.signature = signatureData.signature ? encodeURIComponent(signatureData.signature) : signatureData.signature;
			}
		}
		else if (control instanceof InitialControl) {

			signatureControlModel = SignatureControlModel.create(signer,
				2,
				control.top + signatureTopAdjustment,
				control.left,
				role,
				control.tooltip,
				control.required,
				{ value: (control.data && (control.data as ISignatureData)?.signature.trim().length > 0) ? true : false, type: SignatureControlTypes.Initial });

			if (signerControlDataModal.signatureInitial == null ||
				signerControlDataModal.signatureInitial == undefined ||
				(signerControlDataModal.signatureInitial != null && signerControlDataModal.signatureInitial != undefined && signerControlDataModal.signatureInitial.trim() == "")) {
				if (control.data != undefined && control.data != null) {
					var signatureData: ISignatureData = control.data as ISignatureData;
					signerControlDataModal.signatureInitial = signatureData.signature ? encodeURIComponent(signatureData.signature) : signatureData.signature;
				}
			}

		}
		else if (control instanceof DateControl) {

			signatureControlModel = SignatureControlModel.create(signer,
				3,
				control.top,
				control.left,
				role,
				control.tooltip,
				control.required,
				{ value: control.data && (control.data as ITextData).text, type: SignatureControlTypes.Date });

		}
		else if (control instanceof NameAndTitleControl) {

			signatureControlModel = SignatureControlModel.create(signer,
				4,
				control.top,
				control.left,
				role,
				control.tooltip,
				control.required,
				{ value: control.data && (control.data as ITextData).text, type: SignatureControlTypes.NameAndTitle });
		}
		else if (control instanceof CompanyControl) {

			signatureControlModel = SignatureControlModel.create(signer,
				11,
				control.top,
				control.left,
				role,
				control.tooltip,
				control.required,
				{ value: control.data && (control.data as ITextData).text, type: SignatureControlTypes.Company });

		}
		else if (control instanceof CustomTextControl) {

			signatureControlModel = SignatureControlModel.create(signer,
				10,
				control.top,
				control.left,
				role,
				control.tooltip,
				control.required,
				{ value: control.data && (control.data as ITextData).text, type: SignatureControlTypes.Text });
		}
		else if (control instanceof PrintNameControl) {

			signatureControlModel = SignatureControlModel.create(signer,
				12,
				control.top,
				control.left,
				role,
				control.tooltip,
				control.required,
				{ value: control.data && (control.data as ITextData).text, type: SignatureControlTypes.Text });
		}
		else if (control instanceof TitleControl) {

			signatureControlModel = SignatureControlModel.create(signer,
				13,
				control.top,
				control.left,
				role,
				control.tooltip,
				control.required,
				{ value: control.data && (control.data as ITextData).text, type: SignatureControlTypes.Text });
		}
		else if (control instanceof CheckBoxControl) {

			let checked = false;

			var checkBoxResult = control.data && (control.data as any[]);

			let index = checkBoxResult && checkBoxResult.findIndex(x => x.id === dataIdentifier);
			if (!index || index === -1) {
				index = control.items.findIndex(x => x.id === dataIdentifier);
			}

			checked = checkBoxResult ? checkBoxResult[index].checked : false;

			signatureControlModel = SignatureControlModel.create(signer,
				SignatureControlTypes.CheckBox,
				(control.top) - ControlBase.DEPRECATED_getControlLeftProportionPDFJS(control.items[index].top, 1),
				control.left + ControlBase.DEPRECATED_getControlLeftProportionPDFJS(control.items[index].left, 1),
				role,
				control.tooltip,
				control.required,
				{ checked: checked, type: SignatureControlTypes.CheckBox });
		}
		else if (control instanceof RadioButtonControl) {

			let checked = false;

			var radionButtonResult = control.data && (control.data as IChoosableControlResult);

			let index = control.items.findIndex(x => x.id === dataIdentifier);

			if (dataIdentifier === radionButtonResult?.id) {
				checked = radionButtonResult?.checked;
			}

			signatureControlModel = SignatureControlModel.create(signer,
				SignatureControlTypes.RadioButton,
				(control.top) - ControlBase.DEPRECATED_getControlLeftProportionPDFJS(control.items[index].top, 1),
				control.left + ControlBase.DEPRECATED_getControlLeftProportionPDFJS(control.items[index].left, 1),
				role,
				control.tooltip,
				control.required,
				{ checked: checked, type: SignatureControlTypes.RadioButton });
		}
		else {
			throw new Error("Method not implemented.");
		}

		return signatureControlModel;

	}

	private convertToClientControlModel(control: ISignatureControlModel | any): IControl {

		let model: IControl;
		let customData: ICustomData[] = [];

		customData.push(SignerInfo.create(control.signer));
		customData.push(ControlRole.create(control.signatureControlRole));

		if (control.required === undefined || control.required === null) {
			control.required = true;
		}

		switch (control.type) {

			case 1: // signature

				model = SignatureControl.create(Guid.newGuid().toString(), control.top, control.left, control.tooltip, true, customData, undefined);

				break;

			case 2: // Initial                

				model = InitialControl.create(Guid.newGuid().toString(), control.top, control.left, control.tooltip, control.required, customData, undefined);

				break;

			case 3: // Date

				model = DateControl.create(Guid.newGuid().toString(), control.top, control.left, control.tooltip, control.required, customData, TextData.create(this.getCurrentDate()));

				break

			case 4: // Name and Title

				model = NameAndTitleControl.create(Guid.newGuid().toString(), control.top, control.left, control.tooltip, control.required, false, customData, undefined);

				break;


			case 11: // company

				model = CompanyControl.create(Guid.newGuid().toString(), control.top, control.left, control.tooltip, control.required, false, customData, undefined);

				break;

			case 10: // Text

				model = CustomTextControl.create(Guid.newGuid().toString(), control.top, control.left, control.tooltip, control.required, false, customData, undefined);

				break;

			case 12: // Print Name

				model = PrintNameControl.create(Guid.newGuid().toString(), control.top, control.left, control.tooltip, control.required, false, customData, undefined);

				break;

			case 13: // Title

				model = TitleControl.create(Guid.newGuid().toString(), control.top, control.left, control.tooltip, control.required, false, customData, undefined);

				break;

			case 14: // Check Box

				model = CheckBoxControl.create(Guid.newGuid().toString(), control.top, control.left, control.width, control.height,
					control.tooltip, control.required, control.items, customData, undefined);

				break;

			case 15: // Radio Button

				model = RadioButtonControl.create(Guid.newGuid().toString(), control.top, control.left, control.width, control.height,
					control.tooltip, control.required, control.items, customData, undefined);

				break;

			default:

				throw new Error("Method not implemented.");

		}

		return model;

	}

	private convertToClientControlModelWithDisable(control: ISignatureControlModel | any): IControl {

		let model: IControl;
		let customData: ICustomData[] = [];

		customData.push(SignerInfo.create(control.signer));
		customData.push(ControlRole.create(control.role));
		// Making the control optional in disable mode
		//if (control.required === undefined || control.required === null) {
		control.required = false;
		//}

		switch (control.type) {

			case 1: // signature

				model = SignatureControl.create(Guid.newGuid().toString(), control.top, control.left, control.tooltip, control.required, customData, undefined, true);

				break;

			case 2: // Initial                

				model = InitialControl.create(Guid.newGuid().toString(), control.top, control.left,
					control.tooltip, control.required, customData, undefined, true);

				break;

			case 3: // Date

				model = DateControl.create(Guid.newGuid().toString(), control.top, control.left, control.tooltip, control.required, customData, TextData.create(this.getCurrentDate()), true);

				break

			case 4: // Name and Title

				model = NameAndTitleControl.create(Guid.newGuid().toString(), control.top, control.left, control.tooltip, control.required, false, customData, undefined, true);

				break;


			case 11: // company

				model = CompanyControl.create(Guid.newGuid().toString(), control.top, control.left, control.tooltip, control.required, false, customData, undefined, true);

				break;

			case 10: // Text

				model = CustomTextControl.create(Guid.newGuid().toString(), control.top, control.left, control.tooltip, control.required, false, customData, undefined, true);

				break;

			case 12: // Print Name

				model = PrintNameControl.create(Guid.newGuid().toString(), control.top, control.left, control.tooltip, control.required, false, customData, undefined, true);

				break;

			case 13: // Title

				model = TitleControl.create(Guid.newGuid().toString(), control.top, control.left, control.tooltip, control.required, false, customData, undefined, true);

				break;

			case 14: // Check Box

				model = CheckBoxControl.create(Guid.newGuid().toString(), control.top, control.left, control.width, control.height,
					control.tooltip, control.required, control.items, customData, undefined, true);

				break;
			case 15: // RadioButton

				model = RadioButtonControl.create(Guid.newGuid().toString(), control.top, control.left, control.width, control.height,
					control.tooltip, control.required, control.items, customData, undefined, true);

				break;

			default:

				throw new Error("Method not implemented.");
		}

		return model;

	}


	private getCurrentDate = (): string => {

		var today = new Date();
		var dd = today.getDate().toString();

		var mm = (today.getMonth() + 1).toString();
		var yyyy = today.getFullYear();
		if (Number(dd) < 10) {
			dd = '0' + dd;
		}

		if (Number(mm) < 10) {
			mm = '0' + mm;
		}

		return mm + '/' + dd + '/' + yyyy;
	}

	private getSigners = (document: IDocument) => {
		let signers: SignatureControlRole[] = [];
		document.pages.forEach((page: IPage) => {
			page.controls.forEach((control: IControl) => {
				const role = control.customData.find(x => x instanceof ControlRole)?.role;
				if (role && !signers.includes(role)) {
					signers.push(role);
				}
			});
		});

		return signers;
	}
}