export interface ITutorialInfo {
	clientId: string,
	hideSummaryTutorial?: boolean,
	hideReviewTutorial?: boolean,
}

export const initialTutorialInfo: ITutorialInfo = {
	clientId: "",
	hideReviewTutorial: true,
	hideSummaryTutorial:true,
}