import { AxiosResponse, AxiosRequestConfig } from "axios";
import { Action, Reducer } from "redux";

import { IGroupedReturnTableModel } from "../../core/domain/models/groupedReturns/IGroupedReturnTableModel";
import { initializeAxios } from "../../core/services/dataAccess/DataService.Axios";
import { actionTypes } from "../ActionTypes";
import { NotificationAction, StatusType } from "../Common/NotificationStore";
import { AppThunkAction } from "../index";
import { DocumentStatus } from "../../core/common/Enums";
import {
  ICCRecipientDownloadableDocuments,
  ICCRecipientModel,
} from "../../core/domain/models/ICCRecipientModel";
import { container } from "../../startup/inversify.config";
import { ILoader } from "../../core/utilities/ui/Loader";
import { TYPES } from "../../startup/types";
import { IControllerDashboardViewModel } from "../../core/domain/viewModels/IControllerDashboardViewModel";
import { TelemetryLogger } from "../../components/Logger/AppInsights";
import { IDocumentTransaction } from "../../core/domain/models/IDocumentTransaction";
const logger = TelemetryLogger.getInstance();

const loader = container.get<ILoader>(TYPES.ILoader);

export interface ControllerDashboardState {
  groupedReturnsState: GroupedReturnsState;
  ccRecipientDownloadableDocuments: ICCRecipientDownloadableDocuments[];
  refreshToken: boolean;
}

export interface GroupedReturnsState {
  groupedReturns: IGroupedReturnTableModel[];
  isLoading: boolean;
}

export const initialGroupedReturnsState: GroupedReturnsState = {
  groupedReturns: [],
  isLoading: false,
};

export const initialControllerDashboardState: ControllerDashboardState = {
  groupedReturnsState: initialGroupedReturnsState,
  ccRecipientDownloadableDocuments: [],
  refreshToken: false,
};

interface RequestGroupedDocumentsAction {
  type: actionTypes.DASHBOARD_GROUPED_DOCUMENTS_REQUEST;
}

interface ResponseGroupedDocumentsAction {
  type: actionTypes.DASHBOARD_GROUPED_DOCUMENTS_RESPONSE;
  data: IGroupedReturnTableModel[];
  refreshToken: boolean;
}

interface FailureGroupedDocumentsAction {
  type: actionTypes.DASHBOARD_GROUPED_DOCUMENTS_FAILURE;
  data: IGroupedReturnTableModel[];
  refreshToken: boolean;
}

interface RequestCCRecipientDownloadableDocumentsAction {
  type: actionTypes.DASHBOARD_CCRECIPIENT_DOWNLOADABLE_DOCUMENTS_REQUEST;
  clientId: string;
}

interface ResponseCCRecipientDownloadableDocumentsAction {
  type: actionTypes.DASHBOARD_CCRECIPIENT_DOWNLOADABLE_DOCUMENTS_RESPONSE;
  data: ICCRecipientDownloadableDocuments[];
}

interface FailureCCRecipientDownloadableDocumentsAction {
  type: actionTypes.DASHBOARD_CCRECIPIENT_DOWNLOADABLE_DOCUMENTS_FAILURE;
  clientId: string;
}

interface UpdateNextPaymenDueSuccessAction {
  type: actionTypes.DASHBOARD_UPDATE_NEXT_PAYMENT_DUE_SUCCESS;
  documentId: number;
  data: string;
}

interface UpdateNextPaymenDueFailureAction {
  type: actionTypes.DASHBOARD_UPDATE_NEXT_PAYMENT_DUE_FAILURE;
  documentId: number;
  data: string;
}

type DispatchAction =
  | RequestGroupedDocumentsAction
  | ResponseGroupedDocumentsAction
  | FailureGroupedDocumentsAction
  | RequestCCRecipientDownloadableDocumentsAction
  | ResponseCCRecipientDownloadableDocumentsAction
  | FailureCCRecipientDownloadableDocumentsAction
  | UpdateNextPaymenDueSuccessAction
  | UpdateNextPaymenDueFailureAction;

type KnownAction = DispatchAction | NotificationAction;

export const actionCreators = {
  requestGroupedDocuments:
    (
      clientId: string,
      pageNo: number,
      pageSize: number,
      sortBy: string,
      sortOrder: string,
      filterTaxPayerName: string,
      filterSignatureStatus: string,
      filterK1Status: string,
      filterNextPaymentDue: string,
      filterTaxYear: string,
      filterInvoiceStatus: string,
      searchText: string
    ): AppThunkAction<KnownAction> =>
    (dispatch, getState) => {
      const state = getState();
      dispatch({ type: actionTypes.DASHBOARD_GROUPED_DOCUMENTS_REQUEST });
      return initializeAxios()
        .get<IControllerDashboardViewModel>(
          `api/ControllerDashboard/GetDeliveredReturns/${clientId}?pageNo=${pageNo}&&pageSize=${pageSize}&&sortBy=${sortBy}&&sortOrder=${sortOrder}&&filterTaxPayerName=${filterTaxPayerName}&&filterSignatureStatus=${filterSignatureStatus}&&filterK1Status=${filterK1Status}&&filterNextPaymentDue=${filterNextPaymentDue}&&filterTaxYear=${filterTaxYear}&&filterInvoiceStatus=${filterInvoiceStatus}&&searchText=${searchText}`
        )
        .then(function (
          response: AxiosResponse<IControllerDashboardViewModel>
        ) {
          dispatch({
            type: actionTypes.DASHBOARD_GROUPED_DOCUMENTS_RESPONSE,
            data: response.data.groupedReturns,
            refreshToken: response.data.refreshToken,
          });
        })
        .catch(function (error: any) {
          dispatch({
            type: actionTypes.NOTIFICATION,
            statusMessage: error.response
              ? error.response.statusText
              : "Error occurred while requesting Grouped Returns",
            statusType: StatusType.Error,
          });
          dispatch({
            type: actionTypes.DASHBOARD_GROUPED_DOCUMENTS_FAILURE,
            data: state.groupedReturnDashboardData.groupedReturnsState
              .groupedReturns,
            refreshToken: false,
          });
          logger.trackWarning(
            `requestGroupedDocuments failed with error ${error.message} for controller Id: ${clientId}`,
            { ClientId: clientId }
          );
        });
    },
  requestCCRecipientDownloadableDocuments:
    (
      clientId: string,
      documentId: number,
      documentStatus: DocumentStatus
    ): AppThunkAction<KnownAction> =>
    (dispatch, getState) => {
      let state = getState();
      dispatch({
        type: actionTypes.DASHBOARD_CCRECIPIENT_DOWNLOADABLE_DOCUMENTS_REQUEST,
        clientId: clientId,
      });
      return initializeAxios()
        .get<ICCRecipientDownloadableDocuments>(
          `api/ControllerDashboard/GetDownloadableDocuments/${documentId}
                                                                        /${documentStatus}/${clientId}`
        )
        .then(function (
          response: AxiosResponse<ICCRecipientDownloadableDocuments[]>
        ) {
          dispatch({
            type: actionTypes.DASHBOARD_CCRECIPIENT_DOWNLOADABLE_DOCUMENTS_RESPONSE,
            data: response.data,
          });
        })
        .catch(function (error: any) {
          dispatch({
            type: actionTypes.NOTIFICATION,
            statusMessage: error.response
              ? error.response.statusText
              : "Failed to fetch document list",
            statusType: StatusType.Error,
          });
          dispatch({
            type: actionTypes.DASHBOARD_CCRECIPIENT_DOWNLOADABLE_DOCUMENTS_FAILURE,
            clientId: clientId,
          });
          logger.trackWarning(
            `requestCCRecipientDownloadableDocuments failed with error ${error.message} for controller Id: ${clientId}`,
            { ClientId: clientId }
          );
        });
    },
  forwardDocumentsToRecipients:
    (
      clientId: string,
      recipientInfo: ICCRecipientModel,
      callback: () => void,
      taxYear?: number
    ): AppThunkAction<KnownAction> =>
    (dispatch, getState) => {
      loader.show();
      const taxReturn = getState().taxReturn;
      return initializeAxios()
        .postJson<boolean>(
          recipientInfo,
          `api/ControllerDashboard/SendDownloadableDocumentsToRecipients/${clientId}?taxYear=${
            taxYear ? taxYear : taxReturn.taxDocument.taxYear
          }`
        )
        .then(function (response: any) {
          loader.hide();
          callback();
        })
        .catch(function (error: any) {
          dispatch({
            type: actionTypes.NOTIFICATION,
            statusMessage: error.response?.statusText ?? error.message,
            statusType: StatusType.Error,
          });
          logger.trackWarning(
            `forwardDocumentsToRecipients failed with error ${error.message} for controller Id: ${clientId}`,
            { ClientId: clientId }
          );
        });
    },
  updateNextPaymentDue:
    (
      clientId: string,
      documentId: number,
      formData: string
    ): AppThunkAction<KnownAction> =>
    (dispatch, getState) => {
      loader.show();
      const returns =
        getState().groupedReturnDashboardData.groupedReturnsState
          .groupedReturns;
      let config: AxiosRequestConfig = {
        headers: { "Content-Type": "application/json;utf-8" },
      };

      return initializeAxios()
        .put<boolean>(
          `api/ControllerDashboard/UpdateNextPaymentDue/${clientId}`,
          JSON.stringify({ documentId: documentId, formData: formData }),
          config
        )
        .then(function (response: any) {
          loader.hide();
          if (response) {
            dispatch({
              type: actionTypes.DASHBOARD_UPDATE_NEXT_PAYMENT_DUE_SUCCESS,
              data: formData,
              documentId: documentId,
            });
          } else {
            dispatch({
              type: actionTypes.DASHBOARD_UPDATE_NEXT_PAYMENT_DUE_FAILURE,
              data: returns.find((model) => model.documentId == documentId)!
                .formData,
              documentId: documentId,
            });
          }
        })
        .catch(function (error: any) {
          dispatch({
            type: actionTypes.NOTIFICATION,
            statusMessage: error.response?.statusText ?? error.message,
            statusType: StatusType.Error,
          });
          logger.trackWarning(
            `updateNextPaymentDue failed with error ${error.message} for controller Id: ${clientId}`,
            { ClientId: clientId }
          );
        });
    },
  getSignerData:
    (
      clientId: string,
      updateData: (signerData: any, documentTransaction: any) => void
    ): AppThunkAction<KnownAction> =>
    (dispatch, getState) => {
      return initializeAxios()
        .get<any>(`api/Esign/GetAllSigners/${clientId}`)
        .then(function (response: any) {
          initializeAxios()
            .get<IDocumentTransaction>(
              `api/Esign/GetDocumentClientTracking/${clientId}`
            )
            .then(function (responseResult: any) {
              updateData(response.data, responseResult.data);
            })
            .catch(function (error: any) {
              dispatch({
                type: actionTypes.NOTIFICATION,
                statusMessage: error.response?.statusText ?? error.message,
                statusType: StatusType.Error,
              });
              logger.trackWarning(
                ` fetching Get Document ClientTracking failed with message  ${error.message} for client Id: ${clientId}`,
                { ClientId: clientId }
              );
            });
        })
        .catch(function (error: any) {
          dispatch({
            type: actionTypes.NOTIFICATION,
            statusMessage: error.response?.statusText ?? error.message,
            statusType: StatusType.Error,
          });
          logger.trackWarning(
            ` fetching signer details failed with message  ${error.message} for client Id: ${clientId}`,
            { ClientId: clientId }
          );
        });
    },
};

export const reducer: Reducer<ControllerDashboardState> = (
  state: ControllerDashboardState = initialControllerDashboardState,
  incomingAction: Action
) => {
  const action = incomingAction as DispatchAction;
  const currentState = Object.assign({}, state);
  switch (action.type) {
    case actionTypes.DASHBOARD_GROUPED_DOCUMENTS_REQUEST:
      currentState.groupedReturnsState.isLoading = true;
      return currentState;
    case actionTypes.DASHBOARD_GROUPED_DOCUMENTS_RESPONSE:
    case actionTypes.DASHBOARD_GROUPED_DOCUMENTS_FAILURE:
      return {
        ...currentState,
        refreshToken: action.refreshToken,
        groupedReturnsState: { groupedReturns: action.data, isLoading: false },
      };
    case actionTypes.DASHBOARD_CCRECIPIENT_DOWNLOADABLE_DOCUMENTS_REQUEST:
      currentState.ccRecipientDownloadableDocuments = [];
      return currentState;
    case actionTypes.DASHBOARD_CCRECIPIENT_DOWNLOADABLE_DOCUMENTS_RESPONSE:
      currentState.ccRecipientDownloadableDocuments = action.data;
      return currentState;
    case actionTypes.DASHBOARD_UPDATE_NEXT_PAYMENT_DUE_SUCCESS:
    case actionTypes.DASHBOARD_UPDATE_NEXT_PAYMENT_DUE_FAILURE:
      let updatedModels: IGroupedReturnTableModel[] =
        currentState.groupedReturnsState.groupedReturns.map((model) =>
          Object.assign({}, model)
        );
      updatedModels.forEach((model) => {
        if (model.documentId == action.documentId) {
          model.formData = action.data;
        }
      });
      return {
        ...currentState,
        groupedReturnsState: {
          ...currentState.groupedReturnsState,
          groupedReturns: updatedModels,
        },
      };
    default:
      return currentState || initialControllerDashboardState;
  }
};
