import { injectable } from "inversify";
import * as bootbox from "bootbox";

export interface IDialogBox {
    alert(message: string, callback?: () => void): void;
    confirm(
        title: string,
        message: string,
        cancel: string,
        confirm: string,
        callback?: (result: boolean) => void
    ): void;
    confirmDelete(
        title: string,
        message: string,
        cancel: string,
        confirm: string,
        callback?: (result: boolean) => void
    ): void;
    confirmCustom(
        title: string,
        message: string,
        rightButtonText: string,
        leftButtonText: string,
        callback?: (result: boolean) => void
    ): void;
}

@injectable()
export class DialogBox implements IDialogBox {
    private customCloseIcon: string =
        '<button type="button" class="bootbox-close-button close" aria-label="Close"><svg style="margin-top: -6px;" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none"><g clip-path="url(#clip0_1433_425)"><path d="M0.292542 0.292166C0.48007 0.104695 0.734378 -0.000620803 0.999542 -0.000620803C1.26471 -0.000620803 1.51901 0.104695 1.70654 0.292166L7.99954 6.58517L14.2925 0.292166C14.3848 0.196655 14.4951 0.120473 14.6171 0.0680643C14.7391 0.0156552 14.8704 -0.011931 15.0031 -0.0130848C15.1359 -0.0142386 15.2676 0.0110631 15.3905 0.061344C15.5134 0.111625 15.625 0.185878 15.7189 0.279771C15.8128 0.373663 15.8871 0.485315 15.9374 0.608212C15.9876 0.731108 16.0129 0.862788 16.0118 0.995567C16.0106 1.12835 15.9831 1.25957 15.9306 1.38157C15.8782 1.50357 15.8021 1.61392 15.7065 1.70617L9.41354 7.99917L15.7065 14.2922C15.8887 14.4808 15.9895 14.7334 15.9872 14.9956C15.9849 15.2578 15.8798 15.5086 15.6944 15.694C15.509 15.8794 15.2581 15.9846 14.9959 15.9868C14.7337 15.9891 14.4811 15.8883 14.2925 15.7062L7.99954 9.41317L1.70654 15.7062C1.51794 15.8883 1.26534 15.9891 1.00314 15.9868C0.740944 15.9846 0.490132 15.8794 0.304724 15.694C0.119316 15.5086 0.0141467 15.2578 0.0118683 14.9956C0.00958985 14.7334 0.110384 14.4808 0.292542 14.2922L6.58554 7.99917L0.292542 1.70617C0.105071 1.51864 -0.000244141 1.26433 -0.000244141 0.999166C-0.000244141 0.734001 0.105071 0.479693 0.292542 0.292166Z" fill="rgba(0, 0, 0, 0.5)" /></g><defs><clipPath id="clip0_1433_425"><rect width="16" height="16" fill="white" /></clipPath></defs></svg></button>';

    public alert(message: string, callback?: () => void): void {
        bootbox.alert(message, function () {
            callback && callback();
        });
    }

    public confirm(
        title: string,
        message: string,
        cancel: string,
        confirm: string,
        callback?: (result: boolean) => void
    ): void {
        bootbox.confirm({
            title: title + this.customCloseIcon,
            closeButton: false,
            message: message,
            buttons: {
                cancel: {
                    label: '<i class="glyphicon glyphicon-remove"></i> ' + cancel,
                    className: "button-outline-tertiary button-medium button-margin-right"
                },
                confirm: {
                    label: '<i class="glyphicon glyphicon-ok"></i>' + confirm,
                    className: "button-primary button-medium"
                }
            },
            callback: (result: boolean) => {
                if (callback) {
                    callback(result);
                }
            }
        });
    }

    public confirmDelete(
        title: string,
        message: string,
        cancel: string,
        confirm: string,
        callback?: (result: boolean) => void
    ): void {
        bootbox.confirm({
            title: title + this.customCloseIcon,
            closeButton: false,
            message: message,
            buttons: {
                cancel: {
                    label: '<i class="glyphicon glyphicon-remove"></i> ' + cancel,
                    className: "button-outline-tertiary button-medium button-margin-right"
                },
                confirm: {
                    label: '<i class="glyphicon glyphicon-ok"></i>' + confirm,
                    className: "button-danger button-medium"
                }
            },
            callback: (result: boolean) => {
                if (callback) {
                    callback(result);
                }
            }
        });
    }

    public confirmCustom(
        title: string,
        message: string,
        rightButtonText: string,
        leftButtonText: string,
        callback?: (result: boolean) => void
    ): void {
        bootbox.confirm({
            title: title + this.customCloseIcon,
            closeButton: false,
            message: message,
            buttons: {
                cancel: {
                    label: '<i class="glyphicon glyphicon-remove"></i> ' + leftButtonText,
                    className: "button-outline-tertiary button-medium button-margin-right"
                },
                confirm: {
                    label: '<i class="glyphicon glyphicon-ok"></i>' + rightButtonText,
                    className: "button-primary button-medium"
                }
            },
            callback: (result: boolean) => {
                if (callback) {
                    callback(result);
                }
            }
        });
    }
}
