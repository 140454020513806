import * as React from 'react';
import { CustomButton, CustomButtonSize } from '../Common/CustomButton/CustomButton';
import { ManualSignConstants, Common, DownloadDocumentsConstants } from '../Common/Constants';
import { StepLayout } from '../Common/StepLayout/StepLayout';
import { Body } from '../Common/StepLayout/Body';
import { ITaxReturn } from '../../core/domain/models/ITaxReturn';
import { IAdditionalEsignDocument } from '../../core/domain/models/IAdditionalEsignDocument';
import { MarsNotifier } from '../Common/Notification/MarsNotifier';
import { logger } from '../../routes';
import { DocumentGroups } from '../../core/domain/models/IGroup';
import { SignProcessSteps } from '../Home/TaxpayerSignFlowManager';
import { DownloadDocuments } from './Parts/DownloadDocuments';

export interface DownloadDocumentsProps {
    clientId: string;
    goToNextStep(): void;
	downloadEfileForms(clientId: string, successCallback?: () => void): void;
	downloadAllAdditionEsignDocuments(clientId: string, fileName: string): any;
	additionalEsignDocuments: IAdditionalEsignDocument[];
	taxReturn: ITaxReturn;
    clientName: string;
    isPreviewMode: boolean;
    updateLastVisitedStep: (clientId: string, step: number) => void;
}

export const ManualSignDownloadFiles: React.FunctionComponent<DownloadDocumentsProps> = ({
	clientId, goToNextStep, downloadEfileForms, downloadAllAdditionEsignDocuments, additionalEsignDocuments, taxReturn, clientName,
    isPreviewMode, updateLastVisitedStep }) => {

    React.useEffect(() => {
        logger.trackPageView('Manual Sign Download Page', { "ClientId": clientId, "DocumentId": taxReturn.id, "PreviewMode": isPreviewMode });
    }, []);

    let handleEfileDownload = () => {
        if (!isPreviewMode) {
            const formGroup = taxReturn.formGroups.find(x => x.group === DocumentGroups.EFile);
            if (formGroup && formGroup.forms.length > 0) {
                downloadEfileForms(clientId, () => updateLastVisitedStep(clientId, SignProcessSteps.ManualSign));
            }
            else {
                MarsNotifier.Warning(DownloadDocumentsConstants.EfileNotPresent, null);
                updateLastVisitedStep(clientId, SignProcessSteps.ManualSign);
            }
            logger.trackTrace('Manual Sign Download Document Page: Efile Downloaded', { "ClientId": clientId, "DocumentId": taxReturn.id, "PreviewMode": isPreviewMode });
            setTimeout(function () {
                additionalEsignDocuments && additionalEsignDocuments.length > 0
                    && downloadAllAdditionEsignDocuments && downloadAllAdditionEsignDocuments(clientId, getOtherUnSignedDocumentFileName(additionalEsignDocuments.length));
                logger.trackTrace('Manual Sign Download Document Page: Additional Document(s) Downloaded', { "ClientId": clientId, "DocumentId": taxReturn.id, "PreviewMode": isPreviewMode });
            }, 2000);
        }
        else {
            MarsNotifier.Warning(DownloadDocumentsConstants.DownloadNotAllowed, null);
            goToNextStep();
        }
	}

	let getFileNamePrefix = () => {
		return clientName + "_" + taxReturn.taxYear + "_" + taxReturn.engagementType.toString().substr(1);
	}

	let getOtherSignedDocumentFileName = () => {
		return getFileNamePrefix() + ManualSignConstants.DownloadZipSignedFileName;
	}

	let getOtherUnSignedDocumentFileName = (noOfFiles: number) => {
		return getFileNamePrefix() + (noOfFiles > 1 ? ManualSignConstants.DownloadZipMultipleUnSignedFileName : ManualSignConstants.DownloadZipUnSignedFileName);
	}

    return (<StepLayout className="manual-sign-stepper">

        <Body 
        title={ManualSignConstants.Title} lg={8} md={12} sm={12} xs={12}
        containerClassName={"mt-5"}
        >       
        <React.Fragment>
            <DownloadDocuments clientId={clientId} downloadEfileForms={handleEfileDownload} />            
        </React.Fragment>
        </Body>
    </StepLayout>);
};

