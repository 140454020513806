import { Next } from '@sssuite-component-ui/multi-step-wizard/dist/Footer/Next';
import { Previous } from '@sssuite-component-ui/multi-step-wizard/dist/Footer/Previous';
import { History } from 'history';
import * as React from 'react';
import { match } from 'react-router';
import { ICompany } from '../../../core/domain/models/company/Company';
import { IK1ShareHolderDetails } from '../../../core/domain/models/IK1Distribute';
import * as TaxDocument from '../../../core/domain/models/ITaxReturn';
import { IClientProcessViewModel } from '../../../core/domain/viewModels/IClientProcessViewModel';
import { IHeaderInfoViewModel } from '../../../core/domain/viewModels/IHeaderInfoViewModel';
import * as CompanyStore from '../../../store/Common/Company/CompanyStore';
import * as TaxDocumentStore from '../../../store/Common/TaxDocumentStore';
import * as K1DistributeStore from '../../../store/K1/K1DistributeStore';
import * as SignProcessStore from '../../../store/SignProcess/SignProcessStore';
import { PathConstants, getRoutePathUrl } from '../../Common/Constants';
import { Body } from '../../Common/StepLayout/Body';
import { Footer } from '../../Common/StepLayout/Footer';
import { StepLayout } from '../../Common/StepLayout/StepLayout';
import TaxpayerHelper from '../../Helper/TaxpayerHelper';
import { DistributeManual } from '../../K1Distribution/DistributeManual';
import { Role } from '../../../core/common/Enums';
import { storeControllerIdInMemory } from '../../../core/services/dataAccess/DataService.Axios';
import * as TrialK1DistributeStore from '../../../store/K1/TrialK1DistributeStore';
import { PreviousButtonIcon } from "../../Common/Icons/SvgIcons";

type ManualDistributionProps = {
    taxReturn: TaxDocument.ITaxReturn,
    headInfoViewModel: IHeaderInfoViewModel,
    companyData: ICompany,
    match: match;
    history: History;
    k1Data: IK1ShareHolderDetails[];
    isK1Loading: boolean;
    requestMyDownload: (clientId: string) => void;
}
    & typeof CompanyStore.actionCreators
    & typeof K1DistributeStore.actionCreators
    & typeof TaxDocumentStore.actionCreators
    & typeof SignProcessStore.actionCreators
    & typeof TrialK1DistributeStore.actionCreators;


interface ManualDistributionState {
    isProcessCompleted: boolean;
    isPreviewMode: boolean;
    isTrialView: boolean;
}

export class ManualDistribution extends React.Component<ManualDistributionProps, ManualDistributionState> {
    private _params: any;
    private _distributeManualRef: any = React.createRef();
    constructor(props: ManualDistributionProps) {
        super(props);
        this.state = {
            isProcessCompleted: false,
            isPreviewMode: false,
            isTrialView: false,
        }
    }

    componentDidMount() {
        const param: any = this.props.match.params;
        if (param && param.controllerId) {
            storeControllerIdInMemory(param.controllerId);
        }
        this.getSignProcessStatus(param.clientId);
        this.props.requestTaxDocument(param.clientId);
    }
    gotoCompletionWizard = () => {
        const param: any = this.props.match.params;
        this.props.history.push(getRoutePathUrl(PathConstants.CompletionWizard, param.clientId, param.controllerId));
    }

    gotoPreviousStep = () => {
        const param: any = this.props.match.params;
        this.props.history.push(getRoutePathUrl(PathConstants.DistributeK1, param.clientId, param.controllerId));
    }
    private getSignProcessStatus = (clientId: any) => {
        this.props.requestCurrentStepAndUserRole(clientId, this.handleRedirect);
    }
    private handleRedirect = (clientProcessState: IClientProcessViewModel) => {
        this._params = this.props.match.params;
        this.setState({ 
            isPreviewMode: TaxpayerHelper.isPreviewMode(clientProcessState),
            isTrialView: TaxpayerHelper.isTrialView(),
        });
        const url = TaxpayerHelper.getSignCompletedRedirectUrl(clientProcessState, this._params.clientId, this._params.controllerId);
        if (url && url.trim()) {
            this.props.history.push(url);
        }
        else {
            this.setState({ isProcessCompleted: true });
        }
    }
    public render() {
        const role = this.props.headInfoViewModel ?.loggedInUserInfo ?.role;
        var downloadSelectedDocumentAsync = (role.toString() == Role[Role.CPA] || role == Role.CPA) ? this.props.downloadSelectedK1DocumentForPreviewAsync : this.props.downloadSelectedK1DocumentAsync
        var downloadBulkSelectedK1DocumentAsync= (role.toString() == Role[Role.CPA] || role == Role.CPA) ?
        this.props.downloadBulkSelectedK1DocumentForPreviewAsync : this.props.downloadBulkSelectedK1DocumentAsync
        var downloadAllK1Async = (role.toString() == Role[Role.CPA] || role == Role.CPA) ? this.props.downloadK1DocumentForPreviewAsync : this.props.downloadK1DocumentAsync


        return (
            (this.state.isProcessCompleted) &&
            <StepLayout className={"with-footer"}>
                <Body lg={12} md={12} sm={12} xs={12} bodyClassName={"no-padding"} automationTestId={"11BB28B5-42F3-4571-B567-20700B1740B6"}>
                    {this.props.taxReturn.formGroups.length > 0 &&
                        <DistributeManual
                        ref={(ins) => this._distributeManualRef = ins}
                        requestK1ShareHolderDetails={this.props.requestK1ShareHolderDetails}
                        k1Data={this.props.k1Data}
                        match={this.props.match}
                        taxReturn={this.props.taxReturn}
                        downloadK1DocumentAsync={downloadAllK1Async}
                        downloadIndividualK1DocumentAsync={this.props.downloadIndividualK1DocumentAsync}
                        downloadSelectedK1DocumentAsync={downloadSelectedDocumentAsync}
                        downloadBulkSelectedK1DocumentAsync={downloadBulkSelectedK1DocumentAsync}
                        requestMyDownload={this.props.requestMyDownload}
                        isPreviewMode={this.state.isPreviewMode}
                        isTrialView={this.state.isTrialView}
                        downloadAllK1DocumentsAsync={this.props.downloadAllK1DocumentsAsync}
                        isK1Loading={this.props.isK1Loading}
                        />
                    }
                </Body>

                <Footer lg={12} md={12} sm={12} xs={12}>
                    <div className={"steps-footer "}>
                        <Previous automationTestId={"3F43C07D-73AD-4BB5-83D1-346B1E62ABC6"}
                            text={"Back"} goToPreviousStep={this.gotoPreviousStep} isActive={true}
                                className="awesome-multi-steps-completed-btn-previous" previousButtonIcon={<PreviousButtonIcon />} />

                        <Next automationTestId={"21596F53-89B5-4830-812E-96D126E7C1AE"}
                            text={"Done"} goToNextStep={this.gotoCompletionWizard} isActive={true}
                            className="awesome-multi-steps-btn-done" buttonVariant="success" buttonSize="medium" />
                    </div>
                </Footer>

            </StepLayout>);

    }
}
