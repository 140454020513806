
import { connect } from 'react-redux';
import MobileOTP_GR from "./MobileOTP_GR";
import * as headerInfoStore from "../../store/Common/AccountStore";
import * as HeaderStore from "../../store/HeaderStore";
import * as GroupOTPReducer from "../../store/OTP/OTPStore_GR";


export default connect(
    (state: any) => ({
        headerInfoState: state.HeaderPageReducer,
        OTPPageState: state.GroupOTPReducer


    }),
    {
        ...HeaderStore.actionCreators,
        ...GroupOTPReducer.actionCreators


    }
)(MobileOTP_GR as any);