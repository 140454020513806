import * as React from 'react';

import { IPayScreenViewModel } from '../../../core/domain/viewModels/IPayScreenViewModel';
import { IDocumentDisplaySetting } from '../../../core/domain/models/IDocumentSettings';
import { VoucherTypes, DocumentGroups } from '../../../core/domain/models/IGroup';
import { IUtilities } from '../../../core/utilities/Utilities';
import { container } from '../../../startup/inversify.config';
import { TYPES } from '../../../startup/types';
import { Shimmer } from '../../Common/Shimmer/Shimmer';
import { ITaxReturn } from '../../../core/domain/models/ITaxReturn';
import { getDueDateBasedVouchers } from '../PayHelper';
import { RightArrowIcon, PopupIcon } from "../../Common/Icons/SvgIcons";
import { PaymentInstruction } from '../../Pay/PaymentInstruction';
import { PayConstants } from '../../Common/Constants';

const utilities = container.get<IUtilities>(TYPES.IUtilities);

export interface TaxPaymentProps {
    payScreenData: IPayScreenViewModel,
    documentDisplaySetting: IDocumentDisplaySetting,
    handleDueDateSelection: (selectedDate: string, selectedPaymentType: string) => void;
    taxReturn: ITaxReturn;
    selectedTab: string;
    paymentInstruction: string;
}


interface TaxPaymentStates {
    selectedTab: string;
    selectedPaymentType: string;
    showPaymentInstructionModal: boolean;
}


class PaymentVoucherPay extends React.Component<TaxPaymentProps, TaxPaymentStates> {

    paymentVoucherSet: any[] = [];

    constructor(props: TaxPaymentProps) {
        super(props);
        this.state = {
            selectedTab: "",
            selectedPaymentType: "",
            showPaymentInstructionModal: false
        }

        this.setInitialSelection = this.setInitialSelection.bind(this);
    }

    handleTabSelect = (selectedTab: string, selectedPaymentType: string) => {
        this.setState({ selectedTab: selectedTab, selectedPaymentType: selectedPaymentType });
        this.props.handleDueDateSelection(selectedTab, selectedPaymentType);
    }


    setInitialSelection = () => {
        if (this.paymentVoucherSet.length !== 0 && this.props.selectedTab.length === 0) {
            let first = this.paymentVoucherSet[0];
            this.handleTabSelect(first.groupName, VoucherTypes[VoucherTypes.PaymentVoucher])
        }

    }

    componentDidMount() {
        this.setInitialSelection();
    }

    componentDidUpdate() {
        this.setInitialSelection();
    }

    closePaymentInstructionModal = () => {
        this.setState({ showPaymentInstructionModal: false });
    };

    showPaymentInstructionModal = () => {
        this.setState({ showPaymentInstructionModal: true });
    };

    public render() {
        let groupedVouchers = getDueDateBasedVouchers(this.props.payScreenData.vouchers);

        const isLoaded = this.props.payScreenData.documentId === 0 ? false : true

        return (<>

            <div className="tax-payment-container">

                {this.props.payScreenData.documentId !== 0 ?
                    <div className='col-xs-12 col-sm-12 col-md-12 col-lg-12 no-padding tax-payment-header'>
                        <div className='tax-payment-header-text'>
                            <div className="title">
                                Tax Payments
                                <span
                                    title={PayConstants.PaymentInstructionModalHeader}
                                    onClick={() => this.showPaymentInstructionModal()}>
                                    <PopupIcon /></span>
                            </div>
                        </div>
                    </div>
                    : <Shimmer width={250} />
                }
                 <div className={isLoaded ? "pay-left-panel-list":"pay-left-panel-list fullheight" }>
                    {isLoaded &&
                        this.props.taxReturn.formGroups.find(m => m.group == DocumentGroups.Vouchers)?.forms?.length === 0 &&
                        this.props.taxReturn.invoiceAmount === 0 &&
                        <React.Fragment>
                            <div> <h6 className="section-heading"> No Payment Vouchers</h6></div>
                        </React.Fragment>
                    }

                    {/* Payment Vouchers*/}
                    {isLoaded ?
                        this.props.payScreenData.vouchers.find(m => m.paymentType === VoucherTypes.PaymentVoucher) &&
                        <section>
                            <div className="payment-panel-list-header" data-test-auto="5C0512A6-6E63-458E-8A3D-2B0B7FC56C88">
                                <b>{this.props.payScreenData.taxYear} Tax Payments</b>
                            </div>

                            {
                                groupedVouchers.map((item, _index) => {
                                    if (item.vouchers.find(m => m.paymentType === VoucherTypes.PaymentVoucher)) {
                                        let paymentVoucherTotal = item.vouchers.map(v => v.paymentType == VoucherTypes.PaymentVoucher ? v.amount : 0)
                                            .reduce((sum, current) => sum + current);

                                        this.paymentVoucherSet.push(item);

                                        let className = item.groupName === this.state.selectedTab && this.state.selectedPaymentType === VoucherTypes[VoucherTypes.PaymentVoucher] ?
                                            "payment-panel-list-item cursor-pointer active padding-left-6 pay-screen-voucher" :
                                            " payment-panel-list-item cursor-pointer padding-left-6 pay-screen-voucher";

                                        let active = item.groupName === this.state.selectedTab && this.state.selectedPaymentType === VoucherTypes[VoucherTypes.PaymentVoucher] ?
                                            true : false;

                                        return (
                                            <li className={className}
                                                data-test-auto="A09FAA24-083C-4D52-A608-DC81E0E46941"
                                                onClick={() => this.handleTabSelect(item.groupName, VoucherTypes[VoucherTypes.PaymentVoucher])}>
                                                <span> Due {item.groupName} </span>
                                                {active && <span className='right-arrow'> <RightArrowIcon /></span>}
                                                <span className={active ? "amount flrt  pay-currencytext d-sm-none d-md-block d-none" : "amount flrt  pay-currencytext d-sm-none d-md-block d-none pad-left-43"}
                                                    data-test-auto="B02D53DB-2AC8-4100-A81E-B97D5F4F7A00">
                                                    ${utilities.formatCurrencyText(paymentVoucherTotal)}
                                                </span>
                                            </li>
                                        )
                                    }

                                })
                            }

                        </section> :
                        <Shimmer lineCount={8} />
                    }



                </div>
            </div>

            <PaymentInstruction
                clientInstructions={this.props.paymentInstruction}
                show={this.state.showPaymentInstructionModal}
                closePaymentInstructionModal={this.closePaymentInstructionModal}
            />
        </>);
    }
}

/*

   {isLoaded ?
                        this.props.payScreenData.vouchers.find(m => m.paymentType === VoucherTypes.EstimatedVoucher) &&
                        <section>
                            <div className="section-title" data-test-auto="F1B09F46-E41C-4892-BAC9-8F9CA5E8DBB1">
                                <b>{this.props.payScreenData.taxYear + 1} Estimated Payments </b>
                            </div>

                            {
                                groupedVouchers.map((item, _index) => {

                                    if (item.vouchers.find(m => m.paymentType === VoucherTypes.EstimatedVoucher)) {
                                        let estimatedVoucherTotal = item.vouchers.map(v => v.paymentType == VoucherTypes.EstimatedVoucher ? v.amount : 0)
                                            .reduce((sum, current) => sum + current);


                                        let className = item.groupName === this.state.selectedTab && this.state.selectedPaymentType === VoucherTypes[VoucherTypes.EstimatedVoucher] ?
                                            "pay-right-pointer pay-arrow-right cursor-pointer padding-left-6 pay-screen-voucher" :
                                            " cursor-pointer  pay-screen-voucher";



                                        return (
                                            <li id="pay-tab-navbar" className={className}
                                                data-test-auto="A22C6443-A3D2-4604-BA95-4B04EF0CE0CA"
                                                onClick={() => this.handleTabSelect(item.groupName, VoucherTypes[VoucherTypes.EstimatedVoucher])}>
                                                <span> Due {item.groupName} </span>
                                                <span className="amount flrt pay-currencytext"
                                                    data-test-auto="B790E0FF-3DF1-49AE-8378-BF487780437E">
                                                    ${utilities.formatCurrencyText(estimatedVoucherTotal as number)}
                                                </span>
                                            </li>)
                                    }

                                })
                            }
                        </section> :
                        <Shimmer lineCount={8} />
                    }

 */

export default PaymentVoucherPay;