import { ILogoutMessage } from '../../core/domain/models/ILogoutMessage';
import { History } from 'history';
import * as React from 'react';
import IdleTimer from 'react-idle-timer';
import { match, RouteComponentProps } from 'react-router';
import { BroadcastChannel } from 'broadcast-channel';
import { Toaster } from '../../components/Common/Notification/MarsNotifier';
import { Role } from '../../core/common/Enums';
import { IHeaderInfoViewModel } from '../../core/domain/viewModels/IHeaderInfoViewModel';
import { ILocalStore } from '../../core/utilities/LocalStore';
import { container } from '../../startup/inversify.config';
import { TYPES } from '../../startup/types';
import * as AccountStore from '../../store/Common/AccountStore';
import * as HelperStore from '../../store/Common/HelperStore';
import * as GroupedReturnsStore from '../../store/GroupedReturns/GroupedReturnsStore';
import { SessionTimeout } from '../Account/SessionTimeout';
import { OverlayLoader } from '../Common/Loader/OverlayLoader';
import Notification from '../Common/Notification/NotificationContainer';
import { GroupedReturnHeader } from './GroupedReturnHeader';
import { PathConstants } from '../Common/Constants';
import { removeOpenedWindowStatus, generateControllerSessionId, windowUnloadStep } from '../Helper/HelperFunction';
import { ICompany } from '../../core/domain/models/company/Company';
import * as CompanyStore from '../../store/Common/Company/CompanyStore';
import Footer from './Footer';


declare global {
	interface Window {
		Variables: any;
	}
}

export type GroupedReturnDefaultLayoutProps =
	{
		headerInfo: IHeaderInfoViewModel;
		match: match;
		history: History;
		loading: boolean;
		groupedReturnsState: GroupedReturnsStore.GroupedReturnsState;
		iceServers: RTCIceServer[];
		getIceServers: (clientId: string) => void;
		getWalkMeScript: (id: string, callback: (script: string) => void) => void;
		getWebRTCSignalRHubBaseURL: (id: string, callback: (signalRHubBaseURL: string) => void) => void;
		webRTCSignalRHubBaseURL: string;
		isPreviewMode: boolean;
		companyData: ICompany;
	}
	& typeof AccountStore.actionCreators
	& typeof HelperStore.actionCreators
	& typeof GroupedReturnsStore.actionCreators
	& typeof CompanyStore.actionCreators
	& RouteComponentProps<{}>;

const localStore = container.get<ILocalStore>(TYPES.ILocalStore);
const channel = new BroadcastChannel('ssr-taxpayer');
const WarningVisibleMinutes = 1;

interface IGroupedReturnDefaultLayoutState {
	showTimeout: boolean;
	idleSessionTimeoutInSeconds: number;
};

export class GroupedReturnDefaultLayout extends React.Component<GroupedReturnDefaultLayoutProps, IGroupedReturnDefaultLayoutState> {

	private idleTimer: any;
	private sessionRenew: any;

	constructor(props: GroupedReturnDefaultLayoutProps) {
		super(props);
		this.idleTimer = null;
		this.state = {
			showTimeout: false,
			idleSessionTimeoutInSeconds: 0
		};
	}



	componentDidMount() {
		let param: any = this.props.match.params;
		this.props.requestGroupHeaderInfo(param.clientId);
		this.props.getSessionTimeOutSeconds(param.clientId, this.setSessionRenew);
		this.props.requestControllerInfo(param.clientId);
		this.props.requestCompanyDetails(param.clientId);
		let controllerId = param.clientId;

		if (localStorage.getItem(generateControllerSessionId(controllerId)) !== null) {
			localStorage.removeItem(generateControllerSessionId(controllerId));
		}

		window.addEventListener("beforeunload", (e: any) => {
			e.preventDefault();
			windowUnloadStep(controllerId, true);
			delete e['returnValue'];
		})
	}

	componentWillUnmount() {
		let params: any = this.props.match.params;
		let controllerId: any = params.clientId;

		if (controllerId) {
			removeOpenedWindowStatus(controllerId);
			window.removeEventListener("beforeunload", (e: any) => {
				e.preventDefault();
				windowUnloadStep(controllerId, true);
				delete e['returnValue'];
			});
		}

		clearInterval(this.sessionRenew);
	}

	setSessionRenew = (sessionTimeoutValue: number) =>{
		this.setState({ idleSessionTimeoutInSeconds: sessionTimeoutValue })
		this.sessionRenew = setInterval(() => {
			this.renewSession();
		}, 1000 * 60 * (this.state.idleSessionTimeoutInSeconds / 60));
	}

	public render() {

		return (
			<div>
				{ this.state.idleSessionTimeoutInSeconds > 0 && <IdleTimer
					ref={ref => { this.idleTimer = ref }}
					element={document}
					onIdle={this.onIdle}
					debounce={250}
					timeout={1000 * 60 * (this.state.idleSessionTimeoutInSeconds / 60 - WarningVisibleMinutes)} /> }
				<GroupedReturnHeader
					headerInfo={this.props.headerInfo}
					match={this.props.match}
					history={this.props.history}
					controllerInfo={this.props.groupedReturnsState.controllerInfoState.controllerInfo}
					updateMobileNumber={this.props.updateControllerMobileNumber}
					validateTimeBasedAccessCode={this.props.validateTimeBasedAccessCode}
					getIceServers={this.props.getIceServers}
					iceServers={this.props.iceServers}
					logout={this.props.logout}
					getWebRTCSignalRHubBaseURL={this.props.getWebRTCSignalRHubBaseURL}
					webRTCSignalRHubBaseURL={this.props.webRTCSignalRHubBaseURL}
					isPreviewMode={this.props.isPreviewMode}
					companyData={this.props.companyData}
				/>
				<div id="content-wrapper" className="content-wrapper">
					<Notification />
					{this.props.children}
				</div>
				<Footer />
				<OverlayLoader />
				<Toaster />

				{ this.state.idleSessionTimeoutInSeconds > 0 && <SessionTimeout
					history={this.props.history}
					match={this.props.match}
					showModal={this.state.showTimeout}
					onStayAlive={this.onStayAlive}
					countDownMinutes={WarningVisibleMinutes}
					loggedInUserInfo={this.props.headerInfo.loggedInUserInfo}
					logout={this.props.logout}
				/> }
			</div>
		);
	}

	private onIdle = (e: any) => {
		let params: any = this.props.match.params;
		let controllerId: any = params.clientId;

		if (controllerId && (localStorage.getItem(generateControllerSessionId(controllerId)) === null || (localStorage.getItem(generateControllerSessionId(controllerId)) === "0"))) {
			this.setState({ showTimeout: true });
		}

	}


	private onStayAlive = (e: React.SyntheticEvent<EventTarget>) => {
		e.preventDefault();
		let param: any = this.props.match.params;
		this.props.refreshToken(param.clientId, this.onCountDownComplete);
		this.setState({ showTimeout: false });
	}

	private renewSession = () => {
		if (!this.state.showTimeout) {
			let param: any = this.props.match.params;
			this.props.refreshToken(param.clientId, this.onCountDownComplete);
		}
	}
	onLogOutCompletion = (id: string) => {
		let param: any = this.props.match.params;
		const url = param.controllerId ? PathConstants.ControllerLogin + id : PathConstants.ClientLogin + id;
		this.props.history.push(url);
	}
	private onCountDownComplete = () => {
		if (this.props.headerInfo.loggedInUserInfo.role.toString() === Role[Role.CPA].toString()) {
			window.close();
		} else {

			let param: any = this.props.match.params;

			const message: ILogoutMessage = {
				isLoggedOut: true,
				clientGuid: param.controllerId ? param.controllerId : param.clientId,
			};

			channel.postMessage(message).then((value: any) => {
				this.props.logout(param.controllerId ? param.controllerId : param.clientId, this.onLogOutCompletion);
			}).catch((error: any) => {
				console.log(error);
			});
		}
	}

}
