import * as React from 'react';
import { DropzoneComponent } from 'react-dropzone-component';
import { useEffect } from 'react';
import { IFileUploadUtilities } from '../../../core/utilities/FileUpload/FileUploadUtilities';
import { container } from '../../../startup/inversify.config';
import { TYPES } from '../../../startup/types';

interface FileUploadProps {
    id: string;
    cssClass?: string;
    onAddFiles?: (files: any) => void;
}


export enum UploadStatus {
    Wait = 0,
    Initiating = 1,
    Uploading = 2,
    Uploaded = 3,
    Error = 4,
    Converting = 5
}

const fileUploadUtilities = container.get<IFileUploadUtilities>(TYPES.IFileUploadUtilities);

export const FileUpload: React.FunctionComponent<FileUploadProps> = ({
    id, cssClass, onAddFiles, children
}) => {

    useEffect(() => {
        const dropzoneInput = document.getElementsByClassName("dz-hidden-input");
        if (dropzoneInput?.length > 0) {
            dropzoneInput[0].setAttribute("aria-label", "Select files");
        }
    }, [children]);

    const componentConfig: any = {
        dropzoneSelector: 'div.filepicker',
        iconFiletypes: ['.pdf', '.jpg', '.png', '.jpeg','.xls','.xlsx','.doc','.docx'],
        showFiletypeIcon: true,
        postUrl: "/api/"
    };

    const djsConfig: any = {
        uploadMultiple: true,
        addRemoveLinks: true,
        acceptedFiles: ".pdf,.jpg,.png,.jpeg,.xls,.xlsx,.doc,.docx",
        headers: { 'Access-Control-Allow-Origin': '*', "x-ms-blob-type": "BlockBlob" },
        previewsContainer: false,
        autoProcessQueue: false,
        clickable: '.dz-open-file-browse'
    }

    const eventHandlers: any = {

        init: () => {

        },
    
        addedfiles: (files: any) => {
            onAddFiles && onAddFiles(files)
        }
    }


    useEffect(() => {
        return () => {
            removeDragEvents();
        }
    }, []);


    useEffect(() => {
        registerDragEvents();
    }, []);


    let registerDragEvents = () => {
        let dropArea = document.getElementById('app');

        if (dropArea) {

            ;['dragenter', 'dragover'].forEach(eventName => {
                dropArea && dropArea.addEventListener(eventName, handleDragStart, false);
            });

            ;['dragleave', 'drop'].forEach(eventName => {
                dropArea && dropArea.addEventListener(eventName, handleDragEnd, false);
            });
        }
    }

    let removeDragEvents = () => {
        let dropArea = document.getElementById('app');

        if (dropArea) {

            ;['dragenter', 'dragover'].forEach(eventName => {
                dropArea && dropArea.removeEventListener(eventName, handleDragStart, true);
            });

            ;['dragleave', 'drop'].forEach(eventName => {
                dropArea && dropArea.removeEventListener(eventName, handleDragEnd, true);
            });
        }
    }

    let handleDragStart = (event: any) => {
        if (fileUploadUtilities.isFileExist(event)) {
            let droppableElement: any = document.getElementById(id);
            droppableElement && droppableElement.classList.add("drop-area-highlight");
        }
    }
    
    let handleDragEnd = (event: any) => {
        let droppableElement: any = document.getElementById(id);
        droppableElement && droppableElement.classList.remove("drop-area-highlight");
    }

    return (<div id={id} className={"filepicker " + cssClass} data-test-auto="5B693160-DB6A-485D-BE60-C58920DAF5E6">
        {children}
        <DropzoneComponent config={componentConfig}
            eventHandlers={eventHandlers}
            djsConfig={djsConfig}>
        </DropzoneComponent>
    </div>);

};




