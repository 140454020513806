import * as React from 'react';
var htmlencode = require('htmlencode');
import { IHeaderInfoViewModel } from '../../core/domain/viewModels/IHeaderInfoViewModel';
import { SvgIconPrint } from '../Common/Icons/SvgIcons';
import { IDelegateeInfo } from '../../core/domain/models/IDelegateeSignerModel';
import { Col } from 'react-bootstrap';
import { ButtonComponent } from 'cp-common-ui-components';

interface PrepareMessageProps {
    message: string;
    headerInfo: IHeaderInfoViewModel;
    delegateeInfo?: IDelegateeInfo;
    isDelegatee?: boolean;
}
export class PreparerMessage extends React.Component<PrepareMessageProps, {}> {

    printPreparerMessage = () => {
        const companyLogo: any = '<div><img src="' + this.props.headerInfo.companyLogoPath + '" width="150" height="50"></div><hr>';
        const printContent: any = document.getElementById("preparer_message");
        const WindowPrt: any = window.open('', '_blank', 'left=0,top=0,width=900,height=900,toolbar=0,scrollbars=0,status=0');
        if (WindowPrt) {
            WindowPrt.document?.write('<html><head>');
            WindowPrt.document?.write("<style>.preparerMessage { margin-top: 10px;border: 2px solid " + this.props.headerInfo.brandingSettings.coverPageSetting.bgColorCode +
            ";min-height:250px;padding: 20px;position:relative;font-weight: 500;} .preparerMessagePrint{display:none}" +
            ".preparerMessageContainer { margin-left: 20px; margin-right: 20px; padding-left: 20px; padding-right: 20px; }");
            WindowPrt.document?.write('</style></head><body>');
            WindowPrt.document?.write(companyLogo);
            WindowPrt.document?.write(printContent.innerHTML);
            WindowPrt.document?.write('</body></html>');
            WindowPrt.document?.close();
            WindowPrt.focus();
            WindowPrt.print();
        }
    }

    public render() {
        return (<div id="preparer_message" data-test-auto="5EDC5E02-3A04-4588-9B64-4B51DA8AE12D">
            <div className="preparerMessageContainer">
                <div className="row" data-test-auto="2EEFD3EF-647C-4BF7-9DF4-7F91D13F3CD5">
                    <Col style={{paddingLeft:'0px',marginBottom:'30px'}}>
                        <div style={{ width: "calc(100% - 26px)",fontSize: '14px', color:'#6B7075',fontWeight:600 }}
                            data-test-auto="16F217FB-E625-4417-A837-E9EE13BC27CF">
                            A MESSAGE FROM
                            <span style={{fontWeight:700, textTransform: 'uppercase'}}> {this.props.isDelegatee ? this.props.delegateeInfo?.taxClientName :
                                this.props.headerInfo.companyName}
                            </span>
                        </div>
                        <img src={this.props.headerInfo.companyLogoPath} hidden={true} alt='companyLogo' />
                    </Col>
                    <Col sm={3} style={{paddingRight:'0px'}}>
                        <ButtonComponent                         
                            onClick={this.printPreparerMessage.bind(this)} 
                            title='Print this message.'
                            variant={"outline-primary"}
                            size={"medium"}
                            buttonClassName={"preparerMessagePrint"}
                        >
                            <SvgIconPrint
                                width={17}
                                height={16}
                                data-test-auto="4BB44852-6962-4D6F-B507-3633DF0F2ECF"
                            />
                            <span style={{paddingLeft:'5px'}}>Print</span>
                        </ButtonComponent>
                    </Col>
                </div>
                <div className="row preparerMessage" data-test-auto="14A7D44C-1F5D-4110-95C4-ED64C6FA13E6">
                    <div dangerouslySetInnerHTML={{ __html: htmlencode.htmlDecode(this.props.message) }}></div>
                </div>
            </div>
        </div>);
    }
}
