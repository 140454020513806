import * as NotificationStore from '../store/Common/NotificationStore';
import * as AccountStore from '../store/Common/AccountStore';
import * as LoaderStore from '../store/Common/LoaderStore';
import * as HelperStore from '../store/Common/HelperStore';
import * as SummaryStore from '../store/SummaryStore';
import * as EsignStore from '../store/Common/EsignStore';
import * as PayStore from '../store/PayStore';
import { IHeaderInfoViewModel } from '../core/domain/viewModels/IHeaderInfoViewModel';
import { ISummaryViewModel } from '../core/domain/viewModels/ISummaryViewModel';
import { ICommonDataViewModel } from '../core/domain/viewModels/ICommonDataViewModel';
import { IDocumentSettings } from '../core/domain/models/IDocumentSettings';
import * as PreparerMessageStore from '../store/PreparerMessageStore';
import * as DocumentReviewStore from '../store/ReviewStore';
import { IDocumentReviewModel } from '../core/domain/viewModels/IDocumentReviewModel';
import { ISignatureSettingsModel } from '../core/domain/models/ISignatureSettingsModel';
import { IKBATransactionResponse } from '../core/domain/models/IKBA';
import * as TaxDocumentStore from '../store/Common/TaxDocumentStore';
import * as ManualSignStore from '../store/Sign/ManualSignStore';
import * as CompanyStore from '../store/Common/Company/CompanyStore';
import * as K1DistributeStore from '../store/K1/K1DistributeStore';
import * as DownloadableDocumentsStore from '../store/DownloadableDocumentsStore';
import * as ReviewStore from '../store/ReviewStore';
import { IDownloadableDocumentsViewModel } from '../core/domain/viewModels/IDownloadableDocumentsViewModel';
import * as SignProcessState from './SignProcess/SignProcessStore';
import { IPayScreenViewModel } from '../core/domain/viewModels/IPayScreenViewModel';
import { ITutorialInfo, initialTutorialInfo } from '../core/domain/models/ITutorialInfo';
import * as TutorialStore from './Tutorial/TutorialStore';
import * as CCRecipientStore from './CCRecipientStore';
import { ICCRecipientDownloadableDocuments, ICcRecipientDownloadModel } from '../core/domain/models/ICCRecipientModel';
import { IDelegateeInfo } from '../core/domain/models/IDelegateeSignerModel';
import * as DelegateeSignerStore from '../store/DelegateeSignerStore';
import * as DelegateeSignProcessStore from '../store/DelegateeSignProcessStore';
import { ISignedDocument } from '../core/domain/models/manualsign/SignedDocument';
import * as GroupedReturnDashboardStore from '../store/GroupedReturns/DashboardStore';
import * as GroupedReturnsStore from '../store/GroupedReturns/GroupedReturnsStore';
import * as PaymentStore from '../store/PaymentStore';
import { IPaymentTransactionModel } from '../core/domain/models/IPaymentTransactionModel';
import * as InvoicePaymentStore from '../store/InvoicePaymentStore';
import * as CoverPageReducer from "../store/CoverPage/CoverPageStore";
import * as SSNReducer from "../store/SSN/SSNStore";
import * as OTPPageReducer from "../store/OTP/OTPStore";
import * as HeaderReducer from "../store/HeaderStore";
import * as GroupCoverPageReducer from "../store/CoverPage/CoverPageStore_GR";
import * as GroupOTPReducer from "../store/OTP/OTPStore_GR";
import * as CCR_OTPReducer from "../store/CCRecipient/OTPStore";
import * as Delegatee_OTPReducer from "../store/Delegatee/OTPStore";
import * as ErrorReducer from "../store/Common/ErrorStore";
import * as PaperFileReturnStore from '../store/PaperFileReturn/PaperFileReturnStore';
import * as DocumentStatusStore from "../store/Common/DocumentStatusStore";
import * as TrialK1DistributeStore from '../store/K1/TrialK1DistributeStore';

// The top-level state object
export interface ApplicationState {
    notification: NotificationStore.NotificationState;
    esignData: EsignStore.EsignState;
    manualSignData: ManualSignStore.ManualSignState;
    companyData: CompanyStore.CompanyState;
    kbaData: IKBATransactionResponse;
    headerInfo: IHeaderInfoViewModel;
    groupHeaderInfo: IHeaderInfoViewModel;
    recipientHeaderInfo: IHeaderInfoViewModel;
    delegateeHeaderInfo: IHeaderInfoViewModel;
    loader: LoaderStore.IRequestStatusDictionary;
    commonData: ICommonDataViewModel;
    summaryData: ISummaryViewModel;
    documentSettings: IDocumentSettings;
    documentReviewModel: IDocumentReviewModel[];
    signatureSettingData: ISignatureSettingsModel;
    taxReturn: TaxDocumentStore.TaxDocumentState;
    k1Data: K1DistributeStore.K1State;
    paperFileReturnData: PaperFileReturnStore.PaperFileReturnState;
	downloadableDocuments: IDownloadableDocumentsViewModel;
    signProcessData: SignProcessState.SignProcessState;
    payScreenData: IPayScreenViewModel;
    previewDocument: ReviewStore.IPreviewState;
    tutorialInfo: ITutorialInfo;
    ccRecipientDownloadableDocuments: ICCRecipientDownloadableDocuments[];
    ccDocumentsForDownload: ICcRecipientDownloadModel[];
    delegateeSigner: IDelegateeInfo;
    delegateeReviewModel: IDocumentReviewModel[];
    delegateeSignatureControlsData: EsignStore.EsignState;
    delegateeManualSignData: ISignedDocument[];
    delegateeDownloadableDocuments: ICCRecipientDownloadableDocuments[];
    groupedReturnDashboardData: GroupedReturnDashboardStore.ControllerDashboardState;
    groupedReturnsData: GroupedReturnsStore.GroupedReturnsState
    delegateeKBAData: IKBATransactionResponse;
    paymentData: PaymentStore.PaymentTransactionState;
    invoicePaymentData: InvoicePaymentStore.InvoicePaymentState;
    CoverPageReducer: CoverPageReducer.IClientCoverState,
    SSNPageReducer: SSNReducer.ISSNAuthModel,
    OTPPageReducer: OTPPageReducer.IOTPPage,
    HeaderPageReducer: HeaderReducer.IBaseViewModel,
    GroupCoverReducer: GroupCoverPageReducer.IClientCoverState,
    GroupOTPReducer: GroupOTPReducer.IOTPPage,
    CCRecipientOTPData: CCR_OTPReducer.ICCR_OTPPage;
    DelegateeOTPData: Delegatee_OTPReducer.IDelegateeOTPPage;
    ErrorState: ErrorReducer.ErrorState,
    DocumentStatusState: DocumentStatusStore.IDocumentStatusState,
    trialK1Data: TrialK1DistributeStore.TrialK1State
}

export const reducers = {
    notification: NotificationStore.reducer,
    esignData: EsignStore.reducer,
    companyData: CompanyStore.reducer,
    manualSignData: ManualSignStore.reducer,
    kbaData: EsignStore.kbaReducer,
    headerInfo: AccountStore.reducer,
    groupHeaderInfo: AccountStore.groupHeaderInfoReducer,
    recipientHeaderInfo: AccountStore.recipientHeaderInfoReducer,
    delegateeHeaderInfo: AccountStore.delegateeHeaderInfoReducer,
    loader: LoaderStore.reducer,
    commonData: HelperStore.reducer,
    summaryData: SummaryStore.reducer,
    documentSettings: PreparerMessageStore.reducer,
    documentReviewModel: DocumentReviewStore.reducer,
    taxReturn: TaxDocumentStore.reducer,
    k1Data: K1DistributeStore.reducer,
    paperFileReturnData: PaperFileReturnStore.reducer,
	downloadableDocuments: DownloadableDocumentsStore.reducer,
    signProcessData: SignProcessState.reducer,
    payScreenData: PayStore.reducer,
	previewDocument: ReviewStore.previewReducer,
    tutorialInfo: TutorialStore.reducer,
    ccRecipientDownloadableDocuments: CCRecipientStore.reducer,
    ccDocumentsForDownload: CCRecipientStore.downloadReducer,
    delegateeSigner: DelegateeSignerStore.delegateeInfoReducer,
    delegateeReviewModel: DelegateeSignerStore.delegateeReviewReducer,
    delegateeSignatureControlsData: DelegateeSignProcessStore.reducer,
    delegateeManualSignData: DelegateeSignProcessStore.delegateeManualSignDataReducer,
    delegateeDownloadableDocuments: DelegateeSignerStore.delegateeDownloadableDocReducer,
    groupedReturnDashboardData: GroupedReturnDashboardStore.reducer,
    groupedReturnsData: GroupedReturnsStore.reducer,
    delegateeKBAData: DelegateeSignProcessStore.delegateeKbaAuthReducer,
    paymentData: PaymentStore.reducer,
    invoicePaymentData: InvoicePaymentStore.reducer,
    CoverPageReducer: CoverPageReducer.reducer,
    SSNPageReducer: SSNReducer.reducer,
    OTPPageReducer: OTPPageReducer.reducer,
    HeaderPageReducer: HeaderReducer.reducer,
    GroupCoverReducer: GroupCoverPageReducer.reducer,
    GroupOTPReducer: GroupOTPReducer.reducer,
    CCRecipientOTPData: CCR_OTPReducer.reducer,
    DelegateeOTPData: Delegatee_OTPReducer.reducer,
    ErrorState: ErrorReducer.reducer,
    DocumentStatusState: DocumentStatusStore.reducer,
    trialK1Data: TrialK1DistributeStore.reducer,
};

export interface AppThunkAction<TAction> {
    (dispatch: (action: TAction | AppThunkAction<TAction>) => void, getState: () => ApplicationState): void;
}