import * as React from 'react';
import { ElementsConsumer } from '@stripe/react-stripe-js';
import { ITaxpayerPaymentSettings } from '../../core/domain/models/company/Company';
import { IPaymentTransactionModel } from '../../core/domain/models/IPaymentTransactionModel';
import { CheckoutForm } from './CheckoutForm';
import { Stripe, StripeElements } from '@stripe/stripe-js';

interface IPaymentProps {
    showState: boolean;
    onCancel(): void;
    invoiceAmount: number;
    paymentData: IPaymentTransactionModel;
    paymentSetting: ITaxpayerPaymentSettings;     
    clientId: string;
    updatePurchaseTransactionStatus: (
        clientId: string,
        paymentTransaction: IPaymentTransactionModel,
        callback?: () => void
    ) => void;
}

const InjectedCheckoutFormPage: React.FunctionComponent<IPaymentProps> = (
    { showState, onCancel, invoiceAmount, paymentData, paymentSetting, clientId, updatePurchaseTransactionStatus }) => {
    return (
        <ElementsConsumer>
            {({ elements, stripe }) => (
                <CheckoutForm
                    elements={elements as StripeElements}
                    stripe={stripe as Stripe}
                    showState={showState}
                    onCancel={onCancel}
                    invoiceAmount={invoiceAmount}
                    paymentData={paymentData}
                    paymentSetting={paymentSetting}
                    clientId={clientId}
                    updatePurchaseTransactionStatus={updatePurchaseTransactionStatus}
                />
            )}
        </ElementsConsumer>
    );
}

export default InjectedCheckoutFormPage;