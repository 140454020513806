import * as React from 'react';
import { CardNumberElement, CardExpiryElement, CardCvcElement, } from '@stripe/react-stripe-js';
import { StripeElements, StripeCardNumberElement, Stripe, StripeElement  } from '@stripe/stripe-js';
import { Modal } from 'react-bootstrap';
import { IUtilities } from '../../core/utilities/Utilities';
import { container } from '../../startup/inversify.config';
import { TYPES } from '../../startup/types';
import { ITaxpayerPaymentSettings } from '../../core/domain/models/company/Company';
import { IPaymentTransactionModel, initialPaymentTransactionModel } from '../..//core/domain/models/IPaymentTransactionModel';
import { MarsNotifier } from '../Common/Notification/MarsNotifier';
import { PayConstants } from '../../components/Common/Constants'; 
import { PaymentState } from '../../core/common/Enums';
import { ILoader } from '../../core/utilities/ui/Loader';
import { SvgIconCreditCard ,SvgIconLock ,CloseIcon,SvgIconInfo} from '../Common/Icons/SvgIcons';
import { ButtonComponent } from 'cp-common-ui-components';
import { handleKeyDown } from '../Helper/HelperFunction';

const utilities = container.get<IUtilities>(TYPES.IUtilities);
const loader = container.get<ILoader>(TYPES.ILoader);

interface IPaymentProps {
    showState: boolean;
    onCancel(): void;
    invoiceAmount: number;
    paymentData: IPaymentTransactionModel;
    paymentSetting: ITaxpayerPaymentSettings;
    stripe: Stripe;
    elements: StripeElements; 
    clientId: string; 
    updatePurchaseTransactionStatus: (
        clientId: string,
        paymentTransaction: IPaymentTransactionModel,
        callback?: () => void
    ) => void;
}

export class CheckoutForm extends React.Component<IPaymentProps, any>{
    constructor(props: any) {
        super(props);
        this.state = {
            name: ''
        }
    }

    onPay = async (event: any) => {
        event.preventDefault();
        loader.show();
        const { stripe, elements } = this.props;
        if (!stripe || !elements) {
            return;
        }   
        
        const card: StripeCardNumberElement = elements.getElement(CardNumberElement) as StripeCardNumberElement;
         
        const payload = await stripe.confirmCardPayment(
            this.props.paymentData.clientSecret
            , {
                payment_method: {
                    card: card
                }
            }
        );

        if (payload.error) {
            const error: any = payload.error != null ? payload.error.message : PayConstants.paymentFailure;
            MarsNotifier.Warning(error, null);
            loader.hide();
        } else {
            let purchaseModel: IPaymentTransactionModel = initialPaymentTransactionModel;
            purchaseModel.status = PaymentState.Success;
            purchaseModel.transactionId = this.props.paymentData.transactionId;
            purchaseModel.paymentId = this.props.paymentData.paymentId;
            this.props.updatePurchaseTransactionStatus(this.props.clientId, purchaseModel, () => this.onPopupCancel());            
        }        
    }

    onPopupCancel = () => {
        this.props.onCancel();
    }

    onNameChange = (event: any) => {
        this.setState({
            name: event.target.value
        })
    }

    public render() {
        return (
            <Modal className='checkoutForm' show={this.props.showState} onHide={this.props.onCancel} backdrop="static">
                <Modal.Header>
                    <Modal.Title style={{fontWeight:'700',fontSize:'20px',lineHeight:'45px'}}>
                        Invoice Payment
                    <div
                        className="closeIcon"
                        onClick={this.props.onCancel}
                        tabIndex={0}
                        onKeyDown={(e: any) => handleKeyDown(e, this.props.onCancel)}
                    >
                        <CloseIcon />
                    </div>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="stripe-wrapper">
                        <div className="stripe-popup">
                            <label className="invoice-amount-label" data-test-auto="30E5239B-D95C-46BA-988C-EF9AB0F1EB90">
                               Payment Amount 
                                <div className="invoice-amount">
                                 ${utilities.formatCurrencyText(this.props.invoiceAmount)}
                                 </div>
                            </label>
                            <label className="stripe-label" data-test-auto="B503EE92-D1C9-4C6D-9385-10F3FC4369CD" style={{width:'350px',position:'relative'}}>
                                Card number
                                <CardNumberElement />
                                <SvgIconCreditCard className='creditCardIcon'/>
                            </label>
                            <label htmlFor='checkoutFormName' className="stripe-label" data-test-auto="31443AA4-FF90-423A-ABAF-2A44F354303D">
                                Name on card
                                 <input type='text'
                                    id='checkoutFormName'
                                    onChange={this.onNameChange}
                                    className='StripeElement'
                                    value={this.state.name}
                                    placeholder="Name on card"
                                    data-test-auto="BD209198-337B-4C33-9403-22E91215A9F1"
                                    style={{width:'350px',height:'30.4px',outline: 'none'}}>
                                </input>
                            </label>
                            <span style={{display:'flex',gap:'25px'}}>
                            <label className="stripe-label" data-test-auto="6301EC97-E7BC-4DE9-B1C7-31CCD8FC159B" style={{ width: '200px' }}>
                                Expiration date
                                <CardExpiryElement />
                            </label>
                            <label className="stripe-label" data-test-auto="26F019C1-CFE0-4889-9B8C-47BA2C8AA91F"style={{ width: '125px' }}>
                                <span>
                                    CVC
                                    <SvgIconInfo className='infoIcon'/>
                                </span>
                                <CardCvcElement />
                            </label>
                            </span>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <ButtonComponent
                        data-test-auto="0B6E1426-06D2-4B10-B332-629B9A16409C"
                        onClick={this.props.onCancel}
                        variant={"outline-tertiary"}
                        size={"medium"}
                        buttonClassName={"button-margin-right"}
                    >
                        Cancel
                    </ButtonComponent>
                    <ButtonComponent
                        data-test-auto="EEF8CF34-013E-4950-AA0D-817041CB7DFF"
                        onClick={this.onPay}
                        variant={"primary"}
                        size={"medium"}
                        disabled={!this.props.stripe}
                        style={{ width:'150px', marginLeft:'14px' }}
                    >
                        <span>
                            <SvgIconLock />
                            <span style={{ marginLeft: '8px' }}>
                                Pay ${utilities.formatCurrencyText(this.props.invoiceAmount)}
                            </span>
                        </span>
                    </ButtonComponent>
                </Modal.Footer>
            </Modal >
        )

    }
} 