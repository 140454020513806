import { ApplicationState } from "../../store/index";
import { actionCreators as accountAction } from '../../store/Common/AccountStore';
import { actionCreators as helperAction } from '../../store/Common/HelperStore';
import { actionCreators as groupedReturnsAction } from '../../store/GroupedReturns/GroupedReturnsStore';
import { GroupedReturnDefaultLayout } from "./GroupedReturnDefaultLayout";
import { connect } from 'react-redux';
import { createLoadingSelector } from '../../store/selectors';
import { withRouter } from "react-router";
import { ClientType } from '../../core/common/Enums';
import { actionCreators as companyAction } from '../../store/Common/Company/CompanyStore';
import { IAccessCodeValidation } from "../../core/domain/viewModels/ICommon";
import TaxpayerHelper from '../Helper/TaxpayerHelper';
const loadingSelector = createLoadingSelector(['HEADERINFO', 'TAXING_AUTHORITY']);


function mapStateToProps(state: ApplicationState) {
    return {
        headerInfo: state.groupHeaderInfo,
        loading: loadingSelector(state),
        groupedReturnsState: state.groupedReturnsData,
        iceServers: state.commonData.iceServers,
        isPreviewMode: TaxpayerHelper.isPreviewMode(state.signProcessData.clientprocessmodel),
        webRTCSignalRHubBaseURL: state.commonData.webRTCSignalRHubBaseURL,
        companyData: state.companyData.data
    };
}

const mapDispatchToProps = (dispatch: any) => {
    return {
        requestHeaderInfo: (id: string) => dispatch(accountAction.requestHeaderInfo(id)),
        refreshToken: (clientId: string, failurecallback: () => void) => dispatch(accountAction.refreshToken(clientId, failurecallback)),
        requestControllerInfo: (id: string) => dispatch(groupedReturnsAction.requestControllerInfo(id)),
        requestAllTaxingAuthorities: (id: string) => dispatch(helperAction.requestAllTaxingAuthorities(id)),
        getSessionTimeOutSeconds: (clientId: string, callBack?: (sessionTimeout: number) =>void) => dispatch(accountAction.getSessionTimeOutSeconds(clientId, callBack)),
        updateMobileNumber: (clientGuid: string, id: string, number: string, countryCodeValue: string,
            ssnValue: string, clientType: ClientType, callback: () => void) =>
            dispatch(accountAction.updateMobileNumber(clientGuid, id, number, countryCodeValue, ssnValue, clientType, callback)),
        updateControllerMobileNumber: (clientGuid: string, number: string, countryCodeValue: string, callback: () => void) =>
            dispatch(groupedReturnsAction.updateControllerMobileNumber(clientGuid, number, countryCodeValue, callback)),
        validateTimeBasedAccessCode: (accessCode: IAccessCodeValidation, callback: () => void) => dispatch(helperAction.validateTimeBasedAccessCode(accessCode, callback)),
        getIceServers: (clientId: string) => dispatch(helperAction.getIceServers(clientId)),
        getWalkMeScript: (clientId: string, callback: (script: string) => void) => dispatch(helperAction.getWalkMeScript(clientId, callback)),
        getWebRTCSignalRHubBaseURL: (clientId: string, callback: (script: string) => void) => dispatch(helperAction.getWebRTCSignalRHubBaseURL(clientId, callback)),
        requestRecipientHeaderInfo: (id: string) => dispatch(accountAction.requestRecipientHeaderInfo(id)),
        requestGroupHeaderInfo: (id: string) => dispatch(accountAction.requestGroupHeaderInfo(id)),
        requestDelegateeHeaderInfo: (id: string) => dispatch(accountAction.requestDelegateeHeaderInfo(id)),
        logout: (clientGuid: string, callback: (id: string) => void) => dispatch(accountAction.logout(clientGuid, callback)),
        requestCompanyDetails: (id: string) => dispatch(companyAction.requestCompanyDetails(id)),
        dispatch,
    }
}

const connector = connect(mapStateToProps, mapDispatchToProps);

export default withRouter(connector(GroupedReturnDefaultLayout));

