import { Action, Reducer } from 'redux';
import { actionTypes } from '../ActionTypes';
import { ILoader } from '../../core/utilities/ui/Loader';
import { TYPES } from '../../startup/types';
import { container } from '../../startup/inversify.config';


export interface ErrorState {
    message: string;
}

export interface ErrorAction {
    type: actionTypes.SET_ERROR_MSG;
     data:string
}

type KnownAction = ErrorAction;

let initialErrorState: ErrorState = { message: "" };

const loader = container.get<ILoader>(TYPES.ILoader);

export const reducer: Reducer<ErrorState> = (state: ErrorState = initialErrorState, incomingAction: Action) => {
    const action = incomingAction as KnownAction;
    switch (action.type) {
        case actionTypes.SET_ERROR_MSG:
            return {
                message: action.data
            } as ErrorState;

        default:
            return state || initialErrorState;
    }
}
