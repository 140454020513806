
import { connect } from 'react-redux';
import MobileOTP from "./MobileOTP";
import * as HeaderStore from "../../../store/HeaderStore";
import * as OTPReducer from "../../../store/Delegatee/OTPStore";



export default connect(
    (state: any) => ({
        headerInfoState: state.HeaderPageReducer,
        OTPPageState: state.DelegateeOTPData   

    }),
    {
        ...HeaderStore.actionCreators,
        ...OTPReducer.actionCreators
        
    }
)(MobileOTP as any);