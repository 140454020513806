import * as React from "react";
import { Shimmer } from "../../components/Common/Shimmer/Shimmer";
import {
	ClientType,
	SignatureStatus,
	SignatureType,
	DocumentStatus,
	Role,
	PaymentState,
	EngagementType,
	RedirectionSourcePage,
} from "../../../src/core/common/Enums";
import {
	FormType,
	isPartnership,
} from "../../../src/core/domain/models/ITaxReturn";
import * as TaxDocument from "../../core/domain/models/ITaxReturn";
import { History } from "history";
import { ISignerModel } from "../../../src/core/domain/models/ISignerModel";
import { WelcomeMessage } from "./Parts/WelcomeMessage";
import { NextSignerAlert } from "./Parts/NextSignerAlert";
import {
	CompletedSummaryConstant,
	getRoutePathUrl,
	DelegateeSignerConstants,
	DownloadDocumentsConstants,
} from "../Common/Constants";
import { PathConstants, Common, SiginingConstants } from "../Common/Constants";
import { ISignedDocument } from "../../../src/core/domain/models/manualsign/SignedDocument";
import { IAdditionalEsignDocument } from "../../../src/core/domain/models/IAdditionalEsignDocument";
import { IDownloadableDocumentsViewModel } from "../../../src/core/domain/viewModels/IDownloadableDocumentsViewModel";
import TaxpayerHelper from "../Helper/TaxpayerHelper";
import {
	IDelegateeSignerDetails,
	IDelegateeInfo,
} from "../../core/domain/models/IDelegateeSignerModel";
import { AssignToDelegatee } from "../Delegatee/AssignToDelegatee";
import { MarsNotifier } from "../Common/Notification/MarsNotifier";
import { PaymentInformationPopup } from "../Pay/PaymentInformationPopup";
import {
	initialTaxingAuthority,
	ITaxingAuthority,
} from "../../core/domain/models/ITaxingAuthority";
import { DocumentGroups } from "../../core/domain/models/IGroup";
import { IPaymentTransactionModel } from "../../core/domain/models/IPaymentTransactionModel";
import { InvoiceDetailsPopup } from "../Pay/InvoiceDetailsPopup";
import {
	ICompany,
	PaymentOption,
} from "../../../src/core/domain/models/company/Company";
import { StripePayment } from "../Invoice/StripePayment";
import { IInvoicePayment } from "../../core/domain/models/InvoicePaymentModel";
import { IDialogBox } from "../../core/utilities/ui/DialogBox";
import { container } from "../../startup/inversify.config";
import { TYPES } from "../../startup/types";
import { handleKeyDown, isInvoicePaymentSuccess } from "../Helper/HelperFunction";
import { IClientProcessViewModel } from "../../core/domain/viewModels/IClientProcessViewModel";
import { SignProcessSteps } from "../Home/TaxpayerSignFlowManager";
import SuiteModal from "../Common/SuiteModal";
import ConsentPopUp from "../ConsentPopUp";
import {
	getConsentDetails,
	updateConsentDetails,
} from "../../store/SMSService/SMSService.api";
import {
	ISMSGETResponse,
	ISMSPUTRequest,
} from "../../store/SMSService/SMSService.model";
import { TileComponent } from "cp-common-ui-components";
import { ButtonComponent } from "cp-common-ui-components";

export interface CompletedWelcomePageProps {
	taxYear: number;
	param: any;
	clientName: string;
	hasShareholders: boolean;
	hasPaperFiles: boolean;
	clientType: ClientType;
	taxReturn: any;
	refreshTaxDocument: (
		clientId: string,
		callback?: (data?: any) => void
	) => any;
	sendSigningReminder: (clientId: string) => any;
	history: History;
	downloadSignedEFileDocument: (clientId: string, filename: string) => any;
	signedDocument: ISignedDocument[];
	downloadAllAdditionEsignDocuments: (
		clientId: string,
		filename: string
	) => any;
	downloadableDocumentsViewModel: IDownloadableDocumentsViewModel;
	downloadEFileDocument: (clientId: string, filename: string) => any;
	role: Role;
	isPreviewMode: boolean;
	updateDelegateeSignerDetails: (
		clientId: string,
		delegatee: IDelegateeSignerDetails,
		callback: () => void
	) => void;
	isDelegateeAssigned: boolean;
	isSignCompleted: boolean;
	cancelDelegation: (
		clientId: string,
		remarks: string,
		taxYear: number,
		callback: () => void
	) => void;
	resetLastVisitedSteps: (clientId: string, callback: () => void) => void;
	delegateeSigner: IDelegateeInfo;
	taxingAuthorities: ITaxingAuthority[];
	downloadTaxInvoice: (clientId: string) => void;
	invoicePaymentUrl?: string;
	paymentData: IPaymentTransactionModel;
	companyData: ICompany;
	stepInfo: IClientProcessViewModel;
	requestPaymentIntent: (
		clientId: string,
		amount: number,
		callback: () => void
	) => void;
	updatePurchaseTransactionStatus: (
		clientId: string,
		paymentTransaction: IPaymentTransactionModel,
		callback?: () => void
	) => void;
	invoicePaymentData: IInvoicePayment;
	saveInvoicePayment: (invoice: IInvoicePayment, callback?: () => void) => void;
	clearAllInvoicePayment: (callback?: () => void) => void;
	getClientHubDomainURL: (clientGuid: string, callback: (hubDomainURL: string) => void) => void;
}

export interface CompletedWelcomePageState {
	showPaymentInfoPopup: boolean;
	showInvoicePopup: boolean;
	showStripePaymentPopup: boolean;
	showModal: boolean;
	showConsentPopUp: boolean;
	countryCode: string;
	mobileNumber: string;
	clientId: string;
	clientType: number;
	initialCountryCode: string;
	initialMobileNumber: string;
	isAccessedFromOnehub: boolean;
}

const dialogBox = container.get<IDialogBox>(TYPES.IDialogBox);

export class CompletedWelcomePage extends React.Component<
	CompletedWelcomePageProps,
	CompletedWelcomePageState
> {
	constructor(props: CompletedWelcomePageProps) {
		super(props);
		this.state = {
			showInvoicePopup: false,
			showStripePaymentPopup: false,
			showModal: false,
			showPaymentInfoPopup: false,
			showConsentPopUp: false,
			countryCode: "+1",
			mobileNumber: "",
			clientId: "",
			clientType: 0,
			initialCountryCode: "",
			initialMobileNumber: "",
			isAccessedFromOnehub: false
		};
	}

	componentDidMount() {
		this.props.refreshTaxDocument(
			this.props.param.clientId,
			(taxReturnReceived: TaxDocument.IMarriedJointTaxReturn) => {
				let countryCode = "";
				let mobileNumber = "";
				let clientId = taxReturnReceived.clientId;
				let clientType = 0;
				if (
					taxReturnReceived.spouse?.clientGuid === this.props.param.clientId
				) {
					clientType = 2;
					countryCode = taxReturnReceived.spouse?.countryCode;
					mobileNumber = taxReturnReceived.spouse?.mobileNumber;
				} else {
					clientType = 1;
					countryCode = isPartnership(taxReturnReceived)
						? taxReturnReceived.partnership?.countryCode
						: taxReturnReceived.taxpayer?.countryCode;
					mobileNumber = isPartnership(taxReturnReceived)
						? taxReturnReceived.partnership?.mobileNumber
						: taxReturnReceived.taxpayer?.mobileNumber;
				}
				this.setState({ clientId: clientId, clientType: clientType });
				getConsentDetails(
					this.props.param.clientId,
					clientId,
					clientType,
					mobileNumber,
					(consentResponse: ISMSGETResponse) => {
						if (!countryCode || countryCode.length === 0) {
							countryCode = consentResponse.countryCode ?? "+1";
						}
						if (!mobileNumber || mobileNumber.length === 0) {
							mobileNumber = consentResponse.mobileNumber ?? "";
						}
						this.setState({
							showConsentPopUp: consentResponse.isConsent,
							countryCode: countryCode,
							mobileNumber: mobileNumber,
							initialCountryCode: countryCode,
							initialMobileNumber: mobileNumber,
						});
					}
				);
			}
		);
	}

	componentDidUpdate(prevProps: Readonly<CompletedWelcomePageProps>, prevState: Readonly<CompletedWelcomePageState>, snapshot?: any): void {
		if (prevState.isAccessedFromOnehub !== this.props.stepInfo.isAccessedFromOneHub) {
			this.setState({ isAccessedFromOnehub: this.props.stepInfo.isAccessedFromOneHub });
		}
	}

	redirectToOneHub = () => {
		this.props.getClientHubDomainURL(this.props.stepInfo.currentstep.clientId, (oneHubDomainURL: string) => {
			window.location.href = oneHubDomainURL;
		})
	}

	downloadDocument = () => {
		const param = this.props.param;
		this.props.history.push(
			getRoutePathUrl(
				PathConstants.Download,
				param.clientId,
				param.controllerId
			)
		);
	};

	viewDocument = () => {
		const param = this.props.param;
		this.props.history.push(
			getRoutePathUrl(PathConstants.Review, param.clientId, param.controllerId)
		);
	};

	viewDocumentDocument = () => {
		this.props.history.push(
			`${PathConstants.Download}${this.props.param.clientId}`
		);
	};

	getSignedEfileName = () => {
		let fileNamePrefix = this.getFileNamePrefix();
		let fileName = "";
		if (this.props.signedDocument && this.props.signedDocument.length == 1) {
			if (
				TaxpayerHelper.isMarried(this.props.taxReturn) &&
				this.props.taxReturn.signatureStatus ==
				SignatureStatus[SignatureStatus.PartiallySigned]
			)
				fileName = "_Signature Document.pdf";
			else fileName = "_Signed Document.pdf";
		} else if (
			this.props.signedDocument &&
			this.props.signedDocument.length > 1
		) {
			if (
				TaxpayerHelper.isMarried(this.props.taxReturn) &&
				this.props.taxReturn.signatureStatus ==
				SignatureStatus[SignatureStatus.PartiallySigned]
			)
				fileName = "_Signature Documents.zip";
			else fileName = "_Signed Documents.zip";
		}
		return fileNamePrefix + fileName;
	};

	getFileNamePrefix = () => {
		return (
			this.props.clientName +
			"_" +
			this.props.taxYear +
			"_" +
			this.props.taxReturn.engagementType.toString().substr(1)
		);
	};

	getOtherSignedDocumentFileName = () => {
		if (
			TaxpayerHelper.isMarried(this.props.taxReturn) &&
			this.props.taxReturn.signatureStatus ==
			SignatureStatus[SignatureStatus.PartiallySigned]
		)
			return this.getFileNamePrefix() + "_Other Signature Documents.zip";
		else return this.getFileNamePrefix() + "_Other Signed Documents.zip";
	};

	downloadSignedDocument = () => {
		const _self = this;
		const additionalEsignDocuments: IAdditionalEsignDocument[] =
			this.props.downloadableDocumentsViewModel &&
			this.props.downloadableDocumentsViewModel.additionalEsignDocuments;
		const efile =
			this.props.downloadableDocumentsViewModel &&
			this.props.downloadableDocumentsViewModel.downloadableDocuments.find(
				(x) => x.groupId === FormType.EFile
			);

		if (
			this.props.taxReturn.documentSettings.documentSignatureSetting
				.signatureFormSelectionType === SignatureType.ESign ||
			this.props.taxReturn.documentSettings.documentSignatureSetting
				.signatureFormSelectionType ===
			SignatureType.ESignWhenAllIncludedReturnsAllowed
		) {
			if (efile && efile.documentId > 0) {
				if (!this.props.isPreviewMode) {
					this.props.taxReturn.documentStatus.toString() ===
						DocumentStatus[DocumentStatus.USERSIGNED]
						? this.props.downloadSignedEFileDocument(
							this.props.param.clientId,
							this.getSignedEfileName()
						)
						: this.props.downloadEFileDocument(
							this.props.param.clientId,
							this.getSignedEfileName()
						);
				} else {
					MarsNotifier.Warning(
						DownloadDocumentsConstants.DownloadNotAllowed,
						null
					);
					return;
				}
			}

			setTimeout(function () {
				additionalEsignDocuments &&
					additionalEsignDocuments.length > 0 &&
					_self.props.downloadAllAdditionEsignDocuments(
						_self.props.param.clientId,
						_self.getOtherSignedDocumentFileName()
					);
			}, 2000);
		} else {
			const param = this.props.param;
			if (
				this.props.taxReturn.signatureStatus ===
				SignatureStatus[SignatureStatus.ManuallySigned] &&
				!param.controllerId
			) {
				dialogBox.confirm(
					Common.DialogBox.ConfirmationTitle,
					SiginingConstants.StatusMessage
						.ManualSignReviewDocumentsRedirectConfirmationMessage,
					Common.DialogBox.Cancel,
					Common.Wizard.NextButtonLabel,
					(result: boolean) => {
						result &&
							this.props.history.push(
								`${PathConstants.ManualSignReviewDocuments}${param.clientId}`
							);
					}
				);
			} else {
				this.props.history.push(
					getRoutePathUrl(
						PathConstants.ManualSign,
						param.clientId,
						param.controllerId
					)
				);
			}
		}
	};

	onMakeTaxPayments = () => {
		this.props.history.push(
			`${PathConstants.Pay}${this.props.param.controllerId ? this.props.param.controllerId + "/" : ""
			}${this.props.param.clientId}`
		);
	};

	printFiledReturn = () => {
		//routing logic foe print filed return
	};

	payTaxPrepFee = () => {
		this.setState({ showInvoicePopup: true });
	};

	private onPaymentInfoCancel = () => {
		this.setState({
			showPaymentInfoPopup: false,
		});
	};

	ondistributeK1 = () => {
		const param = this.props.param;
		this.props.history.push(
			getRoutePathUrl(
				PathConstants.DistributeK1,
				param.clientId,
				param.controllerId
			)
		);
	};

	onPaperFileReturn = () => {
		const param = this.props.param;
		this.props.history.push(
			getRoutePathUrl(
				PathConstants.PaperFileReturn,
				param.clientId,
				param.controllerId
			)
		);
	};

	sendReminder = (clientId: string) => {
		if (this.props.role.toString() !== Role[Role.CPA].toString()) {
			this.props.sendSigningReminder(clientId);
		}
	};

	openDelegationPopUp = () => {
		this.setState({ showModal: true });
	};

	closeDelegationPopUp = () => {
		this.setState({ showModal: false });
	};

	checkIfPreviewRequest = (): boolean => {
		if (this.props.role.toString() === Role[Role.CPA].toString()) {
			return true;
		}
		return false;
	};

	private onInvoiceCancel = () => {
		this.setState({
			showInvoicePopup: false,
		});
	};

	private openStripePopup() {
		this.setState({
			showStripePaymentPopup: true,
			showInvoicePopup: false,
		});
	}

	onClosePaymentModal = () => {
		this.setState({
			showStripePaymentPopup: false,
		});
	};

	private onStripePaymentClick = () => {
		if (isInvoicePaymentSuccess(this.props.paymentData)) {
			window.open(this.props.paymentData.receiptPath);
			return;
		}

		if (
			this.props.companyData.taxpayerPaymentSettings.payOption ===
			PaymentOption.Stripe
		) {
			if (!this.props.paymentData.transactionId) {
				this.props.requestPaymentIntent(
					this.props.param.clientId,
					this.props.taxReturn.invoiceAmount,
					() => this.openStripePopup()
				);
			} else if (
				this.props.paymentData.transactionId &&
				this.props.paymentData.status !== PaymentState.Success
			) {
				this.props.requestPaymentIntent(
					this.props.param.clientId,
					this.props.taxReturn.invoiceAmount,
					() => this.openStripePopup()
				);
			} else if (
				this.props.paymentData.transactionId &&
				this.props.paymentData.status === PaymentState.Success
			) {
				window.open(this.props.paymentData.receiptPath);
			}
		} else if (
			this.props.companyData.taxpayerPaymentSettings.payOption ===
			PaymentOption.AttachedURL
		) {
			window.open(this.props.companyData.onlinePaymentUrl);
		} else {
			if (
				this.props.paymentData.transactionId &&
				this.props.paymentData.status === PaymentState.Success
			) {
				window.open(this.props.paymentData.receiptPath);
			}
		}
	};

	private saveInvoicePayment = (invoice: IInvoicePayment) => {
		this.props.saveInvoicePayment(invoice, this.onInvoiceCancel);
	};
	private clearAllInvoicePayment = () => {
		this.props.clearAllInvoicePayment(this.onInvoiceCancel);
	};

	private getPaperFileCaption = (isPreview: boolean): string => {
		if (isPreview) {
			return CompletedSummaryConstant.ImageCaption.ViewDownloadPaperFiledReturn;
		}
		let index = this.props.stepInfo.currentstep?.visitedSteps?.findIndex(
			(x) => x == SignProcessSteps.PaperFileListComplete
		);
		if (index === -1) {
			return CompletedSummaryConstant.ImageCaption.PrintandMailPaperFiledReturn;
		}
		return CompletedSummaryConstant.ImageCaption.ViewDownloadPaperFiledReturn;
	};

	private handleConsent = (
		consentGiven: boolean,
		countryCodeInput?: string,
		mobileNumberInput?: string
	) => {
		const consentDetails: ISMSPUTRequest = {
			clientId: this.state.clientId,
			clientGuid: this.props.param.clientId,
			clientType: this.state.clientType,
			countryCode: consentGiven
				? countryCodeInput
				: this.state.initialCountryCode,
			mobileNumber: consentGiven
				? mobileNumberInput
				: this.state.initialMobileNumber,
			isConsent: consentGiven,
		};
		updateConsentDetails(consentDetails, () => {
			this.setState({ showConsentPopUp: false });
			this.props.refreshTaxDocument(this.props.param.clientId);
		});
	};

	public render() {
		const isPreview: boolean = this.checkIfPreviewRequest();

		const isDownloadSignedDisable =
			(this.props.isDelegateeAssigned && !this.props.isSignCompleted) ||
				this.props.taxReturn.signatureStatus ===
				SignatureStatus[SignatureStatus.SignatureStampingFailed]
				? "disabledbutton"
				: "";

		let imgBtnDivClass = "col-xl-4 col-lg-6 col-md-6 col-sm-12 col-xs-12";
		const returnUnSigned =
			this.props.taxReturn.signedDetails &&
			this.props.taxReturn.signedDetails.filter(
				(x: ISignerModel) => x.isSigned === false
			);

		const spouseSigned = this.props.taxReturn.signedDetails.filter(
			(x: ISignerModel) =>
				x.clientGuid === this.props.param.clientId && x.order === 2
		);
		let nextSigerName = this.props.taxReturn?.spouse?.name;
		let reminderAlert = CompletedSummaryConstant.SpouseReminderAlertMessage;
		if (this.props.taxReturn.clientType === ClientType.Spouse) {
			nextSigerName = this.props.taxReturn?.taxpayer?.name;
			reminderAlert = CompletedSummaryConstant.TaxpayerReminderAlertMessage;
		}
		const isSignReminderEnable =
			this.props.taxReturn.signedDetails &&
			this.props.taxReturn.signedDetails.length > 1 &&
			this.props.taxReturn.signatureStatus ===
			SignatureStatus[SignatureStatus.PartiallySigned] &&
			returnUnSigned.length > 0 &&
			TaxpayerHelper.isFirstSignerLoggedIn(
				this.props.taxReturn,
				this.props.taxReturn.signedDetails,
				this.props.param.clientId
			) &&
			TaxpayerHelper.ifDelegateeExistsAndSigned(
				this.props.isDelegateeAssigned,
				this.props.isSignCompleted
			);

		const welcomeAlertMessage =
			(this.props.taxReturn.signedDetails &&
				this.props.taxReturn.signatureStatus ===
				SignatureStatus[SignatureStatus.ManuallySigned]) ||
				(this.props.taxReturn.signedDetails &&
					this.props.taxReturn.signatureStatus ===
					SignatureStatus[SignatureStatus.AwaitingUpload] &&
					this.props.taxReturn.documentStatus ===
					DocumentStatus[DocumentStatus.DELIVERED])
				? this.props.taxReturn.engagementType ===
					EngagementType[EngagementType.E1040] &&
					this.props.taxReturn.signedDetails.length === 2
					? CompletedSummaryConstant.ManualSignMessageForMFJ.replace(
						"<SIGNERNAME>",
						nextSigerName
					)
					: CompletedSummaryConstant.ManualSignMessage
				: returnUnSigned.length === 0 ||
					(this.props.taxReturn.signedDetails &&
						this.props.taxReturn.signedDetails.length < 2) ||
					(this.props.taxReturn.signedDetails && spouseSigned.length === 1)
					? CompletedSummaryConstant.CompletedMessage.replace(
						"<TAXYEAR>",
						this.props.taxYear.toString()
					)
					: TaxpayerHelper.isFirstSignerLoggedIn(
						this.props.taxReturn,
						this.props.taxReturn.signedDetails,
						this.props.param.clientId
					)
						? CompletedSummaryConstant.SecondSignerAlertMessage.replace(
							"<SIGNERNAME>",
							nextSigerName
						)
						: "";

		const invoiceIndex = this.props.taxReturn.formGroups.findIndex(
			(x: any) => x.group == DocumentGroups.Invoice
		);

		return (
			<div
				id="completedSummary"
				data-test-auto="568B4554-8CFD-4965-9F71-084E1365E564"
			>
				{this.props.clientName && this.props.taxReturn.signedDetails ? (
					<React.Fragment>
						{this.props.taxReturn.signedDetails &&
							this.props.taxReturn.signedDetails.length > 1 &&
							(this.props.taxReturn.signatureStatus ===
								SignatureStatus[SignatureStatus.AwaitingESign] ||
								this.props.taxReturn.signatureStatus ===
								SignatureStatus[SignatureStatus.PartiallySigned]) &&
							returnUnSigned.length > 0 &&
							TaxpayerHelper.isFirstSignerLoggedIn(
								this.props.taxReturn,
								this.props.taxReturn.signedDetails,
								this.props.param.clientId
							) &&
							TaxpayerHelper.ifDelegateeExistsAndSigned(
								this.props.isDelegateeAssigned,
								this.props.isSignCompleted
							) && (
								<div data-test-auto="A0809C33-629A-476A-8192-50B496BFFD45">
									<NextSignerAlert
										alertMessage={reminderAlert}
										icon={CompletedSummaryConstant.Images.InfoCircle}
										nextSignerName={nextSigerName}
										onclick={() => {
											this.sendReminder(this.props.param.clientId);
										}}
										clientId={this.props.param.clientId}
										isSignReminderEnable={isSignReminderEnable}
									/>
								</div>
							)}
						{this.props.taxReturn.signatureStatus !==
							SignatureStatus[SignatureStatus.SignatureStampingFailed] && (
								<div style={{ marginTop: "35px" }}>
									<WelcomeMessage message={welcomeAlertMessage} />
								</div>
							)}
						<div
							className="welcome-intro"
							data-test-auto="58C9B25A-1816-44A7-BF26-EE614AFC1C24"
						>
							{CompletedSummaryConstant.InstructionText}
						</div>
					</React.Fragment>
				) : (
					<Shimmer height={200} />
				)}

				{this.props.clientName && this.props.taxReturn.signedDetails ? (
					<div className="cards-container">
						<div className="step-img-btn cards-content">
							{this.props.taxReturn.formGroups.find(
								(x: any) => x.group == DocumentGroups.Vouchers
							) &&
								TaxpayerHelper.isVoucherExist(this.props.taxReturn) && (
									<div
										data-test-auto="9EC15877-B832-4107-AC19-47E29D43818A"
										className="cards"
										tabIndex={0}
										onKeyDown={(e: any) => handleKeyDown(e, this.onMakeTaxPayments)}
									>
										<TileComponent
											onClick={this.onMakeTaxPayments}
											tileContent={
												<CompletedSummaryConstant.Images.MakeTaxPayment
													data-test-auto="9A7A3F04-B153-4BB1-9005-A8DB5EA558E8"
													className="button---tax-payments"
												/>
											}
											tileDetail={CompletedSummaryConstant.ImageCaption.MakeTaxPayment}
										/>
									</div>
								)}

							{this.props.hasShareholders && (
								<div
									data-test-auto="C98F026C-89C1-4B33-AEDD-EDD130CAC2E1"
									className="cards"
									tabIndex={0}
									onKeyDown={(e: any) => handleKeyDown(e, this.ondistributeK1)}
								>
									<TileComponent
										onClick={this.ondistributeK1}
										tileContent={
											<CompletedSummaryConstant.Images.DistributeK1
												data-test-auto="CF02082B-BC13-408E-8D43-5851E93D4A99"
												className="button---Distribute-K1"
											/>
										}
										tileDetail={CompletedSummaryConstant.ImageCaption.DistributeK1}
									/>
								</div>
							)}

							<div
								data-test-auto="6C6403FF-9D2C-41AC-ADFE-1209675FE325"
								className="cards"
								tabIndex={0}
								onKeyDown={(e: any) => handleKeyDown(e, this.downloadDocument)}
							>
								<TileComponent
									onClick={this.downloadDocument}
									tileContent={
										<CompletedSummaryConstant.Images.DownloadDocument className="button---download-documents" />
									}
									tileDetail={CompletedSummaryConstant.ImageCaption.DownloadDocumnt}
								/>
							</div>

							<div
								data-test-auto="D2E648DE-CAC8-45F1-9263-B67AB0AF738C"
								className="cards"
								tabIndex={0}
								onKeyDown={(e: any) => handleKeyDown(e, this.viewDocument)}
							>
								<TileComponent
									onClick={this.viewDocument}
									tileContent={
										<CompletedSummaryConstant.Images.ViewDocument className="button---review-documents" />
									}
									tileDetail={
										isDownloadSignedDisable != "" ?
											CompletedSummaryConstant.ImageCaption.ViewReturn :
											CompletedSummaryConstant.ImageCaption.ViewForwardTaxDocuments
									}
								/>
							</div>

							<div
								title={
									this.props.taxReturn.signatureStatus ==
										SignatureStatus[SignatureStatus.SignatureStampingFailed]
										? CompletedSummaryConstant.SignatureStampingFailure
										: ""
								}
								data-test-auto="5CB48E27-DE28-4A04-B3ED-ACBFC4AE423E"
								className={`cards ${isDownloadSignedDisable}`}
								tabIndex={0}
								onKeyDown={(e: any) => {
									isDownloadSignedDisable?.length === 0 && handleKeyDown(e, this.downloadSignedDocument);
								}}
							>
								<TileComponent
									onClick={this.downloadSignedDocument}
									tileContent={
										<CompletedSummaryConstant.Images.ViewDownloadSignedDocument
											data-test-auto="E54CED95-F4D5-40DE-8EEE-0C3AF0A4A77E"
											className={"button---signed-documents"}
										/>
									}
									tileDetail={CompletedSummaryConstant.ImageCaption.ViewDownloadSignedDocument}
								/>
							</div>

							{this.props.hasPaperFiles && (
								<div
									data-test-auto="A82F026C-89C1-4B33-AEDD-EDD130CAC2E1"
									className="cards"
									tabIndex={0}
									onKeyDown={(e: any) => handleKeyDown(e, this.onPaperFileReturn)}
								>
									<TileComponent
										onClick={this.onPaperFileReturn}
										tileContent={
											<CompletedSummaryConstant.Images.PaperFile
												data-test-auto="DE02082B-BC13-408E-8D43-5851E93D4A99"
												className="button---PaperFiledReturn"
											/>
										}
										tileDetail={this.getPaperFileCaption(isPreview)}
									/>
								</div>
							)}

							{this.props.isDelegateeAssigned && !this.props.isSignCompleted && (
								<div
									data-test-auto="6C6403FF-9D2C-41AC-ADFE-1209675FE325"
									className="cards"
									tabIndex={0}
									onKeyDown={(e: any) => handleKeyDown(e, this.openDelegationPopUp)}
								>
									<TileComponent
										onClick={this.openDelegationPopUp}
										tileContent={
											<CompletedSummaryConstant.Images.Delegatee
												data-test-auto="DE02082B-BC13-408E-8D43-5851E93D4A99"
												className="button---PaperFiledReturn"
											/>
										}
										tileDetail={CompletedSummaryConstant.ImageCaption.UpdateDelegatee}
									/>
								</div>
							)}

							{this.props.taxReturn &&
								this.props.taxReturn.documentSettings?.documentDisplaySetting &&
								this.props.taxReturn.formGroups &&
								this.props.taxReturn.documentSettings.documentDisplaySetting
									.isEnableInvoice === true &&
								this.props.taxReturn.documentSettings.documentDisplaySetting
									.isSendWithoutInvoice === false &&
								this.props.taxReturn.formGroups.find(
									(x: any) => x.group == DocumentGroups.Invoice
								) !== undefined &&
								invoiceIndex > -1 &&
								this.props.taxReturn.formGroups[invoiceIndex].forms.length >
								0 && (
									<div
										style={{ position: "relative" }}
										data-test-auto="3123C8D4-7541-4B32-89AE-AA3B6EDF4CBC"
										className="cards"
										tabIndex={0}
										onKeyDown={(e: any) =>
											handleKeyDown(e, this.payTaxPrepFee)
										}
									>
										<div
											className={`pay-tiles ${this.props.paymentData.status ===
												PaymentState.Success ||
												this.props.invoicePaymentData.isPaid
												? "pay-tiles-paid"
												: "pay-tiles-due"
												}`}
										>
											{!this.props.paymentData.transactionId &&
												!this.props.invoicePaymentData.isPaid
												? "Invoice Payment Due"
												: this.props.paymentData.status ===
													PaymentState.Success ||
													this.props.invoicePaymentData.isPaid
													? "Paid"
													: "Invoice Payment Due"}
										</div>

										<TileComponent
											onClick={this.payTaxPrepFee}
											tileContent={<CompletedSummaryConstant.Images.InvoicePayment
												data-test-auto="44C73B90-8ECE-47A3-A9A6-A78588D2E9DC"
												className="button---invoice"
											/>}
											tileDetail={CompletedSummaryConstant.ImageCaption.PayTaxPrepFee}
										/>
									</div>
								)}
						</div>

						<AssignToDelegatee
							show={this.state.showModal}
							onCancel={this.closeDelegationPopUp}
							param={this.props.param}
							history={this.props.history}
							taxReturn={this.props.taxReturn}
							saveDelegateeSignerDetails={
								this.props.updateDelegateeSignerDetails
							}
							isDelegateeAssigned={this.props.isDelegateeAssigned}
							cancelDelegation={this.props.cancelDelegation}
							resetLastVisitedSteps={this.props.resetLastVisitedSteps}
							successMsg={
								DelegateeSignerConstants.SuccessMessage.UpdateDelegateeSuccess
							}
							delegateeSigner={this.props.delegateeSigner}
							isPreview={isPreview}
						/>

						{this.state.showInvoicePopup &&
							<InvoiceDetailsPopup
								show={this.state.showInvoicePopup}
								onCancel={this.onInvoiceCancel}
								clientId={this.props.param.clientId}
								invoiceAmount={this.props.taxReturn.invoiceAmount}
								downloadTaxInvoice={this.props.downloadTaxInvoice}
								companyData={this.props.companyData}
								paymentData={this.props.paymentData}
								onStripePaymentClick={this.onStripePaymentClick}
								invoicePaymentData={this.props.invoicePaymentData}
								saveInvoicePayment={this.saveInvoicePayment}
								clearAllInvoicePayment={this.clearAllInvoicePayment}
								previewMode={this.props.isPreviewMode}
							/>
						}

						{this.props.companyData.taxpayerPaymentSettings &&
							this.props.companyData.taxpayerPaymentSettings
								.companyConnectedAccountId != "" &&
							window.Variables.publicKey ? (
							<StripePayment
								clientId={this.props.param.clientId}
								showState={this.state.showStripePaymentPopup}
								onCancel={this.onClosePaymentModal}
								invoiceAmount={this.props.taxReturn.invoiceAmount}
								paymentData={this.props.paymentData}
								paymentSetting={this.props.companyData.taxpayerPaymentSettings}
								updatePurchaseTransactionStatus={
									this.props.updatePurchaseTransactionStatus
								}
							/>
						) : (
							""
						)}

						<PaymentInformationPopup
							show={this.state.showPaymentInfoPopup}
							downloadFilingInstructions={() => { }}
							taxingAuthority={initialTaxingAuthority}
							voucher={{
								amount: 0,
								authorityID: 0,
								authorityImage: "",
								authorityName: "",
								bookmark: "",
								customText: "",
								customType: 0,
								dueDate: new Date(),
								formName: "",
								groupId: 0,
								isMasterPaymentUrl: false,
								onlinePaymentUri: "",
								pageNo: [],
								paymentType: 0,
								vocherMode: 0,
								voucherNo: 0,
								amountPaid: 0,
								checkNumber: "",
								paidDate: new Date(),
								isPaid: false,
							}}
							onCancel={this.onPaymentInfoCancel}
							clientId={this.props.param.clientId}
							isInvoicePopup={true}
							invoiceAmount={this.props.taxReturn.invoiceAmount}
							downloadSingleVoucher={() => { }}
							downloadTaxInvoice={this.props.downloadTaxInvoice}
							invoicePaymentUrl={this.props.invoicePaymentUrl}
							isPreviewMode={this.props.isPreviewMode}
							taxpayerPaymentSettings={
								this.props.companyData.taxpayerPaymentSettings
							}
						/>
					</div>
				) : (
					<Shimmer height={500} />
				)}
				{this.state.showConsentPopUp && (
					<SuiteModal
						width="500"
						theme="light"
						title="SMS Text Notification Consent"
						onClickOutside={() => { }}
						onClickClose={() => {
							this.handleConsent(false);
						}}
					>
						<ConsentPopUp
							companyName={this.props.companyData.name}
							handleConsent={this.handleConsent}
							countryCode={this.state.countryCode}
							mobileNumber={this.state.mobileNumber}
						/>
					</SuiteModal>
				)}

				{this.props.stepInfo.isAccessedFromOneHub &&
					<ButtonComponent
						buttonClassName={`${this.props.stepInfo.redirectionSourcePage == RedirectionSourcePage.Signcompleted ? "" : "completebtn"} flrt`} 
						variant={"success"}
						onClick={() => this.redirectToOneHub()}>
						{this.props.stepInfo.redirectionSourcePage == RedirectionSourcePage.Signcompleted ? "Return To Home" : "Complete"}
					</ButtonComponent>
				}
			</div>
		);
	}
}
