import * as React from "react";
import { ManualSignConstants } from "../../Common/Constants";
import { SvgDownloadButtonIcon } from "../../../components/Common/Icons/SvgIcons";
import { ButtonComponent } from "cp-common-ui-components";

export interface DownloadDocumentsProps {
    clientId: string;
    downloadEfileForms(clientId: string, successCallback?: () => void): void;
}

export const DownloadDocuments: React.FunctionComponent<DownloadDocumentsProps> = ({
    clientId,
    downloadEfileForms
}) => {
    let handleEfileDownload = () => {
        downloadEfileForms(clientId);
    };

    return (
        <div
            className={"col-lg-12 no-padding download-documents-area"}
            data-test-auto="6EBE6B0F-EAAA-4CEB-983C-B2ED0B28F82F"
        >
            <span>1. Download documents</span>

            <div className={"downlod-button-container mt-4"}>
                <ButtonComponent
                    data-test-auto={"EAB719C4-9C65-4C57-86F0-895D008B558A"}
                    onClick={handleEfileDownload}
                    title={ManualSignConstants.DownloadDocumentButtonText}
                    variant={"outline-primary"}
                    size={"medium"}
                    buttonClassName={"manual-sign-download ml-3"}
                >
                    <SvgDownloadButtonIcon />
                    <span className="pl-2">Download</span>
                </ButtonComponent>
            </div>
        </div>
    );
};
