import * as React from 'react';
import { match, RouteComponentProps } from 'react-router';
import { History } from 'history';
import { ICcRecipientDownloadModel, CCRecipientDocumentGroups } from '../../core/domain/models/ICCRecipientModel';
import * as CcRecipientStore from '../../store/CCRecipientStore';
import { Shimmer } from '../Common/Shimmer/Shimmer';
import { TYPES } from '../../startup/types';
import { IFileUtilities } from '../../core/utilities/File/FileUtilities';
import { container } from '../../startup/inversify.config';
import { storeControllerIdInMemory } from '../../core/services/dataAccess/DataService.Axios';
import { SvgDownloadButtonIcon,SvgAttachmentIcon} from '../Common/Icons/SvgIcons';
import { ButtonComponent } from 'cp-common-ui-components';

export type CcRecipientDownloadTaxdocumentProps = {
    match: match;
    history: History;
    ccDocumentsForDownload: ICcRecipientDownloadModel[];
}
    & typeof CcRecipientStore.actionCreators
    & RouteComponentProps<{}>;


interface CcRecipientDownloadTaxdocumentState {
    attachments: ICcRecipientDownloadModel[];
}

const fileUtilities = container.get<IFileUtilities>(TYPES.IFileUtilities);
export class CcRecipientDownloadTaxdocument extends React.Component<CcRecipientDownloadTaxdocumentProps, CcRecipientDownloadTaxdocumentState>{
    private _params: any;
    constructor(props: any) {
        super(props);
        this.state = {
            attachments: []
        }
    }

    componentDidMount() {
        let param: any = this.props.match.params;
        this._params = this.props.match.params;
        if (param && param.controllerId) {
            storeControllerIdInMemory(param.controllerId);
        }
        this.props.requestCCRecipientDownloadableDocumentsForDownload(param.clientId);
    }

    static getDerivedStateFromProps(nextProps: CcRecipientDownloadTaxdocumentProps, prevState: CcRecipientDownloadTaxdocumentState) {
        if (!nextProps.ccDocumentsForDownload || nextProps.ccDocumentsForDownload.length == 0) {
            return null
        }
        else {
            return {
                attachments: nextProps.ccDocumentsForDownload.filter(x => x.documentGroup == CCRecipientDocumentGroups.Attachments)
            }
        }
    }

    downloadSelectedDocument = (clientId: string, documentId: string, taxYear: number,
        fileName: string, groupId: CCRecipientDocumentGroups) => {

        if (groupId == CCRecipientDocumentGroups.AdditionalEsignDocument) {
            this.props.downloadAllAdditionEsignDocuments(clientId, documentId, taxYear, groupId);
            return;
        }
        else if (groupId == CCRecipientDocumentGroups.Attachments) {
            this.state.attachments.length > 1 ? this.props.downloadAllCcAttachments(clientId) :
                this.props.downloadTaxDocument(clientId, documentId, taxYear, this.state.attachments[0].fileName, groupId);
            return;
        }
        else if (groupId == CCRecipientDocumentGroups.PaperFiles) {
            this.props.downloadAllPaperFileDocuments(clientId, documentId, taxYear, groupId);
            return;
        }
        this.props.downloadTaxDocument(clientId, documentId, taxYear, fileName, groupId);
    }

    downloadAllDocuments = () => {
        this.props.downloadAll(this._params.clientId, this.props.ccDocumentsForDownload);
    }

    getCcDownloadableDocuments = () => {
        let downloadableDocuments: ICcRecipientDownloadModel[];
        downloadableDocuments = this.props.ccDocumentsForDownload;
        const attachments = downloadableDocuments.filter(x => x.documentGroup == CCRecipientDocumentGroups.Attachments);
        if (attachments.length > 0) {
            downloadableDocuments = downloadableDocuments.filter(x => x.documentGroup != CCRecipientDocumentGroups.Attachments);
            downloadableDocuments.push({
                documentGroup: CCRecipientDocumentGroups.Attachments,
                documentGuid: attachments[0].documentGuid,
                documentId: attachments[0].documentId,
                fileName: "Attachments",
                taxYear: attachments[0].taxYear
            });
        }
        downloadableDocuments = this.reArrangeDownloadableDocumentsOrder(downloadableDocuments);
        return downloadableDocuments;
    }


    reArrangeDownloadableDocumentsOrder = (downloadableDocuments: ICcRecipientDownloadModel[]) => {
        let data: ICcRecipientDownloadModel[] = [...downloadableDocuments];
        let index: number = downloadableDocuments.findIndex(x => x.documentGroup === CCRecipientDocumentGroups.PaperFiles);
        if (index !== -1) {
            let efileIndex: number = downloadableDocuments.findIndex(x => x.documentGroup === CCRecipientDocumentGroups.SignedEFile);
            efileIndex = efileIndex !== -1 ? efileIndex + 1 : 0;
            data?.splice(efileIndex, 0, downloadableDocuments[index]);
            data?.splice(index + 1, 1);
        }
        return data;
    }

    public render() {
        let fileName: string = "";
        return (
            this.props.ccDocumentsForDownload.length > 0 ?
                <div style={{paddingTop:'15px'}}>
                    <ButtonComponent
                        onClick={this.downloadAllDocuments}
                        variant={"outline-primary"}
                        size={"medium"}
                        buttonClassName="download-all-button ccrecipientDownloadAll "
                    >
                        <SvgDownloadButtonIcon />
                        <span className='pl-2'>Download All</span>
                    </ButtonComponent>
                    <div className='filesSection'>
                        <div className="ccdownload-modal">
                            <div className='ccdownload-header'>File(s) To Download</div>
                            <div className='ccdownload-modal-subheader'>
                                <span className='ccdownload-modal-subheader--fileName'>File Name</span>
                                <span className='ccdownload-modal-subheader--actions'>Actions</span>
                            </div>
                            <div className='ccdownloadfiles'>
                                {this.getCcDownloadableDocuments().map((taxDocument, index) => {
                                    fileName = (taxDocument.documentGroup == CCRecipientDocumentGroups.SignedEFile
                                        || taxDocument.documentGroup == CCRecipientDocumentGroups.AdditionalEsignDocument) ?
                                        taxDocument.fileName.replace(".pdf", "") : taxDocument.fileName;
                                    return <li key={taxDocument.documentGuid + index} data-test-auto={"75378BA6-7D53-4757-85DB-54EF9B2A18D" + index}
                                        className="ccdownload-list" >
                                        <span className='ccrecipient-files'>
                                            <span className='ccrecipient-files--fileName'><SvgAttachmentIcon className='relativePosition-top-2 mr-1 ' />
                                                {
                                                    taxDocument.documentGroup == CCRecipientDocumentGroups.SignedEFile ?
                                                        fileName.startsWith("Signed") ? fileUtilities.getSafeFilename(taxDocument.fileName) : "E-file Forms" : fileUtilities.getSafeFilename(fileName)
                                                }
                                            </span>
                                            <span className='ccrecipient-files--downloadBtn'>
                                                <ButtonComponent
                                                    onClick={() => {
                                                        this.downloadSelectedDocument(this._params.clientId,
                                                        taxDocument.documentGuid, taxDocument.taxYear, taxDocument.fileName, taxDocument.documentGroup)
                                                    }}
                                                    variant={"link"}
                                                    size={"medium"}
                                                >
                                                    <SvgDownloadButtonIcon />
                                                    <span className='downloadText pl-2'>Download</span>
                                                </ButtonComponent>
                                            </span>
                                        </span>
                                    </li>
                                })
                                }
                            </div>
                    </div>
                    </div>
                </div> : <Shimmer lineCount={30} />
        )
    }

}