import { Next } from '@sssuite-component-ui/multi-step-wizard/dist/Footer/Next';
import { Previous } from '@sssuite-component-ui/multi-step-wizard/dist/Footer/Previous';
import { History } from 'history';
import * as React from 'react';
import { match, Prompt } from 'react-router';
import { ICompany } from '../../core/domain/models/company/Company';
import { ISignedDocument } from '../../core/domain/models/manualsign/SignedDocument';
import * as CompanyStore from '../../store/Common/Company/CompanyStore';
import * as ManualSignStore from '../../store/Sign/ManualSignStore';
import { ManualSignConstants, PathConstants, Common, getRoutePathUrl } from '../Common/Constants';
import { Body } from '../Common/StepLayout/Body';
import { Footer } from '../Common/StepLayout/Footer';
import { StepLayout } from '../Common/StepLayout/StepLayout';
import { ManualSign } from '../ManualSign/ManualSign';
import TaxpayerHelper from '../Helper/TaxpayerHelper';
import { IClientProcessViewModel } from '../../core/domain/viewModels/IClientProcessViewModel'
import { Role, DocumentStatus } from '../../core/common/Enums'
import * as SignProcessStore from '../../store/SignProcess/SignProcessStore';
import { IDialogBox } from '../../core/utilities/ui/DialogBox';
import { container } from '../../startup/inversify.config';
import { TYPES } from '../../startup/types';
import { IHeaderInfoViewModel } from '../../core/domain/viewModels/IHeaderInfoViewModel';
import { IAdditionalEsignDocument } from '../../core/domain/models/IAdditionalEsignDocument';
import { ITaxReturn } from '../../core/domain/models/ITaxReturn';
import { storeControllerIdInMemory } from '../../core/services/dataAccess/DataService.Axios';
import { IWindowUtilities } from '../../core/utilities/WindowUtilities';
import * as TaxDocument from '../../core/domain/models/ITaxReturn';
import * as TaxDocumentStore from '../../store/Common/TaxDocumentStore';
import { logger } from '../../routes';


type ManualSignCompletedProps = {
	manualSignData: ISignedDocument[],
	companyData: ICompany,
	headInfoViewModel: IHeaderInfoViewModel,
	match: match;
	history: History;
	additionalEsignDocuments: IAdditionalEsignDocument[];
	downloadAllAdditionEsignDocuments: (clientId: string, fileName: string) => any;
	requestAdditionalEsignDocument(clientGuid: string): void;
	taxReturn: TaxDocument.ITaxReturn;
	clientName: string;
}
	& typeof CompanyStore.actionCreators
	& typeof ManualSignStore.actionCreators
	& typeof TaxDocumentStore.actionCreators
	& typeof SignProcessStore.actionCreators;


interface ManualSignCompletedState {
	isProcessCompleted: boolean;
	isPreviewMode: boolean;
	showRedirectionWarning: boolean;
	isNextButtonDisable: boolean;
	isDocumentDeleted: boolean;
}

const dialogBox = container.get<IDialogBox>(TYPES.IDialogBox);
const windowUtilities = container.get<IWindowUtilities>(TYPES.IWindowUtilities);

export class ManualSignCompleted extends React.Component<ManualSignCompletedProps, ManualSignCompletedState> {
	private _params: any;
	private _manualSign: any;
	private _manualSignData: ISignedDocument[] = [];

	constructor(props: ManualSignCompletedProps) {
		super(props);
		this.state = {
			isProcessCompleted: false,
			isPreviewMode: false,
			showRedirectionWarning: false,
			isNextButtonDisable: false,
			isDocumentDeleted: false
		}
	}

	componentDidMount() {
		const param: any = this.props.match.params;
		logger.trackPageView('Completed Summary Manual Sign Page', { "ClientId": param.clientId, "DocumentId": this.props.taxReturn.id, "PreviewMode": this.state.isPreviewMode });
		if (param && param.controllerId) {
			storeControllerIdInMemory(param.controllerId);
		}
		this.getSignProcessStatus(param.clientId);
		this.setRedirectionWarning();
	}

	componentWillReceiveProps() {

		if (this._manualSignData.length <= 0) {
			this._manualSignData = this.props.manualSignData;
		}

		this.setRedirectionWarning();
	}

	componentWillUnmount() {
		windowUtilities.setRedirectionWarning(false);
	}

	componentDidUpdate = () => {
		this.setRedirectionWarning();
	}

	gotoCompletionWizard = () => {
		let param: any = this.props.match.params;
		this.props.history.push(getRoutePathUrl(PathConstants.CompletionWizard, param.clientId, param.controllerId));
	}

	private getSignProcessStatus = (clientId: any) => {
		this.props.requestCurrentStepAndUserRole(clientId, this.handleRedirect);
	}

	disableNextButton = (value: boolean) => {
		this.setState({ isNextButtonDisable: value });
	}

	private handleRedirect = (clientProcessState: IClientProcessViewModel) => {
		this._params = this.props.match.params;
		const url = TaxpayerHelper.getSignCompletedRedirectUrl(clientProcessState, this._params.clientId, this._params.controllerId);
		if (url && url.trim()) {
			this.props.history.push(url);
		}
		this.setState({
			isProcessCompleted: true,
			isPreviewMode: TaxpayerHelper.isPreviewMode(clientProcessState)
		});
	}

	confirmChanges = () => {
		const _self = this;
		this._params = this.props.match.params;
		logger.trackTrace("Taxpayer Manual sign completion pop up opened", { "ClientId": this._params.clientId, "DocumentId": this.props.taxReturn.id, "PreviewMode": this.state.isPreviewMode });
		dialogBox.confirm(
			Common.DialogBox.ConfirmationTitle,
			ManualSignConstants.ConfirmMessage.replace("<COMPANYNAME>", this.props.headInfoViewModel.companyName),
			Common.DialogBox.Cancel,
			Common.DialogBox.Confrim,
			(result: boolean) => {
				logger.trackTrace("Taxpayer Manual sign completion pop up result ",
					{ "ClientId": this._params.clientId, "DocumentId": this.props.taxReturn.id, "result": result, "PreviewMode": this.state.isPreviewMode });
				if (result && this._manualSign) {
					_self.setState({ showRedirectionWarning: false }, () => {
						_self._manualSign.completeManualSign();
					});
				}

			});
	}

	private onCompletion = () => {
		if (this.state.showRedirectionWarning) {
			this.confirmChanges()
		}
		else if (TaxpayerHelper.getDocumentStatus(this.props.taxReturn.documentStatus.toString()) === DocumentStatus.DELIVERED
			&& this.props.manualSignData.length > 0) {
			this.confirmChanges()
		}
		else if (this.state.isDocumentDeleted && this.props.manualSignData.length > 0) {
			this.confirmChanges();
		}
		else {
			this.gotoCompletionWizard();
		}

		// this.state.showRedirectionWarning ? this.confirmChanges() : this.gotoCompletionWizard();
	}

	private setRedirectionWarning() {

		windowUtilities.setRedirectionWarning(this.state.showRedirectionWarning);
	}

	private handleAddSignedDocument = (clientId: string, signedDocument: ISignedDocument, successCallback?: () => void) => {
		this.setState({ showRedirectionWarning: true }, () => {
			this.props.addSignedDocument(clientId, signedDocument, successCallback);
		});
	}

	private deleteManuallySignedDocument = (clientId: string, id: number, successCallback?: () => void) => {
		this.setState({ isDocumentDeleted: true }, () => {
			this.props.deleteSignedDocument(clientId, id, successCallback);
		});
	}

	public render() {

		return (
			(this.state.isProcessCompleted) &&
			<StepLayout className={"manual-sign-completed with-footer pt-5"}>
				<Prompt
					when={this.state.showRedirectionWarning}
					message={() => ManualSignConstants.RedirectConfirmationMessage} />
				<Body title={ManualSignConstants.Title}
					height={100}
					lg={9} md={12} sm={12} xs={12}
					automationTestId={"11BB28B5-42F3-4571-B567-20700B1740B6"}
					containerClassName={"border-top-0"}>
					<ManualSign match={this.props.match}
						downloadEfileForms={this.props.downloadEfileForms}
						addSignedDocument={this.state.isPreviewMode ? undefined : this.handleAddSignedDocument}
						deleteSignedDocument={this.state.isPreviewMode ? undefined : this.deleteManuallySignedDocument}
						requestSignedDocuments={this.props.requestSignedDocuments}
						signedDocuments={this.props.manualSignData}
						companyInfo={this.props.companyData}
						requestCompanyDetails={this.props.requestCompanyDetails}
						completeManualSign={this.props.completeManualSign}
						onCompletion={this.gotoCompletionWizard}
						ref={(ref: any) => { this._manualSign = ref }}
						additionalEsignDocuments={this.props.additionalEsignDocuments}
						downloadAllAdditionEsignDocuments={this.props.downloadAllAdditionEsignDocuments}
						requestAdditionalEsignDocument={this.props.requestAdditionalEsignDocument}
						clientName={this.props.headInfoViewModel.clientName}
						taxReturn={this.props.taxReturn}
						isDelegatee={false}
						isPreviewMode={this.state.isPreviewMode}
						disableNextButton={this.disableNextButton}
					/>
				</Body>

				<Footer lg={12} md={12} sm={12} xs={12}>
					<div className={"steps-footer"}>
						<Previous automationTestId={"3F43C07D-73AD-4BB5-83D1-346B1E62ABC6"}
							text={"Back"} goToPreviousStep={this.onCompletion} isActive={true} />

						<Next automationTestId={"21596F53-89B5-4830-812E-96D126E7C1AE"} disableNextButton={this.state.isNextButtonDisable}
							text={"Done"} goToNextStep={this.onCompletion} isActive={true} className="awesome-multi-steps-btn-done" buttonVariant="success" buttonSize="medium" />
					</div>
				</Footer>

			</StepLayout>);

	}
}

