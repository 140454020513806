import { ApplicationState } from "../../store/index";
import { actionCreators as SummaryStore } from '../../store/SummaryStore';
import { actionCreators as EsignStore } from '../../store/Common/EsignStore';
import { DelegateeSignFlow } from "./DelegateeSignFlow";
import { actionCreators as PreparerMessageStore } from '../../store/PreparerMessageStore';
import { connect } from 'react-redux';
import { createLoadingSelector } from '../../store/selectors';
import { actionCreators as DocumentReviewStore } from '../../store/ReviewStore';
import { actionCreators as TaxDocumentStore } from '../../store/Common/TaxDocumentStore';
import { actionCreators as CompanyStore } from '../../store/Common/Company/CompanyStore';
import { IDocument } from "../../core/domain/models/esign/Document";
import { actionCreators as SignProcessStore } from '../../store/SignProcess/SignProcessStore';
import { actionCreators as TutorialStore } from '../../store/Tutorial/TutorialStore';
import { IClientProcessViewModel } from '../../core/domain/viewModels/IClientProcessViewModel'
import { actionCreators as DownloadableDocumentsStore } from '../../store/DownloadableDocumentsStore';
import { actionCreators as DelegateeSignerStore } from '../../store/DelegateeSignerStore';
import { actionCreators as DelegateeSignProcessStore } from '../../store/DelegateeSignProcessStore';
import { actionCreators as AccountStore } from '../../store/Common/AccountStore';
import { IDelegateeConsentInfo } from '../../core/domain/models/IDelegateeSignerModel';
import { IKBAAnswers } from '../../core/domain/models/IKBA';

const loadingSelector = createLoadingSelector(['HEADERINFO', 'SUMMARY_DETAILS',
    'TAXING_AUTHORITY', 'DOCUMENT_REVIEW_MODEL', 'DOCUMENT_SETTINGS']);

const mapStateToProps = (state: ApplicationState) => {
    return {
        esignData: state.esignData.data,
        companyData: state.companyData.data,
        delegateeSignatureControlsData: state.delegateeSignatureControlsData.controlsData,
        loading: loadingSelector(state),
        commonData: state.commonData,
        signatureSettingModel: state.signatureSettingData,
        documentSettings: state.documentSettings,
        delegateeReviewModel: state.delegateeReviewModel,
        taxReturn: state.taxReturn.taxDocument,
        signProcessData: state.signProcessData.data,
        signProcessInfo: state.signProcessData.clientprocessmodel.currentstep,
        loggedInUserInfo: state.headerInfo.loggedInUserInfo,
        tutorialInfo: state.tutorialInfo,
        additionalEsignDocuments: state.downloadableDocuments.additionalEsignDocuments,
        delegateeHeaderInfo: state.delegateeHeaderInfo,
        delegateeSigner: state.delegateeSigner,
        delegateeManualSignData: state.delegateeManualSignData,
        delegateeDownloadableDocuments: state.delegateeDownloadableDocuments,
        delegateeKBAData: state.delegateeKBAData,
        isKBAPassed: state.taxReturn.isKBAPassed
    };
}

const mapDispatchToProps = (dispatch: any) => {
    return {
        requestSummaryDetails: (clientId: string) => dispatch(SummaryStore.requestSummaryDetails(clientId)),

        requestDelegateeSignatureControls: (clientGuid: string) =>
            dispatch(DelegateeSignProcessStore.requestDelegateeSignatureControls(clientGuid)),

        updateDocumentSignatureSettingModel: (clientId: string, signatureType: number, callback: any) =>
            dispatch(EsignStore.updateDocumentSignatureSettingModel(clientId, signatureType, callback)),

        updateSignatureControlsData: (data: any) => dispatch(EsignStore.updateSignatureControlsData(data)),

        requestDelegateeDocumentReviewModel: (clientId: string) =>
            dispatch(DelegateeSignerStore.requestDelegateeDocumentReviewModel(clientId)),

        requestDelegateeDownloadableDocuments: (clientId: string) =>
            dispatch(DelegateeSignerStore.requestDelegateeDownloadableDocuments(clientId)),

        requestDocumentSettings: (clientId: string) => dispatch(PreparerMessageStore.requestDocumentSettings(clientId)),

        requestTaxDocument: (clientGuid: string, callback?: (data?: any) => void) =>
            dispatch(TaxDocumentStore.requestTaxDocument(clientGuid, callback)),

        addDelegateeSignedDocument: (clientGuid: string, signedDocument: any, successCallback?: () => void, failureCallback?: () => void) =>
            dispatch(DelegateeSignProcessStore.addDelegateeSignedDocument(clientGuid, signedDocument, successCallback, failureCallback)),

        completeDelegateeManualSign: (clientGuid: string, successCallback?: () => void, failureCallback?: () => void) =>
            dispatch(DelegateeSignProcessStore.completeDelegateeManualSign(clientGuid, successCallback, failureCallback)),

        deleteDelegateeSignedDocument: (clientGuid: string, id: number, successCallback?: () => void, failureCallback?: () => void) =>
            dispatch(DelegateeSignProcessStore.deleteDelegateeSignedDocument(clientGuid, id, successCallback, failureCallback)),

        requestDelegateeSignedDocuments: (clientGuid: string, forceRefresh?: boolean) =>
            dispatch(DelegateeSignProcessStore.requestDelegateeSignedDocuments(clientGuid, forceRefresh)),

        requestCompanyDetails: (clientGuid: string, forceRefresh?: boolean, isDelegatee?: boolean) =>
            dispatch(CompanyStore.requestCompanyDetails(clientGuid, forceRefresh, isDelegatee)),

        delegateeSignComplete: (clientId: string, documentData: IDocument[], callback: (status: boolean) => void) =>
            dispatch(DelegateeSignProcessStore.delegateeSignComplete(clientId, documentData, callback)),

        requestLastVisitedStep: (clientGuid: string, successCallback?: (lastVisitedStep: number) => void) =>
            dispatch(SignProcessStore.requestLastVisitedStep(clientGuid, successCallback)),

        updateLastVisitedStep: (clientGuid: string, step: number, successCallback?: () => void) =>
            dispatch(SignProcessStore.updateLastVisitedStep(clientGuid, step, successCallback)),

        delegateeDownloadEfileForms: (clientGuid: string, successCallback?: () => void) =>
            dispatch(DelegateeSignProcessStore.delegateeDownloadEfileForms(clientGuid, successCallback)),

        requestDelegateeCurrentStepAndUserRole: (clientGuid: string, successCallback?: (clientProcessState: IClientProcessViewModel) => void) =>
            dispatch(SignProcessStore.requestDelegateeCurrentStepAndUserRole(clientGuid, successCallback)),

        requestTutorialInfo: (clientId: string) => dispatch(TutorialStore.requestTutorialInfo(clientId)),

        hideReviewTutorial: (clientId: string, doNotShowNextTime: boolean) =>
            dispatch(TutorialStore.hideReviewTutorial(clientId, doNotShowNextTime)),

        hideSummaryTutorial: (clientId: string, doNotShowNextTime: boolean) =>
            dispatch(TutorialStore.hideSummaryTutorial(clientId, doNotShowNextTime)),

        requestAdditionalEsignDocument: (clientId: string) => dispatch(DownloadableDocumentsStore.requestAdditionalEsignDocument(clientId)),

        delegateeDownloadAllAdditionEsignDocuments: (clientId: string, fileName: string) =>
            dispatch(DelegateeSignProcessStore.delegateeDownloadAllAdditionEsignDocuments(clientId, fileName)),

        downloadAllDocuments: (clientId: string, fileName: string) =>
            dispatch(DocumentReviewStore.downloadAllDocuments(clientId, fileName)),

        downloadDelegateeSelectedDocuments: (clientId: string, documentGroups: number[]) =>
            dispatch(DelegateeSignProcessStore.downloadDelegateeSelectedDocuments(clientId, documentGroups)),

        requestDelegateeDetails: (clientId: string, callback?: () => void) => dispatch(DelegateeSignerStore.requestDelegateeDetails(clientId, callback)),

        saveDeclinedRemarks: (clientId: string, remarks: string, callback: () => void) =>
            dispatch(DelegateeSignerStore.saveDeclinedRemarks(clientId, remarks, callback)),

        resetTaxClientLastVisitedSteps: (clientId: string, callback: () => void) =>
            dispatch(SignProcessStore.resetTaxClientLastVisitedSteps(clientId, callback)),

        saveDelegateeConsentDetails: (clientId: string, consentDetails: IDelegateeConsentInfo, callback: () => void) =>
            dispatch(DelegateeSignerStore.saveDelegateeConsentDetails(clientId, consentDetails, callback)),

        requestDelegateeKBAStatus: (clientGuid: string, successCallback?: () => void) =>
            dispatch(TaxDocumentStore.requestDelegateeKBAStatus(clientGuid, successCallback)),

        kbaAuthentication: (clientGuid: string, failureCallback: () => void) =>
            dispatch(DelegateeSignProcessStore.kbaAuthentication(clientGuid, failureCallback)),

        kbaValidation: (clientGuid: string, data: IKBAAnswers,
            successCallback: (isNextQuestionSet?: boolean) => void, failureCallback: () => void) =>
            dispatch(DelegateeSignProcessStore.kbaValidation(clientGuid, data, successCallback, failureCallback)),

        logout: (clientGuid: string, callback: (id: string) => void) => dispatch(AccountStore.logout(clientGuid, callback)),
    }
}


const connector = connect(mapStateToProps, mapDispatchToProps);

export default connector(DelegateeSignFlow);


