
export interface ICompany {
    fax?: string;
    address?: string;
    onlinePaymentUrl?: string;
    name?: string;
    phone?: string;
    website?: string;
    city?: string;
    zip?: string;
    stateName?: string;
    address1?: string;
    address2?: string;
    taxpayerPaymentSettings: ITaxpayerPaymentSettings;
    displaySettings: IDisplaySettings;
    uteClientHubSettings: IUTESetting;
    isTPMfaEnabled: boolean;
}

export interface ITaxpayerPaymentSettings {
    companyConnectedAccountId: string;
    applicationFeesAmount: number;
    payOption: PaymentOption;
    priortoReview: boolean;
    allowUserToSendReturnWithoutPayment: boolean;
}

export interface IUTESetting
{
    uteEnabled: boolean;
    uteModified: Date;
}

export enum PaymentOption {
    None = 0,
    AttachedURL = 1,
    Stripe = 2,
    ExternalSystem = 3
}

export const initialTaxpayerPaymentSettings: Readonly<ITaxpayerPaymentSettings> = {
    companyConnectedAccountId: '',
    applicationFeesAmount: 0,
    payOption: PaymentOption.None,
    priortoReview: false,
    allowUserToSendReturnWithoutPayment: false
}

export const initalUteClientHubSettings: Readonly<IUTESetting> = {
    uteEnabled: false,
    uteModified: new Date()
}

export interface IDisplaySettings{
    isScreenShareEnabled: boolean;
}

export const initialDisplaySettings: Readonly<IDisplaySettings> ={
    isScreenShareEnabled:false
}

export class Company implements ICompany {
    fax?: string;
    address?: string;
    onlinePaymentUrl?: string;
    name?: string;
    phone?: string;
    website?: string;
    city?: string;
    zip?: string;
    unit?: string;
    stateName?: string;
    address1?: string;
    address2?: string;
    taxpayerPaymentSettings: ITaxpayerPaymentSettings;
    displaySettings: IDisplaySettings;
    isTPMfaEnabled: boolean;
    uteClientHubSettings: IUTESetting;


    constructor(taxpayerPaymentSettings: ITaxpayerPaymentSettings,
        displaySettings: IDisplaySettings,
        isTPMfaEnabled: boolean,
        fax?: string,
        address?: string,
        onlinePaymentUrl?: string) {

        this.fax = fax;
        this.address = address;
        this.onlinePaymentUrl = onlinePaymentUrl;
        this.taxpayerPaymentSettings = taxpayerPaymentSettings;
        this.displaySettings = displaySettings;
        this.isTPMfaEnabled = isTPMfaEnabled;
    }

    public static createNullObject(): ICompany {
        return new Company(initialTaxpayerPaymentSettings, initialDisplaySettings,false, undefined, undefined, undefined);
    }

    public static create(taxpayerPaymentSettings: ITaxpayerPaymentSettings,
        displaySettings: IDisplaySettings,
        isTPMfaEnabled: boolean,
        fax?: string,
        address?: string, onlinePaymentUrl?: string): ICompany {
        return new Company(taxpayerPaymentSettings, displaySettings, isTPMfaEnabled, fax, address, onlinePaymentUrl);
    }

}