import * as React from "react";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer, toast } from "react-toastify";
import { DangerIcon, SuccessIcon, ToasterCloseIcon, WarningIcon } from "../Icons/SvgIcons";

export class MarsNotifier extends React.Component<any, any> {
    static Error = (message: string, title: any) =>
        toast.error(
            <div className="toaster-icon-message">
                <DangerIcon />
                <div
                    style={{ marginLeft: `${message.trim().length > 37 ? "14px" : "8px"}` }}
                    className="toaster-message"
                >
                    {message}
                </div>
            </div>,
            {
                position: toast.POSITION.TOP_RIGHT,
                className: "toaster-error",
                closeButton: <ToasterCloseIcon />
            }
        );

    static Warning = (message: string, title: any) =>
        toast.warn(
            <div className="toaster-icon-message">
                <WarningIcon />
                <div
                    style={{ marginLeft: `${message.trim().length > 37 ? "14px" : "8px"}` }}
                    className="toaster-message"
                >
                    {message}
                </div>
            </div>,
            {
                position: toast.POSITION.TOP_RIGHT,
                className: "toaster-warning",
                closeButton: <ToasterCloseIcon />
            }
        );

    static Success = (message: string, title: any) =>
        toast.success(
            <div className="toaster-icon-message">
                <SuccessIcon />
                <div
                    style={{ marginLeft: `${message.trim().length > 37 ? "14px" : "8px"}` }}
                    className="toaster-message"
                >
                    {message}
                </div>
            </div>,
            {
                position: toast.POSITION.TOP_RIGHT,
                className: "toaster-success",
                closeButton: <ToasterCloseIcon />
            }
        );

    static Info = (message: string, title: any) =>
        toast.info(
            <div className="toaster-icon-message">
                <div
                    style={{ marginLeft: `${message.trim().length > 37 ? "14px" : "8px"}` }}
                    className="toaster-message"
                >
                    {message}
                </div>
            </div>,
            {
                position: toast.POSITION.TOP_RIGHT,
                className: "toaster-info",
                closeButton: <ToasterCloseIcon />
            }
        );
}

export class Toaster extends React.Component<any, any> {
    render() {
        return (
            <div className="toaster_container">
                <ToastContainer
                    autoClose={5000}
                    hideProgressBar={true}
                    position="top-right"
                    newestOnTop={false}
                    closeOnClick={true}
                    rtl={false}
                    pauseOnHover
                />
            </div>
        );
    }
}
