import * as React from "react";
import { WebrtcSignalR } from "./WebrtcSignalR";
import * as HelperStore from "../../store/Common/HelperStore";
import { match, RouteComponentProps } from "react-router";
import { SvgIconStopScreenShare } from "../Common/Icons/SvgIcons";

type ScreenShareProps = {
  match: match;
  iceServers: RTCIceServer[];
  getIceServers: (clientId: string) => void;
  getWebRTCSignalRHubBaseURL: (
    id: string,
    callback: (signalRHubBaseURL: string) => void
  ) => void;
  webRTCSignalRHubBaseURL: string;
} & typeof HelperStore.actionCreators &
  RouteComponentProps<{}>;

export interface ScreenShareState {
  cssClass: string;
}

export class ScreenShare extends React.Component<
  ScreenShareProps,
  ScreenShareState
> {
  videoRef: React.RefObject<HTMLVideoElement>;
  webrtcRef: any;
  remoteStream: MediaStream;

  constructor(props: ScreenShareProps) {
    super(props);

    this.state = {
      cssClass: "disabled",
    };

    this.remoteStream = new MediaStream();
    this.videoRef = React.createRef();
    this.webrtcRef = React.createRef();
  }

  setStreamOnVideoElement = () => {
    if (this.videoRef.current !== null && this.videoRef.current !== undefined) {
      this.videoRef.current.pause();
      const self = this;
      setTimeout(function () {
        self.playVideo();
      }, 1000);
    }
  };

  attachMediaStream = (evt: RTCTrackEvent) => {
    if (evt.track.kind === "audio") {
      this.remoteStream.addTrack(evt.track);
    }
    if (evt.track.kind === "video") {
      this.remoteStream.addTrack(evt.track);
    }
    if (this.videoRef.current !== null && this.videoRef.current !== undefined) {
      this.videoRef.current.pause();
      const self = this;
      setTimeout(function () {
        self.playVideo();
      }, 1000);
    }
  };

  playVideo = () => {
    this.videoRef.current.srcObject = this.remoteStream;
    this.videoRef.current.muted = true;
    let playPromise = this.videoRef.current.play();

    if (playPromise !== undefined) {
      playPromise
        .then((_) => {
          this.setState({ cssClass: "enabled" });
        })
        .catch((err) => {
          console.error("playPromise error: ", err);
        });
    } else {
      this.setState({ cssClass: "enabled" });
    }
  };

  removeMediaStream = () => {
    console.log("OnPage: called removeMediaStream");
    if (this.videoRef.current) {
      this.videoRef.current.pause();
      this.videoRef.current.srcObject = null;
      console.log("OnPage: Removed remote screen sharing stream");
    }

    this.remoteStream &&
      this.remoteStream.getTracks().forEach((track) => {
        track.stop();
      });

    this.setState({ cssClass: "disabled" });
  };

  handleHangup = () => {
    this.webrtcRef.onHangup();
  };

  public render() {
    return (
      <div>
        <video
          id="video"
          ref={this.videoRef}
          className="screen partner"
          loop={true}
          muted={true}
        ></video>
        <div id="controls" className="vertical-center">
          <SvgIconStopScreenShare
            className={"stop " + this.state.cssClass}
            onClick={
              this.state.cssClass === "enabled" ? this.handleHangup : undefined
            }
          />
        </div>
        <WebrtcSignalR
          ref={(ref) => (this.webrtcRef = ref)}
          isDebugEnabled={true}
          match={this.props.match}
          isCPAMode={true}
          handleStream={this.setStreamOnVideoElement}
          handleRemoveStream={this.removeMediaStream}
          handleTrack={this.attachMediaStream}
          getIceServers={this.props.getIceServers}
          iceServers={this.props.iceServers}
          getWebRTCSignalRHubBaseURL={this.props.getWebRTCSignalRHubBaseURL}
          webRTCSignalRHubBaseURL={this.props.webRTCSignalRHubBaseURL}
        />
      </div>
    );
  }
}
