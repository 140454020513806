import * as React from "react";
import { CompletedSummaryConstant } from "../../Common/Constants";
import { SendReminderIcon } from "../../../components/Common/Icons/SvgIcons";

export interface NextSignerAlertProps {
    nextSignerName: string;
    clientId: string;
    icon: any;
    alertMessage: string;
    onclick: (clientId: string) => void;
    isSignReminderEnable: boolean;
}

export const NextSignerAlert: React.FunctionComponent<NextSignerAlertProps> = ({
    nextSignerName,
    clientId,
    icon,
    alertMessage,
    onclick,
    isSignReminderEnable
}) => {
    const IconSvg = icon;
    return (
        <div className="pending alert alert-warning">
            <div className="alert-content">
                <IconSvg />
                <span className="alert-message">{alertMessage}</span>
            </div>
            {isSignReminderEnable ? (
                <span className="send-reminder" data-test-auto="6FD905BE-362E-41DA-880C-F40033BC409C">
                    <SendReminderIcon />
                    <a
                        onClick={() => {
                            onclick(clientId);
                        }}
                    >
                        Send A Reminder
                    </a>
                </span>
            ) : (
                <span
                    className="send-reminder"
                    title={CompletedSummaryConstant.sendReminderMessage}
                    data-test-auto="6FD905BE-362E-41DA-880C-F40033BC409C"
                >
                    Send A Reminder
                </span>
            )}
        </div>
    );
};
