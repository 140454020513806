//<reference path="../../reports/filters.tsx" />
import * as React from 'react';
import { RouteComponentProps, match } from 'react-router';
import { Modal, ListGroup, Row, Col } from 'react-bootstrap';
import 'isomorphic-fetch';
import { container } from '../../startup/inversify.config';
import { IDialogBox } from '../../core/utilities/ui/DialogBox';
import { TYPES } from '../../startup/types';
import { MyDownloadsConstants } from '../Common/Constants';
import Moment from 'moment/moment';
import { MarsNotifier } from '../Common/Notification/MarsNotifier';
import { IFileUtilities } from '../../core/utilities/File/FileUtilities';
import { CloseIcon, SvgPdfIcon, SvgDownloadIcon, SvgDeleteIcon, SvgZipIcon } from '../Common/Icons/SvgIcons';
import { ButtonComponent } from 'cp-common-ui-components';
import { handleKeyDown } from '../Helper/HelperFunction';

const dialogBox = container.get<IDialogBox>(TYPES.IDialogBox);

export interface IDownloadedZipFilesModel {
    id: string;
    clientGuid: string;
    documentId: number;
    fileName: string;
    createdOn?: Date;
    fileSize: number;
    status: MyDownloadStatus;
}


export enum MyDownloadStatus {
    None = 0,
    InProgress = 1,
    Ready = 2,
    Error = 3
}

export interface MyDownloadsProps {
    match: match;
    downloadsList: IDownloadedZipFilesModel[];
    showState: boolean;
    onHide(): void
    deleteMyDownloads: (downloadId: string, clientId: string) => void;
    clearAllMyDownloads: (clientId: string) => void;
    downloadK1Zip: (clientId: string, downloadId: string) => void;
}

interface MyDownloadsState {
    row: IDownloadedZipFilesModel[];
    collapsibleRowId: number
}
const fileUtilities = container.get<IFileUtilities>(TYPES.IFileUtilities);
export class MyDownload extends React.Component<MyDownloadsProps, MyDownloadsState> {
    
    constructor(props: MyDownloadsProps) {
        super(props);
        this.state = {
            collapsibleRowId: 0,
            row: []
        }

    }


    componentWillReceiveProps(nextProps: MyDownloadsProps) {
        if (nextProps.downloadsList) {
            this.setState({ row: nextProps.downloadsList });
        }
    }

   

    rowToggleOpen = (event: any) => {
        if (event.target.nextElementSibling.style['display'] == "none") {
            event.target.style['display'] = "none";
            event.target.nextElementSibling.style['display'] = "block";
            event.target.parentElement.parentElement.nextElementSibling.style['display'] = 'block'
        } else {
            event.target.style['display'] = "block";
            event.target.nextElementSibling.style['display'] = "none";
            event.target.parentElement.parentElement.nextElementSibling.style['display'] = 'none'
        }
    }

    rowToggleClose = (event: any) => {
        if (event.target.previousElementSibling.style['display'] == "none") {
            event.target.style['display'] = "none";
            event.target.previousElementSibling.style['display'] = "block";
            event.target.parentElement.parentElement.nextElementSibling.style['display'] = 'none'
        } else {
            event.target.style['display'] = "block";
            event.target.previousElementSibling.style['display'] = "none";
            event.target.parentElement.parentElement.nextElementSibling.style['display'] = 'block'
        }
    }

    formatBytes = (bytes: number, decimals: number = 2) => {
        if (bytes === 0) return '0 Bytes';
        const k = 1024;
        const dm = decimals < 0 ? 0 : decimals;
        const sizes = ['Bytes', 'KB', 'MB', 'GB'];
        const i = Math.floor(Math.log(bytes) / Math.log(k));
        return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
    }

    getDownloadList = () => {

        const param: any = this.props.match.params;
        const clientId = param.clientId;

        let rowCollection: any = [];
        for (var i = 0; i < this.state.row.length; i++) {
            let tmpForm: any = this.state.row[i];
            const fileName = fileUtilities.removeLastCharUnderscore(fileUtilities.getSafeFilename(tmpForm.fileName));
            //  this.setState({ collapsibleRowId: i });
            rowCollection.push(<li className="delivered-return-download-link" style={{ display: 'block' }}>
                <Row lg={12} md={12} sm={12} xs={12}>
                    <Col lg={1} md={1} sm={1} xs={1} className="margin-top-10"> 
                        {tmpForm.fileName.indexOf('.zip') > -1 ? <SvgZipIcon /> : <SvgPdfIcon />}
                    </Col>
                    <Col lg={9} md={9} sm={9} xs={9}>
                        <span className="list-title"
                            data-test-auto="e6ac69a1-a518-43e6-8d3c-5cdea820efab"
                            title={fileName}>
                            {fileName}
                        </span>
                        <span data-test-auto="02029a10-a305-4855-aa98-3dac79620b5f" className="list-sub-title">
                            {Moment(tmpForm.createdOn).format('MM/DD/YY hh:mm A')} - {this.formatBytes(tmpForm.fileSize)}
                        </span>
                    </Col> 
                    <Col lg={2} md={2} sm={2} xs={2} className="margin-top-10 displayFlex">
                        {
                            tmpForm.status == MyDownloadStatus.Ready ?
                                <span
                                    title="Download"
                                    data-test-auto="6c5355e6-8554-4106-82f0-059caef7c50e"
                                    onClick={() => this.onDownloadClick(clientId, tmpForm.id)}
                                    className="margin-right-10 cursor-pointer"
                                >
                                    <SvgDownloadIcon height={16} width={16} />
                                </span>
                                : <span className="fa fa-spinner fa-pulse fa-2x fa-fw"
                                    title="Loading"
                                    style={{ color: 'grey', cursor: 'pointer', fontSize:'20px' }}>
                                </span>
                        } 
                        <span
                            className="cursor-pointer"
                            title="Remove from list"
                            data-test-auto="C598C7E7-E3BA-4F3D-AFAD-69C29C5C69AF"
                            onClick={() => this.onDeletion(tmpForm.status, tmpForm.id, clientId)}> 
                            <SvgDeleteIcon height={16} width={16} />
                        </span> 
                    </Col>
                </Row> 
                <br />
            </li>)
        }
        return rowCollection.length > 0 ? rowCollection : <div data-test-auto="3DD4CC8C-8F99-4792-86C6-215C6780F83A" style={{ textAlign: 'center' }}> No data </div>

    }

    private onDownloadClick(clientId: string, downloadId: string) {
        this.props.downloadK1Zip(clientId, downloadId);
    }

    private onDeletion = (status: MyDownloadStatus, downloadId: string, clientId: string) => {
        const _self = this;

        status === MyDownloadStatus.InProgress ?
            MarsNotifier.Warning(MyDownloadsConstants.DeleteSingleFileWarning, null) :
            _self.props.deleteMyDownloads(downloadId, clientId);
    }



    public render() {
        const param: any = this.props.match.params;
        const clientId = param.clientId;
      
        return <Modal show={this.props.showState} onHide={this.onPopupClose} className="my-settings" bsSize="medium" >
            <Modal.Header data-test-auto="2DB5023A-6014-4E3E-88BD-1B507912648F">
                <Modal.Title>
                    <span data-test-auto="374ec529-5f4e-4f0d-bcde-a1f8fe09696b">
                        My Downloads
                    </span>
                </Modal.Title>
                <div
                    data-test-auto="12336D54-417C-4371-947C-38CBA96E5E65"
                    className="closeIcon"
                    onClick={this.onPopupClose}
                    tabIndex={0}
                    onKeyDown={(e: any) => handleKeyDown(e, this.onPopupClose)}
                >
                    <CloseIcon />
                </div>
            </Modal.Header>
            <Modal.Body style={{ maxHeight: 'calc(100vh - 210px)', overflowY: 'auto' }}>
                <div>
                    <ListGroup>
                        {
                            this.getDownloadList()

                        }
                    </ListGroup>
                </div>

            </Modal.Body>
            <Modal.Footer>
                <ButtonComponent
                    data-test-auto="F23398C4-697B-44DD-8194-5F18C6B94EF3"
                    onClick={this.onPopupClose}
                    variant={"outline-tertiary"}
                    size={"medium"}
                    buttonClassName={"button-margin-right"}
                >
                    Cancel
                </ButtonComponent>
                <ButtonComponent
                    id="btnClearAll"
                    data-test-auto="2E167F40-B146-4A8E-986C-61BC77C2929D"
                    disabled={this.state.row && this.state.row.find(m => m.status !== MyDownloadStatus.InProgress) ? false : true}
                    onClick={() => this.props.clearAllMyDownloads(clientId)}
                    variant={"primary"}
                    size={"medium"}
                >
                    Clear All
                </ButtonComponent>
            </Modal.Footer>
        </Modal>
    }

    onPopupClose = () => {
        this.props.onHide();
    }
}
export default MyDownload;