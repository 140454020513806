import { Action, Reducer } from 'redux';
import { AppThunkAction } from '../index';
import { actionTypes } from '../ActionTypes';
import { AxiosRequestConfig } from 'axios';
import { initializeAxios } from '../../core/services/dataAccess/DataService.Axios'
import { StatusType, NotificationAction } from '../Common/NotificationStore';
import { DisplayDownloadFile } from '../../components/Common/DisplayDownloadFile';
import { EngagementTypeS } from '../../core/common/Enums';
import { container } from '../../startup/inversify.config';
import { ILoader } from '../../core/utilities/ui/Loader';
import { TYPES } from '../../startup/types';
import { TelemetryLogger } from '../../components/Logger/AppInsights';

const logger = TelemetryLogger.getInstance();

export interface TrialK1State {
}

export const initialTrialState: TrialK1State = {
}

type KnownAction = NotificationAction;

const loader = container.get<ILoader>(TYPES.ILoader);
export const actionCreators = {

    downloadAllK1DocumentsAsync: (clientId: string, engagementType: EngagementTypeS, callback?:() => void): AppThunkAction<KnownAction> => (dispatch, getState) => {
        loader.show();
        let config: AxiosRequestConfig = { headers: { 'Content-Type': 'application/json;utf-8' } };
        return initializeAxios().post<string>('api/TrialK1Download/DownloadAllK1Documents/' + engagementType + '/' + clientId, "", config)
            .then(function (response: any) {
                if (callback) {
                    callback();
                }
                let displayDownloadFile = new DisplayDownloadFile();
                displayDownloadFile.directDownload(response.data);
                loader.hide();
            })
            .catch(function (error: any) {
                dispatch({
                    type: actionTypes.NOTIFICATION, statusMessage: "Failed to Download K1 Document",
                    statusType: StatusType.Error
                });
                logger.trackWarning(`downloadAllK1DocumentsAsync failed with error ${error.message} for client: ${clientId}`, { "ClientId": clientId });
            });
    },
}

export const reducer: Reducer<TrialK1State> = (state: TrialK1State = initialTrialState, incomingAction: Action) => {
    const currentState = Object.assign({}, state);
    return currentState || initialTrialState;
};