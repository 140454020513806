import * as React from 'react';
import { Row, Modal } from 'react-bootstrap';
import { MyDownloadsConstants } from '../Common/Constants';
import { MarsNotifier } from '../Common/Notification/MarsNotifier';
import {
    ICCRecipientDownloadableDocuments,
    CCRecipientDocumentGroups
} from '../../core/domain/models/ICCRecipientModel';
import { CloseIcon } from '../Common/Icons/SvgIcons';
import { ButtonComponent } from 'cp-common-ui-components';
import { handleKeyDown } from '../Helper/HelperFunction';

export interface SelectiveDownloadProps {
    showModal?: boolean;
    downloadableDocuments?: ICCRecipientDownloadableDocuments[];
    onHide: () => void;
    downloadAllDocuments?: () => void;
    downloadSelectedDocuments?: (documentGroups: number[]) => void;
}

interface SelectiveDownloadState {
    documents: number[];
}


export class SelectiveDownload extends React.Component<SelectiveDownloadProps, SelectiveDownloadState>{


    constructor(props: any) {
        super(props);
        this.state = {
            documents: [],
        }
    }

    selectDocuments = (event: any, documentGroup: CCRecipientDocumentGroups) => {
        if (event.target.checked) {
            let documentGroups = [...this.state.documents, documentGroup]
            this.setState({ documents: documentGroups });
        }
        else {
            this.setState({ documents: this.state.documents.filter((groupId, index) => groupId !== documentGroup) });
        }
    }

    downloadSelectedDocuments = () => {
        if (this.state.documents.length == 0) {
            MarsNotifier.Warning(MyDownloadsConstants.DocumentsNotSelectedWarning, null);
            return;
        }
        this.props.downloadSelectedDocuments && this.props.downloadSelectedDocuments(this.state.documents);

    }

    public render() {
        const download = this.props.downloadableDocuments;

        return (
            <Modal show={this.props.showModal} id="download-tax-document-modal">
                <Modal.Header data-test-auto="e109565d-0c31-4e16-aae8-210b5d7805d3">
                    <Modal.Title>
                        <div>{'Download Tax Documents'}</div>
                    </Modal.Title>
                    <div
                        data-test-auto="8cc2eef8-27ec-494a-9518-5d52ce8a0891"
                        className="closeIcon"
                        onClick={this.props.onHide}
                        tabIndex={0}
                        onKeyDown={(e: any) => handleKeyDown(e, this.props.onHide)}
                    >
                        <CloseIcon />
                    </div>
                </Modal.Header>
                <Modal.Body>
                    <div>
                        <div data-test-auto="fd6c0bc7-814b-4bca-b95d-9ca0ed7a5708" className="cc-choose-docs">{MyDownloadsConstants.DownloadFiles}</div>
                        <ul className='ugCheckArea'>
                                    {this.props.downloadableDocuments && this.props.downloadableDocuments.map((document, i) => {
                                        return (
                                            <li key={document.fileName + i}
                                                className='eachUGCheck'>
                                                        <input type="checkbox" id={`downloadableDocuments-${i}`} className='ugCheckbox' data-test-auto={"18EF199A-D9BE-42A6-ABAB-533174ABD07" + i}
                                                            key={document.fileName + document.documentGroup}
                                                            checked={this.state.documents.findIndex(groupId => groupId == document.documentGroup)
                                                                > -1 ? true : false}
                                                            onChange={(e: any) => this.selectDocuments(e, document.documentGroup)} />
                                                <label htmlFor={`downloadableDocuments-${i}`} data-test-auto="85abbf19-5670-489a-aed8-8444b2a99a1b">{document.documentGroup == CCRecipientDocumentGroups.SignedEFile ?
                                                            "E-file Forms" : document.fileName}</label>
                                              </li>
                                   );
                               })}
                        </ul>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Row style={{ width: "100%" }}>
                        <div className="download-tax-document-modal-footer">
                        
                        <div className="download-all-document-button" data-test-auto="810e61cc-c198-4d4c-aedb-86af2ecea901">
                            <ButtonComponent
                                onClick={this.props.downloadAllDocuments}
                                variant={"outline-primary"}
                                size={"medium"}
                            >
                                Download All
                            </ButtonComponent>
                        </div>
                            <div className="download-tax-document-modal-button" style={{ textAlign: "right" }} >
                                <ButtonComponent
                                    data-test-auto="14f8eeff-4e49-4d6a-917e-16c8ef427eaa"
                                    onClick={this.props.onHide}
                                    buttonClassName={"button-margin-right"}
                                    variant={"outline-tertiary"}
                                    size={"medium"}
                                >
                                    Cancel
                                </ButtonComponent>
                                <ButtonComponent
                                    data-test-auto="106c8fe1-4c06-4b68-9f34-1a06f8ff7828"
                                    onClick={this.downloadSelectedDocuments}
                                    variant={"primary"}
                                    size={"medium"}
                                >
                                    OK
                                </ButtonComponent>
                            </div>
                        </div>
                    </Row>
                </Modal.Footer>
            </Modal >

        )
    }
}