import { ApplicationState } from "../../store/index";
import { connect } from 'react-redux';
import { actionCreators as SignProcessStore } from '../../store/SignProcess/SignProcessStore';
import { DelegateeRouteManager } from './DelegateeRouteManager';
import { IClientProcessViewModel } from '../../core/domain/viewModels/IClientProcessViewModel'

function mapStateToProps(state: ApplicationState) {
    return {
        signProcessData: state.signProcessData.data
    };
}
//temperroy fix for webpack failure need to invetigate further when we have time
const mapDispatchToProps = (dispatch: any) => {
    return {
        requestDelegateeCurrentStepAndUserRole: (clientGuid: string, successCallback?: (clientProcessState: IClientProcessViewModel) => void) =>
            dispatch(SignProcessStore.requestDelegateeCurrentStepAndUserRole(clientGuid, successCallback)),
        dispatch
    }
}

const connector = connect(mapStateToProps, mapDispatchToProps);

export default connector(DelegateeRouteManager);

