import * as React from "react";
import { Modal } from "react-bootstrap";
import {
  IK1ShareHolderDetails,
  initialK1ShareHolderDetails,
} from "../../core/domain/models/IK1Distribute";
import { MarsNotifier } from "../Common/Notification/MarsNotifier";
import {
  isValidEmailAddress,
  NullandEmptyCheck,
  validateMobileNumber,
  ssnDisplay,
  einDisplay,
} from "../Common/Validations";
import { match } from "react-router";
import {
  K1ShareHolderConstants,
  ValidationContants,
} from "../Common/Constants";
import { EntityType } from "../../core/common/Enums";
import {
  EntityTypeOptions,
  handleKeyDown,
  k1StatusOptions,
} from "../Helper/HelperFunction";
import { isEqual } from "lodash";
import { CloseIcon } from "../Common/Icons/SvgIcons";
import "@sssuite-component-ui/react-phone-input-2/lib/style.css";
import PhoneInput, {
  CountryData,
} from "@sssuite-component-ui/react-phone-input-2";
import CustomDropdown, {
  ICustomDropdownOption,
} from "../Common/CustomDropDown/CustomDropdown";
import { ButtonComponent } from "cp-common-ui-components";

interface IEditK1PartnerProps {
  showState: boolean;
  onCancel(): void;
  k1Partner: IK1ShareHolderDetails;
  match: match;
  type: string;
  updateK1ShareHolderDetail?: (
    clientGuid: string,
    k1Partner: IK1ShareHolderDetails,
    isEmailChange: boolean,
    isPreview: boolean,
    callback: () => void
  ) => void;
  loadK1ShareHolderDetails(): void;
  isPreviewMode: boolean;
  taxpayerName: string;
  companyName: string;
  updateSelectedK1ShareHolder: (k1Partner: IK1ShareHolderDetails) => void;
}
interface IEditK1PartnerState {
  shareHolder: IK1ShareHolderDetails;
  existingEmail: string;
  isEmailChange: boolean;
  isMobileChange: boolean;
  existingMobileNumber: string;
  exitsingCountryCode: string;
  shareHolderPreviousValue: IK1ShareHolderDetails;
  sendMail: boolean;
}

export class EditK1PartnerDetails extends React.Component<
  IEditK1PartnerProps,
  IEditK1PartnerState
> {
  constructor(props: any) {
    super(props);
    this.state = {
      shareHolder: initialK1ShareHolderDetails,
      existingEmail: "",
      isEmailChange: false,
      isMobileChange: false,
      existingMobileNumber: "",
      exitsingCountryCode: "",
      shareHolderPreviousValue: initialK1ShareHolderDetails,
      sendMail: false,
    };
  }

  componentWillReceiveProps(nextProps: IEditK1PartnerProps) {
    if (
      nextProps.k1Partner &&
      (!isEqual(this.props.k1Partner, nextProps.k1Partner) ||
        this.props.showState !== nextProps.showState)
    ) {
      this.setState({
        shareHolder: nextProps.k1Partner,
        existingEmail: nextProps.k1Partner.email,
        existingMobileNumber: nextProps.k1Partner.mobileNumber,
        exitsingCountryCode: nextProps.k1Partner.countryCode,
        shareHolderPreviousValue: nextProps.k1Partner,
        sendMail: nextProps.k1Partner.isSendK1EditEmail,
      });
    }
  }

  onChangeAddress = (event: any) => {
    let temp = { ...this.state.shareHolder };
    temp.address = event.target.value;
    this.setState({
      shareHolder: temp,
    });
  };

  onChangeEmail = (event: any) => {
    let temp = { ...this.state.shareHolder };
    temp.email = event.target.value;
    let emailChange =
      this.state.existingEmail === event.target.value ? false : true;
    this.setState({
      shareHolder: temp,
      isEmailChange: emailChange,
    });
  };

  onChangeMobile = (fullValue: string, countryObject: CountryData) => {
    let temp = { ...this.state.shareHolder };
    temp.mobileNumber = fullValue.slice(countryObject.dialCode.length);
    temp.countryCode = `+${countryObject.dialCode}`;
    let change =
      this.state.existingMobileNumber === temp.mobileNumber &&
      this.state.exitsingCountryCode === temp.countryCode
        ? false
        : true;
    this.setState({
      shareHolder: temp,
      isMobileChange: change,
    });
  };

  onChangeEntityType = (value: ICustomDropdownOption | null) => {
    let temp = { ...this.state.shareHolder };
    temp.entityType = value.value;
    this.setState({
      shareHolder: temp,
    });
  };

  onChangeK1Status = (value: ICustomDropdownOption | null) => {
    let temp = { ...this.state.shareHolder };
    temp.k1Status = value.value;
    this.setState({
      shareHolder: temp,
    });
  };

  onChangeSendEmail = (e: any) => {
    this.setState({
      sendMail: e.target.checked,
    });
  };

  onUpdatePartner = () => {
    if (this.validatePartner()) {
      let temp = { ...this.state.shareHolder };
      if (temp.entityType === EntityType.None) {
        temp.entityType = EntityType.Individual;
      }
      if (temp.k1Status === 0) {
        temp.k1Status = 3;
      }
      if (this.state.isMobileChange) {
        temp.isMobileVerify = false;
      }

      temp.isSendK1EditEmail = this.state.sendMail;

      let param: any = this.props.match.params;

      this.setState(
        { shareHolderPreviousValue: initialK1ShareHolderDetails },
        () => {
          this.props.updateK1ShareHolderDetail &&
            this.props.updateK1ShareHolderDetail(
              param.clientId,
              temp,
              this.state.isEmailChange,
              this.props.isPreviewMode,
              () => {
                this.props.loadK1ShareHolderDetails();
                this.props.updateSelectedK1ShareHolder(temp);
              }
            );
          this.props.onCancel();
        }
      );
    }
  };

  validatePartner() {
    if (!NullandEmptyCheck(this.state.shareHolder.email)) {
      MarsNotifier.Warning(
        K1ShareHolderConstants.WarningMessage.EmailAdrressWarning,
        null
      );
      return false;
    } else if (!isValidEmailAddress(this.state.shareHolder.email.trim())) {
      MarsNotifier.Warning(
        K1ShareHolderConstants.WarningMessage.InvalidEmailAddress,
        null
      );
      return false;
    } else if (
      NullandEmptyCheck(this.state.shareHolder.mobileNumber) &&
      this.state.shareHolder.countryCode &&
      !validateMobileNumber(
        this.state.shareHolder.mobileNumber,
        this.state.shareHolder.countryCode
      )
    ) {
      return false;
    } else if (
      this.state.shareHolder.entityType === null ||
      this.state.shareHolder.entityType === 0
    ) {
      MarsNotifier.Warning(
        K1ShareHolderConstants.WarningMessage.EntityTypeWarning,
        null
      );
      return false;
    }
    return true;
  }

  setPartnerType = (): ICustomDropdownOption | null => {
    let selectedValue =
      this.state.shareHolder.entityType &&
      EntityTypeOptions().find(
        (option: any) =>
          option.value === this.state.shareHolder.entityType.toString()
      );
    return {
      label: selectedValue && selectedValue.label,
      value: selectedValue && selectedValue.value,
    };
  };

  setStatus = (): ICustomDropdownOption | null => {
    let selectedValue = k1StatusOptions().find(
      (option: any) =>
        option.value ===
        (this.state.shareHolder.k1Status !== 0
          ? this.state.shareHolder.k1Status.toString()
          : 3)
    );
    return {
      label: selectedValue && selectedValue.label,
      value: selectedValue && selectedValue.value,
    };
  };

  public render() {
    const { isPreviewMode, taxpayerName, companyName } = this.props;
    return (
      <Modal
        show={this.props.showState}
        onHide={() => {
          this.setState(
            { shareHolderPreviousValue: initialK1ShareHolderDetails },
            this.props.onCancel
          );
        }}
        className="k1PartnerPopup"
      >
        <Modal.Header data-test-auto="86A8C423-8EC0-4E9F-A544-3574C9CBFD33">
          <Modal.Title>Update K1 {this.props.type} Details</Modal.Title>
          <div
            data-test-auto="12336D54-417C-4371-947C-38CBA96E5E65"
            className="closeIcon"
            onClick={this.props.onCancel}
            tabIndex={0}
            onKeyDown={(e: any) => handleKeyDown(e, this.props.onCancel)}
          >
            <CloseIcon />
          </div>
        </Modal.Header>

        <Modal.Body>
          <div className="k1-partner">
            <label
              htmlFor="k1PartnerName"
              className="col-sm-5 col-md-5 col-xl-5 margin-bottom-4"
            >
              Name
            </label>
            <div className="col-sm-12 col-md-12 col-xl-12">
              <input
                type="text"
                className="form-control"
                id="k1PartnerName"
                value={this.state.shareHolder.name}
                disabled={true}
                data-test-auto="618040C6-427C-4EF2-8CE7-95A16A147243"
              ></input>
            </div>
          </div>

          <div className="k1-partner">
            <label
              htmlFor="k1PartnerSSN"
              className="col-sm-5 col-md-5 col-xl-5 k1-partner-label"
            >
              SSN / EIN
            </label>
            <div className="col-sm-6 col-md-6 col-xl-6">
              <input
                type="text"
                className="form-control"
                id="k1PartnerSSN"
                value={
                  this.state.shareHolder.entityType == EntityType.Individual
                    ? ssnDisplay(this.state.shareHolder.PIN)
                    : einDisplay(this.state.shareHolder.PIN)
                }
                disabled={true}
                data-test-auto="637B7D83-4783-4272-9B9F-9AD9AB499984"
              ></input>
            </div>
          </div>
          <div className="k1-partner">
            <label
              htmlFor="k1PartnerAddress"
              className="col-sm-5 col-md-5 col-xl-5 k1-partner-label"
            >
              Address
            </label>
            <div className="col-sm-12 col-md-12 col-xl-12">
              <textarea
                placeholder="Address"
                className="form-control"
                id="k1PartnerAddress"
                rows={3}
                data-test-auto="66C358C9-7813-4365-A8F7-54190FDA0F14"
                value={this.state.shareHolder.address}
                onChange={this.onChangeAddress}
              />
            </div>
          </div>
          <div className="k1-partner">
            <label
              htmlFor="k1PartnerEmail"
              className="col-sm-5 col-md-5 col-xl-5 k1-partner-label"
            >
              Email Address
            </label>
            <div className="col-sm-12 col-md-12 col-xl-12">
              <input
                type="text"
                placeholder="Email"
                className="form-control"
                id="k1PartnerEmail"
                value={this.state.shareHolder.email}
                onChange={this.onChangeEmail}
                data-test-auto="22B9547E-E5F6-48C3-A62D-91B410E7F39B"
              ></input>
            </div>
          </div>
          <div className="k1-partner">
            <label
              htmlFor="k1PartnerMobile"
              className="col-sm-5 col-md-5 col-xl-5 k1-partner-label"
            >
              Mobile
            </label>
            <div className="col-sm-6 col-md-6 col-xl-6">
              <PhoneInput
                inputProps={{ id: "k1PartnerMobile" }}
                containerClass={`inputCCMobile`}
                country={"us"}
                autoFormat={true}
                placeholder="() -"
                value={`${this.state.shareHolder.countryCode}${this.state.shareHolder.mobileNumber}`}
                onChange={this.onChangeMobile}
                data-test-auto="D876D071-AC7F-43B5-BA18-6D5D75C41212"
              />
            </div>
          </div>
          <div className="k1-partner">
            <label
              htmlFor="ddlEntityType"
              className="col-sm-5 col-md-5 col-xl-5 k1-partner-label"
            >
              Partner Type
            </label>
            <div
              className="col-sm-6 col-md-6 col-xl-6"
              data-test-auto="A1FDF0F4-555F-4C34-BB08-D82EA97ACFC0"
            >
              <CustomDropdown
                id="ddlEntityType"
                selectedOption={this.setPartnerType()}
                setSelectedOption={this.onChangeEntityType}
                options={EntityTypeOptions()}
                clearable={false}
                searchable={false}
                height={28}
                menuListHeight={90}
              />
            </div>
          </div>
          <div className="k1-partner">
            <label
              htmlFor="ddlStatusList"
              className="col-sm-5 col-md-5 col-xl-5 k1-partner-label"
            >
              Status
            </label>
            <div
              className="col-sm-6 col-md-6 col-xl-6"
              data-test-auto="7E695C76-33AD-4512-92C0-1EEC4AD6AFF2"
            >
              <CustomDropdown
                id="ddlStatusList"
                options={k1StatusOptions()}
                setSelectedOption={this.onChangeK1Status}
                selectedOption={this.setStatus()}
                clearable={false}
                searchable={false}
                data-test-auto="6327FC4C-8C87-460D-BA2D-5C20EC2E55A2"
                height={28}
                menuListHeight={150}
              />
            </div>
          </div>
          <div className="k1-partner">
            <div
              className="col-sm-12 col-md-12 col-xl-12 k1-partner-label"
              data-test-auto="7E695C76-33AD-4512-92C0-1EEC4AD6AFF2"
            >
              <input
                type="checkbox"
                className="input-document-list send-email-checkbox"
                data-test-auto={"18EF199A-D9BE-42A6-ABAB-534564AB567"}
                checked={this.state.sendMail}
                onChange={this.onChangeSendEmail}
                id={"sendMailCheckbox"}
              />
              <label htmlFor={"sendMailCheckbox"} style={{ display: "inline" }}>
                Send update notification email to{" "}
                {isPreviewMode ? taxpayerName : companyName}
              </label>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <ButtonComponent
            data-test-auto="35DF912C-6F07-4582-AB97-366243825226"
            onClick={() => {
              this.setState(
                { shareHolderPreviousValue: initialK1ShareHolderDetails },
                this.props.onCancel
              );
            }}
            variant={"outline-tertiary"}
            buttonClassName={"button-margin-right"}
            size={"medium"}
          >
            Cancel
          </ButtonComponent>
          <ButtonComponent
            data-test-auto="CC3A404D-37B9-439A-B452-45254D3B5FC6"
            onClick={this.onUpdatePartner}
            variant={"primary"}
            disabled={
              this.state.shareHolderPreviousValue.shareHolderId == 0 ||
              isEqual(
                this.state.shareHolder,
                this.state.shareHolderPreviousValue
              )
            }
            size={"medium"}
          >
            Save
          </ButtonComponent>
        </Modal.Footer>
      </Modal>
    );
  }
}
