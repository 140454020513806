import * as React from "react";
import { Modal, FormGroup } from "react-bootstrap";
import { ITaxReturn } from "../../core/domain/models/ITaxReturn";
import { match } from "react-router";
import { IAccessCodeValidation } from "../../core/domain/viewModels/ICommon";
import { CloseIcon } from "../Common/Icons/SvgIcons";
import { ButtonComponent } from "cp-common-ui-components";
import { handleKeyDown } from "../Helper/HelperFunction";

export interface AccessCodeProps {
    match: match;
    showState: boolean;
    inProgress: boolean;
    taxDocument?: ITaxReturn;
    onHide: () => void;
    onScreenSharingStart: (accessCode: IAccessCodeValidation, callback: () => void) => void;
    onValidationSuccessful: () => void;
    isPreviewMode: boolean;
}

interface AccessCodeState {
    accessCode: IAccessCodeValidation;
}

export class AccessCode extends React.Component<AccessCodeProps, AccessCodeState> {
    constructor(props: any) {
        super(props);
        this.state = {
            accessCode: {
                clientGuid: "",
                code: ""
            }
        };
    }

    onEnterAccessCode = (e: any) => {
        let param: any = this.props.match.params;
        let code = e.target.value.replace(/[^0-9]/g, "");
        this.setState({
            accessCode: { clientGuid: param.controllerId ? param.controllerId : param.clientId, code: code }
        });
    };

    public render() {
        return (
            <div>
                <Modal
                    show={this.props.showState}
                    className="myaccount"
                    onHide={this.props.onHide}
                    data-test-auto="689A5D12-FAFD-44B8-8C11-373C29A7C1BF"
                >
                    <form
                        onSubmit={(event) => {
                            event.preventDefault();
                            if (this.state.accessCode.code.length > 0) {
                                this.props.onScreenSharingStart(
                                    this.state.accessCode,
                                    this.props.onValidationSuccessful
                                );
                            }
                        }}
                    >
                        <FormGroup role="form">
                            <Modal.Header>
                                <Modal.Title>Share Screen</Modal.Title>
                                <div
                                    data-test-auto="8cc2eef8-27ec-494a-9518-5d52ce8a0891"
                                    className="closeIcon"
                                    onClick={this.props.onHide}
                                    tabIndex={0}
                                    onKeyDown={(e: any) => handleKeyDown(e, this.props.onHide)}
                                >
                                    <CloseIcon />
                                </div>
                            </Modal.Header>
                            <Modal.Body>
                                <div className="row">
                                    <div className="col-lg-12" style={{ display: "inline" }}>
                                        <div>
                                            <div className="row myaccount-text">
                                                {this.props.inProgress ? (
                                                    <div className="col-sm-12 col-md-12 col-xl-12">
                                                        <span> The sharing session has been terminated </span>
                                                    </div>
                                                ) : (
                                                    <div className="col-sm-12 col-md-12 col-xl-12">
                                                        <label htmlFor="accessCode"> Enter Code </label>
                                                        <br />
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            id="accessCode"
                                                            data-test-auto="CDAB9814-31A5-4543-8B0B-DE8606825993"
                                                            value={this.state.accessCode.code}
                                                            onChange={(e) => this.onEnterAccessCode(e)}
                                                            maxLength={8}
                                                        />
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Modal.Body>
                            <Modal.Footer>
                                {this.props.inProgress ? (
                                        <ButtonComponent
                                            id="btnHideMyaccountPopUp"
                                            data-test-auto="4EB783A4-84B4-4BDF-B412-71B3E1385AEA"
                                            onClick={this.props.onHide}
                                            variant={"primary"}
                                            size={"medium"}
                                        >
                                            <span>OK</span>
                                        </ButtonComponent>
                                ) : (
                                    <>
                                        <ButtonComponent
                                            id="btnHideMyaccountPopUp"
                                            data-test-auto="CEC8781D-ED44-4B24-AF4A-8005A00B59FC"
                                            onClick={this.props.onHide}
                                            variant={"outline-tertiary"}
                                            size={"medium"}
                                            buttonClassName={"button-margin-right"}
                                        >
                                            Cancel
                                        </ButtonComponent>
                                        <ButtonComponent
                                            data-test-auto="56A7B8B9-02D6-498E-8953-F7F56C4478AE"
                                            disabled={this.props.isPreviewMode}
                                            variant={"primary"}
                                            size={"medium"}
                                        >
                                            Share
                                        </ButtonComponent>
                                    </>
                                )}
                            </Modal.Footer>
                        </FormGroup>
                    </form>
                </Modal>
            </div>
        );
    }
}
export default AccessCode;
