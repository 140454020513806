import * as React from "react";
import { DateType } from "../../core/domain/viewModels/ICommon";
import CustomDropdown from "./CustomDropDown/CustomDropdown";

export interface IYearMonthDayProps {
    controlValue: Date;
    onControlValueChange(value: string, type: DateType): void;
    id: string;
    customMonthClassName?: string;
    customDayClassName?: string;
    containerClassName?: string;
    customDropdownContainerClass?: string;
}

interface ICustomDropdownOption {
    label: string;
    value: number;
}

interface IYearMonthDayState {
    selectedYear: ICustomDropdownOption;
    selectedMonth: ICustomDropdownOption;
    selectedDay: ICustomDropdownOption;
    dayList: ICustomDropdownOption[];
    monthList: ICustomDropdownOption[];
    yearList: ICustomDropdownOption[];
}

export class YearMonthDayDropdown extends React.Component<IYearMonthDayProps, IYearMonthDayState> {
    constructor(props: any) {
        super(props);
        let today = new Date();
        let day: string = today.getDate().toString();
        let month: string = (today.getMonth() + 1).toString();
        let year: string = today.getFullYear().toString();
        this.state = {
            selectedYear: {
                label: year,
                value: today.getFullYear()
            },
            selectedMonth: {
                label: month,
                value: today.getMonth() + 1
            },
            selectedDay: {
                label: day,
                value: today.getDate()
            },
            dayList: [],
            monthList: [],
            yearList: []
        };

        this.onYearChange = this.onYearChange.bind(this);
        this.onMonthChange = this.onMonthChange.bind(this);
        this.onDayChange = this.onDayChange.bind(this);
    }

    componentDidMount() {
        this.SetControlValues();
    }

    static getDerivedStateFromProps(props: IYearMonthDayProps, state: IYearMonthDayState) {
        if (
            !props.controlValue ||
            (props.controlValue.getDate().toString() == state.selectedDay.label &&
                (props.controlValue.getMonth() + 1).toString() == state.selectedMonth.label &&
                props.controlValue.getFullYear().toString() == state.selectedYear.label)
        ) {
            return null;
        } else {
            let day = props.controlValue.getDate();
            let month = props.controlValue.getMonth() + 1;
            let year = props.controlValue.getFullYear();
            return {
                selectedDay: { label: day.toString(), value: day },
                selectedMonth: { label: month.toString(), value: month },
                selectedYear: { label: year.toString(), value: year }
            } as IYearMonthDayState;
        }
    }

    componentDidUpdate(prevProps: IYearMonthDayProps) {
        if (this.props.controlValue != prevProps.controlValue) {
            this.SetControlValues();
        }
    }

    private SetControlValues = () => {
        let dayOptions: ICustomDropdownOption[] = [];
        for (var i = 1; i <= 31; i++) {
            dayOptions.push({ label: i.toString(), value: i });
        }
        let monthOptions: ICustomDropdownOption[] = [];
        for (var i = 1; i <= 12; i++) {
            monthOptions.push({ label: i.toString(), value: i });
        }
        let yearOptions: ICustomDropdownOption[] = [];
        for (var i = new Date().getFullYear(); i > 1900; i--) {
            yearOptions.push({ label: i.toString(), value: i });
        }
        this.setState({
            yearList: yearOptions,
            monthList: monthOptions,
            dayList: dayOptions
        });
        this.SetDayOptions(this.props);
        this.SetMonthOptions(this.props);
        this.SetYearOptions(this.props);
    };

    private SetDayOptions = (props: IYearMonthDayProps) => {
        let day = props.controlValue.getDate();

        this.setState({ selectedDay: { label: day.toString(), value: day } });
    };

    private SetMonthOptions = (props: IYearMonthDayProps) => {
        let month = props.controlValue.getMonth();

        this.setState({ selectedMonth: { label: (month + 1).toString(), value: month + 1 } });
    };

    private SetYearOptions = (props: IYearMonthDayProps) => {
        let year = props.controlValue.getFullYear();

        this.setState({ selectedYear: { label: year.toString(), value: year } });
    };

    private onYearChange = (option: ICustomDropdownOption | null) => {
        let val: number = option.value ?? 0;
        if (val === undefined || val === null) {
            val = 0;
        }
        if (!isNaN(val)) {
            this.props.onControlValueChange(val.toString(), DateType.Year);

            this.setState({
                selectedYear: {
                    label: val.toString(),
                    value: val
                }
            });
        }
    };

    private onMonthChange = (option: ICustomDropdownOption | null) => {
        let val: number = option.value ?? 0;
        if (val === undefined || val === null) {
            val = 0;
        }
        if (!isNaN(val) && !(val > 12)) {
            this.props.onControlValueChange(val.toString(), DateType.Month);

            this.setState({
                selectedMonth: {
                    label: val.toString(),
                    value: val
                }
            });
        }
    };

    private onDayChange = (option: ICustomDropdownOption | null) => {
        let val: number = option.value ?? 0;
        if (val === undefined || val === null) {
            val = 0;
        }
        if (!isNaN(val) && !(val > 31)) {
            this.props.onControlValueChange(val.toString(), DateType.Date);

            this.setState({
                selectedDay: {
                    label: val.toString(),
                    value: val
                }
            });
        }
    };

    public render() {
        return (
            <div
                style={{ display: "flex" }}
                className={this.props.containerClassName ?? ""}
                id={this.props.id}
                data-test-auto="26FEB28E-20D0-4EF9-B114-E3E58E435BA8"
            >
                <CustomDropdown
                    id="yearMonthDateDropdown"
                    classNamePrefix="monthDropdown"
                    containerClassName={this.props.customDropdownContainerClass ?? ""}
                    selectedOption={this.state.selectedMonth}
                    setSelectedOption={this.onMonthChange}
                    options={this.state.monthList}
                    customPlaceHolder="MM"
                    clearable={false}
                    searchable={false}
                    height={31}
                />
                <CustomDropdown
                    id="yearMonthDateDropdown"
                    classNamePrefix="dayDropdown"
                    containerClassName={this.props.customDropdownContainerClass ?? ""}
                    selectedOption={this.state.selectedDay}
                    setSelectedOption={this.onDayChange}
                    options={this.state.dayList}
                    customPlaceHolder="DD"
                    clearable={false}
                    searchable={false}
                    height={31}
                />
                <CustomDropdown
                    id="yearMonthDateDropdown"
                    classNamePrefix="yearDropdown"
                    containerClassName={this.props.customDropdownContainerClass ?? ""}
                    selectedOption={this.state.selectedYear}
                    setSelectedOption={this.onYearChange}
                    options={this.state.yearList}
                    customPlaceHolder="YYYY"
                    clearable={false}
                    searchable={false}
                    height={31}
                />
            </div>
        );
    }
}

export default YearMonthDayDropdown;
