export interface ITaxingAuthority {
    Id: number,
    AuthorityName: string,
    isEsignEnabled: boolean,
    Abbreviation?: string,
    PaymentURL: string,
    PrefixStateCode: boolean,
    Active: boolean,
    CustomAuthority: boolean,
    StateImage?: string
    InUse: number
}

export const initialTaxingAuthority: ITaxingAuthority = {
    Id: 0,
    AuthorityName: "",
    isEsignEnabled: false,
    Abbreviation: "",
    PaymentURL: "",
    PrefixStateCode: false,
    Active: false,
    CustomAuthority: false,
    StateImage: "",
    InUse: 0
};