import * as React from "react";
import { Body } from "../Common/StepLayout/Body";
import { StepLayout } from "../Common/StepLayout/StepLayout";
import { match } from "react-router";
import { logger } from "../../routes";
import { SigningCompletedIcon } from "../Common/Icons/SvgIcons";

interface SignatureCompletedPageProps {
    companyName: string;
    match: match;
    isPreviewMode: boolean;
}

export const SignatureCompletedPage: React.FunctionComponent<SignatureCompletedPageProps> = ({
    companyName,
    match,
    isPreviewMode
}) => {
    React.useEffect(() => {
        const param: any = match.params;
        logger.trackPageView("Sign Completed Page", { ClientId: param.clientId, PreviewMode: isPreviewMode });
    }, []);

    return (
        <StepLayout className="signing-completed">
            <Body
                containerClassName="signing-completed-container"
                lg={10}
                md={12}
                sm={12}
                xs={12}
                title={"Signing Completed"}
                automationTestId={"4903FAD2-DEA0-423F-82E5-22D85B6BD89B"}
				icon={<SigningCompletedIcon className="signing-completed-icon" />}
            >
                <div
                    style={{ textAlign: "center", marginTop: '16px', lineHeight: '1.4' }}
                    className="content-paragraph"
                    data-test-auto="58C9B25A-1816-44A7-BF26-EE614AFC1C24"
                >
                    <div>All documents have been successfully</div>
					<div>signed and will be forwarded to</div>
                    <div style={{ marginTop: '20px' }} data-test-auto="58C9B25ABF26EE614AFC1C24" className="font-weight-normal-bold">
                        {companyName}
                    </div>
                </div>
            </Body>
        </StepLayout>
    );
};

export default SignatureCompletedPage;
