import * as React from 'react';
import { IHeaderInfoViewModel } from '../../core/domain/viewModels/IHeaderInfoViewModel';
import { Body } from '../Common/StepLayout/Body';
import { StepLayout } from '../Common/StepLayout/StepLayout';
import { PreparerMessage } from './PreparerMessage';
import { IDelegateeInfo } from '../../core/domain/models/IDelegateeSignerModel';
import { logger } from '../../routes';
import { match } from 'react-router';

interface PreparerMessageWizardProps {
    match: match;
    preparerMessage: string;
    headerInfo: IHeaderInfoViewModel;
    delegateeInfo?: IDelegateeInfo;
    isDelegatee: boolean;
    isPreviewMode: boolean;
}

export class PreparerMessageWizardBody extends React.Component<PreparerMessageWizardProps, {}> {

    constructor(props: PreparerMessageWizardProps) {
        super(props);
    }
    
    componentDidMount() {
        const param: any = this.props.match.params;
        logger.trackTrace('Preparer Message Page View', { "ClientId": param.clientId, "PreviewMode": this.props.isPreviewMode });
    }

    public render() {

        return (<StepLayout className='preparerMessageScreen'>     <Body xl={7} lg={10} md={12} sm={12} xs={12} data-test-auto="07145D3B-50E2-48A1-9E85-E771F8896C74">
            <PreparerMessage
                message={this.props.preparerMessage}
                headerInfo={this.props.headerInfo}
                delegateeInfo={this.props.delegateeInfo}
                isDelegatee={this.props.isDelegatee}
                data-test-auto="2885B489-4B5F-40CF-BDFC-7C9C5D282523"
            />
        </Body></StepLayout>
        );
    }
}
