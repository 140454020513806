import { Role } from "../../../core/common/Enums";

export interface ILoggedInUserInfo{
	role: Role;
	permission: string
}

export const initialLoggedInUserInfo: ILoggedInUserInfo = {
	role: Role.None,
	permission: ""
}