import * as React from 'react';
import { Body } from '../Common/StepLayout/Body';
import { StepLayout } from '../Common/StepLayout/StepLayout';
import { DownloadDocuments } from './Parts/DownloadDocuments';
import { UploadDocumentsArea } from './Parts/UploadDocumentsArea';
import { ManualSignConstants, DownloadDocumentsConstants } from '../Common/Constants';
import { ISignedDocument } from '../../core/domain/models/manualsign/SignedDocument';
import { ICompany } from '../../core/domain/models/company/Company';
import { ITaxReturn } from '../../core/domain/models/ITaxReturn';
import { IAdditionalEsignDocument } from '../../core/domain/models/IAdditionalEsignDocument';
import { MarsNotifier } from '../Common/Notification/MarsNotifier';
import { logger } from '../../routes';
import { DocumentGroups } from '../../core/domain/models/IGroup';

export interface ManualSignProps {
    match: any;
    hideFileUploadArea?: boolean;
    signedDocuments: ISignedDocument[];
    companyInfo: ICompany;
    downloadEfileForms(clientId: string): void;
    requestSignedDocuments(clientId: string, forceRefresh?: boolean): void;
    requestCompanyDetails(clientGuid: string, forceRefresh?: boolean): void;
    addSignedDocument?(clientId: string, signedDocument: ISignedDocument, successCallback?: () => void, failureCallback?: () => void): void;
    deleteSignedDocument?(clientId: string, id: number, successCallback?: () => void): void;
    completeManualSign?(clientId: string, successCallback?: () => void): void;
    onCompletion?(): void;
    additionalEsignDocuments: IAdditionalEsignDocument[];
    downloadAllAdditionEsignDocuments: (clientId: string, fileName: string) => any;
    requestAdditionalEsignDocument(clientGuid: string): void;
    taxReturn: ITaxReturn;
    clientName: string;
    isDelegatee: boolean;
    isPreviewMode: boolean;
    disableNextButton?(value: boolean) : void;
}

export interface ManualSignState {

}

export class ManualSign extends React.Component<ManualSignProps, ManualSignState> {

    private _params: any;

    componentDidMount() {
        this._params = this.props.match.params;
        logger.trackTrace("Manual Sign Page", { "ClientId": this._params.clientId, "PreviewMode": this.props.isPreviewMode });
        this.props.requestCompanyDetails(this._params.clientId);
        this.props.requestSignedDocuments(this._params.clientId, true);
        this.props.disableNextButton && this.props.disableNextButton(false);
    }

    handleRequestSignedDocument = (clientId: string) => {
        this.props.requestSignedDocuments(clientId, true);
    }

    downloadESignDocuments = (clientId: string) => {
        if (!this.props.isPreviewMode) {
            const _self = this;
            const formGroup = this.props.taxReturn.formGroups.find(x => x.group === DocumentGroups.EFile);
            if (formGroup && formGroup.forms.length > 0) {
                _self.props.downloadEfileForms(clientId);
            }
            else {
                MarsNotifier.Warning(DownloadDocumentsConstants.EfileNotPresent, null);
            }
            logger.trackTrace("Manual Sign Page: Efile Downloaded", { "ClientId": clientId, "PreviewMode": this.props.isPreviewMode });
            setTimeout(function () {
                _self.props.additionalEsignDocuments && _self.props.additionalEsignDocuments.length > 0
                    && _self.props.downloadAllAdditionEsignDocuments(clientId, _self.getOtherUnSignedDocumentFileName(_self.props.additionalEsignDocuments.length));
                logger.trackTrace("Manual Sign Page: Additional Document(s) Downloaded", { "ClientId": clientId, "PreviewMode": _self.props.isPreviewMode });
            }, 2000);
        }
        else {
            MarsNotifier.Warning(DownloadDocumentsConstants.DownloadNotAllowed, null);
        }
    }

    handleDeleteSignedDocument = (clientId: string, id: number) => {
        this.props.deleteSignedDocument && this.props.deleteSignedDocument(clientId, id, () => { this.props.requestSignedDocuments(clientId, true) });
        logger.trackTrace("Manual Sign Page: Uploaded Document Deleted from client", { "ClientId": clientId, "PreviewMode": this.props.isPreviewMode });
    }

    completeManualSign = () => {
        this.props.completeManualSign && this.props.completeManualSign(this._params.clientId, () => {
            logger.trackTrace("Manual Sign Completed", { "ClientId": this._params?.clientId, "PreviewMode": this.props.isPreviewMode });
            this.props.onCompletion && this.props.onCompletion()
        });
    }

    getFileNamePrefix = () => {
        return this.props.clientName + "_" + this.props.taxReturn.taxYear + "_" + this.props.taxReturn.engagementType.toString().substr(1);
    }

    getOtherSignedDocumentFileName = () => {
        return this.getFileNamePrefix() + ManualSignConstants.DownloadZipSignedFileName;
    }

    getOtherUnSignedDocumentFileName = (noOfFiles: number) => {
        return this.getFileNamePrefix() + (noOfFiles > 1 ? ManualSignConstants.DownloadZipMultipleUnSignedFileName : ManualSignConstants.DownloadZipUnSignedFileName);
    }

    public render() {

        const params: any = this.props.match.params;
        const { hideFileUploadArea, signedDocuments, addSignedDocument, companyInfo } = this.props;

        return (<React.Fragment>
            <DownloadDocuments clientId={params.clientId} downloadEfileForms={this.downloadESignDocuments} />
            {
                !hideFileUploadArea && <UploadDocumentsArea
                    clientId={params.clientId}
                    signedDocuments={signedDocuments}
                    companyInfo={companyInfo}
                    deleteSignedDocument={this.handleDeleteSignedDocument}
                    addSignedDocument={addSignedDocument}
                    requestSignedDocument={this.handleRequestSignedDocument}
                    isDelegatee={this.props.isDelegatee}
                    disableNextButton={this.props.disableNextButton}
                />
            }
        </React.Fragment>);
    }
}
