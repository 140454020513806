
import { connect } from 'react-redux';
import CoverPageContainer from "./CoverPage_GR";
import * as  CoverPageStore from "../../store/CoverPage/CoverPageStore";
import * as headerInfoStore from "../../store/Common/AccountStore";
import * as OTPPageStore from "../../store/OTP/OTPStore_GR";
import * as HeaderStore from "../../store/HeaderStore";
import * as GroupCoverPageReducer from "../../store/CoverPage/CoverPageStore_GR";

export default connect(
    (state: any) => ({
       
        headerInfoState: state.HeaderPageReducer,
        initialCoverState:state.GroupCoverReducer
    }),
    {
       
        ...HeaderStore.actionCreators,
        ...GroupCoverPageReducer.actionCreators,
        ...OTPPageStore.actionCreators
       
    }
)(CoverPageContainer as any);