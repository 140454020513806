import * as React from 'react';
import { IHeaderInfoViewModel } from '../../core/domain/viewModels/IHeaderInfoViewModel';
import { Body } from '../Common/StepLayout/Body';
import { StepLayout } from '../Common/StepLayout/StepLayout';
import { PaperFileMessage } from './PaperFileMessage';
import { IDelegateeInfo } from '../../core/domain/models/IDelegateeSignerModel';
import { logger } from '../../routes';
import { match } from 'react-router';

interface PaperFileMessageWizardProps {
    preparerMessage: string;
    headerInfo: IHeaderInfoViewModel;
    delegateeInfo?: IDelegateeInfo;
    isDelegatee: boolean;
    match: match;
    isPreviewMode: boolean;
    isDraftReturn: boolean;
}

export class PaperFileMessageWizardBody extends React.Component<PaperFileMessageWizardProps, {}> {

    constructor(props: PaperFileMessageWizardProps) {
        super(props);
    }

    componentDidMount() {
        const param: any = this.props.match.params;
        logger.trackPageView('Paper File Page', { "ClientId": param.clientId, "PreviewMode": this.props.isPreviewMode });
    }

    public render() {

        return (
            <StepLayout>
                <Body xl={7} lg={8} md={10} sm={10} xs={10} data-test-auto="77145D3B-50E2-48A1-9E85-E771F8896C74">
                    <PaperFileMessage
                        message={this.props.preparerMessage}
                        headerInfo={this.props.headerInfo}
                        delegateeInfo={this.props.delegateeInfo}
                        isDelegatee={this.props.isDelegatee}
                        data-test-auto="9885B489-4B5F-40CF-BDFC-7C9C5D282523"
                        isDraftReturn={this.props.isDraftReturn}
                    />
                </Body>
            </StepLayout>
        );
    }
}
