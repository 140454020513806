import * as React from "react";
import Select, {
  ActionMeta,
  components,
  ValueContainerProps,
} from "react-select";
import { Props } from "react-select/src/Select";

interface DropdownOption {
  value: string;
  label: string;
}

const optionAll: DropdownOption = { value: "0", label: "All" };

interface ICustomMultiSelectProps extends Props {
  enableAllFilter: boolean;
  filterHandler: (value?: string) => void;
}

interface ICustomMultiSelectState extends Props {
  options: DropdownOption[];
  selectedOptions?: DropdownOption[];
}

export class CustomMultiSelect extends React.Component<
  ICustomMultiSelectProps,
  ICustomMultiSelectState
> {
  constructor(props: any) {
    super(props);
    let dropDownData: DropdownOption[] = [];
    if (props.enableAllFilter) {
      dropDownData.push(optionAll);
    }
    dropDownData.concat(props.options as DropdownOption[]);

    this.state = {
      options: dropDownData,
    };
  }

  static getDerivedStateFromProps(
    props: ICustomMultiSelectProps,
    state: ICustomMultiSelectState
  ) {
    let optionsLength = state.options.length;
    if (props.enableAllFilter) {
      optionsLength--;
    }
    if (props.options?.length != optionsLength) {
      let dropDownData: DropdownOption[] = [];
      if (props.enableAllFilter) {
        dropDownData.push(optionAll);
      }
      dropDownData = dropDownData.concat(props.options as DropdownOption[]);

      return {
        options: dropDownData,
      };
    }
    return null;
  }

  public cleanFiltered = () => {
    this.setState(
      {
        selectedOptions: [],
      },
      () => {
        this.props.filterHandler("-1");
      }
    );
  };

  public applyFilter = (value: any) => {
    this.setState(
      {
        selectedOptions: value,
      },
      () => {
        this.props.filterHandler(this.getHandlerValue());
      }
    );
  };

  getHandlerValue = (): string => {
    let filterValue = "";
    if (this.state.selectedOptions) {
      if (
        this.state.selectedOptions?.findIndex(
          (option) => option.value === optionAll.value
        ) !== -1
      ) {
        filterValue = this.state.selectedOptions
          .filter((option) => option.value !== optionAll.value)
          .map((option) => option.value)
          .join(",");
      } else {
        filterValue = this.state.selectedOptions
          .map((option) => option.value)
          .join(",");
      }
    }
    return filterValue;
  };

  private handleAllSelected = (
    checkedValues: DropdownOption[],
    actionMeta: ActionMeta<DropdownOption>
  ): DropdownOption[] => {
    if (this.props.enableAllFilter) {
      //when all option is selected
      if (
        actionMeta.action === "select-option" &&
        actionMeta.option &&
        actionMeta.option.value == "0"
      ) {
        checkedValues = [...(this.state.options as any)];
      } else if (
        this.state.selectedOptions &&
        this.state.selectedOptions.length > 0
      ) {
        //if All option is unchecked, selected options will be empty.
        if (
          actionMeta.action === "deselect-option" &&
          actionMeta.option &&
          actionMeta.option.value == "0"
        ) {
          checkedValues = [];
        }
        //when all the options are selected
        else if (
          this.props.options &&
          checkedValues.length == this.props.options.length
        ) {
          checkedValues = [...(this.state.options as any)];
        }
      }
    }

    return checkedValues;
  };

  private handleSelectChange = (
    value: any,
    actionMeta: ActionMeta<DropdownOption>
  ) => {
    var selectedValue = this.handleAllSelected(value, actionMeta);
    this.setState({ selectedOptions: selectedValue }, () => {
      if (!this.state.selectedOptions) this.props.filterHandler("-1");
      //if the dropdrop is cleared let the table know something has triggered,table filter will not trigger if the value is empty
      else this.props.filterHandler(this.getHandlerValue());
    });
  };

  public disableOptions = (option: DropdownOption): boolean => {
    let isDisabled = false;
    if (this.state.selectedOptions && this.state.selectedOptions.length > 0) {
      if (
        this.state.selectedOptions
          ?.map((option) => option.value)
          .includes("0") &&
        option.value != "0"
      ) {
        isDisabled = true;
      }
    }
    return isDisabled;
  };

  private CustomOption = (props: any) => {
    const { data } = props;

    const onClick = (e: any) => {
      props.selectOption({ ...data });
      e.stopPropagation();
      e.preventDefault();
    };

    return (
      <div
        style={{ paddingLeft: "5px", verticalAlign: "middle" }}
        onMouseDown={onClick}
      >
        <components.Option {...props}>
          <input
            type="checkbox"
            readOnly
            checked={props.isSelected}
            disabled={this.disableOptions(data)}
            id="custom-multiselect-checkbox"
          />
          <span className="custom-multiselect-label">{props.children}</span>
        </components.Option>
      </div>
    );
  };

  private ValueContainer = ({
    children,
    ...props
  }: ValueContainerProps<any>) => {
    const currentValues = props.getValue();
    let selectedItemsLength = currentValues.length;
    let displayText = "";
    if (
      currentValues.some((val: DropdownOption) => val.value === optionAll.value)
    ) {
      displayText = "All";
    } else {
      displayText = currentValues.length;
    }

    return (
      <components.ValueContainer {...props}>
        {selectedItemsLength > 0 ? `[${displayText}]` : children}
      </components.ValueContainer>
    );
  };

  render() {
    return (
      <>
        <Select
          {...this.props}
          aria-label="Select options"
          className={
            this.props.isMultiSelectCheckbox
              ? "filter select-filter multi-select-control"
              : this.props.enableAllFilter
              ? "filter select-filter multi-select-width100"
              : "filter select-filter multi-select-widthauto"
          }
          options={this.state.options}
          onChange={this.handleSelectChange}
          value={this.state.selectedOptions}
          placeholder={
            <span className="filter select-filter placeholder-selected">
              {this.props.placeholder}
            </span>
          }
          menuPortalTarget={document.body}
          menuPosition={"fixed"}
          components={{
            Option: this.CustomOption,
            ValueContainer: this.ValueContainer,
          }}
          isSearchable={false}
          isMulti
          hideSelectedOptions={false}
          styles={{
            valueContainer: (base) => ({
              ...base,
              overflow: "inherit",
            }),
          }}
          maxMenuHeight={this.props.maxMenuHeight}
        />
      </>
    );
  }
}
