import { Action, Reducer } from 'redux';
import { AppThunkAction } from '../index';
import { AxiosRequestConfig } from 'axios';
import { actionTypes } from '../ActionTypes';
import { IPaperFileReturnDetails } from '../../core/domain/models/IPaperFileReturn';
import { initializeAxios } from '../../core/services/dataAccess/DataService.Axios'
import { StatusType, NotificationAction } from '../Common/NotificationStore';
import { PaperFileReturnConstants, MyDownloadsConstants } from '../../components/Common/Constants';
import { container } from '../../startup/inversify.config';
import { ILoader } from '../../core/utilities/ui/Loader';
import { TYPES } from '../../startup/types';
import { IFileUtilities } from '../../core/utilities/File/FileUtilities';
import { AxiosResponse } from 'axios';
import { DisplayDownloadFile } from '../../components/Common/DisplayDownloadFile';
import { ResponseMyDownloadAction } from '../DownloadableDocumentsStore';

export interface PaperFileReturnState {
    data: IPaperFileReturnDetails[];
}

export const initialPaperFileReturnState: PaperFileReturnState = {
    data: [],
}

interface RequestPaperFileAction {
    type: actionTypes.PAPERFILERETURN_REQUEST;
}

interface ResponsePaperFileAction {
    type: actionTypes.PAPERFILERETURN_RESPONSE;
    data: IPaperFileReturnDetails[];
}

interface UpdatePaperFileAction {
    type: actionTypes.PAPERFILERETURN_UPDATE;
    data: IPaperFileReturnDetails;
}

interface FailurePaperFileAction {
    type: actionTypes.PAPERFILERETURN_FAILURE;
    data: IPaperFileReturnDetails[];
}

type KnownAction =
    DispatchAction |
    ResponseMyDownloadAction |
    NotificationAction;

type DispatchAction = RequestPaperFileAction
    | ResponsePaperFileAction | FailurePaperFileAction
    | UpdatePaperFileAction;

const loader = container.get<ILoader>(TYPES.ILoader);
const fileUtilities = container.get<IFileUtilities>(TYPES.IFileUtilities);
export const actionCreators = {
    requestPaperFileReturnDetails: (clientGuid: string, pageNo: number, pageSize: number, sortBy: string, sortOrder: string, searchText: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const state = getState();
        dispatch({ type: actionTypes.PAPERFILERETURN_REQUEST });
        return initializeAxios().get<IPaperFileReturnDetails[]>('api/PaperFileReturn/GetPaperFileReturnDetails/' + pageNo + '/' + pageSize + '/' + sortBy + '/' + sortOrder + '/' + searchText + '/' + clientGuid)
            .then(function (response: AxiosResponse<IPaperFileReturnDetails[]>) {
                dispatch({
                    type: actionTypes.PAPERFILERETURN_RESPONSE, data: response.data
                });
            })
            .catch(function (error: any) {
                dispatch({
                    type: actionTypes.NOTIFICATION, statusMessage: error.response ? error.response.statusText : PaperFileReturnConstants.ErrorMessage.FailedToFetchPaperFileReturns,
                    statusType: StatusType.Error
                });
                dispatch({ type: actionTypes.PAPERFILERETURN_FAILURE, data: state.paperFileReturnData.data });
            });
    },

    addPaperFileConsentToClientTracking: (clientId: string, callback?: () => void): AppThunkAction<KnownAction> => (dispatch, getState) => {
        let config: AxiosRequestConfig = { responseType: 'arraybuffer', headers: { 'Content-Type': 'application/json;utf-8' } };
        return initializeAxios().post<string>('api/PaperFileReturn/AddPaperFileConsentToClientTracking/' + clientId,"",config)
            .then(function (response: any) {
                if (callback) {
                    callback();
                }
            })
            .catch(function (error: any) {
                dispatch({
                    type: actionTypes.NOTIFICATION, statusMessage: error.response ? error.response.statusText : PaperFileReturnConstants.ErrorMessage.FailedToAddClientTracking,
                    statusType: StatusType.Error
                });
            });
    },

    updatePaperFileConsent: (clientId: string, callback?: () => void): AppThunkAction<KnownAction> => (dispatch, getState) => {
        let config: AxiosRequestConfig = { responseType: 'arraybuffer', headers: { 'Content-Type': 'application/json;utf-8' } };
        return initializeAxios().post<string>('api/PaperFileReturn/UpdatePaperFileConsent/' + clientId, "", config)
            .then(function (response: any) {

                if (callback) {
                    callback();
                }
            })
            .catch(function (error: any) {
                dispatch({
                    type: actionTypes.NOTIFICATION, statusMessage: error.response ? error.response.statusText : PaperFileReturnConstants.ErrorMessage.FailedToAddClientTracking,
                    statusType: StatusType.Error
                });
            });
    },

    addPaperFilePrintToClientTracking: (clientId: string, paperFile: IPaperFileReturnDetails, callback?: () => void): AppThunkAction<KnownAction> => (dispatch, getState) => {
        loader.show();
        let config: AxiosRequestConfig = { headers: { 'Content-Type': 'application/json;utf-8' } };
        return initializeAxios().post<string>('api/PaperFileReturn/AddPaperFilePrintToClientTracking/' + clientId, JSON.stringify(paperFile), config)
            .then(function (response: any) {
                loader.hide();
                if (callback) {
                    callback();
                }
            })
            .catch(function (error: any) {
                dispatch({
                    type: actionTypes.NOTIFICATION, statusMessage: error.response ? error.response.statusText : PaperFileReturnConstants.ErrorMessage.FailedToAddClientTracking,
                    statusType: StatusType.Error
                });
            });
    },

    addPaperFilePrintAllToClientTracking: (clientId: string, callback?: () => void): AppThunkAction<KnownAction> => (dispatch, getState) => {
        loader.show();
        let config: AxiosRequestConfig = { headers: { 'Content-Type': 'application/json;utf-8' } };
        return initializeAxios().post<string>('api/PaperFileReturn/AddPaperFilePrintAllToClientTracking/' + clientId, "", config)
            .then(function (response: any) {
                loader.hide();
                if (callback) {
                    callback();
                }
            })
            .catch(function (error: any) {
                dispatch({
                    type: actionTypes.NOTIFICATION, statusMessage: PaperFileReturnConstants.ErrorMessage.FailedToPrintAllDocument,
                    statusType: StatusType.Error
                });
            });
    },

    downloadAllPaperFileReturns: (clientId: string, callback?: () => void): AppThunkAction<KnownAction> => (dispatch, getState) => {
        loader.show();
        let config: AxiosRequestConfig = { headers: { 'Content-Type': 'application/json;utf-8' } };
        return initializeAxios().post<string>('api/PaperFileReturn/DownloadAllDocumentAsync/' + clientId, "", config)
            .then(function (response: any) {
                if (response.data === "") {
                    dispatch({
                        type: actionTypes.NOTIFICATION, statusMessage: PaperFileReturnConstants.ErrorMessage.FailedDownloadAllDocument,
                        statusType: StatusType.Error
                    });
                    return;
                }

                let displayDownloadFile = new DisplayDownloadFile();
                displayDownloadFile.directDownload(response.data);
                loader.hide();
                if (callback) {
                    callback();
                }
                dispatch({
                    type: actionTypes.NOTIFICATION, statusMessage: PaperFileReturnConstants.SuccessMessage.FileDownloaded,
                    statusType: StatusType.Success
                });
            })
            .catch(function (error: any) {
                dispatch({
                    type: actionTypes.NOTIFICATION, statusMessage: PaperFileReturnConstants.ErrorMessage.FailedDownloadAllDocument,
                    statusType: StatusType.Error
                });
            });
    },

    getPaperFileDocumentStream: (clientId: string,documentGuid:string, paperFile: IPaperFileReturnDetails, callback?: (url: any) => void): AppThunkAction<KnownAction> => (dispatch, getState) => {
        loader.show();
        let config: AxiosRequestConfig = { responseType: 'arraybuffer', headers: { 'Content-Type': 'application/json;utf-8' } };
        return initializeAxios().get(`api/PaperFileReturn/GetPaperFileStream/${paperFile.fileName}/${documentGuid}/${paperFile.taxYear}/${clientId}`, config)
            .then(function (response: any) {
                loader.hide();
                if (callback) {
                    callback(response);
                }
            }).catch(function (error: any) {
                dispatch({
                    type: actionTypes.NOTIFICATION, statusMessage: error.response ? error.response.statusText : PaperFileReturnConstants.ErrorMessage.FailedToDownloadPaperFileReturns,
                    statusType: StatusType.Error
                });
            });
    },

    getMergedPaperFileDocumentStream: (clientId: string, callback?: (url: any) => void): AppThunkAction<KnownAction> => (dispatch, getState) => {
        loader.show();
        let config: AxiosRequestConfig = { responseType: 'arraybuffer', headers: { 'Content-Type': 'application/json;utf-8' } };
        return initializeAxios().get(`api/PaperFileReturn/GetMergedPaperFileStream/${clientId}`, config)
            .then(function (response: any) {
                loader.hide();
                if (callback) {
                    callback(response);
                }
            }).catch(function (error: any) {
                dispatch({
                    type: actionTypes.NOTIFICATION, statusMessage: error.response ? error.response.statusText : PaperFileReturnConstants.ErrorMessage.FailedToDownloadPaperFileReturns,
                    statusType: StatusType.Error
                });
            });
    },

    downloadIndividualPaperFileReturn: (clientId: string, paperFile: IPaperFileReturnDetails,callback?: () => void): AppThunkAction<KnownAction> => (dispatch, getState) => {
        loader.show();
        let config: AxiosRequestConfig = { headers: { 'Content-Type': 'application/json;utf-8' } };
        return initializeAxios().post<string>('api/PaperFileReturn/DownloadIndividualDocumentAsync/' + clientId, JSON.stringify(paperFile), config)
            .then(function (response: any) {
                let displayDownloadFile = new DisplayDownloadFile();
                displayDownloadFile.directDownload(response.data);
                loader.hide();
                if (callback) {
                    callback();
                }
            })
            .catch(function (error: any) {
                dispatch({
                    type: actionTypes.NOTIFICATION, statusMessage: error.response ? error.response.statusText : PaperFileReturnConstants.ErrorMessage.FailedToDownloadPaperFileReturns,
                    statusType: StatusType.Error
                });
            });
    },
}

export const reducer: Reducer<PaperFileReturnState> = (state: PaperFileReturnState = initialPaperFileReturnState, incomingAction: Action) => {
    const action = incomingAction as DispatchAction;
    const currentState = Object.assign({}, state);
    switch (action.type) {
        case actionTypes.PAPERFILERETURN_REQUEST:
            currentState.data = [];
            return currentState;
        case actionTypes.PAPERFILERETURN_RESPONSE:
            currentState.data = action.data;
            return currentState;
        default:
            return currentState || initialPaperFileReturnState;
    }
};