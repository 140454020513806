import { SignProcessSteps } from '../../../components/Home/TaxpayerSignFlowManager';

export interface ISignProcessInfoViewModel {
	clientId: string,
	lastVisitedStep: SignProcessSteps,
	lastUpdateOn: Date,
	visitedSteps: SignProcessSteps[]
}

export const initialSignProcessInfoViewModel: ISignProcessInfoViewModel = {
	clientId: "",
	lastVisitedStep: SignProcessSteps.Summary,
	lastUpdateOn: new Date(),
	visitedSteps:[]
}
