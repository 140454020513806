import { ApplicationState } from "../../store/index";
import { connect } from 'react-redux';
import { actionCreators as CCRecipientStore } from '../../store/CCRecipientStore';
import { DocumentStatus } from '../../core/common/Enums';
import { ICCRecipientModel, CCRecipientDocumentGroups, ICcRecipientDownloadModel } from '../../core/domain/models/ICCRecipientModel';
import { CcRecipientDownloadTaxdocument } from './CcRecipientDownloadTaxdocument';

function mapStateToProps(state: ApplicationState) {
    return {
        ccDocumentsForDownload: state.ccDocumentsForDownload
    };
}

const mapDispatchToProps = (dispatch: any) => {
    return {
        requestCCRecipientDownloadableDocuments: (clientGuid: string, documentStatus: DocumentStatus
        ) => dispatch(CCRecipientStore.requestCCRecipientDownloadableDocuments(clientGuid, documentStatus)),

        forwardDocumentsToRecipients: (clientId: string, recipientInfo: ICCRecipientModel, callback: () => void) =>
            dispatch(CCRecipientStore.forwardDocumentsToRecipients(clientId, recipientInfo, callback)),

        requestCCRecipientDownloadableDocumentsForDownload: (clientId: string) =>
            dispatch(CCRecipientStore.requestCCRecipientDownloadableDocumentsForDownload(clientId)),

        downloadTaxDocument: (clientId: string, documentId: string, taxYear: number,
            fileName: string, groupId: CCRecipientDocumentGroups) =>
            dispatch(CCRecipientStore.downloadTaxDocument(clientId, documentId, taxYear, fileName, groupId)),

        downloadAll: (clientId: string, downloadableDocuments: ICcRecipientDownloadModel[]) =>
            dispatch(CCRecipientStore.downloadAll(clientId, downloadableDocuments)),

        downloadAllAdditionEsignDocuments: (clientId: string, documentId: string, taxYear: number,
            groupId: CCRecipientDocumentGroups) =>
            dispatch(CCRecipientStore.downloadAllAdditionEsignDocuments(clientId, documentId, taxYear, groupId)),

        downloadAllCcAttachments: (clientId: string) => dispatch(CCRecipientStore.downloadAllCcAttachments(clientId)),

        downloadAllPaperFileDocuments: (clientId: string, documentId: string, taxYear: number,
            groupId: CCRecipientDocumentGroups) =>
            dispatch(CCRecipientStore.downloadAllPaperFileDocuments(clientId, documentId, taxYear, groupId))
    }
}


const connector = connect(mapStateToProps, mapDispatchToProps);

export default connector(CcRecipientDownloadTaxdocument);