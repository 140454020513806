export interface IBrandingSettings {
    coverPageSetting: IThemeSettings,
}

export interface IThemeSettings {
    bgColorCode:string,
    foreColorCode:string,
}

export const initialThemeSettings: IThemeSettings = {
    bgColorCode: "",
    foreColorCode:"",
}

export const initialBrandingSettings: IBrandingSettings = {
    coverPageSetting: initialThemeSettings,
}