import * as React from "react";
import HeaderComp from "../Header/HeaderComp";
import Skeleton from "react-loading-skeleton";
import Footer from "../Layout/Footer";
import { DisplayError } from "../Common/Constants";
import OTPMobile from "../Common/OTP/OTPMobile";

export class MobileOTP extends React.Component<any, any> {
  continueRef: any = null;

  constructor(props: any) {
    super(props);
    this.state = {
      otpVal: "",
      continueState: false,
      isInitialOTPSent: false,
      otpError: {
        error: false,
        message: "",
      },
    };

    this.handleOTPChange = this.handleOTPChange.bind(this);
    this.requestCode = this.requestCode.bind(this);
    this.verifyOTP = this.verifyOTP.bind(this);
    this.handleOTPVerification = this.handleOTPVerification.bind(this);
  }

  componentWillMount() {
        this.props.requestCoverClientData(
            this.props.match.params.clientId,
            this.gotToErrorPage
        );
    }

  componentDidMount() {
    this.props.requestHeaderDetails(this.props.match.params.clientId);
    this.props.pageValidity(
      this.props.match.params.clientId,
      this.gotToErrorPage
    );
  }

  componentDidUpdate(prevProps: any) {
        if (prevProps.initialCoverState.mobileVerification != this.props.initialCoverState.mobileVerification) {
            this.props.setMobileNo(
                this.props.initialCoverState.mobileVerification.mobileNumber,
                this.props.initialCoverState.mobileVerification.countryCode
            );
        }
    }

  gotToErrorPage = (result: any) => {
    let errorDesc = result.errorDescription;
    let errorCode = result.errorCode;
    if (errorCode && DisplayError.includes(errorCode)) {
      this.props.history.push("/Error/" + errorDesc);
    } else {
      this.setState({
        otpError: { error: true, message: errorDesc },
        otpVal: "",
      });
    }
  };

  requestCode() {
    if (!this.state.isInitialOTPSent) this.setState({ isInitialOTPSent: true });
    this.props.generateMobileOTP(this.props.match.params.clientId);
  }

  handleOTPVerification(guid: string) {
    console.log("in handleOTP");
    this.props.history.push(`/Taxpayerflow/${guid}`);
  }

  verifyOTP() {
    if (this.state.continueState) {
      this.props.verifyOTP(
        this.state.otpVal,
        this.props.match.params.clientId,
        this.handleOTPVerification,
        this.gotToErrorPage
      );
    }
  }

  handleOTPChange(e: any) {
    this.setState({ otpError: { error: false, message: "" } });

    let otpValue = e;
    if (otpValue?.length <= 6 && (!isNaN(otpValue) || otpValue.length === 0)) {
      if (otpValue.length === 6) {
        this.setState({ otpVal: otpValue, continueState: true });
      } else {
        this.setState({ otpVal: otpValue, continueState: false });
      }
    }
  }

  render() {

    return (
      <>
        <HeaderComp
          headerInfo={this.props.headerInfoState}
          pathname={this.props.match.path}
          clientId={this.props.match.params.clientId}
          history={this.props.history}
          backButton={true}
          navigateLink="/CoverPage/Index"
        />
        {this.props.OTPPageState.loading === true || this.props.OTPPageState.mobileNo === "" ? (
          <Skeleton circle={false} height={"100vh"} width={"100vw"} />
        ) : (
            <OTPMobile
            match={this.props.match}
            history={this.props.history}
            headerInfoState={this.props.headerInfoState}
            OTPPageState={this.props.OTPPageState}
            otpVal={this.state.otpVal}
            continueState={this.state.continueState}
            isInitialOTPSent={this.state.isInitialOTPSent}
            otpError={this.state.otpError}
            handleOTPChange={this.handleOTPChange}
            requestCode={this.requestCode}
            verifyOTP={this.verifyOTP}
            /> 

        )}
        <Footer />
      </>
    );
  }
}
export default MobileOTP;
