import * as React from 'react';
import { fetch, addTask } from 'domain-task';
import { handleResponse } from '../store/Library';
import {  match } from 'react-router';
import * as signalR from '@microsoft/signalr';
import { API_BASE_URL } from '../utils/constants';
import { logger } from '../../src/routes';
import { IEventTelemetry} from '@microsoft/applicationinsights-web';

export interface ISignalRState {
    apiBaseUrl: any,
    signalRInitialized:boolean
};

type SignalRProps =
{
    match: match,
    requestMyDownload: (clientId: string) => void,
}

const bearerPrefix: string = 'Bearer ';

export interface INotificationMessage {
    documentId: number;
    documentGuid: string;
    companyId: number;
    notifictionType: NotifictionType;
    data: any;
}

export interface INotificationMetaData {
    clientId: string
}
export enum NotifictionType {
    None = "None",
    TPDownloadSuccess = "TPDownloadSuccess",
    TPDownloadFailed = "TPDownloadFailed"
}

export class SignalRWebSocket extends React.Component<SignalRProps, ISignalRState> {
    private clientId: string = '';
    private group: string = '';
    private token: any = '';
    private connection: any;

    constructor(props: SignalRProps) {
        super(props);
        this.state = {
            apiBaseUrl: '',
            signalRInitialized:false
        };
    }

    componentDidMount() {
        this.token = this.getCookieValue('id_Token');
        this.getWebSocketUrl();
    }

    private getWebSocketGroup = (companyId: string) => {
        var group = "00000000" + companyId;
        return 'ssr' + group.substr(group.length - 8);
    }

    private initSignalR = (callback?: () => void) => {
        if (!this.state.signalRInitialized) {
            this.signalRConnectionInit(callback);
        }
        else {
            callback && callback();
        }
    }

    private signalRConnectionInit = (callback?: () => void) => {
        
        this.setState({ signalRInitialized: true });

        let fetchTask = fetch(`${this.state.apiBaseUrl}/signalr/negotiate`, {
            method: 'POST',
            headers: {
                'x-ms-signalr-userid': this.clientId,
                'Authorization': bearerPrefix + this.token,
                'ClientType': "TP"
            }
        }).then(handleResponse)
            .then((info: any) => {

                info.accessToken = info.accessToken || info.accessKey;
                info.url = info.url || info.endpoint;

                const options = {
                    accessTokenFactory: () => info.accessToken
                };

                this.connection = new signalR.HubConnectionBuilder()
                    .withUrl(info.url +"&diag=yes", options)
                    .configureLogging(signalR.LogLevel.Information)
                    .build();

                console.log(this.connection);
                this.connection.on('DocumentStatusUpdated', this.processDocumentData);
                this.connection.onclose(() => {
                    console.log('disconnected');
                    this.startConnection(this.connection);
                });
                this.startConnection(this.connection, callback, true);

            }).catch((reason) => {
                console.log(reason);
                this.setState({ signalRInitialized: false });
            })
        addTask(fetchTask);
    }

    private startConnection = (connection: any, callback?: () => void, isInitialConnectionCall : boolean = false) => {
        const _self: any = this;
        console.log('connecting...');
        connection.start()
            .then(function () {
                console.log('connected!');
                _self.logSignalREvent("SignalRConnectionStarted", isInitialConnectionCall);
                _self.addGroup();
                connection.invoke('getConnectionId')
                    .then(function (connectionId: any) {
                        // Send the connectionId to controller
                    });
                    callback && callback();
            })
            .catch(function (err: any) {
                console.error(err);
                setTimeout(_self.startConnection, 5000, connection);
            });
    }

    private logSignalREvent = (eventName: string, isInitialConnectionCall: boolean = false) => {
        let signalREvent: IEventTelemetry = {
            name: eventName,
            properties: { "UserId": this.clientId, "ClientType": "TP","IsReconnection" : !isInitialConnectionCall }
        } as IEventTelemetry;

        logger.trackEvent(signalREvent);
    }

    private getCookieValue = (key: string) => {
        const b: any = document.cookie.match('(^|[^;]+)\\s*' + key + '\\s*=\\s*([^;]+)');
        return b ? b.pop() : '';
    }

    private addGroup = () => {
        const fetchTask = fetch(`${this.state.apiBaseUrl}/signalr/AddToGroup`, {
            method: 'POST',
            headers: {
                'x-ms-signalr-userid': this.clientId,
                'Authorization': bearerPrefix + this.token,
                'ClientType': "TP"
            },
            body: JSON.stringify({
                recipient: this.clientId,
                groupname: this.clientId
            })
        }).then((resp: any) => {
            if (resp.status == 200) {
                console.log("User added to the group successfully")
            }
        }).catch((error: any) => {
            console.log(error);
        });
        addTask(fetchTask);
    }

    private removeGroup = () => {
        const fetchTask = fetch(`${this.state.apiBaseUrl}/signalr/RemoveFromGroup`, {
            method: 'POST',
            headers: {
                'x-ms-signalr-userid': this.clientId,
                'Authorization': bearerPrefix + this.token,
                'ClientType': "TP"
            },
            body: JSON.stringify({
                recipient: this.clientId,
                groupname: this.clientId
            })
        }).then((resp: any) => {
            if (resp.status == 200) {
                console.log("User removed from group successfully")
            }
        }).catch((error: any) => {
            console.log(error);
        });
        addTask(fetchTask);
    }

    private getWebSocketUrl = () => {
        const param: any = this.props.match.params;
        this.clientId = param.clientId;
        let customHeaders = {};
        if (param && param.controllerId) {
            this.clientId = this.clientId || param.controllerId;
            customHeaders = {
                "CONTROLLER-GUID": param.controllerId
            }
        }
        let fetchTask = fetch(API_BASE_URL + 'api/WebSocket/GetWebSocketConnectionInfo/' + this.clientId, {
            method: 'GET',
            credentials: 'include',
            headers: customHeaders
        }).then(handleResponse)
            .then((resp: any) => {
                this.token = resp.Token;
                this.setState({ apiBaseUrl: resp.Url });
            }).catch((error: any) => {
                console.log(error);
            });
        addTask(fetchTask);
    }

    private processDocumentData = (notificationMessage: INotificationMessage) => {
        const param: any = this.props.match.params;
        let metaData: INotificationMetaData;
        switch (notificationMessage.notifictionType) {
            case NotifictionType.TPDownloadSuccess:
                setTimeout(() => {
                    this.props.requestMyDownload(param.clientId);
                }, 3000);
                break
            case NotifictionType.TPDownloadFailed:
                this.props.requestMyDownload(param.clientId);
                break
        }
    }

    public render() {
        return (<div />);
    }
}
export default SignalRWebSocket;