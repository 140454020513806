import * as React from 'react';
import { Modal } from 'react-bootstrap';
import { PayConstants } from '../Common/Constants';
import { CloseIcon } from '../Common/Icons/SvgIcons';
import { ButtonComponent } from 'cp-common-ui-components';
import { handleKeyDown } from '../Helper/HelperFunction';
export interface PaymentInstructionProps {
    clientInstructions: string;
    show: boolean;
    closePaymentInstructionModal: () => void;
}

export class PaymentInstruction extends React.Component<PaymentInstructionProps, {}> {
    constructor(props: PaymentInstructionProps) {
        super(props);
    }

    handleCancel = () => {
        this.props.closePaymentInstructionModal();
    }

    public render() {

        return (
            <Modal show={this.props.show && this.props.show} >
                <Modal.Header data-test-auto="91897DA0-02F5-45C6-9D29-E0C2F0C4E7C9">
                    <Modal.Title>{PayConstants.PaymentInstructionModalHeader}</Modal.Title>
                        <div
                            data-test-auto="15613D09-8326-4FE6-B256-F30085B56534"
                            className="closeIcon"
                            onClick={this.handleCancel}
                            tabIndex={0}
                            onKeyDown={(e: any) => handleKeyDown(e, this.handleCancel)}
                        >
                            <CloseIcon />
                        </div>
                </Modal.Header>
                <Modal.Body>
                    <div className="payment-instruction-container">
                        <div className="body" style={{ whiteSpace: "pre-line" }}> {this.props.clientInstructions} </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <ButtonComponent
                        data-test-auto="Z927BDC4-A00E-4A67-9D5D-F5CA7CC46BBB"
                        onClick={this.handleCancel}
                        variant={"primary"}
                        size={"medium"}
                    >
                        OK
                    </ButtonComponent>
                </Modal.Footer>
            </Modal >
        )
    }
}