import * as React from 'react';
import { CustomButton, CustomButtonSize, CustomButtonPlacement } from '../Common/CustomButton/CustomButton';
import { IUtilities } from '../../core/utilities/Utilities';
import { container } from '../../startup/inversify.config';
import { TYPES } from '../../startup/types';
import { IPaymentTransactionModel } from '../../core/domain/models/IPaymentTransactionModel';
import { ICompany, PaymentOption } from '../../core/domain/models/company/Company';
import { Col, Modal, Row } from 'react-bootstrap';
import CustomDropdown, { ICustomDropdownOption } from '../Common/CustomDropDown/CustomDropdown'
import {
    CloseIcon,
    TaxPreparationFeeHeaderIcon,
    ButtonDownloadIcon,
    PayInfoIcon,
    DollarIcon,
    CalenderIcon,
    ViewReceiptIcon,
    TaxFeePaidcon
} from '../Common/Icons/SvgIcons';
import { CurrencyTextBox } from '../Common/Controls/CurrencyTextBox';
import DatePicker from "react-datepicker";
import { SelectComponent } from '../Common/SelectComponent';
import Moment from 'moment/moment';
import { IInvoicePayment, PaymentMode } from '../../core/domain/models/InvoicePaymentModel';
const utilities = container.get<IUtilities>(TYPES.IUtilities);
import { MarsNotifier } from '../Common/Notification/MarsNotifier';
import { InvoicePaymentConstants } from '../Common/Constants';
import { handleKeyDown, isAttachedUrlEnabled, isInvoicePaymentSuccess, isStripeEnabled } from '../Helper/HelperFunction';
import CustomDatepicker from '../Common/CustomDatePicker/CustomDatepicker';
import { ButtonComponent } from 'cp-common-ui-components';

export interface InvoiceDetailsPopupProps {
    show: boolean;
    clientId: string;
    invoiceAmount: number;
    downloadTaxInvoice: (clientId: string) => void;
    onCancel(): void;
    invoicePaymentUrl?: string;
    onStripePaymentClick: () => void;
    paymentData: IPaymentTransactionModel;
    companyData: ICompany;
    invoicePaymentData: IInvoicePayment;
    saveInvoicePayment: (invoice: IInvoicePayment) => void;
    clearAllInvoicePayment: () => void;
    previewMode: boolean;
}

interface InvoiceDetailsPopupState {
    showInvoicePopup: boolean;
    showStripePaymentPopup: boolean;
    transactionReferenceNumber: string;
    paidDate: Date;
    selectedPaymentMode: string;
    remark: string;
    isPaymentFormValid: boolean;
    amountPaid: number;
    isFormDataChanged: boolean;
    disableButton: boolean;
}
const defaultDate = new Date("0001-01-01T00:00:00");


function paymentModeOptions() {
    const paymentModeOptions: ICustomDropdownOption[] = [];
    paymentModeOptions.push({ value: PaymentMode.Online, label: PaymentMode[PaymentMode.Online] });
    paymentModeOptions.push({ value: PaymentMode.Check, label: PaymentMode[PaymentMode.Check] });
    return paymentModeOptions;
}

export class InvoiceDetailsPopup extends React.Component<InvoiceDetailsPopupProps, InvoiceDetailsPopupState>{
    constructor(props: InvoiceDetailsPopupProps) {
        super(props);
        this.state = {
            paidDate: new Date(props.invoicePaymentData?.paidDate),
            transactionReferenceNumber: props.invoicePaymentData?.transactionReferenceNumber,
            showInvoicePopup: false,
            showStripePaymentPopup: false,
            selectedPaymentMode: props.invoicePaymentData?.paymentMode.toString(),
            remark: props.invoicePaymentData?.remark,
            isPaymentFormValid: false,
            amountPaid: props.invoicePaymentData?.amountPaid,
            isFormDataChanged: false,
            disableButton: false
        }
    }

    static getDerivedStateFromProps(nextProps: InvoiceDetailsPopupProps, prevState: InvoiceDetailsPopupState) {
        if (prevState.isFormDataChanged) {
            return ({
                amountPaid: prevState.amountPaid,
                paidDate: new Date(prevState.paidDate),
                transactionReferenceNumber: prevState.transactionReferenceNumber,
                selectedPaymentMode: prevState.selectedPaymentMode,
                remark: prevState.remark,
                isPaymentFormValid: prevState.isPaymentFormValid
            })
        }
        else {
            return ({
                amountPaid: nextProps.invoicePaymentData?.amountPaid,
                paidDate: new Date(nextProps.invoicePaymentData.paidDate),
                transactionReferenceNumber: nextProps.invoicePaymentData?.transactionReferenceNumber,
                selectedPaymentMode: nextProps.invoicePaymentData?.paymentMode.toString(),
                remark: nextProps.invoicePaymentData?.remark,
                isPaymentFormValid: nextProps.invoicePaymentData.amountPaid > 0 && !(Moment(nextProps.invoicePaymentData.paidDate).format('MM/DD/YYYY') === "01/01/0001") ? true : false
            })
        }

    }
    validateInvoiceForm = () => {
        const isValid = this.state.amountPaid > 0 &&
            !(Moment(this.state.paidDate).format('MM/DD/YYYY') === "01/01/0001") ? true : false;
        this.setState({
            isPaymentFormValid: isValid
        });
    }

    handleAmountChange = (value: number) => {
        this.setState({ amountPaid: value, isFormDataChanged: true }, this.validateInvoiceForm);
    }

    handleDateChange = (event: any) => {
        this.setState({ paidDate: event == null ? defaultDate : event, isFormDataChanged: true }, this.validateInvoiceForm);
    }

    handleReferenceNumberChange = (event: any) => {
        this.setState({ transactionReferenceNumber: event.target.value, isFormDataChanged: true });
    }

    handlePaymentModeChange = (selectedValue: any) => {
        this.setState({
            selectedPaymentMode: PaymentMode[selectedValue.label].toString(),
            transactionReferenceNumber: "",
            isFormDataChanged: true
        });
    }
    handleRemarkChange = (event: any) => {
        this.setState({ remark: event.target.value, isFormDataChanged: true });
    }
    onCancel = () => {
        this.setState({
            amountPaid: this.props.invoicePaymentData.amountPaid,
            transactionReferenceNumber: this.props.invoicePaymentData.transactionReferenceNumber,
            paidDate: this.props.invoicePaymentData.paidDate,
            remark: this.props.invoicePaymentData.remark,
            selectedPaymentMode: this.props.invoicePaymentData.paymentMode.toString(),
            isFormDataChanged: true,
            disableButton: false
        }, this.props.onCancel);
    }
    onclearAll = () => {
        this.setState({
            amountPaid: 0,
            transactionReferenceNumber: '',
            paidDate: defaultDate,
            remark: '',
            selectedPaymentMode: PaymentMode.Online.toString(),
            isFormDataChanged: true,
            isPaymentFormValid: false
        }, () => {
                if (this.props.invoicePaymentData.isPaid) {
                    this.props.clearAllInvoicePayment();
                }
                else {
                    MarsNotifier.Success(InvoicePaymentConstants.SuccessMessage.ClearAllInvoicePaymentSuccess, null);
                    this.onCancel();
                }
        });
    }

    onSave = () => {
        if (this.props.invoicePaymentData !== null) {
            if (this.state.amountPaid > this.props.invoiceAmount) {
                MarsNotifier.Warning(InvoicePaymentConstants.ErrorMessage.InvalidAmountPaid, null);
                return;
            }
            else {
                this.setState({ disableButton: true }, () => {
                    const invoicePayment = this.props.invoicePaymentData;
                    invoicePayment.amountDue = this.props.invoiceAmount;
                    invoicePayment.amountPaid = this.state.amountPaid;
                    invoicePayment.transactionReferenceNumber = this.state.transactionReferenceNumber;
                    invoicePayment.paidDate = this.state.paidDate;
                    invoicePayment.paymentMode = parseInt(this.state.selectedPaymentMode);
                    invoicePayment.remark = this.state.remark;
                    this.setState({ isFormDataChanged: false });
                    this.props.saveInvoicePayment(invoicePayment)
                });
            }
        }
    }

    setSelectedPaymentMode = (): ICustomDropdownOption | null => {
        return {
            label: PaymentMode[this.state.selectedPaymentMode],
            value: parseInt(this.state.selectedPaymentMode)
        };
    };

    private  renderOnlyDownloadInvoiceCard = (showReceipt:boolean) => {
		return (<>

                    {showReceipt ? 
                        
                        <>
                            <div className='paid-invoice-action'>
                                <ButtonComponent
                                    data-test-auto="CgNuimmgBh79kCiVxgq6XRrGUs"
                                    onClick={() => this.props.downloadTaxInvoice(this.props.clientId)}
                                    title="Download Invoice"
                                    variant={"outline-tertiary"}
                                    size={"medium"}
                                    className={"btn-invoice-download"}
                                >
                                    <ButtonDownloadIcon /><span className='pl-2'>Download Invoice</span>
                                </ButtonComponent>
                                <ButtonComponent
                                    data-test-auto="xc2pBfg1fTy3mXNIXfnttdTK50"
                                    onClick={this.props.onStripePaymentClick}
                                    title="View Receipt"
                                    variant={"outline-tertiary"}
                                    size={"medium"}
                                    className={"btn-invoice-download"}
                                >
                                    <ViewReceiptIcon /><span className='pl-2'>View Receipt</span>
                                </ButtonComponent>
                            </div>
                        </> :
                        <>
                            <div className='col-lg-12 col-md-12 col-sm-12 col-xs-12 no-padding invoice-download-card-container'>
                                <div className='card pay-display-card'>
                                    <div className='card-header'>
                                        Pay By Check or Money Order
                                    </div>
                                    <div className="card-body">
                                        <div className='pay-card-instruction'>
                                            <div>Your tax preparer has provided payment and mailing instructions below</div>
                                        </div>
                                        <ButtonComponent
                                            data-test-auto="CgNuimmgBh79kCiVxgq6XRrGUs"
                                            onClick={() => this.props.downloadTaxInvoice(this.props.clientId)}
                                            title="Download Invoice"
                                            variant={"outline-tertiary"}
                                            size={"medium"}
                                            buttonClassName={"btn-invoice-download"}
                                        >
                                            <ButtonDownloadIcon /><span className='pl-2'>Download Invoice</span>
                                        </ButtonComponent>
                                    </div>
                                </div>
                                </div>
                        </>
                    }
				</>
			)
	}

    private  renderPayOnlineCard = (previewMode:boolean, invoiceAmount:number,payButtonClass:string) => {
        return <div className='card pay-display-card'>
                <div className='card-header' style={{textAlign: "left"}}>
                    Pay Online
                </div>
                <div className="card-body">
                    <div className='pay-card-instruction'>
                        <div>Click button below to </div>
                        <div>make your payment.</div>
                    </div>
                    <ButtonComponent
                        data-test-auto="wotYXc2Fc74d5D9RUeNMs6DaN8"
                        onClick={() => {
                            if (!previewMode && invoiceAmount > 0) {
                                this.props.onStripePaymentClick()
                            }
                        }}
                        title="Pay Online"
                        disabled={payButtonClass != ''}
                        variant={"outline-tertiary"}
                        size={"medium"}
                        buttonClassName={`btn-invoice-download ${payButtonClass}`}
                    >
                        <PayInfoIcon /><span className='pl-2'>Pay Now</span>
                    </ButtonComponent>
                </div>
            </div>
    }

    private  renderDownloadandPayInvoiceCard = (previewMode:boolean, invoiceAmount:number, payButtonClass:string, showReceipt:boolean) => {

        const payOnlineRequired = isAttachedUrlEnabled(this.props.companyData) || isStripeEnabled(this.props.companyData);

		 return <>
            {
                payOnlineRequired ?
                <>
                    <div className='col-lg-6 col-md-6 col-sm-12 col-xs-12 no-padding' style={{marginLeft: "-9px"}}>
                        {this.renderPayOnlineCard(previewMode,invoiceAmount,payButtonClass)}
                    </div>
                    <div className='col-lg-6 col-md-6 col-sm-12 col-xs-12 no-padding'>
                        <div className='card pay-display-card'>
                            <div className='card-header' style={{textAlign: "left"}}>
                                Check or Money Order
                            </div>
                            <div className="card-body">
                                <div className='pay-card-instruction'>
                                    <div>Download your payment</div>
                                    <div>voucher.</div>
                                </div>
                                <ButtonComponent
                                    data-test-auto="NR2DMKMY595M19RDUC6EK1Y5WH"
                                    onClick={() => this.props.downloadTaxInvoice(this.props.clientId)}
                                    title="Download Invoice"
                                    variant={"outline-tertiary"}
                                    size={"medium"}
                                    buttonClassName={"btn-invoice-download"}
                                >
                                    <ButtonDownloadIcon /><span className='pl-2'>Download Invoice</span>
                                </ButtonComponent>
                            </div>
                        </div>
                    </div>
                </>: this.renderOnlyDownloadInvoiceCard(showReceipt)
            }
		</>;
	}

    private renderPaymentDetails = () => {
           return( <>
            <div className='invoice-payment-info-container'>
                <Row className="invoice-payment-title row-padd">
                    <Col md={12}>
                    Add/Update Payment Details
                    </Col>
                </Row>
                <Row className="row-padd">
                    <Col md={5} className='label-text'>
                        Amount Paid<span className="mandatory-star">*</span>
                    </Col>
                    <Col md={7}>
                        <div className="currency-wrap width195">
                            <span className="currency-code"><DollarIcon /></span>
                            <CurrencyTextBox
                                data-test-auto="0ACC92D1-F44E-4256-BBDC-2C1E21D16B36"
                                value={this.state.amountPaid}
                                onChange={this.handleAmountChange}
                                className='text-currency'
                                disabledDefaultStyle={true}
                            />
                        </div>
                    </Col>
                </Row>
                <Row className="row-padd">
                    <Col md={5} className='label-text'>
                        <label htmlFor='datePaid'>Date Paid<span className="mandatory-star">*</span></label>
                    </Col>
                    <Col md={7}>
                        <div className='datepicker-input-container width195'>
                            <CustomDatepicker
                                id={"datePaid"}
                                data-test-auto="A3FA1EBE-72B5-4739-B616-9EE4B4D77484"
                                selectedDate={Moment(this.state.paidDate).format('MM/DD/YYYY') === "01/01/0001" ? null : this.state.paidDate}
                                setSelectedDate={this.handleDateChange}
                                placeholderText='MM/DD/YYYY'
                            />
                            <div className='datepicker-icon'>
                                <CalenderIcon />
                            </div>
                        </div>
                    </Col>
                </Row>
                <Row className="row-padd">
                    <Col md={5} className='label-text'>
                        Payment Mode<span className="mandatory-star">*</span>
                    </Col>
                    <Col md={7}>
                        <CustomDropdown
                            id='drpInvoicePaymentMode'
                            selectedOption={this.setSelectedPaymentMode()}
                            setSelectedOption={this.handlePaymentModeChange}
                            options={paymentModeOptions()}
                            clearable={false}
                            searchable={false}
                            data-test-auto="271C2A00-2C22-4E8A-9EEF-966E58AAE121"
                        />
                    </Col>
                </Row>
                <Row className="row-padd">
                    <Col md={5} className='label-text'>
                        {this.state.selectedPaymentMode == PaymentMode.Online.toString() ? 'Transaction Number' : 'Check Number'}
                    </Col>
                    <Col md={7}>
                        <input type='text'
                            className="form-control width195"
                            id='txtTransactionReferenceNumber'
                            value={this.state.transactionReferenceNumber}
                            onChange={this.handleReferenceNumberChange}
                            placeholder={this.props.invoicePaymentData.paymentMode == PaymentMode.Online ? 'Enter Transaction Number' : 'Enter Check Number'}
                            data-test-auto="54678A68-8B5C-4107-9D4D-913E52F498BB"
                            aria-label='Enter Transaction Reference Number'>
                        </input>
                    </Col>
                </Row>
                <Row className="row-padd">
                    <Col md={5} className='label-text'>
                        <label htmlFor='remarks'>Remarks</label>
                    </Col>
                    <Col md={7}>
                        <textarea
                            id='remarks'
                            className="form-control width195 invoice-pay-remarks"
                            value={this.state.remark}
                            onChange={this.handleRemarkChange}
                            placeholder="Enter Remark"
                            data-test-auto="54678A68-8B5C-4107-9D4D-913E52F498BB"
                            style={{ height: "51px" }}>
                        </textarea>
                    </Col>
                </Row>
            </div>
            </>)
    }

    private renderInvoiceBody = (previewMode:boolean, invoiceAmount:number, payButtonClass : string, 
        isRenderPaymentDetails:boolean, showReceipt:boolean) => {
		return (<>
			<div className='col-lg-12 col-md-12 col-sm-12 col-xs-12 no-padding invoice-body-container'>
				<div className='col-lg-1 col-md-1 col-sm-12'></div>
				<div className='col-lg-3 col-md-3 col-sm-12'>
                    <div style={{paddingTop: '10px'}}>
                        {
                            this.props.paymentData.receiptPath ||
                            this.props.invoicePaymentData.isPaid ?                    
                                <><TaxFeePaidcon  data-test-auto="HY59RPP5DW24GCX4JH0HCL7LU4" /></> : <><TaxPreparationFeeHeaderIcon data-test-auto="HY59RPP5DW24GCX4JH0HCL7LU4" /></>
                        }
				    </div>
                </div>
				<div className='col-lg-8 col-md-8 col-sm-12 no-padding'>
					<div
						data-test-auto="TT25GUFQC8LMV4JL9UKMUL28LM"
						className="state-name-text">
                        {this.props.paymentData.receiptPath ||
                                        this.props.invoicePaymentData.isPaid ? "Tax Preparation Fee Paid" : "Tax Preparation Fee"}
					</div>
					<div className="pay-card-fee-amount">
						<span data-test-auto="NCMDQ8TLXNNMC2JZXCH89KVJLH">
							${utilities.formatCurrencyText(this.props.invoiceAmount)}
						</span>
					</div>
				</div>
			</div>
			<div className='col-lg-12 col-md-12 col-sm-12 col-xs-12 no-padding taxpreparation-card-container'>
				{
                    this.props.paymentData.receiptPath || this.props.invoicePaymentData.isPaid ?  
                        this.renderOnlyDownloadInvoiceCard(showReceipt) : this.renderDownloadandPayInvoiceCard(previewMode, invoiceAmount, payButtonClass, showReceipt)
                }
			</div>
            
            {(isRenderPaymentDetails) &&
                <div className='col-lg-12 col-md-12 col-sm-12 col-xs-12 no-padding'>
                    {this.renderPaymentDetails()}
                </div>
            }
			
		</>
		)
	}

    public render() {

        const { previewMode, invoiceAmount } = this.props;
        let modalHeightClass = "";
        let payButtonClass = "";
        let isRenderPaymentDetails = false;
        let showReceipt = false;

        if (previewMode)
        {
            payButtonClass = "cursor-not-allowed disabled";
        }

        if(((!(isStripeEnabled(this.props.companyData)) ||
            ((isStripeEnabled(this.props.companyData)) && this.props.invoicePaymentData.isPaid)) && 
            !this.props.previewMode && !this.props.paymentData.receiptPath)){
            isRenderPaymentDetails = true;
            modalHeightClass = "taxpreparation-information-modal-ex";
        }

        showReceipt= (isInvoicePaymentSuccess(this.props.paymentData) ||
                        (   !this.props.invoicePaymentData.isPaid && (isStripeEnabled(this.props.companyData) ||
                            (isAttachedUrlEnabled(this.props.companyData)))
                        )) && this.props.paymentData.receiptPath!="";

        return (<Modal
            className={`taxpreparation-information-modal ${modalHeightClass} ${showReceipt?'taxpaid-modal':''}`}
			show={this.props.show}
			onHide={this.props.onCancel}>

			<Modal.Header>
				<Modal.Title>Payment Information</Modal.Title>
					<div
						data-test-auto="LyC0nbIy8URnDC2tHfvvy0F9KC"
						className="closeIcon"
						onClick={this.onCancel}
                        tabIndex={0}
                        onKeyDown={(e: any) => handleKeyDown(e, this.onCancel)}
                    >
						<CloseIcon />
					</div>
			</Modal.Header>
			<Modal.Body>
				{
					this.renderInvoiceBody(previewMode, invoiceAmount, payButtonClass,isRenderPaymentDetails, showReceipt)
				}
			</Modal.Body>
            {((!(this.props.companyData.taxpayerPaymentSettings.payOption === PaymentOption.Stripe) ||
                ((this.props.companyData.taxpayerPaymentSettings.payOption === PaymentOption.Stripe) && this.props.invoicePaymentData.isPaid)) && !previewMode && !this.props.paymentData.receiptPath) ?
                <Modal.Footer>
                    <ButtonComponent
                        data-test-auto="C346hnS58kqdXeS1Cd9JxyMq9n"
                        onClick={this.onclearAll}
                        hidden={false}
                        variant={"outline-tertiary"}
                        size={"medium"}
                        buttonClassName={"btn-invoice-clear"}
                    >
                        Clear Details
                    </ButtonComponent>

                    <ButtonComponent 
                        data-test-auto="C346hnS58kqdXeS1Cd9JxyMq9n"
                        onClick={() => { this.onSave() }}
                        disabled={!this.state.isPaymentFormValid || this.state.disableButton}
                        hidden={false}
                        variant={"primary"}
                        size={"medium"}
                        buttonClassName={"flrt"}
                    >
                        {"Done"}
                    </ButtonComponent>
                </Modal.Footer>
                :
                <Modal.Footer>
                    <ButtonComponent 
                        data-test-auto="C346hnS58kqdXeS1Cd9JxyMq9n"
                        onClick={this.props.onCancel}
                        hidden={false}
                        variant={"primary"}
                        size={"medium"}
                        buttonClassName={"flrt"}
                    >
                        {"Done"}
                    </ButtonComponent>
                </Modal.Footer>
            }
		</Modal>);
    }
}