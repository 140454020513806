import { ApplicationState } from "../../store/index";
import { connect } from 'react-redux';
import { actionCreators as ManualSignStore } from '../../store/Sign/ManualSignStore';
import { actionCreators as CompanyStore } from '../../store/Common/Company/CompanyStore';
import { ManualSignCompleted } from './ManualSignCompleted'
import { actionCreators as SignProcessStore } from '../../store/SignProcess/SignProcessStore';
import { IClientProcessViewModel } from '../../core/domain/viewModels/IClientProcessViewModel'
import { actionCreators as DownloadableDocumentsStore } from '../../store/DownloadableDocumentsStore';

function mapStateToProps(state: ApplicationState) {
    return {
        manualSignData: state.manualSignData.data,
        companyData: state.companyData.data,
        commonData: state.commonData,
        signatureSettingModel: state.signatureSettingData,
        documentSettings: state.documentSettings,
        documentReviewModel: state.documentReviewModel,
        headInfoViewModel: state.headerInfo,
		taxReturn: state.taxReturn.taxDocument,
		clientName: state.headerInfo.clientName,
		additionalEsignDocuments: state.downloadableDocuments.additionalEsignDocuments,
	};
}
//temperroy fix for webpack failure need to invetigate further when we have time
const mapDispatchToProps = (dispatch: any) => {
    return {        
        addSignedDocument: (clientGuid: string, signedDocument: any, successCallback?: () => void, failureCallback?: () => void) => dispatch(ManualSignStore.addSignedDocument(clientGuid, signedDocument, successCallback, failureCallback)),
        completeManualSign: (clientGuid: string, successCallback?: () => void, failureCallback?: () => void) => dispatch(ManualSignStore.completeManualSign(clientGuid, successCallback, failureCallback)),
        deleteSignedDocument: (clientGuid: string, id: number, successCallback?: () => void, failureCallback?: () => void) => dispatch(ManualSignStore.deleteSignedDocument(clientGuid, id, successCallback, failureCallback)),
        requestSignedDocuments: (clientGuid: string, forceRefresh?: boolean) => dispatch(ManualSignStore.requestSignedDocuments(clientGuid, forceRefresh)),
        requestCompanyDetails: (clientGuid: string, forceRefresh?: boolean) => dispatch(CompanyStore.requestCompanyDetails(clientGuid, forceRefresh)),
        downloadEfileForms: (clientGuid: string, successCallback?: () => void) => dispatch(ManualSignStore.downloadEfileForms(clientGuid)),
        requestCurrentStepAndUserRole: (clientGuid: string, successCallback?: (clientProcessState: IClientProcessViewModel) => void) => dispatch(SignProcessStore.requestCurrentStepAndUserRole(clientGuid, successCallback)),

		requestAdditionalEsignDocument: (clientId: string) => dispatch(DownloadableDocumentsStore.requestAdditionalEsignDocument(clientId)),
		downloadAllAdditionEsignDocuments: (clientId: string, fileName: string) => dispatch(DownloadableDocumentsStore.downloadAllAdditionEsignDocuments(clientId, fileName)),

		dispatch
    }
}


const connector = connect(mapStateToProps, mapDispatchToProps);

export default connector(ManualSignCompleted);



