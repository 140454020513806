import * as React from "react";
import { Shimmer } from "../../../components/Common/Shimmer/Shimmer";
import { WelcomeSummaryConstants, CompletedSummaryConstant } from "../../../components/Common/Constants";
import { WelcomeMessage } from "../../../components/CompletedSummary/Parts/WelcomeMessage";
import { SignProcessSteps } from "../../Home/TaxpayerSignFlowManager";
import {
    SvgIconReviewDocumentsSummary,
    SvgIconSignDocumentsSummary,
    SvgIconDistributeK1sSummary,
    SvgIconTaxPaymentsSummary,
    SvgIconPaperFileDocumentsSummary,
    ReviewDraftIcon,
    ReviewPaymentsIcon
} from "../../Common/Icons/SvgIcons";
import { IVoucher } from "../../../core/domain/models/IGroup";
import { logger } from "../../../routes";
import { history } from "../../../core/services/dataAccess/History";
import { ButtonComponent } from "cp-common-ui-components";
import { TileComponent } from "cp-common-ui-components";
import { handleKeyDown } from "../../../components/Helper/HelperFunction";

export interface WelcomeProps {
    taxYear: number;
    clientName: string;
    hasShareholders: boolean;
    hasPaperFiles: boolean;
    goToStep: (stepNumber: SignProcessSteps) => void;
    goToStepById: (id: any) => void;
    vouchers: IVoucher[];
    isDraftReturn: boolean;
}

export class Welcome extends React.Component<WelcomeProps, {}> {
    constructor(props: WelcomeProps) {
        super(props);
    }

    componentDidMount() {
        const params: any = history;
        let clientId: string = "";
        if (params) {
            clientId = params.location?.pathname?.split("/").pop();
        }
        logger.trackTrace("Summary Page View", { ClientId: clientId });
    }

    validateVouchers = (vouchers: IVoucher[]): boolean => {
        for (var i = 0; i < vouchers.length; i++) {
            if (vouchers[i].amount > 0) {
                return true;
            }
        }
        return false;
    };

    getNoOfSteps = (hasShareholders: boolean, hasVoucher: boolean, hasPaperFile: boolean): number => {
        let stepsCount = 2; //Review and Sign Step
        if (hasShareholders) {
            //Distribute Step
            stepsCount++;
        }
        if (hasVoucher) {
            //Pay Step
            stepsCount++;
        }
        if (hasPaperFile) {
            //PaperFile Step
            stepsCount++;
        }
        return stepsCount;
    };

    getStepsDivClass = (noOfSteps: number): string => {
        switch (noOfSteps) {
            case 1:
                return "col-lg-12 col-md-12";
            case 2:
                return "col-lg-6 col-md-6";
            case 3:
                return "col-lg-4 col-md-4";
            case 4:
                return "col-lg-3 col-md-3";
            case 5:
                return "col-lg-3 col-md-3";
            default:
                return "col-lg-4 col-md-4";
        }
    };

    renderSummaryPageHeader = () => {
        const renderWelcomeHeader = (): string => {
            return CompletedSummaryConstant.WelcomeMessage.replace(
                "<TAXPAYERNAME>",
                this.props.clientName
            );
        };

        const renderWelcomeMessage = (): string => {
            if (this.props.isDraftReturn) {
                return WelcomeSummaryConstants.WelcomeDraftMessage;
            }
            return WelcomeSummaryConstants.WelcomeMessage.replace(
                "<TAXYEAR>",
                this.props.taxYear.toString()
            );
        };

        return this.props.clientName ? (
            <div className="welcome-container">
                <WelcomeMessage
                    customClassName='welcome-message-ready'
                    message={renderWelcomeHeader()}
                />
                <div style={{ marginTop: "5px" }}>
                    <WelcomeMessage
                        customClassName='welcome-message-ready'
                        message={renderWelcomeMessage()}
                    />
                </div>
            </div>
        ) : (
            <Shimmer height={200} />
        )
    };

    renderSummaryPageTiles = () => {
        const isValidVouchers = this.props.vouchers && this.validateVouchers(this.props.vouchers);
        const noOfSteps = this.getNoOfSteps(this.props.hasShareholders, isValidVouchers, this.props.hasPaperFiles);
    
        const renderTile = (step: SignProcessSteps, SvgIcon: any, svgClassName: string, tileDetail: string, dataTestAuto: string) => (
            <div
                className="cards"
                tabIndex={0}
                onKeyDown={(e: any) => handleKeyDown(e, () => this.props.goToStep(step))}
            >
                <TileComponent
                    onClick={() => this.props.goToStep(step)}
                    tileContent={<SvgIcon className={svgClassName} data-test-auto={dataTestAuto} />}
                    tileDetail={tileDetail}
                />
            </div>
        );
    
        const renderButton = (buttonClassName: string) => (
            <div className="summary-start-step-container flex-align-center">
                <ButtonComponent
                    data-test-auto="87742DFF-5468-44D2-B959-090842533E9E"
                    onClick={() => this.props.goToStep(SignProcessSteps.PreparerMessage)}
                    variant={"primary"}
                    buttonClassName={buttonClassName}
                    size={"medium"}
                >
                    Get Started
                </ButtonComponent>
            </div>
        );

        if (this.props.clientName && this.props.isDraftReturn) {
            return (
                <div className="cards-container">
                    <div className="welcome-page-step-img-btn cards-content">
                        {renderTile(SignProcessSteps.Invoice, ReviewDraftIcon, "icon---summary-review-documents", "Review Draft", "62D257EA-93C3-41E6-9E47-464110924398")}
                        {isValidVouchers && renderTile(SignProcessSteps.Pay, ReviewPaymentsIcon, "icon---summary-sign-documents", "Review Payments", "D26257EA-93C3-41E6-9E47-464110924398")}
                    </div>
                    {renderButton("summary-begin-btn")}
                </div>
            )
        }
    
        return this.props.clientName ? noOfSteps > 4 ? (
            <div className="cards-container">
                <div className="welcome-page-step-img-btn cards-content">
                    {renderTile(SignProcessSteps.Invoice, SvgIconReviewDocumentsSummary, "icon---summary-review-documents", "Review Documents", "62D257EA-93C3-41E6-9E47-464110924398")}
                    {renderTile(SignProcessSteps.SignMethod, SvgIconSignDocumentsSummary, "icon---summary-sign-documents", "Sign Documents", "EXZYX16V8VT451HPFZ6FLC7V6H")}
                    {renderTile(SignProcessSteps.PaperFileMessage, SvgIconPaperFileDocumentsSummary, "icon---summary-paperfile-documents", "Print and Mail Paper File Returns", "80B13E77-CBEF-4828-A7DE-6ACD70A1EA11")}
                    {renderTile(SignProcessSteps.DistributeSelection, SvgIconDistributeK1sSummary, "icon---distribute-k1s", "Distribute K-1s", "80B13E77-CBEF-4828-A7DE-6ACD70A1EA11")}
                    {renderTile(SignProcessSteps.Pay, SvgIconTaxPaymentsSummary, "icon---summary-make-payments", "Make Payments", "9CD8CB75-5602-4393-831E-4627EC43FDF1")}
                </div>
                {renderButton("summary-begin-small-btn")}
            </div>
        ) : (
            <div className="cards-container">
                <div className="welcome-page-step-img-btn cards-content">
                    {renderTile(SignProcessSteps.Invoice, SvgIconReviewDocumentsSummary, "icon---summary-review-documents", "Review Documents", "62D257EA-93C3-41E6-9E47-464110924398")}
                    {renderTile(SignProcessSteps.SignMethod, SvgIconSignDocumentsSummary, "icon---summary-sign-documents", "Sign Documents", "EXZYX16V8VT451HPFZ6FLC7V6H")}
                    {this.props.hasPaperFiles && renderTile(SignProcessSteps.PaperFileMessage, SvgIconPaperFileDocumentsSummary, "icon---summary-paperfile-documents", "Print and Mail Paper File Returns", "80B13E77-CBEF-4828-A7DE-6ACD70A1EA11")}
                    {this.props.hasShareholders && renderTile(SignProcessSteps.DistributeSelection, SvgIconDistributeK1sSummary, "icon---distribute-k1s", "Distribute K-1s", "80B13E77-CBEF-4828-A7DE-6ACD70A1EA11")}
                    {isValidVouchers && renderTile(SignProcessSteps.Pay, SvgIconTaxPaymentsSummary, "icon---summary-make-payments", "Make Payments", "9CD8CB75-5602-4393-831E-4627EC43FDF1")}
                </div>
                {renderButton("summary-begin-btn")}
            </div>
        ) : (
            <Shimmer height={500} />
        )
    };

    public render() {
        return (
            <div id="welcomeMessage" data-test-auto="43EFC177-DFB7-4DCA-9CA0-36F39098289F">
                {this.renderSummaryPageHeader()}
                {this.renderSummaryPageTiles()}
            </div>
        );
    }
}
