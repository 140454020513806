import { connect } from 'react-redux';
import MobileOTP from "./MobileOTP";
import * as headerInfoStore from "../../store/Common/AccountStore";
import * as OTPPageReducer from "../../store/OTP/OTPStore";
import * as HeaderStore from "../../store/HeaderStore";
import * as  CoverPageStore from "../../store/CoverPage/CoverPageStore";

export default connect(
    (state: any) => ({
        initialCoverState: state.CoverPageReducer,
        headerInfoState: state.HeaderPageReducer,
        OTPPageState: state.OTPPageReducer
    }),
    {
        ...CoverPageStore.actionCreators,
        ...HeaderStore.actionCreators,
        ...OTPPageReducer.actionCreators
    }
)(MobileOTP as any);