import * as React from 'react';
import { match } from "react-router";
import { DateType } from '../../../core/domain/viewModels/ICommon';
import { StepLayout } from '../../Common/StepLayout/StepLayout';
import { Body } from '../../Common/StepLayout/Body';
import {
    IDelegateeInfo, IDelegateeConsentInfo,
    initialDelegateeConsentInfo, DelegateeConsentInfo
} from '../../../core/domain/models/IDelegateeSignerModel';
import { SignProcessSteps } from '../../Home/TaxpayerSignFlowManager';
import { isValidDOB } from '../../Common/Validations';
import { MarsNotifier } from '../../Common/Notification/MarsNotifier';
import { SiginingConstants, DelegateeSignerConstants } from '../../Common/Constants';
import YearMonthDayDropdown from '../../../components/Common/YearMonthDayDropdown';

export type KbaConsentFormProps =
    {
        match: match;
        nextStep: (id: any) => void;
        delegateeInfo: IDelegateeInfo;
        consentChecked: boolean;
        onConsentAgreed(isChecked: boolean): void;
        saveDelegateeConsentDetails(clientId: string, consentDetails: IDelegateeConsentInfo, callback: () => void): void;
    }

export interface KbaConsentFormState {
    delegateeDetails: IDelegateeConsentInfo;
    isDOBUpdated: boolean;
}

export class KbaConsentForm extends React.Component<KbaConsentFormProps, KbaConsentFormState> {

    constructor(props: any) {
        super(props);

        this.state = {
            delegateeDetails: initialDelegateeConsentInfo,
            isDOBUpdated: false
        }
    }

    static getDerivedStateFromProps = (nextProps: KbaConsentFormProps, nextState: KbaConsentFormState) => {
        if (nextProps.delegateeInfo != null && nextProps.delegateeInfo != undefined &&
            nextState.delegateeDetails.email == "")
            return {
                delegateeDetails: DelegateeConsentInfo.create(nextProps.delegateeInfo.firstName,
                    nextProps.delegateeInfo.lastName, nextProps.delegateeInfo.email, "", "", "", "", new Date(), "")
            }
    }

    onChangeFirstName = (event: any) => {
        let delegateeConsent = { ...this.state.delegateeDetails };
        delegateeConsent.firstName = event.target.value;
        this.setState({ delegateeDetails: delegateeConsent });
    }

    onChangeLastName = (event: any) => {
        let delegateeConsent = { ...this.state.delegateeDetails };
        delegateeConsent.lastName = event.target.value;
        this.setState({ delegateeDetails: delegateeConsent });
    }

    onChangeHomeAddress = (event: any) => {
        let delegateeConsent = { ...this.state.delegateeDetails };
        delegateeConsent.homeAddress = event.target.value;
        this.setState({ delegateeDetails: delegateeConsent });
    }

    onChangeCity = (event: any) => {
        let delegateeConsent = { ...this.state.delegateeDetails };
        delegateeConsent.city = event.target.value;
        this.setState({ delegateeDetails: delegateeConsent });
    }

    onChangeState = (event: any) => {
        let delegateeConsent = { ...this.state.delegateeDetails };
        delegateeConsent.state = event.target.value;
        this.setState({ delegateeDetails: delegateeConsent });
    }

    onChangeZipCode = (event: any) => {
        let delegateeConsent = { ...this.state.delegateeDetails };
        delegateeConsent.zipCode = event.target.value;
        this.setState({ delegateeDetails: delegateeConsent });
    }

    onChangeSSN = (event: any) => {
        let delegateeConsent = { ...this.state.delegateeDetails };
        delegateeConsent.ssn = event.target.value;
        this.setState({ delegateeDetails: delegateeConsent });
    }

    onConsent = (e: any) => {
        this.props.onConsentAgreed(e.target.checked);
    }

    handleDOBChange = (dob: string, type: DateType) => {
        let delegateeConsent = { ...this.state.delegateeDetails };
        const birthDate: Date = delegateeConsent.dateOfBirth;
        switch (type) {
            case DateType.Date:
                birthDate.setDate(parseInt(dob));
                delegateeConsent.dateOfBirth = birthDate;
                break;
            case DateType.Month:
                birthDate.setMonth(parseInt(dob) - 1);
                delegateeConsent.dateOfBirth = birthDate;
                break;
            case DateType.Year:
                birthDate.setFullYear(parseInt(dob));
                delegateeConsent.dateOfBirth = birthDate;
                break;
        }
        this.setState({ delegateeDetails: delegateeConsent, isDOBUpdated: true });
    }

    onNext = () => {
        if (!isValidDOB(this.state.delegateeDetails.dateOfBirth)) {
            MarsNotifier.Warning(SiginingConstants.WarningMessage.InvalidDOB, null);
            return;
        }
        if (this.state.delegateeDetails.ssn.trim() == "" || this.state.delegateeDetails.ssn.trim().length < 4) {
            MarsNotifier.Warning(SiginingConstants.WarningMessage.InvalidSSN, null);
            return;
        }
        else if (this.state.delegateeDetails.firstName.trim() == '') {
            MarsNotifier.Warning(DelegateeSignerConstants.WarningMessage.EmptyFirstName, "");
            return
        }
        const param: any = this.props.match.params;
        this.props.saveDelegateeConsentDetails(param.clientId, this.state.delegateeDetails,
            () => { this.props.nextStep(SignProcessSteps.KBA) });
    }

    public render() {
        return (
            <StepLayout className='delegatee-consent-email'>
                <Body xl={7} lg={12} md={12} sm={12} xs={12}
                    containerClassName={"delegatee-kba-consent-wrapper"}
                    bodyClassName={"delegatee-consent-container"}>
                    <div className="delegatee-kba-consent-wrapper-header">
                        Sign Tax Documents Electronically
                    </div>
                    <div className="delegatee-consent" data-test-auto="4FFB32DC-4674-4682-A047-FF94DAA7E259">
                        <div className="content-paragraph">
                            Before signing, verify your identity by answering a few security questions. 
                        </div>
                        <div className="content-paragraph">
                            Verify or enter the requested information and click "Next" to proceed.
                        </div>
                        <div style={{ marginTop: "20px" }}>
                            <div className='flex-container'>
                                <div className='flex-item flex-1'>
                                    <label htmlFor='kbaFirstName'>First Name</label>
                                    <div>
                                        <input
                                            type="text"
                                            id='kbaFirstName'
                                            className='form-control'
                                            value={this.state.delegateeDetails.firstName}
                                            onChange={(e) => { this.onChangeFirstName(e) }}
                                            placeholder="Enter First Name"
                                        />
                                    </div>
                                </div>
                                <div className='flex-item flex-1'>
                                    <label htmlFor='kbaLastName'>Last Name</label>
                                    <div>
                                        <input
                                            type="text"
                                            id='kbaLastName'
                                            className='form-control'
                                            value={this.state.delegateeDetails.lastName}
                                            onChange={(e) => { this.onChangeLastName(e) }}
                                            placeholder="Enter Last Name"
                                        />
                                    </div>
                                </div>
                            </div>

                            <div className='flex-container'>
                                <div className='flex-item flex-1'>
                                    <label htmlFor='kbaHomeAddress'>Home Address</label>
                                    <div>
                                        <input
                                            type="text"
                                            id='kbaHomeAddress'
                                            className='form-control'
                                            value={this.state.delegateeDetails.homeAddress}
                                            onChange={(e) => { this.onChangeHomeAddress(e) }}
                                            placeholder="Enter Home Address"
                                        />
                                    </div>
                                </div>
                            </div>

                            <div className='flex-container'>
                                <div className='flex-item flex-2'>
                                    <label htmlFor='kbaCity'>City</label>
                                    <div>
                                        <input
                                            type="text"
                                            id='kbaCity'
                                            className='form-control'
                                            value={this.state.delegateeDetails.city}
                                            onChange={(e) => { this.onChangeCity(e) }}
                                            placeholder="Enter City"
                                        />
                                    </div>
                                </div>

                                <div className='flex-item flex-2'>
                                    <label htmlFor='kbaState'>State</label>
                                    <div>
                                        <input
                                            type="text"
                                            id='kbaState'
                                            className='form-control'
                                            value={this.state.delegateeDetails.state}
                                            onChange={(e) => { this.onChangeState(e) }}
                                            placeholder="Enter State"
                                        />
                                    </div>
                                </div>

                                <div className='flex-item flex-1'>
                                    <label htmlFor='kbaZipCode'>Zip Code</label>
                                    <div>
                                        <input
                                            type="text"
                                            id='kbaZipCode'
                                            className='form-control'
                                            value={this.state.delegateeDetails.zipCode}
                                            onChange={(e) => { this.onChangeZipCode(e) }}
                                            placeholder="Enter Zip Code"
                                        />
                                    </div>
                                </div>
                            </div>

                            <div className='flex-container'>
                                <div className='flex-2'>
                                    <label>Date of Birth</label>
                                    <YearMonthDayDropdown
                                        id="ddlYear"
                                        controlValue={this.state.delegateeDetails.dateOfBirth}
                                        onControlValueChange={this.handleDOBChange}
                                        containerClassName='delegatee-kba-consent-dob'
                                        customDropdownContainerClass='flex-1'
                                    />
                                </div>

                                <div className='flex-2'>
                                    <label htmlFor='kbaSSN'>Last 4 Digits of SSN</label>
                                    <div style={{ width: '80px' }}>
                                        <input
                                            type="text"
                                            id='kbaSSN'
                                            className='form-control'
                                            value={this.state.delegateeDetails.ssn}
                                            onChange={(e) => { this.onChangeSSN(e) }}
                                            placeholder="xxxx"
                                            maxLength={4}
                                        />
                                    </div>
                                </div>

                                <div className='flex-1'></div>
                            </div>
                        </div>
                        <div className="checkbox">
                            <ul className='ugCheckArea'>
                                <li className='eachUGCheck'>
                                    <input
                                        type="checkbox"
                                        className='ugCheckbox'
                                        data-test-auto="2BA6893F-0D36-4F45-8185-47B246FAB6E0"
                                        checked={this.props.consentChecked}
                                        onChange={this.onConsent}
                                        data-name="esign-consent"
                                        id="esign-consent"
                                    />
                                    <label
                                        data-test-auto="2BA6893F-0D36-4F45-8185-47B246FAB6E0"
                                        htmlFor="esign-consent"
                                        data-name="esign-consent"
                                        id="esign-consent"
                                    >
                                        I agree to provide my personal information in order to validate my identity.
                                    </label>
                                </li>
                            </ul>
                            <div style={{ marginLeft: '24px' }}>Identity verification powered by LexisNexis</div>
                        </div>
                    </div>
                </Body>
            </StepLayout>);
    }
}