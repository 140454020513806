import { PaymentGateway, Origin, PaymentFlowType, PaymentState } from '../../common/Enums';

export interface IPaymentTransactionModel {
    transactionId: string,
    paymentGatewayType: PaymentGateway,
    originApp: Origin,
    paymentFlow: PaymentFlowType,
    status: PaymentState,
    message: string,
    details: string,
    paymentId: string
    createdBy: string,
    createdOn?: Date,
    totalAmount: number,
    appllicationFees: number,
    clientSecret: string,
    receiptPath: string,
}

export const initialPaymentTransactionModel: Readonly<IPaymentTransactionModel> = {
    transactionId: '',
    paymentGatewayType: PaymentGateway.Stripe,
    originApp: Origin.TaxPayer,
    paymentFlow: PaymentFlowType.None,
    status: PaymentState.Initialized,
    message: '',
    details: '',
    paymentId: '',
    createdBy: '',
    totalAmount: 0,
    appllicationFees: 0,
    clientSecret: '',
    receiptPath: ''
}
