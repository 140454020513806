import { Action, Reducer } from 'redux';
import { AppThunkAction } from '../';
import { actionTypes } from '../ActionTypes';
import { StatusType, NotificationAction } from '../Common/NotificationStore';
import { IHeaderInfoViewModel, initialHeaderInfoViewModel } from '../../core/domain/viewModels/IHeaderInfoViewModel';
import { NotificationType } from '../../core/common/Enums';
import { initializeAxios } from '../../core/services/dataAccess/DataService.Axios'
import { AxiosResponse, AxiosRequestConfig } from 'axios';
import { ClientType } from '../../core/common/Enums';
import { ITaxDocumentModel, initialTaxDocumentModel } from '../../core/domain/models/ITaxDocumentModel';
import { ErrorMessages } from '../../components/Common/Constants';
import { TelemetryLogger } from '../../components/Logger/AppInsights';

const logger = TelemetryLogger.getInstance();

interface RequestHeaderInfoAction {
    type: actionTypes.HEADERINFO_REQUEST;
    id: string;
}

interface ResponseHeaderInfoAction {
    type: actionTypes.HEADERINFO_RESPONSE;
    data: IHeaderInfoViewModel;
}
interface FailureHeaderInfoAction {
    type: actionTypes.HEADERINFO_FAILURE;
    id: string;
}

interface RequestUpdatedTaxclientMobileNumberAction {
    type: actionTypes.UPDATE_TAXCLIENT_MOBILE_NUMBER_REQUEST;
}

interface ResponseUpdatedTaxclientMobileNumberAction {
    type: actionTypes.UPDATE_TAXCLIENT_MOBILE_NUMBER_RESPONSE;
    mobileNumber: string;
    countryCode: string;
    clientType: ClientType;
}

interface RequestRecipientHeaderInfoAction {
    type: actionTypes.RECIPIENT_HEADERINFO_REQUEST;
    id: string;
}

interface ResponseRecipientHeaderInfoAction {
    type: actionTypes.RECIPIENT_HEADERINFO_RESPONSE;
    headerInfo: IHeaderInfoViewModel;
}
interface FailureRecipientHeaderInfoAction {
    type: actionTypes.RECIPIENT_HEADERINFO_FAILURE;
    id: string;
}

interface RequestGroupHeaderInfoAction {
    type: actionTypes.GROUPED_RETURN_HEADERINFO_REQUEST;
    id: string;
}

interface ResponseGroupHeaderInfoAction {
    type: actionTypes.GROUPED_RETURN_HEADERINFO_RESPONSE;
    data: IHeaderInfoViewModel;
}

interface FailureGroupHeaderInfoAction {
    type: actionTypes.GROUPED_RETURN_HEADERINFO_FAILURE;
    id: string;
}
interface RequestDelegateeHeaderInfoAction {
    type: actionTypes.DELEGATEE_HEADERINFO_REQUEST;
    id: string;
}

interface ResponseDelegateeHeaderInfoAction {
    type: actionTypes.DELEGATEE_HEADERINFO_RESPONSE;
    delegateeHeaderInfo: IHeaderInfoViewModel;
}
interface FailureDelegateeHeaderInfoAction {
    type: actionTypes.DELEGATEE_HEADERINFO_FAILURE;
    id: string;
}


type KnownAction =
    DispatchAction |
    NotificationAction;

type DispatchAction = ResponseHeaderInfoAction
    | RequestHeaderInfoAction
    | FailureHeaderInfoAction
    | RequestUpdatedTaxclientMobileNumberAction
    | ResponseUpdatedTaxclientMobileNumberAction
    | RequestRecipientHeaderInfoAction
    | ResponseRecipientHeaderInfoAction
    | FailureRecipientHeaderInfoAction
    | RequestDelegateeHeaderInfoAction
    | ResponseDelegateeHeaderInfoAction
    | FailureDelegateeHeaderInfoAction
    | RequestGroupHeaderInfoAction
    | ResponseGroupHeaderInfoAction
    | FailureGroupHeaderInfoAction;

export const actionCreators = {

    requestHeaderInfo: (id: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        let state = getState();
        dispatch({ type: actionTypes.HEADERINFO_REQUEST, id: id });
        return initializeAxios().get<IHeaderInfoViewModel>('api/Helper/GetHeaderInfoAsync/' + id)
            .then(function (response: AxiosResponse<IHeaderInfoViewModel>) {

                dispatch({
                    type: actionTypes.HEADERINFO_RESPONSE, data: response.data
                });

            })
            .catch(function (error: any) {
                dispatch({
                    type: actionTypes.NOTIFICATION, statusMessage: error.response ? error.response.statusText : ErrorMessages.HeaderInfoError,
                    statusType: StatusType.Error
                });
                dispatch({ type: actionTypes.HEADERINFO_FAILURE, id: id });
                logger.trackWarning(`requestHeaderInfo for taxpayer failed with error ${error.message} for client: ${id}`), { "ClientId": id };
            });
    },

    refreshToken: (clientId: string, failurecallback: () => void): AppThunkAction<KnownAction> => (dispatch, getState) => {
        return initializeAxios().post('api/helper/refresh/' + clientId)
            .then(function () {

            })
            .catch(function (error: any) {
                dispatch({
                    type: actionTypes.NOTIFICATION, statusMessage: error.response ? error.response.statusText : "Failed to refresh token",
                    statusType: StatusType.Error
                });
                failurecallback();
                logger.trackWarning(`refreshToken failed with error ${error.message} for client: ${clientId}`, { "ClientId": clientId });
            });
    },
    logout: (clientId: string, successCallback: (id: string) => void): AppThunkAction<KnownAction> => (dispatch, getState) => {
        return initializeAxios().post('api/Authentication/Logout/' + clientId)
            .then(function (response: AxiosResponse<string>) {
                if(response.data)
                    successCallback(response.data);
            })
            .catch((error: any) => {
                console.log(error);
            });
    },

    updateMobileNumber: (clientGuid: string, id: string, number: string, countryCodeValue: string,
        ssnValue: string, clientType: ClientType, callback: () => void): AppThunkAction<KnownAction> => (dispatch, getState) => {
            const state = getState();
            dispatch({ type: actionTypes.UPDATE_TAXCLIENT_MOBILE_NUMBER_REQUEST });

            const data = { clientId: id, mobileNumber: number, countryCode: countryCodeValue, ssn: ssnValue };
            return initializeAxios().putJson(data, 'api/Esign/UpdateMobileNumber/' + clientGuid)
                .then(function (response: AxiosResponse<ITaxDocumentModel>) {
                    dispatch({
                        type: actionTypes.UPDATE_TAXCLIENT_MOBILE_NUMBER_RESPONSE, mobileNumber: data.mobileNumber, countryCode: data.countryCode, clientType: clientType
                    });
                    callback();
                })
                .catch(function (error: any) {
                    dispatch({
                        type: actionTypes.NOTIFICATION, statusMessage: error.response ? error.response.statusText : ErrorMessages.ChangeMobileNumberError,
                        statusType: StatusType.Error
                    });
                    logger.trackWarning(`updateMobileNumber failed with error ${error.message} for client: ${clientGuid}`, { "ClientId": clientGuid });
                });
        },

    requestRecipientHeaderInfo: (id: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        let state = getState();
        dispatch({ type: actionTypes.RECIPIENT_HEADERINFO_REQUEST, id: id });
        return initializeAxios().get<IHeaderInfoViewModel>('api/Helper/GetRecipientHeaderInfoAsync/' + id)
            .then(function (response: AxiosResponse<IHeaderInfoViewModel>) {

                dispatch({
                    type: actionTypes.RECIPIENT_HEADERINFO_RESPONSE, headerInfo: response.data
                });

            })
            .catch(function (error: any) {
                dispatch({
                    type: actionTypes.NOTIFICATION, statusMessage: error.response ? error.response.statusText : ErrorMessages.HeaderInfoError,
                    statusType: StatusType.Error
                });
                dispatch({ type: actionTypes.RECIPIENT_HEADERINFO_FAILURE, id: id });
                logger.trackWarning(`requestRecipientHeaderInfo for cc recipient failed with error ${error.message} for client: ${id}`, { "ClientId": id });
            });
    },

    requestDelegateeHeaderInfo: (id: string, failedCallback?: () => void): AppThunkAction<KnownAction> => (dispatch, getState) => {
        let state = getState();
        dispatch({ type: actionTypes.DELEGATEE_HEADERINFO_REQUEST, id: id });
        return initializeAxios().get<IHeaderInfoViewModel>('api/Helper/GetDelegateeHeaderInfoAsync/' + id)
            .then(function (response: AxiosResponse<IHeaderInfoViewModel>) {
                if (response.data) {
                dispatch({
                    type: actionTypes.DELEGATEE_HEADERINFO_RESPONSE, delegateeHeaderInfo: response.data
                });
                }
                else {
                 if (failedCallback) {
                     failedCallback();
                    }
                }
            })
            .catch(function (error: any) {
                dispatch({
                    type: actionTypes.NOTIFICATION, statusMessage: error.response ? error.response.statusText : ErrorMessages.HeaderInfoError,
                    statusType: StatusType.Error
                });
                dispatch({ type: actionTypes.DELEGATEE_HEADERINFO_FAILURE, id: id });
                logger.trackWarning(`requestDelegateeHeaderInfo failed with error ${error.message} for client: ${id}`, { "ClientId": id });
            });
    },


    requestGroupHeaderInfo: (id: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        let state = getState();
        dispatch({ type: actionTypes.GROUPED_RETURN_HEADERINFO_REQUEST, id: id });
        return initializeAxios().get<IHeaderInfoViewModel>('api/Helper/GetGroupHeaderInfoAsync/' + id)
            .then(function (response: AxiosResponse<IHeaderInfoViewModel>) {

                dispatch({
                    type: actionTypes.GROUPED_RETURN_HEADERINFO_RESPONSE, data: response.data
                });

            })
            .catch(function (error: any) {
                dispatch({
                    type: actionTypes.NOTIFICATION, statusMessage: error.response ? error.response.statusText : ErrorMessages.HeaderInfoError,
                    statusType: StatusType.Error
                });
                dispatch({ type: actionTypes.GROUPED_RETURN_HEADERINFO_FAILURE, id: id });
                logger.trackWarning(`requestGroupHeaderInfo failed with error ${error.message} for controller Id: ${id}`, { "ClientId": id });
            });
    },
	getSessionTimeOutSeconds: (clientId: string, callBack?: (sessionTimeout: number) => void): AppThunkAction<KnownAction> => (dispatch, getState) => {
		return initializeAxios().get<number>('api/Helper/GetSessionTimeOutSeconds/' + clientId)
			.then(function (response: AxiosResponse<number>) {					
				callBack && callBack(response.data);
			})
			.catch(function (error: any) {	
				dispatch({
					type: actionTypes.NOTIFICATION, statusMessage: error.response ? error.response.statusText : ErrorMessages.GetSessionTimeOutSecondsError,
					statusType: StatusType.Error
				});			
			});
	}
}

export const reducer: Reducer<IHeaderInfoViewModel> = (state: IHeaderInfoViewModel = initialHeaderInfoViewModel, incomingAction: Action) => {
    const action = incomingAction as DispatchAction;
    var currentState = Object.assign({}, state);
    switch (action.type) {
        case actionTypes.HEADERINFO_REQUEST:
            return initialHeaderInfoViewModel;
        case actionTypes.HEADERINFO_RESPONSE:
            if (!action.data.contactPerson) {
                action.data.contactPerson = initialHeaderInfoViewModel.contactPerson;
            }
            return action.data;
        case actionTypes.UPDATE_TAXCLIENT_MOBILE_NUMBER_REQUEST:
            currentState.data = initialTaxDocumentModel;
            return currentState;
        case actionTypes.UPDATE_TAXCLIENT_MOBILE_NUMBER_RESPONSE:
            switch (action.clientType) {
                case ClientType.Taxpayer:
                    currentState.data.taxpayer.mobileNumber = action.mobileNumber;
                    currentState.data.taxpayer.countryCode = action.countryCode;
                    break;
                case ClientType.Spouse:
                    currentState.data.spouse.mobileNumber = action.mobileNumber;
                    currentState.data.spouse.countryCode = action.countryCode;
                    break;
                case ClientType.Partnership:
                    currentState.data.partnership.mobileNumber = action.mobileNumber;
                    currentState.data.partnership.countryCode = action.countryCode;
                    break;
            }
            return currentState;
        default:
            return state || initialHeaderInfoViewModel;
    }
};

export const recipientHeaderInfoReducer: Reducer<IHeaderInfoViewModel> = (state: IHeaderInfoViewModel = initialHeaderInfoViewModel, incomingAction: Action) => {
    const action = incomingAction as DispatchAction;
    var currentState = Object.assign({}, state);
    switch (action.type) {
        case actionTypes.RECIPIENT_HEADERINFO_REQUEST:
            return initialHeaderInfoViewModel;
        case actionTypes.RECIPIENT_HEADERINFO_RESPONSE:
            return action.headerInfo;
        default:
            return state || initialHeaderInfoViewModel;
    }
};

export const groupHeaderInfoReducer: Reducer<IHeaderInfoViewModel> = (state: IHeaderInfoViewModel = initialHeaderInfoViewModel, incomingAction: Action) => {
    const action = incomingAction as DispatchAction;
    var currentState = Object.assign({}, state);
    switch (action.type) {
        case actionTypes.GROUPED_RETURN_HEADERINFO_REQUEST:
            return initialHeaderInfoViewModel;
        case actionTypes.GROUPED_RETURN_HEADERINFO_RESPONSE:
            return action.data;
        default:
            return state || initialHeaderInfoViewModel;
    }
};


export const delegateeHeaderInfoReducer: Reducer<IHeaderInfoViewModel> = (state: IHeaderInfoViewModel = initialHeaderInfoViewModel, incomingAction: Action) => {
    const action = incomingAction as DispatchAction;
    switch (action.type) {
        case actionTypes.DELEGATEE_HEADERINFO_REQUEST:
            return initialHeaderInfoViewModel;
        case actionTypes.DELEGATEE_HEADERINFO_RESPONSE:
            return action.delegateeHeaderInfo;
        default:
            return state || initialHeaderInfoViewModel;
    }
};