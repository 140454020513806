import * as React from 'react';
import Moment from 'moment/moment';
import { Modal } from 'react-bootstrap';
import { ITaxReturn } from '../../core/domain/models/ITaxReturn';
import { ITaxingAuthority } from '../../core/domain/models/ITaxingAuthority';
import { IUtilities } from '../../core/utilities/Utilities';
import { IVoucher } from '../../core/domain/models/IGroup';
import { container } from '../../startup/inversify.config';
import { TYPES } from '../../startup/types';
import { CustomButton, CustomButtonSize, CustomButtonPlacement } from '../Common/CustomButton/CustomButton';
import {
	SvgIconOnlinePayment,
	SvgIconPayTaxPrep,
	SvgIconDownloadAll,
	TaxPreparationFeeHeaderIcon,
	CircleIcon, BorderLine, 
	GetVoucherInfoIcon, 
	PayInfoIcon,
	CloseIcon,
	SvgDownloadIcon
} from '../Common/Icons/SvgIcons';
import { Common, DownloadDocumentsConstants } from '../Common/Constants';
import { AuthorityImage, StateImages } from '../Common/Icons/StateImages';
import { MarsNotifier } from '../Common/Notification/MarsNotifier';
import { ITaxpayerPaymentSettings, PaymentOption } from '../../core/domain/models/company/Company';
import { VoucherPayConstant } from "../Common/Constants";
import TaxpayerHelper from "../Helper/TaxpayerHelper";
import { handleKeyDown, openURLInNewWindow } from '../Helper/HelperFunction';

const utilities = container.get<IUtilities>(TYPES.IUtilities);


export interface PaymentInformationPopupProps {
	show: boolean;
	isInvoicePopup: boolean;
	taxingAuthority: ITaxingAuthority;
	voucher: IVoucher;
	clientId: string;
	invoiceAmount: number;
	downloadFilingInstructions: (clientId: string) => void;
	downloadTaxInvoice: (clientId: string) => void;
	downloadSingleVoucher: (clientId: string, fileName: string, pageNo: string, bookMarks: string) => void;
	onCancel(): void;
	invoicePaymentUrl?: string;
	isPreviewMode: boolean;
	taxpayerPaymentSettings: ITaxpayerPaymentSettings;
	documentUpdated?: boolean
	SetDocumentIsDownloaded?: (clientId: string) => void
	taxReturn?: ITaxReturn
}

interface PaymentInformationPopupState {
	showPopup: boolean;
}

export class PaymentInformationPopup extends React.Component<PaymentInformationPopupProps, PaymentInformationPopupState> {
	constructor(props: PaymentInformationPopupProps) {
		super(props);
		this.state = {
			showPopup: true,
		}
	}

	onDownloadInvoice = () => {
		if (!this.props.isPreviewMode) {
			this.props.downloadTaxInvoice(this.props.clientId);
		}
		else {
			MarsNotifier.Warning(DownloadDocumentsConstants.DownloadNotAllowed, null);
		}
	}

	onDownloadIndividualVoucher = (clientId: string, fileName: string, pageNo: string, bookMarks: string) => {
		if (!this.props.isPreviewMode) {
			this.props.downloadSingleVoucher(clientId, fileName, pageNo, bookMarks);
		}
		else {
			MarsNotifier.Warning(DownloadDocumentsConstants.DownloadNotAllowed, null);
		}
	}

	onDownloadFilingInstructions = () => {
		if (!this.props.isPreviewMode) {
			this.props.downloadFilingInstructions(this.props.clientId);
			this.props.taxReturn && this.props.SetDocumentIsDownloaded && this.props.documentUpdated && TaxpayerHelper.isTransmittalUpdated(this.props.taxReturn) && this.props.SetDocumentIsDownloaded(this.props.clientId)
		}
		else {
			MarsNotifier.Warning(DownloadDocumentsConstants.DownloadNotAllowed, null);
		}
	}

	private renderInvoiceBody = () => {
		return <>
			<div>
				<table className="voucher">
					<tbody>
						<tr>
							<td rowSpan={3}
								className="pay-icon image-col">
								<SvgIconPayTaxPrep
									data-test-auto="HY59RPP5DW24GCX4JH0HCL7LU4"
									className="img-responsive"
								/>
							</td>
							<td colSpan={3}
								style={{ paddingTop: "10px" }}
								className="pay-fee-name large-text"
								data-test-auto="PEEJ90MGZX0MQ8030ZKKGDYMM0">
								Tax Preparation Fee
							</td>
						</tr>
						<tr>
							<td
								style={{ paddingLeft: "0px" }}
								data-test-auto="FX36J5ZJ8A04TA8Z5VAWPHWCZ0"
								className="larger amount indent">
								<span style={{ padding: "5px" }}>Amount Due Per Invoice:</span>
								<span data-test-auto="M0E59YGDYFF4Q9L8LA3Z97MQMM" style={{ fontWeight: "bold", padding: "5px" }}>
									${utilities.formatCurrencyText(this.props.invoiceAmount)}
								</span>
							</td>
							<td />
						</tr>
						<td />
					</tbody>
				</table>
			</div>
			<div className={"pay-container"}   >
				<div className="pay-box">
					<div>
						<div
							data-test-auto="ME4VPUP6737M7G0ENCPV4R0H00"
							className="content-title">
							Pay By Check or Money Order
						</div>
						<p
							style={{ minHeight: "112px" }}
							data-test-auto="9G2PJ9YC9FV4GCG4E3WEVZVDJ8">
							Your tax preparer has provided payment and mailing instructions below
						</p>
						<br />
						<br />
						<CustomButton
							placement={CustomButtonPlacement.Center}
							size={CustomButtonSize.MD}
							icon={SvgIconDownloadAll}
							text="Download Invoice"
							onClick={this.onDownloadInvoice}
							automationTestId="NR2DMKMY595M19RDUC6EK1Y5WH"
						/>
					</div>
				</div>
				{(this.props.taxpayerPaymentSettings?.payOption === PaymentOption.AttachedURL && this.props.invoicePaymentUrl) &&
					<>
						<p style={{ margin: "auto 5px" }}>or</p>

						<div className="pay-box">
							<div>
								<div
									data-test-auto="ZVUGAL4700F4ZGD1PYL6M9D908"
									className="content-title">
									Pay Online Now
								</div>
								<p
									className="pay-info-text"
									data-test-auto="6XJHF8D4CUG4NGPWKCM4HP99D4">
									Click on the button below to make a direct payment electronically.
								</p>
								<CustomButton
									placement={CustomButtonPlacement.Center}
									size={CustomButtonSize.MD}
									icon={SvgIconOnlinePayment}
									text="Pay Online"
									onClick={() => { openURLInNewWindow(this.props.invoicePaymentUrl) }}
									automationTestId="4827D4J573843D587PZMYY1H4M"
								/>
							</div>
						</div>
					</>
				}
			</div>
		</>;
	}

	handleCancel = () => {
        this.props.onCancel();
    }

	private renderVoucherBody = () => {

		const authorityName = TaxpayerHelper.getAuthorityImageNameByState(this.props.taxingAuthority.AuthorityName);

		return <>

			<div className='col-lg-12 col-md-12 col-sm-12 col-xs-12 paymentinfo-authority-container'>
				<div className='col-lg-1 col-md-1 col-sm-12'></div>
				<div className='col-lg-4 col-md-4 col-sm-12'>
					<AuthorityImage
						data-test-auto="XN61MH4536D4Z8GJPGX8XL1UCD"
						authoriyName={authorityName}
						className="state-shadow"
						height={70}
						width={65}
					/>
				</div>
				<div className='col-lg-7 col-md-7 col-sm-12'>
					<div
						data-test-auto="TT25GUFQC8LMV4JL9UKMUL28LM"
						className="state-name-text">
						{this.props.taxingAuthority.AuthorityName == "Federal" ? "Internal Revenue Service" : this.props.taxingAuthority.AuthorityName}
					</div>
					<div className="pay-card-fee-amount">
						<span data-test-auto="NCMDQ8TLXNNMC2JZXCH89KVJLH">
							${utilities.formatCurrencyText(this.props.voucher.amount)}
						</span>
						<span data-test-auto="DFADKLHTFV6M166R2LVQ1Y9U3R" style={{ marginLeft: '10px' }} className="paydue-date-text">Due: {Moment.utc(this.props.voucher.dueDate).format('MM/DD/YYYY')}
						</span>
					</div>
				</div>
			</div>
			<BorderLine />
			<div className='col-lg-12 col-md-12 col-sm-12 col-sm-12'>
				<div className="payment-instructions-note">
					<span className='pay-circle'>1</span>
					<CircleIcon />
					<span className='payment-instructions-step'>Download Your</span>
					<span
						className="tColor"
						data-test-auto="EHA1CUJW0814Z17FGPPKUXZU2H"
						onClick={() => {
							if (this.props.taxReturn && TaxpayerHelper.anyTransmittals(this.props.taxReturn)) {
								this.onDownloadFilingInstructions();
							}
						}}
					><u>Filing Instructions</u></span>
				</div>
			</div>
			<div className='col-lg-12 col-md-12 col-sm-12 col-sm-12'>
				<div className="payment-instructions-note"><span className='pay-circle'>2</span> <CircleIcon /><span className='payment-instructions-step'>Review Payment Options and Instructions</span></div>
			</div>
			<div className='col-lg-12 col-md-12 col-sm-12 col-sm-12'>
				<div className="payment-instructions-note"><span className='pay-circle'>3</span> <CircleIcon /><span className='payment-instructions-step'>Select Your  Payment Method</span></div>
			</div>
			{this.getPaymentInfoActions()}
		</>;
	}

	private getPaymentInfoActions = () => {
		let paymentInfoDialog = [];
		let className="col-lg-6 col-md-6 col-sm-12 col-xs-12 no-padding";
		if (this.props.voucher.vocherMode == 1 && this.props.voucher.onlinePaymentUri == undefined) {
			paymentInfoDialog.push("ManualPay");
		}
		if (this.props.voucher.onlinePaymentUri && this.props.voucher.vocherMode !== 1) {
			paymentInfoDialog.push("Pay");
		}
		if (this.props.voucher.onlinePaymentUri && this.props.voucher.vocherMode === 1) {
			paymentInfoDialog.push("Pay");
		}
		if (this.props.voucher.vocherMode == 1 && this.props.voucher.onlinePaymentUri && this.props.voucher.customType !== 3) {
			paymentInfoDialog.push("ManualPay");
		}

		if(paymentInfoDialog.length == 1){
			return(<>{ paymentInfoDialog[0]=='ManualPay' ? this.getManualPayFullCard() : this.getPayFullCard()  }</>);
		}

		let dialogInfo = [];

		paymentInfoDialog.map((item) => {
			if(item == "ManualPay"){
				dialogInfo.push(<>{this.getManualPayBox(className)}</>);
			}
			else{
				dialogInfo.push(<>{this.getPayBox(className)}</>);
			}
		});
		return (<div className={"col-lg-12 col-md-12 col-sm-12 col-xs-12 no-padding paymentinfo-action-container"}> {dialogInfo} </div>);
	}

	private  getManualPayFullCard = () => {
		return (<>
					<div className='col-lg-12 col-md-12 col-sm-12 col-xs-12 no-padding payment-downloadcard-container'>
						<div className='card pay-display-card'>
							<div className='card-header'>
								Check or Money Order
							</div>
							<div className="card-body">
								<div className='pay-card-instruction'>
									<div>Download your payment.</div>
									<div>voucher.</div>
								</div>
                                
								<button
									onClick={() => { this.onDownloadIndividualVoucher(this.props.clientId, 'Voucher_' + this.props.taxingAuthority.AuthorityName + '_' + Moment(this.props.voucher.dueDate).format('MM-DD-YYYY').toString(), this.props.voucher.pageNo.toString(), this.props.voucher.bookmark) }}
									title="Download Voucher"
									data-test-auto="8B4D5347-6A26-4D66-B7D5-2ED30E40A6C7"
									className="btn btn-default rounded btn-payinfo-footer">
									<GetVoucherInfoIcon />  <span>Get Voucher</span>
								</button>
                                
							</div>
						</div>
					</div>
				</>
			)
	}

	private  getPayFullCard = () => {
		return (<>
					<div className='col-lg-12 col-md-12 col-sm-12 col-xs-12 no-padding payment-downloadcard-container'>
						<div className='card pay-display-card'>
							<div className='card-header'>
								Pay Online
							</div>
							<div className="card-body">
								<div className='pay-card-instruction'>
								<div>Click button below to </div>
								<div>make your payment.</div>
								</div>
								<button
									onClick={() => { openURLInNewWindow(this.props.voucher.onlinePaymentUri) }}
									title="Pay Online"
									data-test-auto="B9554632-64F4-4089-ACBF-83319120C206"
									className="btn btn-default rounded btn-payinfo-footer">
									<PayInfoIcon />   <span>Pay Now</span>
								</button>
							</div>
						</div>
					</div>
				</>
			)
	}

	private getPayBox = (className: string) => {

		return (
			<div className={className}>
				<div className='card pay-display-card'>
					<div className='card-header'>
						Pay Online
					</div>
					<div className="card-body">
						<div className='pay-card-instruction'>
							<div>Click button below to </div>
							<div>make your payment.</div>
						</div>
						<button
							onClick={() => { openURLInNewWindow(this.props.voucher.onlinePaymentUri) }}
							title="Pay Online"
							data-test-auto="B9554632-64F4-4089-ACBF-83319120C206"
							className="btn btn-default rounded btn-payinfo-footer">
							<PayInfoIcon />   <span>Pay Now</span>
						</button>
					</div>
				</div>
			</div>
		)
	}

	private getManualPayBox = (parentClassName: string) => {
		return (
			<div className={parentClassName}>
				<div className='card pay-display-card'>
					<div className='card-header'>
						Check or Money Order
					</div>
					<div className="card-body">
						<div className='pay-card-instruction'>
							<div>Download your payment</div>
							<div>voucher.</div>
						</div>
						<button
							onClick={() => { this.onDownloadIndividualVoucher(this.props.clientId, 'Voucher_' + this.props.taxingAuthority.AuthorityName + '_' + Moment(this.props.voucher.dueDate).format('MM-DD-YYYY').toString(), this.props.voucher.pageNo.toString(), this.props.voucher.bookmark) }}
							title="Download Voucher"
							data-test-auto="8B4D5347-6A26-4D66-B7D5-2ED30E40A6C7"
							className="btn btn-default rounded btn-payinfo-footer">
							<GetVoucherInfoIcon />  <span>Get Voucher</span>
						</button>
					</div>
				</div>
			</div>
		)
	}

	public render() {
		return (<Modal
			className={ this.props.isInvoicePopup ? "taxpreparation-information-modal" : "payment-information-modal"}
			show={this.props.show}
			onHide={this.props.onCancel}>

			<Modal.Header className='darkBackground'>
				<Modal.Title>Payment Information</Modal.Title>
					<div
						data-test-auto="LWA2HTLWVK54TCLGQAA4QX1ZM8"
						className="closeIcon"
						onClick={this.handleCancel}
						tabIndex={0}
						onKeyDown={(e: any) => handleKeyDown(e, this.handleCancel)}
					>
						<CloseIcon />
					</div>
			</Modal.Header>
			<Modal.Body>
				{
					this.props.isInvoicePopup ? this.renderInvoiceBody() : this.renderVoucherBody()
				}
			</Modal.Body>
		</Modal>);
	}
}