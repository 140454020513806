import * as React from 'react';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { ITaxpayerPaymentSettings } from '../../core/domain/models/company/Company';
import { IPaymentTransactionModel } from '../../core/domain/models/IPaymentTransactionModel';
import InjectedCheckoutForm from './InjectedCheckoutForm';

interface IPaymentProps {
    showState: boolean;
    onCancel(): void;
    invoiceAmount: number;
    paymentData: IPaymentTransactionModel;
    paymentSetting: ITaxpayerPaymentSettings; 
    clientId: string;
    updatePurchaseTransactionStatus: (
        clientId: string,
        paymentTransaction: IPaymentTransactionModel,
        callback?: () => void
    ) => void;
}


export class StripePayment extends React.Component<IPaymentProps, {}>{
    private stripePromise: any;
    constructor(props: any) {
        super(props);
        this.stripePromise = loadStripe(window.Variables.publicKey, { stripeAccount: this.props.paymentSetting.companyConnectedAccountId })
    } 

    public render() {
        return (
            <Elements stripe={this.stripePromise}>
                <InjectedCheckoutForm
                    showState={this.props.showState}
                    onCancel={this.props.onCancel}
                    invoiceAmount={this.props.invoiceAmount}
                    paymentData={this.props.paymentData}
                    paymentSetting={this.props.paymentSetting}
                    clientId={this.props.clientId}
                    updatePurchaseTransactionStatus={this.props.updatePurchaseTransactionStatus}
                />
            </Elements>
        )
    }
}


