import * as React from 'react';
import { Modal, Col, Row } from 'react-bootstrap';
import { match } from 'react-router';
import { History } from 'history';
import { BroadcastChannel } from 'broadcast-channel';

import { Countdown } from '../Helper/CountDown';
import { ILoggedInUserInfo } from '../../core/domain/viewModels/ILoggedInUserInfo'
import { Role } from '../../core/common/Enums';
import { SessionTimeout as TimeOutSvg } from '../Common/Icons/CustomGraphics';
import { ILogoutMessage } from '../../core/domain/models/ILogoutMessage';
import { PathConstants } from '../Common/Constants';
import { ButtonComponent } from 'cp-common-ui-components';

interface ISessionTimeoutProps {
    history: History;
    match: match;
    showModal: boolean;
    onStayAlive(event: any): void;
    countDownMinutes: number;
    loggedInUserInfo: ILoggedInUserInfo;
    logout: (clientGuid: string, callback: (id: string) => void) => void;
}



export class SessionTimeout extends React.Component<ISessionTimeoutProps, {}> {
    private countDown?: Countdown;
    private channel:any = null;

    constructor(props: ISessionTimeoutProps) {
        super(props);
        this.countDown = undefined;

        this.initializeChannel = this.initializeChannel.bind(this);
    }





    componentDidMount() {
        this.initializeChannel();
        this.channel.onmessage = (message: ILogoutMessage) => this.onLogoutMessage(message);
    }

    componentDidUpdate() {
        this.initializeChannel();
    }

    initializeChannel() {
        if (this.channel === null || this.channel.closed) {
            this.channel = new BroadcastChannel('ssr-taxpayer');
        }
    }


    componentWillUnmount() {
        this.channel.close();
    }
    onLogOutCompletion = (id: string) => {
        let param: any = this.props.match.params;
        const url = param.controllerId ? PathConstants.ControllerLogin + id : PathConstants.ClientLogin + id;
        this.props.history.push(url);
    }
    private onLogoutMessage = (message: ILogoutMessage) => {
        let param: any = this.props.match.params;
        if (message.isLoggedOut && (param.controllerId === message.clientGuid || param.clientId === message.clientGuid)) {
            setTimeout(() => {
                window.location.reload();
            }, 1000);
        }
    }

    public render() {
        return <Modal
            className="session-timeout-modal"
            show={this.props.showModal}
            onHide={this.onLogout}>
            <Modal.Header closeButton data-test-auto="60334535-F01C-41D3-AC34-CA9BC7B804DD">
                <Modal.Title>
                    <span className='modalIcon text-secondary fa fa-clock'>
                    </span>Warning
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Row data-test-auto="F12E9E3A-5B0D-4463-9329-750FAA9B7D12">
                    <Col lg={3}>
                        <TimeOutSvg
                            width={100}
                            height={100}
                            className="svg-shadow"
                        />
                    </Col>
                    <Col lg={9}>
                        <h2 style={{ color: '#337ab7' }}>Session Expiration</h2>
                        <div>Your session will expire in <Countdown
                            date={this.getCountDown()}
                            onComplete={this.onCountDownComplete}
                            ref={(instance: any) => { this.countDown = instance }} /> 
                        </div>
                        <div>Do you want to continue working?</div>
                    </Col>
                </Row>
            </Modal.Body>
            <Modal.Footer>
                <ButtonComponent
                    data-test-auto="C0280D93-94DD-438B-9AB9-4F5BC452FE66"
                    onClick={this.onLogout}
                    variant={"outline-tertiary"}
                    size={"medium"}
                    buttonClassName={"button-margin-right"}
                >
                    <i className="fas fa-sign-out-alt"></i>
                    <span className='pl-2'>Logoff</span>
                </ButtonComponent>
                <ButtonComponent
                    data-test-auto="CD93CDEF-A642-4D28-9C11-E6913BC2C2C0"
                    onClick={this.onStayAlive}
                    variant={"primary"}
                    size={"medium"}
                >
                    <i className="fas fa-check-double"></i>
                    <span className='pl-2'>Continue Working</span>
                </ButtonComponent>
            </Modal.Footer>
        </Modal>
    }


    private getCountDown = (): Date => {
        const now = new Date();
        let ret = now;
        ret.setMinutes(now.getMinutes() + this.props.countDownMinutes);
        return ret;
    }

    private onStayAlive = (e: any) => {
        if (this.countDown) {
            clearInterval(this.countDown.interval);
        }
        this.props.onStayAlive(e);
    }

    private onLogout = (e?: React.SyntheticEvent<EventTarget>) => {
       e && e.preventDefault();
        this.onCountDownComplete();
    }
    private onCountDownComplete = () => {
        if (this.props.loggedInUserInfo.role.toString() === Role[Role.CPA].toString()) {
            window.close();
        } else {

            let param: any = this.props.match.params;

            const message: ILogoutMessage = {
                isLoggedOut: true,
                clientGuid: param.controllerId ? param.controllerId : param.clientId,
            };
            
            this.channel.postMessage(message).then((value: any) => {
                this.props.logout(param.controllerId ? param.controllerId : param.clientId, this.onLogOutCompletion);
            }).catch((error: any) => {
                console.log(error);
            });
        }
    }


}

