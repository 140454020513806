import { Action, Reducer } from 'redux';
import { AppThunkAction } from './';
import { actionTypes } from './ActionTypes';
import { StatusType, NotificationAction } from './Common/NotificationStore';
import { IDownloadableDocumentsViewModel, initialDownloadableDocumentsViewModel } from '../core/domain/viewModels/IDownloadableDocumentsViewModel';
import { initializeAxios } from '../core/services/dataAccess/DataService.Axios';
import { AxiosResponse, AxiosRequestConfig } from 'axios';
import { DisplayDownloadFile } from '../components/Common/DisplayDownloadFile';
import { IDownloadedZipFilesModel, MyDownloadStatus } from '../components/Layout/MyDownload';
import { MyDownloadsConstants } from '../components/Common/Constants';
import { ILoader } from '../core/utilities/ui/Loader';
import { TYPES } from '../startup/types';
import { container } from '../startup/inversify.config';
import { IAdditionalEsignDocument } from '../core/domain/models/IAdditionalEsignDocument';
import { IFileUtilities } from '../core/utilities/File/FileUtilities';
import { TelemetryLogger } from '../components/Logger/AppInsights';

const logger = TelemetryLogger.getInstance();


interface RequestDownloadableDocumentsAction {
	type: actionTypes.DOWNLOADABLE_DOCUMENTS_REQUEST;
	clientId: string
}

interface ResponseDownloadableDocumentsAction {
	type: actionTypes.DOWNLOADABLE_DOCUMENTS_RESPONSE;
	data: IDownloadableDocumentsViewModel;
}

interface FailureDownloadableDocumentsAction {
	type: actionTypes.DOWNLOADABLE_DOCUMENTS_FAILURE;
	clientId: string

}

interface RequestMyDownloadAction {
	type: actionTypes.MY_DOWNLOAD_REQUEST;
}

export interface ResponseMyDownloadAction {
	type: actionTypes.MY_DOWNLOAD_RESPONSE;
	data: IDownloadedZipFilesModel[];
}
interface FailureMyDownloadAction {
	type: actionTypes.MY_DOWNLOAD_FAILURE;
}

interface RequestAdditionalEsignDocument {
	type: actionTypes.ADDITIONAL_ESIGN_DOCUMENT_REQUEST;
}

export interface ResponseAdditionalEsignDocument {
	type: actionTypes.ADDITIONAL_ESIGN_DOCUMENT_RESPONSE;
	data: IAdditionalEsignDocument[];
}

type KnownAction =
	DispatchAction |
	NotificationAction;

type DispatchAction = ResponseDownloadableDocumentsAction
	| RequestDownloadableDocumentsAction
	| FailureDownloadableDocumentsAction
	| RequestMyDownloadAction
	| ResponseMyDownloadAction
	| RequestAdditionalEsignDocument
	| ResponseAdditionalEsignDocument
	| FailureMyDownloadAction;

const loader = container.get<ILoader>(TYPES.ILoader);
const fileUtilities = container.get<IFileUtilities>(TYPES.IFileUtilities);
export const actionCreators = {

	requestDownloadableDocuments: (clientId: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
		let state = getState();
		dispatch({ type: actionTypes.DOWNLOADABLE_DOCUMENTS_REQUEST, clientId: clientId });
		return initializeAxios().get<IDownloadableDocumentsViewModel>('api/DownloadableDocument/GetAllAsync/' + clientId)
			.then(function (response: AxiosResponse<IDownloadableDocumentsViewModel>) {

				dispatch({
					type: actionTypes.DOWNLOADABLE_DOCUMENTS_RESPONSE, data: response.data
                });

                if (state.downloadableDocuments.myDownloadList === undefined || state.downloadableDocuments.myDownloadList.length === 0) {
                    dispatch(actionCreators.requestMyDownload(clientId));
                }
			})
			.catch(function (error: any) {
				dispatch({
					type: actionTypes.NOTIFICATION, statusMessage: error.response ? error.response.statusText : "Failed to fetch document list",
					statusType: StatusType.Error
				});
				dispatch({ type: actionTypes.DOWNLOADABLE_DOCUMENTS_FAILURE, clientId: clientId });
				logger.trackWarning(`requestDownloadableDocuments failed to with error ${error.message}`, { "ClientId": clientId });
			});
	},
	downloadTaxReturnDocument: (clientId: string, fileName: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
		loader.show();
		return initializeAxios().get('api/Download/GetTaxReturnDocumentSasAsync/' + clientId)
			.then(function (response: any) {
				let displayDownloadFile = new DisplayDownloadFile();
				displayDownloadFile.directDownload(response.data);
				loader.hide();
			}).catch(function (error: any) {
				dispatch({
					type: actionTypes.NOTIFICATION, statusMessage: error.response ? error.response.statusText : "Failed to download Tax Return",
					statusType: StatusType.Error
				});
				logger.trackWarning(`downloadTaxReturnDocument failed to with error ${error.message}`, { "ClientId": clientId });
			});
	},
	downloadEFileDocument: (clientId: string, fileName: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
		loader.show();
		return initializeAxios().get('api/Download/GetEfileDocumentSas/' + clientId)
			.then(function (response: any) {
				let displayDownloadFile = new DisplayDownloadFile();
				displayDownloadFile.directDownload(response.data);
				loader.hide();
			}).catch(function (error: any) {
				dispatch({
					type: actionTypes.NOTIFICATION, statusMessage: error.response ? error.response.statusText : "Failed to download E-File",
					statusType: StatusType.Error
				});
				logger.trackWarning(`downloadEFileDocument failed to with error ${error.message} for client: ${clientId}`, { "ClientId": clientId });
			});
	},
	downloadSignedEFileDocument: (clientId: string, fileName: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
		loader.show();
		let config: AxiosRequestConfig = { responseType: 'arraybuffer', headers: { 'Content-Type': 'application/json;utf-8' } };
		return initializeAxios().get('api/Download/GetSignedEfileDocumentStreamAsync/' + clientId, config)
			.then(function (response: any) {
				const contentDisposition = response.headers["content-disposition"];
				const fileNameMatch = contentDisposition ? /filename="?([^"]*)"?;/g.exec(contentDisposition) : undefined;
				if (fileNameMatch && fileNameMatch.length > 1) {
					fileName = fileNameMatch[1];
				}
				let displayDownloadFile = new DisplayDownloadFile();
				displayDownloadFile.showFile(response.data, fileUtilities.getSafeFilename(fileName));
				loader.hide();
			}).catch(function (error: any) {
				dispatch({
					type: actionTypes.NOTIFICATION, statusMessage: error.response ? error.response.statusText : "Failed to download E-File",
					statusType: StatusType.Error
				});
				logger.trackWarning(`downloadSignedEFileDocument failed to with error ${error.message} for client: ${clientId}`, { "ClientId": clientId });
			});
	},
	downloadTransmittalDocument: (clientId: string, fileName: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
		loader.show();
		return initializeAxios().get('api/Download/GetTransmittalDocumentSasAsync/' + clientId)
			.then(function (response: any) {
				let displayDownloadFile = new DisplayDownloadFile();
				displayDownloadFile.directDownload(response.data);
				loader.hide();
			}).catch(function (error: any) {
				dispatch({
					type: actionTypes.NOTIFICATION, statusMessage: error.response ? error.response.statusText : "Failed to download Transmittal",
					statusType: StatusType.Error
				});
				logger.trackWarning(`downloadTransmittalDocument failed to with error ${error.message} for client: ${clientId}`, { "ClientId": clientId });
			});
	},
	downloadInvoiceDocument: (clientId: string, fileName: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
		loader.show();
		return initializeAxios().get('api/Download/GetInvoiceDocumentSasAsync/' + clientId)
			.then(function (response: any) {
				let displayDownloadFile = new DisplayDownloadFile();
				displayDownloadFile.directDownload(response.data);
				loader.hide();
			}).catch(function (error: any) {
				dispatch({
					type: actionTypes.NOTIFICATION, statusMessage: error.response ? error.response.statusText : "Failed to download Invoice",
					statusType: StatusType.Error
				});
				logger.trackWarning(`downloadInvoiceDocument failed to with error ${error.message} for client: ${clientId}`, { "ClientId": clientId });
			});
	},
	downloadK1Document: (clientId: string, fileName: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
		loader.show();
		return initializeAxios().get('api/Download/GetK1DocumentSasAsync/' + clientId)
			.then(function (response: any) {
				let displayDownloadFile = new DisplayDownloadFile();
				displayDownloadFile.directDownload(response.data);
				loader.hide();
			}).catch(function (error: any) {
				dispatch({
					type: actionTypes.NOTIFICATION, statusMessage: error.response ? error.response.statusText : "Failed to download K1-Document",
					statusType: StatusType.Error
				});
				logger.trackWarning(`downloadK1Document failed with error ${error.message} for client: ${clientId}`, { "ClientId": clientId });
			});
	},
	downloadVoucherDocument: (clientId: string, fileName: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
		loader.show();
		return initializeAxios().get('api/Download/GetVoucherDocumentSasAsync/' + clientId + "?fileName=" + fileName)
			.then(function (response: any) {
				let displayDownloadFile = new DisplayDownloadFile();
				displayDownloadFile.directDownload(response.data);
				loader.hide();
			}).catch(function (error: any) {
				dispatch({
					type: actionTypes.NOTIFICATION, statusMessage: error.response ? error.response.statusText : "Failed to download Voucher",
					statusType: StatusType.Error
				});
				logger.trackWarning(`downloadVoucherDocument failed with error ${error.message} for client: ${clientId}`, { "ClientId": clientId });
			});
	},

	downloadK1Zip: (clientId: string, downloadId: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
		loader.show();
		let fileName: string;
		let config: AxiosRequestConfig = { responseType: 'arraybuffer', headers: { 'Content-Type': 'application/json;utf-8' } };
		return initializeAxios().post<string>('api/Download/Getk1DownloadedFile/' + downloadId + '/' + clientId, null, config)
			.then(function (response: any) {
				const contentDisposition = response.headers["content-disposition"];
				const fileNameMatch = contentDisposition ? /filename="?([^"]*)"?;/g.exec(contentDisposition) : undefined;
				if (fileNameMatch && fileNameMatch.length > 1) {
					fileName = fileNameMatch[1];
				}
				let displayDownloadFile = new DisplayDownloadFile();
				displayDownloadFile.showFile(response.data, fileUtilities.getSafeFilename(fileName));
				loader.hide();
			}).catch(function (error: any) {
				dispatch({
					type: actionTypes.NOTIFICATION, statusMessage: error.response ? error.response.statusText : "Failed to download K1",
					statusType: StatusType.Error
				});
				logger.trackWarning(`downloadK1Zip failed with error ${error.message} for client: ${clientId}`, { "ClientId": clientId });
			});
	},

	downloadAttachment: (clientId: string, fileName: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
		loader.show();
		return initializeAxios().get('api/Download/GetAttachmentSasAsync/' + clientId + "?fileName=" + fileName)
			.then(function (response: any) {
				let displayDownloadFile = new DisplayDownloadFile();
				displayDownloadFile.directDownload(response.data);
				loader.hide();
			}).catch(function (error: any) {
				dispatch({
					type: actionTypes.NOTIFICATION, statusMessage: error.response ? error.response.statusText : "Failed to download Attachments",
					statusType: StatusType.Error
				});
				logger.trackWarning(`downloadAttachment failed with error ${error.message} for client: ${clientId}`, { "ClientId": clientId });
			});
	},
	downloadAllDocuments: (clientId: string, fileName: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
		loader.show();
		return initializeAxios().get('api/Download/GetAllDocumentZipSasAsync/' + clientId)
			.then(function (response: any) {
				let displayDownloadFile = new DisplayDownloadFile();
				if (response.data && response.data.length > 0) {
					displayDownloadFile.directDownload(response.data);
					loader.hide();
				}
				else {
					let config: AxiosRequestConfig = { responseType: 'arraybuffer', headers: { 'Content-Type': 'application/json;utf-8' } };
					return initializeAxios().get('api/Download/GetAllDocumentsZipStreamAsync/' + clientId, config)
						.then(function (response: any) {
							displayDownloadFile.showFile(response.data, fileUtilities.getSafeFilename(fileName));
							loader.hide();
						}).catch(function (error: any) {
							dispatch({
								type: actionTypes.NOTIFICATION, statusMessage: error.response ? error.response.statusText : "Failed to download All Attachments",
								statusType: StatusType.Error
							});
							logger.trackWarning(`downloadAllDocuments failed with error ${error.message} for client: ${clientId}`, { "ClientId": clientId });
						});
				}
			}).catch(function (error: any) {
				dispatch({
					type: actionTypes.NOTIFICATION, statusMessage: error.response ? error.response.statusText : "Failed to download All Documents",
					statusType: StatusType.Error
				});
				logger.trackWarning(`downloadAllDocuments failed with error ${error.message} for client: ${clientId}`, { "ClientId": clientId });
			});
	},
	downloadAllAttatchments: (clientId: string, fileName: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
		loader.show();
		return initializeAxios().get('api/Download/GetAllAttachmentsZipSasAsync/' + clientId)
			.then(function (response: any) {
				let displayDownloadFile = new DisplayDownloadFile();
				if (response.data && response.data.length > 0) {
					displayDownloadFile.directDownload(response.data);
					loader.hide();
				}
				else {
					let config: AxiosRequestConfig = { responseType: 'arraybuffer', headers: { 'Content-Type': 'application/json;utf-8' } };
					return initializeAxios().get('api/Download/GetAllAttachmentsZipStreamAsync/' + clientId, config)
						.then(function (response: any) {
							displayDownloadFile.showFile(response.data, fileUtilities.getSafeFilename(fileName));
							loader.hide();
						}).catch(function (error: any) {
							dispatch({
								type: actionTypes.NOTIFICATION, statusMessage: error.response ? error.response.statusText : "Failed to download All Attachments",
								statusType: StatusType.Error
							});
							logger.trackWarning(`downloadAllAttachments failed with error ${error.message} for client: ${clientId}`, { "ClientId": clientId });
						});
				}
			}).catch(function (error: any) {
				dispatch({
					type: actionTypes.NOTIFICATION, statusMessage: error.response ? error.response.statusText : "Failed to download All Attachments",
					statusType: StatusType.Error
				});
				logger.trackWarning(`downloadAllAttachments failed with error ${error.message} for client: ${clientId}`, { "ClientId": clientId });
			});
	},
	downloadAllAdditionEsignDocuments: (clientId: string, fileName: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
		loader.show();
		return initializeAxios().get('api/Download/GetAllAdditionEsignDocumentsSasAsync/' + clientId)
			.then(function (response: any) {
				let displayDownloadFile = new DisplayDownloadFile();
				if (response.data && response.data.length > 0) {
					displayDownloadFile.directDownload(response.data);
					loader.hide();
				}
				else {
					let config: AxiosRequestConfig = { responseType: 'arraybuffer', headers: { 'Content-Type': 'application/json;utf-8' } };
					return initializeAxios().get('api/Download/GetAllAdditionalEsignDocumentsZipStreamAsync/' + clientId, config)
						.then(function (response: any) {
							displayDownloadFile.showFile(response.data, fileUtilities.getSafeFilename(fileName));
							loader.hide();
						}).catch(function (error: any) {
							dispatch({
								type: actionTypes.NOTIFICATION, statusMessage: error.response ? error.response.statusText : "Failed to download All Attachments",
								statusType: StatusType.Error
							});
							logger.trackWarning(`downloadAllAdditionEsignDocuments failed with error ${error.message} for client: ${clientId}`, { "ClientId": clientId });
						});
				}
			}).catch(function (error: any) {
				dispatch({
					type: actionTypes.NOTIFICATION, statusMessage: error.response ? error.response.statusText : "Failed to download Other Signed Documents",
					statusType: StatusType.Error
				});
				logger.trackWarning(`downloadAllAdditionEsignDocuments failed with error ${error.message} for client: ${clientId}`, { "ClientId": clientId });
			});
	},
	downloadAllAdditionEsignDocumentsForPreview: (clientId: string, fileName: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
		loader.show();
		let config: AxiosRequestConfig = { responseType: 'arraybuffer', headers: { 'Content-Type': 'application/json;utf-8' } };
		return initializeAxios().get('api/Download/GetAllAdditionalEsignDocumentsZipStreamForPreviewAsync/' + clientId, config)
			.then(function (response: any) {
				let displayDownloadFile = new DisplayDownloadFile();
				displayDownloadFile.showFile(response.data, fileUtilities.getSafeFilename(fileName));
				loader.hide();
			}).catch(function (error: any) {
				dispatch({
					type: actionTypes.NOTIFICATION, statusMessage: error.response ? error.response.statusText : "Failed to download Other Signed Documents",
					statusType: StatusType.Error
				});
				logger.trackWarning(`downloadAllAdditionEsignDocumentsForPreview failed with error ${error.message} for client: ${clientId}`, { "ClientId": clientId });
			});
	},
	requestMyDownload: (clientId: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
		const state = getState();
		return initializeAxios().get<IDownloadedZipFilesModel[]>('api/Download/GetMyDownload/' + clientId)
			.then(function (response: AxiosResponse<IDownloadedZipFilesModel[]>) {
				dispatch({
					type: actionTypes.MY_DOWNLOAD_RESPONSE, data: response.data
				});
			})
			.catch(function (error: any) {
				dispatch({
					type: actionTypes.NOTIFICATION, statusMessage: error.response?.statusText ?? error.message,
					statusType: StatusType.Error
				});
				dispatch({ type: actionTypes.MY_DOWNLOAD_FAILURE });
				logger.trackWarning(`requestMyDownload failed with error ${error.message} for client: ${clientId}`, { "ClientId": clientId });
			});
	},

	deleteMyDownloads: (downloadId: string, clientId: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
		let state = getState();
		return initializeAxios().post('api/Download/DeleteMyDownloadAsync/' + clientId + "?downloadId=" + downloadId)
			.then(function (response: any) {

				let data: any[] = state.downloadableDocuments.myDownloadList;
				data?.splice(data.findIndex(m => m.id === downloadId), 1);

				dispatch({
					type: actionTypes.MY_DOWNLOAD_RESPONSE, data: data
				});

				dispatch({
					type: actionTypes.NOTIFICATION, statusMessage: MyDownloadsConstants.DeleteMyDownload,
					statusType: StatusType.Success
				});

			})
			.catch(function (error: any) {
				dispatch({
					type: actionTypes.NOTIFICATION, statusMessage: error.response ? error.response.statusText : "Failed to Delete the Selected File",
					statusType: StatusType.Error
				});
				dispatch({ type: actionTypes.MY_DOWNLOAD_FAILURE });
				logger.trackWarning(`deleteMyDownloads failed with error ${error.message} for client: ${clientId}`, { "ClientId": clientId });
			});
	},

	clearAllMyDownloads: (clientId: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
		let state = getState();
		return initializeAxios().post('api/Download/ClearAllDownloadsAsync/' + clientId)
			.then(function (response: any) {

				let data: any[] = state.downloadableDocuments.myDownloadList;
				data = data?.filter(function (download) {
					return download.status === MyDownloadStatus.InProgress;
				});

				dispatch({
					type: actionTypes.MY_DOWNLOAD_RESPONSE, data: data
				});

				dispatch({
					type: actionTypes.NOTIFICATION, statusMessage: MyDownloadsConstants.ClearAllMyDownloads,
					statusType: StatusType.Success
				});

			})
			.catch(function (error: any) {
				dispatch({
					type: actionTypes.NOTIFICATION, statusMessage: error.response ? error.response.statusText : "Failed to Clear your Downloads",
					statusType: StatusType.Error
				});
				dispatch({ type: actionTypes.MY_DOWNLOAD_FAILURE });
				logger.trackWarning(`clearAllMyDownloads failed with error ${error.message} for client: ${clientId}`, { "ClientId": clientId });
			});
	},

	requestAdditionalEsignDocument: (clientId: string, callback?:()=>void): AppThunkAction<KnownAction> => (dispatch, getState) => {
		const state = getState();
		dispatch({ type: actionTypes.ADDITIONAL_ESIGN_DOCUMENT_REQUEST });
		return initializeAxios().get<IAdditionalEsignDocument[]>('api/DownloadableDocument/GetAdditionalEsignDocumentsAsync/' + clientId)
			.then(function (response: AxiosResponse<IAdditionalEsignDocument[]>) {
				callback && callback();
				dispatch({
					type: actionTypes.ADDITIONAL_ESIGN_DOCUMENT_RESPONSE, data: response.data
				});
			})
			.catch(function (error: any) {
				dispatch({
					type: actionTypes.NOTIFICATION, statusMessage: error.response?.statusText ?? error.message,
					statusType: StatusType.Error
				});
				logger.trackWarning(`requestAdditionalEsignDocument failed with error ${error.message} for client: ${clientId}`, { "ClientId": clientId });
			});
	},
}

export const reducer: Reducer<IDownloadableDocumentsViewModel> = (state: IDownloadableDocumentsViewModel = initialDownloadableDocumentsViewModel, incomingAction: Action) => {
	const action = incomingAction as DispatchAction;
	var data = Object.assign({}, state);
	switch (action.type) {
		case actionTypes.DOWNLOADABLE_DOCUMENTS_REQUEST:
			return initialDownloadableDocumentsViewModel;
		case actionTypes.DOWNLOADABLE_DOCUMENTS_RESPONSE:
			return action.data;
		case actionTypes.MY_DOWNLOAD_REQUEST:
			data.myDownloadList = [];
			return data;
		case actionTypes.MY_DOWNLOAD_RESPONSE:
			data.myDownloadList = action.data;
			return data;
		case actionTypes.ADDITIONAL_ESIGN_DOCUMENT_REQUEST:
			data.additionalEsignDocuments = [];
			return data;
		case actionTypes.ADDITIONAL_ESIGN_DOCUMENT_RESPONSE:
			data.additionalEsignDocuments = action.data;
			return data;
		default:
			return state || initialDownloadableDocumentsViewModel;
	}
};