import * as React from 'react';
import { ViewerMode } from 'awesome-pdf-viewer/dist/viewer/ViewerBase';
import * as TaxDocumentStore from '../../store/Common/TaxDocumentStore';
import { match } from 'react-router';
import * as DocumentReviewStore from '../../store/ReviewStore';
import { History } from 'history';
import { PathConstants } from '../Common/Constants';
import { StepLayout } from '../Common/StepLayout/StepLayout';
import { Body } from '../Common/StepLayout/Body';
import { Footer } from '../Common/StepLayout/Footer';
import { Next } from '@sssuite-component-ui/multi-step-wizard/dist/Footer/Next';
import { Previous } from '@sssuite-component-ui/multi-step-wizard/dist/Footer/Previous';
import TaxpayerHelper from '../Helper/TaxpayerHelper';
import { IClientProcessViewModel } from '../../core/domain/viewModels/IClientProcessViewModel'
import * as SignProcessStore from '../../store/SignProcess/SignProcessStore';
import * as CCRecipientStore from '../../store/CCRecipientStore';
import { DocumentGroups } from '../../core/domain/models/IGroup';
import { storeControllerIdInMemory } from '../../core/services/dataAccess/DataService.Axios';
import { IDocumentReviewModel, ReviewDocumentType } from '../../core/domain/viewModels/IDocumentReviewModel';
import { PdfViewer } from './Main/PdfViewer';
import { IReviewDocumentKeyValue, ReviewTabNames, ReviewTabs } from './Main/Review';
import { Viewer } from '../Common/PdfView/Viewer';
import { AttachmentsView } from './Main/AttachmentsView';
import { AddCCRecipientModal } from '../CCRecipient/AddCCRecipientModal';
import { ICCRecipientDownloadableDocuments } from '../../core/domain/models/ICCRecipientModel';
import { ITaxReturn } from '../../core/domain/models/ITaxReturn';
import { logger } from '../../routes';
import { CustomButton } from '@sssuite-component-ui/multi-step-wizard/dist/Footer/CustomButton';
import * as TaxDocument from '../../core/domain/models/ITaxReturn';
import { Common } from '../Common/Constants';
import { NextButtonIcon, SvgForwardButtonIcon } from '../Common/Icons/SvgIcons';
import { MarsNotifier } from '../Common/Notification/MarsNotifier';
import { DownloadDocumentsConstants } from "../Common/Constants";


type ReviewCompletedProps = {
    documentReviewModel: IDocumentReviewModel[];
    match: match;
    history: History;
    ccRecipientDownloadableDocuments: ICCRecipientDownloadableDocuments[];
    taxReturn: ITaxReturn;
    isPreviewMode: boolean;
}
    & typeof CCRecipientStore.actionCreators
    & typeof DocumentReviewStore.actionCreators
    & typeof TaxDocumentStore.actionCreators
    & typeof SignProcessStore.actionCreators;

interface ReviewCompletedState {
    isProcessCompleted: boolean;
    isClientView: boolean;
    currentStep: number;
    activeTabs: IReviewDocumentKeyValue[];
    hideTitle: boolean;
    showCCModal: boolean;
    nextButtonText: string;
    isDelegateeAssigned: boolean,
    isSignCompleted: boolean,
    isPreviewMode:boolean;
}


export class ReviewCompleted extends React.Component<ReviewCompletedProps, ReviewCompletedState> {
    private _params: any;
    constructor(props: ReviewCompletedProps) {
        super(props);
        this.state = {
            isProcessCompleted: false,
            isClientView: false,
            currentStep: ReviewTabs.FilingInstructions,
            activeTabs: [],
            hideTitle: false,
            showCCModal: false,
            nextButtonText: Common.Wizard.NextButtonLabel,
            isDelegateeAssigned: false,
            isSignCompleted : false,
            isPreviewMode:false
        }

        this.onPrevious = this.onPrevious.bind(this);
        this.onNext = this.onNext.bind(this);
        this.getActiveTabs = this.getActiveTabs;
        this.getNavBar = this.getNavBar.bind(this);
        this.getActiveTabDetails = this.getActiveTabDetails.bind(this);
    }

    static getDerivedStateFromProps(nextProps: ReviewCompletedProps, prevState: ReviewCompletedState) {
        let currentTab: ReviewTabs = ReviewTabs.FilingInstructions;
        let activeTabs: IReviewDocumentKeyValue[] = [];

        if (nextProps.documentReviewModel.length > 0 && prevState.activeTabs.length === 0) {
            if (nextProps.documentReviewModel.findIndex(x => x.documentGroup === DocumentGroups.Transmittals) !== -1) {
                currentTab = ReviewTabs.FilingInstructions;
            }
            else if (nextProps.documentReviewModel.findIndex(x => x.documentGroup === DocumentGroups.TaxReturns) !== -1) {
                currentTab = ReviewTabs.TaxReturns;
            }
            else if (nextProps.documentReviewModel.findIndex(x => x.documentGroup == DocumentGroups.None &&
                x.documentType == ReviewDocumentType.Attachment) != -1) {
                currentTab = ReviewTabs.Attachments;
            }
            else if (nextProps.documentReviewModel.findIndex(x => x.documentGroup == DocumentGroups.None &&
                x.documentType == ReviewDocumentType.PaperFileReturn) != -1) {
                currentTab = ReviewTabs.PaperFileReturns;
            }
            return ({ currentStep: currentTab, activeTabs: activeTabs });
        }

        return null;
    }


    componentDidMount() {
        const param: any = this.props.match.params;
        if (param && param.controllerId) {
            storeControllerIdInMemory(param.controllerId);
        }
        this.getSignProcessStatus(param.clientId);
        this.props.requestDocumentReviewModel(param.clientId);
        this.props.requestTaxDocument(param.clientId,(data?: TaxDocument.ITaxReturn) => { this.requestCCRecipientDownloadableDocuments(data)});

        logger.trackPageView('Completed Summary Review Taxdocuments Page', { "ClientId": param.clientId, "DocumentId": this.props.taxReturn.id, "PreviewMode": this.props.isPreviewMode });
    }

    private requestCCRecipientDownloadableDocuments = (taxDocument: TaxDocument.ITaxReturn) => {
        const param: any = this.props.match.params;
        this.props.requestCCRecipientDownloadableDocuments(param.clientId,
            taxDocument.documentStatus);
    }

    gotoCompletionWizard = () => {
        const param: any = this.props.match.params;

        this.props.history.push(`${PathConstants.CompletionWizard}${param.clientId}`);
    }

    private getSignProcessStatus = (clientId: any) => {
        this.props.requestCurrentStepAndUserRole(clientId, this.handleRedirect);
    }

    private handleRedirect = (clientProcessState: IClientProcessViewModel) => {

        this._params = this.props.match.params;

        const url = TaxpayerHelper.getSignCompletedRedirectUrl(clientProcessState, this._params.clientId);

        if (url && url.trim()) {
            this.props.history.push(url);
            this.setState({ 
                isClientView: TaxpayerHelper.isPreviewModeWithWritePermission(clientProcessState),
                isDelegateeAssigned: clientProcessState.isDelegated,
                isSignCompleted: clientProcessState.isSigned
            });
        }
        else {
            this.setState({ 
                isProcessCompleted: true, 
                isClientView: TaxpayerHelper.isPreviewModeWithWritePermission(clientProcessState),
                isPreviewMode:TaxpayerHelper.isPreviewMode(clientProcessState),
                isDelegateeAssigned: clientProcessState.isDelegated,
                isSignCompleted: clientProcessState.isSigned
             });
        }
    }

    getActiveTabName = () => {
        return ReviewTabNames[this.state.currentStep];
    }

    private handleTabSelect = (index: number) => {
        this.setState({ currentStep: index });
    }

    getActiveTabs(nextProps: ReviewCompletedProps) {
        let activeTabs: IReviewDocumentKeyValue[] = [];
        let formIndex: number = nextProps.documentReviewModel.findIndex(x => x.documentGroup == DocumentGroups.Transmittals);
        formIndex != -1 ? activeTabs.push({ tabName: ReviewTabNames[1], tabIndex: 1 }) : "";
        formIndex = nextProps.documentReviewModel.findIndex(x => x.documentGroup == DocumentGroups.TaxReturns);
        formIndex != -1 ? activeTabs.push({ tabName: ReviewTabNames[2], tabIndex: 2 }) : "";
        formIndex = nextProps.documentReviewModel.findIndex(x => x.documentGroup == DocumentGroups.None &&
            x.documentType == ReviewDocumentType.Attachment);
        formIndex != -1 ? activeTabs.push({ tabName: ReviewTabNames[3], tabIndex: 3 }) : "";
        formIndex = nextProps.documentReviewModel.findIndex(x => x.documentGroup == DocumentGroups.None &&
            x.documentType == ReviewDocumentType.PaperFileReturn);
        formIndex != -1 ? activeTabs.push({ tabName: ReviewTabNames[4], tabIndex: 4 }) : "";
        this.setState({ activeTabs: activeTabs });
    }

    getNavBar() {
        let currentStep: number = this.state.currentStep;
        let activeTabName = ReviewTabNames[currentStep];
        if (this.props.documentReviewModel.length > 0 && this.state.activeTabs.length == 0) {
            this.getActiveTabs(this.props);
        }
        let navBars: any = this.state.activeTabs.map((tab, index) => {
            return <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }} key={tab.tabIndex + tab.tabName} className={activeTabName == tab.tabName
                ? "right-pointer cursor-pointer" : "cursor-pointer"} id="review-tab-navbar"
                onClick={() => {
                    this.handleTabSelect(tab.tabIndex);
                    this.setState({ nextButtonText: this.state.activeTabs.length === tab.tabIndex ? "Done" : Common.Wizard.NextButtonLabel });
                }}>
                {tab.tabName} 
                { activeTabName == tab.tabName && <NextButtonIcon className='margin-right-10' />}
            </div>
        });
        return navBars;
    }


    handleViewModeChange = (viewerMode: ViewerMode) => {
        let hideTitle: boolean = false;
        if (viewerMode == ViewerMode.Read) {
            hideTitle = true;
        }
        this.setState({ hideTitle: hideTitle });
    }

    getActiveTabDetails() {
        let document: IDocumentReviewModel;
        if (this.props.documentReviewModel.length > 0) {
            switch (this.state.currentStep) {
                case ReviewTabs.FilingInstructions:
                    document = this.props.documentReviewModel.filter(x => x.documentGroup == DocumentGroups.Transmittals)[0];
                    return (
                        <Viewer leftPanel={this.getNavBar()} url={document && document.fileLink}
                            onViewModeChange={this.handleViewModeChange} />)

                case ReviewTabs.TaxReturns:
                    document = this.props.documentReviewModel.filter(x => x.documentGroup == DocumentGroups.TaxReturns)[0];
                    return (
                        <Viewer leftPanel={this.getNavBar()} url={document && document.fileLink} onViewModeChange={this.handleViewModeChange} />
                    )

                case ReviewTabs.Attachments:
                    let attachmentsInfo: IDocumentReviewModel[] = [];
                    attachmentsInfo = this.props.documentReviewModel.filter(x => x.documentGroup == DocumentGroups.None &&
                        x.documentType == ReviewDocumentType.Attachment);
                    return (
                        <AttachmentsView attachmentReviewModel={attachmentsInfo} match={this.props.match}
                            downloadAttachment={this.props.downloadIndividualAttachment}
                            downloadAllAttachments={this.props.downloadAllAttachments} isPreviewMode={this.props.isPreviewMode} />
                    )

                case ReviewTabs.PaperFileReturns:
                    document = this.props.documentReviewModel.filter(x => x.documentGroup == DocumentGroups.None &&
                        x.documentType == ReviewDocumentType.PaperFileReturn)[0];
                    return (
                        <Viewer leftPanel={this.getNavBar()} url={document && document.fileLink} onViewModeChange={this.handleViewModeChange} />
                    )
            }
        }
    }

    closeCCRecipientModal = () => {
        this.setState({ showCCModal: false });
    }

    openCCRecipientModal = () => 
    {
        if(!this.state.isPreviewMode)
            {
                this.setState({ showCCModal: true });
            }
        else
        {
            MarsNotifier.Warning(DownloadDocumentsConstants.DownloadNotAllowed, null);
        }       
    }

    onNext = () => {
        const tabsActive = this.state.activeTabs.length;
        if (tabsActive > 1) {
            const nextStepIndex = this.state.activeTabs.findIndex(x => x.tabName == ReviewTabNames[this.state.currentStep]) + 1;

            if (this.state.activeTabs[nextStepIndex]) {
                this.handleTabSelect(this.state.activeTabs[nextStepIndex].tabIndex);
                this.setState({ nextButtonText: (tabsActive - 1) === nextStepIndex ? "Done" : Common.Wizard.NextButtonLabel });
                return;
            }
        }
        this.gotoCompletionWizard();
    }

    onPrevious = () => {
        const tabsActive = this.state.activeTabs.length;
        if (tabsActive > 1) {
            const nextStepIndex = this.state.activeTabs.findIndex(x => x.tabName == ReviewTabNames[this.state.currentStep]) - 1;

            if (this.state.activeTabs[nextStepIndex]) {
                this.handleTabSelect(this.state.activeTabs[nextStepIndex].tabIndex);
                this.setState({ nextButtonText: Common.Wizard.NextButtonLabel });
                return;
            }
        }
        this.gotoCompletionWizard();
    }

    getActiveWidth = () => {
        switch (this.state.currentStep) {
            case 3:
                return 10;
            case 2:
            case 1:
                return 12;
            default:
                return 12

        }
    }

    getActiveClassName = () => {
        switch (this.state.currentStep) {
            case 3:
                return "";
            case 2:
            case 1:
                return "no-padding";
            default:
                return "no-padding"

        }
    }

    public render() {

        const hideDocumentForwardButton:boolean = this.state.isDelegateeAssigned && !this.state.isSignCompleted;

        return ((this.state.isProcessCompleted) &&
            <StepLayout className={"with-footer no-padding review-step-container"} lg={this.getActiveWidth()} md={6} sm={6} xs={6}
                title={this.state.hideTitle ? undefined : `Review Tax Documents - ${this.getActiveTabName()}`}
                customButtonText={Common.Wizard.ForwardDocuments}
                showCustomButton={!hideDocumentForwardButton ? true : false}
                handleCustomButtonClick={this.openCCRecipientModal}
                customButtonIcon={SvgForwardButtonIcon}
                customButtonTooltip={'Forward a copy of your tax documents.'}>

                <Body  
                    xl={this.state.currentStep === ReviewTabs.Attachments ? 7 : 12}
                    lg={this.state.currentStep === ReviewTabs.Attachments ? 8 : 12}
                    md={this.state.currentStep === ReviewTabs.Attachments ? 10 : 12}
                    sm={this.state.currentStep === ReviewTabs.Attachments ? 10 : 12}
                    xs={this.state.currentStep === ReviewTabs.Attachments ? 10 : 12}
                    containerClassName={"row" + (this.state.currentStep === 3 ? " attachment-padding" : "")}
                    automationTestId={"11BB28B5-42F3-4571-B567-20700B1740B6"}
                    noBackgroundColor={true}>
                    <div style={{ height: 'inherit'}}>
                        <PdfViewer data-test-auto="4F3F7B87-7A53-4774-BDBA-1977E1BD6962">
                            {this.getActiveTabDetails()}
                        </PdfViewer>
                    </div>
                </Body>
                <Footer lg={12} md={12} sm={12} xs={12}>
                    <div className={"steps-footer"}>
                        <Previous automationTestId={"3F43C07D-73AD-4BB5-83D1-346B1E62ABC6"}
                            text={"Back"} goToPreviousStep={this.onPrevious} isActive={true} disableMobileResponsiveness={true} />

                        <Next automationTestId={"21596F53-89B5-4830-812E-96D126E7C1AE"}
                            text={this.state.nextButtonText} goToNextStep={this.onNext} isActive={true} disableMobileResponsiveness={true} />                        
                    </div>
                </Footer>
                <AddCCRecipientModal
                    show={this.state.showCCModal}
                    closeCCRecipientModal={this.closeCCRecipientModal}
                    downloadableDocuments={this.props.ccRecipientDownloadableDocuments}
                    taxdocumentId={this.props.taxReturn.id}
                    forwardDocuments={this.props.forwardDocumentsToRecipients}
                    match={this.props.match}
                />
            </StepLayout>);

    }
}
