import { Action, Reducer } from "redux";
import { AppThunkAction } from "../index";
import { actionTypes } from "../ActionTypes";
import { AxiosResponse } from "axios";
import { AxiosRequestConfig } from "axios";
import {
  IK1ShareHolderActivity,
  IK1ShareHolderDetails,
} from "../../core/domain/models/IK1Distribute";
import { initializeAxios } from "../../core/services/dataAccess/DataService.Axios";
import { StatusType, NotificationAction } from "../Common/NotificationStore";
import { DisplayDownloadFile } from "../../components/Common/DisplayDownloadFile";
import { EngagementTypeS } from "../../core/common/Enums";
import {
  K1ShareHolderConstants,
  MyDownloadsConstants,
} from "../../components/Common/Constants";
import { IDownloadedZipFilesModel } from "../../components/Layout/MyDownload";
import { ResponseMyDownloadAction } from "../DownloadableDocumentsStore";
import { container } from "../../startup/inversify.config";
import { ILoader } from "../../core/utilities/ui/Loader";
import { TYPES } from "../../startup/types";
import { IFileUtilities } from "../../core/utilities/File/FileUtilities";
import { TelemetryLogger } from "../../components/Logger/AppInsights";
import { getLocalDate } from "../../components/Helper/HelperFunction";

const logger = TelemetryLogger.getInstance();

export interface K1State {
  data: IK1ShareHolderDetails[];
  isLoading: boolean;
}

export const initialK1State: K1State = {
  data: [],
  isLoading: false,
};

interface RequestK1DistributeAction {
  type: actionTypes.K1DISTRIBUTE_REQUEST;
}

interface ResponseK1DistributeAction {
  type: actionTypes.K1DISTRIBUTE_RESPONSE;
  data: IK1ShareHolderDetails[];
}

interface UPDATEK1DistributeAction {
  type: actionTypes.K1DISTRIBUTE_UPDATE;
  data: IK1ShareHolderDetails;
}

interface FailureK1DistributeAction {
  type: actionTypes.K1DISTRIBUTE_FAILURE;
  data: IK1ShareHolderDetails[];
}

type KnownAction =
  | DispatchAction
  | ResponseMyDownloadAction
  | NotificationAction;

type DispatchAction =
  | RequestK1DistributeAction
  | ResponseK1DistributeAction
  | FailureK1DistributeAction
  | UPDATEK1DistributeAction;

const loader = container.get<ILoader>(TYPES.ILoader);
const fileUtilities = container.get<IFileUtilities>(TYPES.IFileUtilities);
export const actionCreators = {
  requestK1ShareHolderDetails:
    (
      clientGuid: string,
      pageNo: number,
      pageSize: number,
      sortBy: string,
      sortOrder: string,
      searchText: string,
      successCallback?: () => void
    ): AppThunkAction<KnownAction> =>
    (dispatch, getState) => {
      const state = getState();
      searchText = searchText === "" ? " " : searchText;
      searchText = encodeURIComponent(searchText);
      dispatch({ type: actionTypes.K1DISTRIBUTE_REQUEST });
      return initializeAxios()
        .get<IK1ShareHolderDetails[]>(
          "api/K1Distribute/GetK1ShareHolderDetails/" +
            pageNo +
            "/" +
            pageSize +
            "/" +
            sortBy +
            "/" +
            sortOrder +
            "/" +
            clientGuid +
            "?searchText=" +
            searchText
        )
        .then(function (response: AxiosResponse<IK1ShareHolderDetails[]>) {
          dispatch({
            type: actionTypes.K1DISTRIBUTE_RESPONSE,
            data: response.data,
          });
          successCallback && successCallback();
        })
        .catch(function (error: any) {
          dispatch({
            type: actionTypes.NOTIFICATION,
            statusMessage: error.response
              ? error.response.statusText
              : "Error occurred while requesting K1 shareholder model",
            statusType: StatusType.Error,
          });
          dispatch({
            type: actionTypes.K1DISTRIBUTE_FAILURE,
            data: state.k1Data.data,
          });
          logger.trackWarning(
            `requestK1ShareHolderDetails failed with error ${error.message} for client: ${clientGuid}`,
            { ClientId: clientGuid }
          );
        });
    },

  requestK1ShareHolderActivity:
    (
      clientGuid: string,
      documentId: number,
      shareholderId: number,
      successCallback?: (
        k1ShareHolderActivity: IK1ShareHolderActivity[]
      ) => void
    ): AppThunkAction<KnownAction> =>
    (dispatch, getState) => {
      const state = getState();
      return initializeAxios()
        .get<IK1ShareHolderActivity[]>(
          "api/k1-shareholder-activity/get-all/" +
            documentId +
            "/" +
            shareholderId +
            "/" +
            clientGuid
        )
        .then(function (response: AxiosResponse<IK1ShareHolderActivity[]>) {
          successCallback && successCallback(response.data);
        })
        .catch(function (error: any) {
          logger.trackWarning(
            `requestK1ShareHolderActivity failed with error ${error.message} for document: ${documentId} and k1 shareholder id ${shareholderId}`
          );
        });
    },

  updateK1ShareHolderEmail:
    (
      clientGuid: string,
      k1Partner: IK1ShareHolderDetails,
      successCallback?: () => void
    ): AppThunkAction<KnownAction> =>
    (dispatch, getState) => {
      const state = getState();
      let config: AxiosRequestConfig = {
        responseType: "arraybuffer",
        headers: { "Content-Type": "application/json;utf-8" },
      };
      return initializeAxios()
        .put<IK1ShareHolderDetails>(
          "api/K1Distribute/updateK1ShareHolderEmail/" + clientGuid,
          JSON.stringify(k1Partner),
          config
        )
        .then(function (response: AxiosResponse<IK1ShareHolderDetails>) {
          dispatch({
            type: actionTypes.K1DISTRIBUTE_UPDATE,
            data: { ...k1Partner },
          });
          /*const result = state.k1Data.data.map(x => {                  
                    return k1Partner.id == x.id ? k1Partner : x;
                });
                dispatch({
                    type: actionTypes.K1DISTRIBUTE_RESPONSE, data: result
                });*/
          dispatch({
            type: actionTypes.NOTIFICATION,
            statusMessage: K1ShareHolderConstants.SuccessMessage.emailUpdated,
            statusType: StatusType.Success,
          });
          successCallback && successCallback();
        })
        .catch(function (error: any) {
          dispatch({
            type: actionTypes.NOTIFICATION,
            statusMessage: error.response
              ? error.response.statusText
              : "Error occurred while requesting K1 shareholder model",
            statusType: StatusType.Error,
          });
          dispatch({
            type: actionTypes.K1DISTRIBUTE_FAILURE,
            data: state.k1Data.data,
          });
          logger.trackWarning(
            `updateK1ShareHolderEmail failed with error ${error.message} for client: ${clientGuid}`,
            { ClientId: clientGuid }
          );
        });
    },

  updateK1ShareHolderDetail:
    (
      clientGuid: string,
      k1Partner: IK1ShareHolderDetails,
      isEmailChange: boolean,
      isPreview: boolean,
      callBack?: () => void
    ): AppThunkAction<KnownAction> =>
    (dispatch, getState) => {
      const state = getState();
      let config: AxiosRequestConfig = {
        responseType: "arraybuffer",
        headers: { "Content-Type": "application/json;utf-8" },
      };
      return initializeAxios()
        .put<IK1ShareHolderDetails>(
          "api/K1Distribute/UpdateK1ShareHolderDetails/" +
            isEmailChange +
            "/" +
            isPreview +
            "/" +
            clientGuid,
          JSON.stringify(k1Partner),
          config
        )
        .then(function (response: AxiosResponse<IK1ShareHolderDetails>) {
          dispatch({
            type: actionTypes.K1DISTRIBUTE_UPDATE,
            data: { ...k1Partner },
          });
          /* const result = state.k1Data.data.map(x => {
                         return k1Partner.id == x.id ? k1Partner : x;
                     });
                     dispatch({
                         type: actionTypes.K1DISTRIBUTE_RESPONSE, data: result
                     });*/
          dispatch({
            type: actionTypes.NOTIFICATION,
            statusMessage: K1ShareHolderConstants.SuccessMessage.detailsUpdated,
            statusType: StatusType.Success,
          });
          callBack && callBack();
        })
        .catch(function (error: any) {
          dispatch({
            type: actionTypes.NOTIFICATION,
            statusMessage: error.response
              ? error.response.statusText
              : "Error occurred while requesting K1 shareholder model",
            statusType: StatusType.Error,
          });
          // dispatch({ type: actionTypes.K1DISTRIBUTE_FAILURE, data: state. });
          logger.trackWarning(
            `updateK1ShareHolderDetail failed with error ${error.message} for client: ${clientGuid}`,
            { ClientId: clientGuid }
          );
        });
    },

  downloadK1DocumentAsync:
    (
      clientId: string,
      engagementType: EngagementTypeS,
      callback?: () => void
    ): AppThunkAction<KnownAction> =>
    (dispatch, getState) => {
      const options = {
        headers: {
          Accept: "application/json, text/plain, *",
          "Content-Type": "application/json; charset=utf-8",
        },
      };
      return initializeAxios()
        .post<string>(
          "api/K1Download/DownloadK1DocumentAsync/" +
            engagementType +
            "/" +
            clientId
        )
        .then(function (response: AxiosResponse<IDownloadedZipFilesModel>) {
          if (callback) {
            callback();
          }
          const state = getState();
          let data =
            state.downloadableDocuments?.myDownloadList == undefined
              ? []
              : state.downloadableDocuments.myDownloadList;
          data = [response.data, ...data];
          dispatch({
            type: actionTypes.MY_DOWNLOAD_RESPONSE,
            data: data,
          });

          dispatch({
            type: actionTypes.NOTIFICATION,
            statusMessage: MyDownloadsConstants.StatusMessage.replace(
              "<FILENAME>",
              fileUtilities.removeLastCharUnderscore(
                fileUtilities.getSafeFilename(response.data.fileName)
              )
            ),
            statusType: StatusType.Success,
          });
        })
        .catch(function (error: any) {
          dispatch({
            type: actionTypes.NOTIFICATION,
            statusMessage: error.response
              ? error.response.statusText
              : "Failed to Download K1 Document",
            statusType: StatusType.Error,
          });
          logger.trackWarning(
            `downloadK1DocumentAsync failed with error ${error.message} for client: ${clientId}`,
            { ClientId: clientId }
          );
        });
    },

  downloadIndividualK1DocumentAsync:
    (
      clientId: string,
      shareHolder: IK1ShareHolderDetails,
      engagementType: EngagementTypeS
    ): AppThunkAction<KnownAction> =>
    (dispatch, getState) => {
      let fileName: string;
      loader.show();
      shareHolder.localDate = getLocalDate();
      let config: AxiosRequestConfig = {
        responseType: "arraybuffer",
        headers: { "Content-Type": "application/json;utf-8" },
      };
      return initializeAxios()
        .post<string>(
          "api/K1Download/DownloadIndividualK1DocumentAsync/" +
            engagementType +
            "/" +
            clientId,
          JSON.stringify(shareHolder),
          config
        )
        .then(function (response: any) {
          const contentDisposition = response.headers["content-disposition"];
          const fileNameMatch = contentDisposition
            ? /filename="?([^"]*)"?;/g.exec(contentDisposition)
            : undefined;
          if (fileNameMatch && fileNameMatch.length > 1) {
            fileName = fileNameMatch[1];
          }
          let displayDownloadFile = new DisplayDownloadFile();
          displayDownloadFile.showFile(response.data, fileName);
          loader.hide();
        })
        .catch(function (error: any) {
          dispatch({
            type: actionTypes.NOTIFICATION,
            statusMessage: error.response
              ? error.response.statusText
              : "Failed to Download K1 Document",
            statusType: StatusType.Error,
          });
          logger.trackWarning(
            `downloadIndividualK1DocumentAsync failed with error ${error.message} for client: ${clientId}`,
            { ClientId: clientId }
          );
        });
    },

  downloadSelectedK1DocumentAsync:
    (
      clientId: string,
      shareHolders: IK1ShareHolderDetails[],
      engagementType: EngagementTypeS,
      callback?: () => void
    ): AppThunkAction<KnownAction> =>
    (dispatch, getState) => {
      shareHolders.forEach((shareholder) => {
        shareholder.localDate = getLocalDate();
      });
      const options = {
        headers: {
          Accept: "application/json, text/plain, *",
          "Content-Type": "application/json; charset=utf-8",
        },
      };
      return initializeAxios()
        .post<IDownloadedZipFilesModel>(
          "api/K1Download/DownloadSelectedK1DocumentAsync/" +
            engagementType +
            "/" +
            clientId,
          JSON.stringify(shareHolders),
          options
        )
        .then(function (response: AxiosResponse<IDownloadedZipFilesModel>) {
          if (callback) {
            callback();
          }
          const state = getState();
          let data =
            state.downloadableDocuments?.myDownloadList == undefined
              ? []
              : state.downloadableDocuments.myDownloadList;
          data = [response.data, ...data];
          dispatch({
            type: actionTypes.MY_DOWNLOAD_RESPONSE,
            data: data,
          });

          dispatch({
            type: actionTypes.NOTIFICATION,
            statusMessage: MyDownloadsConstants.StatusMessage.replace(
              "<FILENAME>",
              fileUtilities.removeLastCharUnderscore(
                fileUtilities.getSafeFilename(response.data.fileName)
              )
            ),
            statusType: StatusType.Success,
          });
        })
        .catch(function (error: any) {
          dispatch({
            type: actionTypes.NOTIFICATION,
            statusMessage: error.response
              ? error.response.statusText
              : "Failed to Download K1 Document",
            statusType: StatusType.Error,
          });
          logger.trackWarning(
            `downloadSelectedK1DocumentAsync failed with error ${error.message} for client: ${clientId}`,
            { ClientId: clientId }
          );
        });
    },

  downloadBulkSelectedK1DocumentAsync:
    (
      clientId: string,
      unSelectedK1Partners: IK1ShareHolderDetails[],
      engagementType: EngagementTypeS,
      callback?: () => void
    ): AppThunkAction<KnownAction> =>
    (dispatch, getState) => {
      unSelectedK1Partners.forEach((shareholder) => {
        shareholder.localDate = getLocalDate();
      });
      const options = {
        headers: {
          Accept: "application/json, text/plain, *",
          "Content-Type": "application/json; charset=utf-8",
        },
      };
      return initializeAxios()
        .post<IDownloadedZipFilesModel>(
          "api/K1Download/DownloadBulkSelectedK1DocumentAsync/" +
            engagementType +
            "/" +
            clientId,
          JSON.stringify(unSelectedK1Partners),
          options
        )
        .then(function (response: AxiosResponse<IDownloadedZipFilesModel>) {
          if (callback) {
            callback();
          }
          const state = getState();
          let data =
            state.downloadableDocuments?.myDownloadList == undefined
              ? []
              : state.downloadableDocuments.myDownloadList;
          data = [response.data, ...data];
          dispatch({
            type: actionTypes.MY_DOWNLOAD_RESPONSE,
            data: data,
          });

          dispatch({
            type: actionTypes.NOTIFICATION,
            statusMessage: MyDownloadsConstants.StatusMessage.replace(
              "<FILENAME>",
              fileUtilities.removeLastCharUnderscore(
                fileUtilities.getSafeFilename(response.data.fileName)
              )
            ),
            statusType: StatusType.Success,
          });
        })
        .catch(function (error: any) {
          dispatch({
            type: actionTypes.NOTIFICATION,
            statusMessage: error.response
              ? error.response.statusText
              : "Failed to Download K1 Document",
            statusType: StatusType.Error,
          });
          logger.trackWarning(
            `downloadSelectedK1DocumentAsync failed with error ${error.message} for client: ${clientId}`,
            { ClientId: clientId }
          );
        });
    },

  downloadK1DocumentForPreviewAsync:
    (
      clientId: string,
      engagementType: EngagementTypeS,
      callback?: () => void
    ): AppThunkAction<KnownAction> =>
    (dispatch, getState) => {
      let fileName: string;
      loader.show();
      let config: AxiosRequestConfig = {
        headers: { "Content-Type": "application/json;utf-8" },
      };
      return initializeAxios()
        .post<string>(
          "api/K1Download/DownloadK1DocumentForPreviewAsync/" +
            engagementType +
            "/" +
            clientId,
          "",
          config
        )
        .then(function (response: any) {
          if (callback) {
            callback();
          }
          let displayDownloadFile = new DisplayDownloadFile();
          displayDownloadFile.directDownload(response.data);
          loader.hide();
        })
        .catch(function (error: any) {
          dispatch({
            type: actionTypes.NOTIFICATION,
            statusMessage: error.response
              ? error.response.statusText
              : "Failed to Download K1 Document",
            statusType: StatusType.Error,
          });
          logger.trackWarning(
            `downloadK1DocumentForPreviewAsync failed with error ${error.message} for client: ${clientId}`,
            { ClientId: clientId }
          );
        });
    },

  downloadIndividualK1DocumentForPreviewAsync:
    (
      clientId: string,
      shareHolder: IK1ShareHolderDetails,
      engagementType: EngagementTypeS
    ): AppThunkAction<KnownAction> =>
    (dispatch, getState) => {
      let fileName: string;
      loader.show();
      let config: AxiosRequestConfig = {
        responseType: "arraybuffer",
        headers: { "Content-Type": "application/json;utf-8" },
      };
      return initializeAxios()
        .post<string>(
          "api/K1Download/DownloadIndividualK1DocumentForPreviewAsync/" +
            engagementType +
            "/" +
            clientId,
          JSON.stringify(shareHolder),
          config
        )
        .then(function (response: any) {
          const contentDisposition = response.headers["content-disposition"];
          const fileNameMatch = contentDisposition
            ? /filename="?([^"]*)"?;/g.exec(contentDisposition)
            : undefined;
          if (fileNameMatch && fileNameMatch.length > 1) {
            fileName = fileNameMatch[1];
          }
          let displayDownloadFile = new DisplayDownloadFile();
          displayDownloadFile.showFile(response.data, fileName);
          loader.hide();
        })
        .catch(function (error: any) {
          dispatch({
            type: actionTypes.NOTIFICATION,
            statusMessage: error.response
              ? error.response.statusText
              : "Failed to Download K1 Document",
            statusType: StatusType.Error,
          });
          logger.trackWarning(
            `downloadIndividualK1DocumentForPreviewAsync failed with error ${error.message} for client: ${clientId}`,
            { ClientId: clientId }
          );
        });
    },

  downloadSelectedK1DocumentForPreviewAsync:
    (
      clientId: string,
      shareHolders: IK1ShareHolderDetails[],
      engagementType: EngagementTypeS,
      callback?: () => void
    ): AppThunkAction<KnownAction> =>
    (dispatch, getState) => {
      let fileName: string;
      loader.show();
      let config: AxiosRequestConfig = {
        responseType: "arraybuffer",
        headers: { "Content-Type": "application/json;utf-8" },
      };
      return initializeAxios()
        .post<string>(
          "api/K1Download/DownloadSelectedK1DocumentForPreviewAsync/" +
            engagementType +
            "/" +
            clientId,
          JSON.stringify(shareHolders),
          config
        )
        .then(function (response: any) {
          const contentDisposition = response.headers["content-disposition"];
          const fileNameMatch = contentDisposition
            ? /filename="?([^"]*)"?;/g.exec(contentDisposition)
            : undefined;
          if (fileNameMatch && fileNameMatch.length > 1) {
            fileName = fileNameMatch[1];
          }
          if (callback) {
            callback();
          }
          let displayDownloadFile = new DisplayDownloadFile();
          displayDownloadFile.showFile(response.data, fileName);
          loader.hide();
        })
        .catch(function (error: any) {
          dispatch({
            type: actionTypes.NOTIFICATION,
            statusMessage: error.response
              ? error.response.statusText
              : "Failed to Download K1 Document",
            statusType: StatusType.Error,
          });
          logger.trackWarning(
            `downloadSelectedK1DocumentForPreviewAsync failed with error ${error.message} for client: ${clientId}`,
            { ClientId: clientId }
          );
        });
    },

  downloadBulkSelectedK1DocumentForPreviewAsync:
    (
      clientId: string,
      unSelectedK1Partners: IK1ShareHolderDetails[],
      engagementType: EngagementTypeS,
      callback?: () => void
    ): AppThunkAction<KnownAction> =>
    (dispatch, getState) => {
      let fileName: string;
      loader.show();
      let config: AxiosRequestConfig = {
        responseType: "arraybuffer",
        headers: { "Content-Type": "application/json;utf-8" },
      };
      return initializeAxios()
        .post<string>(
          "api/K1Download/DownloadBulkSelectedK1DocumentForPreviewAsync/" +
            engagementType +
            "/" +
            clientId,
          JSON.stringify(unSelectedK1Partners),
          config
        )
        .then(function (response: any) {
          const contentDisposition = response.headers["content-disposition"];
          const fileNameMatch = contentDisposition
            ? /filename="?([^"]*)"?;/g.exec(contentDisposition)
            : undefined;
          if (fileNameMatch && fileNameMatch.length > 1) {
            fileName = fileNameMatch[1];
          }
          if (callback) {
            callback();
          }
          let displayDownloadFile = new DisplayDownloadFile();
          displayDownloadFile.showFile(response.data, fileName);
          loader.hide();
        })
        .catch(function (error: any) {
          dispatch({
            type: actionTypes.NOTIFICATION,
            statusMessage: error.response
              ? error.response.statusText
              : "Failed to Download K1 Document",
            statusType: StatusType.Error,
          });
          logger.trackWarning(
            `downloadSelectedK1DocumentForPreviewAsync failed with error ${error.message} for client: ${clientId}`,
            { ClientId: clientId }
          );
        });
    },

  sendMailToShareHolder:
    (
      clientId: string,
      partners: IK1ShareHolderDetails[],
      callback: () => void
    ): AppThunkAction<KnownAction> =>
    (dispatch, getState) => {
      let config: AxiosRequestConfig = {
        responseType: "arraybuffer",
        headers: { "Content-Type": "application/json;utf-8" },
      };
      return initializeAxios()
        .post<string>(
          "api/K1Distribute/SendMailToShareHolders/" + clientId,
          JSON.stringify(partners),
          config
        )
        .then(function (response: any) {
          if (callback) {
            callback();
          }
          dispatch({
            type: actionTypes.NOTIFICATION,
            statusMessage: K1ShareHolderConstants.SuccessMessage.emailSent,
            statusType: StatusType.Success,
          });
        })
        .catch(function (error: any) {
          dispatch({
            type: actionTypes.NOTIFICATION,
            statusMessage: error.response
              ? error.response.statusText
              : "Failed to Send K1 Document",
            statusType: StatusType.Error,
          });
          logger.trackWarning(
            `sendMailToShareHolder failed with error ${error.message} for client: ${clientId}`,
            { ClientId: clientId }
          );
        });
    },

  bulkSendMailToShareHolder:
    (
      clientId: string,
      unSelectedRows: IK1ShareHolderDetails[],
      callback: () => void
    ): AppThunkAction<KnownAction> =>
    (dispatch, getState) => {
      return initializeAxios()
        .postJson<any>(
          JSON.stringify(unSelectedRows),
          "api/K1Distribute/BulkSendMailToShareHolders/" + clientId
        )
        .then(function (response: any) {
          let result = response.data;

          if (result.isSuccess) {
            if (callback) {
              callback();
            }
            dispatch({
              type: actionTypes.NOTIFICATION,
              statusMessage: K1ShareHolderConstants.SuccessMessage.emailSent,
              statusType: StatusType.Success,
            });
          } else {
            if (result.errorCode === "EMPTY_EMAILID") {
              dispatch({
                type: actionTypes.NOTIFICATION,
                statusMessage:
                  K1ShareHolderConstants.WarningMessage.EmailAdrressEmpty,
                statusType: StatusType.Warning,
              });
            }
          }
        })
        .catch(function (error: any) {
          dispatch({
            type: actionTypes.NOTIFICATION,
            statusMessage: error.response
              ? error.response.statusText
              : "Failed to Send K1 Document",
            statusType: StatusType.Error,
          });
          logger.trackWarning(
            `bulkSendMailToShareHolder failed with error ${error.message} for client: ${clientId}`,
            { ClientId: clientId }
          );
        });
    },
};

export const reducer: Reducer<K1State> = (
  state: K1State = initialK1State,
  incomingAction: Action
) => {
  const action = incomingAction as DispatchAction;
  const currentState = Object.assign({}, state);
  switch (action.type) {
    case actionTypes.K1DISTRIBUTE_REQUEST:
      currentState.data = [];
      currentState.isLoading = true;
      return currentState;
    case actionTypes.K1DISTRIBUTE_RESPONSE:
      currentState.data = action.data;
      currentState.isLoading = false;
      return currentState;
    case actionTypes.K1DISTRIBUTE_UPDATE:
      let updated: K1State = { ...state };
      let index = updated.data.findIndex(
        (x) => x.shareHolderId === action.data.shareHolderId
      );
      updated.data[index] = action.data;
      return {
        ...updated,
      } as K1State;
    default:
      return currentState || initialK1State;
  }
};
