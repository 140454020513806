import { ILogoutMessage } from "../../core/domain/models/ILogoutMessage";
import { History } from "history";
import * as React from "react";
import { OverlayTrigger, Popover } from "react-bootstrap";
import { match } from "react-router";
import { Link } from "react-router-dom";
import { BroadcastChannel } from "broadcast-channel";
import { ChangeNumber } from "../../components/Layout/ChangeNumber";
import { Role } from "../../core/common/Enums";
import { IControllerInfo } from "../../core/domain/models/groupedReturns/IControllerInfo";
import { IAccessCodeValidation } from "../../core/domain/viewModels/ICommon";
import {
  IHeaderInfoViewModel,
  initialHeaderInfoViewModel,
} from "../../core/domain/viewModels/IHeaderInfoViewModel";
import { IUtilities } from "../../core/utilities/Utilities";
import { container } from "../../startup/inversify.config";
import { TYPES } from "../../startup/types";
import { MyAccountConstants } from "../Common/Constants";
import {
  ArrowIcon,
  SvgIconAccountHeader,
  SvgIconCalendarHeader,
  SvgIconContactHeader,
  SvgIconRequestScreenShare,
} from "../Common/Icons/SvgIcons";
import { MarsNotifier } from "../Common/Notification/MarsNotifier";
import { validateMobileNumber } from "../Common/Validations";
import { AccessCode } from "../Layout/AccessCode";
import { MyAccount } from "../Layout/MyAccount";
import { WebrtcSignalR } from "../ScreenShare/WebrtcSignalR";
import { PathConstants } from "../Common/Constants";
import { ICompany } from "../../core/domain/models/company/Company";
import { handleKeyDown } from "../Helper/HelperFunction";

const utilities = container.get<IUtilities>(TYPES.IUtilities);
const channel = new BroadcastChannel("ssr-taxpayer");

export interface GroupedReturnHeaderProps {
  headerInfo: IHeaderInfoViewModel;
  match: match;
  history: History;
  controllerInfo: IControllerInfo;
  iceServers: RTCIceServer[];
  getIceServers: (id: string) => void;
  updateMobileNumber: (
    clientId: string,
    number: string,
    countryCodeValue: string,
    callback: () => void
  ) => void;
  validateTimeBasedAccessCode: (
    accessCode: IAccessCodeValidation,
    callback: () => void
  ) => void;
  logout: (clientGuid: string, callback: (id: string) => void) => void;
  isPreviewMode: boolean;
  getWebRTCSignalRHubBaseURL: (
    id: string,
    callback: (signalRHubBaseURL: string) => void
  ) => void;
  webRTCSignalRHubBaseURL: string;
  companyData: ICompany;
}

export interface GroupedReturnHeaderState {
  emailAddress: string;
  headerInfo: IHeaderInfoViewModel;
  showMyAccountPopUp: boolean;
  showAccessCodePopUp: boolean;
  mobileNumber: string;
  countryCode: string;
  ssn: string;
  showChangeNumberPopUp: boolean;
  showMyDownloadPopUp: boolean;
  screenShareInProgress: boolean;
  windowWidth: number;
  showAccountPopover: boolean;
  showContactPopover: boolean;
}

export class GroupedReturnHeader extends React.Component<
  GroupedReturnHeaderProps,
  GroupedReturnHeaderState
> {
  webrtcRef: any;
  inputRef: any;
  popoverRef: any;
  contactRef: any;

  constructor(
    props: GroupedReturnHeaderProps,
    states: GroupedReturnHeaderState
  ) {
    super(props, states);
    this.state = {
      headerInfo: initialHeaderInfoViewModel,
      showMyAccountPopUp: false,
      showAccessCodePopUp: false,
      emailAddress: "",
      mobileNumber: "",
      countryCode: "",
      ssn: "",
      showChangeNumberPopUp: false,
      showMyDownloadPopUp: false,
      screenShareInProgress: false,
      windowWidth: window.innerWidth,
      showAccountPopover: false,
      showContactPopover: false,
    };

    this.webrtcRef = React.createRef();
    this.inputRef = React.createRef();
    this.popoverRef = React.createRef();
    this.contactRef = React.createRef();
  }

  static getDerivedStateFromProps(
    nextProps: GroupedReturnHeaderProps,
    prevState: GroupedReturnHeaderState
  ) {
    if (
      nextProps.headerInfo &&
      (nextProps.headerInfo.brandingSettings.coverPageSetting.bgColorCode !==
        prevState.headerInfo.brandingSettings.coverPageSetting.bgColorCode ||
        nextProps.headerInfo.brandingSettings.coverPageSetting.foreColorCode !==
          prevState.headerInfo.brandingSettings.coverPageSetting.foreColorCode)
    ) {
      utilities.applyBrandingSettings(nextProps.headerInfo.brandingSettings);
      window.Variables.companyName = nextProps.headerInfo.companyName;
      return {
        headerInfo: nextProps.headerInfo,
      };
    } else {
      return null;
    }
  }

  closeDownloadPopup = () => {
    this.setState({ showMyDownloadPopUp: false });
  };

  openDownloadPopup = () => {
    this.setState({ showMyDownloadPopUp: true });
  };

  componentDidMount() {
    document.addEventListener("mousedown", this.handleClickOutside);
    document.addEventListener("mousedown", this.handleClickOutsideContact);
    document.addEventListener("keydown", this.handleEscapeKeyEvent);
    if (screen && screen.orientation && screen.orientation.addEventListener) {
      screen.orientation.addEventListener(
        "change",
        this.handleOrientationChange
      );
    }
  }

  componentWillUnmount(): void {
    document.removeEventListener("mousedown", this.handleClickOutside);
    document.removeEventListener("mousedown", this.handleClickOutsideContact);
    document.removeEventListener("keydown", this.handleEscapeKeyEvent);
    if (
      screen &&
      screen.orientation &&
      screen.orientation.removeEventListener
    ) {
      screen.orientation.removeEventListener(
        "change",
        this.handleOrientationChange
      );
    }
  }

  handleClickOutside = (event: any) => {
    if (
      this.popoverRef &&
      this.state.showAccountPopover &&
      this.popoverRef.current &&
      !this.popoverRef.current.contains(event.target)
    ) {
      this.setState({ showAccountPopover: false });
    }
  };

  handleClickOutsideContact = (event: any) => {
    if (
      this.contactRef &&
      this.state.showContactPopover &&
      this.contactRef.current &&
      !this.contactRef.current.contains(event.target)
    ) {
      this.setState({ showContactPopover: false });
    }
  };

  handleEscapeKeyEvent = (event: any) => {
    handleKeyDown(
      event,
      () => {
        if (this.state.showAccountPopover) {
          this.setState({ showAccountPopover: false });
        }
        if (this.state.showContactPopover) {
          this.setState({ showContactPopover: false });
        }
      },
      "Escape"
    );
  };

  handleOrientationChange = () => {
    this.setState({ windowWidth: window.innerWidth });
  };

  getMyaccount = () => {
    this.setState({
      showMyAccountPopUp: true,
    });
  };

  screenShareLink = () => {
    this.state.screenShareInProgress
      ? this.endScreenShareSession()
      : this.setState({ showAccessCodePopUp: true });
  };

  onCancelMyAccountPopUp = () => {
    this.setState({
      showMyAccountPopUp: false,
    });
  };

  onCancelAccessCodePopUp = () => {
    this.setState({
      screenShareInProgress: false,
      showAccessCodePopUp: false,
    });
  };

  onHideChangeNumberPopUp = () => {
    this.setState({
      showChangeNumberPopUp: false,
    });
  };

  onSaveNumberClick = (
    changedMobileNumber: string,
    changedCountryCode: string
  ) => {
    if (
      this.props.headerInfo.loggedInUserInfo.role.toString() ===
      Role[Role.CPA].toString()
    ) {
      this.onHideChangeNumberPopUp();
    } else {
      if (validateMobileNumber(changedMobileNumber, changedCountryCode)) {
        const params: any = this.props.match.params;

        this.setState(
          {
            mobileNumber: changedMobileNumber,
            countryCode: changedCountryCode,
          },
          () => {
            this.props.updateMobileNumber(
              params.clientId,
              this.state.mobileNumber,
              this.state.countryCode,
              () => {
                MarsNotifier.Success(
                  MyAccountConstants.StatusMessage.UpdateInformation,
                  null
                );
              }
            );
          }
        );
        this.setState({
          showChangeNumberPopUp: false,
          showMyAccountPopUp: false,
        });
      }
    }
  };

  onChangeMobileNumberClick = () => {
    this.setState({
      showChangeNumberPopUp: true,
    });
  };

  handleValidationSuccessful = () => {
    this.setState({
      showAccessCodePopUp: false,
      screenShareInProgress: true,
    });
  };

  endScreenShareSession = () => {
    this.webrtcRef.onHangup();
    this.setState({ showAccessCodePopUp: true });
  };
  onLogOutCompletion = (id: string) => {
    let param: any = this.props.match.params;
    const url = PathConstants.ControllerLogin + id;
    this.props.history.push(url);
  };
  Logout() {
    let param: any = this.props.match.params;

    const message: ILogoutMessage = {
      isLoggedOut: true,
      clientGuid: param.clientId,
    };

    channel
      .postMessage(message)
      .then((value: any) => {
        this.props.logout(param.clientId, this.onLogOutCompletion);
      })
      .catch((error: any) => {
        console.log(error);
      });
  }

  public render() {
    let param: any = this.props.match.params;
    let companyLogo = null;
    const toggleMenuWidth =
      this.state.windowWidth > 0
        ? this.inputRef.current?.getBoundingClientRect()?.width
        : 0;
    if (this.state.headerInfo.companyWhiteLogoPath == "") {
      companyLogo = (
        <span
          style={{ marginLeft: `${toggleMenuWidth > 0 ? "30px" : ""}` }}
          className="company-name"
        >
          {this.state.headerInfo.companyName}
        </span>
      );
    } else {
      companyLogo = (
        <img
          style={{ marginLeft: `${toggleMenuWidth > 0 ? "30px" : ""}` }}
          className="logo-img"
          src={this.state.headerInfo.companyWhiteLogoPath}
          alt="company-white-logo"
        />
      );
    }

    let contactPersonFullName: string = "";
    const contactPerson = this.state.headerInfo.contactPerson;
    if (contactPerson) {
      contactPersonFullName += contactPerson.firstName;
      if (contactPerson.middleName && contactPerson.middleName.length > 0) {
        contactPersonFullName += " " + contactPerson.middleName;
      }
      if (contactPerson.lastName && contactPerson.lastName.length > 0) {
        contactPersonFullName += " " + contactPerson.lastName;
      }
    }

    const contactInfoPopover = (
      <Popover id="contactInfoPopover" placement="bottom">
        <div ref={this.contactRef}>
          <h3 className="popover-header">Contact Information</h3>
          <div
            className="popover-body"
            data-test-auto="3464EE21-1DF8-4F1F-BE4B-D838ACE36298"
          >
            <div className="medium">{contactPersonFullName} </div>
            <div>
              <strong>{utilities.formateFax(contactPerson.phoneNumber)}</strong>
              {contactPerson &&
                contactPerson.extension &&
                contactPerson.extension.length > 0 && (
                  <strong> Ext: {contactPerson.extension} </strong>
                )}
            </div>
            <div className="mail">
              <a
                tabIndex={2}
                style={{ color: "#0973BA" }}
                href={"mailto:" + contactPerson.email}
              >
                {contactPerson.email}
              </a>
            </div>
            {this.props.match.url.search("screenshare") < 1 && (
              <div className="screen-share">
                <Link
                  tabIndex={3}
                  id="lnkAccessCodePopup"
                  to={"#"}
                  onClick={() => this.screenShareLink()}
                  data-test-auto="CD23630C-42E3-4CF7-A9A9-5EB35C5B0C69"
                >
                  <SvgIconRequestScreenShare className="modalIcon" />
                  <span>
                    {this.state.screenShareInProgress
                      ? " Stop Screen Share"
                      : " Share Screen"}
                  </span>
                </Link>
              </div>
            )}
          </div>
        </div>
      </Popover>
    );
    const accountPopover = (
      <Popover id="accountPopover" placement="bottom-start">
        <div className="popover-body" ref={this.popoverRef}>
          <div className="account-menu">
            <div
              className="profile-name"
              title={this.props.headerInfo.clientName}
            >
              Hi, {this.props.headerInfo.clientName}!
            </div>
            <div
              className="my-account dropdown-item"
              onClick={this.getMyaccount}
              onKeyDown={(e: any) => handleKeyDown(e, this.getMyaccount)}
              data-test-auto="65AD7EA3-7B05-43C9-B862-F079DE711606"
              tabIndex={5}
            >
              Profile
            </div>
            {this.props.headerInfo &&
              this.props.headerInfo.loggedInUserInfo.role.toString() !==
                Role[Role.CPA].toString() && (
                <>
                  <hr className="dropdown-divider" />
                  <div
                    className="log-out dropdown-item"
                    onClick={() => this.Logout()}
                    onKeyDown={(e: any) =>
                      handleKeyDown(e, () => this.Logout())
                    }
                    data-test-auto="767609CB-7931-49FF-A431-CBF72F015D70"
                    tabIndex={6}
                  >
                    Log Out
                  </div>
                </>
              )}
          </div>
        </div>
      </Popover>
    );

    return (
      <header className="app-header">
        <div
          className="header-left-container"
          data-test-auto="951602DF-76D9-480A-BA0F-D12E216FBB2B"
        >
          <input
            type="checkbox"
            className="openSidebarMenu"
            id="openSidebarMenu"
            aria-label="Toggle Side Menu"
          />
          <label
            ref={this.inputRef}
            htmlFor="openSidebarMenu"
            className="sidebarIconToggle"
            aria-label="Side Menu Icon"
          >
            <div className="spinner diagonal part-1"></div>
            <div className="spinner horizontal"></div>
            <div className="spinner diagonal part-2"></div>
          </label>
          <div id="sidebarMenu">
            <ul className="sidebar navbar-nav">
              <li
                className="nav-item layout-dropdown mobile-header-taxYear show"
                data-test-auto="1D9B4F96-A523-4A04-AA58-AB62A9B1DD3A"
              >
                <span
                  className="nav-link layout-dropdown-toggle"
                  role="button"
                  data-toggle="layout-dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                  id="pageslayout-dropdown"
                >
                  <i className="fa fa-calendar margin-right-10"></i>
                  <span>Tax Year</span>
                </span>
                <div
                  className="layout-dropdown-menu show"
                  aria-labelledby="pageslayout-dropdown"
                >
                  <a className="layout-dropdown-item active">
                    {this.state.headerInfo.taxYear}
                  </a>
                </div>
              </li>
              <li
                className="nav-item layout-dropdown show"
                data-test-auto="9E3149F1-20E9-48E8-9067-5E70360C410F"
              >
                <span
                  className="nav-link layout-dropdown-toggle"
                  role="button"
                  data-toggle="layout-dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                  id="pageslayout-account"
                >
                  <i className="fa fa-cog margin-right-10"></i>
                  <span>Account</span>
                </span>
                <div
                  className="layout-dropdown-menu show"
                  aria-labelledby="pageslayout-account"
                >
                  <a
                    className="layout-dropdown-item"
                    onClick={() => this.getMyaccount()}
                    data-test-auto="05558ACE-66E2-42FA-A41F-FF6E8AEC1881"
                  >
                    <i className="fa fa-user ddl-icon headerForeColor"></i>My
                    Account
                  </a>
                  <a
                    className="layout-dropdown-item"
                    onClick={() => this.Logout()}
                    data-test-auto="A213D79D-034B-4264-8249-C1D438D433B3"
                  >
                    <i className="fa fa-sign-out ddl-icon headerForeColor"></i>
                    Logout
                  </a>
                </div>
              </li>
            </ul>
          </div>

          {companyLogo}
        </div>
        <div
          className="header-right-container"
          data-test-auto="710F957F-072E-4A27-867F-0B40C2077D3B"
        >
          <span className="header-account">
            <OverlayTrigger
              data-test-auto="D8169D39-B5BF-4166-B3E7-DA97E9F7AECB"
              rootClose
              trigger="click"
              onEnter={() => document.body?.click()}
              overlay={accountPopover}
              placement="bottom"
              show={this.state.showAccountPopover}
            >
              <a
                tabIndex={4}
                onClick={() =>
                  this.setState({
                    showAccountPopover: !this.state.showAccountPopover,
                  })
                }
                onKeyDown={(e: any) =>
                  handleKeyDown(e, () =>
                    this.setState({
                      showAccountPopover: !this.state.showAccountPopover,
                    })
                  )
                }
              >
                <span style={{ fontWeight: "700" }}>
                  {this.state.headerInfo.clientName}
                </span>
                <ArrowIcon fillColor="var(--headerForeColor)" />
              </a>
            </OverlayTrigger>
          </span>

          <span
            className="header-contact-info"
            title="Contact Person's Information"
          >
            <OverlayTrigger
              data-test-auto="CB7E32C5-518D-40B5-8E8F-E75C9A89CF58"
              rootClose
              trigger="click"
              onEnter={() => document.body?.click()}
              overlay={contactInfoPopover}
              placement="bottom"
              show={this.state.showContactPopover}
            >
              <a
                tabIndex={1}
                onClick={() =>
                  this.setState({
                    showContactPopover: !this.state.showContactPopover,
                  })
                }
                onKeyDown={(e: any) =>
                  handleKeyDown(e, () =>
                    this.setState({
                      showContactPopover: !this.state.showContactPopover,
                    })
                  )
                }
              >
                <SvgIconContactHeader
                  fillColor="var(--headerForeColor)"
                  height={20}
                />
                <span>Contact</span>
              </a>
            </OverlayTrigger>
          </span>
          <MyAccount
            key="value"
            onHide={this.onCancelMyAccountPopUp}
            showState={this.state.showMyAccountPopUp}
            onChangeNumberClick={this.onChangeMobileNumberClick}
            controller={this.props.controllerInfo}
            isTPMfaEnabled={this.props.companyData.isTPMfaEnabled}
            onSaveButtonClick={(countryCode: string, mobileNumber: string) => {
              this.onSaveNumberClick(mobileNumber, countryCode);
            }}
          />
          {this.state.showAccessCodePopUp && (
            <AccessCode
              key="value"
              onHide={this.onCancelAccessCodePopUp}
              showState={this.state.showAccessCodePopUp}
              onScreenSharingStart={this.props.validateTimeBasedAccessCode}
              onValidationSuccessful={this.handleValidationSuccessful}
              inProgress={this.state.screenShareInProgress}
              match={this.props.match}
              isPreviewMode={this.props.isPreviewMode}
            />
          )}

          <ChangeNumber
            onHideChangeNumberPopUp={this.onHideChangeNumberPopUp}
            showState={this.state.showChangeNumberPopUp}
            mobileNumber={this.props.controllerInfo.mobileNumber}
            countryCode={this.props.controllerInfo.countryCode}
            onSaveNumberClick={this.onSaveNumberClick}
          />
          {this.state.screenShareInProgress && (
            //load a signalr component for webrtc
            <WebrtcSignalR
              ref={(ref) => (this.webrtcRef = ref)}
              isDebugEnabled={false}
              match={this.props.match}
              isCPAMode={false}
              handleRemoveStream={() => {
                this.setState({ showAccessCodePopUp: true });
              }}
              getIceServers={this.props.getIceServers}
              iceServers={this.props.iceServers}
              getWebRTCSignalRHubBaseURL={this.props.getWebRTCSignalRHubBaseURL}
              webRTCSignalRHubBaseURL={this.props.webRTCSignalRHubBaseURL}
            />
          )}
        </div>
      </header>
    );
  }
}
