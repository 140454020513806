
import { connect } from 'react-redux';
import { ErrorPage} from "./ErrorPage";




export default connect(
    (state: any) => ({
        ErrorMessage: state.ErrorState 
     
    }),
    {
       
    }
)(ErrorPage as any);

