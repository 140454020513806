import React, { FC, ReactElement } from "react";
import { Modal } from "react-bootstrap";
import ModalCloseIcon from "../../../assets/images/ModalCloseIcon";
import "./SuiteModal.style.scss";

interface SuiteModalProps {
  width: string;
  title: string;
  theme: string;
  onClickOutside: () => void;
  onClickClose: () => void;
  children: ReactElement<any, any>;
  className?: string;
}
const SuiteModal: FC<SuiteModalProps> = (props) => {
  const {
    width,
    title,
    theme,
    onClickOutside,
    onClickClose,
    children,
    className,
  } = props;
  let widthClassName = `modal${width}`;
  return (
    <Modal
      show={true}
      onHide={onClickOutside}
      className={`suiteModal ${widthClassName} ${className ? className : ""}`}
    >
      <Modal.Header className={theme === "light" ? "lighttheme" : "darktheme"}>
        <div>
          <Modal.Title>{title}</Modal.Title>
        </div>
        <div
          data-test-auto="e2ab6c41-b6f5-4181-b38f-3455585b2141"
          className="closeIcon"
          onClick={onClickClose}
        >
          <ModalCloseIcon />
        </div>
      </Modal.Header>
      {children}
    </Modal>
  );
};

export default SuiteModal;
