import * as React from 'react';
import { Modal, Row, Col } from 'react-bootstrap';
import { Countdown } from '../../Helper/CountDown';
import { match } from 'react-router';
import { ButtonComponent } from 'cp-common-ui-components';
import { initializeAxios } from '../../../core/services/dataAccess/DataService.Axios';

const WarningVisibleMinutes = 1;

interface ISessionTimeoutProps {
    match: match,
    idleSessionTimeoutInSeconds: number
    refreshToken(clientId: string, callback: () => void): void
}

interface ISessionTimeoutState {
    showTimeout: boolean
}

export class DelegateeSessionTimeout extends React.Component<ISessionTimeoutProps, ISessionTimeoutState> {
    private countDown?: Countdown;
    constructor(props: ISessionTimeoutProps) {
        super(props);
        this.countDown = undefined;
        this.state = {
            showTimeout: false
        };
    }

    componentDidMount() {
        this.setExpiryTime();
    }

    public setExpiryTime = () => {
        window.setTimeout(() => { this.setState({ showTimeout: true }) }, 1000 * 60 * ((this.props.idleSessionTimeoutInSeconds / 60 ) - WarningVisibleMinutes));
    }

    public render() {
        return <Modal
            className="session-timeout-modal"
            show={this.state.showTimeout}
            onHide={this.onLogout}>
            <Modal.Header closeButton data-test-auto="60334535-F01C-41D3-AC34-CA9BC7B804DD">
                <Modal.Title>
                    <span className='modalIcon text-secondary fa fa-clock'>
                    </span>Warning
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Row data-test-auto="F12E9E3A-5B0D-4463-9329-750FAA9B7D12">
                    <Col lg={3}>
                        <img width="100px" height="100px"
                            src="/dist/assets/images/SessionTimeout.svg"
                            className="svg-shadow" alt='session-timeout-logo' />
                    </Col>
                    <Col lg={9}>
                        <h2 style={{ color: '#337ab7' }}>Session Expiration</h2>
                        <div>Your session will expire in <Countdown
                            date={this.getCountDown()}
                            onComplete={this.onLogout}
                            ref={(instance: any) => { this.countDown = instance }} />
                        </div>
                        <div>Do you want to continue working?</div>
                    </Col>
                </Row>
            </Modal.Body>
            <Modal.Footer>
                <ButtonComponent
                    data-test-auto="C0280D93-94DD-438B-9AB9-4F5BC452FE66"
                    onClick={this.onLogout}
                    variant={"outline-tertiary"}
                    buttonClassName={"button-margin-right"}
                    size={"medium"}
                >
                    <i style={{ marginRight: '4px' }} className='fas fa-sign-out-alt'></i>Logoff
                </ButtonComponent>
                <ButtonComponent
                    data-test-auto="CD93CDEF-A642-4D28-9C11-E6913BC2C2C0"
                    onClick={this.onStayAlive}
                    variant={"primary"}
                    size={"medium"}
                >
                    <i style={{ marginRight: '4px' }} className='fas fa-check-double'></i>Continue Working
                </ButtonComponent>
            </Modal.Footer>
        </Modal>
    }

    private getCountDown = (): Date => {
        const now = new Date();
        let ret = now;
        ret.setMinutes(now.getMinutes() + WarningVisibleMinutes);
        return ret;
    }

    private onStayAlive = (e: React.SyntheticEvent<EventTarget>) => {
        e.preventDefault();
        let param: any = this.props.match.params;
        if (this.countDown) {
            clearInterval(this.countDown.interval);
        }
        this.props.refreshToken(param.clientId, this.setExpiryTime);
        this.setState({ showTimeout: false });
    }

    private onLogout = () => {
        let param: any = this.props.match.params;
        initializeAxios().post('api/Authentication/DelegateeLogout/' + param.clientId)
            .then(() => {})
            .catch(() => {})
    }
}