import * as React from 'react';
import { UploadedFile } from './UploadDocuments';
import { container } from '../../../startup/inversify.config';
import { IDialogBox } from '../../../core/utilities/ui/DialogBox';
import { TYPES } from '../../../startup/types';
import { Common, ManualSignConstants } from '../../Common/Constants';
import { IDateUtilities } from '../../../core/utilities/date/DateUtilities';
import { logger } from '../../../routes';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import { SvgAttachmentIcon, SvgDeleteIcon } from '../../../components/Common/Icons/SvgIcons';
import { Button } from 'react-bootstrap';

interface UploadedDocumentTableProps {
    clientId: string;
    files: UploadedFile[];
    deleteSignedDocument?(clientId: string, id: number): void;
}


const dialogBox = container.get<IDialogBox>(TYPES.IDialogBox);
const dateUtilities = container.get<IDateUtilities>(TYPES.IDateUtilities);

export const UploadedDocumentTable: React.FunctionComponent<UploadedDocumentTableProps> = ({
    clientId, files, deleteSignedDocument
}) => {

    let handleDeleteDocument = (id: number) => {

        dialogBox.confirmDelete(Common.DialogBox.DeleteTitle, ManualSignConstants.FileDeleteConfirmation, Common.DialogBox.Cancel, Common.DialogBox.Delete, (result: boolean) => {
            logger.trackTrace('Remove manually uploaded signed document confirmation result', { "ClientId": clientId, "SignedDocumentId": id, "Result": result  });
            result && deleteSignedDocument &&  deleteSignedDocument(clientId, id);
        });

    }


    const fileNameCell = (cell: any, row: any) => {
            let re: RegExp = /(?:\.([^.]+))?$/;
            let fileExtArray = re.exec(cell); 
            let fileExt = (fileExtArray != null) ? fileExtArray[1] : undefined;
        return (  <div className="task col-7 d-flex no-padding">
         <SvgAttachmentIcon />
         <div className='ml-2'>
             <div className="name" title={cell}> {cell}</div>             
         </div>
     </div>)
    }  

    const dateCell = (cell: any, row: any) => {
        return (<div className="dates col-3 no-padding">
        <div className="bar" style={{color:"#898D91"}} title={dateUtilities.getFormattedDate(cell)}>
            {dateUtilities.getFormattedDate(cell)}</div>
    </div>)
    }    

    const deleteCell = (cell: any, row: any) => {
        return (
        <>
        <div className="priority col-2 no-padding">
        {row.id > 0 ? (
            <Button
            onClick={() => { row.id && handleDeleteDocument(row.id) }}
            className="signed-file-delete no-padding"
            title='Delete'
            variant={"default"}
            style={{marginLeft: '-12px'}}
          >
            <SvgDeleteIcon/>
            <span className='ml-2'>Delete</span>
        </Button>        
       ) : (<i className="fa fa-spinner fa-spin ml-3" />)
        }
    </div>
        </>
       )
    }

    let columns = [
        {
            header: '',
            key: 'rowIndex',
            isKey: true,
            columnClassName: '',
            dataSort: true,
            toolTip: false,
            isHidden: true,
            width: 'auto',

        },
        {
            header: "File Name",
            key: 'fileName',
            isKey: false,
            dataFormat: fileNameCell,
            columnClassName: '',
            dataSort: false,
            toolTip: false,
            isHidden: false,
            width: '50%',
        },
        {
            header: "Date",
            key: 'date',
            isKey: false,
            dataFormat: dateCell,
            columnClassName: '',
            dataSort: false,
            toolTip: false,
            isHidden: false,
            width: 'auto',
        },
        {
            header: "Actions",
            key: 'id',
            isKey: false,
            dataFormat: deleteCell,
            columnClassName: '',
            dataSort: false,
            toolTip: false,
            isHidden: false,
            width: 'auto',
        },
    ];

    const data = files.map((model, index) => {
        return {
            fileName: model.name,
            date: model.uploadedOn,
            id: model.id,
            rowIndex: index,
        }
    });

    return (<div className="file-list-container">
        <div className={(files.length > 0 ? ("filepicker uploaded-files-list minimized") : ("filepicker uploaded-files-list"))} data-test-auto="AA01CE21-29D4-42E6-9F0C-2E058D3E9A46">
        { data.length >0 && <div style={{ width : '100%' }} data-test-auto="77357D6E-0A73-4063-8FCC-A28DA25A0F5E" className="margin-top-10">
                    <BootstrapTable
                        data={data}
                        remote={false}
                        bordered={false}
                        withoutTabIndex={true}
                    >
                        {columns.map((value, index) => {
                            const header = value.header === "" && value.isHidden ? `Column ${index + 1}` : value.header;
                            return <TableHeaderColumn
                                key={index}
                                isKey={value.isKey}
                                dataField={value.key}
                                hidden={value.isHidden}
                                width={value.width}
                                dataFormat={value.dataFormat}
                                columnClassName={value.columnClassName}
                                columnTitle={value.toolTip}
                                dataSort={value.dataSort}
                                thStyle={{borderTop: 'none'}}
                                tdStyle={{borderTop: 'none'}}
                            >
                                {header}
                            </TableHeaderColumn>;
                        })}
                    </BootstrapTable>
                </div>}                     
        </div>
    </div>);
};