import * as React from 'react';
import { match } from 'react-router';
import { DocumentSignatureDataViewModel } from '../../../core/domain/viewModels/IDocumentSignatureDataViewModel';
import { PdfView } from '../../Esign/Main/PdfView';
import { IDocument } from '../../../core/domain/models/esign/Document';
import { EsignManager, IEsignValidationModel } from '../../Esign/Main/EsignBase';
import { MarsNotifier } from '../../Common/Notification/MarsNotifier';
import { Main } from '../../Esign/Main/Main';
import { History } from 'history';
import { ViewerMode } from 'awesome-pdf-viewer/dist/viewer/ViewerBase';
import { IHeaderInfoViewModel } from '../../../core/domain/viewModels/IHeaderInfoViewModel';
import { EngagementTypeS } from '../../../core/common/Enums';
import { isIndividualEngagementType } from '../../Helper/HelperFunction';
import { DelegateeCancelPopup } from '../DelegateeCancelPopup';
import { DelegateeSignerConstants, DelegateePathConstants, PathConstants } from '../../Common/Constants';
import TaxpayerHelper from '../../Helper/TaxpayerHelper';
import { IMarriedJointTaxReturn } from '../../../core/domain/models/ITaxReturn';
import { ISignerModel } from '../../../core/domain/models/ISignerModel';
import { resizeEventHandler } from "../../Helper/HelperFunction";
import { logger } from '../../../routes';

export const NO_INDEX = -1;

interface EsignState {
    documentControlsAndData: DocumentSignatureDataViewModel[],
    showTimeout: boolean,
    finishEnabled: boolean,
    showSubmitLoader: boolean,
    showModal: boolean,
    declineRemarks: string,
}

export interface ESignProps {
    requestSignatureControls: (clientGuid: string) => void;
    match: match;
    history: History;
    signatureControlsData: IDocument[];
    onSigningComplete: (signData: IDocument[]) => any;
    disableNextButton: (value: boolean) => void;
    completeSigningStep: () => void;
    isSpouseSignRequired: boolean;
    sign?: (clientId: string, documentData: IDocument[], callback: (status: boolean) => void) => void;
    onHeaderAndFooterVisibility: (visibility: boolean) => void;
    isPreviewMode: boolean;
    headerInfo: IHeaderInfoViewModel;
    engagementType: EngagementTypeS;
    saveDeclinedRemarks: (clientId: string, remarks: string, callback: () => void) => void;
    resetTaxClientLastVisitedSteps: (clientId: string, callback: () => void) => void;
    mutual: IMarriedJointTaxReturn;
    signerData: ISignerModel[];
    logout: (clientGuid: string, callback: (id: string) => void) => void;
}


export class Esign extends React.Component<ESignProps, EsignState> {

    private _pdfView: any;

    constructor(props: any, states: EsignState) {
        super(props, states);
        this.state = {
            documentControlsAndData: [],
            showTimeout: false,
            finishEnabled: false,
            showSubmitLoader: false,
            showModal: false,
            declineRemarks: "",
        }
        this.props.disableNextButton(!this.props.isPreviewMode);
    }

   

    componentDidMount() {
        window.addEventListener('resize', resizeEventHandler);

        let param: any = this.props.match.params;
        if (this.props.signatureControlsData.length == 0) {
            this.props.requestSignatureControls(param.clientId);
        }
        let declineBtn: any = document.getElementById("awesome-multi-steps-btn-skip");
        declineBtn && (declineBtn.style.float = "left");
        declineBtn && (declineBtn.style.marginLeft = "30px");
    }

    componentWillUnmount() {
        window.removeEventListener('resize', resizeEventHandler);
    }

    static getDerivedStateFromProps(nextProps: ESignProps, nextState: EsignState) {
        if (nextProps.signatureControlsData &&
            nextProps.signatureControlsData.length !== nextState.documentControlsAndData.length) {
            return { documentControlsAndData: nextProps.signatureControlsData }
        }
        else {
            return { documentControlsAndData: nextState.documentControlsAndData };
        }
    }

    handleDocumentSignFinish = (documentControlData: DocumentSignatureDataViewModel) => {
        let documentDataList: DocumentSignatureDataViewModel[] = [...this.state.documentControlsAndData];

        const index = documentDataList.findIndex(x => x.id === documentControlData.id);
        if (index == NO_INDEX) {
            documentDataList.push(documentControlData);
        }
        else {
            documentDataList[index] = documentControlData;
        }
        this.setState({ documentControlsAndData: documentDataList });
    }

    handleSigningCompleted = () => {

        const documentData: IDocument[] = this._pdfView.getDocumentsData();
        const validation: IEsignValidationModel = EsignManager.validateEsignData(documentData);

        if (validation.status) {
            this.props.onSigningComplete(documentData);
            this.props.disableNextButton(false);

        }
        else {
            this._pdfView.showValidationMessage(validation.document, validation.page, validation.control);
        }
    }

    handleNextButton = (isDisable = false) => {
        this.props.disableNextButton(isDisable);
    }

    onNext(index: number) {
        const _self = this;
        const documentData: IDocument[] = _self._pdfView.getDocumentsData();
        const validation: IEsignValidationModel = EsignManager.validateEsignData(documentData);

        if (!validation.status) {
            _self._pdfView.showValidationMessage(validation.document, validation.page, validation.control);
        }
        else {
            _self.setState({ showSubmitLoader: true }, () => {
                _self.props.disableNextButton(true);
                let param: any = this.props.match.params;

                _self.props.sign && this.props.sign(param.clientId, _self.props.signatureControlsData, (status: boolean, hideLoader?: () => void) => {
                    if (status) { 
                        setTimeout(function () {
                            hideLoader && hideLoader();   
                            _self.props.completeSigningStep();     
                        }, 5000);
                          
                    }
                    else {
                        _self.setState({ showSubmitLoader: false }, () => { MarsNotifier.Error("Signing process failed!", ""); });
                    }
                });
            });
        }    
    }

    handleViewModeChange = (viewerMode: ViewerMode) => {

        if (viewerMode == ViewerMode.Read) {
            this.props.onHeaderAndFooterVisibility(true);
        }
        else {
            this.props.onHeaderAndFooterVisibility(false);
        }
    }

    onShowModal = () => {
        this.setState({ showModal: true });
    }

    onClose = () => {
        this.setState({ showModal: false });
    }

    onRemarksChange = (event: any) => {
        this.setState({ declineRemarks: event.target.value });
    }

    onSubmit = () => {
        const param: any = this.props.match.params;
        this.props.resetTaxClientLastVisitedSteps(param.clientId, () => {
            this.props.saveDeclinedRemarks(param.clientId, this.state.declineRemarks, () => {
                MarsNotifier.Success(DelegateeSignerConstants.SuccessMessage.DeclineSignSuccess, null);
                logger.trackTrace(`Delegatee signing declined`, { "ClientId": param.clientId, "PreviewMode": this.props.isPreviewMode });
                this.logout();
            });
        });
    }

    logout = () => {
        let param: any = this.props.match.params;
        this.props.logout(param.clientId, this.onLogOutCompletion);
    }
    onLogOutCompletion = (id: string) => {
        const url = PathConstants.DelegateeLogin + id;
        this.props.history.push(url);
    }


    public render() {
        const param: any = this.props.match.params;
        return (
            <Main>
                <PdfView
                    onViewModeChange={this.handleViewModeChange}
                    documents={this.props.signatureControlsData}
                    onSigningCompleted={this.handleSigningCompleted}
                    disableNextButton={this.handleNextButton}
                    headerInfo={this.props.headerInfo}
                    ref={(ref: PdfView) => this._pdfView = ref}
                    doNotAllowToEditSignatureName={isIndividualEngagementType(this.props.engagementType)}                    
                    spouseName={TaxpayerHelper.getSecondSignerName(this.props.mutual, this.props.signerData, param.clientId)}
                />

                <DelegateeCancelPopup
                    onCancel={this.onClose}
                    show={this.state.showModal}
                    onSaveRemarks={this.onSubmit}
                    reason={this.state.declineRemarks}
                    onRemarksChange={this.onRemarksChange}
                    title={"Reason for Decline"}
                    warningMessage={DelegateeSignerConstants.WarningMessage.EmptyDeclineSignRemarks}
                />
            </Main>

        );
    }
}
