import * as React from "react";
import { Shimmer } from "../../../components/Common/Shimmer/Shimmer";
import { SignatureType, DocumentStatus, SignatureStatus } from "../../../../src/core/common/Enums";
import {
    FormType,
    isIndividual,
    isMutual,
    isPartnership,
    ToIndividual,
    ToMutual,
    ToPartnership
} from "../../../../src/core/domain/models/ITaxReturn";
import { History } from "history";
import { WelcomeMessage } from "../../CompletedSummary/Parts/WelcomeMessage";
import { CompletedSummaryConstant } from "../../Common/Constants";
import { Common, DelegateePathConstants, PathConstants, SiginingConstants } from "../../Common/Constants";
import { ISignedDocument } from "../../../../src/core/domain/models/manualsign/SignedDocument";
import { IAdditionalEsignDocument } from "../../../../src/core/domain/models/IAdditionalEsignDocument";
import { IDownloadableDocumentsViewModel } from "../../../../src/core/domain/viewModels/IDownloadableDocumentsViewModel";
import { IDialogBox } from "../../../core/utilities/ui/DialogBox";
import { container } from "../../../startup/inversify.config";
import { TYPES } from "../../../startup/types";
import TaxpayerHelper from "../../Helper/TaxpayerHelper";
import { TileComponent } from "cp-common-ui-components";
import { handleKeyDown } from "../../../components/Helper/HelperFunction";
import { IClientProcessViewModel } from "src/core/domain/viewModels/IClientProcessViewModel";
import { ButtonComponent } from "cp-common-ui-components";

export interface CompletedWelcomePageProps {
    taxYear: number;
    param: any;
    clientName: string;
    taxClientGuid: string;
    taxReturn: any;
    history: History;
    downloadSignedEFileDocument: (clientId: string, filename: string) => any;
    signedDocument: ISignedDocument[];
    downloadAllAdditionEsignDocuments: (clientId: string, filename: string) => any;
    downloadableDocumentsViewModel: IDownloadableDocumentsViewModel;
    downloadEFileDocument: (clientId: string) => any;
    clientProcessData: IClientProcessViewModel;
    getClientHubDomainURL: (id: string, callback: (clientHubDomainURL: string) => void) => void;
}

const dialogBox = container.get<IDialogBox>(TYPES.IDialogBox);

export class CompletedSummaryPage extends React.Component<CompletedWelcomePageProps, {}> {
    constructor(props: CompletedWelcomePageProps) {
        super(props);
        this.state = {
            showModal: false
        };
    }

    getSignedEfileName = () => {
        let fileNamePrefix = this.getFileNamePrefix();
        let fileName = "";
        if (this.props.signedDocument && this.props.signedDocument.length == 1) {
            if (
                TaxpayerHelper.isMarried(this.props.taxReturn) &&
                this.props.taxReturn.signatureStatus == SignatureStatus[SignatureStatus.PartiallySigned]
            )
                fileName = "_Signature Document.pdf";
            else fileName = "_Signed Document.pdf";
        } else if (this.props.signedDocument && this.props.signedDocument.length > 1) {
            if (
                TaxpayerHelper.isMarried(this.props.taxReturn) &&
                this.props.taxReturn.signatureStatus == SignatureStatus[SignatureStatus.PartiallySigned]
            )
                fileName = "_Signature Documents.zip";
            else fileName = "_Signed Documents.zip";
        }
        return fileNamePrefix + fileName;
    };

    getFileNamePrefix = () => {
        return (
            this.getClientName() +
            "_" +
            this.props.taxYear +
            "_" +
            this.props.taxReturn.engagementType.toString().substr(1)
        );
    };

    private getClientName = () => {
        const taxDocument = this.props.taxReturn;
        if (isIndividual(taxDocument)) {
            const individualTaxDocument = ToIndividual(taxDocument);
            return individualTaxDocument.taxpayer.name;
        } else if (isMutual(taxDocument)) {
            const joinTaxDocument = ToMutual(taxDocument);
            if (this.props.taxClientGuid == joinTaxDocument.taxpayer.clientGuid) return joinTaxDocument.taxpayer.name;
            if (this.props.taxClientGuid == joinTaxDocument.spouse.clientGuid) return joinTaxDocument.spouse.name;
        } else if (isPartnership(taxDocument)) {
            const partnerShipTaxDocument = ToPartnership(taxDocument);
            return partnerShipTaxDocument.partnership.name;
        }
    };

    getOtherSignedDocumentFileName = () => {
        if (
            TaxpayerHelper.isMarried(this.props.taxReturn) &&
            this.props.taxReturn.signatureStatus == SignatureStatus[SignatureStatus.PartiallySigned]
        )
            return this.getFileNamePrefix() + "_Other Signature Documents.zip";
        else return this.getFileNamePrefix() + "_Other Signed Documents.zip";
    };

    downloadSignedDocument = () => {
        const _self = this;
        const additionalEsignDocuments: IAdditionalEsignDocument[] =
            this.props.downloadableDocumentsViewModel &&
            this.props.downloadableDocumentsViewModel.additionalEsignDocuments;
        const efile =
            this.props.downloadableDocumentsViewModel &&
            this.props.downloadableDocumentsViewModel.downloadableDocuments.find((x) => x.groupId === FormType.EFile);

        if (
            this.props.taxReturn.documentSettings.documentSignatureSetting.signatureFormSelectionType ===
                SignatureType.ESign ||
            this.props.taxReturn.documentSettings.documentSignatureSetting.signatureFormSelectionType ===
                SignatureType.ESignWhenAllIncludedReturnsAllowed
        ) {
            if (efile && efile.documentId > 0) {
                this.props.taxReturn.documentStatus.toString() === DocumentStatus[DocumentStatus.USERSIGNED]
                    ? this.props.downloadSignedEFileDocument(this.props.param.clientId, this.getSignedEfileName())
                    : this.props.downloadEFileDocument(this.props.param.clientId);
            }

            setTimeout(function () {
                additionalEsignDocuments &&
                    additionalEsignDocuments.length > 0 &&
                    _self.props.downloadAllAdditionEsignDocuments(
                        _self.props.param.clientId,
                        _self.getOtherSignedDocumentFileName()
                    );
            }, 2000);
        } else {
            if (this.props.taxReturn.signatureStatus === SignatureStatus[SignatureStatus.ManuallySigned]) {
                dialogBox.confirm(
                    Common.DialogBox.ConfirmationTitle,
                    SiginingConstants.StatusMessage.ManualSignReviewDocumentsRedirectConfirmationMessage,
                    Common.DialogBox.Cancel,
                    Common.Wizard.NextButtonLabel,
                    (result: boolean) => {
                        result &&
                            this.props.history.push(
                                `${PathConstants.DelegateeManualSignReviewDocuments}${this.props.param.clientId}`
                            );
                    }
                );
            } else {
                this.props.history.push(`${DelegateePathConstants.ManualSign}${this.props.param.clientId}`);
            }
        }
    };

    redirectToOneHub = () => {
        this.props.getClientHubDomainURL(this.props.clientProcessData.currentstep.clientId, (oneHubDomainURL: string) => {
          window.location.href = oneHubDomainURL;
        })
      }

    public render() {
        const isDownloadSignedDisable =
            this.props.taxReturn.signatureStatus === SignatureStatus[SignatureStatus.SignatureStampingFailed]
                ? "disabledbutton"
                : "";

        const welcomeAlertMessage = CompletedSummaryConstant.CompletedMessage.replace(
            "<TAXYEAR>",
            this.props.taxYear.toString()
        ).replace("<br />", "");

        return (
            <div id="welcomeMessage">
                {this.props.clientName && this.props.taxReturn.signedDetails && this.props.taxYear > 0 ? (
                    <React.Fragment>
                        <div className="welcome-container">
                            <WelcomeMessage
                                customClassName="welcome-message-ready delegatee-completed"
                                message={CompletedSummaryConstant.WelcomeMessage2.replace(
                                    "<TAXPAYERNAME>",
                                    this.props.clientName
                                )}
                            />
                            {this.props.taxReturn.signatureStatus !==
                                SignatureStatus[SignatureStatus.SignatureStampingFailed] && (
                                <div style={{ marginTop: "7px" }}>
                                    <WelcomeMessage message={welcomeAlertMessage} />
                                </div>
                            )}
                            <div className="welcome-intro">
                                {this.props.taxReturn.signatureStatus !==
                                SignatureStatus[SignatureStatus.SignatureStampingFailed]
                                    ? CompletedSummaryConstant.InstructionText
                                    : CompletedSummaryConstant.SignatureStampingFailure}
                            </div>
                        </div>
                    </React.Fragment>
                ) : (
                    <Shimmer height={200} />
                )}

                {this.props.clientName && this.props.taxReturn.signedDetails ? (
                    <div className="cards-container">
                        <div className="step-img-btn cards-content">
                            <div
                                className={`cards ${isDownloadSignedDisable}`}
                                tabIndex={0}
                                onKeyDown={(e: any) => isDownloadSignedDisable.length === 0 && handleKeyDown(e, this.downloadSignedDocument)}
                            >
                                <TileComponent
                                    onClick={this.downloadSignedDocument}
                                    tileContent={<CompletedSummaryConstant.Images.ViewDownloadSignedDocument className="button---signed-documents" />}
                                    tileDetail={CompletedSummaryConstant.ImageCaption.ViewDownloadSignedDocument}
                                />
                            </div>
                        </div>
                    </div>
                ) : (
                    <Shimmer height={500} />
                )}
            {this.props.clientProcessData.isAccessedFromOneHub &&
                <ButtonComponent
                    buttonClassName="completebtn flrt"
                    variant={"success"}
                    onClick={() => this.redirectToOneHub()}>
                    Complete
                </ButtonComponent>
            }   
            </div>
        );
    }
}
