
import { connect } from 'react-redux';
import * as headerInfoStore from "../../../store/Common/AccountStore";
import * as HeaderStore from "../../../store/HeaderStore";
import EmailOtp from "./EmailOtp";
import * as CCROTPReducer from "../../../store/CCRecipient/OTPStore";




export default connect(
    (state: any) => ({
        headerInfoState: state.HeaderPageReducer,
        OTPPageState: state.CCRecipientOTPData   

    }),
    {
        ...HeaderStore.actionCreators,
        ...CCROTPReducer.actionCreators
        
    }
)(EmailOtp as any);