import * as React from 'react';
import { CoverPageConstants} from "../Common/Constants";


interface InvalidLinkState {

}

export class InvalidLink extends React.Component<{}, InvalidLinkState> {

	constructor(props: any, states: InvalidLinkState) {
		super(props, states);

	}

	public render() {
		return <div>

			<div className="invalid-container">

				<div className="invalid-header">

        </div>

		<div className="content-wrapper">
			<div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 error-page">
				<div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 error-page-container">
					<div className="message">
						{CoverPageConstants.InvalidLink}
					</div>
				</div>
			</div>
		</div>

    </div>
		</div>
	}
}
