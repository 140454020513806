import React, { FC, useEffect, useState } from "react";
import { MessageNoteInfoIcon, MessageNoteWarningIcon } from "../../Common/Icons/SvgIcons";
import { NotificationType } from "../../../core/common/Enums"; 

export interface MessageNoteProps {
    noteType: NotificationType;
    note: string;
    height?: number;
}

const MessageNote: FC<MessageNoteProps> = (props) => {

    return (
        <div className='message-note' style={{ minHeight: props?.height > 0 ? props.height : 63, height:'auto' }}>
            <div className='icon'>
                {
                    props.noteType === NotificationType.Warning ? (
                        <MessageNoteWarningIcon />
                    ) : props.noteType === NotificationType.Info ? (
                            <MessageNoteInfoIcon />) : ""
                }
            </div>
            <div className='text'
                dangerouslySetInnerHTML={{ __html: props.note }}>
            </div>
        </div>
    );
};

export default MessageNote;