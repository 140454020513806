import * as React from 'react';
import { ManualSignConstants } from '../../Common/Constants';
import { Shimmer } from '../../Common/Shimmer/Shimmer';
import { ICompany } from '../../../core/domain/models/company/Company';
import { phoneNumberDisplay } from '../../Common/Validations';

interface CompanyInfoProps {
    address?: string;
    fax?: string;
    companyDetails?:ICompany
}

export const CompanyInfo: React.FunctionComponent<CompanyInfoProps> = ({
    address, fax, companyDetails
}) => {

    let isFaxNull = (): boolean => {
        return fax ? false : true;
    }  

    let isAddressNull = (): boolean => {
        return address ? false : true;
    }  

    return (<div className={"col-lg-4 company-info"} data-test-auto="56ED9846-B593-41DD-A54F-32026D9AF796">

        <div hidden={isAddressNull()} className={"company-address"} data-test-auto={"5915405C-33DC-4608-AC32-AFD92C3C0CA7"}>
            <h6 className={"title"}> {ManualSignConstants.SendByMailText} </h6>
            <p>
                {companyDetails?.name != undefined ? companyDetails?.name : <Shimmer lineCount={1} />}
                <br />  {address != undefined ? unescape(address) : <Shimmer lineCount={4} />}
                {companyDetails?.address1 && <br />}{unescape(companyDetails?.address1 == undefined ? "" : companyDetails?.address1)}
                {companyDetails?.city && <br />}{unescape(companyDetails?.city == undefined ? "" : companyDetails?.city)},
                {unescape(companyDetails?.stateName == undefined ? "" : " " +companyDetails?.stateName)}
                {companyDetails?.zip && <br />}{unescape(companyDetails?.zip == undefined ? "" : companyDetails?.zip)}
            </p>
        </div>
        <br />
        <div hidden={isAddressNull() || isFaxNull()} >
            Or
        </div>
        <br />
        <div hidden={isFaxNull()} className={"company-fax"} data-test-auto={"B26D5333-0889-4324-8EFE-4F4C3D48CBC4"}>
            <h6 className={"title"}> {ManualSignConstants.SendByFaxText} </h6>
            <p>
                {fax != undefined ? phoneNumberDisplay(fax) : <Shimmer lineCount={4} />}
            </p>
        </div>
    </div>);
};