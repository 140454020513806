import * as React from "react";
import HeaderComp from "../Header/HeaderComp";
import Skeleton from "react-loading-skeleton";
import { TaxpayerAuthenticationType1 } from "../../store/SSN/SSNStore";
import Footer from "../Layout/Footer";
import { DisplayError } from "../Common/Constants";
import { SvgIconExclamationErrorCircle } from "../Common/Icons/SvgIcons";
import { SvgIconProfile } from "../Common/Icons/BrandIcons";
import { ButtonComponent } from "cp-common-ui-components";

export class SSNAuth extends React.Component<any, any> {
  continueRef: any = null;
  constructor(props: any) {
    super(props);
    this.state = {
      taxPayerAuthType1: 1,
      SSNStr: "",
      continueState: false,
      ssnError: {
        error: false,
        message: "",
      },
    };
    this.verifySSN = this.verifySSN.bind(this);
    this.handleSSNChange = this.handleSSNChange.bind(this);
    this.navigateToOTP = this.navigateToOTP.bind(this);
  }

  componentDidMount() {
    this.props.requestHeaderDetails(this.props.match.params.clientId);
    this.props.requestSSNData(
      this.props.match.params.clientId,
      this.errorCallBack
    );
  }

  componentDidUpdate() {
    console.log("got data for SSN", this.props.SSNPageState);
  }

  errorCallBack = (result?: any) => {
    let errorDesc = result.errorDescription;
    let errorCode = result.errorCode;
    if (errorCode && DisplayError.includes(errorCode)) {
      this.props.history.push("/Error/" + errorDesc);
    } else {
      this.setState({ ssnError: { error: true, message: errorDesc } });
    }
  };

  navigateToOTP(target: string) {
    let clientId: string = this.props.match.params.clientId;
    //test scenario
    if (target === "OTP") {
      this.props.history.push(`/OTP/Index/${clientId}`);
    } else {
      this.props.history.push(`/MobileOTP/Index/${clientId}`);
    }
  }

  handleSSNChange(e: any) {
    this.setState({ ssnError: { error: false, message: "" } });

    if (
      e.target.value.length <= 4 &&
      (!isNaN(e.target.value) || e.target.value.length === 0)
    ) {
      if (e.target.value.length === 4) {
        this.setState({ SSNStr: e.target.value, continueState: true });
      } else {
        this.setState({ SSNStr: e.target.value, continueState: false });
      }
    }
  }

  verifySSN() {
    let clientId: string = this.props.match.params.clientId;

    if (this.state.continueState === true) {
      this.props.verifySSN(
        this.state.SSNStr,
        clientId,
        this.navigateToOTP,
        this.errorCallBack
      );
    }
  }

  render() {
    let message: string = "";
    let hint: string = "";

    if (this.props.SSNPageState.taxpayerAuthentication !== 0) {
      if (
        this.props.SSNPageState.taxpayerAuthentication ===
        TaxpayerAuthenticationType1.First4ofSSN
      ) {
        message =
          "Please enter the first four digits of your Social Security Number";
        hint = "(We use the first four digits to verify your identity)";
      } else {
        message =
          "Please enter the last four digits of your Social Security Number";
        hint = "(We use the last four digits to verify your identity)";
      }
    }

    let verifyBtnClass = "button-primary button-medium rounded-pill disabled";
    if (this.state.continueState === true) {
      verifyBtnClass = "button-primary button-medium rounded-pill";
      this.continueRef.focus();
    }

    return (
      <>
        <HeaderComp
          headerInfo={this.props.headerInfoState}
          pathname={this.props.match.path}
          clientId={this.props.match.params.clientId}
          history={this.props.history}
          backButton={true}
          navigateLink="/CoverPage/Index"
        />
        {this.props.SSNPageState.loading === true ? (
          <Skeleton circle={false} height={"100vh"} width={"100vw"} />
        ) : (
          <div className="layout-container ssn-page d-flex align-items-center justify-content-center">
            <div className="row">
              <div className="col">
                <div className="row justify-content-center">
                  <SvgIconProfile />
                </div>
                <div className="row justify-content-center margin-top-20">
                  <h5 className="ssn-page-taxpayer-header">
                    <span>Hello&nbsp;</span>
                    <span
                      className="name-label"
                      data-test-auto="QRE5GQ404HLMZFQLVZ1R4JVTQR"
                    >
                      {this.props.SSNPageState.clientName},
                    </span>
                  </h5>
                </div>
                <div className="ssn-page-instruction-label row justify-content-center margin-top-10">
                  <div className="width-50">
                    <span data-test-auto="8546U06DYUNML97VJTE8PD8240">
                      {message}
                    </span>
                  </div>
                </div>
                <div className="row justify-content-center margin-top-50">
                  <div className="d-flex flex-column">
                    <div
                      className={
                        this.state.ssnError.error
                          ? "ssn-page-input error"
                          : "ssn-page-input"
                      }
                    >
                      <input
                        id="SSNNumber"
                        type="password"
                        aria-label={message}
                        data-test-auto="BBFA6D87-E99D-4721-8ED3-5E6AA6C1DEA9"
                        value={this.state.SSNStr}
                        className="col p-0"
                        style={{ border: "none" }}
                        placeholder="4 digits"
                        onChange={this.handleSSNChange}
                      />
                      {this.state.ssnError.error ? (
                        <SvgIconExclamationErrorCircle />
                      ) : (
                        <></>
                      )}
                    </div>
                    <div className="ssn-page-error margin-top-10"
                    data-test-auto="3E8002B3-1896-4892-BD7B-C5C2CA43D90E">
                      {this.state.ssnError.error ? (
                        <span className="error-message">
                          {this.state.ssnError.message}
                        </span>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                </div>
                <div className="row justify-content-center margin-top-50">
                  <div className="ssn-page-submit">
                    <ButtonComponent
                      ref={(ref) => {
                        this.continueRef = ref;
                      }}
                      type="submit"
                      data-test-auto="U3FJ9K54A32MN9LEDNDGRRULZH"
                      onClick={this.verifySSN}
                      buttonClassName={verifyBtnClass}
                      style={{ width: 250, height: 56 }}
                      id="btnAuthenticate"
                      variant={"primary"}
                      size={"medium"}
                    >
                      Confirm
                    </ButtonComponent>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
        <Footer />
      </>
    );
  }
}

export default SSNAuth;
