import React from "react";
import "./K1ShareHolderActivityTable.style.scss";
import {
  ISortColumn,
  K1ShareHolderActivityModel,
} from "./K1ShareHolderActivityModal";

const columnNames = {
  eventName: "Status",
  actedBy: "By",
  actedOn: "Date",
};

interface IK1ShareHolderActivityTableProps {
  k1ActivityData: K1ShareHolderActivityModel[];
  isLoading: boolean;
  sortColumn: ISortColumn;
  handleSort: (columnName: string) => void;
}

const K1ShareHolderActivityTable: React.FC<
  IK1ShareHolderActivityTableProps
> = ({ k1ActivityData, isLoading, sortColumn, handleSort }) => {
  const sortClass = (column: string) => {
    if (sortColumn.sortBy === "eventName" && column === "eventName") {
      return sortColumn.sortOrder === "asc" ? "fa-sort-asc" : "fa-sort-desc";
    } else if (sortColumn.sortBy === "actedBy" && column === "actedBy") {
      return sortColumn.sortOrder === "asc" ? "fa-sort-asc" : "fa-sort-desc";
    } else if (sortColumn.sortBy === "actedOn" && column === "actedOn") {
      return sortColumn.sortOrder === "asc" ? "fa-sort-asc" : "fa-sort-desc";
    } else {
      return "fa-sort";
    }
  };

  const formatDate = (utcDateStr: string) => {
    const date = new Date(utcDateStr + "Z");
    const optionsDate = { year: "numeric" as const, month: "2-digit" as const, day: "2-digit" as const };
    const optionsTime = { hour: "2-digit" as const, minute: "2-digit" as const, hour12: true };
    const datePart = new Intl.DateTimeFormat("en-US", optionsDate).format(date);
    const timePart = new Intl.DateTimeFormat("en-US", optionsTime).format(date);
    return `${datePart} ${timePart}`;
  };

  return (
    <>
      <table className="table table-striped table-hover k1ActivityTable">
        <thead className="table-header">
          <tr>
            <th
              className="status clickableColumns"
              scope="col"
              onClick={() => handleSort("eventName")}
            >
              Status
              <i className={`fa fa-fw ${sortClass("eventName")} sortIcon`}></i>
            </th>
            <th
              className="by clickableColumns"
              scope="col"
              onClick={() => handleSort("actedBy")}
            >
              By
              <i className={`fa fa-fw ${sortClass("actedBy")} sortIcon`}></i>
            </th>
            <th
              className="date clickableColumns"
              scope="col"
              onClick={() => handleSort("actedOn")}
            >
              Date
              <i className={`fa fa-fw ${sortClass("actedOn")} sortIcon`}></i>
            </th>
          </tr>
        </thead>
        <tbody>
          {isLoading ? (
            <tr>
              <td
                data-toggle="collapse"
                colSpan={3}
                className="k1DataLoadingArea"
              >
                <div className="text-center">
                  <div className="spinner-border" role="status"></div>
                  <div>
                    <span className="k1DataLoadingText">Loading...</span>
                  </div>
                </div>
              </td>
            </tr>
          ) : k1ActivityData.length === 0 ? (
            <tr>
              <td
                data-toggle="collapse"
                colSpan={Object.values(columnNames).length}
                className="k1DataLoadingArea text-center"
              >
                No matching records found
              </td>
            </tr>
          ) : (
            k1ActivityData.map(
              (eachK1Activity: K1ShareHolderActivityModel, index: number) => {
                return (
                  <tr key={index} className="eachActivityRow">
                    <td className="statusData ellipsis">{`${eachK1Activity.eventName}`}</td>
                    <td
                      className="byData ellipsis"
                      title={eachK1Activity.actedBy}
                    >
                      {eachK1Activity.actedBy}
                    </td>
                    <td className="dateData ellipsis">
                      {formatDate(eachK1Activity.actedOn)}
                    </td>
                  </tr>
                );
              }
            )
          )}
        </tbody>
      </table>
    </>
  );
};

export default K1ShareHolderActivityTable;
