import { Action, Reducer } from 'redux';
import { AppThunkAction } from './';
import { actionTypes } from './ActionTypes';
import { StatusType, NotificationAction } from './Common/NotificationStore';
import { initializeAxios } from '../core/services/dataAccess/DataService.Axios'
import { AxiosResponse, AxiosRequestConfig } from 'axios';
import { ILoader } from '../core/utilities/ui/Loader';
import { ErrorMessages, PayConstants } from '../components/Common/Constants';
import { IPaymentTransactionModel, initialPaymentTransactionModel } from '../core/domain/models/IPaymentTransactionModel';
import { TYPES } from '../startup/types';
import { container } from '../startup/inversify.config';
import { TelemetryLogger } from '../components/Logger/AppInsights';

const logger = TelemetryLogger.getInstance();

const loader = container.get<ILoader>(TYPES.ILoader);

export interface PaymentTransactionState {
    data: IPaymentTransactionModel;
}

export const initialPaymentTransactionState: PaymentTransactionState = {
    data: initialPaymentTransactionModel,
}

interface RequestPaymentTransactionAction {
    type: actionTypes.PAYMENT_TRANSACTION_REQUEST
}

interface ResponsePaymentTransactionAction {
    type: actionTypes.PAYMENT_TRANSACTION_RESPONSE
    data: IPaymentTransactionModel;
}

interface ResponsePaymentIntentAction {
    type: actionTypes.PAYMENT_INTENT_RESPONSE
    data: IPaymentTransactionModel;
} 

interface UpdatePaymnetTransactionAction {
    type: actionTypes.UPDATE_PAYMENT_TRANSACTION;
    data: IPaymentTransactionModel;
}


type KnownAction =
    DispatchAction |
    NotificationAction;

type DispatchAction = RequestPaymentTransactionAction |
    ResponsePaymentTransactionAction |
    ResponsePaymentIntentAction | 
    UpdatePaymnetTransactionAction;

export const actionCreators = {

    requestPaymentTransaction: (clientId: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch({ type: actionTypes.PAYMENT_TRANSACTION_REQUEST });
        return initializeAxios().get<IPaymentTransactionModel>('api/Payment/GetPaymentTransactionAsync/' + clientId)
            .then(function (response: AxiosResponse<IPaymentTransactionModel>) {
                dispatch({
                    type: actionTypes.PAYMENT_TRANSACTION_RESPONSE, data: response.data
                });
            })
            .catch(function (error: any) {
                dispatch({
                    type: actionTypes.NOTIFICATION, statusMessage: error.response ? error.response.statusText : ErrorMessages.RequestTransactionIdError,
                    statusType: StatusType.Error
                });
                logger.trackWarning(`requestPaymentTransaction failed with error ${error.message} for client: ${clientId}`, { "ClientId": clientId });
            });
    },

    requestPaymentIntent: (clientId: string, amount: number, callback?: () => void): AppThunkAction<KnownAction> => (dispatch, getState) => {
        loader.show();
        return initializeAxios().get<IPaymentTransactionModel>('api/Payment/CreatePaymentIntentAsync/' + amount + '/' + clientId)
            .then(function (response: AxiosResponse<IPaymentTransactionModel>) {
                dispatch({
                    type: actionTypes.PAYMENT_INTENT_RESPONSE, data: response.data
                });
                if (callback) {
                    callback();
                }
                loader.hide();
            })
            .catch(function (error: any) {
                dispatch({
                    type: actionTypes.NOTIFICATION, statusMessage: error.response ? error.response.statusText : ErrorMessages.RequestPaymentTransactionError,
                    statusType: StatusType.Error
                });
                loader.hide();
                logger.trackWarning(`requestPaymentIntent failed with error ${error.message} for client: ${clientId}`, { "ClientId": clientId });
            });
    },
    

    updatePurchaseTransactionStatus: (clientId: string, paymentTransaction: IPaymentTransactionModel, callback?: () => void): AppThunkAction<KnownAction> => (dispatch, getState) => {
        let config: AxiosRequestConfig = { headers: { 'Content-Type': 'application/json;utf-8' } };
        return initializeAxios().post<string>('api/Payment/UpdatePurchaseTransactionStatus/' + clientId, JSON.stringify(paymentTransaction), config)
            .then(function (response: AxiosResponse<string>) {
                dispatch({
                    type: actionTypes.UPDATE_PAYMENT_TRANSACTION, data: { ...paymentTransaction, receiptPath: response.data }
                });
                dispatch({
                    type: actionTypes.NOTIFICATION, statusMessage: PayConstants.paymentSuccess,
                    statusType: StatusType.Success
                });
                if (callback) {
                    callback();
                }
                loader.hide();
            })
            .catch(function (error: any) {
                dispatch({
                    type: actionTypes.NOTIFICATION, statusMessage: error.response ? error.response.statusText : ErrorMessages.SavePaymentTransactionError,
                    statusType: StatusType.Error
                });
                loader.hide();
                logger.trackWarning(`updatePurchaseTransactionStatus failed with error ${error.message} for client: ${clientId}`, { "ClientId": clientId });
            });
    },
    requestStripePublicAPIKey: (clientId: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
		return initializeAxios().get<string>('api/Payment/GetStripePublicAPIKey/' + clientId)
        .then(function (response: AxiosResponse<string>) {					
				window.Variables.publicKey = response.data;
			})
			.catch(function (error: any) {	
				dispatch({
					type: actionTypes.NOTIFICATION, statusMessage: error.response ? error.response.statusText : ErrorMessages.RequestStripePublicAPIKey,
					statusType: StatusType.Error
				});			
			});
	}

}

export const reducer: Reducer<PaymentTransactionState> = (state: PaymentTransactionState = initialPaymentTransactionState, incomingAction: Action) => {
    const action = incomingAction as DispatchAction;
    const currentState = Object.assign({}, state);
    switch (action.type) {
        case actionTypes.PAYMENT_TRANSACTION_REQUEST:
            currentState.data = initialPaymentTransactionModel;
            return currentState;
        case actionTypes.PAYMENT_TRANSACTION_RESPONSE:
            currentState.data.transactionId = action.data.transactionId;
            currentState.data.paymentId = action.data.paymentId;
            currentState.data.status = action.data.status;
            currentState.data.receiptPath = action.data.receiptPath;
            return currentState; 
        case actionTypes.PAYMENT_INTENT_RESPONSE:
            currentState.data.clientSecret = action.data.clientSecret;
            currentState.data.paymentId = action.data.paymentId;
            currentState.data.transactionId = action.data.transactionId;
            return currentState; 
        case actionTypes.UPDATE_PAYMENT_TRANSACTION:
            currentState.data = action.data
            return currentState;
        default:
            return currentState || initialPaymentTransactionState;
    }
};

