import * as React from "react";
import { Modal } from "react-bootstrap";
import { ClientType } from "../../core/common/Enums";
import { IControllerInfo } from "../../core/domain/models/groupedReturns/IControllerInfo";
import { ITaxClient, ITaxpayer } from "../../core/domain/models/ITaxClient";
import { CloseIcon } from "../Common/Icons/SvgIcons";
import "@sssuite-component-ui/react-phone-input-2/lib/style.css";
import PhoneInput, {
  CountryData,
} from "@sssuite-component-ui/react-phone-input-2";
import { ButtonComponent } from "cp-common-ui-components";
import { handleKeyDown } from "../Helper/HelperFunction";

export interface MyAccountProps {
  showState: boolean;
  onHide: () => void;
  onSaveButtonClick: (
    countryCode: string,
    mobileNumber: string,
    spouseEmailAddress?: string,
    spouseCountryCode?: string,
    spouseMobileNumber?: string
  ) => void;
  taxDocument?: any;
  partnerShip?: ITaxClient;
  taxPayer?: ITaxpayer;
  spouse?: ITaxpayer;
  controller?: IControllerInfo;
  loggedInUserClientGuid?: string;
  isTPMfaEnabled: boolean;
  onChangeNumberClick: () => void;
}

interface MyAccountState {
  countryCode: string;
  phoneNumber: string;
  spouseEmailAddress: string;
  spousePhoneNumber: string;
  spouseCountryCode: string;
}

export class MyAccount extends React.Component<MyAccountProps, MyAccountState> {
  constructor(props: any) {
    super(props);
    this.state = this.initializeState();
  }

  private initializeState(): MyAccountState {
    return {
      countryCode: this.props.controller
        ? this.props.controller.countryCode
        : this.props.partnerShip
        ? this.props.partnerShip.countryCode
        : this.props.taxPayer?.countryCode,
      phoneNumber: this.props.controller
        ? this.props.controller.mobileNumber
        : this.props.partnerShip
        ? this.props.partnerShip.mobileNumber
        : this.props.taxPayer?.mobileNumber,
      spouseEmailAddress: this.props.spouse?.email,
      spousePhoneNumber: this.props.spouse?.mobileNumber,
      spouseCountryCode: this.props.spouse?.countryCode,
    };
  }

  private onSpouseMailChange = (e: any) => {
    this.setState({ spouseEmailAddress: e.target.value });
  };

  private handleMobileChanges = (
    fullValue: string,
    countryObject: CountryData
  ) => {
    if (fullValue.length == 0) {
      this.setState({
        phoneNumber: "",
        countryCode: "",
      });
    } else {
      this.setState({
        phoneNumber: fullValue.slice(countryObject.dialCode?.length),
        countryCode: `+${countryObject.dialCode}`,
      });
    }
  };

  private handleSpouseMobileChanges = (
    fullValue: string,
    countryObject: CountryData
  ) => {
    if (fullValue.length == 0) {
      this.setState({
        spousePhoneNumber: "",
        spouseCountryCode: "",
      });
    } else {
      this.setState({
        spousePhoneNumber: fullValue.slice(countryObject.dialCode?.length),
        spouseCountryCode: `+${countryObject.dialCode}`,
      });
    }
  };

  onHide = () => {
    this.setState(this.initializeState(), () => this.props.onHide());
  };

  componentDidUpdate(prevProps: MyAccountProps) {
    if (
      prevProps.taxPayer !== this.props.taxPayer ||
      prevProps.spouse !== this.props.spouse ||
      prevProps.partnerShip !== this.props.partnerShip ||
      prevProps.controller !== this.props.controller
    ) {
      this.setState(this.initializeState());
    }
  }

  getClientType = (): ClientType => {
    let clientType = this.props.taxDocument.clientType;
    if (
      this.props.taxDocument.clientType == ClientType.Controller &&
      this.props.loggedInUserClientGuid
    ) {
      if (
        this.props.loggedInUserClientGuid == this.props.taxPayer?.clientGuid
      ) {
        clientType = ClientType.Taxpayer;
      } else if (
        this.props.loggedInUserClientGuid == this.props.spouse?.clientGuid
      ) {
        clientType = ClientType.Spouse;
      } else if (
        this.props.loggedInUserClientGuid == this.props.partnerShip?.clientGuid
      ) {
        clientType = ClientType.Partnership;
      }
    }
    return clientType;
  };

  public render() {
    return (
      <div>
        <Modal
          show={this.props.showState}
          className="myaccount myaccountModal"
          onHide={this.onHide}
          data-test-auto="689A5D12-FAFD-44B8-8C11-373C29A7C1BF"
        >
          <Modal.Header className="darkBackground">
            <Modal.Title>My Account</Modal.Title>
            <div
              data-test-auto="8cc2eef8-27ec-494a-9518-5d52ce8a0891"
              className="closeIcon"
              onClick={this.props.onHide}
              tabIndex={0}
              onKeyDown={(e: any) => handleKeyDown(e, this.props.onHide)}
            >
              <CloseIcon />
            </div>
          </Modal.Header>
          <Modal.Body>
            <div className="myaccount_body">
              <div className="myaccount_body-headerMainTitle">
                Mobile Number
              </div>

              {this.props.isTPMfaEnabled && (
                <div className="myaccount_body-paragraph">
                  An access code will be sent every time you login.
                </div>
              )}
              <div
                className="myaccount_body-section1"
                data-test-auto="FB4FBF72-5EA4-45F9-A6EA-F3608FDEE04B"
              >
                <label
                  htmlFor="taxpayerMobileNumber"
                  className="myaccount_body-section1--headerText"
                >
                  Enter Mobile Number
                </label>
                <div style={{ width: "225px" }} className="mobileSection">
                  <PhoneInput
                    inputProps={{ id: "taxpayerMobileNumber" }}
                    containerClass={`inputCCMobile`}
                    country={
                      this.state.countryCode ? this.state.countryCode : "us"
                    }
                    autoFormat={true}
                    placeholder="() -"
                    value={`${this.state.countryCode}${this.state.phoneNumber}`}
                    onChange={this.handleMobileChanges}
                  />
                </div>
              </div>
              {this.props.spouse && !this.props.spouse.isDeceased && (
                <div className="myaccount_body-section2">
                  <div
                    className="myaccount_body-section2--name"
                    data-test-auto="B305427D-D94A-47D6-AE22-5B71ACA21532"
                  >
                    <span className="myaccount_body-section2--headerTitle">
                      {this.getClientType() == ClientType.Taxpayer
                        ? "Spouse's Name: "
                        : "Taxpayer's Name: "}
                    </span>
                    <span className="myaccount_body-section2--headerValue">
                      {this.props.spouse.name}
                    </span>
                  </div>

                  <div className="second-signerinfo">
                    <div>
                      <label
                        data-test-auto="DA080EA9-D508-423E-9E48-99F15673059C"
                        className="myaccount_body-section2--headerText"
                        htmlFor="spouseEmailAddress"
                      >
                        Email Address
                      </label>
                      <div>
                        <input
                          id="spouseEmailAddress"
                          type="text"
                          style={{ width: "348px", marginBottom: "10px" }}
                          className="form-control"
                          data-test-auto="CDAB9814-31A5-4543-8B0B-DE8606825993"
                          aria-label="Enter Spouse Email Address"
                          value={this.state.spouseEmailAddress}
                          onChange={(e) => this.onSpouseMailChange(e)}
                        />
                      </div>
                    </div>
                    <div>
                      <label
                        data-test-auto="DA080EA9-D508-423E-9E48-99F15673059C"
                        className="myaccount_body-section2--headerText"
                        htmlFor="spouseMobileNumber"
                      >
                        Mobile
                      </label>
                      <div style={{ width: "225px" }} className="mobileSection">
                        <PhoneInput
                          inputProps={{ id: "spouseMobileNumber" }}
                          containerClass={`inputCCMobile`}
                          country={
                            this.state.spouseCountryCode
                              ? this.state.spouseCountryCode
                              : "us"
                          }
                          autoFormat={true}
                          placeholder="() -"
                          value={`${this.state.spouseCountryCode}${this.state.spousePhoneNumber}`}
                          onChange={this.handleSpouseMobileChanges}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </Modal.Body>
          {
            <Modal.Footer>
              <ButtonComponent
                id="btnHideMyaccountPopUp"
                onClick={this.onHide}
                data-test-auto="CEC8781D-ED44-4B24-AF4A-8005A00B59FC"
                variant={"outline-tertiary"}
                size={"medium"}
                buttonClassName={"button-margin-right"}
              >
                Cancel
              </ButtonComponent>
              <ButtonComponent
                onClick={() => {
                  this.props.onSaveButtonClick(
                    this.state.countryCode ?? "",
                    this.state.phoneNumber ?? "",
                    this.state.spouseEmailAddress ?? "",
                    this.state.spouseCountryCode ?? "",
                    this.state.spousePhoneNumber ?? ""
                  );
                }}
                data-test-auto="56A7B8B9-02D6-498E-8953-F7F56C4478AE"
                variant={"primary"}
                size={"medium"}
              >
                Save
              </ButtonComponent>
            </Modal.Footer>
          }
        </Modal>
      </div>
    );
  }
}

export default MyAccount;
