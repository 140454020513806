import * as React from "react";
import { DateType } from "../../core/domain/viewModels/ICommon";
import YearMonthDayDropdown from "../Common/YearMonthDayDropdown";
import { handleKeyDown } from "../Helper/HelperFunction";

export type ConsentProps = {
    dateOfBirth: Date;
    consentChecked: boolean;
    handleConsent(isChecked: boolean): void;
    handleDOBChange(dob: string, type: DateType): void;
};

export interface IConsentState {}

export class Consent extends React.Component<ConsentProps, IConsentState> {
    constructor(props: any) {
        super(props);

        this.state = {};
    }

    onConsent = (e: any) => {
        this.props.handleConsent(e.target.checked);
    };

    onDobChange = (val: string, type: DateType) => {
        this.props.handleDOBChange(val, type);
    };

    public render() {
        return (
            <div className="esign-consent" data-test-auto="4FFB32DC-4674-4682-A047-FF94DAA7E259">
                <div className="content-paragraph">
                    Before signing, verify your identity by answering a few security questions. Verify or enter the
                    requested information and click "Next" to proceed.
                </div>
                <div className="date-of-birth">
                    {
                        <>
                            <div className="date-of-birth-label">Date of Birth</div>
                            <YearMonthDayDropdown
                                id="ddlYear"
                                controlValue={this.props.dateOfBirth}
                                onControlValueChange={this.onDobChange}
                            ></YearMonthDayDropdown>
                        </>
                    }
                </div>
                <div className="checkbox">
                    <ul className='ugCheckArea'>
                        <li className='eachUGCheck'>
                            <input
                                type="checkbox"
                                className='ugCheckbox'
                                data-test-auto="2BA6893F-0D36-4F45-8185-47B246FAB6E0"
                                checked={this.props.consentChecked}
                                onChange={this.onConsent}
                                onKeyDown={(e: any) => handleKeyDown(e, () => {
                                    if (this.props.consentChecked) {
                                        this.props.handleConsent(false);
                                    } else {
                                        this.props.handleConsent(true);
                                    }
                                })}
                                data-name="esign-consent"
                                id="esign-consent"
                            />
                            <label
                                data-test-auto="2BA6893F-0D36-4F45-8185-47B246FAB6E0"
                                htmlFor="esign-consent"
                                data-name="esign-consent"
                                id="esign-consent"
                            >
                                I have reviewed my tax returns and consent to e-Sign
                            </label>
                        </li>
                    </ul>
                </div>
            </div>
        );
    }
}
