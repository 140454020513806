import { ITaxingAuthority } from "../models/ITaxingAuthority";

export interface ICommonDataViewModel {
    taxingAuthorities: ITaxingAuthority[];
    iceServers: RTCIceServer[];
    webRTCSignalRHubBaseURL: string;
}

export const initialCommonData: ICommonDataViewModel = {
    taxingAuthorities: [],
    iceServers: [],
    webRTCSignalRHubBaseURL: "",
}