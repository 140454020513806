import { SignatureMode } from "awesome-pdf-viewer/dist/Controls/ControlBase";
import { SignatureControlRole } from "../IGroup";

export interface IControl {
    id: string;
    top: number;
    left: number;
    tooltip: string;
    required: boolean;
    customData: ICustomData[];
    data?: IControlData;
    disabled?: boolean;
    isLandScapePage?: boolean;
}



export interface ISignatureControl extends IControl {

}

export interface ITextBoxControl extends IControl {
    readOnly: boolean;
}

export interface ILabelControl extends IControl {

}



export interface IInitialControl extends ISignatureControl {


}

export interface ITitleControl extends ITextBoxControl {


}

export interface ICompanyControl extends ITextBoxControl {


}

export interface ICustomTextControl extends ITextBoxControl {


}

export interface IDateControl extends ILabelControl {


}

export interface IChoosableControl extends IControl {
    width: number;
    height: number;
    items: ChoosableControlData[];
}

export interface IChoosableControlResult extends IControlData {
    checked: boolean;
    id: string;
}

export interface ICustomData {
    role?: SignatureControlRole;
}



export interface IControlData {


}

export interface ISignatureData extends IControlData {

    name: string;
    signature: string;
    signatureMode?: SignatureMode;
}

export interface ITextData extends IControlData {
    text: string;
}



export class SignatureControl implements ISignatureControl {

    id: string;
    top: number;
    left: number;
    tooltip: string;
    required: boolean;
    customData: ICustomData[];
    data?: IControlData;
    disabled?: boolean;
    isLandScapePage?: boolean;

    constructor(id: string,
        top: number,
        left: number,
        tooltip: string,
        required: boolean,
        customData: ICustomData[],
        data?: IControlData,
        disabled?: boolean,
        isLandScapePage?: boolean) {

        this.id = id;
        this.top = top;
        this.left = left;
        this.tooltip = tooltip;
        this.required = required;
        this.customData = customData;
        this.data = data;
        this.disabled = disabled;
        this.isLandScapePage = isLandScapePage;
    }

    static create(id: string,
        top: number,
        left: number,
        tooltip: string,
        required: boolean,
        customData: ICustomData[],
        data?: IControlData,
        disabled?: boolean,
        isLandScapePage?: boolean): ISignatureControl {

        return new SignatureControl(id,
            top,
            left,
            tooltip,
            required,
            customData,
            data,
            disabled,
            isLandScapePage);

    }
}

export class InitialControl implements ISignatureControl {

    id: string;
    top: number;
    left: number;
    tooltip: string;
    required: boolean;
    customData: ICustomData[];
    data?: IControlData;
    disabled?: boolean;
    isLandScapePage?: boolean;

    constructor(id: string,
        top: number,
        left: number,
        tooltip: string,
        required: boolean,
        customData: ICustomData[],
        data?: IControlData,
        disabled?: boolean,
        isLandScapePage?: boolean) {

        this.id = id;
        this.top = top;
        this.left = left;
        this.tooltip = tooltip;
        this.required = required;
        this.customData = customData;
        this.data = data;
        this.disabled = disabled;
        this.isLandScapePage = isLandScapePage;
    }

    static create(id: string,
        top: number,
        left: number,
        tooltip: string,
        required: boolean,
        customData: ICustomData[],
        data?: IControlData,
        disabled?: boolean,
        isLandScapePage?: boolean): ISignatureControl {

        return new InitialControl(id,
            top,
            left,
            tooltip,
            required,
            customData,
            data,
            disabled,
            isLandScapePage);

    }
}

export class NameAndTitleControl implements ITextBoxControl {

    readOnly: boolean;
    id: string;
    top: number;
    left: number;
    tooltip: string;
    required: boolean;
    customData: ICustomData[];
    data?: IControlData;
    disabled?: boolean;
    isLandScapePage?: boolean;

    constructor(id: string,
        top: number,
        left: number,
        tooltip: string,
        required: boolean,
        readOnly: boolean,
        customData: ICustomData[],
        data?: IControlData,
        disabled?: boolean,
        isLandScapePage?: boolean) {

        this.id = id;
        this.top = top;
        this.left = left;
        this.tooltip = tooltip;
        this.required = required;
        this.readOnly = readOnly;
        this.customData = customData;
        this.data = data;
        this.disabled = disabled;
        this.isLandScapePage = isLandScapePage;
    }

    static create(id: string,
        top: number,
        left: number,
        tooltip: string,
        required: boolean,
        readOnly: boolean,
        customData: ICustomData[],
        data?: IControlData,
        disabled?: boolean,
        isLandScapePage?: boolean): ITextBoxControl {

        return new NameAndTitleControl(id,
            top,
            left,
            tooltip,
            required,
            readOnly,
            customData,
            data,
            disabled,
            isLandScapePage);

    }

}

export class CompanyControl implements ITextBoxControl {

    readOnly: boolean;
    id: string;
    top: number;
    left: number;
    tooltip: string;
    required: boolean;
    customData: ICustomData[];
    data?: IControlData;
    disabled?: boolean;
    isLandScapePage?: boolean;

    constructor(id: string,
        top: number,
        left: number,
        tooltip: string,
        required: boolean,
        readOnly: boolean,
        customData: ICustomData[],
        data?: IControlData,
        disabled?: boolean,
        isLandScapePage?: boolean) {

        this.id = id;
        this.top = top;
        this.left = left;
        this.tooltip = tooltip;
        this.required = required;
        this.readOnly = readOnly;
        this.customData = customData;
        this.data = data;
        this.disabled = disabled;
        this.isLandScapePage = isLandScapePage;
    }

    static create(id: string,
        top: number,
        left: number,
        tooltip: string,
        required: boolean,
        readOnly: boolean,
        customData: ICustomData[],
        data?: IControlData,
        disabled?: boolean,
        isLandScapePage?: boolean): ITextBoxControl {

        return new CompanyControl(id,
            top,
            left,
            tooltip,
            required,
            readOnly,
            customData,
            data,
            disabled,
            isLandScapePage);

    }

}

export class CustomTextControl implements ITextBoxControl {

    readOnly: boolean;
    id: string;
    top: number;
    left: number;
    tooltip: string;
    required: boolean;
    customData: ICustomData[];
    data?: IControlData;
    disabled?: boolean;
    isLandScapePage?: boolean;

    constructor(id: string,
        top: number,
        left: number,
        tooltip: string,
        required: boolean,
        readOnly: boolean,
        customData: ICustomData[],
        data?: IControlData,
        disabled?: boolean,
        isLandScapePage?: boolean) {

        this.id = id;
        this.top = top;
        this.left = left;
        this.tooltip = tooltip;
        this.required = required;
        this.readOnly = readOnly;
        this.customData = customData;
        this.data = data;
        this.disabled = disabled;
        this.isLandScapePage = isLandScapePage;
    }

    static create(id: string,
        top: number,
        left: number,
        tooltip: string,
        required: boolean,
        readOnly: boolean,
        customData: ICustomData[],
        data?: IControlData,
        disabled?: boolean,
        isLandScapePage?: boolean): ITextBoxControl {

        return new CustomTextControl(id,
            top,
            left,
            tooltip,
            required,
            readOnly,
            customData,
            data,
            disabled,
            isLandScapePage);

    }
}

export class DateControl implements ILabelControl {

    id: string;
    top: number;
    left: number;
    tooltip: string;
    required: boolean;
    customData: ICustomData[];
    data?: IControlData;
    disabled?: boolean;
    isLandScapePage?: boolean;

    constructor(id: string,
        top: number,
        left: number,
        tooltip: string,
        required: boolean,
        customData: ICustomData[],
        data?: IControlData,
        disabled?: boolean,
        isLandScapePage?: boolean) {

        this.id = id;
        this.top = top;
        this.left = left;
        this.tooltip = tooltip;
        this.required = required;
        this.customData = customData;
        this.data = data;
        this.disabled = disabled;
        this.isLandScapePage = isLandScapePage;

    }

    static create(id: string,
        top: number,
        left: number,
        tooltip: string,
        required: boolean,
        customData: ICustomData[],
        data?: IControlData,
        disabled?: boolean,
        isLandScapePage?: boolean): ILabelControl {

        return new DateControl(id,
            top,
            left,
            tooltip,
            required,
            customData,
            data,
            disabled,
            isLandScapePage);

    }
}

export class PrintNameControl implements ITextBoxControl {

    readOnly: boolean;
    id: string;
    top: number;
    left: number;
    tooltip: string;
    required: boolean;
    customData: ICustomData[];
    data?: IControlData;
    disabled?: boolean;
    isLandScapePage?: boolean;

    constructor(id: string,
        top: number,
        left: number,
        tooltip: string,
        required: boolean,
        readOnly: boolean,
        customData: ICustomData[],
        data?: IControlData,
        disabled?: boolean,
        isLandScapePage?: boolean) {

        this.id = id;
        this.top = top;
        this.left = left;
        this.tooltip = tooltip;
        this.required = required;
        this.readOnly = readOnly;
        this.customData = customData;
        this.data = data;
        this.disabled = disabled;
        this.isLandScapePage = isLandScapePage;
    }

    static create(id: string,
        top: number,
        left: number,
        tooltip: string,
        required: boolean,
        readOnly: boolean,
        customData: ICustomData[],
        data?: IControlData,
        disabled?: boolean,
        isLandScapePage?: boolean): ITextBoxControl {

        return new PrintNameControl(id,
            top,
            left,
            tooltip,
            required,
            readOnly,
            customData,
            data,
            disabled,
            isLandScapePage);

    }
}

export class TitleControl implements ITextBoxControl {

    readOnly: boolean;
    id: string;
    top: number;
    left: number;
    tooltip: string;
    required: boolean;
    customData: ICustomData[];
    data?: IControlData;
    disabled?: boolean;
    isLandScapePage?: boolean;

    constructor(id: string,
        top: number,
        left: number,
        tooltip: string,
        required: boolean,
        readOnly: boolean,
        customData: ICustomData[],
        data?: IControlData,
        disabled?: boolean,
        isLandScapePage?: boolean) {

        this.id = id;
        this.top = top;
        this.left = left;
        this.tooltip = tooltip;
        this.required = required;
        this.readOnly = readOnly;
        this.customData = customData;
        this.data = data;
        this.disabled = disabled;
        this.isLandScapePage = isLandScapePage;
    }

    static create(id: string,
        top: number,
        left: number,
        tooltip: string,
        required: boolean,
        readOnly: boolean,
        customData: ICustomData[],
        data?: IControlData,
        disabled?: boolean,
        isLandScapePage?: boolean): ITextBoxControl {

        return new TitleControl(id,
            top,
            left,
            tooltip,
            required,
            readOnly,
            customData,
            data,
            disabled,
            isLandScapePage);

    }

}

export class SignatureData implements ISignatureData {

    name: string;
    signature: string;
    signatureMode?: SignatureMode;

    constructor(name: string, sign: string, signmode: SignatureMode) {

        this.name = name;
        this.signature = sign;
        this.signatureMode = signmode;
    }

    static create(name: string, sign: string, signmode: SignatureMode): ISignatureData {
        return new SignatureData(name, sign, signmode);
    }
}

export class TextData implements ITextData {

    text: string;

    constructor(text: string) {
        this.text = text;
    }

    static create(text: string): ITextData {
        return new TextData(text);
    }
}

export interface ISignerInfo extends ICustomData {
    email: string;
}

export interface IControlRole extends ICustomData {
    role: SignatureControlRole;
}

export class SignerInfo implements ISignerInfo {
    email: string;

    constructor(email: string) {
        this.email = email;
    }

    static create(email: string): ISignerInfo {
        return new SignerInfo(email);
    }
}

export class ControlRole implements IControlRole {
    role: SignatureControlRole;

    constructor(role: SignatureControlRole) {
        this.role = role;
    }

    static create(role: SignatureControlRole): IControlRole {
        return new ControlRole(role);
    }
}

export class CheckBoxControl implements IChoosableControl {

    constructor(id: string,
        top: number,
        left: number,
        width: number,
        height: number,
        tooltip: string,
        required: boolean,
        items: ChoosableControlData[],
        customData: ICustomData[],
        data?: IControlData,
        disabled?: boolean) {

        this.id = id;
        this.top = top;
        this.left = left;
        this.tooltip = tooltip;
        this.required = required;
        this.customData = customData;
        this.data = data;
        this.disabled = disabled;
        this.width = width;
        this.height = height;
        this.items = items;
    }
    items: ChoosableControlData[];
    width: number;
    height: number;
    id: string;
    top: number;
    left: number;
    tooltip: string;
    required: boolean;
    customData: ICustomData[];
    data?: IControlData | undefined;
    disabled?: boolean | undefined;

    static create(id: string,
        top: number,
        left: number,
        width: number,
        height: number,
        tooltip: string,
        required: boolean,
        items: ChoosableControlData[],
        customData: ICustomData[],
        data?: IControlData,
        disabled?: boolean): CheckBoxControl {

        return new CheckBoxControl(id,
            top,
            left,
            width,
            height,
            tooltip,
            required,
            items,
            customData,
            data,
            disabled);
    }
}

export class RadioButtonControl implements IChoosableControl {

    constructor(id: string,
        top: number,
        left: number,
        width: number,
        height: number,
        tooltip: string,
        required: boolean,
        items: ChoosableControlData[],
        customData: ICustomData[],
        data?: IControlData,
        disabled?: boolean) {

        this.id = id;
        this.top = top;
        this.left = left;
        this.width = width;
        this.height = height;
        this.tooltip = tooltip;
        this.required = required;
        this.customData = customData;
        this.data = data;
        this.disabled = disabled;
        this.items = items;
    }
    width: number;
    height: number;
    items: ChoosableControlData[];
    id: string;
    top: number;
    left: number;
    tooltip: string;
    required: boolean;
    customData: ICustomData[];
    data?: IControlData | undefined;
    disabled?: boolean | undefined;

    static create(id: string,
        top: number,
        left: number,
        width: number,
        height: number,
        tooltip: string,
        required: boolean,
        items: ChoosableControlData[],
        customData: ICustomData[],
        data?: IControlData,
        disabled?: boolean): RadioButtonControl {

        return new RadioButtonControl(id,
            top,
            left,
            width,
            height,
            tooltip,
            required,
            items,
            customData,
            data,
            disabled);
    }
}

export class ChoosableControlData {

    id: string;
    name: string;
    top: number;
    left: number;
    width: number;
    height: number;
    value: any;

    constructor(id: string,
        name: string,
        top: number,
        left: number,
        width: number,
        height: number,
        value: any) {
        this.id = id;
        this.name = name;
        this.top = top;
        this.left = left;
        this.width = width;
        this.height = height;
        this.value = value;
    }

    public static create(
        id: string,
        name: string,
        top: number,
        left: number,
        width: number,
        height: number,
        value: any
    ) {

        var data = new ChoosableControlData(id, name, top, left, width, height, value);
        return data;
    }

    public static createNullObject() {

        var data = new ChoosableControlData('', '', 0, 0, 0, 0, { checked: false, value: '' });
        return data;
    }
}

export class ChoosableControlResult implements IChoosableControlResult {
    id: string;
    checked: boolean;

    constructor(id: string,
        checked: boolean
    ) {
        this.id = id;
        this.checked = checked;
    }

    public static create(
        id: string,
        checked: boolean
    ) {

        var data = new ChoosableControlResult(id, checked);
        return data;
    }
}
