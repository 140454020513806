import { ApplicationState } from "../../store/index";
import { actionCreators as SummaryStore } from "../../store/SummaryStore";
import { actionCreators as HelperStore } from "../../store/Common/HelperStore";
import { actionCreators as EsignStore } from "../../store/Common/EsignStore";
import { TaxpayerSignFlow } from "./TaxpayerSignFlow";
import { actionCreators as PreparerMessageStore } from "../../store/PreparerMessageStore";
import { connect } from "react-redux";
import { createLoadingSelector } from "../../store/selectors";
import { actionCreators as DocumentReviewStore } from "../../store/ReviewStore";
import { actionCreators as TaxDocumentStore } from "../../store/Common/TaxDocumentStore";
import { actionCreators as ManualSignStore } from "../../store/Sign/ManualSignStore";
import { actionCreators as CompanyStore } from "../../store/Common/Company/CompanyStore";
import { IDocument } from "../../core/domain/models/esign/Document";
import { actionCreators as PayStore } from "../../store/PayStore";
import { IPaymentVoucherNotificationSettings } from "../../core/domain/models/IDocumentSettings";
import {
  ClientType,
  EngagementTypeS,
  SignatureStatus,
} from "../../core/common/Enums";
import { actionCreators as PaperFileReturnStore } from "../../store/PaperFileReturn/PaperFileReturnStore";
import { actionCreators as K1DistributeStore } from "../../store/K1/K1DistributeStore";
import {
  IK1ShareHolderActivity,
  IK1ShareHolderDetails,
} from "../../core/domain/models/IK1Distribute";
import { IPaperFileReturnDetails } from "../../core/domain/models/IPaperFileReturn";
import { actionCreators as SignProcessStore } from "../../store/SignProcess/SignProcessStore";
import { actionCreators as TutorialStore } from "../../store/Tutorial/TutorialStore";
import { actionCreators as CCRecipientStore } from "../../store/CCRecipientStore";
import { DocumentStatus } from "../../core/common/Enums";
import {
  ICCRecipientModel,
  CCRecipientDocumentGroups,
} from "../../core/domain/models/ICCRecipientModel";
import { IClientProcessViewModel } from "../../core/domain/viewModels/IClientProcessViewModel";
import { actionCreators as PaymentStore } from "../../store/PaymentStore";
import { IPaymentTransactionModel } from "../../core/domain/models/IPaymentTransactionModel";
import { actionCreators as DownloadableDocumentsStore } from "../../store/DownloadableDocumentsStore";
import { IVoucher } from "../../core/domain/models/IGroup";
import { actionCreators as DelegateeSignerStore } from "../../store/DelegateeSignerStore";
import { IDelegateeSignerDetails } from "../../core/domain/models/IDelegateeSignerModel";
import { actionCreators as DocumentStatusStore } from "../../store/Common/DocumentStatusStore";
import { actionCreators as TrialK1DistributeStore } from "../../store/K1/TrialK1DistributeStore";

const loadingSelector = createLoadingSelector([
  "HEADERINFO",
  "SUMMARY_DETAILS",
  "TAXING_AUTHORITY",
  "DOCUMENT_REVIEW_MODEL",
  "DOCUMENT_SETTINGS",
]);

function mapStateToProps(state: ApplicationState) {
  return {
    summaryData: state.summaryData,
    esignData: state.esignData.data,
    signerData: state.esignData.signerDetails,
    manualSignData: state.manualSignData.data,
    companyData: state.companyData.data,
    kbaData: state.kbaData,
    signatureControlsData: state.esignData.controlsData,
    loading: loadingSelector(state),
    commonData: state.commonData,
    signatureSettingModel: state.signatureSettingData,
    documentSettings: state.documentSettings,
    documentReviewModel: state.documentReviewModel,
    headInfoViewModel: state.headerInfo,
    taxReturn: state.taxReturn.taxDocument,
    isKBAPassed: state.taxReturn.isKBAPassed,
    k1Data: state.k1Data.data,
    isK1Loading: state.k1Data.isLoading,
    paperFileReturnData: state.paperFileReturnData.data,
    signProcessData: state.signProcessData.data,
    payScreenData: state.payScreenData,
    signProcessInfo: state.signProcessData.clientprocessmodel.currentstep,
    loggedInUserInfo: state.headerInfo.loggedInUserInfo,
    tutorialInfo: state.tutorialInfo,
    additionalEsignDocuments:
      state.downloadableDocuments.additionalEsignDocuments,
    ccRecipientDownloadableDocuments: state.ccRecipientDownloadableDocuments,
    changedSignBehalfSpouse: state.esignData.changedSignBehalfSpouse,
    paymentData: state.paymentData.data,
    DocumentStatusState: state.DocumentStatusState,
  };
}
//temperroy fix for webpack failure need to invetigate further when we have time
const mapDispatchToProps = (dispatch: any) => {
  return {
    requestSummaryDetails: (clientId: string) =>
      dispatch(SummaryStore.requestSummaryDetails(clientId)),
    requestAllTaxingAuthorities: (id: string, callback?: () => void) =>
      dispatch(HelperStore.requestAllTaxingAuthorities(id, callback)),
    clientAuthentication: (clientGuid: string, failureCallback: () => void) =>
      dispatch(EsignStore.clientAuthentication(clientGuid, failureCallback)),
    clientValidation: (
      clientGuid: string,
      data: any,
      successCallback: (isNextQuestionSet?: boolean) => void,
      failureCallback: () => void
    ) =>
      dispatch(
        EsignStore.clientValidation(
          clientGuid,
          data,
          successCallback,
          failureCallback
        )
      ),
    requestSignatureControls: (clientGuid: string) =>
      dispatch(EsignStore.requestSignatureControls(clientGuid)),
    requestPreviewSignatureControls: (clientGuid: string) =>
      dispatch(EsignStore.requestPreviewSignatureControls(clientGuid)),
    requestSignBehalfSpouseSignatureControls: (clientGuid: string) =>
      dispatch(EsignStore.requestSignBehalfSpouseSignatureControls(clientGuid)),
    requestSignBehalfSpousePreviewSignatureControls: (clientGuid: string) =>
      dispatch(
        EsignStore.requestSignBehalfSpousePreviewSignatureControls(clientGuid)
      ),
    updateDOB: (
      clientGuid: string,
      id: string,
      dob: string,
      step: boolean,
      callback: (step: boolean) => void
    ) => dispatch(EsignStore.updateDOB(clientGuid, id, dob, step, callback)),
    updateDocumentSignatureSettingModel: (
      clientId: string,
      signatureType: number,
      callback: any,
      failureCallback?: () => void
    ) =>
      dispatch(
        EsignStore.updateDocumentSignatureSettingModel(
          clientId,
          signatureType,
          callback,
          failureCallback
        )
      ),
    updateSignatureControlsData: (data: any) =>
      dispatch(EsignStore.updateSignatureControlsData(data)),
    updateSpouseMail: (
      clientGuid: string,
      spouseGuid: string,
      id: string,
      mail: string,
      type: ClientType,
      step?: boolean,
      callback?: (step: boolean) => void
    ) =>
      dispatch(
        EsignStore.updateSpouseMail(
          clientGuid,
          spouseGuid,
          id,
          mail,
          type,
          step,
          callback
        )
      ),
    downloadAllAttachments: (clientId: string, attachments: any) =>
      dispatch(
        DocumentReviewStore.downloadAllAttachments(clientId, attachments)
      ),
    downloadIndividualAttachment: (clientId: string, attachment: any) =>
      dispatch(
        DocumentReviewStore.downloadIndividualAttachment(clientId, attachment)
      ),
    requestDocumentReviewModel: (clientId: string, callback?: () => void) =>
      dispatch(
        DocumentReviewStore.requestDocumentReviewModel(clientId, callback)
      ),
    requestDocumentSettings: (clientId: string, callback?: () => void) =>
      dispatch(
        PreparerMessageStore.requestDocumentSettings(clientId, callback)
      ),
    requestKBAStatus: (clientGuid: string, successCallback?: () => void) =>
      dispatch(TaxDocumentStore.requestKBAStatus(clientGuid, successCallback)),
    requestTaxDocument: (clientGuid: string, callback?: (data?: any) => void) =>
      dispatch(TaxDocumentStore.requestTaxDocument(clientGuid, callback)),
    requestSigners: (clientGuid: string, callback?: () => void) =>
      dispatch(EsignStore.requestSigners(clientGuid, callback)),
    addSignedDocument: (
      clientGuid: string,
      signedDocument: any,
      successCallback?: () => void,
      failureCallback?: () => void
    ) =>
      dispatch(
        ManualSignStore.addSignedDocument(
          clientGuid,
          signedDocument,
          successCallback,
          failureCallback
        )
      ),
    completeManualSign: (
      clientGuid: string,
      successCallback?: () => void,
      failureCallback?: () => void
    ) =>
      dispatch(
        ManualSignStore.completeManualSign(
          clientGuid,
          successCallback,
          failureCallback
        )
      ),
    deleteSignedDocument: (
      clientGuid: string,
      id: number,
      successCallback?: () => void,
      failureCallback?: () => void
    ) =>
      dispatch(
        ManualSignStore.deleteSignedDocument(
          clientGuid,
          id,
          successCallback,
          failureCallback
        )
      ),
    requestSignedDocuments: (
      clientGuid: string,
      forceRefresh?: boolean,
      callback?: () => void
    ) =>
      dispatch(
        ManualSignStore.requestSignedDocuments(
          clientGuid,
          forceRefresh,
          callback
        )
      ),
    requestCompanyDetails: (clientGuid: string, forceRefresh?: boolean) =>
      dispatch(CompanyStore.requestCompanyDetails(clientGuid, forceRefresh)),
    requestK1ShareHolderDetails: (
      clientGuid: string,
      pageNo: number,
      pageSize: number,
      sortBy: string,
      sortOrder: string,
      searchText: string,
      successCallback?: () => void
    ) =>
      dispatch(
        K1DistributeStore.requestK1ShareHolderDetails(
          clientGuid,
          pageNo,
          pageSize,
          sortBy,
          sortOrder,
          searchText,
          successCallback
        )
      ),
    requestK1ShareHolderActivity: (
      clientGuid: string,
      documentId: number,
      shareholderId: number,
      successCallback?: (
        k1ShareHolderActivity: IK1ShareHolderActivity[]
      ) => void
    ) =>
      dispatch(
        K1DistributeStore.requestK1ShareHolderActivity(
          clientGuid,
          documentId,
          shareholderId,
          successCallback
        )
      ),
    updateK1ShareHolderEmail: (
      clientGuid: string,
      k1Partner: IK1ShareHolderDetails,
      successCallback?: () => void
    ) =>
      dispatch(
        K1DistributeStore.updateK1ShareHolderEmail(
          clientGuid,
          k1Partner,
          successCallback
        )
      ),

    updateK1ShareHolderDetail: (
      clientGuid: string,
      k1Partner: IK1ShareHolderDetails,
      isEmailChange: boolean,
      isPreview: boolean,
      callBack?: () => void
    ) =>
      dispatch(
        K1DistributeStore.updateK1ShareHolderDetail(
          clientGuid,
          k1Partner,
          isEmailChange,
          isPreview,
          callBack
        )
      ),

    downloadK1DocumentAsync: (
      clientId: string,
      engagementTpe: EngagementTypeS,
      callback?: () => void
    ) =>
      dispatch(
        K1DistributeStore.downloadK1DocumentAsync(
          clientId,
          engagementTpe,
          callback
        )
      ),
    downloadIndividualK1DocumentAsync: (
      clientId: string,
      shareHolder: IK1ShareHolderDetails,
      engagementTpe: EngagementTypeS
    ) =>
      dispatch(
        K1DistributeStore.downloadIndividualK1DocumentAsync(
          clientId,
          shareHolder,
          engagementTpe
        )
      ),
    sendMailToShareHolder: (
      clientId: string,
      partners: IK1ShareHolderDetails[],
      callback: () => void
    ) =>
      dispatch(
        K1DistributeStore.sendMailToShareHolder(clientId, partners, callback)
      ),
    downloadSelectedK1DocumentAsync: (
      clientId: string,
      shareHolders: IK1ShareHolderDetails[],
      engagementTpe: EngagementTypeS,
      callback?: () => void
    ) =>
      dispatch(
        K1DistributeStore.downloadSelectedK1DocumentAsync(
          clientId,
          shareHolders,
          engagementTpe,
          callback
        )
      ),
    sign: (
      clientId: string,
      documentData: IDocument[],
      callback: (status: boolean) => void
    ) => dispatch(EsignStore.sign(clientId, documentData, callback)),
    signOnBehalfSpouse: (
      clientId: string,
      documentData: IDocument[],
      taxpayerGuid: string,
      spouseGuid: string,
      callback: (status: boolean) => void
    ) =>
      dispatch(
        EsignStore.signOnBehalfSpouse(
          clientId,
          documentData,
          taxpayerGuid,
          spouseGuid,
          callback
        )
      ),
    requestLastVisitedStep: (
      clientGuid: string,
      successCallback?: (lastVisitedStep: number) => void
    ) =>
      dispatch(
        SignProcessStore.requestLastVisitedStep(clientGuid, successCallback)
      ),
    updateLastVisitedStep: (
      clientGuid: string,
      step: number,
      successCallback?: () => void
    ) =>
      dispatch(
        SignProcessStore.updateLastVisitedStep(
          clientGuid,
          step,
          successCallback
        )
      ),

    requestPayScreenDetails: (clientId: string) =>
      dispatch(PayStore.requestPayScreenDetails(clientId)),
    downloadAllVouchers: (clientId: string) =>
      dispatch(PayStore.downloadAllVouchers(clientId)),
    downloadFilingInstructions: (clientId: string) =>
      dispatch(PayStore.downloadFilingInstructions(clientId)),
    downloadTaxInvoice: (clientId: string) =>
      dispatch(PayStore.downloadTaxInvoice(clientId)),
    updateVoucherNotificationSettings: (
      clientId: string,
      setting: IPaymentVoucherNotificationSettings,
      callback: () => void
    ) =>
      dispatch(
        PayStore.updateVoucherNotificationSettings(clientId, setting, callback)
      ),
    downloadSingleVoucher: (
      clientId: string,
      fileName: string,
      pageNo: string,
      bookMarks: string
    ) =>
      dispatch(
        PayStore.downloadSingleVoucher(clientId, fileName, pageNo, bookMarks)
      ),
    downloadEfileForms: (clientGuid: string, successCallback?: () => void) =>
      dispatch(ManualSignStore.downloadEfileForms(clientGuid, successCallback)),
    addVoucherPayemnt: (
      clientId: string,
      voucher: IVoucher,
      Callback?: () => void
    ) => dispatch(PayStore.addVoucherPayemnt(clientId, voucher, Callback)),
    updateVoucherPayemnt: (
      clientId: string,
      voucher: IVoucher,
      Callback?: () => void
    ) => dispatch(PayStore.updateVoucherPayemnt(clientId, voucher, Callback)),
    requestCurrentStepAndUserRole: (
      clientGuid: string,
      successCallback?: (clientProcessState: IClientProcessViewModel) => void
    ) =>
      dispatch(
        SignProcessStore.requestCurrentStepAndUserRole(
          clientGuid,
          successCallback
        )
      ),

    requestTutorialInfo: (clientId: string) =>
      dispatch(TutorialStore.requestTutorialInfo(clientId)),
    hideReviewTutorial: (clientId: string, doNotShowNextTime: boolean) =>
      dispatch(TutorialStore.hideReviewTutorial(clientId, doNotShowNextTime)),
    hideSummaryTutorial: (clientId: string, doNotShowNextTime: boolean) =>
      dispatch(TutorialStore.hideSummaryTutorial(clientId, doNotShowNextTime)),

    requestAdditionalEsignDocument: (clientId: string, callback?: () => void) =>
      dispatch(
        DownloadableDocumentsStore.requestAdditionalEsignDocument(
          clientId,
          callback
        )
      ),
    downloadAllAdditionEsignDocuments: (clientId: string, fileName: string) =>
      dispatch(
        DownloadableDocumentsStore.downloadAllAdditionEsignDocuments(
          clientId,
          fileName
        )
      ),
    requestMyDownload: (clientGuid: string) =>
      dispatch(DownloadableDocumentsStore.requestMyDownload(clientGuid)),

    requestCCRecipientDownloadableDocuments: (
      clientGuid: string,
      documentStatus: DocumentStatus,
      callback?: () => void
    ) =>
      dispatch(
        CCRecipientStore.requestCCRecipientDownloadableDocuments(
          clientGuid,
          documentStatus,
          callback
        )
      ),

    forwardDocumentsToRecipients: (
      clientId: string,
      recipientInfo: ICCRecipientModel,
      callback: () => void
    ) =>
      dispatch(
        CCRecipientStore.forwardDocumentsToRecipients(
          clientId,
          recipientInfo,
          callback
        )
      ),

    requestPaymentTransaction: (clientId: string) =>
      dispatch(PaymentStore.requestPaymentTransaction(clientId)),
    requestStripePublicAPIKey: (clientId: string) =>
      dispatch(PaymentStore.requestStripePublicAPIKey(clientId)),
    requestPaymentIntent: (
      clientId: string,
      amount: number,
      callbak?: () => void
    ) => dispatch(PaymentStore.requestPaymentIntent(clientId, amount, callbak)),
    updatePurchaseTransactionStatus: (
      clientId: string,
      paymentTransaction: IPaymentTransactionModel,
      callback?: () => void
    ) =>
      dispatch(
        PaymentStore.updatePurchaseTransactionStatus(
          clientId,
          paymentTransaction,
          callback
        )
      ),

    downloadAllDocuments: (clientId: string, fileName: string) =>
      dispatch(DocumentReviewStore.downloadAllDocuments(clientId, fileName)),

    downloadSelectedDocuments: (clientId: string, documentGroups: number[]) =>
      dispatch(
        DocumentReviewStore.downloadSelectedDocuments(clientId, documentGroups)
      ),
    updateSpouseMobileNumber: (
      clientGuid: string,
      id: string,
      newMobileNumber: string,
      newCountryCode: string,
      ssn: string,
      type: ClientType,
      step?: boolean,
      callback?: (step: boolean) => void
    ) =>
      dispatch(
        EsignStore.updateSpouseMobileNumber(
          clientGuid,
          id,
          newMobileNumber,
          newCountryCode,
          ssn,
          type,
          step,
          callback
        )
      ),
    assignToDelegatee: (
      clientId: string,
      delegatee: IDelegateeSignerDetails,
      callback: () => void
    ) =>
      dispatch(
        DelegateeSignerStore.assignToDelegatee(clientId, delegatee, callback)
      ),

    updateSignBehalfSpouseState: () =>
      dispatch(EsignStore.updateSignBehalfSpouseState()),
    clearPaymentDetails: (
      clientId: string,
      voucher: IVoucher,
      Callback?: () => void
    ) => dispatch(PayStore.clearPaymentDetails(clientId, voucher, Callback)),
    addPaperFileConsentToClientTracking: (
      clientId: string,
      callback?: () => void
    ) =>
      dispatch(
        PaperFileReturnStore.addPaperFileConsentToClientTracking(
          clientId,
          callback
        )
      ),
    updatePaperFileConsent: (clientId: string, callback?: () => void) =>
      dispatch(PaperFileReturnStore.updatePaperFileConsent(clientId, callback)),
    addPaperFilePrintToClientTracking: (
      clientId: string,
      paperFile: IPaperFileReturnDetails,
      callback?: () => void
    ) =>
      dispatch(
        PaperFileReturnStore.addPaperFilePrintToClientTracking(
          clientId,
          paperFile,
          callback
        )
      ),
    addPaperFilePrintAllToClientTracking: (
      clientId: string,
      callback?: () => void
    ) =>
      dispatch(
        PaperFileReturnStore.addPaperFilePrintAllToClientTracking(
          clientId,
          callback
        )
      ),
    downloadAllPaperFileReturns: (clientId: string, callback?: () => void) =>
      dispatch(
        PaperFileReturnStore.downloadAllPaperFileReturns(clientId, callback)
      ),
    downloadIndividualPaperFileReturn: (
      clientId: string,
      paperFile: IPaperFileReturnDetails,
      callback?: () => void
    ) =>
      dispatch(
        PaperFileReturnStore.downloadIndividualPaperFileReturn(
          clientId,
          paperFile,
          callback
        )
      ),
    getPaperFileDocumentStream: (
      clientId: string,
      documentGuid: string,
      paperFile: IPaperFileReturnDetails,
      callback?: (data: any) => void
    ) =>
      dispatch(
        PaperFileReturnStore.getPaperFileDocumentStream(
          clientId,
          documentGuid,
          paperFile,
          callback
        )
      ),
    getMergedPaperFileDocumentStream: (
      clientId: string,
      callback?: (url: any) => void
    ) =>
      dispatch(
        PaperFileReturnStore.getMergedPaperFileDocumentStream(
          clientId,
          callback
        )
      ),
    requestPaperFileReturnDetails: (
      clientGuid: string,
      pageNo: number,
      pageSize: number,
      sortBy: string,
      sortOrder: string,
      searchText: string
    ) =>
      dispatch(
        PaperFileReturnStore.requestPaperFileReturnDetails(
          clientGuid,
          pageNo,
          pageSize,
          sortBy,
          sortOrder,
          searchText
        )
      ),
    getDocumentUpdatedStatus: (clientId: string) =>
      dispatch(DocumentStatusStore.getDocumentUpdatedStatus(clientId)),
    SetDocumentIsDownloaded: (clientId: string) =>
      dispatch(DocumentStatusStore.SetDocumentIsDownloaded(clientId)),
    getDocumentTransactions: (clientId: string, callback?: () => void) =>
      dispatch(HelperStore.getDocumentTransactions(clientId, callback)),

    downloadAllK1DocumentsAsync: (
      clientId: string,
      engagementTpe: EngagementTypeS,
      callback?: () => void
    ) =>
      dispatch(
        TrialK1DistributeStore.downloadAllK1DocumentsAsync(
          clientId,
          engagementTpe,
          callback
        )
      ),
    bulkSendMailToShareHolder: (
      clientId: string,
      unSelectedRows: IK1ShareHolderDetails[],
      callback: () => void
    ) =>
      dispatch(
        K1DistributeStore.bulkSendMailToShareHolder(
          clientId,
          unSelectedRows,
          callback
        )
      ),

    downloadBulkSelectedK1DocumentAsync: (
      clientId: string,
      shareHolders: IK1ShareHolderDetails[],
      engagementTpe: EngagementTypeS,
      callback?: () => void
    ) =>
      dispatch(
        K1DistributeStore.downloadBulkSelectedK1DocumentAsync(
          clientId,
          shareHolders,
          engagementTpe,
          callback
        )
      ),

    updateDraftReturnSignatureStatus: (
      clientId: string,
      signatureStatus: SignatureStatus,
      callback?: () => void
    ) =>
      dispatch(
        EsignStore.updateDraftReturnSignatureStatus(
          clientId,
          signatureStatus,
          callback
        )
      ),
  };
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export default connector(TaxpayerSignFlow);

/*export default withRouter(connect(
    (state: ApplicationState) => ({
        summaryData: state.summaryData,
        esignData: state.esignData.data,
        signerData: state.esignData.signerDetails,
        manualSignData: state.manualSignData.data,
        companyData: state.companyData.data,
        kbaData: state.kbaData,
        signatureControlsData: state.esignData.controlsData,
        loading: loadingSelector(state),
        commonData: state.commonData,
        signatureSettingModel: state.signatureSettingData,
        documentSettings: state.documentSettings,
        documentReviewModel: state.documentReviewModel,
        headInfoViewModel: state.headerInfo,
        taxReturn: state.taxReturn.taxDocument,
        isKBAPassed: state.taxReturn.isKBAPassed,
        k1Data: state.k1Data.data
    }),
    {
        ...SummaryStore.actionCreators,
        ...HelperStore.actionCreators,
        ...PreparerMessageStore.actionCreators,
        ...DocumentReviewStore.actionCreators,
        ...HelperStore.actionCreators,
        ...EsignStore.actionCreators,
        ...CompanyStore.actionCreators,
        ...ManualSignStore.actionCreators,
        ...TaxDocumentStore.actionCreators
        ...K1DistributeStore.actionCreators
    }
)(TaxpayerSignFlow));*/
