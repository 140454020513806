import * as React from 'react';
import { match } from 'react-router';
import { History } from 'history'
import { IHeaderInfoViewModel, initialHeaderInfoViewModel } from '../../core/domain/viewModels/IHeaderInfoViewModel';
import { TYPES } from '../../startup/types';
import { container } from '../../startup/inversify.config';
import { IUtilities } from '../../core/utilities/Utilities';
import { Shimmer } from '../Common/Shimmer/Shimmer';
import { SvgIconContactHeader,Home } from '../Common/Icons/SvgIcons';
import { PathConstants } from '../Common/Constants';
import { OverlayTrigger, Popover } from 'react-bootstrap';
import { handleKeyDown } from '../Helper/HelperFunction';

const utilities = container.get<IUtilities>(TYPES.IUtilities);


export interface RecipientHeaderProps {
    headerInfo: IHeaderInfoViewModel,
    match: match;
    history: History;
    logout: (clientGuid: string, callback: (id: string) => void) => void;
}
export interface RecipientHeaderState {
    headerInfo: IHeaderInfoViewModel,
    windowWidth: number;
    showContactPopover: boolean;
}

export class RecipientHeader extends React.Component<RecipientHeaderProps, RecipientHeaderState> {
    inputRef: any;
    contactRef: any;

    constructor(props: RecipientHeaderProps, states: RecipientHeaderState) {
        super(props, states);
        this.state = {
            headerInfo: initialHeaderInfoViewModel,
            windowWidth: window.innerWidth,
            showContactPopover: false,
        }

        this.inputRef = React.createRef();
        this.contactRef = React.createRef();
    }

    static getDerivedStateFromProps(nextProps: RecipientHeaderProps, prevState: RecipientHeaderState) {
        if (nextProps.headerInfo && (nextProps.headerInfo.brandingSettings.coverPageSetting.bgColorCode !== prevState.headerInfo.brandingSettings.coverPageSetting.bgColorCode
            || nextProps.headerInfo.brandingSettings.coverPageSetting.foreColorCode !== prevState.headerInfo.brandingSettings.coverPageSetting.foreColorCode)) {
            utilities.applyBrandingSettings(nextProps.headerInfo.brandingSettings);
            window.Variables.companyName = nextProps.headerInfo.companyName;
            return {
                headerInfo: nextProps.headerInfo
            }
        }
        else {
            return null;
        }
    }

    componentDidMount(): void {
        document.addEventListener("mousedown", this.handleClickOutsideContact);
        document.addEventListener("keydown", this.handleEscapeKeyEvent);
        if (screen && screen.orientation && screen.orientation.addEventListener) {
            screen.orientation.addEventListener('change', this.handleOrientationChange);
        }
    }

    componentWillUnmount(): void {
        document.removeEventListener("mousedown", this.handleClickOutsideContact);
        document.removeEventListener("keydown", this.handleEscapeKeyEvent);
        if (screen && screen.orientation && screen.orientation.removeEventListener) {
            screen.orientation.removeEventListener('change', this.handleOrientationChange);
        }
    }

    handleClickOutsideContact = (event: any) => {
        if (this.contactRef && this.state.showContactPopover &&
            this.contactRef.current && !this.contactRef.current.contains(event.target)) {
            this.setState({ showContactPopover: false });
        }
    }

    handleEscapeKeyEvent = (event: any) => {
        handleKeyDown(event, () => {
            if (this.state.showContactPopover) {
                this.setState({ showContactPopover: false });
            }
        }, "Escape")
    }

    handleOrientationChange = () => {
        this.setState({ windowWidth: window.innerWidth });
    }

    Logout = () => {
        let param: any = this.props.match.params;
        this.props.logout(param.clientId, this.onLogOutCompletion);
    }
    onLogOutCompletion = (id: string) => {
        const url = PathConstants.CCRecipientLogin + id;
        this.props.history.push(url);
    }
    public render() {
        let param: any = this.props.match.params;
        let contactPersonFullName: string = "";
        const contactPerson = this.state.headerInfo.contactPerson;
        contactPersonFullName += contactPerson.firstName;

        const toggleMenuWidth = this.state.windowWidth > 0 ? this.inputRef.current?.getBoundingClientRect()?.width : 0;

        let companyLogo = null;
        if (this.state.headerInfo.companyWhiteLogoPath == "") {
            companyLogo = <span data-test-auto="A2150408-8C6E-4460-9F2F-BA9DF37B09CA" style={{ marginLeft: `${toggleMenuWidth > 0 ? '30px' : ''}` }} className={`company-name ${this.props.headerInfo.companyName.length > 50 ? "long-text" : ""}`}>{this.state.headerInfo.companyName}</span>;
        }
        else {
            companyLogo = <img data-test-auto="A2150408-8C6E-4460-9F2F-BA9DF37B09CA" style={{ marginLeft: `${toggleMenuWidth > 0 ? '30px' : ''}` }} className="logo-img" src={this.state.headerInfo.companyWhiteLogoPath} alt="company-white-logo" />;
        }

        if (contactPerson.middleName && contactPerson.middleName.length > 0) {
            contactPersonFullName += " " + contactPerson.middleName;
        }
        if (contactPerson.lastName && contactPerson.lastName.length > 0) {
            contactPersonFullName += " " + contactPerson.lastName;
        }
        const contactInfoPopover = (
            <Popover id="contactInfoPopover" placement="bottom" data-test-auto="832DA592-EB4D-4463-998F-819121409D4B">
                <div ref={this.contactRef}>
                    <h3 className="popover-header">Contact Information</h3>
                    <div className="popover-body" data-test-auto="AB7B10F9-DABF-433B-B6CB-E54D40F5913C">
                        <div className='medium'>{contactPersonFullName} </div>
                        <div>
                            <strong>{utilities.formateFax(contactPerson.phoneNumber)}</strong>
                            {contactPerson && contactPerson.extension && contactPerson.extension.length > 0 && <strong> Ext: {contactPerson.extension}  </strong>}
                        </div>
                        <div className='mail' data-test-auto="AE6CABBD-ADBE-4A12-96C2-EB5621BE1C6A">
                            <a tabIndex={2} href={"mailto:" + contactPerson.email}>{contactPerson.email}</a>
                        </div>
                    </div>
                </div>
            </Popover>
        );

        return (
            this.props.headerInfo.brandingSettings &&
                this.props.headerInfo.brandingSettings.coverPageSetting.bgColorCode != "" ?
                <header className="app-header" data-test-auto="72FDDFDB-0D5C-475A-BFE1-560FC5E95118">
                    <div className="header-left-container">

                        <input type="checkbox" className="openSidebarMenu" id="openSidebarMenu" aria-label='Toggle Side Menu' />
                        <label ref={this.inputRef} htmlFor="openSidebarMenu" className="sidebarIconToggle" aria-label='Side Menu Icon'>
                            <div className="spinner diagonal part-1"> </div>
                            <div className="spinner horizontal"> </div>
                            <div className="spinner diagonal part-2"> </div>
                        </label>
                        <div id="sidebarMenu">
                            <ul className="sidebar navbar-nav">
                            </ul>
                        </div>

                        {companyLogo}
                    </div>
                    <div className="header-right-container">
                        <span className="header-account">
                            <a
                                tabIndex={3}
                                onClick={() => this.Logout()}
                                onKeyDown={(e: any) => handleKeyDown(e, () => this.Logout())}
                                style={{ color: 'var(--headerForeColor)' }}
                                data-test-auto="4AC1F751-00D6-4014-8633-02D244124744"
                            >
                                <Home fillColor="var(--headerForeColor)" />
                                <span style={{ marginRight: 0, marginLeft: '10px' }}>Logout</span>
                            </a>
                        </span>

                        <span className="header-contact-info" title="Contact Person's Information">
                            <OverlayTrigger
                                data-test-auto="2E7FCEA3-31A4-4A53-BBE2-7AA5156B6F8C"
                                rootClose trigger="click"
                                onEnter={() => document.body?.click()}
                                overlay={contactInfoPopover}
                                placement="bottom"
                                show={this.state.showContactPopover}
                            >
                                <a
                                    tabIndex={1}
                                    onClick={() => this.setState({ showContactPopover: !this.state.showContactPopover })}
                                    onKeyDown={(e: any) => handleKeyDown(e, () => this.setState({ showContactPopover: !this.state.showContactPopover }))}
                                >
                                    <SvgIconContactHeader
                                        fillColor="var(--headerForeColor)"
                                        height={20} />
                                    <span>Contact</span>
                                </a>
                            </OverlayTrigger>
                        </span>

                    </div>
                </header> : <Shimmer height={55} />)
    }
}