
import { connect } from 'react-redux';
import CoverPageContainer from "./CoverPage";
import * as  CoverPageStore from "../../../store/CoverPage/CoverPageStore";
import * as headerInfoStore from "../../../store/Common/AccountStore";

import * as HeaderStore from "../../../store/HeaderStore";

export default connect(
    (state: any) => ({
        initialCoverState: state.CoverPageReducer,
        headerInfoState: state.HeaderPageReducer
    }),
    {
        ...CoverPageStore.actionCreators,
        ...HeaderStore.actionCreators
    }
)(CoverPageContainer as any);