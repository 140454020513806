import { Action, Reducer } from 'redux';
import { AppThunkAction } from './';
import { actionTypes } from './ActionTypes';
import { StatusType, NotificationAction } from './Common/NotificationStore';
import { initializeAxios } from '../core/services/dataAccess/DataService.Axios'
import { AxiosResponse } from 'axios';
import { InvoicePaymentConstants } from '../components/Common/Constants';
import { IInvoicePayment, initialInvoicePayment } from '../core/domain/models/InvoicePaymentModel';
import Moment from 'moment/moment';
import { handleResponse } from './Library';
import { TelemetryLogger } from '../components/Logger/AppInsights';

const logger = TelemetryLogger.getInstance();

export interface InvoicePaymentState {
    data: IInvoicePayment;
}

export const initialInvoicePaymentState: InvoicePaymentState = {
    data: initialInvoicePayment,
}

interface RequestInvoicePaymentAction {
    type: actionTypes.INVOICE_PAYMENT_REQUEST;
    clientId: string;
}

interface ResponseInvoicePaymentAction {
    type: actionTypes.INVOICE_PAYMENT_RESPONSE;
    data: IInvoicePayment;
}


type KnownAction =
    DispatchAction |
    NotificationAction;

type DispatchAction = RequestInvoicePaymentAction |
    ResponseInvoicePaymentAction;

export const actionCreators = {

    requestInvoicePaymentDetails: (clientId: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch({ type: actionTypes.INVOICE_PAYMENT_REQUEST, clientId: clientId });

        return initializeAxios().get<IInvoicePayment>('api/Pay/GetInvoicePaymentDetailAsync/' + clientId)
            .then(function (response: AxiosResponse<IInvoicePayment>) {
                if (response.data.amountPaid > 0)
                    response.data.isPaid = true;
                dispatch({ type: actionTypes.INVOICE_PAYMENT_RESPONSE, data: response.data });

            })
            .catch(function (error: any) {
                dispatch({
                    type: actionTypes.NOTIFICATION, statusMessage: error.Response ? error.response.statusText : InvoicePaymentConstants.ErrorMessage.RequestInvoicePaymentError,
                    statusType: StatusType.Error
                });
                logger.trackWarning(`requestInvoicePaymentDetails failed with error ${error.message} for client: ${clientId}`, { "ClientId": clientId });
            });
    },
    addInvoicePayment: (clientId: string, invoice: IInvoicePayment, callback?: () => void): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const options = {
            headers: {
                'Accept': 'application/json, text/plain, *',
                'Content-Type': 'application/json; charset=utf-8'
            }
        };
        let dataObj: string = JSON.stringify(invoice, function (key, value) {
            if (key === "paidDate") {
                return Moment(value).format('MM/DD/YYYY');
            } else { return value; }
        });
        return initializeAxios().post<IInvoicePayment>('api/Pay/AddInvoicePaymentAsync/' + clientId, dataObj, options)
            .then(function (response: AxiosResponse<IInvoicePayment>) {
                invoice.isPaid = true;
                dispatch({
                    type: actionTypes.INVOICE_PAYMENT_RESPONSE, data: invoice,
                });

                if (callback) {
                    callback();
                }
                dispatch({
                    type: actionTypes.NOTIFICATION, statusMessage: InvoicePaymentConstants.SuccessMessage.AddInvoicePaymentSuccess,
                    statusType: StatusType.Success
                });

            })
            .catch(function (error: any) {
                dispatch({
                    type: actionTypes.NOTIFICATION, statusMessage: InvoicePaymentConstants.ErrorMessage.AddInvoicePaymentError,
                    statusType: StatusType.Error
                });
                logger.trackWarning(`addInvoicePayment failed with error ${error.message} for client: ${clientId}`, { "ClientId": clientId });
            });
    },
    updateInvoicePayment: (clientId: string, invoice: IInvoicePayment, callback?: () => void): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const options = {
            headers: {
                'Accept': 'application/json, text/plain, *',
                'Content-Type': 'application/json; charset=utf-8'
            }
        };
        let dataObj: string = JSON.stringify(invoice, function (key, value) {
            if (key === "paidDate") {
                return Moment(value).format('MM/DD/YYYY');
            } else { return value; }
        });
        return initializeAxios().put<IInvoicePayment>('api/Pay/UpdateInvoicePaymentAsync/' + clientId, dataObj, options)
            .then(function (response: AxiosResponse<IInvoicePayment>) {
                dispatch({
                    type: actionTypes.INVOICE_PAYMENT_RESPONSE, data: invoice,
                });

                if (callback) {
                    callback();
                }
                dispatch({
                    type: actionTypes.NOTIFICATION, statusMessage: InvoicePaymentConstants.SuccessMessage.UpdateInvoicePaymentSuccess,
                    statusType: StatusType.Success
                });

            })
            .catch(function (error: any) {
                dispatch({
                    type: actionTypes.NOTIFICATION, statusMessage: InvoicePaymentConstants.ErrorMessage.UpdateInvoicePaymentError,
                    statusType: StatusType.Error
                });
                logger.trackWarning(`updateInvoicePayment failed with error ${error.message} for client: ${clientId}`, { "ClientId": clientId });
            });
    },
    deleteInvoicePayment: (clientId: string, callback?: () => void): AppThunkAction<KnownAction> => (dispatch, getState) => {
        return initializeAxios().put('api/Pay/DeleteInvoicePaymentAsync/' + clientId)
            .then(function (response: AxiosResponse<IInvoicePayment>) {
                dispatch({
                    type: actionTypes.INVOICE_PAYMENT_RESPONSE, data: initialInvoicePayment,
                });

                if (callback) {
                    callback();
                }
                dispatch({
                    type: actionTypes.NOTIFICATION, statusMessage: InvoicePaymentConstants.SuccessMessage.ClearAllInvoicePaymentSuccess,
                    statusType: StatusType.Success
                });
            })
            .catch(function (error: any) {
                dispatch({
                    type: actionTypes.NOTIFICATION, statusMessage: InvoicePaymentConstants.ErrorMessage.ClearAllInvoicePaymentError,
                    statusType: StatusType.Error
                });
                logger.trackWarning(`deleteInvoicePayment failed with error ${error.message} for client: ${clientId}`, { "ClientId": clientId });
            });
    }
}

export const reducer: Reducer<InvoicePaymentState> = (state: InvoicePaymentState = initialInvoicePaymentState, incomingAction: Action) => {
    const action = incomingAction as DispatchAction;
    const currentState = Object.assign({}, state);
    switch (action.type) {
        case actionTypes.INVOICE_PAYMENT_REQUEST:
            return currentState;
        case actionTypes.INVOICE_PAYMENT_RESPONSE:
            currentState.data = action.data;
            return currentState;
        default:
            return currentState || initialInvoicePaymentState;
    }
};

