import * as React from 'react';
import { match, RouteComponentProps } from "react-router";
import { PathConstants, getRoutePathUrl } from '../Common/Constants';
import { connect } from 'react-redux';
import { ApplicationState } from "../../store/index";
import { VoucherSelectorPage } from "../Common/Constants";
import { checkVoucherValidation, handleKeyDown } from "../Helper/HelperFunction";
import { VoucherTypes } from '../../core/domain/models/IGroup';
import { VoucherPayConstant } from "../../components/Common/Constants";
import { logger } from '../../routes';
import { Footer } from '../Common/StepLayout/Footer';
import { Next } from '@sssuite-component-ui/multi-step-wizard/dist/Footer/Next';
import { Previous } from '@sssuite-component-ui/multi-step-wizard/dist/Footer/Previous';
import { StepLayout } from '../Common/StepLayout/StepLayout';
import { Body } from '../Common/StepLayout/Body';
import { BackButtonIcon, TaxPaymentInfoIcon, EstimatePaymentInfoIcon } from "../Common/Icons/SvgIcons";
import { TileComponent } from 'cp-common-ui-components';
import {  RedirectionSourcePage } from '../../core/common/Enums'
import * as HelperStore from "../../store/Common/HelperStore";
import * as TaxDocumentStore from "../../store/Common/TaxDocumentStore";
import * as SignProcessStore from "../../store/SignProcess/SignProcessStore";
import * as TaxDocument from "../../core/domain/models/ITaxReturn";
import { IClientProcessViewModel } from "../../core/domain/viewModels/IClientProcessViewModel";

export type VoucherSelectionComponentProps = {
    clientProcessData: IClientProcessViewModel;
    getClientHubDomainURL: (clientGuid: string, callback: (hubDomainURL: string) => void) => void;
    taxReturn: TaxDocument.ITaxReturn;
  } & typeof TaxDocumentStore.actionCreators &
    typeof SignProcessStore.actionCreators &
    typeof HelperStore.actionCreators &
    RouteComponentProps<{}>;

class VoucherSelector extends React.Component<VoucherSelectionComponentProps, any> {

    constructor(props: VoucherSelectionComponentProps) {
        super(props);
        this.state = {
            taxYear: "",
            isAccessedFromOnehub: false
        }
    }

    componentDidMount() {
        const param: any = this.props.match.params;
        logger.trackPageView('Completed Summary Voucher Selection Page', { "ClientId": param.clientId, "DocumentId": this.props.taxReturn.id });
        this.state.taxYear.length === 0 && this.setState({ taxYear: this.props.taxReturn.taxYear })
        this.props.requestTaxDocument(param.clientId);
        this.props.requestCurrentStepAndUserRole(param.clientId);
    }

    componentDidUpdate(prevProps: VoucherSelectionComponentProps,prevState: Readonly<any>) {
        if(prevProps.taxReturn.taxYear ===0 && this.props.taxReturn.taxYear > 0){
            this.setState({ taxYear: this.props.taxReturn.taxYear });
        }
        if(this.props.clientProcessData && prevState.isAccessedFromOnehub !== this.props.clientProcessData.isAccessedFromOneHub){
            this.setState({isAccessedFromOnehub: this.props.clientProcessData.isAccessedFromOneHub});
        }
    }

    isPayPageDirectAccessFromUTE =():boolean => {
        return this.props.clientProcessData.redirectionSourcePage === RedirectionSourcePage.Pay;
    }

     handleBackButtonAction = () => {
        this.isPayPageDirectAccessFromUTE() ? this.redirectToOneHub(PathConstants.UTEPay) : this.goToSummary();
    }

    handleDoneButtonAction = () => {
        this.isPayPageDirectAccessFromUTE() ? this.redirectToOneHub(PathConstants.UTEPay) : this.goToSummary();
    }

    redirectToOneHub = (returnUrl?: string) => {
        let params: any = this.props.match.params;
        this.props.getClientHubDomainURL(params.clientId, (oneHubDomainURL: string) => {
            window.location.href = oneHubDomainURL + (returnUrl ? (returnUrl):"");
        });
    }


    goToSummary = () => {
        const param: any = this.props.match.params;
        return this.props.history.push(getRoutePathUrl(PathConstants.CompletionWizard, param.clientId, param.controllerId));
    }

    goToEstimateVoucherPage = () => {
        const param: any = this.props.match.params;
        return this.props.history.push(`${VoucherSelectorPage.EstimatedVoucherPageURL}/${param.controllerId ? param.controllerId + "/" : ""}${param.clientId}`);
    }


    goToPaymentVoucherPage = () => {
        const param: any = this.props.match.params;
        return this.props.history.push(`${VoucherSelectorPage.TaxpaymentVoucherPageURL}/${param.controllerId ? param.controllerId + "/" : ""}${param.clientId}`);
    }

    public render() {
        const taxYear = this.state.taxYear;
        return (<>
        <StepLayout className={"with-footer"}  lg={12} md={12} sm={12} xs={12} >
            <Body bodyClassName='voucher-selector' lg={12} md={12} sm={12} xs={12}>
                <div className="pay-selector-main">
                    <div className="cards-container">
                        <div className="pay-header">
                            <p className='py-1.25'>Tax Payments</p>
                            <p>Select the voucher type to be able to view tax vouchers and make payments.</p>
                        </div>
                        <div className='cards-content'>
                            <div
                                className='cards'
                                tabIndex={0}
                                onKeyDown={(e: any) => handleKeyDown(e, () => checkVoucherValidation(this.props.taxReturn, VoucherTypes.PaymentVoucher, VoucherPayConstant.NoPaymentVoucher, this.goToPaymentVoucherPage))}
                            >
                                <TileComponent
                                    onClick={() => { checkVoucherValidation(this.props.taxReturn, VoucherTypes.PaymentVoucher, VoucherPayConstant.NoPaymentVoucher, this.goToPaymentVoucherPage) }}
                                    tileContent={<TaxPaymentInfoIcon/>}
                                    tileDetail={<p>{taxYear}  Tax Payments</p>}
                                />
                            </div>
                            <div
                                className='cards'
                                tabIndex={0}
                                onKeyDown={(e: any) => handleKeyDown(e, () => checkVoucherValidation(this.props.taxReturn, VoucherTypes.EstimatedVoucher, VoucherPayConstant.NoEstimatedVoucher, this.goToEstimateVoucherPage))}
                            >
                                <TileComponent
                                    onClick={() => { checkVoucherValidation(this.props.taxReturn, VoucherTypes.EstimatedVoucher, VoucherPayConstant.NoEstimatedVoucher, this.goToEstimateVoucherPage) }}
                                    tileContent={<EstimatePaymentInfoIcon/>}
                                    tileDetail={<p>{taxYear + 1} Estimated Payments</p>}
                                />
                            </div>
                        </div>
                    </div>
                </div> 
            </Body>
        </StepLayout>
        <Footer lg={12} md={12} sm={12} xs={12}>
                    <div className={"steps-footer "}>
                        <Previous automationTestId={"334"} 
                            disablePreviousButton = { taxYear === 0}
                            text={"Back"} goToPreviousStep={this.handleBackButtonAction} isActive={true} disableMobileResponsiveness={true}
                            previousButtonIcon={<BackButtonIcon />}
                        />
                        <span style={{ float: "right", marginLeft: "20px" }}>
                            <Next automationTestId={"324234"}
                                disableNextButton = { taxYear === 0}
                                text={"Done"} goToNextStep={this.handleDoneButtonAction} isActive={true} disableMobileResponsiveness={true} className="awesome-multi-steps-btn-done" buttonVariant="success" buttonSize="medium" />
                        </span>
                    </div>
            </Footer>
        </>);
    }
}

function mapStateToProps(state: ApplicationState) {
    return {
        taxReturn: state.taxReturn.taxDocument

    };
}
const connector = connect(mapStateToProps, {});

export default connector(VoucherSelector);
