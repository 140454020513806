import * as React from 'react';
import { Modal } from 'react-bootstrap';
import { SiginingConstants } from '../Common/Constants';
import { CloseIcon } from '../Common/Icons/SvgIcons';
import { ButtonComponent } from 'cp-common-ui-components';
import { handleKeyDown } from '../Helper/HelperFunction';

export interface EditSpouseEmailProps {
    showAlert: boolean;
    showState: boolean;
    onSaveButtonClick: (email: string) => void;
    onHide: () => void;
    spouseEmail: string;
}

interface EditSpouseEmailState {
    emailAddress: string;
}


export class EditSpouseMail extends React.Component<EditSpouseEmailProps, EditSpouseEmailState>
{
    constructor(props: any) {
        super(props);
        this.state = {
            emailAddress: props.spouseEmail
        }
    }

    private onSpouseMailChange = (e: any) => {
        //if (!e.target.value || !isValidEmailAddress(e.target.value)) {
        //    return;
        //} else {
            this.setState({ emailAddress: e.target.value });
       // }

    }

    static getDerivedStateFromProps(props: EditSpouseEmailProps, state: EditSpouseEmailState) {
        if (!props.spouseEmail) {
            return null;
        }

        else {
            if (props.spouseEmail && props.spouseEmail !== state.emailAddress) {
                return {
                    emailAddress: state.emailAddress,
                };
            }
        }
        return null;
    }


    public render() {
        return (
            <Modal className='edit-spouse-mail' show={this.props.showState} onHide={this.props.onHide}>
                <Modal.Header className='darkBackground' data-test-auto="6E0248EE-A2AB-4F54-90F3-6AE4953B1BAC">
                    <Modal.Title>
                        {SiginingConstants.StatusMessage.EditSpouseEmail}
                    </Modal.Title>
                    <div
                        data-test-auto="3dd909b2-07a9-40f3-9feb-89ac9a2c01cc"
                        className="closeIcon"
                        onClick={this.props.onHide}
                        tabIndex={0}
                        onKeyDown={(e: any) => handleKeyDown(e, this.props.onHide)}
                    >
                        <CloseIcon />
                    </div>
                </Modal.Header>
                <Modal.Body>
                    <div data-test-auto="D6E17265-FCBC-4D8A-91AB-56FD94F48770">
                        <label htmlFor='spouseEmail' className='labelMobileNumber'>Enter Spouse Email Address</label>
                        <div>
                            <input
                                className="form-control"
                                type="text"
                                id='spouseEmail'
                                value={this.state.emailAddress}
                                onChange={(e) => this.onSpouseMailChange(e)}
                                data-test-auto="BACE91D0-C00E-458D-9992-3CEB62ABB5E7"
                            />
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <ButtonComponent
                        data-test-auto="C20DF8CC-F4AA-4E8D-B710-75DB7EEC6146"
                        onClick={this.props.onHide}
                        variant={"outline-tertiary"}
                        buttonClassName={"button-margin-right"}
                        size={"medium"}
                    >
                        Cancel
                    </ButtonComponent>
                    <ButtonComponent
                        data-test-auto="3BAB1674-CFFD-466E-B34E-18BF07B654C0"
                        onClick={() => { this.props.onSaveButtonClick(this.state.emailAddress) }}
                        variant={"primary"}
                        size={"medium"}
                    >
                        Save
                    </ButtonComponent>
                </Modal.Footer>
            </Modal>
        );
    }

}

export default EditSpouseMail;