import { Next } from '@sssuite-component-ui/multi-step-wizard/dist/Footer/Next';
import { Previous } from '@sssuite-component-ui/multi-step-wizard/dist/Footer/Previous';
import { History } from 'history';
import * as React from 'react';
import { match } from 'react-router';
import { DocumentStatus, SignatureStatus } from '../../core/common/Enums';
import { IAdditionalEsignDocument } from '../../core/domain/models/IAdditionalEsignDocument';
import { IAttachment } from '../../core/domain/models/IAttachment';
import { FormType, IDownloadableDocuments, ITaxReturn } from '../../core/domain/models/ITaxReturn';
import { ISignedDocument } from '../../core/domain/models/manualsign/SignedDocument';
import { IClientProcessViewModel } from '../../core/domain/viewModels/IClientProcessViewModel';
import { IDownloadableDocumentsViewModel } from '../../core/domain/viewModels/IDownloadableDocumentsViewModel';
import { IHeaderInfoViewModel } from '../../core/domain/viewModels/IHeaderInfoViewModel';
import { PathConstants, getRoutePathUrl } from '../Common/Constants';
import { Shimmer } from '../Common/Shimmer/Shimmer';
import { Body } from '../Common/StepLayout/Body';
import { Footer } from '../Common/StepLayout/Footer';
import { StepLayout } from '../Common/StepLayout/StepLayout';
import { DownloadAll } from '../Download/DownloadAll';
import TaxpayerHelper from '../Helper/TaxpayerHelper';
import { DownloadList, DownloadListItem } from './DownloadList';
import { IFileUtilities } from '../../core/utilities/File/FileUtilities';
import { container } from '../../startup/inversify.config';
import { TYPES } from '../../startup/types';
import { storeControllerIdInMemory } from '../../core/services/dataAccess/DataService.Axios';
import { logger } from '../../routes';
import { DisplayDownloadFile } from '../Common/DisplayDownloadFile';
import { PreviousButtonIcon } from '../Common/Icons/SvgIcons';

export interface DownloadProps {
	match: match;
	history: History;
	DownloadableDocuments: IDownloadableDocumentsViewModel;
	TaxDocument: ITaxReturn;
	signedDocuments: ISignedDocument[];
	headerInfo: IHeaderInfoViewModel;
	requestDownloadableDocuments: (clientId: string) => any;
	refreshTaxDocument: (clientId: string) => any;
	downloadTaxReturnDocument: (clientGuid: string, fileName: string) => any;
	downloadEFileDocument: (clientGuid: string, fileName: string) => any;
	downloadSignedEFileDocument: (clientGuid: string, fileName: string) => any;
	downloadTransmittalDocument: (clientGuid: string, fileName: string) => any;
	downloadInvoiceDocument: (clientGuid: string, fileName: string) => any;
	downloadK1Document: (clientGuid: string, fileName: string) => any;
	downloadVoucherDocument: (clientGuid: string, fileName: string) => any;
	downloadAttachment: (clientGuid: string, fileName: string) => any;
	downloadAllDocuments: (clientGuid: string, fileName: string) => any;
	downloadAllAttatchments: (clientGuid: string, fileName: string) => any;
	downloadAllAdditionEsignDocuments: (clientGuid: string, fileName: string) => any;
	requestSignedDocuments: (clientGuid: string, forceRefresh?: boolean) => any;
	getMergedPaperFileDocumentStream: (clientId: string, callback?: (url: any) => any) => any;
	requestCurrentStepAndUserRole: (clientId: string, successCallback?: (clientProcessState: IClientProcessViewModel) => void) => any;
}

export interface DownloadState {
	showDownloadAllModal: boolean;
	isProcessCompleted: boolean;
	isPreviewMode: boolean;
}

const fileUtilities = container.get<IFileUtilities>(TYPES.IFileUtilities);

export class Main extends React.Component<DownloadProps, DownloadState> {

	constructor(props: DownloadProps) {
		super(props);
		this.state = {
			showDownloadAllModal: false,
			isProcessCompleted: false,
			isPreviewMode: false
		}
	}

	componentDidMount() {
		const param: any = this.props.match.params;
		if (param && param.controllerId) {
			storeControllerIdInMemory(param.controllerId);
		}
		this.props.requestDownloadableDocuments(param.clientId);
		if (this.props.TaxDocument.id === 0) {
			this.props.refreshTaxDocument(param.clientId);
		}
		if (this.props.signedDocuments && this.props.signedDocuments.length == 0) {
			this.props.requestSignedDocuments(param.clientId, true);
		}
		this.getSignProcessStatus(param.clientId);
		logger.trackPageView('Completed Summary Download Taxdocuments Page', { "ClientId": param.clientId, "DocumentId": this.props.TaxDocument.id, "PreviewMode": this.state.isPreviewMode });
	}

	hideDownloadAllModal = () => {
		this.setState({ showDownloadAllModal: false });
	}

	showDownloadAllModal = () => {
		this.setState({ showDownloadAllModal: true });
	}

    getSignedDocumentFileName = () => {
		let taxReturn: any = this.props.TaxDocument;
        let fileNamePrefix = this.getFileNamePrefix();
        let fileName = "";
        if (this.props.signedDocuments && this.props.signedDocuments.length == 1) {
            if(TaxpayerHelper.isMarried(taxReturn) && taxReturn.signatureStatus == SignatureStatus[SignatureStatus.PartiallySigned])
                fileName = "_Signature Document.pdf";
        else
                fileName = "_Signed Document.pdf";
        }
        else if (this.props.signedDocuments && this.props.signedDocuments.length > 1) {
            if(TaxpayerHelper.isMarried(taxReturn) && taxReturn.signatureStatus == SignatureStatus[SignatureStatus.PartiallySigned])
                fileName = "_Signature Documents.zip";
        else
                fileName = "_Signed Documents.zip";
        }
        return fileNamePrefix + fileName;
    }

	getPaperFileName = () => {
		return this.props.headerInfo.taxYear + " Paper Filed Return(s).pdf"
	}

	getFileNamePrefix = () => {
		return this.props.headerInfo.clientName + "_" + this.props.headerInfo.taxYear + "_" + this.props.TaxDocument.engagementType.toString().substr(1);
	}

	getOtherSignedDocumentFileName = () => {
		let taxreturn: any = this.props.TaxDocument;
		if(TaxpayerHelper.isMarried(taxreturn) && taxreturn.signatureStatus 
			==  SignatureStatus[SignatureStatus.PartiallySigned])
			return this.getFileNamePrefix() + "_Other Signature Documents.zip";
		else
			return this.getFileNamePrefix() + "_Other Signed Documents.zip";
	}

	private getSignProcessStatus = (clientId: any) => {
		this.props.requestCurrentStepAndUserRole(clientId, this.handleRedirect);
	}

	private handleRedirect = (clientProcessState: IClientProcessViewModel) => {
		const param: any = this.props.match.params;
		const url = TaxpayerHelper.getSignCompletedRedirectUrl(clientProcessState, param.clientId);
		if (url && url.trim()) {
			this.props.history.push(url);
		}
		else {
			this.setState({ isProcessCompleted: true });
		}
		this.setState({ isPreviewMode: TaxpayerHelper.isPreviewMode(clientProcessState) });
	}

	private downloadMergedPaperFile = (clientId:string) => {
		this.props.getMergedPaperFileDocumentStream(clientId, 
			(response) => {
				let fileName: string = this.getPaperFileName();				
				var file = new Blob([response.data], { type: 'application/pdf' });
				let displayDownloadFile = new DisplayDownloadFile();
				displayDownloadFile.showFile(file, fileName);
			}
		);
	}

	public render() {
		const param: any = this.props.match.params;

		const vouchers: IDownloadableDocuments[] = this.props.DownloadableDocuments.
			downloadableDocuments.filter(x => x.groupId === FormType.Vouchers);
		const invoices: IDownloadableDocuments[] = this.props.DownloadableDocuments.
			downloadableDocuments.filter(x => x.groupId === FormType.Invoice);
		const attachments: IAttachment[] = this.props.DownloadableDocuments
			.attachments;
		const additionalEsignDocuments: IAdditionalEsignDocument[] = this.props.DownloadableDocuments
			.additionalEsignDocuments;

		let numberOfDivision: number = 1;

		if (vouchers && vouchers.length > 0) {
			numberOfDivision = numberOfDivision + 1;
		}

		if ((invoices && invoices.length > 0) || (attachments && attachments.length > 0)) {
			numberOfDivision = numberOfDivision + 1;
		}

		const taxReturn = this.props.DownloadableDocuments.
			downloadableDocuments.find(x => x.groupId === FormType.TaxReturns);

		const k1 = this.props.DownloadableDocuments.
			downloadableDocuments.find(x => x.groupId === FormType.K1);

		const transmittals = this.props.DownloadableDocuments.
			downloadableDocuments.find(x => x.groupId === FormType.Transmittals);

		const efile = this.props.DownloadableDocuments.
			downloadableDocuments.find(x => x.groupId === FormType.EFile);

		let invoiceAndAttachmentHeader = (invoices && invoices.length > 0) ? "Invoice" : "";
		invoiceAndAttachmentHeader = invoiceAndAttachmentHeader + (attachments && attachments.length > 0 ?
			((invoices && invoices.length > 0 ? " & " : "") + " Attachments") : "");

		const taxDocumentListItem: DownloadListItem[] = [];

		if (taxReturn && taxReturn.documentId > 0) {
			taxDocumentListItem.push({
				onClick: this.props.downloadTaxReturnDocument,
				testAutoId: 'A561B7A4-EF0C-4877-AA4B-48F81D31FF6C',
				text: taxReturn.fileName,
				args: [param.clientId, taxReturn.fileName],
				isPreviewMode: this.state.isPreviewMode
			});
		}

		if (k1 && k1.documentId > 0) {
			taxDocumentListItem.push({
				onClick: this.props.downloadK1Document,
				testAutoId: 'EB81A470-879E-4B99-917C-99445D769816',
				text: k1.fileName,
				args: [param.clientId, k1.fileName],
			});
		}

		if (transmittals && transmittals.documentId > 0) {
			taxDocumentListItem.push({
				onClick: this.props.downloadTransmittalDocument,
				testAutoId: 'E4EBE666-FDEE-4DB7-9566-436D36FDB392',
				text: transmittals.fileName,
				args: [param.clientId, transmittals.fileName],
				isPreviewMode: this.state.isPreviewMode
			});
		}

		if (efile && efile.documentId > 0) {
			taxDocumentListItem.push({
				onClick: this.props.signedDocuments.length > 0 ?
					this.props.downloadSignedEFileDocument :
					this.props.downloadEFileDocument,
				testAutoId: '5000B40B-2EC9-4DAA-A2EF-B683503E289F',
				text: efile.fileName,
				instruction: efile.fileName != "Signed Efile" ? "Please complete the signing process to download your signed documents." : undefined,
				args: [param.clientId, this.props.TaxDocument.documentStatus.toString() === DocumentStatus[DocumentStatus.USERSIGNED]
					? this.getSignedDocumentFileName() : fileUtilities.addExtensionIfNotExist(efile.fileName, "pdf")],
				isPreviewMode: this.state.isPreviewMode
			});
		}

		if (additionalEsignDocuments && additionalEsignDocuments.length > 0) {
			taxDocumentListItem.push({
				onClick: this.props.downloadAllAdditionEsignDocuments,
				testAutoId: 'B1CD56B3-439C-421E-8996-EEC082949D3A',
				text: "Other Signed Documents",
				args: [param.clientId, this.getOtherSignedDocumentFileName()],
				isPreviewMode: this.state.isPreviewMode
			});
		}

		const voucherListItem: DownloadListItem[] = [];

		if (vouchers) {
			vouchers.map((value, index) => {
				voucherListItem.push({
					onClick: this.props.downloadVoucherDocument,
					testAutoId: '',
					text: value.fileName,
					args: [param.clientId, value.fileName],
					isPreviewMode: this.state.isPreviewMode
				});
			});
		}

		const invoiceAndAttachmentListItem: DownloadListItem[] = [];

		if (invoices) {
			invoices.map((value, index) => {
				invoiceAndAttachmentListItem.push({
					onClick: this.props.downloadInvoiceDocument,
					testAutoId: '',
					text: value.fileName,
					args: [param.clientId, value.fileName],
					isPreviewMode: this.state.isPreviewMode
				});
			});
		}

		if (attachments) {
			attachments.map((value, index) => {
				invoiceAndAttachmentListItem.push({
					onClick: this.props.downloadAttachment,
					testAutoId: '',
					text: value.fileName,
					args: [param.clientId, value.fileName],
					instruction: value.instruction,
					isPreviewMode: this.state.isPreviewMode
				});
			});
		}

		return (
            this.state.isProcessCompleted && (
                <StepLayout
					className={"with-footer download-documents-layout"}
					showDownloadButton={true}
					customDownloadText='Download All'
					title=' '
					handleDownload={this.showDownloadAllModal}
				>
                    <Body
                        automationTestId={"11BB28B5-42F3-4571-B567-20700B1740B6"}
                        lg={12}
                        md={12}
                        sm={12}
                        xs={12}
                        bodyClassName={"no-padding"}
						containerClassName='download-documents-screen'
                    >
                        {this.props.DownloadableDocuments.downloadableDocuments.length === 0 ||
                        this.props.TaxDocument.id === 0 ? (
                            <Shimmer height={550} />
                        ) : (
                            <div className='download-documents-screen_container'>
								<div className='download-documents-screen_title'>Download Tax Documents</div>
								
								<p className='download-documents-screen_paragraph'>
									Your {this.props.TaxDocument.taxYear} tax documents are available for download. 
									You can retrieve them individually or download them all as a ZIP file.
								</p>

                                <div>
                                    <div
                                        data-test-auto="EB9A6226-E38F-46CA-AB9A-4C3F946066EB"
                                    >
                                        <DownloadList title="Tax Documents" items={taxDocumentListItem} />
                                    </div>

                                    {vouchers && vouchers.length > 0 && (
                                        <div
                                            data-test-auto="8D6413F7-759D-4AD2-A852-D1C0265854EE"
                                        >
                                            <DownloadList title="Payment Vouchers" items={voucherListItem} />
                                        </div>
                                    )}

                                    {invoiceAndAttachmentHeader.length > 0 && (
                                        <div
                                            data-test-auto="AFA9F180-D9E7-43DD-968C-E7972A775557"
                                        >
                                            <DownloadList
                                                title={invoiceAndAttachmentHeader}
                                                items={invoiceAndAttachmentListItem}
                                            />
                                        </div>
                                    )}
                                </div>
                            </div>
                        )}

                        <DownloadAll
                            DownloadableDocuments={this.props.DownloadableDocuments}
                            downloadAttachment={this.props.downloadAttachment}
                            downloadInvoiceDocument={this.props.downloadInvoiceDocument}
                            downloadK1Document={this.props.downloadK1Document}
                            downloadTaxReturnDocument={this.props.downloadTaxReturnDocument}
                            downloadTransmittalDocument={this.props.downloadTransmittalDocument}
                            downloadVoucherDocument={this.props.downloadVoucherDocument}
                            downloadMergedPaperFileDocument={this.downloadMergedPaperFile}
                            onDownloadAll={this.props.downloadAllDocuments}
                            onHide={this.hideDownloadAllModal}
                            showModal={this.state.showDownloadAllModal}
                            match={this.props.match}
                            history={this.props.history}
                            downloadAllAttatchments={this.props.downloadAllAttatchments}
                            fileNamePrefix={this.getFileNamePrefix()}
                            paperFileName={this.getPaperFileName()}
                            isPreviewMode={this.state.isPreviewMode}
                        />
                    </Body>

                    <Footer lg={12} md={12} sm={12} xs={12}>
                        <div className={"col-lg-12 steps-footer "}>
                            <Previous
                                automationTestId={"3F43C07D-73AD-4BB5-83D1-346B1E62ABC6"}
                                text={"Back"}
                                goToPreviousStep={() => {
                                    this.props.history.push(
                                        getRoutePathUrl(
                                            PathConstants.CompletionWizard,
                                            param.clientId,
                                            param.controllerId
                                        )
                                    );
                                }}
                                isActive={true}
                                disableMobileResponsiveness={true}
								previousButtonIcon={<PreviousButtonIcon />}
                            />

                            <Next
                                automationTestId={"21596F53-89B5-4830-812E-96D126E7C1AE"}
                                text={"Done"}
                                goToNextStep={() => {
                                    this.props.history.push(
                                        getRoutePathUrl(
                                            PathConstants.CompletionWizard,
                                            param.clientId,
                                            param.controllerId
                                        )
                                    );
                                }}
                                isActive={true}
                                disableMobileResponsiveness={true}
								className='awesome-multi-steps-btn-done'
								buttonVariant='success'
								buttonSize='medium'
                            />
                        </div>
                    </Footer>
                </StepLayout>
            )
        );
	}
}
