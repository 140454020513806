import React, { FC } from "react";
import OtpInput from "react-otp-input";
import { SvgIconExclamationErrorCircle } from "../Icons/SvgIcons";

interface IOtpInputControlProps {
  otp: string;
  setOtp: (otp: string) => void;
  isInvalid?: boolean;
  errorText?: string;
  numInputs?: number;
}

const OtpInputControl: FC<IOtpInputControlProps> = ({
  otp,
  setOtp,
  isInvalid,
  errorText,
  numInputs,
}) => {
  return (
    <div
      className={`otp-input-control-wrapper${
        isInvalid && otp.length === 0 ? " error" : ""
      }`}
    >
      <div className="d-flex">
      <OtpInput
        value={otp}
        onChange={setOtp}
        numInputs={numInputs}
        inputType={"tel"}
        containerStyle="input-container"
        inputStyle={{ width: 34 }}
        renderInput={(props) => <input {...props} />}
      />
      {isInvalid && <SvgIconExclamationErrorCircle className="ml-3 mt-3"/>}
      </div>      
      {isInvalid && (
        <>{errorText && <div className="invalid-feedback">{errorText}</div>}</>
      )}
    </div>
  );
};

export default OtpInputControl;
