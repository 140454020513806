import * as React from 'react';
import { SignatureType } from '../../../core/common/Enums';
import { IDialogBox } from '../../../core/utilities/ui/DialogBox';
import { container } from '../../../startup/inversify.config';
import { TYPES } from '../../../startup/types';
import { SiginingConstants, Common } from '../../Common/Constants';
import { CustomLargeButton } from '../../Common/CustomButton/CustomLargeButton';
import { StepLayout } from '../../Common/StepLayout/StepLayout';
import { Body } from '../../Common/StepLayout/Body';
import { logger } from '../../../routes';
import { match } from 'react-router';



interface SelectSignMethodProps {
    onSetSignMethod: (signatureType: SignatureType) => void;
    clearParentState: () => void;
    match: match;
    isPreviewMode: boolean;
}


const dialogBox = container.get<IDialogBox>(TYPES.IDialogBox);

export class SelectSignMethod extends React.Component<SelectSignMethodProps, {}>
{
    constructor(props: any) {
        super(props);
        this.props.clearParentState();
    }

    componentDidMount() {
        const param: any = this.props.match.params;
        logger.trackPageView('Delegatee Select Sign Method Page', { "ClientId": param.clientId, "PreviewMode": this.props.isPreviewMode });
    }

    private selectSignMethodConfirm = (signatureType: SignatureType) => {
        dialogBox.confirm(
            Common.DialogBox.ConfirmationTitle,
            SiginingConstants.StatusMessage.ManualSignSelectConfirmationMessage,
            Common.DialogBox.No,
            Common.DialogBox.Yes,
            (result: boolean) => { result && this.setSignMethod(signatureType) });
    }

    private setSignMethod = (signatureType: SignatureType) => {

        const { onSetSignMethod } = this.props;
        onSetSignMethod(signatureType);
    }

    render() {

        return (<StepLayout className='select-sign-method-container1'>
            <Body
                title={SiginingConstants.SelectSignTitle}
                subTitle={SiginingConstants.SelectSignMessage}
                lg={10} md={12} sm={12} xs={12}
            >
                <div className='select-sign-method-container2'>
                    <div className='sign-tab'>
                        <CustomLargeButton
                            icon={Common.Images.ElectronicalSignIcon}
                            onClick={() => { this.setSignMethod(SignatureType.ESign) }}
                        />
                        <div className="large-button-footer-text">{SiginingConstants.EsignCaption}</div>
                    </div>

                    <div className='sign-tab'>
                        <CustomLargeButton
                            icon={Common.Images.ManuallySignIcon}
                            onClick={() => { this.selectSignMethodConfirm(SignatureType.ManualSign) }}
                        />
                        <div className="large-button-footer-text">{SiginingConstants.ManuallySignCaption}</div>
                    </div>
                </div>
            </Body>
        </StepLayout>);
    }
}

export default SelectSignMethod;