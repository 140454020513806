
import { AxiosResponse } from 'axios';
import { Action, Reducer } from 'redux';
import { initializeAxios } from '../../core/services/dataAccess/DataService.Axios';
import { actionTypes } from '../ActionTypes';
import { NotificationAction, StatusType } from '../Common/NotificationStore';
import { AppThunkAction } from '../index';
import { SignProcessSteps } from '../../components/Home/TaxpayerSignFlowManager';
import { IClientProcessViewModel, initialClientProcessModel } from '../../core/domain/viewModels/IClientProcessViewModel'
import { ErrorMessages } from '../../components/Common/Constants';
import { SSNPageConstants } from "../../components/Common/Constants";



export enum TaxpayerAuthenticationType {
	None = 0,
	Last4ofSSN = 1,
	First4ofSSN = 2
}


export enum ClientTypes {
	Undefied = 0,
	Taxpayer = 1,
	Spouse = 2,
	Partner = 3,
	PartnerShip = 4,
	ShareHolder = 5,
	CcRecipient = 6,
	Delegatee = 7,
	Controller = 8
}

export enum LinkValidationResult {
	None,
	Success,
	LockedBySSN,
	LockedByOTP,
}


export interface ISSNAuthModel {
	taxpayerAuthentication: TaxpayerAuthenticationType | null,
	clientType: ClientTypes | null,
	clientName: any,
	linkValidationResult: LinkValidationResult | null,
	SSN: any,
	data: any,
	ReturnUrl: string,
	RedirectUrl: string,
	loading: boolean
}

export const SSNData: ISSNAuthModel = {

	taxpayerAuthentication: null,
	clientType: null,
	clientName: "",
	linkValidationResult: null,
	SSN: "",
	ReturnUrl: "",
	data: "",
	RedirectUrl: "",
	loading: false
}



interface RequestSignProcessAction {
	type: actionTypes.SIGN_PROCESS_REQUEST;
}
interface RequestCurrentStepAction {
	type: actionTypes.SIGN_PROCESS_STEP;
	data: IClientProcessViewModel;
}


interface ResponseSignProcessAction {
	type: actionTypes.SIGN_PROCESS_RESPONSE;
	data: number;
}

interface FailureSignProcessAction {
	type: actionTypes.SIGN_PROCESS_FAILURE;
	data: number;
}

interface UpdateLastVisitedStep {
	type: actionTypes.UPDATE_LAST_VISITED_PROCESS_STEP;
	data: SignProcessSteps;
}

export interface SignProcessState {
	data: number;
	clientprocessmodel: IClientProcessViewModel;
}

export const initialSignProcessState: SignProcessState = {
	data: 0,
	clientprocessmodel: initialClientProcessModel
}

interface SSNState {
	type: actionTypes.RECEIVE_SSN_DETAILS,
	data: typeof SSNData
}

type KnownAction =
	DispatchAction |
	NotificationAction;

type DispatchAction =
	ResponseSignProcessAction
	| RequestSignProcessAction
	| FailureSignProcessAction
	| RequestCurrentStepAction
	| UpdateLastVisitedStep
	| SSNState



interface IClientResponse {
	IsSuccess: boolean,
	ErrorCode: string,
	ErrorDescription: string,
	Data: any
}




 
export const actionCreators = {
	requestSSNDataGroup: (clientId?: string): AppThunkAction<any> => (dispatch, getState) => {
		dispatch({ type: actionTypes.FETCH_GROUP_SSN_DETAILS })
		return initializeAxios().get<any>("api/Coverpage/SSN/" + clientId)
			.then(function (response: AxiosResponse<any>) {

				dispatch({
					type: actionTypes.RECEIVE_GROUP_SSN_DETAILS,
					data: response.data
				});
			})
			.catch((e: any) => {
				console.log("got a error");

				//error specification is left here

				dispatch({
					type: actionTypes.NOTIFICATION,
					statusMessage: "Unable to fetch client information",
					statusType: StatusType.Error
				})
			}
			);


	},
	verifySSNGroup: (SSN: string, clientId: string, navigatePath: (target: string) => void): AppThunkAction<any> => (dispatch, getState) => {
		let state = getState().SSNPageReducer;

		let tempData = {
			TaxpayerAuthentication: state.taxpayerAuthentication,
			ClientType: state.clientType,
			ClientName: state.clientName,
			LinkValidationResult: state.linkValidationResult,
			SSN: SSN,
			ReturnUrl: state.ReturnUrl,
			Data: state.data,
			RedirectUrl: state.RedirectUrl
		}


		return initializeAxios().postJson<any>(tempData, 'api/Coverpage/SSN/Validate/' + clientId)
			.then(function (response: any) {
				console.log("got data from SSN", response.data);

				if (response.data.isSuccess) {

					if (response.data.data === "OTP") {
						navigatePath("OTP");
					}
					else {
						navigatePath("MobileOTP");
					}
				}
				else {
					let errorCode = response.data.errorCode;
					let errorDes = response.data.errorDescription
					if (errorCode === "ERROR_INVALID_SSN") {
						dispatch({
							type: actionTypes.NOTIFICATION,
							statusMessage: errorDes,
							statusType: StatusType.Error
						})

					}
					else if (errorCode === "OTP_LOCKED") {
						dispatch({
							type: actionTypes.NOTIFICATION,
							statusMessage: errorDes,
							statusType: StatusType.Error
						})

					}
				}
			})
			.catch(function (error: any) {
				dispatch({
					type: actionTypes.NOTIFICATION,
					statusMessage: SSNPageConstants.ErrorMessage.serverError,
					statusType: StatusType.Error
				})
				console.log("got error from SSN");

			});





	}
}

export const reducer: Reducer<typeof SSNData> = (state: typeof SSNData = SSNData, incomingAction: Action) => {
	const action = incomingAction as any;
	const currentState = Object.assign({}, state);
	console.log("in reducers", action);
	switch (action.type) {
		case actionTypes.FETCH_GROUP_SSN_DETAILS:
			return { ...currentState, loading: true }
		case actionTypes.RECEIVE_GROUP_SSN_DETAILS:
			let data = action.data;
			/*add the data getting back from the api*/
			currentState.taxpayerAuthentication = data.taxpayerAuthentication;
			currentState.clientType = data.clientType;
			currentState.clientName = data.clientName;
			currentState.linkValidationResult = data.linkValidationResult;
			currentState.SSN = data.SSN;
			currentState.data = data.data;
			currentState.RedirectUrl = data.RedirectUrl;
			currentState.loading = false;


			return { ...currentState };
		default:
			return currentState || SSNData;
	}
}; 