import { ApplicationState } from "../../store/index";
import { ScreenShare } from "./ScreenShare";
import { connect } from 'react-redux';
import { actionCreators as helperAction } from '../../store/Common/HelperStore';


function mapStateToProps(state: ApplicationState) {
    return {
        iceServers: state.commonData.iceServers,
        webRTCSignalRHubBaseURL: state.commonData.webRTCSignalRHubBaseURL,
    };
}

const mapDispatchToProps = (dispatch: any) => {
    return {
        getIceServers: (clientId: string) => dispatch(helperAction.getIceServers(clientId)),
        getWebRTCSignalRHubBaseURL: (clientId: string, callback: (script: string) => void) => dispatch(helperAction.getWebRTCSignalRHubBaseURL(clientId, callback)),
        dispatch
    }
}


const connector = connect(mapStateToProps, mapDispatchToProps);

export default connector(ScreenShare);