import * as React from "react";
import { ErrorState } from "../../store/Common/ErrorStore";
import { match } from "react-router";
import { CoverPageConstants } from "../Common/Constants";
import { SvgIconReturnDeclined, SvgIconReturnLocked } from "./Icons/BrandIcons";
import Footer from "../Layout/Footer";

interface ErrorPageState {}

interface ErrorPageProps {
    ErrorMessage: ErrorState;
    match: match;
}

export class ErrorPage extends React.Component<ErrorPageProps, ErrorPageState> {
    constructor(props: ErrorPageProps, states: ErrorPageState) {
        super(props, states);
    }

    public render() {
        let param: any = this.props.match.params;
        return (
            <div>
                <div className="invalid-container">
                    <div className="invalid-header"></div>
                    <div className="content-wrapper layout-container">
                        <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 error-page d-flex justify-content-center align-items-center">
                            <div className="row error-page-container ">
                                <div className="col">
                                    <div className="row justify-content-center">
                                        {typeof this.props.ErrorMessage?.message === "string" &&
                                        this.props.ErrorMessage.message.includes("declined signing") ? (
                                            <SvgIconReturnDeclined />
                                        ) : (
                                            <SvgIconReturnLocked />
                                        )}
                                    </div>
                                    <div className="row justify-content-center margin-top-50">
                                        <div className="message text-center" style={{ width: 480 }}>
                                            {this.props.ErrorMessage.message ||
                                                param.message ||
                                                CoverPageConstants.InvalidLink}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Footer />
                </div>
            </div>
        );
    }
}
