import * as React from "react";
import HeaderComp from "../Header/HeaderComp";
import Skeleton from "react-loading-skeleton";
import { EngagementTypes } from "../../store/CoverPage/CoverPageStore";
import { EngagementType } from "../../core/common/Enums";
import TaxpayerHelper from "../Helper/TaxpayerHelper";
import Footer from "../Layout/Footer";
import { ButtonComponent } from "cp-common-ui-components";

export class CoverPage extends React.Component<any, any> {
  constructor(props: any) {
    super(props);
    this.state = {
      textColor: "white",
      bgColor: "green",
    };

    this.navigate = this.navigate.bind(this);
    this.setColor = this.setColor.bind(this);
  }

  componentDidMount() {
    if (TaxpayerHelper.isTrialView()) {
      this.props.requestTrialDocumentId(
        this.props.match.params.clientId,
        this.clearTrialInterval
      );
      this.setState({ isTrialView: true });
      this.setTrialInterval();
    } else {
      this.props.requestCoverClientData(
        this.props.match.params.clientId,
        this.goToErrorPage
      );
      this.props.requestHeaderDetails(this.props.match.params.clientId);
    }
  }

  componentDidUpdate() {
    console.log("in did update", this.props.headerInfoState);
  }

  setTrialInterval = () => {
    const intervalId = setInterval(() => {
      this.props.requestTrialDocumentId(
        this.props.match.params.clientId,
        this.clearTrialInterval
      );
    }, 5000);
    this.setState({ intervalId: intervalId });
  };

  clearTrialInterval = (documentId: number) => {
    if (documentId > 0) {
      this.props.requestCoverClientData(
        this.props.match.params.clientId,
        this.goToErrorPage
      );
      this.props.requestHeaderDetails(this.props.match.params.clientId);
    }

    clearInterval(this.state.intervalId);
  };

  trialLogin = (guid: string) => {
    console.log("in trial login");
    if (this.state.isTrialView) {
      this.props.history.push(`/Taxpayerflow/${guid}`);
    } else {
      this.goToErrorPage();
    }
  };

  goToErrorPage = () => {
    this.props.history.push("/Error");
  };

  setColor(bgColor: string, textColor: string) {
    this.setState({ textColor: textColor, bgColor: bgColor });
  }

  navigate() {
    console.log("props value", this.props);
    let clientId: string = this.props.match.params.clientId;
    if (this.state.isTrialView) {
      this.props.trialLogin(
        this.props.match.params.clientId,
        this.trialLogin,
        this.goToErrorPage
      );
    }
    if (
      this.props.initialCoverState.engagementType === EngagementTypes.E1040 ||
      this.props.initialCoverState.engagementType === EngagementType.E1040NR
    ) {
      this.props.history.push(`/Authentication/SSN/${clientId}`);

      if (this.props.initialCoverState.mobileVerification) {
        this.props.setMobileNo(
          this.props.initialCoverState.mobileVerification.mobileNumber,
          this.props.initialCoverState.mobileVerification.countryCode
        );
      }
    } else {
      let mfaSettings = this.props.initialCoverState.mfaSettings;
      if (
        mfaSettings.iSTPMfaEnabled === true &&
        this.props.initialCoverState.mobileVerification
          ? this.props.initialCoverState.mobileVerification.mobileNumber
          : false && this.props.initialCoverState.mobileVerification
          ? this.props.initialCoverState.mobileVerification.countryCode
          : false
      ) {
        this.props.setMobileNo(
          this.props.initialCoverState.mobileVerification.mobileNumber,
          this.props.initialCoverState.mobileVerification.countryCode
        );
        this.props.history.push(`/MobileOTP/Index/${clientId}`);
      } else {
        this.props.history.push(`/OTP/Index/${clientId}`);
      }
    }
  }

  render() {
    let coverBorderColor = this.state.bgColor;
    let textColorCode = this.state.textColor;

    if (this.props.headerInfoState.brandingSettings) {
      coverBorderColor =
        this.props.headerInfoState.brandingSettings.coverPageSetting
          .bgColorCode;
      textColorCode =
        this.props.headerInfoState.brandingSettings.coverPageSetting
          .foreColorCode;
    }

    return (
      <>
        <>
          <HeaderComp
            headerInfo={this.props.headerInfoState}
            pathname={this.props.match.path}
            clientId={this.props.match.params.clientId}
            history={this.props.history}
            backButton={false}
            navigateLink=""
          />
          {this.props.initialCoverState.loaderState === true ? (
            <Skeleton circle={false} height={"100vh"} width={"100vw"} />
          ) : (
            <div className="layout-container welcome-page d-flex align-items-center justify-content-center">
              <div className="row">
                <div className="col-lg-6 col-md-6 col-sm-6">
                  <div className="welcome-text text-left">
                    <div style={{ color: "#0973BA" }}>Hello again,</div>
                    <div>It's tax season.</div>
                  </div>
                  <div className="client-details-header text-left">
                    <div className="tax-year-header"
                    data-test-auto="JY3NUDQEG0D471NH0XPU52468D">
                      {this.props.initialCoverState.taxYear} Tax Return
                    </div>
                    <div className="client-name-header"
                    data-test-auto="W4GRMP71REY4Z1DV1YG7L6XDLH">
                      {this.props.initialCoverState.clientName}
                    </div>
                  </div>

                  <div className="margin-top-50">
                    <ButtonComponent
                      data-test-auto="NYG6C9FLML943538RXC2D9XDW8"
                      size="medium"
                      buttonClassName="get-started-button"
                      variant="primary"
                      onClick={this.navigate}
                    >
                      Get Started
                    </ButtonComponent>
                  </div>

                  <div className="margin-top-50">
                    <div>
                      <div className="prepared-by-title text-left"
                      data-test-auto="DKL160X9V0A45ATKDN4REGDFE0">
                        Prepared by
                      </div>
                    </div>
                    <div>
                      {this.props.initialCoverState.logoPath.length !== 0 ? (
                        <img
                          className="prepared-by-img margin-top-10 float-left"
                          data-test-auto="VM1Y507X3KAMT985AC3UCPACMW"
                          src={this.props.initialCoverState.logoPath}
                          alt="company-logo"
                        />
                      ) : (
                        <div className="prepared-by-company-name margin-top-10 text-left"
                        data-test-auto="R3YCTEA0QLM4T6Z3495P4NJ1DR">
                          {this.props.initialCoverState.companyName || this.props.initialCoverState.contactAddress?.name}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 col-md-6 col-sm-6">
                  <img
                    style={{
                      alignSelf: "center",
                      height: 400,
                      width: 400,
                      padding: 0,
                    }}
                    src={
                      require("../../assets/images/WelcomeScreenIllustration.svg")
                        .default
                    }
                    alt="welcome-screen-logo"
                  />
                </div>
              </div>
            </div>
          )}
          <Footer />
        </>
      </>
    );
  }
}
export default CoverPage;
