import { ITaxReturn } from "../../core/domain/models/ITaxReturn";
import { History } from "history";
import * as React from "react";
import { match } from "react-router";
import { ISignedDocument } from "../../../src/core/domain/models/manualsign/SignedDocument";
import { IDownloadableDocumentsViewModel } from "../../../src/core/domain/viewModels/IDownloadableDocumentsViewModel";
import { EstimatedVoucher } from "../../components/Summary/Main/EstimatedVoucher";
import { LeftBar } from "../../components/Summary/Main/LeftBar";
import { Main } from "../../components/Summary/Main/Main";
import { PaymentAndRefund } from "../../components/Summary/Main/PaymentAndRefund";
import { Role } from "../../core/common/Enums";
import { IClientProcessViewModel } from "../../core/domain/viewModels/IClientProcessViewModel";
import { ICommonDataViewModel } from "../../core/domain/viewModels/ICommonDataViewModel";
import { IHeaderInfoViewModel } from "../../core/domain/viewModels/IHeaderInfoViewModel";
import { ISummaryViewModel, getEstimatedVouchers } from "../../core/domain/viewModels/ISummaryViewModel";
import TaxpayerHelper from "../Helper/TaxpayerHelper";
import { SignProcessSteps } from "../Home/TaxpayerSignFlowManager";
import { IPaymentTransactionModel } from "../../../src/core/domain/models/IPaymentTransactionModel";
import { ICompany } from "../../../src/core/domain/models/company/Company";
import { CompletedWelcomePage } from "./CompletedWelcomePage";
import {
  IDelegateeSignerDetails,
  IDelegateeInfo,
} from "../../core/domain/models/IDelegateeSignerModel";
import { IInvoicePayment } from "../../core/domain/models/InvoicePaymentModel";

export interface CompletedSummaryPageProps {
  summaryData: ISummaryViewModel;
  commonData: ICommonDataViewModel;
  requestSummaryDetails: (clientId: string) => any;
  match: match;
  history: History;
  taxReturn: ITaxReturn;
  requestSignerDetails: (clientId: string) => any;
  sendSigningReminder: (clientId: string) => any;
  downloadSignedEFileDocument: (clientId: string, fileName: string) => any;
  signedDocument: ISignedDocument[];
  downloadAllAdditionEsignDocuments: (
    clientId: string,
    fileName: string
  ) => any;
  downloadableDocumentsViewModel: IDownloadableDocumentsViewModel;
  requestDownloadableDocuments: (clientId: string) => any;
  refreshTaxDocument: (
    clientId: string,
    callback?: (data?: any) => void
  ) => any;
  requestSignedDocuments: (clientGuid: string, forceRefresh?: boolean) => any;
  downloadEFileDocument: (clientId: string, fileName: string) => any;
  clientProcessModel: IClientProcessViewModel;
  requestCurrentStepAndUserRole: (
    clientId: string,
    successCallback?: (clientProcessState: IClientProcessViewModel) => void
  ) => any;
  headerInfo: IHeaderInfoViewModel;
  updateDelegateeDetails: (
    clientId: string,
    delegatee: IDelegateeSignerDetails,
    callback: () => void
  ) => void;
  cancelDelegation: (
    clientId: string,
    remarks: string,
    taxYear: number,
    callback: () => void
  ) => void;
  resetLastVisitedSteps: (clientId: string, callback: () => void) => void;
  delegateeSigner: IDelegateeInfo;
  requestTaxClientDelegateeDetails: (clientId: string) => void;
  downloadTaxInvoice: (clientId: string) => void;
  paymentData: IPaymentTransactionModel;
  companyData: ICompany;
  requestPaymentIntent: (
    clientId: string,
    amount: number,
    callback: () => void
  ) => void;
  updatePurchaseTransactionStatus: (
    clientId: string,
    paymentTransaction: IPaymentTransactionModel,
    callback?: () => void
  ) => void;
  invoicePaymentUrl?: string;
  invoicePaymentData: IInvoicePayment;
  addInvoicePayment: (
    clientId: string,
    invoice: IInvoicePayment,
    callback?: () => void
  ) => void;
  updateInvoicePayment: (
    clientId: string,
    invoice: IInvoicePayment,
    callback?: () => void
  ) => void;
  deleteInvoicePayment: (clientId: string, callback?: () => void) => void;
  getClientHubDomainURL: (clientGuid: string, callback: (hubDomainURL: string) => void) => void;
}

export interface CompletedSummaryPageState {
  role: Role;
  isDelegateeAssigned: boolean;
  isPreviewMode: boolean;
  isSignCompleted: boolean;
  stepInfo: IClientProcessViewModel;
  card1Open: boolean;
  card2Open: boolean;
}

export class CompletedSummaryPage extends React.Component<
  CompletedSummaryPageProps,
  CompletedSummaryPageState
> {
  private _params: any;

  constructor(props: CompletedSummaryPageProps) {
    super(props);
    this.state = {
      role: Role.None,
      isDelegateeAssigned: false,
      isPreviewMode: false,
      isSignCompleted: false,
      stepInfo: this.props.clientProcessModel,
      card1Open: true,
      card2Open: false,
    };
  }

  componentDidMount() {
    const param: any = this.props.match.params;

    this.getSignProcessStatus(param.clientId);

    if (param.clientId && this.props.summaryData.documentId === 0) {
      this.props.requestSummaryDetails(param.clientId);
    }
    this.props.requestSignerDetails(param.clientId);
    this.props.requestDownloadableDocuments(param.clientId);
    this.props.refreshTaxDocument(param.clientId);
    this.props.requestSignedDocuments(param.clientId, true);
  }

  private getSignProcessStatus = (clientId: any) => {
    this.props.requestCurrentStepAndUserRole(clientId, this.handleRedirect);
  };

  private isLinkVisited(
    step: SignProcessSteps,
    clientProcessState: IClientProcessViewModel
  ): boolean | undefined {
    const NO_INDEX = -1;
    let index = NO_INDEX;
    index = clientProcessState.currentstep.visitedSteps
      ? clientProcessState.currentstep.visitedSteps.indexOf(step)
      : NO_INDEX;
    return index !== null && index !== NO_INDEX;
  }

  private handleRedirect = (clientProcessState: IClientProcessViewModel) => {
    this._params = this.props.match.params;
    if (
      !this.isLinkVisited(
        SignProcessSteps.SignerProcessComplete,
        clientProcessState
      )
    ) {
      const url = TaxpayerHelper.getSignCompletedRedirectUrl(
        clientProcessState,
        this._params.clientId
      );
      if (url && url.trim()) {
        this.props.history.push(url);
      }
    }

    if (clientProcessState.isDelegated) {
      this.props.requestTaxClientDelegateeDetails(this._params.clientId);
    }

    this.setState({
      role: clientProcessState.role,
      isDelegateeAssigned: clientProcessState.isDelegated,
      isSignCompleted: clientProcessState.isSigned,
      isPreviewMode: TaxpayerHelper.isPreviewMode(clientProcessState),
      stepInfo: clientProcessState,
    });
  };

  private saveInvoicePayment = (
    invoice: IInvoicePayment,
    callback?: () => void
  ) => {
    const param: any = this.props.match.params;
    if (invoice !== null && invoice !== undefined) {
      if (!invoice.isPaid) {
        this.props.addInvoicePayment(param.clientId, invoice, callback);
      } else {
        this.props.updateInvoicePayment(param.clientId, invoice, callback);
      }
    }
  };

  private clearAllInvoicePayment = (callback?: () => void) => {
    const param: any = this.props.match.params;
    this.props.deleteInvoicePayment(param.clientId, callback);
  };

  private toggleCards = (cardNumber: number) => {
    if (cardNumber === 1) {
        this.setState({ card1Open: !this.state.card1Open }, () => {
            this.setState({ card2Open: this.state.card1Open ? false : true });
        });
    } else if (cardNumber === 2) {
        this.setState({ card2Open: !this.state.card2Open }, () => {
            this.setState({ card1Open: this.state.card2Open ? false : true });
        });
    }
  }

  public render() {
    const estimatedVouchers = getEstimatedVouchers(this.props.summaryData.vouchers, this.props.commonData.taxingAuthorities);

    return (
      <div
        id="summary-review"
        className="row width-100 completed-summary-step-container"
      >
        <LeftBar>
          <PaymentAndRefund
            taxYear={this.props.summaryData.taxYear}
            addedRefunds={this.props.summaryData.addedRefunds}
            vouchers={this.props.summaryData.vouchers}
            refunds={this.props.summaryData.refunds}
            taxingAuthorities={this.props.commonData.taxingAuthorities}
            showPaymentRefundPrint={false}
            headerInfo={this.props.headerInfo}
            card1Open={this.state.card1Open}
            toggleCards={this.toggleCards}
            isEstimatedVouchersEmpty={estimatedVouchers.length <= 0}
          />
          {
            estimatedVouchers.length > 0 ?
            <EstimatedVoucher
              taxYear={this.props.summaryData.taxYear}
              vouchers={this.props.summaryData.vouchers}
              taxingAuthorities={this.props.commonData.taxingAuthorities}
              card2Open={this.state.card2Open}
              toggleCards={this.toggleCards}
            /> :
            <></>
          }
        </LeftBar>

        <Main>
          <CompletedWelcomePage
            clientName={this.props.summaryData.clientName}
            hasShareholders={this.props.summaryData.hasShareholders}
            hasPaperFiles={this.props.summaryData.hasPaperFiles}
            taxYear={this.props.summaryData.taxYear}
            clientType={this.props.taxReturn.clientType}
            taxReturn={this.props.taxReturn}
            param={this.props.match.params}
            sendSigningReminder={this.props.sendSigningReminder}
            history={this.props.history}
            downloadSignedEFileDocument={this.props.downloadSignedEFileDocument}
            signedDocument={this.props.signedDocument}
            downloadAllAdditionEsignDocuments={
              this.props.downloadAllAdditionEsignDocuments
            }
            downloadableDocumentsViewModel={
              this.props.downloadableDocumentsViewModel
            }
            downloadEFileDocument={this.props.downloadEFileDocument}
            role={this.state.role}
            isPreviewMode={this.state.isPreviewMode}
            updateDelegateeSignerDetails={this.props.updateDelegateeDetails}
            isDelegateeAssigned={this.state.isDelegateeAssigned}
            isSignCompleted={this.state.isSignCompleted}
            cancelDelegation={this.props.cancelDelegation}
            resetLastVisitedSteps={this.props.resetLastVisitedSteps}
            delegateeSigner={this.props.delegateeSigner}
            taxingAuthorities={this.props.commonData.taxingAuthorities}
            invoicePaymentUrl={this.props.invoicePaymentUrl}
            downloadTaxInvoice={this.props.downloadTaxInvoice}
            paymentData={this.props.paymentData}
            companyData={this.props.companyData}
            requestPaymentIntent={this.props.requestPaymentIntent}
            updatePurchaseTransactionStatus={
              this.props.updatePurchaseTransactionStatus
            }
            invoicePaymentData={this.props.invoicePaymentData}
            saveInvoicePayment={this.saveInvoicePayment}
            clearAllInvoicePayment={this.clearAllInvoicePayment}
            stepInfo={this.state.stepInfo}
            refreshTaxDocument={this.props.refreshTaxDocument}
            getClientHubDomainURL={this.props.getClientHubDomainURL}
          />
        </Main>
      </div>
    );
  }
}
