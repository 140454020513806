import * as React from "react";
import DatePicker, { ReactDatePickerProps } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "react-datepicker/dist/react-datepicker-cssmodules.css";
import moment from "moment";

interface CustomDateFilterProps extends ReactDatePickerProps {
  filterHandler: (value?: string, type?: "CustomFilter") => void;
}

interface CustomDateFilterState {
  selectedDate?: Date | null;
}

export class CustomDateFilter extends React.Component<
  CustomDateFilterProps,
  CustomDateFilterState
> {
  constructor(props: any) {
    super(props);
    this.state = { selectedDate: undefined };
  }

  public applyFilter = (value: string) => {
    this.setState(
      {
        selectedDate: new Date(value),
      },
      () => {
        this.props.filterHandler(
          moment(this.state.selectedDate).format("MM/DD/YYYY")
        );
      }
    );
  };

  cleanFiltered() {
    this.setState(
      {
        selectedDate: undefined,
      },
      () => {
        this.props.filterHandler();
      }
    );
  }

  private onStatusChange = (
    date: Date | null,
    event?: React.SyntheticEvent<any, Event>
  ) => {
    this.state.selectedDate != date &&
      this.setState({ selectedDate: date }, () => {
        this.props.filterHandler(
          date ? moment(date).format("MM/DD/YYYY") : "",
          "CustomFilter"
        );
      });
    this.props.onChange(date, event);
  };

  public render() {
    return (
      <div>
        <DatePicker
          data-test-auto="E0370673-1761-4DE1-8A4E-837482D74D79"
          onChange={(date, event) => this.onStatusChange(date as Date, event)}
          selected={this.state.selectedDate}
          placeholderText={this.props.placeholderText ?? "MM/DD/YYYY"}
          className="form-control input-fontSize filter"
          isClearable={true}
          minDate={new Date()}
          calendarClassName={this.props.calendarClassName}
        />
      </div>
    );
  }
}
