import { ApplicationState } from "../../../store/index";
import { connect } from 'react-redux';
import { actionCreators as K1DistributeStore } from '../../../store/K1/K1DistributeStore';
import { IK1ShareHolderDetails } from '../../../core/domain/models/IK1Distribute';
import { ManualDistribution } from "./ManualDistribution";
import { actionCreators as TaxDocumentStore } from '../../../store/Common/TaxDocumentStore';
import { EngagementTypeS } from "../../../core/common/Enums";
import { actionCreators as SignProcessStore } from '../../../store/SignProcess/SignProcessStore';
import { IClientProcessViewModel } from '../../../core/domain/viewModels/IClientProcessViewModel';
import { actionCreators as DownloadableDocumentsStore }  from '../../../store/DownloadableDocumentsStore';
import { actionCreators as TrialK1DistributeStore } from '../../../store/K1/TrialK1DistributeStore';

function mapStateToProps(state: ApplicationState) {
    return {
        k1Data: state.k1Data.data,
        companyData: state.companyData.data,
        commonData: state.commonData,
        headInfoViewModel: state.headerInfo,
        taxReturn: state.taxReturn.taxDocument,
        isK1Loading: state.k1Data.isLoading
    };
}
//temperroy fix for webpack failure need to invetigate further when we have time
const mapDispatchToProps = (dispatch: any) => {
    return {
        requestTaxDocument: (clientGuid: string) => dispatch(TaxDocumentStore.requestTaxDocument(clientGuid)),
        requestK1ShareHolderDetails: (clientGuid: string, pageNo: number, pageSize: number, sortBy: string, sortOrder: string, searchText: string, successCallback?:()=> void) => dispatch(K1DistributeStore.requestK1ShareHolderDetails(clientGuid, pageNo, pageSize, sortBy, sortOrder, searchText, successCallback)),
        updateK1ShareHolderEmail: (clientGuid: string, k1Partner: IK1ShareHolderDetails, successCallback?: () => void) => dispatch(K1DistributeStore.updateK1ShareHolderEmail(clientGuid, k1Partner, successCallback)),

        updateK1ShareHolderDetail: (clientGuid: string, k1Partner: IK1ShareHolderDetails, isEmailChange: boolean, isPreview: boolean, callBack?: () => void) =>
            dispatch(K1DistributeStore.updateK1ShareHolderDetail(clientGuid, k1Partner, isEmailChange, isPreview, callBack)),

        downloadK1DocumentAsync: (clientId: string, engagementTpe: EngagementTypeS, callback?:()=> void) => dispatch(K1DistributeStore.downloadK1DocumentAsync(clientId, engagementTpe, callback)),
        downloadK1DocumentForPreviewAsync: (clientId: string, engagementTpe: EngagementTypeS, callback?:()=> void) => dispatch(K1DistributeStore.downloadK1DocumentForPreviewAsync(clientId, engagementTpe, callback)),    
        downloadIndividualK1DocumentAsync: (clientId: string, shareHolder: IK1ShareHolderDetails, engagementTpe: EngagementTypeS) => dispatch(K1DistributeStore.downloadIndividualK1DocumentAsync(clientId, shareHolder, engagementTpe)),
        sendMailToShareHolder: (clientId: string, partners: IK1ShareHolderDetails[], callback: () => void) => dispatch(K1DistributeStore.sendMailToShareHolder(clientId, partners, callback)),
        downloadSelectedK1DocumentAsync: (clientId: string, shareHolders: IK1ShareHolderDetails[], engagementTpe: EngagementTypeS, callback?:()=>void) => dispatch(K1DistributeStore.downloadSelectedK1DocumentAsync(clientId, shareHolders, engagementTpe,callback)),
        downloadSelectedK1DocumentForPreviewAsync: (clientId: string, shareHolders: IK1ShareHolderDetails[], engagementTpe: EngagementTypeS, callback?:()=>void) => dispatch(K1DistributeStore.downloadSelectedK1DocumentForPreviewAsync(clientId, shareHolders, engagementTpe, callback)),
        requestCurrentStepAndUserRole: (clientGuid: string, successCallback?: (clientProcessState: IClientProcessViewModel) => void) => dispatch(SignProcessStore.requestCurrentStepAndUserRole(clientGuid, successCallback)),
        requestMyDownload: (clientGuid: string) => dispatch(DownloadableDocumentsStore.requestMyDownload(clientGuid)),
        
        downloadAllK1DocumentsAsync: (clientId: string, engagementTpe: EngagementTypeS, callback?:()=> void) => 
            dispatch(TrialK1DistributeStore.downloadAllK1DocumentsAsync(clientId, engagementTpe, callback)),
        
        downloadBulkSelectedK1DocumentAsync: (clientId: string, shareHolders: IK1ShareHolderDetails[], engagementTpe: EngagementTypeS, callback?:()=>void) =>
            dispatch(K1DistributeStore.downloadBulkSelectedK1DocumentAsync(clientId, shareHolders, engagementTpe, callback)),

        downloadBulkSelectedK1DocumentForPreviewAsync: (clientId: string, shareHolders: IK1ShareHolderDetails[], engagementTpe: EngagementTypeS, callback?:()=>void) => 
            dispatch(K1DistributeStore.downloadBulkSelectedK1DocumentForPreviewAsync(clientId, shareHolders, engagementTpe, callback)),
    }
}


const connector = connect(mapStateToProps, mapDispatchToProps);

export default connector(ManualDistribution);



