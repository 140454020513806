import { ApplicationState } from "../../store/index";
import { connect } from 'react-redux';
import { actionCreators as ManualSignStore } from '../../store/Sign/ManualSignStore';
import { actionCreators as EsignStore } from '../../store/Common/EsignStore';
import { ManualSignReviewDocuments } from './ReviewManualSignDocuments';
import { actionCreators as DownloadableDocumentsStore } from '../../store/DownloadableDocumentsStore';

const mapStateToProps = (state: ApplicationState) => {
    return {
        signatureControlsData: state.esignData.controlsData,
        headerInfo: state.headerInfo,
        taxReturn: state.taxReturn.taxDocument,
        signerData: state.esignData.signerDetails,
        downloadableDocumentsViewModel: state.downloadableDocuments,
        summaryData: state.summaryData,
    };
}

const mapDispatchToProps = (dispatch: any) => {
    return {
        requestPreviewSignatureControls: (clientGuid: string) => dispatch(EsignStore.requestPreviewSignatureControls(clientGuid)),

        downloadEfileForms: (clientGuid: string, successCallback?: () => void) =>
            dispatch(ManualSignStore.downloadEfileForms(clientGuid, successCallback)),

        requestAdditionalEsignDocument: (clientId: string) =>
            dispatch(DownloadableDocumentsStore.requestAdditionalEsignDocument(clientId)),

        downloadAllAdditionEsignDocuments: (clientId: string, fileName: string) =>
            dispatch(DownloadableDocumentsStore.downloadAllAdditionEsignDocuments(clientId, fileName)),

        dispatch
    }
}


const connector = connect(mapStateToProps, mapDispatchToProps);

export default connector(ManualSignReviewDocuments);



