import React, { useEffect, useRef, useState } from "react";
import { Editor } from "@tinymce/tinymce-react";
var htmlencode = require("htmlencode");

interface TinyMCEComponentProps {
    messageBody: string;
    changeStateTinymceBody: (event: any) => void;
    readOnly: number;
    placeholder?: string;
    id?: string;
}

const TinyMCEComponent: React.FC<TinyMCEComponentProps> = (props: TinyMCEComponentProps) => {
    const { messageBody, changeStateTinymceBody, readOnly, placeholder, id } = props;

    const editorRef: any = useRef(null);
    const [inputText, setInputText] = useState("");

    useEffect(() => {
        setInputText(messageBody);
    }, []);

    return (
        <>
            <Editor
                id={id}
                tinymceScriptSrc={process.env.PUBLIC_URL + "/tinymce/tinymce.min.js"}
                onInit={(evt, editor) => (editorRef.current = editor)}
                initialValue={htmlencode.htmlDecode(inputText)}
                onEditorChange={changeStateTinymceBody}
                init={{
                    height: 200,
                    branding: false,
                    promotion: false,
                    statusbar: false,
                    menubar: "edit format",
                    menu: {
                        edit: { title: "Edit", items: "undo redo | cut copy paste | selectall" },
                        format: { title: "Format", items: "bold italic underline | blocks align " }
                    },
                    toolbar:
                        "undo redo | bold italic underline | alignleft aligncenter alignright | forecolor backcolor",
                    block_formats:
                        "Paragraph=p;Heading 1=h1;Heading 2=h2;Heading 3=h3;Heading 4=h4;Heading 5=h5;Heading 6=h6",
                    placeholder: placeholder ?? "",
                    content_style: "p { margin: 0; }"
                }}
                disabled={readOnly ? true : false}
            />
        </>
    );
};

export default TinyMCEComponent;