import * as React from 'react';
import { Modal, Button } from 'react-bootstrap';
import { AuthorityImage, StateImages, } from '../Common/Icons/StateImages';
import { CalenderIcon, DollarIcon, CloseIcon } from '../Common/Icons/SvgIcons';
import { ITaxingAuthority } from '../../core/domain/models/ITaxingAuthority';
import { IUtilities } from '../../core/utilities/Utilities';
import { container } from '../../startup/inversify.config';
import { TYPES } from '../../startup/types';
import { IVoucher } from '../../core/domain/models/IGroup';
import Moment from 'moment/moment';
import { CurrencyTextBox } from '../Common/Controls/CurrencyTextBox';
import "react-datepicker/dist/react-datepicker.css";
import CustomDatepicker from '../Common/CustomDatePicker/CustomDatepicker';
import TaxpayerHelper from "../Helper/TaxpayerHelper";
import { handleKeyDown } from '../Helper/HelperFunction';
import { ButtonComponent } from 'cp-common-ui-components';
//const DatePicker = require("react-bootstrap-date-picker");

const utilities = container.get<IUtilities>(TYPES.IUtilities);

export interface IVoucherPaymentInfoPopupProps {
    show: boolean;
    onCancel(): void;
    taxingAuthority: ITaxingAuthority;
    voucher: IVoucher;
    saveVoucherPayment: (voucher: IVoucher) => void;
    clearPaymentDetails: (voucher: IVoucher) => void;
}

interface IVoucherPaymentInfoPopupState {
    amountPaid: number;
    checkNumber: string;
    paidDate: Date;
    isDateChanged: boolean;
}

const DATE_FORMAT: string = "MM/DD/YYYY";
const defaultDate = new Date(1, 1, 1);

export class VoucherPaymentInfoPopup extends React.Component<IVoucherPaymentInfoPopupProps, IVoucherPaymentInfoPopupState> {

    constructor(props: IVoucherPaymentInfoPopupProps) {
        super(props);
        this.state = {
            amountPaid: props.voucher.amountPaid,
            paidDate: Moment(props.voucher.paidDate).format('MM/DD/YYYY') === "01/01/0001" ? defaultDate : new Date(props.voucher.paidDate),
            checkNumber: props.voucher.checkNumber,
            isDateChanged: !(Moment(props.voucher.paidDate).format('MM/DD/YYYY') === "01/01/0001"),
        }
        this.updateValue = this.updateValue.bind(this);
        this.handleAmountChange = this.handleAmountChange.bind(this);
        this.handleDateChange = this.handleDateChange.bind(this);
        this.handleCheckNumberChange = this.handleCheckNumberChange.bind(this);
    }


    componentWillReceiveProps(nextProps: IVoucherPaymentInfoPopupProps) {
        this.props.voucher && this.updateValue(nextProps);
    }

    private updateValue(props: IVoucherPaymentInfoPopupProps) {

        this.setState({
            amountPaid: props.voucher.amountPaid,
            paidDate: new Date(props.voucher.paidDate),
            checkNumber: props.voucher.checkNumber,
            isDateChanged: !(Moment(props.voucher.paidDate).format('MM/DD/YYYY') === "01/01/0001"),
        });
    }

    handleAmountChange = (value: number) => {
        this.setState({ amountPaid: value });
    }

    handleDateChange = (event: any) => {
        this.setState({ paidDate: event, isDateChanged: true });
    }


    handleCheckNumberChange = (event: any) => {
        this.setState({ checkNumber: event.target.value });
    }

    onCancel = () => {
        this.setState({ checkNumber: "", paidDate: defaultDate, amountPaid: 0, isDateChanged: false });
        this.props.onCancel();
    }

    saveVoucherPayementDetails = () => {
        if (this.props.voucher !== null) {

            let tempVoucher = { ...this.props.voucher };
            tempVoucher.amountPaid = this.state.amountPaid;
            tempVoucher.checkNumber = this.state.checkNumber;
            tempVoucher.paidDate = this.state.paidDate;
            this.setState({ checkNumber: "", paidDate: defaultDate, amountPaid: 0, isDateChanged: false });
            this.props.onCancel();
            this.props.saveVoucherPayment(tempVoucher);
        }
    }

    clearVoucherPayementDetails = () => {
        if (this.props.voucher !== null) {
            let tempVoucher = { ...this.props.voucher };
            tempVoucher.amountPaid = 0;
            tempVoucher.checkNumber = "";
            tempVoucher.paidDate = new Date();
            this.setState({ checkNumber: "", paidDate: defaultDate, amountPaid: 0, isDateChanged: false });
            this.props.onCancel();
            this.props.clearPaymentDetails(tempVoucher);

        }

    }
    private renderBody = () => {
        const authorityName = TaxpayerHelper.getAuthorityImageNameByState(this.props.taxingAuthority.AuthorityName);
        return (<>
            <div>
                <div className='edit-payment-header'>
                    <span className='state-info-text'>State Information</span>
                    <span className='flrt'>Required</span>
                    <span className='flrt mandatory'>*</span>
                </div>
                <div className='col-lg-12 paymentdetails-container'>
                    <div className='col-lg-6 no-padding'>
                        <AuthorityImage
                            data-test-auto="629EA225-8B0D-4419-913B-C7A9EBEC061A"
                            authoriyName={authorityName}
                            className="state-shadow"
                            height={99}
						    width={132}
                        />
                    </div>
                    <div className='col-lg-6 no-padding'>
                        <div
                            data-test-auto="9BE1E99E-2850-4434-ABA0-7E86844331AB"
                            className="state-name-text">
                            {this.props.taxingAuthority.AuthorityName == "Federal" ? "Internal Revenue Service" : this.props.taxingAuthority.AuthorityName}
                        </div>
                        <div
                            data-test-auto="7D0CFCE5-AE08-4FF0-8FDD-C9786EF6EF59"
                            className="pay-card-fee-amount">
                            <span>
                                ${utilities.formatCurrencyText(this.props.voucher.amount)}
                            </span>
                        </div>
                        <div
                            data-test-auto="61016C69-4431-46EF-84EA-E78368E33799"
                            className="paydue-date-text">
                            <span>Due: {Moment.utc(this.props.voucher.dueDate).format('MM/DD/YYYY')}</span>
                        </div>
                    </div>
                </div>
            </div>

            <div className='row padT10'>
                <label htmlFor='currencyTextBox' className='col-xl-4 col-md-4 payment-col-label'>
                    Amount Paid<span className="mandatory-star">*</span>
                </label>
                <div className='col-xl-6 col-md-6'>
                    <div className="currency-wrap width150">
                        <span className="currency-code"><DollarIcon /></span>
                        <CurrencyTextBox
                            data-test-auto="0ACC92D1-F44E-4256-BBDC-2C1E21D16B36"
                            value={this.state.amountPaid}
                            onChange={this.handleAmountChange}
                            className='text-currency'
                            disabledDefaultStyle={true}
                        />
                    </div>
                </div>
            </div>
            <div className='row padT10'>
                <div className='col-xl-4 col-md-4 payment-col-label'>
                    <label htmlFor='datePaid'>Date Paid<span className="mandatory-star">*</span></label>
                </div>
                <div className='col-xl-6 col-md-6'>
                    <div className='width150'>
                        <div className='datepicker-input-container'>
                            <CustomDatepicker
                                id={"datePaid"}
                                data-test-auto="A3FA1EBE-72B5-4739-B616-9EE4B4D77484"
                                selectedDate={Moment(this.state.paidDate).format('MM/DD/YYYY') === "01/01/0001" ? null : this.state.paidDate}
                                setSelectedDate={this.handleDateChange}
                                placeholderText='MM/DD/YYYY'
                            />
                            <div className='datepicker-icon'>
                                <CalenderIcon />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='row padT10'>
                <label htmlFor='payment-checknumber' className='col-xl-4 col-md-4 payment-col-label'>
                    Check Number
                </label>
                <div className='col-xl-6 col-md-6'>
                    <input type='text'
                        className="payment-checknumber width150"
                        id='payment-checknumber'
                        value={this.state.checkNumber}
                        onChange={this.handleCheckNumberChange}
                        placeholder="Enter check number"
                        data-test-auto="54678A68-8B5C-4107-9D4D-913E52F498BB">
                    </input>
                </div>
            </div>
        </>)
    };

    public render() {
        const { amountPaid, isDateChanged, paidDate } = this.state;
        const voucherPaidClass = this.props.voucher.isPaid ? 'btn-voucherpaid' : ''
        const enabled =
            amountPaid > 0 &&
            paidDate != null &&
            !(Moment(paidDate).format('MM/DD/YYYY') === "01/01/0001");
        return (<Modal
            className="voucherPayementOverlayPopup"
            show={this.props.show}
            onHide={this.props.onCancel}>
            <Modal.Header className='darkBackground'>
                <Modal.Title>{this.props.voucher.isPaid ? 'Edit Payment Details' : 'Add Payment Details'}</Modal.Title>
                <div
                    data-test-auto="12336D54-417C-4371-947C-38CBA96E5E65"
                    className="closeIcon"
                    onClick={this.props.onCancel}
                    tabIndex={0}
                    onKeyDown={(e: any) => handleKeyDown(e, this.props.onCancel)}
                >
                    <CloseIcon />
                </div>
            </Modal.Header>
            <Modal.Body>
                {this.renderBody()}
            </Modal.Body>
            <Modal.Footer>
                {
                    this.props.voucher.isPaid &&
                        <Button className="btn-goto-payment"
                            data-test-auto="546FCCB2-B309-40B2-BA98-20256EB85CC0"
                            style={{paddingLeft: '0px', marginLeft: '-1px'}}
                            onClick={this.clearVoucherPayementDetails}>
                            <u>Clear Details</u>
                        </Button>
                }
                <ButtonComponent
                    data-test-auto="546FCCB2-B309-40B2-BA98-20256EB85CC0"
                    onClick={this.saveVoucherPayementDetails}
                    disabled={!enabled}
                    buttonClassName={`flrt ${voucherPaidClass}`}
                    variant={"primary"}
                    size={"medium"}
                >
                    {this.props.voucher.isPaid ? 'Edit Payment' : 'Add Payment'}
                </ButtonComponent>

                <ButtonComponent
                    data-test-auto="C2610598-AE33-4512-A6D6-B047FC96147F"
                    onClick={this.onCancel}
                    hidden={false}
                    buttonClassName={`flrt ${voucherPaidClass} button-margin-right`}
                    variant={"outline-tertiary"}
                    size={"medium"}
                >
                    Cancel
                </ButtonComponent>
            </Modal.Footer>
        </Modal>);
    }
}