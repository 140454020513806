import { DocumentSignatureDataViewModel } from '../viewModels/IDocumentSignatureDataViewModel';

export interface ISignerControlDataModal {
    clientGuid: string,
    signature: string,
    documentData: DocumentSignatureDataViewModel[],
    signatureInitial: string;
}

export class SignerControlDataModal implements ISignerControlDataModal {

    clientGuid: string;
    signature: string;
    documentData: DocumentSignatureDataViewModel[];
    signatureInitial: string;

    constructor(clientGuid: string, signature: string, documentData: DocumentSignatureDataViewModel[],signatureInitial:string) {

        this.clientGuid = clientGuid;
        this.signature = signature;
        this.documentData = documentData;
        this.signatureInitial = signatureInitial;
    }


    static create(clientGuid: string, signature: string, documentData: DocumentSignatureDataViewModel[], signatureInitial: string): ISignerControlDataModal {
        return new SignerControlDataModal(clientGuid, signature, documentData, signatureInitial);
    }

    public static createNullObject(): ISignerControlDataModal {
        return new SignerControlDataModal("", "", [], "");
    }
}