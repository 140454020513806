
import { connect } from 'react-redux';
import EmailOTP from "./EmailOTP";
import * as HeaderStore from "../../../store/HeaderStore";
import * as OTPReducer from "../../../store/Delegatee/OTPStore";



export default connect(
    (state: any) => ({
        headerInfoState: state.HeaderPageReducer,
        OTPPageState: state.DelegateeOTPData

    }),
    {
        ...HeaderStore.actionCreators,
        ...OTPReducer.actionCreators
  
    }
)(EmailOTP as any);