import * as React from "react";
import { Modal } from "react-bootstrap";
import { CloseIcon } from "../Common/Icons/SvgIcons";
import "@sssuite-component-ui/react-phone-input-2/lib/style.css";
import PhoneInput, {
  CountryData,
} from "@sssuite-component-ui/react-phone-input-2";
import { ButtonComponent } from "cp-common-ui-components";
import { handleKeyDown } from "../Helper/HelperFunction";

export interface ChangeNumberProps {
  showState: boolean;
  onSaveNumberClick: (number: string, countrycode: string) => void;
  onHideChangeNumberPopUp: () => void;
  mobileNumber: string;
  countryCode: string;
}

interface ChangeNumberState {
  editedMobileNumber: string;
  editedCountryCode: string;
}

interface CountryData {
  name: string;
  dialCode: string;
  countryCode: string;
  format: string;
}

export class ChangeNumber extends React.Component<
  ChangeNumberProps,
  ChangeNumberState
> {
  constructor(props: any) {
    super(props);
    this.state = {
      editedMobileNumber: "",
      editedCountryCode: "",
    };
  }

  private handleMobileChanges = (
    fullValue: string,
    countryObject: CountryData
  ) => {
    this.setState({
      editedMobileNumber: fullValue.slice(countryObject.dialCode.length),
      editedCountryCode: `+${countryObject.dialCode}`,
    });
  };

  onHide = () => {
    this.setState({ editedMobileNumber: this.props.mobileNumber }, () =>
      this.props.onHideChangeNumberPopUp()
    );
    this.setState({ editedCountryCode: this.props.countryCode }, () =>
      this.props.onHideChangeNumberPopUp()
    );
  };

  componentDidUpdate(prevProps: ChangeNumberProps) {
    if (prevProps.mobileNumber != this.props.mobileNumber) {
      this.setState({
        editedMobileNumber: this.props.mobileNumber,
        editedCountryCode: this.props.countryCode,
      });
    }
  }

  public render() {
    return (
      <div>
        <div style={{ position: "relative" }}>
          <Modal
            show={this.props.showState}
            onHide={this.onHide}
            className="myaccount-mobilenumber"
            data-test-auto="3F647974-90BC-47E8-9019-D6BB02CC2601"
          >
            <Modal.Header>
              <Modal.Title>Edit Mobile Number</Modal.Title>
              <div
                data-test-auto="8cc2eef8-27ec-494a-9518-5d52ce8a0891"
                className="closeIcon"
                onClick={this.onHide}
                tabIndex={0}
                onKeyDown={(e: any) => handleKeyDown(e, this.onHide)}
              >
                <CloseIcon />
              </div>
            </Modal.Header>
            <Modal.Body>
              <div className="row marB10">
                <div className="col-lg-12" style={{ display: "inline" }}>
                  <label className="labelMobileNumber">
                    Enter Mobile Number
                  </label>
                  <div style={{ width: "225px" }} className="mobileSection">
                    <PhoneInput
                      containerClass={`inputCCMobile`}
                      country={"us"}
                      autoFormat={true}
                      placeholder="() -"
                      value={`${this.state.editedCountryCode}${this.state.editedMobileNumber}`}
                      onChange={this.handleMobileChanges}
                    />
                  </div>
                </div>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <ButtonComponent
                data-test-auto="E68460D2-1A81-418E-BE28-6E7C1F2E395B"
                id="btnHideChangeNumberPopUp"
                onClick={this.onHide}
                variant={"outline-tertiary"}
                size={"medium"}
              >
                Cancel
              </ButtonComponent>
              <ButtonComponent
                data-test-auto="511110AF-725E-45AD-8CFF-37790FC8B7C1"
                onClick={() => {
                  this.props.onSaveNumberClick(
                    this.state.editedMobileNumber,
                    this.state.editedCountryCode
                  );
                }}
                variant={"primary"}
                size={"medium"}
              >
                Save
              </ButtonComponent>
            </Modal.Footer>
          </Modal>
        </div>
      </div>
    );
  }
}
export default ChangeNumber;
