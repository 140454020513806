import { Action, Reducer } from 'redux';
import { AppThunkAction } from './';
import { actionTypes } from './ActionTypes';
import { StatusType, NotificationAction } from './Common/NotificationStore';
import { ISummaryViewModel, initialSummaryViewModel } from '../core/domain/viewModels/ISummaryViewModel';
import { initialPaperFileConsentInfo } from '../core/domain/viewModels/IPaperFileCosentInfo';
import { NotificationType } from '../core/common/Enums';
import { initializeAxios } from '../core/services/dataAccess/DataService.Axios'
import { AxiosResponse } from 'axios';
import { ErrorMessages, CompletedSummaryConstant } from '../components/Common/Constants';
import { TelemetryLogger } from '../components/Logger/AppInsights';

const logger = TelemetryLogger.getInstance();

interface RequestSummaryDetailsAction {
    type: actionTypes.SUMMARY_DETAILS_REQUEST;
    clientId: string
}

interface ResponseSummaryDetailsAction {
    type: actionTypes.SUMMARY_DETAILS_RESPONSE;
    data: ISummaryViewModel;
}
interface FailureSummaryDetailsAction {
    type: actionTypes.SUMMARY_DETAILS_FAILURE;
    clientId: string

}

interface SendSigningReminderRequestAction {
    type: actionTypes.SEND_SIGNING_REMINDER_REQUEST;
    loading: boolean;
}

interface SendSigningReminderResponseAction {
    type: actionTypes.SEND_SIGNING_REMINDER_RESPONSE;
    loading: boolean;
}

interface SendSigningReminderFailureAction {
    type: actionTypes.SEND_SIGNING_REMINDER_FAILURE;
    loading: boolean;
}

type KnownAction =
    DispatchAction |
    NotificationAction;

type DispatchAction = ResponseSummaryDetailsAction
    | RequestSummaryDetailsAction
    | FailureSummaryDetailsAction
    | SendSigningReminderRequestAction
    | SendSigningReminderResponseAction
    | SendSigningReminderFailureAction;

export const actionCreators = {

    requestSummaryDetails: (clientId: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        let state = getState();
        dispatch({ type: actionTypes.SUMMARY_DETAILS_REQUEST, clientId: clientId });
        return initializeAxios().get<ISummaryViewModel>('api/Summary/GetSummaryDetailsAsync/' + clientId)
            .then(function (response: AxiosResponse<ISummaryViewModel>) {

                dispatch({
                    type: actionTypes.SUMMARY_DETAILS_RESPONSE, data: response.data
                });

            })
            .catch(function (error: any) {
                dispatch({
                    type: actionTypes.NOTIFICATION, statusMessage: error.response ? error.response.statusText : ErrorMessages.SummaryDetails,
                    statusType: StatusType.Error
                });
                dispatch({ type: actionTypes.SUMMARY_DETAILS_FAILURE, clientId: clientId });
                logger.trackWarning(`requestSummaryDetails failed with error ${error.message} for client: ${clientId}`, { "ClientId": clientId });
            });
    },

    sendSigningReminder: (clientId: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        let state = getState();
        dispatch({ type: actionTypes.SEND_SIGNING_REMINDER_REQUEST, loading: true });
        return initializeAxios().post<boolean>('api/Summary/SendSigningReminderAsync/' + clientId)
            .then(function (response: AxiosResponse<boolean>) {
                const { data } = response;
                dispatch({
                    type: actionTypes.NOTIFICATION,
                    statusMessage: data ? CompletedSummaryConstant.SendSigningReminderSuccess : CompletedSummaryConstant.SendSigningReminderFailure,
                    statusType: data ? StatusType.Success : StatusType.Error
                });
                dispatch({
                    type: actionTypes.SEND_SIGNING_REMINDER_RESPONSE, loading: false
                });

            })
            .catch(function (error: any) {
                dispatch({
                    type: actionTypes.NOTIFICATION, statusMessage: error.response ? error.response.statusText : CompletedSummaryConstant.SendSigningReminderFailure,
                    statusType: StatusType.Error
                });
                dispatch({ type: actionTypes.SEND_SIGNING_REMINDER_FAILURE, loading: false });
                logger.trackWarning(`sendSigningReminder failed with error ${error.message} for client: ${clientId}`, { "ClientId": clientId });
            });
    }
}

export const reducer: Reducer<ISummaryViewModel> = (state: ISummaryViewModel = initialSummaryViewModel, incomingAction: Action) => {
    const action = incomingAction as DispatchAction;
    var data = Object.assign({}, state);
    switch (action.type) {
        case actionTypes.SUMMARY_DETAILS_REQUEST:
            return initialSummaryViewModel;
        case actionTypes.SUMMARY_DETAILS_RESPONSE:
            if (action.data.paperFileConsentInfo == undefined) {
                action.data.paperFileConsentInfo = initialPaperFileConsentInfo;
            }
            return action.data;
        default:
            return state || initialSummaryViewModel;
    }
};