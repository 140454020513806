import { ICCRecipientDownloadableDocuments } from '../models/ICCRecipientModel';

export interface IDelegateeSignerDetails {
    firstName: string;
    lastName: string;
    email: string;
    confirmEmail: string;
    countryCode: string;
    mobileNumber: string;
    description: string;
}

export const initialState: IDelegateeSignerDetails = {
    firstName: "",
    lastName: "",
    email: "",
    confirmEmail: "",
    countryCode: "",
    mobileNumber: "",
    description: "",
}

export interface IDelegateeInfo extends IDelegateeSignerDetails {
    taxClientGuid: string;
    clientGuid: string;
    taxClientName: string;
}

export interface IDelegateeConsentInfo {
    firstName: string;
    lastName: string;
    email: string;
    homeAddress: string;
    city: string;
    state: string;
    zipCode: string;
    dateOfBirth: Date;
    ssn: string;
}

export const initialDelegateeInfoState: IDelegateeInfo = {
    taxClientGuid: "",
    taxClientName: "",
    clientGuid: "",
    firstName: "",
    lastName: "",
    email: "",
    confirmEmail: "",
    countryCode: "",
    mobileNumber: "",
    description: "",
}

export const initialDelegateeConsentInfo: IDelegateeConsentInfo = {
    firstName: "",
    lastName: "",
    email: "",
    homeAddress: "",
    city: "",
    state: "",
    zipCode: "",
    dateOfBirth: new Date(),
    ssn: "",
}

export class DelegateeSignerDetails implements IDelegateeSignerDetails {
    firstName: string;
    lastName: string;
    email: string;
    confirmEmail: string;
    countryCode: string;
    mobileNumber: string;
    description: string;

    constructor(firstName: string,
        lastName: string,
        email: string,
        confirmEmail: string,
        countryCode: string,
        mobileNumber: string,
        description: string) {
        this.firstName = firstName;
        this.lastName = lastName;
        this.email = email;
        this.confirmEmail = email;
        this.countryCode = countryCode;
        this.mobileNumber = mobileNumber;
        this.description = description;
    }

    public static create(firstName: string,
        lastName: string,
        email: string,
        confirmEmail: string,
        countryCode: string,
        mobileNumber: string,
        description: string): IDelegateeSignerDetails {

        return new DelegateeSignerDetails(firstName,
            lastName,
            email,
            confirmEmail,
            countryCode,
            mobileNumber,
            description)
    }
}

export class DelegateeConsentInfo implements IDelegateeConsentInfo {
    firstName: string;
    lastName: string;
    email: string;
    homeAddress: string;
    city: string;
    state: string;
    zipCode: string;
    dateOfBirth: Date;
    ssn: string;

    constructor(firstName: string,
        lastName: string,
        email: string,
        homeAddress: string,
        city: string,
        state: string,
        zipCode: string,
        dateOfBirth: Date,
        ssn: string) {
        this.firstName = firstName;
        this.lastName = lastName;
        this.email = email;
        this.homeAddress = homeAddress;
        this.city = city;
        this.state = state;
        this.zipCode = zipCode;
        this.dateOfBirth = dateOfBirth;
        this.ssn = ssn;
    }

    public static create(firstName: string,
        lastName: string,
        email: string,
        homeAddress: string,
        city: string,
        state: string,
        zipCode: string,
        dateOfBirth: Date,
        ssn: string): IDelegateeConsentInfo {

        return new DelegateeConsentInfo(firstName,
            lastName,
            email,
            homeAddress,
            city,
            state,
            zipCode,
            dateOfBirth,
            ssn)
    }
}