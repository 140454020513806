import { ApplicationState } from "../../store/index";
import { actionCreators as accountAction } from "../../store/Common/AccountStore";
import { actionCreators as helperAction } from "../../store/Common/HelperStore";
import { actionCreators as esignAction } from "../../store/Common/EsignStore";
import { actionCreators as downloadAction } from "../../store/DownloadableDocumentsStore";
import { actionCreators as taxDocumentAction } from "../../store/Common/TaxDocumentStore";
import { actionCreators as companyAction } from "../../store/Common/Company/CompanyStore";
import { DefaultLayout } from "./DefaultLayout";
import { connect } from "react-redux";
import { createLoadingSelector } from "../../store/selectors";
import { withRouter } from "react-router";
import { ClientType } from "../../core/common/Enums";
import { IDocument } from "../../core/domain/models/esign/Document";
import { ITaxReturn } from "../../core/domain/models/ITaxReturn";
import { actionCreators as TaxDocumentStore } from "../../store/Common/TaxDocumentStore";
import { IAccessCodeValidation } from "../../core/domain/viewModels/ICommon";
import TaxpayerHelper from "../Helper/TaxpayerHelper";
import { stat } from "fs";
const loadingSelector = createLoadingSelector([
  "HEADERINFO",
  "TAXING_AUTHORITY",
]);

function mapStateToProps(state: ApplicationState) {
  return {
    headerInfo: state.headerInfo,
    loading: loadingSelector(state),
    taxReturn: state.taxReturn.taxDocument,
    signerData: state.esignData.signerDetails,
    downloadList: state.downloadableDocuments,
    iceServers: state.commonData.iceServers,
    isScreenShareEnabled:
      state.companyData.data.displaySettings.isScreenShareEnabled,
    isPreviewMode: TaxpayerHelper.isPreviewMode(
      state.signProcessData.clientprocessmodel
    ),
    webRTCSignalRHubBaseURL: state.commonData.webRTCSignalRHubBaseURL,
    companyData: state.companyData.data,
    clientProcessData: state.signProcessData.clientprocessmodel,
  };
}
//temperroy fix for webpack failure need to invetigate further when we have time
const mapDispatchToProps = (dispatch: any) => {
  return {
    requestHeaderInfo: (id: string) =>
      dispatch(accountAction.requestHeaderInfo(id)),

    refreshToken: (clientId: string, failurecallback: () => void) =>
      dispatch(accountAction.refreshToken(clientId, failurecallback)),

    requestAllTaxingAuthorities: (id: string) =>
      dispatch(helperAction.requestAllTaxingAuthorities(id)),

    getSessionTimeOutSeconds: (
      clientId: string,
      callBack?: (sessionTimeout: number) => void
    ) => dispatch(accountAction.getSessionTimeOutSeconds(clientId, callBack)),

    requestTaxDocument: (id: string) =>
      dispatch(taxDocumentAction.requestTaxDocument(id)),

    requestKBAStatus: (id: string) =>
      dispatch(taxDocumentAction.requestKBAStatus(id)),

    requestTaxDocumentclientType: (id: string) =>
      dispatch(taxDocumentAction.requestTaxDocumentclientType(id)),
    clientAuthentication: (clientGuid: string, failureCallback: () => void) =>
      dispatch(esignAction.clientAuthentication(clientGuid, failureCallback)),
    //esign store is not right place for mobilenumber update need to move from esign store to account store some where
    //Vision team please do the need full just added all method to avoid webpack failure issue
    clientValidation: (
      clientGuid: string,
      data: any,
      successCallback: (isNextQuestionSet?: boolean) => void,
      failureCallback: () => void
    ) =>
      dispatch(
        esignAction.clientValidation(
          clientGuid,
          data,
          successCallback,
          failureCallback
        )
      ),

    requestSigners: (clientGuid: string) =>
      dispatch(esignAction.requestSigners(clientGuid)),

    requestSignatureControls: (clientGuid: string) =>
      dispatch(esignAction.requestSignatureControls(clientGuid)),
    requestSignBehalfSpouseSignatureControls: (clientGuid: string) =>
      dispatch(
        esignAction.requestSignBehalfSpouseSignatureControls(clientGuid)
      ),
    requestPreviewSignatureControls: (clientGuid: string) =>
      dispatch(esignAction.requestPreviewSignatureControls(clientGuid)),
    requestSignBehalfSpousePreviewSignatureControls: (clientGuid: string) =>
      dispatch(
        esignAction.requestSignBehalfSpousePreviewSignatureControls(clientGuid)
      ),
    updateDOB: (
      clientGuid: string,
      id: string,
      dob: string,
      step: boolean,
      callback: (step: boolean) => void
    ) => dispatch(esignAction.updateDOB(clientGuid, id, dob, step, callback)),

    updateDocumentSignatureSettingModel: (
      clientId: string,
      signatureType: number,
      callback: any
    ) =>
      dispatch(
        esignAction.updateDocumentSignatureSettingModel(
          clientId,
          signatureType,
          callback
        )
      ),

    updateSignatureControlsData: (data: any) =>
      dispatch(esignAction.updateSignatureControlsData(data)),

    updateSpouseMail: (
      clientGuid: string,
      spouseGuid: string,
      id: string,
      mail: string,
      type: ClientType,
      step?: boolean,
      callback?: (step: boolean) => void
    ) =>
      dispatch(
        esignAction.updateSpouseMail(
          clientGuid,
          spouseGuid,
          id,
          mail,
          type,
          step,
          callback
        )
      ),

    updateMobileNumber: (
      clientGuid: string,
      id: string,
      number: string,
      countryCodeValue: string,
      ssnValue: string,
      clientType: ClientType,
      callback: () => void
    ) =>
      dispatch(
        accountAction.updateMobileNumber(
          clientGuid,
          id,
          number,
          countryCodeValue,
          ssnValue,
          clientType,
          callback
        )
      ),
    sign: (
      clientId: string,
      documentData: IDocument[],
      callback: (status: boolean) => void
    ) => dispatch(esignAction.sign(clientId, documentData, callback)),
    signOnBehalfSpouse: (
      clientId: string,
      documentData: IDocument[],
      taxpayerGuid: string,
      spouseGuid: string,
      callback: (status: boolean) => void
    ) =>
      dispatch(
        esignAction.signOnBehalfSpouse(
          clientId,
          documentData,
          taxpayerGuid,
          spouseGuid,
          callback
        )
      ),
    updateTaxDocument: (taxDocument: ITaxReturn) =>
      dispatch(taxDocumentAction.updateTaxDocument(taxDocument)),

    requestNextSigner: (clientGuid: string) =>
      dispatch(TaxDocumentStore.requestNextSigner(clientGuid)),

    requestMyDownload: (clientId: string) =>
      dispatch(downloadAction.requestMyDownload(clientId)),

    deleteMyDownloads: (downloadId: string, clientId: string) =>
      dispatch(downloadAction.deleteMyDownloads(downloadId, clientId)),

    clearAllMyDownloads: (clientId: string) =>
      dispatch(downloadAction.clearAllMyDownloads(clientId)),

    downloadK1Zip: (clientGuid: string, documentId: string) =>
      dispatch(downloadAction.downloadK1Zip(clientGuid, documentId)),

    validateTimeBasedAccessCode: (
      accessCode: IAccessCodeValidation,
      callback: () => void
    ) =>
      dispatch(helperAction.validateTimeBasedAccessCode(accessCode, callback)),

    logout: (clientGuid: string, callback: (id: string) => void) =>
      dispatch(accountAction.logout(clientGuid, callback)),

    getIceServers: (clientId: string) =>
      dispatch(helperAction.getIceServers(clientId)),

    getWalkMeScript: (clientId: string, callback: (script: string) => void) =>
      dispatch(helperAction.getWalkMeScript(clientId, callback)),

    getWebRTCSignalRHubBaseURL: (
      clientId: string,
      callback: (script: string) => void
    ) => dispatch(helperAction.getWebRTCSignalRHubBaseURL(clientId, callback)),

    requestRecipientHeaderInfo: (id: string) =>
      dispatch(accountAction.requestRecipientHeaderInfo(id)),

    updateSpouseMobileNumber: (
      clientGuid: string,
      id: string,
      newMobileNumber: string,
      newCountryCode: string,
      ssn: string,
      type: ClientType,
      step?: boolean,
      callback?: (step: boolean) => void
    ) =>
      dispatch(
        esignAction.updateSpouseMobileNumber(
          clientGuid,
          id,
          newMobileNumber,
          newCountryCode,
          ssn,
          type,
          step,
          callback
        )
      ),

    requestDelegateeHeaderInfo: (id: string) =>
      dispatch(accountAction.requestDelegateeHeaderInfo(id)),

    requestGroupHeaderInfo: (id: string) =>
      dispatch(accountAction.requestGroupHeaderInfo(id)),

    updateSignBehalfSpouseState: () =>
      dispatch(esignAction.updateSignBehalfSpouseState()),

        requestCompanyDetails: (id: string) => dispatch(companyAction.requestCompanyDetails(id)),

    requestDelegateeKBAStatus: (
      clientGuid: string,
      successCallback?: () => void
    ) =>
      dispatch(
        taxDocumentAction.requestDelegateeKBAStatus(clientGuid, successCallback)
      ),

    getClientHubDomainURL: (
      clientGuid: string,
      callback: (hubDomainURL: string) => void
    ) => dispatch(helperAction.getClientHubDomainURL(clientGuid, callback)),

    checkIfUserExistsInOneHub: (
      clientGuid: string,
      callback: (isUserExists: boolean) => void
    ) => dispatch(helperAction.checkIfUserExistsInOneHub(clientGuid, callback)),

    getOnehubRedirectURL: (
      clientGuid: string,
      callback: (onehubRedirectURL: string) => void
    ) => dispatch(helperAction.getOnehubRedirectURL(clientGuid, callback)),

    getOnehubRedirectURLForClientView: (
      clientGuid: string,
      callback: (onehubRedirectURL: string) => void
    ) =>
      dispatch(
        helperAction.getOnehubRedirectURLForClientView(clientGuid, callback)
      ),
  };
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export default withRouter(connector(DefaultLayout));
