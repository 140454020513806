import * as React from "react";
import * as TaxDocumentStore from "../../../store/Common/TaxDocumentStore";
import * as TaxDocument from "../../../core/domain/models/ITaxReturn";
import { match } from "react-router";
import * as CompanyStore from "../../../store/Common/Company/CompanyStore";
import { History } from "history";
import { IHeaderInfoViewModel } from "../../../core/domain/viewModels/IHeaderInfoViewModel";
import { Role } from "../../../core/common/Enums";
import {
  PathConstants,
  getRoutePathUrl,
  PaperFileReturnConstants,
} from "../../Common/Constants";
import { Body } from "../../Common/StepLayout/Body";
import { StepLayout } from "../../Common/StepLayout/StepLayout";
import { Next } from "@sssuite-component-ui/multi-step-wizard/dist/Footer/Next";
import { Previous } from "@sssuite-component-ui/multi-step-wizard/dist/Footer/Previous";
import { Footer } from "../../Common/StepLayout/Footer";
import TaxpayerHelper from "../../Helper/TaxpayerHelper";
import { IClientProcessViewModel } from "../../../core/domain/viewModels/IClientProcessViewModel";
import * as SignProcessStore from "../../../store/SignProcess/SignProcessStore";
import * as PaperFileReturnStore from "../../../store/PaperFileReturn/PaperFileReturnStore";
import { storeControllerIdInMemory } from "../../../core/services/dataAccess/DataService.Axios";
import { PaperFileList } from "../PaperFileList";
import { ISummaryViewModel } from "../../../core/domain/viewModels/ISummaryViewModel";
import { ISignerModel } from "../../../core/domain/models/ISignerModel";
import { IPaperFileReturnDetails } from "../../../core/domain/models/IPaperFileReturn";
import * as SummaryStore from "../../../store/SummaryStore";
import { SignProcessSteps } from "../../Home/TaxpayerSignFlowManager";
import { logger } from "../../../routes";
import { PreviousButtonIcon } from "../../Common/Icons/SvgIcons";

type IPaperFileListCompletedProps = {
  match: match;
  history: History;
  taxReturn: TaxDocument.ITaxReturn;
  headInfoViewModel: IHeaderInfoViewModel;
  paperFileReturnData: IPaperFileReturnDetails[];
  clientName: string;
  signerData: ISignerModel[];
  headerInfo: IHeaderInfoViewModel;
  summaryData: ISummaryViewModel;
  requestSummaryDetails: (clientGuid: string) => any;
  requestSigners: (clientGuid: string) => any;
} & typeof SummaryStore.actionCreators &
  typeof CompanyStore.actionCreators &
  typeof PaperFileReturnStore.actionCreators &
  typeof TaxDocumentStore.actionCreators &
  typeof SignProcessStore.actionCreators;

interface PaperFileListCompletedState {
  isProcessCompleted: boolean;
  role: Role;
}

export class PaperFiledReturnCompleted extends React.Component<
  IPaperFileListCompletedProps,
  PaperFileListCompletedState
> {
  private _params: any;
  constructor(props: IPaperFileListCompletedProps) {
    super(props);
    this.state = {
      isProcessCompleted: false,
      role: Role.None,
    };
  }

  componentDidMount() {
    const param: any = this.props.match.params;
    logger.trackPageView("Completed Summary Paper File Return Page", {
      ClientId: param.clientId,
      DocumentId: this.props.taxReturn.id,
    });
    if (param && param.controllerId) {
      storeControllerIdInMemory(param.controllerId);
    }
    this.getSignProcessStatus(param.clientId);
    if (param.clientId && this.props.summaryData.documentId === 0) {
      this.props.requestSummaryDetails(param.clientId);
    }
    this.props.requestSigners(param.clientId);
    this.props.requestTaxDocument(param.clientId);
  }

  onConsentSkip = () => {
    const param: any = this.props.match.params;
    this.props.history.push(
      getRoutePathUrl(
        PathConstants.CompletionWizard,
        param.clientId,
        param.controllerId
      )
    );
  };

  gotoCompletionWizard = () => {
    const param: any = this.props.match.params;
    if (!this.checkIfPreviewRequest()) {
      this.props.updateLastVisitedStep(
        this._params.clientId,
        SignProcessSteps.PaperFileListComplete,
        () => {
          this.props.history.push(
            getRoutePathUrl(
              PathConstants.CompletionWizard,
              param.clientId,
              param.controllerId
            )
          );
        }
      );
    } else {
      this.props.history.push(
        getRoutePathUrl(
          PathConstants.CompletionWizard,
          param.clientId,
          param.controllerId
        )
      );
    }
  };

  onPrevious = () => {
    const param: any = this.props.match.params;
    this.props.history.push(
      getRoutePathUrl(
        PathConstants.CompletionWizard,
        param.clientId,
        param.controllerId
      )
    );
  };

  private getSignProcessStatus = (clientId: any) => {
    this.props.requestCurrentStepAndUserRole(clientId, this.handleRedirect);
  };

  private handleRedirect = (clientProcessState: IClientProcessViewModel) => {
    this._params = this.props.match.params;
    const url = TaxpayerHelper.getSignCompletedRedirectUrl(
      clientProcessState,
      this._params.clientId,
      this._params.controllerId
    );
    if (url && url.trim()) {
      this.props.history.push(url);
    } else {
      this.setState({
        isProcessCompleted: true,
        role: clientProcessState.role,
      });
    }
  };

  checkIfPreviewRequest = (): boolean => {
    if (this.state.role.toString() === Role[Role.CPA].toString()) {
      return true;
    }
    return false;
  };

  public render() {
    const isPreview: boolean = this.checkIfPreviewRequest();
    return (
      this.state.isProcessCompleted &&
      this.props.summaryData.hasPaperFiles && (
        <StepLayout className={"with-footer"}>
          <Body
            lg={12}
            md={12}
            sm={12}
            xs={12}
            automationTestId={"98BB28B5-42F3-4571-B567-20700B1740B6"}
          >
            {
              <PaperFileList
                clientName={this.props.headInfoViewModel.clientName}
                requestPaperFileReturnDetails={
                  this.props.requestPaperFileReturnDetails
                }
                paperFiledRetursData={this.props.paperFileReturnData}
                match={this.props.match}
                isPreviewMode={isPreview}
                taxReturn={this.props.taxReturn}
                signerData={this.props.signerData}
                downloadAllPaperFileReturns={
                  this.props.downloadAllPaperFileReturns
                }
                downloadIndividualPaperFileReturn={
                  this.props.downloadIndividualPaperFileReturn
                }
                getPaperFileDocumentStream={
                  this.props.getPaperFileDocumentStream
                }
                getMergedPaperFileDocumentStream={
                  this.props.getMergedPaperFileDocumentStream
                }
                headerInfo={this.props.headInfoViewModel}
                addPaperFilePrintToClientTracking={
                  this.props.addPaperFilePrintToClientTracking
                }
                addPaperFilePrintAllToClientTracking={
                  this.props.addPaperFilePrintAllToClientTracking
                }
                summaryData={this.props.summaryData}
                updatePaperFileConsent={this.props.updatePaperFileConsent}
                onConsentSkip={this.onConsentSkip}
                skipButtonText={PaperFileReturnConstants.SkipButtonText}
              />
            }
          </Body>

          <Footer lg={12} md={12} sm={12} xs={12}>
            <div className={"steps-footer "}>
              <Previous
                automationTestId={"3F43C07D-73AD-4BB5-83D1-346B1E62ABC6"}
                previousButtonIcon={<PreviousButtonIcon />}
                text={"Back"}
                goToPreviousStep={this.onPrevious}
                isActive={true}
                disableMobileResponsiveness={true}
                className="awesome-multi-steps-completed-btn-previous"
              />

              <Next
                automationTestId={"21596F53-89B5-4830-812E-96D126E7C1AE"}
                className="awesome-multi-steps-btn-done"
                text={"Done"}
                goToNextStep={this.gotoCompletionWizard}
                isActive={true}
                disableMobileResponsiveness={true}
                buttonVariant="success"
                buttonSize="medium"
              />
            </div>
          </Footer>
        </StepLayout>
      )
    );
  }
}
