import { Action, Reducer } from 'redux';
import { AppThunkAction } from './';
import { actionTypes } from './ActionTypes';
import { StatusType, NotificationAction } from './Common/NotificationStore';
import { IPayScreenViewModel, initialPayScreenViewModel } from '../core/domain/viewModels/IPayScreenViewModel';
import { initializeAxios } from '../core/services/dataAccess/DataService.Axios'
import { AxiosResponse, AxiosRequestConfig } from 'axios';
import { stat } from 'fs';
import { DisplayDownloadFile } from '../components/Common/DisplayDownloadFile';
import { handleBlobwithFileName, handleResponse } from './Library';
import { IPaymentVoucherNotificationSettings } from '../core/domain/models/IDocumentSettings';
import * as Constants from '../components/Common/Constants';
import { ILoader } from '../core/utilities/ui/Loader';
import { TYPES } from '../startup/types';
import { container } from '../startup/inversify.config';
import { IVoucher } from '../core/domain/models/IGroup';
import Moment from 'moment/moment';
import { TelemetryLogger } from '../components/Logger/AppInsights';
import { getLocalDate } from '../components/Helper/HelperFunction';

const logger = TelemetryLogger.getInstance();

interface RequestPayScreenDetailsAction {
    type: actionTypes.PAYSCREEN_DETAILS_REQUEST;
    clientId: string
}

interface ResponsePayScreenDetailsAction {
    type: actionTypes.PAYSCREEN_DETAILS_RESPONSE;
    data: IPayScreenViewModel;
}

interface FailurePayScreenDetailsAction {
    type: actionTypes.PAYSCREEN_DETAILS_FAILURE;
    clientId: string

}


interface UpdateVoucherReminderSetting {
    type: actionTypes.UPDATE_VOUCHER_NOTIFICATION_SETTING,
    data: IPaymentVoucherNotificationSettings
}

interface RequestVoucherPaymentAction {
    type: actionTypes.VOUCHER_PAYMENT_REQUEST;
    voucher: IVoucher
}

type KnownAction =
    DispatchAction |
    NotificationAction;

type DispatchAction = RequestPayScreenDetailsAction
    | ResponsePayScreenDetailsAction
    | FailurePayScreenDetailsAction
    | RequestVoucherPaymentAction
    | UpdateVoucherReminderSetting;


const loader = container.get<ILoader>(TYPES.ILoader);

export const actionCreators = {

    requestPayScreenDetails: (clientId: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        let state = getState();

        dispatch({ type: actionTypes.PAYSCREEN_DETAILS_REQUEST, clientId: clientId });

        return initializeAxios().get<IPayScreenViewModel>('api/Pay/GetPayScreenDetailsAsync/' + clientId)
            .then(function (response: AxiosResponse<IPayScreenViewModel>) {

                dispatch({ type: actionTypes.PAYSCREEN_DETAILS_RESPONSE, data: response.data });

            })
            .catch(function (error: any) {
                dispatch({
                    type: actionTypes.NOTIFICATION, statusMessage: Constants.PayConstants.ErrorVoucherPayment,
                    statusType: StatusType.Error
                });
                dispatch({ type: actionTypes.PAYSCREEN_DETAILS_FAILURE, clientId: clientId });
                logger.trackWarning(`requestPayScreenDetails failed with error ${error.message} for client: ${clientId}`, { "ClientId": clientId });
            });
    },
    downloadAllVouchers: (clientId: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        loader.show();
        const localDate = getLocalDate();
        let config: AxiosRequestConfig = { responseType: 'arraybuffer', headers: { 'Content-Type': 'application/json;utf-8' } };
        return initializeAxios().post('api/Download/GetAllVouchers/' + clientId, JSON.stringify(localDate), config)
            .then(function (response: any) {
                const contentDisposition = response.headers["content-disposition"];
                const fileNameMatch = contentDisposition ? /filename="?([^"]*)"?;/g.exec(contentDisposition) : undefined;
                let fileName = '';
                if (fileNameMatch && fileNameMatch.length > 1) {
                    fileName = fileNameMatch[1];
                }
                let displayDownloadFile = new DisplayDownloadFile();

                displayDownloadFile.showFile(response.data, fileName);
                loader.hide();
            })
            .catch(function (error: any) {
                dispatch({
                    type: actionTypes.NOTIFICATION, statusMessage: Constants.PayConstants.DownloadFailed,
                    statusType: StatusType.Error
                });
                logger.trackWarning(`downloadAllVouchers failed with error ${error.message} for client: ${clientId}`, { "ClientId": clientId });
            });
    },
    downloadFilingInstructions: (clientId: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        loader.show();
        return initializeAxios().post('api/Download/GetFilingInstructions/' + clientId)
            .then(function (response: any) {
                let displayDownloadFile = new DisplayDownloadFile();
                displayDownloadFile.directDownload(response.data);
                loader.hide();
            }).catch(function (error: any) {
                dispatch({
                    type: actionTypes.NOTIFICATION, statusMessage:  Constants.PayConstants.DownloadFailed,
                    statusType: StatusType.Error
                });
                logger.trackWarning(`downloadFilingInstructions failed with error ${error.message} for client: ${clientId}`, { "ClientId": clientId });
            });
    },

    downloadTaxInvoice: (clientId: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        loader.show();
        return initializeAxios().post('api/Download/GetTaxInvoices/' + clientId)
            .then(function (response: any) {
                let displayDownloadFile = new DisplayDownloadFile();
                displayDownloadFile.directDownload(response.data);
                loader.hide();
            }).catch(function (error: any) {
                dispatch({
                    type: actionTypes.NOTIFICATION, statusMessage: Constants.PayConstants.DownloadFailed,
                    statusType: StatusType.Error
                });
                logger.trackWarning(`downloadTaxInvoice failed with error ${error.message} for client: ${clientId}`, { "ClientId": clientId });
            });
    },
    updateVoucherNotificationSettings: (clientId: string, settings: IPaymentVoucherNotificationSettings, Callback: () => void): AppThunkAction<KnownAction> => (dispatch, getState) => {
        loader.show();
        let config: AxiosRequestConfig = { headers: { 'Content-Type': 'application/json;utf-8' } };
        return initializeAxios().put<IPaymentVoucherNotificationSettings>('api/Pay/UpdateVoucherReminderDetails/' + clientId,
            JSON.stringify(settings), config)
            .then(function (response: any) {
                dispatch({
                    type: actionTypes.NOTIFICATION,
                    statusMessage: Constants.PayConstants.VoucherSettingUpdated,
                    statusType: StatusType.Success
                });

                dispatch({
                    type: actionTypes.UPDATE_VOUCHER_NOTIFICATION_SETTING,
                    data: settings
                })

                Callback();
                loader.hide();
            })
            .catch(function (error: any) {
                dispatch({
                    type: actionTypes.NOTIFICATION,
                    statusMessage: Constants.PayConstants.ErrorVoucherSettingUpdated,
                    statusType: StatusType.Error
                });
                Callback();
                logger.trackWarning(`updateVoucherNotificationSettings failed with error ${error.message} for client: ${clientId}`, { "ClientId": clientId });
            });
    },

    downloadSingleVoucher: (clientId: string, fileName: string, pageNo: string, bookMarks: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        loader.show();
        var bookMark = bookMarks.replace("/", "").replace(/[^\x20-\x7E]/g, '');
        let config: AxiosRequestConfig = { responseType: 'arraybuffer', headers: { 'Content-Type': 'application/json;utf-8' } };
        const url: string = `api/Download/GetSingleVoucher/${fileName}/${pageNo}/${encodeURIComponent(bookMark)}/${clientId}`;
        return initializeAxios().post(url, null, config)
            .then(function (response: any) {
                const contentDisposition = response.headers["content-disposition"];
                const fileNameMatch = contentDisposition ? /filename="?([^"]*)"?;/g.exec(contentDisposition) : undefined;
                let fileName = '';
                if (fileNameMatch && fileNameMatch.length > 1) {
                    fileName = fileNameMatch[1];
                }
                let displayDownloadFile = new DisplayDownloadFile();

                displayDownloadFile.showFile(response.data, fileName);
                loader.hide();
            }).catch(function (error: any) {
                dispatch({
                    type: actionTypes.NOTIFICATION, statusMessage: Constants.PayConstants.DownloadFailed,
                    statusType: StatusType.Error
                });
                logger.trackWarning(`downloadSingleVoucher failed with error ${error.message} for client: ${clientId}`, { "ClientId": clientId });
            });
    },

    downloadPreviewFilingInstruction: (clientGuid: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        loader.show();
        loader.show();
        let config: AxiosRequestConfig = { responseType: 'arraybuffer', headers: { 'Content-Type': 'application/json;utf-8' } };
        return initializeAxios().post('api/Download/GetPreviewFilingInstruction/' + clientGuid, null, config)
            .then(function (response: any) {
                const contentDisposition = response.headers["content-disposition"];
                const fileNameMatch = contentDisposition ? /filename="?([^"]*)"?;/g.exec(contentDisposition) : undefined;
                let fileName = '';
                if (fileNameMatch && fileNameMatch.length > 1) {
                    fileName = fileNameMatch[1];
                }
                let displayDownloadFile = new DisplayDownloadFile();

                displayDownloadFile.showFile(response.data, fileName);
                loader.hide();
            }).catch(function (error: any) {
                dispatch({
                    type: actionTypes.NOTIFICATION, statusMessage: Constants.PayConstants.DownloadFailed,
                    statusType: StatusType.Error
                });
                logger.trackWarning(`downloadPreviewFilingInstruction failed with error ${error.message} for client: ${clientGuid}`, { "ClientId": clientGuid });
            });
    },

    downloadPreviewAllVouchers: (clientId: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        loader.show();
        let config: AxiosRequestConfig = { responseType: 'arraybuffer', headers: { 'Content-Type': 'application/json;utf-8' } };
        return initializeAxios().post('api/Download/GetPreviewAllVouchers/' + clientId, null, config)
            .then(function (response: any) {
                const contentDisposition = response.headers["content-disposition"];
                const fileNameMatch = contentDisposition ? /filename="?([^"]*)"?;/g.exec(contentDisposition) : undefined;
                let fileName = '';
                if (fileNameMatch && fileNameMatch.length > 1) {
                    fileName = fileNameMatch[1];
                }
                let displayDownloadFile = new DisplayDownloadFile();

                displayDownloadFile.showFile(response.data, fileName);
                loader.hide();
            })
            .catch(function (error: any) {
                dispatch({
                    type: actionTypes.NOTIFICATION, statusMessage: Constants.PayConstants.DownloadFailed,
                    statusType: StatusType.Error
                });
                logger.trackWarning(`downloadPreviewAllVouchers failed with error ${error.message} for client: ${clientId}`, { "ClientId": clientId });
            });
    },



    addVoucherPayemnt: (clientId: string, voucher: IVoucher, Callback?: () => void): AppThunkAction<KnownAction> => (dispatch, getState) => {
        let config: AxiosRequestConfig = { headers: { 'Content-Type': 'application/json;utf-8' } };
        return initializeAxios().post('api/Pay/AddVoucherPayementAsync/' + clientId,
            JSON.stringify(voucher), config)
            .then(function (data: AxiosResponse<any>) {
                if (data) {
                    let state = getState();
                    voucher.isPaid = true;
                    const index = state.payScreenData.vouchers.findIndex(x => x.authorityID === voucher.authorityID && x.dueDate == voucher.dueDate && x.amount === voucher.amount);

                    state.payScreenData.vouchers[index] = voucher;
                    dispatch({
                        type: actionTypes.PAYSCREEN_DETAILS_RESPONSE, data: state.payScreenData,
                    });

                    dispatch({
                        type: actionTypes.NOTIFICATION, statusMessage: Constants.PayConstants.SuccesVoucherPayementMessage,
                        statusType: StatusType.Success
                    });
                    if (Callback) {
                        Callback();
                    }
                }
            })
            .catch(function (error: any) {
                if (error.response && error.response.statusText) {
                    dispatch({
                        type: actionTypes.NOTIFICATION, statusMessage: Constants.PayConstants.ErrorVoucherPaymentMessage,
                        statusType: StatusType.Error
                    });
                }
                logger.trackWarning(`addVoucherPayemnt failed with error ${error.message} for client: ${clientId}`, { "ClientId": clientId });
            });
    },

    updateVoucherPayemnt: (clientId: string, voucher: IVoucher, Callback?: () => void): AppThunkAction<KnownAction> => (dispatch, getState) => {
        let config: AxiosRequestConfig = { headers: { 'Content-Type': 'application/json;utf-8' } };
        return initializeAxios().put('api/Pay/UpdateVoucherPayementAsync/' + clientId,
            JSON.stringify(voucher), config)
            .then(function (data: AxiosResponse<any>) {
                if (data) {
                    let state = getState();
                    const index = state.payScreenData.vouchers.findIndex(x => x.authorityID === voucher.authorityID && x.dueDate == voucher.dueDate && x.amount === voucher.amount);
                    state.payScreenData.vouchers[index] = voucher;
                    dispatch({
                        type: actionTypes.PAYSCREEN_DETAILS_RESPONSE, data: state.payScreenData,
                    });
                    dispatch({
                        type: actionTypes.NOTIFICATION, statusMessage: Constants.PayConstants.SuccesVoucherPayementMessage,
                        statusType: StatusType.Success
                    });
                    if (Callback) {
                        Callback();
                    }
                }
            })
            .catch(function (error: any) {
                if (error.response && error.response.statusText) {
                    dispatch({
                        type: actionTypes.NOTIFICATION, statusMessage: Constants.PayConstants.ErrorVoucherPaymentMessage,
                        statusType: StatusType.Error
                    });
                }
                logger.trackWarning(`updateVoucherPayemnt failed with error ${error.message} for client: ${clientId}`, { "ClientId": clientId });
            });
    },

    clearPaymentDetails: (clientId: string, voucher: IVoucher, Callback?: () => void): AppThunkAction<KnownAction> => (dispatch, getState) => {
        let config: AxiosRequestConfig = { headers: { 'Content-Type': 'application/json;utf-8' } };
        return initializeAxios().put('api/Pay/ClearPaymentDetails/' + clientId,
            JSON.stringify(voucher), config)
            .then(function (data: AxiosResponse<any>) {
                if (data) {
                    let state = getState();
                    voucher.paidDate = new Date("0001-01-01T00:00:00");
                    voucher.isPaid = false;
                    const index = state.payScreenData.vouchers.findIndex(x => x.authorityID === voucher.authorityID && x.dueDate == voucher.dueDate && x.amount === voucher.amount);
                    state.payScreenData.vouchers[index] = voucher;
                    dispatch({
                        type: actionTypes.PAYSCREEN_DETAILS_RESPONSE, data: state.payScreenData,

                    });
                    dispatch({
                        type: actionTypes.NOTIFICATION, statusMessage: Constants.PayConstants.ClearVoucherDetailsSuccess,
                        statusType: StatusType.Success

                    });
                    if (Callback) {
                        Callback();
                    }

                }

            })
            .catch(function (error: any) {
                if (error.response && error.response.statusText) {
                    dispatch({
                        type: actionTypes.NOTIFICATION, statusMessage: Constants.PayConstants.ClearVoucherDetailsFailure,
                        statusType: StatusType.Error

                    });
                }
                logger.trackWarning(`clearPaymentDetails failed with error ${error.message} for client: ${clientId}`, { "ClientId": clientId });
            });



    }
}

export const reducer: Reducer<IPayScreenViewModel> = (state: IPayScreenViewModel = initialPayScreenViewModel, incomingAction: Action) => {
    const action = incomingAction as DispatchAction;
    var data = Object.assign({}, state);
    switch (action.type) {
        case actionTypes.PAYSCREEN_DETAILS_REQUEST:
            return initialPayScreenViewModel;

        case actionTypes.PAYSCREEN_DETAILS_RESPONSE:
            return { ...action.data}
        default:
            return state || initialPayScreenViewModel;
    }
};