import * as React from "react";
import { match } from "react-router";
import {
  IK1ShareHolderActivity,
  IK1ShareHolderDetails,
  initialK1ShareHolderDetails,
} from "../../core/domain/models/IK1Distribute";
import { DistributeElectronicTable } from "../K1Distribution/DistributeElectronicTable";
import { ITaxReturn, isPartnership } from "../../core/domain/models/ITaxReturn";
import { GetK1EngagementType } from "../../core/domain/models/IK1Distribute";
import { DownloadK1s } from "./DownloadK1s";
import { EditK1PartnerDetails } from "../K1Distribution/EditK1PartnerDetails";
import { EditK1PartnerEmail } from "../K1Distribution/EditK1PartnerEmail";
import { EngagementTypeS } from "../../core/common/Enums";
import { MarsNotifier } from "../Common/Notification/MarsNotifier";
import { K1ShareHolderConstants, Permissions } from "../Common/Constants";
import { IClientProcessViewModel } from "../../core/domain/viewModels/IClientProcessViewModel";
import { ILoggedInUserInfo } from "../../core/domain/viewModels/ILoggedInUserInfo";
import { logger } from "../../routes";
import { Role } from "../../core/common/Enums";
import { SignalRWebSocket } from "../SignalRWebSocket";
import { SvgEmailIcon, SvgSearchIcon } from "../Common/Icons/SvgIcons";
import { ButtonComponent } from "cp-common-ui-components";

const NO_INDEX = -1;

interface DistributeElectronicProps {
  requestK1ShareHolderDetails: (
    clientGuid: string,
    pageNo: number,
    pageSize: number,
    sortBy: string,
    sortOrder: string,
    searchText: string,
    callback?: () => void
  ) => void;
  requestK1ShareHolderActivity: (
    clientGuid: string,
    documentId: number,
    shareholderId: number,
    callback?: (k1ShareHolderActivity: IK1ShareHolderActivity[]) => void
  ) => void;
  k1Data: IK1ShareHolderDetails[];
  match: match;
  taxReturn: ITaxReturn;
  updateK1ShareHolderEmail?: (
    clientGuid: string,
    k1Partner: IK1ShareHolderDetails
  ) => void;
  downloadK1DocumentAsync: (
    clientId: string,
    engagementTpe: EngagementTypeS,
    callback?: () => void
  ) => void;
  updateK1ShareHolderDetail?: (
    clientGuid: string,
    k1Partner: IK1ShareHolderDetails,
    isEmailChange: boolean,
    isPreview: boolean,
    callback: () => void
  ) => void;

  downloadSelectedK1DocumentAsync: (
    clientId: string,
    shareHolders: IK1ShareHolderDetails[],
    engagementTpe: EngagementTypeS,
    callback?: () => void
  ) => void;

  downloadBulkSelectedK1DocumentAsync: (
    clientId: string,
    unSelectedShareHolders: IK1ShareHolderDetails[],
    engagementTpe: EngagementTypeS,
    callback?: () => void
  ) => void;

  downloadIndividualK1DocumentAsync: (
    clientId: string,
    shareHolder: IK1ShareHolderDetails,
    engagementTpe: EngagementTypeS
  ) => void;
  sendMailToShareHolder?: (
    clientId: string,
    partners: IK1ShareHolderDetails[],
    callback: () => void
  ) => void;
  bulkSendMailToShareHolder: (
    clientId: string,
    unSelectedRows: IK1ShareHolderDetails[],
    callback: () => void
  ) => void;
  requestCurrentStepAndUserRole?: (
    clientId: string,
    successCallback?: (clientProcessState: IClientProcessViewModel) => void
  ) => void;
  loggedInUserInfo: ILoggedInUserInfo;
  isPreviewMode: boolean;
  companyName: string;
  requestMyDownload: (clientId: string) => void;
  downloadAllK1DocumentsAsync: (
    clientId: string,
    engagementTpe: EngagementTypeS,
    callback?: () => void
  ) => void;
  isTrialView: boolean;
  isK1Loading: boolean;
  isClientView?: boolean;
  isDraftReturn?: boolean;
}

interface DistributeElectronicState {
  sortName: string;
  sortOrder: string;
  searchText: string;
  showDetailPopup: boolean;
  showEmailPopup: boolean;
  k1Partner: IK1ShareHolderDetails;
  page: number;
  readonly: boolean;
  selectedK1Partners: IK1ShareHolderDetails[];
  showBulkSelectionMessage: boolean;
  isBulkSelectionEnabled: boolean;
  unSelectedRows: IK1ShareHolderDetails[];
}

const pageSize: number = 10;

export class DistributeElectronic extends React.Component<
  DistributeElectronicProps,
  DistributeElectronicState
> {
  private _signalrRef: any = React.createRef();
  constructor(props: any) {
    super(props);
    this.state = {
      sortName: "Name",
      sortOrder: "asc",
      searchText: "",
      showDetailPopup: false,
      showEmailPopup: false,
      k1Partner: initialK1ShareHolderDetails,
      page: 1,
      readonly: false,
      selectedK1Partners: [],
      showBulkSelectionMessage: false,
      isBulkSelectionEnabled: false,
      unSelectedRows: [],
    };
  }

  componentDidMount() {
    let param: any = this.props.match.params;
    logger.trackPageView("Electronic K1 Distribute Page", {
      ClientId: param.clientId,
      DocumentId: this.props.taxReturn.id,
      PreviewMode: this.props.isPreviewMode,
    });
    this.props.requestK1ShareHolderDetails(
      param.clientId,
      this.state.page,
      pageSize,
      this.state.sortName,
      this.state.sortOrder,
      this.state.searchText
    );
  }

  loadK1ShareHolderDetails = () => {
    let param: any = this.props.match.params;
    this.props.requestK1ShareHolderDetails(
      param.clientId,
      this.state.page,
      pageSize,
      this.state.sortName,
      this.state.sortOrder,
      this.state.searchText,
      this.state.isBulkSelectionEnabled ? this.bulkSelect : undefined
    );
  };

  static getDerivedStateFromProps(
    nextProps: DistributeElectronicProps,
    nextState: DistributeElectronicState
  ) {
    if (
      nextProps.loggedInUserInfo.role.toString() ===
        Role[Role.CPA].toString() &&
      nextProps.loggedInUserInfo.permission === Permissions.Read
    ) {
      return { readonly: true };
    } else {
      return { readonly: false };
    }
  }

  private bulkSelect = () => {
    let newSelectedK1Partners = [...this.state.selectedK1Partners];
    this.props.k1Data.forEach((k1Partner) => {
      let index = newSelectedK1Partners.findIndex(
        (x) => x.shareHolderId === k1Partner.shareHolderId
      );
      if (
        index == -1 &&
        this.state.unSelectedRows.filter(
          (x) => x.shareHolderId === k1Partner.shareHolderId
        ).length == 0
      )
        newSelectedK1Partners.push(k1Partner);
    });
    this.setState({
      selectedK1Partners: newSelectedK1Partners,
    });
  };

  private onPageChange = (page: number, sizePerPage: number) => {
    this.setState(
      {
        page: page,
        showBulkSelectionMessage: false,
      },
      () => this.loadK1ShareHolderDetails()
    );
  };

  private onSortChange: any = (sortName: any, sortOrder: any, column: any) => {
    this.setState(
      {
        sortName: sortName,
        sortOrder: sortOrder == "asc" ? "asc" : "desc",
      },
      () => this.loadK1ShareHolderDetails()
    );
  };

  private onSearchChange = (event: any) => {
    this.setState(
      {
        searchText: event.target.value,
        page: 1,
      },
      () => this.loadK1ShareHolderDetails()
    );
  };

  private onRowSelect = (row: any, isSelected: any, e: any) => {
    let newSelectedK1Partners = [...this.state.selectedK1Partners];
    let unSelectedRows = this.state.unSelectedRows;

    if (
      e.target.tagName !== "BUTTON" &&
      e.target.tagName !== "I" &&
      e.target.tagName !== "SPAN" &&
      e.target.tagName !== "A"
    ) {
      if (isSelected) {
        newSelectedK1Partners.push(this.props.k1Data[row.rowIndex]);
        if (this.state.isBulkSelectionEnabled) {
          unSelectedRows = unSelectedRows.filter(
            (x) => x.shareHolderId != row.shareHolderId
          );
        }
      } else {
        newSelectedK1Partners = newSelectedK1Partners.filter(
          (newk1Partner) => newk1Partner.shareHolderId != row.shareHolderId
        );
        if (this.state.isBulkSelectionEnabled) {
          unSelectedRows.push(
            this.props.k1Data.filter(
              (x) => x.shareHolderId == row.shareHolderId
            )[0]
          );
        }
      }
      this.state.isBulkSelectionEnabled
        ? this.setState({
            selectedK1Partners: newSelectedK1Partners,
            unSelectedRows: unSelectedRows,
          })
        : this.setState({ selectedK1Partners: newSelectedK1Partners });
    }
  };

  private updateSelectedK1ShareHolder = (k1Partner: IK1ShareHolderDetails) => {
    let selectedK1Partners = [...this.state.selectedK1Partners];
    const index = selectedK1Partners.findIndex(
      (x) => x.id == k1Partner.id && x.PIN == k1Partner.PIN
    );

    if (index != NO_INDEX) {
      selectedK1Partners.splice(index, 1, k1Partner);
    }
    this.setState({ selectedK1Partners: selectedK1Partners });
  };

  private onSelectAll = (isSelected: any, rows: any[], e: any) => {
    let newSelectedK1Partners = [...this.state.selectedK1Partners];
    if (isSelected) {
      rows.forEach((row) => {
        let index = newSelectedK1Partners.findIndex(
          (x) =>
            x.shareHolderId === this.props.k1Data[row.rowIndex].shareHolderId
        );
        if (index == -1)
          newSelectedK1Partners.push(this.props.k1Data[row.rowIndex]);
      });
      this.setState({
        showBulkSelectionMessage: true,
      });
    } else {
      rows.forEach((row) => {
        newSelectedK1Partners = newSelectedK1Partners.filter(
          (newK1Partner) => newK1Partner.shareHolderId != row.shareHolderId
        );
      });
      this.setState({
        showBulkSelectionMessage: false,
      });
    }
    this.setState({
      selectedK1Partners: !this.state.isBulkSelectionEnabled
        ? newSelectedK1Partners
        : [],
      isBulkSelectionEnabled: false,
      unSelectedRows: [],
    });
  };

  onCloseDetailModal = () => {
    this.setState({
      showDetailPopup: false,
    });
  };

  onCloseEmailModal = () => {
    this.setState({
      showEmailPopup: false,
    });
  };

  private editPartnerDetails = (rowIndex: number) => {
    let financialYear = this.props.k1Data[rowIndex].financialYearEnd;
    let partner = this.props.k1Data[rowIndex];
    if (!financialYear) {
      this.setState({
        showEmailPopup: true,
        k1Partner: partner,
      });
    } else {
      this.setState({
        showDetailPopup: true,
        k1Partner: partner,
      });
    }
  };

  private downloadSelectedK1s = () => {
    let param: any = this.props.match.params;
    if (this.state.selectedK1Partners.length == 0) {
      MarsNotifier.Warning(
        K1ShareHolderConstants.WarningMessage.CheckBoxSelect,
        null
      );
    } else {
      if (this.state.selectedK1Partners.length == 1) {
        this.props.downloadIndividualK1DocumentAsync(
          param.clientId,
          this.state.selectedK1Partners[0],
          this.props.taxReturn.engagementType
        );
        this.onClearSelection();
      } else {
        if (this.props.isTrialView) {
          this.props.downloadAllK1DocumentsAsync(
            param.clientId,
            this.props.taxReturn.engagementType,
            this.onClearSelection
          );
        } else if (this._signalrRef && !this.props.isPreviewMode) {
          if (this.state.isBulkSelectionEnabled) {
            this._signalrRef.initSignalR(() =>
              this.props.downloadBulkSelectedK1DocumentAsync(
                param.clientId,
                this.state.unSelectedRows,
                this.props.taxReturn.engagementType,
                this.onClearSelection
              )
            );
          } else {
            this._signalrRef.initSignalR(() =>
              this.props.downloadSelectedK1DocumentAsync(
                param.clientId,
                this.state.selectedK1Partners,
                this.props.taxReturn.engagementType,
                this.onClearSelection
              )
            );
          }
        } else {
          if (this.state.isBulkSelectionEnabled)
            this.props.downloadBulkSelectedK1DocumentAsync(
              param.clientId,
              this.state.unSelectedRows,
              this.props.taxReturn.engagementType,
              this.onClearSelection
            );
          else
            this.props.downloadSelectedK1DocumentAsync(
              param.clientId,
              this.state.selectedK1Partners,
              this.props.taxReturn.engagementType,
              this.onClearSelection
            );
        }
      }
    }
  };

  private downloadAllK1Document = () => {
    let param: any = this.props.match.params;

    if (this.props.isTrialView) {
      this.props.downloadAllK1DocumentsAsync(
        param.clientId,
        this.props.taxReturn.engagementType,
        this.onClearSelection
      );
    } else if (this._signalrRef && !this.props.isPreviewMode) {
      this._signalrRef.initSignalR(() =>
        this.props.downloadK1DocumentAsync(
          param.clientId,
          this.props.taxReturn.engagementType,
          this.onClearSelection
        )
      );
    } else {
      this.props.downloadK1DocumentAsync(
        param.clientId,
        this.props.taxReturn.engagementType,
        this.onClearSelection
      );
    }
  };

  private onSelectDownloadOption = (item: any) => {
    const value: number = parseInt(item.value);
    switch (value) {
      case 1:
        this.downloadAllK1Document();
        break;
      case 2:
        this.downloadSelectedK1s();
        break;
    }
  };

  private onClearSelection = () => {
    this.setState({
      selectedK1Partners: [],
      isBulkSelectionEnabled: false,
      showBulkSelectionMessage: false,
    });
  };

  private onSendMail = () => {
    let emptyEmail: boolean = false;
    let param: any = this.props.match.params;
    let partners: IK1ShareHolderDetails[] = [];
    if (this.state.selectedK1Partners.length == 0) {
      MarsNotifier.Warning(
        K1ShareHolderConstants.WarningMessage.CheckBoxSelect,
        null
      );
    } else {
      if (this.state.isBulkSelectionEnabled) {
        this.props.bulkSendMailToShareHolder &&
          this.props.bulkSendMailToShareHolder(
            param.clientId,
            this.state.unSelectedRows,
            this.onAfterSendMail
          );
      } else {
        this.state.selectedK1Partners.forEach((k1Partner) => {
          if (k1Partner && k1Partner.email) {
            partners.push(k1Partner);
          } else {
            emptyEmail = true;
            return;
          }
        });
        if (!emptyEmail) {
          this.props.sendMailToShareHolder &&
            this.props.sendMailToShareHolder(
              param.clientId,
              partners,
              this.onAfterSendMail
            );
        } else {
          MarsNotifier.Warning(
            K1ShareHolderConstants.WarningMessage.EmailAdrressEmpty,
            null
          );
        }
      }
    }
  };

  private onAfterSendMail = () => {
    this.setState(
      {
        isBulkSelectionEnabled: false,
        showBulkSelectionMessage: false,
      },
      () => this.loadK1ShareHolderDetails()
    );
    this.loadK1ShareHolderDetails();
    this.onClearSelection();
  };

  private onResendMail = (shareHolderId: number) => {
    const shareHolder = this.props.k1Data.filter(
      (x) => x.shareHolderId === shareHolderId
    );
    if (shareHolder) {
      const param: any = this.props.match.params;
      this.props.sendMailToShareHolder &&
        this.props.sendMailToShareHolder(
          param.clientId,
          shareHolder,
          this.loadK1ShareHolderDetails
        );
    }
  };

  validateEmail = (partner: IK1ShareHolderDetails) => {
    if (!partner.email) {
      MarsNotifier.Warning(
        K1ShareHolderConstants.WarningMessage.EmailAdrressEmpty,
        null
      );
      return false;
    }
    return true;
  };

  private onBulkSelectionChange = (isEnabled: boolean) => {
    let showBulkSelectionMessage = false;

    this.setState({
      isBulkSelectionEnabled: isEnabled,
      showBulkSelectionMessage: showBulkSelectionMessage,
    });
  };

  public render() {
    const { isPreviewMode, companyName, taxReturn, isClientView } = this.props;
    const taxpayerName = isPartnership(taxReturn)
      ? taxReturn.partnership.name
      : "";
    const type: string =
      this.props.taxReturn &&
      GetK1EngagementType(this.props.taxReturn.engagementType);
    const text: string = "Select " + type + " to Email";
    return (
      <div
        className="col-xs-6 col-sm-6 col-md-12 col-lg-12 content-wrapper-2"
        style={{ minWidth: "100%" }}
      >
        <div className="displayFlex margin-bottom-40 margin-top-20">
          <h3
            className="k1-title displayFlex3 no-padding"
            data-test-auto="12789752-d436-4c73-bb14-2415a364883f"
          >
            Distribute K-1 Documents Electronically{" "}
          </h3>
          <div className="k1Search">
            <input
              type="text"
              id="searchText"
              placeholder="Search"
              value={this.state.searchText}
              maxLength={500}
              onChange={this.onSearchChange}
              aria-label="Enter search text"
            />
            <button className="searchIcon" aria-label="Search">
              <SvgSearchIcon />
            </button>
          </div>
          <ButtonComponent
            data-test-auto={"fe3c98ec-5d42-4057-a65f-fe331639255f"}
            onClick={this.onSendMail}
            title={"Email Documents"}
            disabled={
              this.props.isDraftReturn
                ? true
                : isPreviewMode
                ? isClientView
                  ? !this.props.taxReturn.documentSettings.deliverySettings
                      .allowUserToDistributeK1
                  : true
                : false
            }
            variant={"outline-primary"}
            size={"medium"}
            buttonClassName="k1-step-layout-button email-documents"
          >
            <SvgEmailIcon />
            <span>{"Email Documents"}</span>
          </ButtonComponent>
          <DownloadK1s
            match={this.props.match}
            taxReturn={this.props.taxReturn}
            onSelect={this.onSelectDownloadOption}
            isDraftReturn={this.props.isDraftReturn}
          />
          <div className="download-helper">
            <span
              className="sub-title"
              data-test-auto="54514737-3cfa-48e0-a9fb-a7d2dde17064"
            >
              {" "}
              {text}{" "}
            </span>
          </div>
        </div>
        <DistributeElectronicTable
          onPageChange={this.onPageChange}
          onSortChange={this.onSortChange}
          onSearchChange={this.onSearchChange}
          onRowSelect={this.onRowSelect}
          onSelectAll={this.onSelectAll}
          pageNo={this.state.page}
          pageSize={pageSize}
          k1Data={this.props.k1Data}
          selectedK1Partners={this.state.selectedK1Partners}
          editPartnerDetails={this.editPartnerDetails}
          downloadIndividualK1DocumentAsync={
            this.props.downloadIndividualK1DocumentAsync
          }
          taxReturn={this.props.taxReturn}
          match={this.props.match}
          onResendMail={this.onResendMail}
          isK1Loading={this.props.isK1Loading}
          onBulkSelectionChange={this.onBulkSelectionChange}
          showBulkSelectionMessage={this.state.showBulkSelectionMessage}
          requestK1ShareHolderActivity={this.props.requestK1ShareHolderActivity}
          isDraftReturn={this.props.isDraftReturn}
        />
        <EditK1PartnerDetails
          showState={this.state.showDetailPopup}
          onCancel={this.onCloseDetailModal}
          k1Partner={this.state.k1Partner}
          match={this.props.match}
          updateK1ShareHolderDetail={
            this.state.readonly === true
              ? undefined
              : this.props.updateK1ShareHolderDetail
          }
          loadK1ShareHolderDetails={this.loadK1ShareHolderDetails}
          type={type}
          isPreviewMode={isPreviewMode}
          companyName={companyName}
          taxpayerName={taxpayerName}
          updateSelectedK1ShareHolder={this.updateSelectedK1ShareHolder}
        />
        <EditK1PartnerEmail
          showState={this.state.showEmailPopup}
          onCancel={this.onCloseEmailModal}
          k1Partner={this.state.k1Partner}
          updateK1ShareHolderEmail={
            this.state.readonly === true
              ? undefined
              : this.props.updateK1ShareHolderEmail
          }
          match={this.props.match}
          type={type}
          updateSelectedK1ShareHolder={this.updateSelectedK1ShareHolder}
        />

        {
          <SignalRWebSocket
            ref={(ins) => (this._signalrRef = ins)}
            match={this.props.match}
            requestMyDownload={this.props.requestMyDownload}
          />
        }
      </div>
    );
  }
}
