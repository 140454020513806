import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { ButtonComponent } from "cp-common-ui-components";
import Pagination, { PaginationPageProps } from "../Common/Pagination";
import K1ShareHolderActivityTable from "./K1ShareHolderActivityTable";
import { IK1ShareHolderActivity } from "src/core/domain/models/IK1Distribute";

export interface ISortColumn {
  sortBy: string;
  sortOrder: string;
}

export interface K1ShareHolderActivityModel {
  eventName: string;
  actedBy: string;
  actedOn: string;
}

type K1ShareHolderActivityModalProps = {
  onClose: () => void;
  requestK1ShareHolderActivity: (
    clientId: string,
    documentId: number,
    shareholderId: number,
    callback?: (k1ShareHolderActivity: IK1ShareHolderActivity[]) => void
  ) => void;
  clientGuid: string;
  documentId: number;
  shareHolderId: number;
};

const K1ShareHolderActivityModal = ({
  onClose,
  requestK1ShareHolderActivity,
  clientGuid,
  documentId,
  shareHolderId,
}: K1ShareHolderActivityModalProps) => {
  const [k1ActivityArray, setK1ActivityArray] = useState<
    IK1ShareHolderActivity[]
  >([]);
  const [page, setPage] = useState<PaginationPageProps>({
    pageIndex: 1,
    pageSize: 10,
  });
  const [sortColumn, setSortColumn] = useState<ISortColumn>({
    sortBy: "actedOn",
    sortOrder: "desc",
  });
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    requestK1ShareHolderActivity(
      clientGuid,
      documentId,
      shareHolderId,
      (k1ShareHolderActivity: IK1ShareHolderActivity[]) => {
        setK1ActivityArray(k1ShareHolderActivity);
        setIsLoading(false);
      }
    );
  }, []);

  const handleSort = (columnName: string) => {
    const newSortColumn = JSON.parse(JSON.stringify(sortColumn));
    if (sortColumn.sortBy === columnName) {
      newSortColumn.sortOrder =
        sortColumn.sortOrder === "desc" ? "asc" : "desc";
    } else {
      newSortColumn.sortBy = columnName;
      newSortColumn.sortOrder = "desc";
    }
    const newArray = k1ActivityArray.sort((a, b) => {
      if (newSortColumn.sortOrder === "asc") {
        return a[newSortColumn.sortBy].localeCompare(b[newSortColumn.sortBy]);
      } else if (newSortColumn.sortOrder === "desc") {
        return b[newSortColumn.sortBy].localeCompare(a[newSortColumn.sortBy]);
      }
    });
    setK1ActivityArray(newArray);
    setSortColumn(newSortColumn);
    setPage({ ...page, pageIndex: 1 });
  };

  const goToPage = (newPageIndex: number, newPageSize: number) => {
    const totalPages = Math.ceil(k1ActivityArray.length / page.pageSize);
    if (
      (newPageIndex !== page.pageIndex &&
        newPageIndex <= totalPages &&
        newPageIndex >= 1) ||
      newPageSize !== page.pageSize
    ) {
      const newPage = { ...page };
      newPage.pageIndex = newPageIndex;
      newPage.pageSize = newPageSize;
      setPage(newPage);
    }
  };

  return (
    <>
      <Modal.Body className="k1ActivityModalBody">
        <K1ShareHolderActivityTable
          isLoading={isLoading}
          k1ActivityData={k1ActivityArray.slice(
            (page.pageIndex - 1) * page.pageSize,
            (page.pageIndex - 1) * page.pageSize + page.pageSize
          )}
          sortColumn={sortColumn}
          handleSort={handleSort}
        />
        <Pagination
          count={k1ActivityArray.length}
          page={page}
          goToPage={goToPage}
        />
      </Modal.Body>
      <Modal.Footer className="k1ActivityModalFooter">
        <ButtonComponent
          data-test-auto="9ff5fabf-fb69-4a7e-b3dc-f2365b860dc9"
          disabled={false}
          onClick={onClose}
          variant={"outline-tertiary"}
          size={"medium"}
        >
          Cancel
        </ButtonComponent>
        <ButtonComponent
          data-test-auto="9ff5fabf-fb69-4a7e-b3dc-f2365b860dc9"
          disabled={false}
          onClick={onClose}
          variant={"primary"}
          size={"medium"}
        >
          OK
        </ButtonComponent>
      </Modal.Footer>
    </>
  );
};

export default K1ShareHolderActivityModal;
