import * as React from "react";

interface SignBehalfSpouseProps {
    signChecked: boolean;
    handleSignOn(isChecked: boolean): void;
    isSpouseBehalfCheckDisabled?: boolean;
}

export class SignBehalfSpouse extends React.Component<SignBehalfSpouseProps> {
    constructor(props: any) {
        super(props);
    }

    onSignOnChanged = (e: any) => {
        this.props.handleSignOn(e.target.checked);
    };

    render() {
        return (
            <div className="checkbox">
                <ul className="ugCheckArea">
                    <li className="eachUGCheck">
                        <input
                            type="checkbox"
                            className="ugCheckbox"
                            data-test-auto="2BA6893F-0D36-4F45-8185-47B245FAB6E0"
                            checked={this.props.signChecked}
                            onChange={this.onSignOnChanged}
                            data-name="controller-esign-consent"
                            id="controller-esign-consent"
                        />
                        <label
                            data-test-auto="2BA6893F-0D36-4F45-8185-47B245FAB6E0"
                            htmlFor="controller-esign-consent"
                            data-name="controller-esign-consent"
                            id="controller-esign-consent"
                        >
                            Sign on behalf of spouse
                        </label>
                    </li>
                </ul>
            </div>
        );
    }
}
