import * as React from "react";
import { IKBAAnswers, IKBAAnswer, IKBAAnswerChoice, IKBATransactionResponse } from "../../core/domain/models/IKBA";
import { Form, Row, Col } from "react-bootstrap";
import { match } from "react-router";
import { Shimmer } from "../Common/Shimmer/Shimmer";
import { KBATransactionResultType } from "../../core/common/Enums";
import { StepLayout } from "../Common/StepLayout/StepLayout";
import { Body } from "../Common/StepLayout/Body";
import { SignatureType } from '../../core/common/Enums';
import { IDialogBox } from '../../core/utilities/ui/DialogBox';
import { container } from '../../startup/inversify.config';
import { TYPES } from '../../startup/types';
import { Common, KBAConstants } from '../Common/Constants';
import { MarsNotifier } from '../Common/Notification/MarsNotifier';
import { SignProcessSteps } from '../../components/Home/TaxpayerSignFlowManager';
import { logger } from '../../routes';
import { NextButtonIcon } from "../Common/Icons/SvgIcons";
import { handleKeyDown } from "../Helper/HelperFunction";

export type IKBAComponentProps =
    {
        match: match;
        response: IKBATransactionResponse;
        loadKBAQuestions(clientGuid: string, failureCallback: () => void): void;
        validateKBAAnswers(clientGuid: string, data: IKBAAnswers, successCallback: (isNextQuestionSet?: boolean) => void, failureCallback: () => void): void;
        clearParentState: () => void;
        refreshKBAStatus(clientGuid: string, successCallback?: () => void): void;
        onCompletion?(): void;
        updateDocumentSignatureSettingModel(clientId: string, signatureType: number, callback: any): void;
        onKBAFailure(): void;
        refreshTaxDocument: (clientGuid: string) => void;
        updateLastVisitedStep: (clientGuid: string, step: SignProcessSteps, successCallback?: () => void) => void;
        isPreviewMode: boolean;
    }

export interface IKBAComponentState {
    currentQuestionIndex: number;
    remainingQuestions: number;
    isChoiceSelected: boolean;
    errorText: string;
    kbaAnswer: IKBAAnswers;
    answer: IKBAAnswer;
    choice: IKBAAnswerChoice;
    retryCount: number;
}
const dialogBox = container.get<IDialogBox>(TYPES.IDialogBox);
export class KBAComponent extends React.Component<IKBAComponentProps, IKBAComponentState> {

    constructor(props: IKBAComponentProps) {
        super(props);
        this.props.clearParentState();
        this.clearComponentState(true);
    }

    componentDidMount() {
        const param: any = this.props.match.params;
        this.props.loadKBAQuestions(param.clientId, this.onKBAIdentityVerificationFailure);
        logger.trackTrace('KBA Page', { "ClientId": param.clientId, "PreviewMode": this.props.isPreviewMode });
    }

    static getDerivedStateFromProps(props: IKBAComponentProps, state: IKBAComponentState) {
        if (!props.response.questions || !props.response.questions.question) {
            return null;
        } else {
            return {
                remainingQuestions: state.remainingQuestions
                    ? state.remainingQuestions
                    : props.response.questions.question.length
            } as IKBAComponentState;
        }
    }

    private clearComponentState = (isConstructor: boolean) => {
        if (isConstructor) {
            this.state = {
                currentQuestionIndex: 0,
                remainingQuestions: 0,
                isChoiceSelected: false,
                errorText: "",
                kbaAnswer: {
                    transactionId: 0,
                    tryCount: 0,
                    questionSetId: 0,
                    answers: []
                },
                answer: {
                    questionId: 0,
                    choice:
                    {
                        choiceId: 0,
                        choiceText: ''
                    }
                },
                choice: {
                    choiceId: 0,
                    choiceText: ''
                },
                retryCount: 0
            }
        }
        else {
            this.setState({
                currentQuestionIndex: 0,
                remainingQuestions: 0,
                isChoiceSelected: false,
                errorText: "",
                kbaAnswer: {
                    transactionId: 0,
                    tryCount: 0,
                    questionSetId: 0,
                    answers: []
                },
                answer: {
                    questionId: 0,
                    choice:
                    {
                        choiceId: 0,
                        choiceText: ''
                    }
                },
                choice: {
                    choiceId: 0,
                    choiceText: ''
                }
            });
        }
    }

    private handleRadioChange = (e: any) => {
        const param: any = this.props.match.params;
        this.setState({ isChoiceSelected: true, errorText: "", choice: { choiceId: e.target.value, choiceText: e.target.name } });
        logger.trackTrace(`selected choiceId: ${e.target.value} choiceText: ${e.target.label}`, { "ClientId": param.clientId, "PreviewMode": this.props.isPreviewMode });
    }

    private handleQuestionChange = () => {
        this.validateCurrentQuestion();
    }

    private validateCurrentQuestion = () => {
        if (!this.state.isChoiceSelected) {
            this.setState({ errorText: "Please select a valid choice" });
        }
        else if (!this.props.response.questions.question[this.state.currentQuestionIndex]) {
            this.setState({ errorText: "Invalid choice" });
        }
        else {
            const param: any = this.props.match?.params;
            logger.trackTrace(`questions loaded: ${this.props.response.questions}`, { "ClientId": param.clientId, "PreviewMode": this.props.isPreviewMode });
            const currIndex: number = this.state.currentQuestionIndex;
            logger.trackTrace(`current question index: ${currIndex}`, { "ClientId": param.clientId, "PreviewMode": this.props.isPreviewMode });
            const count = this.props.response.questions.question.length - 1;
            logger.trackTrace(`totals questions: ${count}`, { "ClientId": param.clientId, "PreviewMode": this.props.isPreviewMode });
            const remQuestions = this.state.remainingQuestions;
            logger.trackTrace(`remaining questions: ${remQuestions}`, { "ClientId": param.clientId, "PreviewMode": this.props.isPreviewMode });
            const allChoices: IKBAAnswerChoice[] = this.props.response.questions.question[this.state.currentQuestionIndex].choices;
            logger.trackTrace(`choices loaded for current question: ${this.props.response.questions.question[this.state.currentQuestionIndex].choices}`, { "ClientId": param.clientId, "PreviewMode": this.props.isPreviewMode });

            const selectedChoice: IKBAAnswerChoice | undefined = allChoices.find((el) => {
                if (el.choiceId == this.state.choice.choiceId) {
                    return el;
                }
            });
            const userChoice: IKBAAnswerChoice = selectedChoice ? selectedChoice : allChoices[0];
            const answer: IKBAAnswer = {
                questionId: this.props.response.questions.question[this.state.currentQuestionIndex].questionId,
                choice: userChoice
            };

            let kbaAnswer = this.state.kbaAnswer;

            kbaAnswer.answers.push(answer);
            kbaAnswer.transactionId = this.props.response.transactionId;
            kbaAnswer.questionSetId = this.props.response.questions.questionSetId;
            kbaAnswer.tryCount = this.state.retryCount;
            if (currIndex < count || this.props.response.questions.question.length == this.state.remainingQuestions) {
                const newIndex = currIndex + 1;
                this.setState({
                    currentQuestionIndex: newIndex, remainingQuestions: (remQuestions - 1), isChoiceSelected: false,
                    choice: {
                        choiceId: 0, choiceText: ''
                    },
                    kbaAnswer: {
                        transactionId: this.props.response.transactionId,
                        questionSetId: this.props.response.questions.questionSetId,
                        tryCount: kbaAnswer.tryCount,
                        answers: kbaAnswer.answers
                    }
                });
            }
        }
    }

    private handleSubmit = (e: any) => {
        e.currentTarget.disabled = true;
        const param: any = this.props.match.params;
        this.validateCurrentQuestion();
        if (this.state.isChoiceSelected) {
            this.props.validateKBAAnswers(param.clientId, this.state.kbaAnswer, this.onSubmitSuccessful, this.onSubmitFailed);
        }
    }

    private onSubmitSuccessful = (isNextQuestionSet?: boolean) => {
        const param: any = this.props.match.params;
        this.clearComponentState(false);
        if (!isNextQuestionSet) {
            this.props.refreshKBAStatus(param.clientId, () => { this.props.onCompletion && this.props.onCompletion() });
        }
    }

    confirmRedirectToManualSign = () => {
        const param: any = this.props.match.params;
        //Updating last visited step in case TP refreshes the page on KBA failure
        this.props.updateLastVisitedStep(param.clientId, SignProcessSteps.ManualSignDownload);
        dialogBox.alert(KBAConstants.KBARetryExceedMessage,
            () => {
                this.onKBARetryExceed()
            });
    }
    onKBAIdentityVerificationFailure = () => {
        const param: any = this.props.match.params;
        this.props.updateLastVisitedStep(param.clientId, SignProcessSteps.ManualSignDownload);
        dialogBox.alert(KBAConstants.KBAIdentificationFailure,
            () => {
                this.onKBARetryExceed()
            });
    }
    private onKBARetryExceed = () => {
        const param: any = this.props.match.params;
        this.props.refreshTaxDocument(param.clientId);
        this.props.onKBAFailure();
    }

    private onSubmitFailed = () => {
        const param: any = this.props.match.params;
        this.setState({ retryCount: this.state.retryCount + 1 });
        if (this.state.retryCount >= 3) {
            this.confirmRedirectToManualSign();
        }
        else {
            MarsNotifier.Error(KBAConstants.KBAFailureMessage, "");
            this.props.loadKBAQuestions(param.clientId, this.onKBAIdentityVerificationFailure);
            this.clearComponentState(false);
        }
    }

    private isErrorState = () => {
        return (this.props.response.transactionStatus.toString() === KBATransactionResultType[KBATransactionResultType.error] || this.props.response.transactionStatus.toString() === KBATransactionResultType[KBATransactionResultType.failed])
    }

    public render() {

        return (<div><StepLayout className={"kba-border"}>
            <Body height={100}
                automationTestId={"11BB28B5-42F3-4571-B567-20700B1740B6"}>
                <div className = "kba-component">
                    <h4 className="kba-header">
                        Security Questions
                   </h4>
                    <div className="content-header">
                        IRS required Disclosure. Please verify your identity by answering the following questions. These questions are generated from a third
                        party's database. This process does not access or impact your credit report and the third party does not have access to your taxpayer information.
                   </div>
                    <div className="component-header">
                        Please answer the following:
                    </div>
                    <div data-test-auto="3A17285E-0BA8-4410-B006-ECAAAEE56BAE">
                        {this.state.errorText ? <div className="error-container">{this.state.errorText}</div> : null}
                        <div className="kba-questions content-paragraph" data-test-auto="816B863F-7F5E-4A93-8321-2F6EA081A301">
                            {this.props.response.questions?.question?.length > 0 ?
                                this.props.response.questions.question[this.state.currentQuestionIndex]?.question :
                                this.isErrorState() ?
                                    null : <Shimmer height={25} />}
                        </div>
                        <div className="content-paragraph" data-test-auto="22A75A11-9F78-4C90-9E2A-31271A2A8518">
                            <Form.Group as={Row}>
                                <Col sm={10}>
                                    {
                                        this.props.response.questions?.question?.length > 0 ?
                                            this.props.response.questions?.question[this.state.currentQuestionIndex]?.choices?.map((choice, index) => {
                                                return (
                                                    <div className='ugRadioInput' key={choice.choiceId}>
                                                        <input
                                                            type="radio"
                                                            data-test-auto="2BA6893F-0D36-4F45-8185-47B246FAB6F0"
                                                            checked={choice.choiceId == this.state.choice.choiceId ? true : false}
                                                            onChange={this.handleRadioChange}
                                                            onKeyDown={(e: any) => handleKeyDown(e, () => this.handleRadioChange(e))}
                                                            name={choice.choiceText}
                                                            value={choice.choiceId}
                                                            data-name={"kba-radio-" + (index + 1)}
                                                            id={"kba-radio-" + (index + 1)}
                                                        />
                                                        <label
                                                            data-test-auto="2BA6893F-0D36-4F45-8185-47B246FAB6F0"
                                                            htmlFor={"kba-radio-" + (index + 1)}
                                                            data-name={"kba-radio-" + (index + 1)}
                                                            id={"kba-radio-" + (index + 1)}
                                                        >
                                                            {choice.choiceText}
                                                        </label>
                                                    </div>
                                                )
                                            }) :
                                            this.isErrorState() ?
                                                null : <Shimmer lineCount={5} />
                                    }
                                </Col>
                            </Form.Group>
                        </div>
                    </div>
                </div>
            </Body>
            </StepLayout>
            <div className="download-footer steps-footer">
            {
                this.state.remainingQuestions > 1 ?
                <button
                    id="awesome-multi-steps-btn-next"
                    disabled={!this.state.isChoiceSelected}
                    onClick={this.handleQuestionChange}
                    data-test-auto="73A4B994-8D10-40E8-A98B-C41FCFE78E44"
                    className=" btn btn-primary rounded kba-button"
                >
                    <label style={{ paddingRight : "3px", width:"35px", height:"24px"}}>Next</label>
                    <NextButtonIcon />
                </button> :
                <button
                    id="awesome-multi-steps-btn-next"
                    onClick={this.handleSubmit}
                    disabled={!this.state.isChoiceSelected}
                    data-test-auto="A2C1D707-9F35-44AA-9E07-D9E37C179DCD"
                    className=" btn btn-primary rounded kba-button"
                >
                    <label style={{paddingRight : "3px", width:"35px", height:"24px"}}>Next</label>
                    <NextButtonIcon />
                </button>
            }         
            </div></div>);
    }
}
