
import { connect } from 'react-redux';
import MobileOTP from "./MobileOTP";
import * as HeaderStore from "../../../store/HeaderStore";
import * as CCROTPReducer from "../../../store/CCRecipient/OTPStore";





export default connect(
    (state: any) => ({
        headerInfoState: state.HeaderPageReducer,
        OTPPageState: state.CCRecipientOTPData

    }),
    {
        ...HeaderStore.actionCreators,
        ...CCROTPReducer.actionCreators

    }
)(MobileOTP as any);