import * as React from 'react';
import { MyDownloadsConstants } from '../../Common/Constants';
import { SvgDownloadButtonIcon } from '../Icons/SvgIcons';
import { ButtonComponent } from 'cp-common-ui-components';

export enum StepLayoutPlacement {
    Left = 0,
    Center = 1,
    Right = 2
}

interface StepLayoutProps {
    title?: string;
    className?: string;
    height?: string;
    placement?: StepLayoutPlacement | StepLayoutPlacement.Center;
    lg?: number;
    md?: number;
    sm?: number;
    xs?: number;
    width?: number;
    marginTop?: number;
    marginBottom?: number;
    showDownloadButton?: boolean;
    handleDownload?: () => void;
    customDownloadText?: string;
    customDownloadTooltip?: string;
    enlargeBtnSize?: boolean;
    enlargeDownloadIcon?: boolean;
    showCustomButton?: boolean;
    handleCustomButtonClick?: () => void;
    customButtonText?: string;
    customButtonIcon?: any;
    customButtonTooltip?: string;
    customIconHeight?: number;
    customIconWidth?: number;
    disableCustomButton?: boolean;
    disableDownloadButton?: boolean;
}

const DEFAULT_WIDTH_LG: number = 12;
const DEFAULT_WIDTH_MD: number = 12;
const DEFAULT_WIDTH_SM: number = 12;
const DEFAULT_WIDTH_XS: number = 12;

export const StepLayout: React.FunctionComponent<StepLayoutProps> = ({
    title, className, placement, width, lg,
    md,
    sm,
    xs, height,
    marginTop, marginBottom, children, showDownloadButton, handleDownload, customDownloadText, enlargeBtnSize, enlargeDownloadIcon,     
    showCustomButton, handleCustomButtonClick, customButtonText, customButtonIcon, customButtonTooltip, customDownloadTooltip, customIconHeight, customIconWidth, disableCustomButton, disableDownloadButton
}) => {

    let getBodyWidthClass = () => {

        let bodyWidthClass: string = "";

        if (lg) {
            bodyWidthClass = bodyWidthClass.concat(' col-lg-', lg.toString());
        }
        else {
            bodyWidthClass = bodyWidthClass.concat(' col-lg-', DEFAULT_WIDTH_LG.toString());
        }

        if (md) {
            bodyWidthClass = bodyWidthClass.concat(' col-md-', md.toString());
        }
        else {
            bodyWidthClass = bodyWidthClass.concat(' col-md-', DEFAULT_WIDTH_MD.toString());
        }

        if (sm) {
            bodyWidthClass = bodyWidthClass.concat(' col-sm-', sm.toString());
        }
        else {
            bodyWidthClass = bodyWidthClass.concat(' col-sm-', DEFAULT_WIDTH_SM.toString());
        }

        if (xs) {
            bodyWidthClass = bodyWidthClass.concat(' col-xs-', xs.toString());
        }
        else {
            bodyWidthClass = bodyWidthClass.concat(' col-xs-', DEFAULT_WIDTH_XS.toString());
        }

        return bodyWidthClass;
    }

    let getPlacementClass = () => {

        switch (placement) {
            case StepLayoutPlacement.Left:
                return " left ";
            case StepLayoutPlacement.Center:
                return " center ";
            case StepLayoutPlacement.Right:
                return " right ";
            default:
                return " center ";
        }
    }


    let getStepLayoutHeightStyle = () => {

        let stepLayoutHeight: string = "";

        marginTop = marginTop ? marginTop : 0;
        const headerHeight: number = title ? 50 : 0;

        stepLayoutHeight = `calc(100% - ${marginTop + headerHeight}px)`;

        return stepLayoutHeight;
    }

    const CustomSvgIcon: any = customButtonIcon;
    return (<div className={`step-layout ${className} ${getPlacementClass()} ${getBodyWidthClass()} `}
        style={{
            marginTop: marginTop + "px",
            marginBottom: marginBottom + "px",

        }}
        data-test-auto="981BE1C7-A826-4AD6-B9AB-AD55A67CF666">
        {title && <div
            className={"step-layout-header col-lg-12"}>{title}
            <div className="row float-right pr-5">
            {showCustomButton && showCustomButton == true &&
                <ButtonComponent
                    onClick={handleCustomButtonClick}
                    title={customButtonTooltip}
                    buttonClassName={"custom-step-layout-button"}
                    variant={"outline-primary"}
                    size={"medium"}
                    disabled={disableCustomButton}
                >
                    {customButtonIcon && <CustomSvgIcon width={customIconWidth || 26} height={customIconHeight || 24} />}
                    <span className='pl-2'>{!customButtonText ? '': customButtonText}</span>
                </ButtonComponent>
            }
            {showDownloadButton && showDownloadButton == true &&
                <ButtonComponent
                    data-test-auto={"fe3c98ec-5d42-4057-a65f-fe331639255f"}
                    onClick={handleDownload}
                    buttonClassName={"custom-step-layout-button ml-3"}
                    title={!customDownloadTooltip ? MyDownloadsConstants.DownloadDocument : customDownloadTooltip}
                    variant={"outline-primary"}
                    size={"medium"}
                    disabled={disableDownloadButton}
                >
                    <SvgDownloadButtonIcon />
                    <span className='pl-2'>{!customDownloadText ? MyDownloadsConstants.DownloadDocument : customDownloadText}</span>
                </ButtonComponent>
            }
            </div>
           
        </div>
        }



        <div className={"step-layout-content row"}
            style={{
                height: getStepLayoutHeightStyle()
            }}
        >{children}</div>
    </div>);
};
