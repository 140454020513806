import { ApplicationState } from "../../store/index";
import { actionCreators as DownloadableDocumentsStore } from '../../store/DownloadableDocumentsStore';
import { actionCreators as TaxDocumentStore } from '../../store/Common/TaxDocumentStore';
import { actionCreators as ManualSignStore } from '../../store/Sign/ManualSignStore';
import { connect } from 'react-redux';
import { Main } from '../Download/Main'
import { strict } from "assert";
import { IClientProcessViewModel } from '../../core/domain/viewModels/IClientProcessViewModel'
import { actionCreators as SignProcessStore } from '../../store/SignProcess/SignProcessStore';
import { actionCreators as PaperFileReturnStore} from '../../store/PaperFileReturn/PaperFileReturnStore';

function mapStateToProps(state: ApplicationState) {
	return {
		DownloadableDocuments: state.downloadableDocuments,
		TaxDocument: state.taxReturn.taxDocument,
		signedDocuments: state.manualSignData.data,
		headerInfo: state.headerInfo
	};
}

const mapDispatchToProps = (dispatch: any) => {
	return {
		requestDownloadableDocuments: (clientGuid: string) => dispatch(DownloadableDocumentsStore.requestDownloadableDocuments(clientGuid)),
		requestSignedDocuments: (clientGuid: string, forceRefresh?: boolean) => dispatch(ManualSignStore.requestSignedDocuments(clientGuid, forceRefresh)),
		refreshTaxDocument: (clientGuid: string) => dispatch(TaxDocumentStore.requestTaxDocument(clientGuid)),
		downloadTaxReturnDocument: (clientGuid: string, fileName: string) => dispatch(DownloadableDocumentsStore.downloadTaxReturnDocument(clientGuid, fileName)),
		downloadEFileDocument: (clientGuid: string, fileName: string) => dispatch(DownloadableDocumentsStore.downloadEFileDocument(clientGuid, fileName)),
		downloadSignedEFileDocument: (clientGuid: string, fileName: string) => dispatch(DownloadableDocumentsStore.downloadSignedEFileDocument(clientGuid, fileName)),
		downloadTransmittalDocument: (clientGuid: string, fileName: string) => dispatch(DownloadableDocumentsStore.downloadTransmittalDocument(clientGuid, fileName)),
		downloadInvoiceDocument: (clientGuid: string, fileName: string) => dispatch(DownloadableDocumentsStore.downloadInvoiceDocument(clientGuid, fileName)),
		downloadK1Document: (clientGuid: string, fileName: string) => dispatch(DownloadableDocumentsStore.downloadK1Document(clientGuid, fileName)),
		downloadVoucherDocument: (clientGuid: string, fileName: string) => dispatch(DownloadableDocumentsStore.downloadVoucherDocument(clientGuid, fileName)),
		downloadAttachment: (clientGuid: string, fileName: string) => dispatch(DownloadableDocumentsStore.downloadAttachment(clientGuid, fileName)),
		downloadAllDocuments: (clientGuid: string, fileName: string) => dispatch(DownloadableDocumentsStore.downloadAllDocuments(clientGuid, fileName)),
		downloadAllAttatchments: (clientGuid: string, fileName: string) => dispatch(DownloadableDocumentsStore.downloadAllAttatchments(clientGuid, fileName)),
		downloadAllAdditionEsignDocuments: (clientGuid: string, fileName: string) => dispatch(DownloadableDocumentsStore.downloadAllAdditionEsignDocuments(clientGuid, fileName)),
        getMergedPaperFileDocumentStream: (clientId: string, callback?: (url: any) => void) => dispatch(PaperFileReturnStore.getMergedPaperFileDocumentStream(clientId, callback)),
		requestCurrentStepAndUserRole: (clientGuid: string, successCallback?: (clientProcessState: IClientProcessViewModel) => void) => dispatch(SignProcessStore.requestCurrentStepAndUserRole(clientGuid, successCallback)),

	}
}

const connector = connect(mapStateToProps, mapDispatchToProps);

export default connector(Main);
