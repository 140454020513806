
import { connect } from 'react-redux';
import OTPRequest from "./OTPRequest";
import * as headerInfoStore from "../../store/Common/AccountStore";
import * as OTPPageReducer from "../../store/OTP/OTPStore";
import * as HeaderStore from "../../store/HeaderStore";



export default connect(
    (state: any) => ({
        headerInfoState: state.HeaderPageReducer,
        OTPPageState:state.OTPPageReducer
       

    }),
    {
        ...HeaderStore.actionCreators,
        ...OTPPageReducer.actionCreators
      
    }
)(OTPRequest as any);