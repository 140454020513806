import { ApplicationState } from "../../store/index";
import { actionCreators as accountAction } from '../../store/Common/AccountStore';
import { CcRecipientDefaultLayout } from "./CcRecipientDefaultLayout";
import { connect } from 'react-redux';
import { withRouter } from "react-router";
import { ClientType } from '../../core/common/Enums';
import { createLoadingSelector } from '../../store/selectors';
const loadingSelector = createLoadingSelector(['RECIPIENT_HEADERINFO', 'CCRECIPIENT_DOCUMENTS_FOR_DOWNLOAD']);

function mapStateToProps(state: ApplicationState) {
    return {
        recipientHeaderInfo: state.recipientHeaderInfo,
        loading: loadingSelector(state),
    };
}

const mapDispatchToProps = (dispatch: any) => {
    return {
        requestRecipientHeaderInfo: (id: string) => dispatch(accountAction.requestRecipientHeaderInfo(id)),

        requestHeaderInfo: (id: string) => dispatch(accountAction.requestHeaderInfo(id)),

        refreshToken: (clientId: string, callback: any) => dispatch(accountAction.refreshToken(clientId, callback())),

        getSessionTimeOutSeconds: (clientId: string, callBack?: (sessionTimeout: number) =>void) => dispatch(accountAction.getSessionTimeOutSeconds(clientId, callBack)),

        updateMobileNumber: (clientGuid: string, id: string, number: string, countryCodeValue: string,
            ssnValue: string, clientType: ClientType, callback: () => void) =>
            dispatch(accountAction.updateMobileNumber(clientGuid, id, number, countryCodeValue, ssnValue, clientType, callback)),
        requestGroupHeaderInfo: (id: string) => dispatch(accountAction.requestGroupHeaderInfo(id)),
        logout: (clientGuid: string, callback: (id: string) => void) => dispatch(accountAction.logout(clientGuid, callback)),
        requestDelegateeHeaderInfo: (id: string) => dispatch(accountAction.requestDelegateeHeaderInfo(id)),
        dispatch
    }
}

const connector = connect(mapStateToProps, mapDispatchToProps);

export default withRouter(connector(CcRecipientDefaultLayout));

