import { Action, Reducer } from 'redux';
import { AppThunkAction } from './';
import { actionTypes } from './ActionTypes';
import { StatusType, NotificationAction } from './Common/NotificationStore';
import { IDocumentReviewModel, initialDocumentReviewModel } from '../core/domain/viewModels/IDocumentReviewModel';
import { IAttachmentDownloadViewModel } from '../core/domain/viewModels/IAttachmentDownloadViewModel';
import { initializeAxios } from '../core/services/dataAccess/DataService.Axios'
import { AxiosResponse } from 'axios';
import { AxiosRequestConfig } from 'axios';
import { DisplayDownloadFile } from '../components/Common/DisplayDownloadFile';
import { ILoader } from '../core/utilities/ui/Loader';
import { ErrorMessages } from '../components/Common/Constants';
import { TYPES } from '../startup/types';
import { container } from '../startup/inversify.config';
import { TelemetryLogger } from '../components/Logger/AppInsights';
import { Role } from '../core/common/Enums';
import { getLocalDate } from '../components/Helper/HelperFunction';
import { initialDownloadDocumentViewModel, IDownloadDocumentViewModel } from '../core/domain/viewModels/IDownloadDocumentViewModel';

const logger = TelemetryLogger.getInstance();

interface RequestDocumentReviewModelAction {
    type: actionTypes.DOCUMENT_REVIEW_MODEL_REQUEST;
}

interface ResponseDocumentReviewModelAction {
    type: actionTypes.DOCUMENT_REVIEW_MODEL_RESPONSE;
    data: IDocumentReviewModel[];
}
interface FailureDocumentReviewModelAction {
    type: actionTypes.DOCUMENT_REVIEW_MODEL_FAILURE;
}

interface RequestPreviewDocumentStatusAction {
    type: actionTypes.PREVIEW_DOCUMENTS_REQUEST;
}

interface ResponsePreviewDocumentStatusAction {
    type: actionTypes.PREVIEW_DOCUMENTS_RESPONSE;
    data: boolean;
}

interface FailurePreviewDocumentStatusAction {
    type: actionTypes.PREVIEW_DOCUMENTS_FAILURE;
}

export interface IPreviewState {
    previewReady: boolean;
}

export const initialPreviewState: IPreviewState = {
    previewReady: false
}

type KnownAction =
    DispatchAction |
    NotificationAction;

type DispatchAction = ResponseDocumentReviewModelAction
    | RequestDocumentReviewModelAction
    | FailureDocumentReviewModelAction
    | ResponsePreviewDocumentStatusAction
    | RequestPreviewDocumentStatusAction
    | FailurePreviewDocumentStatusAction;

const loader = container.get<ILoader>(TYPES.ILoader);

export const actionCreators = {

    requestDocumentReviewModel: (clientId: string, callback?:()=>void): AppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch({ type: actionTypes.DOCUMENT_REVIEW_MODEL_REQUEST });
        return initializeAxios().get<IDocumentReviewModel[]>('api/Review/GetDocumentReviewModel/' + clientId)
            .then(function (response: AxiosResponse<IDocumentReviewModel[]>) {

                callback && callback();
                dispatch({
                    type: actionTypes.DOCUMENT_REVIEW_MODEL_RESPONSE, data: response.data
                });

            })
            .catch(function (error: any) {
                dispatch({
                    type: actionTypes.NOTIFICATION, statusMessage: error.response ? error.response.statusText : ErrorMessages.DocumentReviewModel,
                    statusType: StatusType.Error
                });
                dispatch({ type: actionTypes.DOCUMENT_REVIEW_MODEL_FAILURE });
                logger.trackWarning(`requestDocumentReviewModel failed with error ${error.message} for client: ${clientId}`, { "ClientId": clientId });
            });
    },

    requestDocumentsForPreview: (clientId: string, callback?: () => void): AppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch({ type: actionTypes.DOCUMENT_REVIEW_MODEL_REQUEST });
        return initializeAxios().get<IDocumentReviewModel[]>('api/Review/GetAllDocumentsForPreview/' + clientId)
            .then(function (response: AxiosResponse<IDocumentReviewModel[]>) {
                dispatch({
                    type: actionTypes.DOCUMENT_REVIEW_MODEL_RESPONSE, data: response.data
                });
                callback && callback();

            })
            .catch(function (error: any) {
                dispatch({
                    type: actionTypes.NOTIFICATION, statusMessage: error.response ? error.response.statusText : ErrorMessages.DocumentReviewModel,
                    statusType: StatusType.Error
                });
                dispatch({ type: actionTypes.DOCUMENT_REVIEW_MODEL_FAILURE });
                logger.trackWarning(`requestDocumentsForPreview failed with error ${error.message} for client: ${clientId}`, { "ClientId": clientId });
            });
    },

    downloadIndividualAttachment: (clientId: string, attachment: IAttachmentDownloadViewModel): AppThunkAction<KnownAction> => (dispatch, getState) => {
        let config: AxiosRequestConfig = { responseType: 'arraybuffer', headers: { 'Content-Type': 'application/json;utf-8' } };
        loader.show();
        return initializeAxios().post<IAttachmentDownloadViewModel>('api/Review/DownloadIndividualAttachment/' + clientId,
            JSON.stringify(attachment), config)
            .then(function (response: any) {
                const contentDisposition = response.headers["content-disposition"];
                const fileName = contentDisposition ? /filename="?([^"]*)"?;/g.exec(contentDisposition) : undefined;
                if (fileName && fileName.length > 1) {
                    attachment.attachmentName = fileName[1];
                }
                let displayDownloadFile = new DisplayDownloadFile();
                displayDownloadFile.showFile(response.data, attachment.attachmentName);
                loader.hide();
            })
            .catch(function (error: any) {
                dispatch({
                    type: actionTypes.NOTIFICATION, statusMessage: error.response?.statusText ?? error.message,
                    statusType: StatusType.Error
                });
                logger.trackWarning(`downloadIndividualAttachment failed with error ${error.message} for client: ${clientId}`, { "ClientId": clientId });
            });
    },

    downloadAllAttachments: (clientId: string, attachments: IAttachmentDownloadViewModel[]): AppThunkAction<KnownAction> => (dispatch, getState) => {
        let config: AxiosRequestConfig = { responseType: 'arraybuffer', headers: { 'Content-Type': 'application/json;utf-8' } };
        loader.show();
        const todayDateTime = getLocalDate();
        return initializeAxios().post<IAttachmentDownloadViewModel[]>('api/Review/DownloadAllAttachments/' + clientId,
            JSON.stringify({ attachmentDownload : attachments , localDate: todayDateTime}), config)
            .then(function (response: any) {
                let displayDownloadFile = new DisplayDownloadFile();
                displayDownloadFile.showFile(response.data, 'Attachments.zip');
                loader.hide();
            }).catch(function (error: any) {
                dispatch({
                    type: actionTypes.NOTIFICATION, statusMessage: error.response?.statusText ?? error.message,
                    statusType: StatusType.Error
                });
                logger.trackWarning(`downloadAllAttachments failed with error ${error.message} for client: ${clientId}`, { "ClientId": clientId });
            });
    },

    requestDocumentPreviewStatus: (clientId: string, step: number, callback: (step?: number) => void): AppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch({ type: actionTypes.PREVIEW_DOCUMENTS_REQUEST });
        return initializeAxios().get('api/Review/IsDocumentPreviewReady/' + clientId)
            .then(function (response: AxiosResponse<boolean>) {
                dispatch({ type: actionTypes.PREVIEW_DOCUMENTS_RESPONSE, data: response.data });
                if (response.data) {
                    if (step) {
                        callback(step);
                    } else {
                        callback();
                    }
                }
            }).catch(function (error: any) {
                dispatch({
                    type: actionTypes.NOTIFICATION, statusMessage: error.response?.statusText ?? error.message,
                    statusType: StatusType.Error
                });
                dispatch({ type: actionTypes.PREVIEW_DOCUMENTS_FAILURE });
                logger.trackWarning(`requestDocumentPreviewStatus failed with error ${error.message} for client: ${clientId}`, { "ClientId": clientId });
            });
    },
    downloadAllDocuments: (clientId: string, fileName: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const state = getState();
        const isPreviewMode = state.signProcessData.clientprocessmodel.role === Role.CPA;
        loader.show();
        return initializeAxios().get('api/Download/GetAllDocumentZipSasAsync/' + clientId)
            .then(function (response: any) {
                let displayDownloadFile = new DisplayDownloadFile();
                if (response.data && response.data.length > 0) {
                    displayDownloadFile.directDownload(response.data);
                    logger.trackTrace(`downloadAllDocuments success`, { "ClientId": clientId, "PreviewMode": isPreviewMode });
                    loader.hide();
                }
                else {
                    let config: AxiosRequestConfig = { responseType: 'arraybuffer', headers: { 'Content-Type': 'application/json;utf-8' } };
                    return initializeAxios().get('api/Download/GetAllDocumentsZipStreamAsync/' + clientId, config)
                        .then(function (response: any) {
                            displayDownloadFile.showFile(response.data, fileName);
                            logger.trackTrace(`downloadAllDocuments success`, { "ClientId": clientId, "PreviewMode": isPreviewMode });
                            loader.hide();
                        }).catch(function (error: any) {
                            dispatch({
                                type: actionTypes.NOTIFICATION, statusMessage: error.response ? error.response.statusText : "Failed to download All Attachments",
                                statusType: StatusType.Error
                            });
                            logger.trackWarning(`downloadAllDocuments failed with error ${error.message} for client: ${clientId}`, { "ClientId": clientId, "PreviewMode": isPreviewMode });
                        });
                }
            }).catch(function (error: any) {
                dispatch({
                    type: actionTypes.NOTIFICATION, statusMessage: error.response ? error.response.statusText : "Failed to download All Documents",
                    statusType: StatusType.Error
                });
                logger.trackWarning(`downloadAllDocuments failed with error ${error.message} for client: ${clientId}`, { "ClientId": clientId, "PreviewMode": isPreviewMode });
            });
    },
    downloadSelectedDocuments: (clientId: string, documentGroups: number[]): AppThunkAction<KnownAction> => (dispatch, getState) => {
        let downloadDocumentModel: IDownloadDocumentViewModel = { ...initialDownloadDocumentViewModel };
        downloadDocumentModel.documentGroupId = documentGroups;
        downloadDocumentModel.localDate = getLocalDate();
        const state = getState(); 
        const isPreviewMode = state.signProcessData.clientprocessmodel.role === Role.CPA;
        let config: AxiosRequestConfig = { responseType: 'arraybuffer', headers: { 'Content-Type': 'application/json;utf-8' } };
        loader.show();
        let fileName: string;
        return initializeAxios().post<IAttachmentDownloadViewModel[]>('api/Download/GetSelectedDocuments/' + clientId,
            JSON.stringify(downloadDocumentModel), config)
            .then(function (response: any) {
                let displayDownloadFile = new DisplayDownloadFile();
                const contentDisposition = response.headers["content-disposition"];
                const fileNameMatch = contentDisposition ? /filename="?([^"]*)"?;/g.exec(contentDisposition) : undefined;
                if (fileNameMatch && fileNameMatch.length > 1) {
                    fileName = fileNameMatch[1];
                }
                displayDownloadFile.showFile(response.data, fileName);
                logger.trackTrace(`downloadSelectedDocuments success`, { "ClientId": clientId, "PreviewMode": isPreviewMode });
                loader.hide();
            }).catch(function (error: any) {
                dispatch({
                    type: actionTypes.NOTIFICATION, statusMessage: error.response?.statusText ?? error.message,
                    statusType: StatusType.Error
                });
                logger.trackWarning(`downloadSelectedDocuments failed with error ${error.message} for client: ${clientId}`, { "ClientId": clientId, "PreviewMode": isPreviewMode });
            });
    }
}

export const reducer: Reducer<IDocumentReviewModel[]> = (state: IDocumentReviewModel[] = initialDocumentReviewModel, incomingAction: Action) => {
    const action = incomingAction as DispatchAction;
    switch (action.type) {
        case actionTypes.DOCUMENT_REVIEW_MODEL_REQUEST:
            return initialDocumentReviewModel;
        case actionTypes.DOCUMENT_REVIEW_MODEL_RESPONSE:
            let receivedDocumentReviewModel: IDocumentReviewModel[] = { ...initialDocumentReviewModel }
            receivedDocumentReviewModel = action.data;
            return receivedDocumentReviewModel;
        default:
            return state || initialDocumentReviewModel;
    }
};

export const previewReducer: Reducer<IPreviewState> = (state: IPreviewState = initialPreviewState, incomingAction: Action) => {
    const action = incomingAction as DispatchAction;
    switch (action.type) {
        case actionTypes.PREVIEW_DOCUMENTS_REQUEST:
            return initialPreviewState;
        case actionTypes.PREVIEW_DOCUMENTS_RESPONSE:
            return { previewReady: action.data };
        case actionTypes.PREVIEW_DOCUMENTS_FAILURE:
            return initialPreviewState;
        default:
            return state || initialPreviewState;
    }
};