import React from "react";
import { FooterComponent } from "cp-common-ui-components";

const Footer = () => {
    return (
        <FooterComponent
            footerLeftContent={<div>Copyright © 2024 cPaperless LLC</div>}
            footerRightContent={
                <div>
                    <a href="https://safesend.com/legal-policies/terms-of-use.php" target="_blank">
                        Terms of service
                    </a>
                    <span> - </span>
                    <a href="https://safesend.com/legal-policies/privacy-statement.php" target="_blank">
                        Privacy Policy
                    </a>
                </div>
            }
        />
    );
};

export default Footer;
